/*global _ */
import MissingArgumentsError from '../../../../../../classes/errors/MissingArgumentsError'
import {toBoolean, get, has, currency} from '../../../../../../lib/formatters'

export default {
  data() {
    return {
      isProcessing: false,
      processingMessage: '',
      builderListeners: {}
    }
  },
  async created() {

    this.builderListeners = {
      'fields:changed': this.fieldsChanged,
      'fields.date:changed': this.dateChanged,
      'fields.time:changed': this.timeChanged,
      'fields.duration:changed': this.durationChanged,
      'fields.participants:changed': this.participantsChanged,
      'finalize': this.finalize,
    }
  },
  methods: {
    async finalize(fields, vm) {
      await this.addAndGotoCart(fields, vm)
    },
    async fieldsChanged(field, value, event, vm) {

    },
    async dateChanged(value, event, vm) {
      await this.fetchUnavailability(value, event, vm)
    },
    async timeChanged(value, event, vm) {
      await this.checkAvailability(value, event, vm)
    },
    async durationChanged(value, event, vm) {
      await this.fetchUnavailability(value, event, vm)
    },
    async participantsChanged(value, event, vm) {
      await this.fetchUnavailability(value, event, vm)
    },
    async fetchUnavailability(value, event, vm) {
      if (value) {
        try {
          this.startPromising(this.$$t('messages.processing.availability', 'Checking...'))
          vm.resetFieldsValidation()
          vm.setBuilderFormFieldProp(this.getTimeslotsFields())
          const data = await this.product.getUnavailability(this.fields)
          vm.setBuilderFormFieldProp(this.getTimeslotsFields(data.unavailability))

          await this.checkAvailability(this.fields, event, vm)

        } catch (e) {
          if (!(e instanceof MissingArgumentsError)) {
            this.fields.start_at_date = ''
            this.fields.start_at_time = ''
            await this.alert(e.message)
          }
        }
        this.stopPromising()
      }
    },

    async checkAvailability(data, event, vm) {
      if (data) {
        try {
          this.startPromising(this.$$t('messages.processing.availability', 'Checking...'))
          vm.resetFieldsValidation()
          this.fields.available = false
          const data = await this.product.checkAvailability(this.fields)
          this.fields.available = toBoolean(data)
        } catch (e) {
          if (!(e instanceof MissingArgumentsError)) {
            this.$$debugLog({error: e});
            if (e.all) {
              vm.setCustomError('start_at_time', e.all)
            } else {
              vm.setCustomError('start_at_time', e.message)
            }
          }
        }
        this.stopPromising()
      }
    },

    async prepareCartItem(fields, vm) {
      fields = fields || this.fields
      await this.holdReservation(fields, vm)
      return await this.validateCartItem(fields, vm)
    },

    async holdReservation(fields, vm) {
      fields = fields || this.fields
      const holdMessage =  this.$$t('messages.processing.hold', 'holding reservation...')
      try {
          vm.resetFieldsValidation()
          this.startPromising(holdMessage)

          this.fields.hold = null
          this.fields.reservation_hold_id = null

          const hold = await this.product.hold(fields)
          const holdData = this.fields.hold = hold.data
          this.fields.ignore_reservation_hold_id =  this.fields.reservation_hold_id = holdData.id

          this.stopPromising()
        } catch (e) {
          this.stopPromising()
          if (!(e instanceof MissingArgumentsError)) {
            this.$$debugLog({error: e});
            throw e
          }
        }

    },

    async validateCartItem(fields, vm) {
      fields = fields || this.fields
      const validateMessage =  this.$$t('messages.processing.validate', 'Validating reservation...')
      try {
          vm.resetFieldsValidation()
          this.startPromising(validateMessage)

          this.fields.costDetails = null
          const costDetails = _.cloneDeep(await this.product.validate(fields))
          costDetails.deposit = currency(costDetails.deposit)
          this.fields.costDetails = costDetails

          this.stopPromising()
        } catch (e) {
          this.stopPromising()
          if (!(e instanceof MissingArgumentsError)) {
            this.$$debugLog({error: e});
            throw e
          }
        }
    },

    async addAndGotoCart(fields, vm) {
      fields = fields || this.fields
      await this.validateCartItem(fields, vm)

      const finalizeMessage =  this.$$t('messages.processing.finalize', 'Processing your order...')
      try {
        vm.resetFieldsValidation()
        this.startPromising(finalizeMessage)
        const item = this.product.getCartItem(fields)
        if (this.editItem) {
          item.id = this.editItem.id
        }
        item.editRoute = this.getProductEditRoute(item.attributes.product)
        this.addExtraOrderOptions(item)
        await this.addProductItemToCart(item, true)

      } catch (e) {
        this.stopPromising()
        if (!(e instanceof MissingArgumentsError)) {
          this.$$debugLog({error: e});
          throw e
        }
      }
    },

    addExtraOrderOptions(item) {
      const data = this.pageBuilderData
      const defs = _.pickBy(get(data, 'form.fields', {}), i => has(i, 'orderOptionPath'))
      _.forOwn(defs, ({orderOptionPath = ''}, fieldName) => {
        const value = this.fields[fieldName]
        _.set(item, `payload.options.${orderOptionPath}`, value)
      })
    },

    getTimeslotsFields(unavailability = null) {
      const options = _.merge({},
        this.$$clone(this.$$t('timeslots', {})),
        this.$$clone(this.$$s('timeslots', {}))
      )
      options.date = this.fields.date
      options.unavailable = !unavailability ? null : _.cloneDeep(unavailability)
      return {
        name: 'start_at_time',
        options
      }
    },
  }

}
