/*global _ */
import {name, mixins, props} from './config'
import {getFirstErrorMessage} from './../../../../services/base'

export default {
  name,
  mixins,
  props,
  events: 'promotion.manual-claim',
  data() {
    return {
      success: false,
      errorMessage: null,
      promotion: null,
      promotionType: null,
      partialError: null,
    }
  },
  computed: {
    submitButtonAttr() {
      const attrs = this.submitButtonAttrs || {}
      const classes = this.parseAttrClasses(attrs, this.submitButtonClasses)
      return {
        title: this.submitButtonTitle,
        ...attrs,
        class: classes
      }
    },
    hasError() {
      return !!this.errorMessage
    },
    errorTitle() {
      return this.hasError ?
        this.buildMessage(this.errorTitlesMap) :
        null
    }
  },

  async created() {
    await this.initClaim()
  },

  methods: {
    async initClaim() {
      this.success = false
      this.errorMessage = null
      this.promotion = null

      const id = this.promotedUserId
      if (!id) {
        this.errorMessage = this.missingIdMessage
        return
      }

      this.emitSlim('init', id, () => this)
      try {
        this.startPromising(this.loadingMessage)
        this.partialError = null
        const data = await this.invokeService('user.promotions.claim', {id})
        this.promotion = this.parsePromotion(data)
        this.emitSlim('success', data, id, () => this)
        this.success = true
      } catch (e) {
        debugLog({CLAIM_ERROR: e})
        this.errorMessage = getFirstErrorMessage(e)
        this.emitSlim('error', e, id, () => this)
      } finally {
        this.emitSlim('end', id, () => this)
        this.stopPromising()
      }
    },
    parsePromotion(data) {
      const promotion = _.cloneDeep(data || {})
      const type = this.promotionType = _.get(promotion, 'promotion.data.action', 'default')

      const methodName = _.camelCase(`parse_${type}_promotion_data`)
      if (this[methodName]) {
        this[methodName](promotion)
      }

      const params = {promotion, getVM: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.promotion`, params)
      this.$$applyDataFilters(`${this.eventSpace}.promotion.${type}`, params)
      if (params.error) {
        if (params.error instanceof Error) {
          throw params.error
        } else {
          throw new Error(params.error)
        }
      }
      return params.promotion
    }
  }
}
