import {Vue} from '~/addiesaas'

import Validations, {
  parseValidations,
  parseValidationSetup,
  setValidationRule,
  Validators
} from '../../lib/validations'

import formatters, {templiParse} from '../../lib/formatters'

const {empty, notEmpty, map} = formatters

export default {
  props: {
    value: {}
  },
  data() {
    return {
      formErrorMessage: null,
      fieldCustomErrors: {},
      formSetup: {},
      fields: this.value || {},

    }
  },
  mixins: [],

  computed: {},

  watch: {
    fields: {
      handler(newVal, oldVal) {
        this.$emit('input', this.fields)
      },
      deep: true
    }
  },
  created() {
    if (this.validateFormFieldOnDirty) {
      this.formSubmitting = true
    }
    this.setupForm()
  },
  methods: {
    getFieldProps(name, fieldDef, sectionField) {
      const omit = ['reactive', '[keepif]', '[removeif]', '[data]', '[switch]', '[case]', 'customErrors']
      const $$v = this.$v
      const namespace = `form.fields.${name}`
      const parse = (values) => map(values, (v, i) => {
        if (_.isString(v)) {
          v = this.$$trim(v)
          if (/^{\[[^\]]+?\]}$/.test(v)) {
            v = templiParse(v, this.builderData)
          }
          if (/{{[^}]+?\}}/.test(v)) {
            v = templiParse(v, this.builderData)
          }
        } else {
          if (_.isObject(v)) {
            v = parse(v)
          }
        }
        return v
      })

      const field = parse({name, ..._.omit(fieldDef, omit)})

      const {model = name, validationRule = model} = fieldDef || {}
      const validationDef = this.validations['fields'][validationRule]
      const props = {
        settings: _.merge({}, this.$$clone(this.$$s(`content.form.fields.${name}`, {}))),
        translations: _.merge({}, this.$$clone(this.$$t(`content.form.fields.${name}`, {}))),
        $$v,
        field,
        form: this.builderForm,
        class: this.getFieldContainerClasses(name, sectionField, fieldDef, validationDef),
        validations: sectionField['validations'] !== false ? validationDef : {},
        v: $$v.fields[validationRule],
        vRoot:  $$v.fields,
        'data-namespace': namespace,
        'data-key': name,
        customErrors: this.fieldCustomErrors[validationRule],
        ref: namespace,
      }
      return props
    },
    getFieldContainerClasses(fieldName, field, fieldDef, validations) {
      const generic = 're-input-' + _.kebabCase(fieldName)
      return [generic,
        ...this.getFieldContainerRequiredClasses(fieldName, validations),
        ...this.getFieldContainerExtraClasses(fieldName, validations)
      ]
    },
    getFieldContainerRequiredClasses(fieldName, validations) {
      validations = validations || this.validations
      return [{required: _.has(validations, 'items.required')}]
    },
    getFieldContainerExtraClasses(fieldName, validations) {
      return []
    },

    resetForm(fieldsPath = 'fields') {
      if (!this.validateFormFieldOnDirty) {
        this.formSubmitting = false
      }
      const form = _.get(this, fieldsPath, {})
      if (!_.isEmpty(form)) {
        _.forOwn(form, (value, key) => {
          form[key] = null
        })
      }
      const v = this.$v
      if (v) {
        const formV = _.get(v, fieldsPath)
        if (formV) {
          formV.$reset()
        } else {
          v.$reset()
        }
      }
    },
    resetFieldsValidation(fieldsPath = 'fields') {
      const reset = _.get(this.$v, `${fieldsPath}.$reset`, (() => {
      }))
      reset()
      this.fieldCustomErrors = {}
      this.formErrorMessage = ''
    },
    resetFieldValidation(fieldName, fieldsPath = 'fields') {
      const pathPrefix = (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const reset = _.get(this.$v, pathPrefix + '.$reset', (() => {
      }))
      this.setCustomError(fieldName, [])
      reset()
    },

    setCustomError(fieldName, error) {
      if (_.isString(error)) {
        error = {message: error}
      }
      Vue.set(this.fieldCustomErrors, fieldName, error)
    },
    showFormError(e, options) {
      let {scrollTo = null} = options || {}
      const all = e.all
      let shownOnField = false
      if (this.showSubmitErrorOnFields) {
        _.forOwn(all, (message, key) => {
          this.setCustomError(key, {message})
        })
        shownOnField = _.some(all, (error, key) => _.has(this.fields, key))
      }
      if (!shownOnField) {
        this.formErrorMessage = e.message
      }
      if (scrollTo) {
        this.scrollTo(scrollTo)
      } else {
        this.scrollToFirstErrorElement()
      }
    },


    parseValidationSetup(validationRules) {
      return parseValidationSetup(validationRules, this)
    },

    setupFormFields() {
      const form = this.builderForm || {}
      const formFields = form.fields || {}
      const values = this.builderValues || {}
      let defaultFormFields = _.transform(formFields, (carry, f, key) => {
        const value = this.$$fv(
          values[key],
          f.value,
          f.default,
          ''
        )
        return carry[key] = value
      }, {})

      const defaultValues = defaultFormFields
      const fields = defaultValues

      this.hydrateFields(fields, true)

      const valueGetter = (v) => {
        return this.fields[v]
      }
      const propGetter = (v) => {
        return formFields[v]
      }
      const valueSetter = (k, v) => {
        Vue.set(this.fields, k, v)
      }
      const propSetter = (name, v) => {
        this.setBuilderFormFieldProp({...v, name})
      }

      const namespace = 'fields'
      //data: this.builderData
      this.setupReactiveItems(formFields, {data: this.getBuilderData, namespace, valueGetter, propGetter, valueSetter, propSetter})

      this.hydrateFields(fields)
    },

    setupFormValidations() {
      const form = this.builderForm || {}
      const formFields = form.fields || {}
      const validationSettings = form.validations || {}
      const fields = this.fields

      // initiate custom error object of fields
      this.fieldCustomErrors = _.mapValues(fields, v => null)

      const parsedValidations = this.parseValidationSettings(validationSettings.fields, formFields, fields)
      let defaultValidationFields = _.merge(
        {},
        _.mapValues(fields, v => ({})),
        parsedValidations.validations
      )
      let validationRules = {fields: defaultValidationFields}

      _.forOwn(validationSettings, (v, k) => {
        if (_.isArray(v)) {
          // validationRules[k] = fieldPaths(v)
          validationRules[k] = v
        }
      })

      this.builderValidations = {
        validations: validationRules,
        messages: {fields: parsedValidations.messages},
      }
    },

    setupForm() {
      this.setupFormFields()
      this.setupFormValidations()
    },

    hydrateFields(values, create = false) {
      values = values || this.data.values || {}
      _.forOwn(values, (v, k) => {
        if (!create || !_.has(this.fields, k)) {
          Vue.set(this.fields, k, create ? null : v)
        }
      })
    },
  }
}
