/*global _ */
export const props = {
  allowEdit: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowEdit', true)
    }
  },
  editOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('edit', {})
      const $t = this.$options.$$t('edit', {})
      return _.merge({}, $t, $s)
    }
  },
  formOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('form', {})
      const $t = this.$options.$$t('form', {})
      return _.merge({}, $t, $s)
    }
  },
  editFormOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('editForm', {})
      const $t = this.$options.$$t('editForm', {})
      return _.merge({}, $t, $s)
    }
  },
  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', true)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  submitOnEnter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submitOnEnter', false)
    }
  }
}
export default props
