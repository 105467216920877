<template lang="pug">
    div(v-once :id="`reContentPlaceholder_${name}`")
</template>

<script>
export default {
    name: 're-content-placeholder',

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    data() { return {
        wasInvoked: false,
    }},

    mounted() {
        if (!this.wasInvoked) {
            this.wasInvoked = true
            if (window.addiesaas.cart_rules && window.addiesaas.cart_rules.runDelayedRules) {
                window.addiesaas.cart_rules.runDelayedRules(window.addiesaas.$core.EventBus, this.name)
            }
        }
    },
}
</script>
