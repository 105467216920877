import axios from 'axios'
import {setHeaders, getHeaders} from '~/vuestrap/axios'

export const init = (customAxios) => {
  customAxios = customAxios || axios
  setHeaders(null, customAxios)
  const headers = getHeaders() || {}
  return {
    axios: customAxios,
    headers,
    authToken: headers['X-Authorization'],
    csrf: headers['X-CSRF-TOKEN'],
  }
}

export default {initAxios: init, getHeaders, setHeaders}
