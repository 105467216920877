export const name = 're-core-page-shop-product'
export const component = 're-shop-product'

import {factory} from '../../../../lib/page'

const props = {
  product: {},
  token: {},
}

const data = {
  renameMergeAttrProps: {
    product: 'item'
  }
}

export default factory(name, {props, component, data})


