export const name = 're-core-page-shop-cart'
export const component = 're-shopping-cart'

import {factory} from '../../../../lib/page'

const props = {
  step: {},
  waiver: {}
}

export default factory(name, {props, component})

