export const legacyProps = {
  isSeparateForms: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('form.separate', true)
    }
  },
  profileFormClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('form.classes', 're-profile-form')
    }
  },
  profileSubmitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.buttons.submit.title', 'Change')
    }
  },
  profileSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('form.success.message', 'Your profile has been updated')
    }
  },
  personalFormClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('forms.personalDetails.classes', 're-personal-form')
    }
  },
  personalBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.personalDetails.title', 'Profile')
    }
  },
  personalSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('forms.personalDetails.success.message', 'Personal information has been successfully changed')
    }
  },
  personalSubmitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.personalDetails.buttons.submit.title', 'Save')
    }
  },


  addressFormClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('forms.addressDetails.classes', 're-address-form')
    }
  },
  addressBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.addressDetails.title', 'Address')
    }
  },
  addressSubmitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.addressDetails.buttons.submit.title', 'Save')
    }
  },
  addressSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('forms.addressDetails.success.message', 'Address has been successfully changed')
    }
  },

  passwordComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.passwordDetails', 're-profile-reset-password')
    }
  },

  passwordFormClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('forms.passwordDetails.classes', 're-password-form')
    }
  },
  passwordBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.passwordDetails.title', 'Password')
    }
  },
  passwordFormButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.passwordDetails.buttons.submit.title', 'Change')
    }
  },
  passwordFormButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('forms.passwordDetails.buttons.classes', '')
    }
  },
  passwordSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('forms.passwordDetails.success.message', 'Your password has been changed')
    }
  },

  hasFirstNameInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.first_name.show', true) !== false
    }
  },
  firstNameRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.first_name.validations.required', true)
    }
  },
  firstNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.first_name.label', 'First Name')
    },
  },

  hasLastNameInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.last_name.show', true) !== false
    }
  },
  lastNameRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.last_name.validations.required', true)
    }
  },
  lastNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.last_name.label', 'Last Name')
    },
  },

  hasRacerNameInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.racer_name.show', false) !== false
    }
  },
  raceNameRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.racer_name.validations.required', false)
    }
  },
  raceNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.racer_name.label', 'Racer Name')
    }
  },

  hasBirthDateInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.birth_date.show', true) !== false
    }
  },
  birthDateRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.birth_date.validations.required', true)
    }
  },
  birthDateLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.birth_date.label', 'Date of Birth')
    }
  },

  hasEmailInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.email.show', true) !== false
    }
  },
  emailRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.email.validations.required', true)
    }
  },
  emailLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.email.label', 'Email')
    }
  },

  hasPhoneInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.phone.show', true) !== false
    }
  },
  phoneRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.phone.validations.required', true)
    }
  },
  phoneLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.phone.label', 'Mobile Phone')
    }
  },

  hasGenderInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.gender.show', true) !== false
    }
  },
  genderRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.gender.validations.required', false)
    }
  },
  genderLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.gender.label', 'Gender')
    }
  },

  hasAddressInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.address.show', true) !== false
    }
  },
  addressRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.address.validations.required', true)
    }
  },
  addressLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.address.label', 'Address 1')
    }
  },
  hasSecondAddressInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.address2.show', true) !== false
    }
  },
  secondAddressRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.address2.validations.required', false)
    }
  },
  secondAddressLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.address2.label', 'Address 2')
    }
  },
  hasCityInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.city.show', true) !== false
    }
  },
  cityRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.city.validations.required', true)
    }
  },
  cityLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.city.label', 'City')
    }
  },
  hasStateInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.state.show', true) !== false
    }
  },
  stateRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.state.validations.required', false)
    }
  },
  stateLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.state.label', 'State')
    }
  },
  hasZipInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.zip.show', true) !== false
    }
  },
  zipRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.zip.validations.required', false)
    }
  },
  zipLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.zip.label', 'Zip Code')
    }
  },
  hasCountryInput: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.country.show', true) !== false
    }
  },
  countryRequired: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('form.fields.country.validations.required', false)
    }
  },
  countryLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.country.label', 'Country')
    }
  },

}

export default legacyProps
