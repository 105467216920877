/* global _ */
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      processingMessage: null,
      relations: {},
      eventSpace: 'user.profile.billing',
      filterLocationId: null,
    }
  },
  computed: {
    items() {
      return this.User.paymentMethods
    },
    itemsCount() {
      return _.size(this.items || [])
    },
    hasItems() {
      return !!this.itemsCount
    },
    tableData() {
      if (this.showLocationFilter && this.filterLocationId) {
        return _.filter(this.items, item => {
          return item.location_id === this.filterLocationId
        })
      }
      return this.items
    },
    hasVisibleData() {
      return this.hasItems
    },
    showNoBilling() {
      return !this.hasVisibleData && !this.editingCard && !this.isPromising
    },
    locationInputAttrs() {
      const allOptionLabel = this.$$t('form.filters.location_id.allOptionLabel')
      const defaults = this.locationInputOptions || {
        'label': 'Select location',
      }
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      return this.getLocationFieldProperties('location_id', {defaults, allOptionLabel}, 'filters')
    },
    hasFilteredCards() {
      return !!this.tableData.length
    },
  },

  async created() {
    await this.fetch()
  },
  watch: {
    filterLocationId(id) {
      if (id) {
        this.profileLocationId = id
      }
    },
    profileLocationId(id) {
      if (id && this.filterLocationId && id !== this.filterLocationId) {
        this.filterLocationId = id
      }
    }
  },
  methods: {
    getComponentExtraClasses() {
      return [{
        'editing-card': !!this.editingCard
      }]
    },
    async fetch(autoloadAll) {
      this.emit('fetch.init')
      try {
        if (!this.hasVisibleData) {
          this.startPromising(this.loadingMessage)
        }
        if (autoloadAll) {
          await this.autoLoadAll()
        }
        await this.initialAutoLoadCompleted()
        const items = await this.autoLoadPaymentMethods()
        this.$emit('success', items)
        this.emit('fetch.success', items)
      } catch (e) {
        this.$emit('error', e)
        this.emit('fetch.error', e)
      } finally {
        this.emit('fetch.end')
        this.stopPromising()
      }
    },
  }
}
