import {Vue} from '~/addiesaas'
import ReRegistration from './re-registration'
Vue.component(ReRegistration.name, ReRegistration)

import ReRegistrationPlug from './re-registration-plug'
Vue.component(ReRegistrationPlug.name, ReRegistrationPlug)

import ReEmailVerification from './re-email-verification'
Vue.component(ReEmailVerification.name, ReEmailVerification)

import ReForgotPassword from './re-forgot-password'
Vue.component(ReForgotPassword.name, ReForgotPassword)

import ReLogin from './re-login'
Vue.component(ReLogin.name, ReLogin)

import ReLoginPlug from './re-login-plug'
Vue.component(ReLoginPlug.name, ReLoginPlug)

import ReLoginStatus from './re-login-status'
Vue.component(ReLoginStatus.name, ReLoginStatus)

import ReResetPassword from './re-reset-password'
Vue.component(ReResetPassword.name, ReResetPassword)

import ReVerifyAccountError from './re-verify-account-error'
Vue.component(ReVerifyAccountError.name, ReVerifyAccountError)

import ReClaimPromotionReward from './re-claim-promotion-reward'
Vue.component(ReClaimPromotionReward.name, ReClaimPromotionReward)

import RePromotionStatus from './re-promotion-status'
Vue.component(RePromotionStatus.name, RePromotionStatus)

export default {
  ReRegistration,
  ReRegistrationPlug,
  ReEmailVerification,
  ReForgotPassword,
  ReLogin,
  ReLoginPlug,
  ReLoginStatus,
  ReResetPassword,
  ReVerifyAccountError,
  ReClaimPromotionReward,
  RePromotionStatus
}
