/*global _ */
import {name, mixins, props, data, EventBus, Subscription} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      productView: this.view,
      productItem: this.item,
      planItem: this.plan,
      subscriptionItem: this.subscription,
      showCardUpdateForm: false,
      cardUpdateData: {},
      galleryImages: []
    }
  },

  computed: {
    isProfileMode() {
      return this.mode !== 'shop' || !_.isEmpty(this.subscriptionItem)
    },
    isGridView() {
      return this.productView === 'grid'
    },

    subscriberName(){
      return this.subscriptionItem.subscriberName
    },
    billedCard() {
      return this.subscriptionItem.cardMaskedNumber
    },
    cardExpiration() {
      return this.subscriptionItem.cardExpiration
    },
    cardExpirationFormatted() {
      return this.formatDate(this.cardExpiration, this.$$s('order.card.expiration.format', 'MM/yy'))
    },
    cardExpiring() {
      return this.subscriptionItem.isCardExpiring
    },
    cardExpired() {
      return this.subscriptionItem.hasCardExpired
    },
    nextBillDate() {
      return this.subscriptionItem.nextBill
    },
    nextBillDateFormatted() {
      return this.formatDate(this.nextBillDate, this.$$s('order.billing.next.date.format', null))
    },
    showProductFeaturedImage() {
      return this.getProductImage() && this.showFeaturedImage &&
        this.$$s(`view.types.subscription.images.featured.show`, false)
    },
    cardFormAttr() {
      return {
        settings: this.$$clone(this.$$s(`updateCard`, {})),
        translations: this.$$clone(this.$$t(`updateCard`, {})),
      }
    },
  },

  async created() {
    this.productQuantity = this.getProductCartedQuantity()
    if (!_.isEmpty(this.subscription) && !(this.subscription instanceof Subscription)) {
      this.subscription = new Subscription(this.subscription, this)
    }
  },

  async mounted() {
  },
  methods: {
    getPlanItemStyle() {
      const styles = {...this.getPlanBackgroundImageStyle()}
      return styles
    },
    getPlanItemDetailsStyle() {
      const styles = {...this.getPlanBackgroundImageStyle()}
      return styles
    },
    getComponentExtraClasses() {
      return [
        `${this.productView}-view-item`,
        `plan-id-${this.planItem.id}`,
        `plan-code-${this.planItem.code}`,
      ]
    },
    async updateBillingDetails(fields, vm) {
      fields = fields || this.cardUpdateData
      const loaderMessage = this.$$t('updateCard.processing.message', 'Updating...')
      const successMessage = this.$$t('updateCard.success.message', 'Card updated successfully')
      const successMessageTitle = this.$$t('updateCard.success.title', null)
      try {
          vm.isProcessing = true
          vm.processingMessage = loaderMessage
          await this.$nextTick()
          const data = await this.subscriptionItem.updatePaymentMethod(fields)
          this.showCardUpdateForm = false
          await this.successAlertAsync(successMessage, successMessageTitle)
          this.$emit('subscription:card.updated', true)
        } catch (e) {
          vm.isProcessing = false
          vm.formErrorMessage = e.message
          // this.errorAlertAsync(e.message)
        }
    },
    initUpdateBillingDetails() {
      Vue.set(this.cardUpdateData, 'name', this.subscriberName)
      this.showCardUpdateForm = true
    },

    async cancelSubscription() {
      const message = this.$$t('cancel.confirmation.message', 'Do you really want to cancel?')
      const title = this.$$t('cancel.confirmation.title', null)
      const confirmLabel = this.$$t('cancel.confirmation.buttons.yes.title', 'Yes')
      const denyLabel = this.$$t('cancel.confirmation.buttons.no.title', 'No')
      const options = {message, title, confirmLabel, denyLabel}
      const loaderMessage = this.$$t('cancel.processing.message', 'Cancelling...')
      const successMessage = this.$$t('cancel.success.message', 'Subscription cancelled successfully')
      const successMessageTitle = this.$$t('cancel.success.title', null)

      const confirmed = await this.confirmAsync(options)
      if (confirmed) {
        try {
          this.isPromising = true
          this.promisingMessage = loaderMessage
          const data = await this.subscriptionItem.initCancel()
          this.isPromising = false
          await this.successAlertAsync(successMessage, successMessageTitle)
          this.$emit('subscription:cancelled', true)
        } catch (e) {
          this.isPromising = false
          this.errorAlertAsync(e.message)
        }
      }
    }
  },
}
