export const name = 're-core-page-user-profile'
export const component = 're-profile'

import {factory} from '../../../../lib/page'

const props = {
  tab: {},
}

export default factory(name, {props, component})
