/*global _ */

/*
DEVELOPER NOTE: A rule that does not require a parameter is called a standalone rule,
                others with parameters are parameterized rules.
1. Make sure to define a standalone rule similar to a non-standalone rule.
  e.g. required is standalone rule
2. Add the name of the standalone rule in list of standalone rules below
3. parameterized rules e.g., max, must take one or more params in the wrapping function
4. A parameterized rule may break due to null or undefined passed as params
  In that case developer can take precaution to check if the parameter values are null or undefined
   and return alwaysTrue rule instead
 */

import F from '../../formatters'
import V from '../index'
const {len, regex: buildRegex, req} = V

export const alwaysTrueRule = () => true

export const rules = {}
export const alpha = rules.alpha = () => V.alpha
export const alphaNum = rules.alphaNum = () => V.alphaNum
export const between = rules.between = (min, max) => V.between(min, max)
export const digits = rules.digits = () => () => V.decimal
export const digitsBetween = rules.digitsBetween = (min, max) => buildRegex(
  'digitsBetween', new RegExp(`^\\d{${min},${max}}$`)
)
export const email = rules.email = () => V.email
export const endsWith = rules.endsWith =  (n) => (v) => !req(v) || _.endsWith(v, n)
export const gt = rules.gt = (limit) => (v) => !req(v) || v > limit
export const gte = rules.gte = (limit) => (v) => !req(v) || v >= limit

export const integer = rules.integer = () => V.integer
export const ip = rules.ip = () => V.ipAddress
export const lt = rules.lt = (limit) => (v) => !req(v) || v >= limit
export const lte = rules.lte = (limit) => (v) => !req(v) || v >= limit
export const macAddress = rules.macAddress = () => V.macAddress
// export const nullable = rules.nullable = () => () => true
export const numeric = rules.numeric = () => V.numeric

export const regex = rules.regex = V.regexp
export const required = rules.required = () => V.required
export const requiredIf = rules.requiredIf = (v) => V.requiredIf(v)
export const requiredUnless = rules.requiredUnless = (v) => V.requiredUnless(v)
export const same = rules.same = (v) => V.sameAs(v)
// special case due to reserved keyword
export const startsWith = rules.startsWith = (n) => (v) => !req(v) || _.startsWith(v, n)
export const stringRule = rules.stringRule = () => (v) => !req(v) || _.isString(v)
export const url = rules.url = () => V.url

// @todo: Use maxValue for numeric
export const max = rules.max = (limit) => {
  limit = F.toNumber(limit)
  return _.isNaN(limit) ? alwaysTrueRule : V.maxLength(limit)
}
// @todo: Use minvalue for numeric
export const min = rules.min = (limit) => {
  limit = F.toNumber(limit)
  return _.isNaN(limit) ? alwaysTrueRule : V.minLength(limit)
}
// @todo: implement laravel
export const size = rules.size = () => len

// Special cases
export const phone = rules.phone = () => () => V.phoneRuleIfNotEmpty
export const intlPhone = rules.intlPhone = () => V.phoneRuleIfNotEmpty
export const postalCode = rules.postalCode = () => V.zipRuleIfNotEmpty
// @todo: Fix postalCodeFor to accept param
export const postalCodeFor = rules.postalCodeFor = () => V.zipRuleIfNotEmpty

// @todo implement
// export const accepted = rules.accepted = () => () => true
// export const acceptedIf = rules.acceptedIf = () => () => true
// export const activeUrl = rules.activeUrl = () => {}
// export const after = rules.after = () => () => true
// export const afterOrEqual = rules.afterOrEqual = () => () => true
// export const alphaDash = rules.alphaDash = () => () => true
// export const array = rules.array = () => () => true
// export const bail = rules.bail = () => () => true
// export const before = rules.before = () => () => true
// export const beforeOfEqual = rules.beforeOfEqual = () => () => true
// // special case due to reserved keyword
// export const booleanRule = rules.booleanRule = () => () => true
// export const confirmed = rules.confirmed = () => () => true
// export const currentPassword = rules.currentPassword = () => () => true
// export const date = rules.date = () => () => true
// export const dateEquals = rules.dateEquals = () => () => true
// export const dateFormat = rules.dateFormat = () => () => true
// export const decline = rules.declined = () => () => true
// export const declinedIf = rules.declinedIf = () => () => true
// export const different = rules.different = () => () => true
// export const dimensions = rules.dimensions = () => () => true
// export const distinct = rules.distinct = () => () => true
// // special case due to reserved keyword
// export const enumRule = rules.enumRule = () => () => true
// export const exclude = rules.exclude = () => () => true
// export const excludeIf = rules.excludeIf = () => () => true
// export const excludeUnless = rules.excludeUnless = () => () => true
// export const excludeWithout = rules.excludeWithout = () => () => true
// export const exists = rules.exists = () => () => true
// export const file = rules.file = () => () => true
// export const filled = rules.filled = () => () => true

// export const image = rules.image = () => () => true
// // special case due to reserved keyword
// export const inRule = rules.inRule = () => () => true
// export const inArray = rules.inArray = () => () => true
// export const ipv4 = rules.ipv4 = () => () => true
// export const ipv6 = rules.ipv6 = () => () => true
// export const json = rules.json = () => () => true

// export const mimeTypes = rules.mimeTypes = () => () => true
// export const mimes = rules.mimes = () => () => true
// export const multipleOf = rules.multipleOf = () => () => true
// export const notIn = rules.notIn = () => () => true
// export const notRegex = rules.notRegex = () => () => true
// export const password = rules.password = () => () => true
// export const present = rules.present = () => () => true
// export const prohibited = rules.prohibited = () => () => true
// export const prohibitedIf = rules.prohibitedIf = () => () => true
// export const prohibitedUnless = rules.prohibitedUnless = () => () => true
// export const prohibits = rules.prohibits = () => () => true
// export const requiredWith = rules.requiredWith = () => () => true
// export const requiredWithAll = rules.requiredWithAll = () => () => true
// export const requiredWithout = rules.requiredWithout = () => () => true
// export const requiredWithoutAll = rules.requiredWithoutAll = () => () => true
// export const sometimes = rules.sometimes = () => () => true
// export const timezone = rules.timezone = () => () => true
// export const unique = rules.unique = () => () => true
// export const uuid = rules.uuid = () => () => true

export const standaloneRules = [
  'alpha',
  'alphaNum',
  'digits',
  'email',
  'integer',
  'ip',
  'macAddress',
  'nullable',
  'numeric',
  'required',
  'stringRule',
  'url',
  'size',
  'phone',
  'intlPhone',
  'postalCode',
  // 'accepted',
  // 'acceptedIf',
  // 'activeUrl',
  // 'afterOrEqual',
  // 'alphaDash',
  // 'array',
  // 'bail',
  // 'before',
  // 'beforeOfEqual',
  // 'booleanRule',
  // 'confirmed',
  // 'currentPassword',
  // 'date',
  // 'decline',
  // 'dimensions',
  // 'distinct',
  // 'enumRule',
  // 'exclude',
  // 'exists',
  // 'file',
  // 'filled',
  // 'image',
  // 'ipv4',
  // 'ipv6',
  // 'json',
  // 'password',
  // 'present',
  // 'prohibited',
  // 'prohibits',
  // 'sometimes',
  // 'unique',
  // 'uuid',
]

export const getRule = (name) => {
  const nameAlt = _.camelCase(name)
  const nameAltRule = _.camelCase(name + '_rule')
  return F.fv(rules[nameAltRule], rules[nameAlt], rules[name], false)
}

export const isStandaloneRule = (name) => {
  const nameAlt = _.camelCase(name)
  const nameAltRule = _.camelCase(name + '_rule')
  return standaloneRules.includes(nameAltRule) ||
    standaloneRules.includes(nameAlt) ||
    standaloneRules.includes(name)
}

const ad = window.addiesaas = (window.addiesaas || {})
const adfn = ad.$fn || (ad.$fn = {})
const adValidators = adfn.validators || (adfn.validators = {})
adValidators.backendRules = rules

export default {rules, getRule, isStandaloneRule, standaloneRules}
