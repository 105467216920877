/*  global _ */
/*eslint no-console: 0*/

const EventBus = window.VueEventBus
const emit = (name, args, vm, dontFireById = false) => {
  vm = vm || {}
  args = {vm, ...(args || {})}
  EventBus.$emit(`re-simple-dropdown.${name}`, dontFireById ? null : (vm && vm.id || ''), args)
  if (!dontFireById && vm && vm.id) {
    EventBus.$emit(`re-simple-dropdown.${vm.id}.${name}`, args)
  }
}
export default {
  name: 're-simple-dropdown',
  props: {
    value: {},
    options: {},
    id: {
      type: [String, Function],
      default: ''
    },
    type: {
      type: [String, Function],
      default: 'dropdown' // list
    },
    size: {
      type: [String, Number, Function],
      default: 'full' //
    },
    containerClass: {
      type: [Array, String, Object, Function],
      default: ''
    },
    itemClass: {
      type: [Array, String, Object, Function],
      default: ''
    },
    skipDropdownIfOne: {
      type: [Boolean, String, Object, Function],
      default: true
    }
  },
  data() {
    return {
      menuIsOpen: false,
      activeValue: null,
      activeItem: null,
      containerHeight: null
    }
  },
  computed: {
    me() {
      return this
    },
    containerClasses() {
      return [
        this.containerClass,
        {
          hasOne: this.hasOne,
          open: !this.hasOne && this.menuIsOpen
        }
      ]
    },
    itemCount() {
      return _.size(this.options)
    },
    hasOne() {
      return this.skipDropdownIfOne && this.itemCount <= 1
    }
  },

  beforeCreate() {
    // emit('beforeCreate', {}, this, true)
  },
  created() {
    this.init()
    emit('created', {}, this)
  },
  beforeMount() {
    emit('beforeMount', {}, this)
  },
  mounted() {
    const element = this.$el
    this.containerHeight = element.offsetHeight
    emit('mounted', {element}, this)
  },
  beforeUpdate() {
    emit('beforeUpdate', {}, this)
  },
  updated() {
    // emit('updated', {}, this)
  },
  beforeDestroy() {
    emit('beforeDestroy', {}, this)
  },
  destroyed() {
    emit('destroyed', {}, this)
  },
  errorCaptured(error, component, info) {
    emit('errorCaptured', {error, component, info}, this)
  },
  methods: {
    init() {

      let value = this.value
      let item = this.options[value]
      if (!item) {
        value = _.head(Object.keys(this.options))
        item = this.options[value]
      }
      this.activeValue = value
      this.activeItem = item
    },
    isItemVisible(value, item) {
      return this.menuIsOpen || this.activeValue === value
    },
    getItemStyles(value, item) {
      const height = this.containerHeight ? this.containerHeight + 'px' : 'auto'
      return [
        { height }
      ]
    },
    getItemClasses(value, item) {
      return [this.itemClass, {
        active: this.activeValue === value
      }]
    },
    selectItem(value, option, $event) {
      const wasOpen  = this.menuIsOpen
      this.toggleDropdown($event)
      if (wasOpen) {
        this.activeValue = value
        this.activeItem = option

        this.$emit('update:value', value)
        this.$emit('update:option', option)
        this.$emit('update:selection', {[value]: option})

        this.$emit('input', value, {option, $event}, this)
        emit('change', {value, option, $event}, this)
      }
    },
    toggleDropdown($event, force = false) {
      this.menuIsOpen = force ? false : !this.menuIsOpen
      const show = this.menuIsOpen
      if (show) {
        this.$emit('show', {$event}, this)
      }
      else {
        this.$emit('hide', {$event}, this)
      }

    },
    dropdownBlur($event) {
      this.toggleDropdown($event, true)
    },
  }
}
