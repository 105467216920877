import ShopLocationMixin from '../../../../mixins/shop/location'
import ShopFailingLocationMixin from '../../../../mixins/shop/failing-location'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'
import ProductsFilterMixin from '../../../../mixins/store/products-filter'
import {getLocationsSync} from "../../../../lib/formatters";

export default {
  mixins: [
    ShopLocationMixin,
    ShopFailingLocationMixin,
    FieldsMixin,
    ProductsFilterMixin
  ],
  data() {
    return {
      location_id: null,
      allLocations: {},
    }
  },
  computed: {
    showLocationsInput() {
      return this.useLocation
    },
    useLocationFilter() {
      return this.showLocationsInput && this.filterProductByLocation
    },
    useStrictLocationFilter() {
      return this.filterProductByLocation === 'strict'
    },
    hideNonLocationFields() {
      return this.useLocationFilter && !this.location_id && this.hideOtherFieldsOnNoLocation
    },
    locationInputContainerAttrs() {
      return {
        class: this.getFieldContainerClasses('location_id')
      }
    },
    locationInputAttrs() {
      const defaults = this.locationInputOptions || {}
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      defaults.checkInvalid = true
      defaults.required = true
      return this.getLocationFieldProperties('location_id', {defaults})
    },
  },
  created() {
    this.allLocations = getLocationsSync()
  }
}
