export const name = 're-profile-subscriptions'

// custom setup
import Subscription from '../../../../classes/user/Subscription'

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
import props from './props'

export {props, validationsRules, defaultFormFields, Subscription}

import ComponentMixin from '../../../../mixins/components/complex'
import ProfileMixin from '../../../../mixins/profile'
import DynamicSectionsMixin from '../../../../mixins/dynamic/sections'
export const mixins = [ComponentMixin, ProfileMixin, DynamicSectionsMixin]

export const EventBus = window.VueEventBus

// data
const orders = []
const propDefs = props
export const data = {
  orders,
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  EventBus,
  Subscription
}
