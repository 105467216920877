export function getModules(requireContext) {
  return decorate(getGeneric(requireContext))
}

export function getDefaultModules(requireContext) {
  return getModules(requireContext).defaults()
}

export function getModulesKeyCustomized(requireContext, customizer) {
  return keyCustomizer(getModules(requireContext), customizer)
}

export function getModulesValueCustomized(requireContext, customizer) {
  return valueCustomizer(getModules(requireContext), customizer)
}

export function getModulesKeyValueCustomized(requireContext, keyCustomizer, valueCustomizer) {
  return kVCustomizer(getModules(requireContext), keyCustomizer, valueCustomizer)
}

export function getModulesWithContainersPath(requireContext, moreSanitizers = []) {
  return keyCustomizer(getModules(requireContext), containerNameSanitizer().concat(moreSanitizers))
}

export function getModulesWith(requireContext, customizer = items => items) {
  return getModules(requireContext).map(customizer)
}

export function getGeneric(requireContext) {
  return requireContext.keys().map(key => [key, requireContext(key)])
    .reduce((items, [key, resource]) => {
      items[key] = resource
      return items
    }, {})
}

export function kVCustomizer(items, keyCustomizer = key => key, valueCustomizer = item => item) {
  const newItems = Object.keys(items).reduce((newItems, key) => {
    const item = items[key]
    const newKey = sanitizeKey(keyCustomizer, item, key)
    const newValue = sanitizeValue(valueCustomizer, item, key)
    newItems[newKey] = newValue
    return newItems
  }, {})
  return decorate(newItems)
}

export function keyCustomizer(items, customizer = key => key) {
  const newItems = Object.keys(items).reduce((newItems, key) => {
    const item = items[key]
    const newKey = sanitizeKey(customizer, item, key)
    newItems[newKey] = item
    return newItems
  }, {})
  return decorate(newItems)
}

export function valueCustomizer(items, customizer = item => item) {
  const newItems = Object.keys(items).reduce((newItems, key) => {
    const newValue = sanitizeValue(customizer, items[key], key)
    newItems[key] = newValue
    return newItems
  }, {})
  return decorate(newItems)
}

export function decorate(object) {
  const merge = Object.assign
  const prop = Object.defineProperty
  const propDescriptor = {
    enumerable: false,
    configurable: false,
    writable: false
  }

  prop(object, 'keys', merge(propDescriptor, {value: () => Object.keys(object)}))
  prop(object, 'values', merge(propDescriptor, {value: () => Object.values(object)}))
  prop(object, 'defaults', merge(propDescriptor, {value: () => valueCustomizer(object, v => v.default || v)}))
  prop(object, 'map', merge(propDescriptor, {value: customizer => customizer(object)}))
  prop(object, 'mapKeys', merge(propDescriptor, {value: customizer => keyCustomizer(object, customizer)}))
  prop(object, 'mapValues', merge(propDescriptor, {value: customizer => valueCustomizer(object, customizer)}))
  prop(object, 'mapKeyValues', merge(propDescriptor, {value: (keyC, valueC) => kVCustomizer(object, keyC, valueC)}))

  return object
}

export const sanitizers = {
  'copy': str => str,
  'noResourcePath': str => str.replace(process.env.CONTAINERS_WEB_UI_ASSETS_JS_PATH, ''),
  'noPrefixSlash': str => str.replace(/^\/|^\.\//g, ''),
  'noExt': str => str.replace(/\..+?$/, ''),
  'noExtIntel': str => str.replace(/(\/index)?\..+?$/, ''),
  'lcase': str => str.toLowerCase(),
  'slashTo': (str, to = '') => str.replace(/\//g, to)
}

export function assignCustomizer(item) {
  if (typeof item === 'string') {
    return sanitizers[item] || sanitizers['copy']
  }
  if (typeof item === 'object') {
    const customizer = Object.keys(item)[0]
    const param = Object.values(item)[0]
    return function (key, value) {
      return (sanitizers[customizer] || sanitizers['copy'])(key, param)
    }
  }
  return item
}

export function sanitizeKey(customizers, value, key) {
  if (typeof customizers === 'function') {
    customizers = [customizers]
  }
  if (Array.isArray(customizers)) {
    customizers = customizers.map(assignCustomizer)
    customizers.forEach(customizer => {
      key = customizer(key)
    })
  }
  return key
}

export function sanitizeValue(customizers, value, key) {
  if (typeof customizers === 'function') {
    return customizers(value, key)
  }
  if (Array.isArray(customizers)) {
    customizers.forEach(customizer => {
      if (typeof customizer === 'function') {
        value = customizer(value, key)
      }
    })
  }
  return value
}

export function containerNameSanitizer() {
  return ['noResourcePath', 'noPrefixSlash', 'noExtIntel', 'lcase']
}

export default {
  getModules, getDefaultModules, getModulesWith, getModulesWithContainersPath,
  getModulesKeyCustomized, getModulesValueCustomized, getModulesKeyValueCustomized
}
