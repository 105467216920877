/* global _ */
import {
  name,
  mixins,
  props,
  data,
  defaultFormFields,
  validationsRules,
  EventBus,
  userFields,
  metaFields,
  defaultSections
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      eventSpace: 'user.profile.view-edit',
      showForm: false,
      updateCount: 0,
    }
  },
  computed: {
    maxDateTime() {
      return this.getNow().toISODate()
    },
    isBirthdayDisabled() {
      if (this.disableSavedBirthDay && this.user && this.user.meta) {
        const birthDate = this.parseDateTime(this.user.meta.birth_date)
        return birthDate && birthDate.isValid
      }
      return false
    }
  },
  watch: {
    userDataMeta: {
      deep: true,
      handler(value) {
        this.updateView()
        this.hydrateFields()
      }
    }
  },
  async created() {
    await this.initialAutoLoadCompleted()
    this.setupForm(validationsRules, defaultFormFields)
    this.updateView()
    if (this.showEdit) {
      await this.showEditor()
    }
    this.$on('success', () => {
      if (this.backToViewOnSave) {
        this.hideEditor()
      }
    })
  },

  methods: {
    updateView() {
      this.sections = this.parseDynamicSections(this.$$s('sections', defaultSections), this.userDataMeta)
    },
    async showEditor() {
      if (!this.isDesignerMode) {
        this.startPromising('')
        await this.pingUser()
        this.stopPromising()
      }
      this.showForm = true
      this.emit('editor.mounted', this)
    },
    hideEditor() {
      this.showForm = false
      this.emit('editor.unmounted', this)
    },
    hydrateFields(fields, fieldsKey = 'fields') {
      const user = this.user
      const meta = user.meta

      let birth_date = null
      const birthDate = this.parseDateTime(meta.birth_date)
      if (birthDate && birthDate.isValid) {
        birth_date = birthDate.setZone(this.TIMEZONE).toISO()
      }
      const values = {
        ..._.pick(user, userFields),
        ..._.pick(meta, metaFields),
        birth_date
      }

      const form = this[fieldsKey] || this
      _.forOwn(this.fields, (item, key) => {
        if (!_.isNil(values[key])) {
          form[key] = values[key]
        }
      })
    },

    async initProfileUpdate() {
      this.resetFieldsValidation()

      const payload = _.omit(_.cloneDeep(this.fields), [this.isBirthdayDisabled ? 'birth_date': ''])
      this.emit('update.before', payload)

      this.$v.$touch()
      this.formSubmitting = true

      if (!this.isAnyInvalid) {
        this.emit('update.init', payload)
        try {
          this.startPromising(this.formSubmitMessage)
          const [data] = await this.promise('user.update', this.user.id, payload)
          const user = data.data || {}
          this.updateUser({user})
          this.updateView()
          this.$emit('success', user)
          this.emit('update.success', user)
        } catch (e) {
          this.stopPromising()
          const fields = {username: true}
          this.showFormError(e, {'scrollTo': '.re-profile-edit-box', flattenTopMessages: true, fields})
          this.$emit('error', e)
          this.emit('update.error', e)
        } finally {
          this.stopPromising()
          this.emit('update.end', payload)
        }
      } else {
        this.scrollToFirstErrorElement()
      }
    }
  }
}
