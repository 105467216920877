import {fv} from '../../../lib/utils'
import {getNow} from '../../../lib/dates'
import {getCountries, getLocationsSync, getCountryStates, getGenders} from '../../../lib/formatters'

export const fields = {
  computed: {},
  methods: {},
}

export const getGenderFieldProperties = fields.methods.getGenderFieldProperties = function (name, config) {
  name = name || 'gender'
  config = config || {}

  const optionsPath = `${name}.options`
  const options = {
    m: this.getFieldLabel(`${optionsPath}.genderMale`, 'Male'),
    f: this.getFieldLabel(`${optionsPath}.genderFemale`, 'Female'),
  }
  if (this.isField(`${optionsPath}.genderOther`)) {
    options.o = this.getFieldLabel(`${optionsPath}.genderOther`, 'Other')
  }

  const defaults = {
    options,
    label: 'Gender'
  }
  config.defaults = _.merge({}, defaults, config.defaults || {})
  let props = this.getFieldProps(name, config)

  props.hydrate = fv(props.hydrate, {method: 'getGenders'})
  if (!_.isEmpty(props.hydrate)) {
    _.unset(props, 'options')
  } else {
    props.options = props.options || getGenders()
  }

  return props
}

export const getBirthdayFieldProperties = fields.methods.getBirthdayFieldProperties = function (name, options) {
  name = name || 'birth_date'
  options = options || {}

  const defaults = {
    label: 'Birthday',
    maxDatetime: getNow().toISODate(),
    showInputFields: this.withBirthDayInputs,
    inputFieldsOrder: this.birthdayInputsOrder,
    showPickerButton: this.withBirthDayInputButton
  }
  options.defaults = _.merge({}, defaults, options.defaults || {})

  let props = this.getFieldProps(name, options)

  return props
}

export const getCountryFieldProperties = fields.methods.getCountryFieldProperties = function (name, options) {
  name = name || 'country'
  options = options || {}
  const defaults = {
    label: 'Country',
    blankOption: this.getFieldLabel(name + '.options.default', 'Select Country')
  }
  options.defaults = _.merge({}, defaults, options.defaults || {})

  let props = this.getFieldProps(name, options)
  props.hydrate = fv(props.hydrate, {method: 'getCountries'})
  if (!_.isEmpty(props.hydrate)) {
    _.unset(props, 'options')
  } else {
    props.options = props.options || getCountries()
  }
  return props
}

export const getStateFieldProperties = fields.methods.getStateFieldProperties = function (name, country, options) {
  name = name || 'state'
  options = options || {}
  const defaults = {
    label: 'State',
    blankOption: this.getFieldLabel(name + '.options.default', 'Select State')
  }
  options.defaults = _.merge({}, defaults, options.defaults || {})

  let props = this.getFieldProps(name, options)
  props.hydrate = fv(props.hydrate, {method: 'getCountryStates', params: [country]})
  if (!_.isEmpty(props.hydrate)) {
    _.unset(props, 'options')
  } else {
    props.options = props.options || getCountryStates()
  }
  return props
}

export const getLocationFieldProperties = fields.methods.getLocationFieldProperties = function (name, options, fieldPath = 'fields') {
  name = name || 'location'
  options = options || {}
  options.defaults = (options.defaults || {})
  options.defaults.label = fv(options.defaults.label, 'Location')

  let props = this.getFieldProps(name, fieldPath, options)
  // props.hydrate = fv(props.hydrate, {method: 'getLocationsSync'})
  if (_.isEmpty(props.hydrate) && !props.options) {
    let locations = _.clone(getLocationsSync())
    if (options.allOptionLabel) {
      locations = {'': options.allOptionLabel, ...locations}
    }
    props.options = locations
  }
  return props
}

export const countryFieldValueChanged = fields.methods.countryFieldValueChanged = function (country, stateFieldPath = 'fields.state') {
  // debugLog({countryFieldValueChanged: country, stateFieldPath})
  if (_.has(this, stateFieldPath)) {
    _.set(this, stateFieldPath, '')
  }
}

export default fields
