export const name = 're-core-page-card-check-balance'
export const component = 're-card-balance'

import {factory} from '../../../../lib/page'

const props = {
  number: {},
  cvv: {},
}

const def = {
  created() {
    window.VueEventBus.$on('buy.card-reload.add-to-cart', () => {
      this.$router && this.$router.push({ name: 'cart' })
    })
  }
}
export default factory(name, {props, component, def})

