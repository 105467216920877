export const DEFAULT_PARSE_MAP = {
  $name: {
    valuePath: 'promotion.data.name',
  },
  $promotion: {
    value: '{[promotion.data]}'
  },
  $promotable: {
    value: '{[promotable.data]}'
  },
  $expiringIn: {
    value: '{[expire_at]}',
    format: [
      ['formatDate', [{method: 'toRelative', params: [{style: 'long'}]}]],
      ['The reward will expire {{value}}.', [], true]
    ],
    skipNil: true,
    defaultValue: '',
  },
  $failingLocations: {
    value: '{[failingLocations]}',
    format: [
      ['join', [', ']]
    ],
    skipNil: true,
    defaultValue: '',
  },
  $failingLocationsLabel: {
    value: 'location|locations',
    defaultValue: 'location|locations',
    mergeData: {
      count: {
        value: '{[failingLocations.length]}'
      }
    },
    parseParams: true,
    format: [['$$tc', ['{{value}}', '{[count]}']]],
  },
  $failingLocationsBe: {
    value: 'is|are',
    defaultValue: 'is|are',
    mergeData: {
      count: {
        value: '{[failingLocations.length]}'
      }
    },
    parseParams: true,
    format: [['$$tc', ['{{value}}', '{[count]}']]],
  }
}

export const DEFAULT_TITLES = 'Your {{$name}} promotion reward is claimed!'

export const DEFAULT_SUBTITLES = ''

export const DEFAULT_MESSAGES = {
  'default': `The promotion has been applied to your {{$cardName}}{{$playcards}}.
      {{br}}
      {{$expiringIn}}`
}

export const DEFAULT_ERROR_TITLES = {
  'default': `Oops! Something went wrong`
}

export const DEFAULT_ERROR_MESSAGES = {
  'default': `Failed to apply the promotion. Please try again after some time.`
}
