/*global _ */
const EventBus = window.VueEventBus
import F, {mask, getFirst} from '../../../../../lib/formatters'
import {getFirstErrorMessage} from '../../../../../services/main'
import ConfigMixin from '../../../../../mixins/config'
import ComponentMixin from '../../../../../mixins/components/complex'
import CardLocationFailingMixin from '../../../../../mixins/shop/card/failing-location'
import contextProps from '../../../../../props/context'

export default {
  name: 're-card-item',
  mixins: [
    ConfigMixin,
    ComponentMixin,
    CardLocationFailingMixin
  ],
  props: {
    ...contextProps,
    translations: {},
    settings: {},
    purchaseLink: {},
    reloadRoute: {
      type: [String, Array, Object],
      default: () => ({name: 'playcard.reload'})
    },
    reloadType: {
      type: String,
      default: null
    },

    card: {
      type: Object,
      default: null
    },
    cardOptions: {
      type: Object,
      default() {
        const $t = this.$options.$$t('options', {})
        const $s = this.$options.$$s('options', {})
        return _.merge({}, $t, $s)
      }
    },
    cardType: {
      type: String,
      required: true,
    },
    showImage: {
      type: [Boolean, Number, String],
      default: true
    },
    imageClass: {
      type: [String, Array, Object],
      default: null
    },
    modalClasses: {
      type: [String, Array, Object],
      default: null
    },
    archiveModalTitle: {
      type: String,
      default: 'Do you want to archive your Card?'
    },
    archiveSuccessModalTitle: {
      type: String,
      default: 'Your Gift Card has been archived.'
    },
    reloadCardLinkLabel: {
      type: String,
      default: null
    },

    emptyCardMessage: {
      type: String,
      default: 'No Cards have been associated to this account. To add a Cards click the button below.'
    }
  },

  data() {
    return {
      canAutoLoadLocations: false, // disable auto loading of location from this component
      selectedCardProperty: 'card',

      isPrimaring: false,
      isResending: false,
      isArchiving: false,
      isClaiming: false,

      errorMessage: null,
      addingErrorMessage: null,
      archivingErrorMessage: null,
      resendErrorMessage: null,
      claimErrorMessage: null,
      recoveringErrorMessage: null,
      checkingErrorMessage: null,

      showArchiveModal: false,

      showGiftcardResentModal: false,
      showPrimarySetModal: false,

      showClaimModal: false,
      showClaimSuccessModal: false,

      isCheckingBalance: false,
      showBalanceModal: false,
      fetchedCard: null
    }
  },

  computed: {
    selectedCard() {
      return {
        ...this.card,
        isLocationFailing: this.isSelectedCardLocationFailing
      }
    },
    customComponent() {
      return this.cardOptions.component
    },
    customComponentAttrs() {
      const attrs = this.cardOptions.componentAttrs || {}
      return {
        context: this,
        value: this.selectedCard,
        ...attrs
      }
    },
    customComponentListeners() {
      return this.cardOptions.componentListeners || {}
    },
    BALANCE() {
      return this.$$t('balance.label', "Balance")//@added:
    },
    TICKETS() {
      return this.$$t('tickets.label', "Tickets")//@added:
    },
    BONUS() {
      return this.$$t('bonus.label', "Bonus")//@added:
    },
    POINTS() {
      return this.$$t('points.label', "Points")//@added:
    },
    CARDNUMBER() {
      return this.$$t('number.label', "Card number")//@added:
    },
    FORGOTCODE() {
      return this.$$t('forgotCode.title', "Forgot Code")//@added:
    },
    CLAIM() {
      return this.$$t('claim.title', "Claim")//@added:
    },
    CLAIMMYSELF() {
      return this.$$t('claimself.title', "Claim for myself")//@added:
    },
    ARCHIVE() {
      return this.$$t('archive.title', "Archive")//@added:
    },
    RELOAD() {
      return this.reloadCardLinkLabel || this.$$t('reload.title', "Reload card")//@added:
    },
    primaryCardStatus() {
      return this.$$t('primaryCard.status.label', "Primary Card")//@added:
    },
    resendGiftCardTitle() {
      return this.$$t('resend.title', " Resend gift card") //@added:
    },

    isGiftcard() {
      return this.cardType === 'giftcard'
    },
    isPlaycard() {
      return this.cardType === 'playcard'
    },

    resendEmail() {
      return _.get(this.selectedCard, 'recipient.data.email')
    },

    cardImage() {
      return _.get(this.selectedCard, 'media.image')
    },
    cardStatus() {
      return this.selectedCard.state === 'claimed' && !this.selectedCard.is_virtual ?
        this.$$t('status.claimed', 'Claimed') :
        this.$$t(`status.${this.selectedCard.state}`, 'Unclaimed')
    },
    cardNumber() {
      let number = ''
      if (this.isShowNumberMasked) {
        return this.selectedCard.maskedNumber || mask(this.selectedCard.number, this.$$t('mask'))
      }
      if (this.isShowNumber) {
        number = ('' + this.selectedCard.number).replace(/(\d{4})/g, '$1 ')
      }
      return number
    },
    cardBalance() {
      return this.isPlaycard ?
        Number(this.selectedCard.cash)  :  this.selectedCard.balance
    },
    isShowStatus() {
      return this.can('showStatus')
    },
    isShowBalance() {
      return this.can('showBalance')
    },
    isShowTickets() {
      return this.can('showTicket')
    },
    isShowPoints() {
      return this.can('showPoints')
    },
    isShowBonus() {
      return this.can('showBonus')
    },
    isPrimaryCard() {
      return this.selectedCard.is_primary || false
    },
    isShowNumber() {
      return this.can('showNumber')
    },
    isShowNumberMasked() {
      return this.can('showNumberMasked')
    },
    isShowRecipient() {
      return this.can('showRecipient')
    },
    isShowGiver() {
      return this.can('showGiver')
    },

    isShowRecover() {
      return this.can('recoverCode')
    },
    allowSetPrimary() {
      return this.can('setPrimary')
    },
    showClaimButton() {
      return this.can('claim') && !this.can('claimForMyself')
    },
    isShowClaimMyselfButton() {
      return this.can('claimForMyself')
    },
    isShowResendButton() {
      return this.can('resend')
    },
    isShowArchiveButton() {
      return this.can('archive')
    },

    allowReload() {
      return this.can('reload')
    },

    reloadCardPath() {
      let route = this.reloadRoute
      if (_.isString(route)) {
        route = {name: route}
      }
      route.query = {
        card: this.selectedCard.id,
        number: this.selectedCard.number,
        type: this.reloadType,
        owner: true,
      }
      return route
    }
  },
  watch: {
    allLocationDetails: {
      deep: true,
      handler() {

      }
    }
  },
  created() {
    this.registerEventListeners()
  },

  methods: {
    registerEventListeners() {
    },
    can(what, defaultValue = null) {
      const props = this.selectedCard.$can || {}
      return this.$$fv(props[what], defaultValue)
    },
    async act(what, ...args) {
      const actions = this.selectedCard.$actions || {}
      const action = actions[what]
      if (action) {
        return await action(...args)
      }
    },
    hasAnyError() {
      return (
        this.resendErrorMessage ||
        this.recoveringErrorMessage ||
        this.checkingErrorMessage ||
        this.errorMessage
      )
    },
    async showError(error, title) {
      this.errorMessage = error || this.hasAnyError()
      this.errorAlertAsync(this.errorMessage, title)
      this.errorMessage = ''
    },
    hideError() {
      this.errorMessage = ''
      this.resendErrorMessage = ''
      this.addingErrorMessage = ''
      this.archivingErrorMessage = ''
      this.claimErrorMessage = ''
      this.recoveringErrorMessage = ''
      this.checkingErrorMessage = ''
    },
    async validateArchive() {
      const validator = _.get(this.cardOptions, 'archive.validate')
      if (validator) {
        const fields = validator.fields || ['balance']
        const rule = validator.rule || 'isZero'
        let isValid = _.every(fields, (field) => {
          const value = _.get(this.selectedCard, field)
          if (_.isFunction(rule)) {
            return rule(value)
          }
          const method = getFirst(rule, this, this.context, F, _, window)
          if (_.isFunction(method)) {
            return method(value)
          }
          return true
        })
        if (!isValid) {
          const errorConfig = validator.error || {}
          const confirmer = errorConfig.confirm || {}
          const message = confirmer.message || errorConfig.message
          const title = confirmer.title || errorConfig.title
          if (!_.isEmpty(confirmer)) {
            const options = confirmer.options
            const isConfirm = await this.confirmAsync(message, title, options)
            if (isConfirm) {
              const eventName = confirmer.event
              this.emit(eventName, this.selectedCard, this)
            }
          } else {
            this.alertAsync(message, title)
          }
          return false
        }
      }
      return true
    },
    async initArchive() {
      const isValid = await this.validateArchive()
      if (!isValid) {
        return
      }
      let confirmation
      EventBus.$emit('card.before.archive')
      const action = _.get(this.cardOptions, 'archive.discard') ? 'discard' : 'archive'
      if (_.get(this.cardOptions, 'archive.confirmer')) {
        if (!confirmation) {
          confirmation = await this.confirmAsync(
            this.$$t('archive.question', `Do you want to ${action} your card?`),
            this.$$t('archive.title', null),
            this.$$s('archive.confirmer.options', {})
          )
        }
        if (confirmation) {
          this.startProcessing()
          await this.archive()
          this.stopProcessing()
        }
      } else {
        this.showArchiveModal = true
      }
    },
    closeArchiveModal() {
      this.showArchiveModal = false
    },
    async archive() {
      this.isArchiving = true
      this.archivingErrorMessage = ''
      const action = _.get(this.cardOptions, 'archive.discard') ? 'discard' : 'archive'
       try {
        await this.act(action, this.selectedCard)
        this.showArchiveModal = false
        this.isArchiving = false
      } catch (e) {
        this.isArchiving = false
        const message = getFirstErrorMessage(
          e,
          this.$$t('archive.error.message',  `Unable to ${action} card.`)
        )
        if (_.get(this.cardOptions, 'archive.confirmer')) {
          await this.showError(message, this.$$t('archive.errorTitle', null))
        } else {
          this.archivingErrorMessage = message
        }
      }
    },

    async resend() {
      this.isResending = true
      try {
        await this.act('resend', this.selectedCard)
        this.showGiftcardResentModal = true
        this.isResending = false
      } catch (e) {
        this.isResending = false
        await this.showError(getFirstErrorMessage(
            e,
            this.$$t('resent.error.message',  'Error resending card code.')
          )
        )
      }
    },

    async initClaim() {
      await this.claimCard()
      //this.showClaimModal = true
    },

    async claimCard() {
      this.isClaiming = true
      try {
        await this.act('claim', this.selectedCard)
        this.showClaimModal = false
        this.showClaimSuccessModal = true
      } catch (e) {
         this.claimErrorMessage = getFirstErrorMessage(
          e,
          this.$$t('claim.error.message',  'Error claiming card.')
         )
      }
      this.isClaiming = false
    },

    async setPrimaryCard() {
      this.isPrimaring = true
      try {
        await this.act('primary', this.selectedCard)
        if (!_.get(this.cardOptions, 'setPrimary.hideConfirmation')) {
          this.showPrimarySetModal = true
        }
        this.isPrimaring = false
       } catch (e) {
        this.isPrimaring = false
        await this.showError(getFirstErrorMessage(
            e,
            this.$$t('setPrimary.error.message',  'Error setting card as primary.')
          )
        )
      }
    },

    async updateFamilyMember(id) {
      const processingMessage = this.$$t(
        'assignCard.processing.message',
        'Assigning card to member.'
      )
      this.startProcessing(processingMessage)
      try {
        await this.act('updateFamilyMember', id)
        this.stopProcessing()
       } catch (e) {
        this.stopProcessing()
        const errorMessage = this.$$t(
          'assignCard.error.message',
          'Error while assigning card to member.'
        )
        await this.showError(getFirstErrorMessage(e, errorMessage))
      }
    },
  },
}
