export const name = 're-profile-view-edit'

export const defaultSections = {
  'profile': {
    items: {
      phone: {
        title: 'Phone',
        valuePath: 'user.meta.phone',
      },
      email: {
        title: 'Email',
        valuePath: 'user.email',
      },
      address: {
        title: 'Address',
        valuePath: 'user.meta.address',
      },
      country: {
        title: 'Country',
        valuePath: 'user.meta.country',
      },
    }
  }
}

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields, userFields, metaFields} from './form'
import props from './props'

export {props, validationsRules, defaultFormFields, userFields, metaFields}

import ComponentMixin from '../../../../mixins/components/complex'
import ProfileMixin from '../../../../mixins/profile'
import FormMixin from '../../../../mixins/form'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'
import DynamicSectionsMixin from '../../../../mixins/dynamic/sections'
export const mixins = [ComponentMixin, FormMixin, FieldsMixin, ProfileMixin, DynamicSectionsMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  sections: defaultSections,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  userFields,
  metaFields,

  EventBus,
  defaultSections
}
