/*global _ */
import {
  nil,
  notEmpty,
  empty,
  notNil,
  hasSome,
  templify,
  map,
  fv,
  getFirst
} from '../../../lib/formatters'
import MashupBuilderReactiveMixin from '../../../mixins/mashup-builder/reactives'
import ComponentMixin from '../../../mixins/component-base'
import LanguageMixin from '../../../mixins/lang'
import SettingsMixin from '../../../mixins/settings'
import contextProps from '../../../props/context'

const mixins = [ComponentMixin, LanguageMixin, SettingsMixin, MashupBuilderReactiveMixin]

export default {
  name: 're-content-sections',
  mixins,
  props: {
    ...contextProps,
    settings: {},
    translations: {},
    sections: {
      type: [Array, Object],
      default() {
        const $t = this.$options.$$t('sections', {})
        const $s = this.$options.$$s('sections', {})
        return _.merge({}, $t, $s)
      }
    },
    component: {
      type: String,
      default() {
        return this.$options.$$s('sectionsComponent', 're-group-box')
      },
    },
    itemComponent: {
      type: String,
      default() {
        return this.$options.$$s('sectionsItemComponent', 're-blank-component')
      }
    },
    parseData: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('sectionsParseData', false)
      }
    },
    options: {
      type: [Object],
      default() {
        const $t = this.$options.$$t('sectionsOptions', {})
        const $s = this.$options.$$s('sectionsOptions', {})
        return _.merge({}, $t, $s)
      }
    },
    classes: {
      default() {
        return this.$options.$$s('sectionsClasses', '')
      }
    },
    data: {}, //@deprecated: use `value` instead
    value: {
      default() {
        return this.$options.$$s('sectionsData', null)
      }
    }
  },
  data() {
    return {
      nil,
      notNil,
      empty,
      notEmpty,
      sectionsData: {},
      dataSource: fv(this.value, this.data)
    }
  },
  computed: {
    sectionComponent() {
      return this.component || 're-blank-component'
    },
    sectionItemComponent() {
      return this.itemComponent || 're-blank-component'
    },
    isGroupBox() {
      return this.sectionComponent === 're-group-box'
    },
  },
  watch: {
    value: {
      handler(v) {
        this.dataSource = v
        this.reload()
      },
      deep: true
    },
    data: {
      handler(v) {
        this.dataSource = v
        this.reload()
      },
      deep: true
    },
    sections: {
      handler(v) {
        this.reload(v)
      },
      deep: true
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload(data) {
      this.sectionsData = this.prepare(data || this.sections)
    },
    prepare(sections) {
      return map(sections, (section, sectionKey) => {
        const titleSection = {
          ...section,
          parseData: section.parseTitle
        }
        const key = `sections.${sectionKey}`
        return {
          classes: [
            section.classes,
            this.parseAttrClasses(section, _.kebabCase(key))
          ],
          showTitle: section.showTitle,
          ...section,

          key,
          show: this.showSection(section),
          component: this.getSectionComponent(section),
          componentAttrs: this.getComponentAttr(section),
          listeners: this.getComponentListeners(section),
          title: this.parseText(section.title, section),
          showBoxTitle: !this.isGroupBox && fv(section.showTitle, section.title),
          boxTitle: this.parseText(section.title, {}, section),
          showBoxSubTitle: fv(section.showSubTitle, section.subTitle),
          boxSubTitle: this.parseText(section.subTitle, section),
          boxSubTitleClasses: {'re-group-box-subtitle' : this.isGroupBox},
          showBoxNotice: fv(section.showNotice, section.notice),
          boxNotice: this.parseText(section.notice, titleSection),

          items: this.prepareItems(section, sectionKey),
          actions: this.prepareActions(section, sectionKey),
        }
      })
    },
    prepareItems(section, sectionKey) {
      if (!section.items) {
        return {}
      }
      let items = map(section.items, (item, itemKey) => {
        const titleSection = {
          ...section,
          parseData: section.parseTitle
        }
        const titleItem = {
          ...item,
          parseData: item.parseTitle
        }
        const key =`${sectionKey}.items.${itemKey}`
        return {
          prefix: '',
          suffix: '',
          updating: null,
          defaultValue: '',
          valueComponent: null,
          dynamic: item.dynamic,
          ...item,

          key,
          itemKey,
          show: this.showItem(item, section),
          component: this.getItemComponent(item, section),
          componentAttrs: this.getComponentAttr(item, section),
          listeners: this.getComponentListeners(item),
          classes: [
            item.classes || [],
            item.dynamic ? 're-section-dynamic-item': 're-section-item',
            this.parseAttrClasses(item, _.kebabCase(key))
          ],
          title: this.parseText(item.title, titleSection, titleItem),
          showTitle: this.showItemTitle(item, section),

          ...this.prepareValueComponent(item, section , key)
        }
      })

      return map(items, (item, key) => {
        const value = item.value = this.parseValue(item, section)
        item.showValue = this.showValue(value, item, section)
        return item
      })
    },
    prepareValueComponent(item, section, key) {
      const valueComponentKey = `${key}.value`
      const valueItem = {
        ...item
      }
      if (item.valueComponentAttrs) {
        valueItem.componentAttrs =  item.valueComponentAttrs
      }
      if (item.valueComponentParseAttrs) {
        valueItem.componentParseAttrs = item.valueComponentParseAttrs
      }
      if (item.valueComponentOn) {
        valueItem.on = item.valueComponentOn
      }

      return {
          valueComponentAttrs: this.getComponentAttr(valueItem, section),
          valueComponentListeners: this.getComponentListeners(valueItem),
          valueComponentKey,
      }
    },
    prepareActions(section, sectionKey) {
      if (!section.actions) {
        return null
      }
      return map(section.actions, (action, actionKey) => {
        const key = `${sectionKey}.actions.${actionKey}`
        return {
          classes: [
            action.classes,
            this.parseAttrClasses(action, _.kebabCase(key))
          ],
          ...action,
          key,
          component: this.getActionComponent(action, section),
          componentAttrs: this.getComponentAttr(action),
          listeners: this.getComponentListeners(action),
        }
      })
    },
    getComponentAttr(item, section) {
      item = item || {}
      section = section || {}
      let componentAttrs = item.componentAttrs
      let componentParseAttrs = item.componentParseAttrs
      if (componentParseAttrs) {
        componentParseAttrs = this.parseDynamicAttrs(componentParseAttrs, item, section)
        componentAttrs = _.merge(
          {},
          componentAttrs || {},
          componentParseAttrs || {}
        )
      }

      const skip = ['type', 'classes', 'component', 'componentParseAttrs', 'componentAttrs']
      const allAttrs = _.omitBy(item, (i, k) => {
        return /^[[$@]/.test(k) || _.includes(skip, k)
      })

      return componentAttrs || allAttrs
    },
    getComponentListeners(item, type) {
      let listeners = {
        ...(item.listeners || item.on || {}),
        ..._.pickBy(item, (i, k) => _.startsWith(k, '@'))
      }

      //const event = item.event || key
      listeners = _.mapValues(listeners, (listener) => {
        if (_.isObject(listener)) {
          listener = listener.handler
        }
        if (_.isString(listener)) {
          listener = listener.replace(/^@/, '')
          listener = getFirst(listener, this, this.context)
        }
        return listener
      })

      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      if (type) {
        return listeners[type]
      }
      return listeners
    },

    getSectionComponent(section) {
      return section.component || this.sectionComponent
    },
    sectionOptions(section) {
      return _.omit(section, ['items'])
    },
    getItemsOrdered(section) {
      const items = section.items
      let order = section.itemOrder || _.keys(items)
      if (_.isString(order)) {
        order = order.replace(/\s/g, '').split(',')
      }
      return _.filter(order.map(itemKey => items[itemKey]))
    },
    getItemComponent(item, section) {
      return item.component || section.itemComponent || this.itemComponent
    },

    showSection(section) {
      let show = this.$$fv(section.show, null)
      if (_.isBoolean(show)) {
        return show
      }

      const hide = section.hide
      if (_.isObject(hide) && hide['if']) {
        return !this.probeIf(hide['if'], section, {}, section)
      }

      if (_.isObject(show) && show['if']) {
        return this.probeIf(show['if'], section, {}, section)
      }
      return true
    },
    showItem(item, section = {}) {
      let show = this.$$fv(item.show, empty(item) ? section.show : section.showItems, null)
      if (_.isBoolean(show)) {
        return show
      }

      const hide = item.hide
      if (_.isObject(hide) && hide['if']) {
        show = !this.probeIf(hide['if'], null, item, section, true)
        return show
      }

      if (_.isObject(show) && show['if']) {
        show = this.probeIf(show['if'], null, item, section, true)
        return show
      }

      if (nil(show)) {
        if(!this.hasValue(item)) {
          return true
        }
      }

      const value = this.parseValue(item, section, true)
      const hideBlank = this.$$fv(item.hideBlank, section.hideBlankItem)
      const hideZero = this.$$fv(item.hideZero, section.hideZeroItem)
      if ((hideBlank && nil(value)) || (hideZero && /^0(.00)?$/.test(value))) {
        return false
      }

      show = true
      return show
    },
    showItemTitle(item, section) {
      return this.$$fv(item.showTitle, section.showItemTitle)
    },

    getActionComponent(action, section) {
      const component = action.component || ('re-' + (action.type || 'button'))
      return component
    },

    needsParsing(item, section) {
      return this.$$fv(item.parseData, section.parseData, this.parseData)
    },
    hasValue(item) {
      return hasSome(item, ['value', 'valuePath', 'method', 'promise'])
    },

    parseText(value, section = {}, item = {}) {
      const isParse = this.needsParsing(item, section)
      if (isParse) {
        const data = this.parseSourceData(item.data || section.data || this.data || {}, value)
        value = templify(value, data)
      }
      return value
    },
    updateItemValue(value, item, section, index) {
      if (!_.isNil(index)) {
        item.values[index] = value
      } else {
        item.values = [value]
      }
      item.value = item.values
      item.showValue = this.showValue(value, item, section)
      item.updating = false
    },
    parseValue(item, section = {}, getRaw, thisValue) {
      // const debug = true
      let value = this.$$fv(thisValue, item.value, item.values)
      // if (debug) {
      //   debugLog({parseValue: item, section, getRaw, thisValue})
      //   debugLog({firstValue: value})
      // }
      const isParse = this.needsParsing(item, section)
      // if (debug) {
      //   debugLog({isParse})
      // }
      if (isParse) {
        const data = this.parseSourceData(this.getDataSource(item, section), value)
        // if (debug) {
        //   debugLog({parseSource: data, item, thisValue, value})
        // }
        if (getRaw) {
          item = _.omit(item, ['format'])
        }
        value = this.parseReactiveValues(item, data)
        // if (debug) {
        //   debugLog({parsedValue: value})
        // }

        if (!getRaw && !_.isObjectLike(value)) {
          value = _.castArray(value)
        }
      }
      // if (debug) {
      //   debugLog({finalValue: value})
      // }
      if (value instanceof Promise) {
        item.updating = true
        value.then((val) => {
          this.updateItemValue(val, item, section)
        })
        return ''
      }
      if (_.isArray(value)) {
        value = _.map(value, (v, i) => {
          if (v instanceof Promise) {
            item.updating = true
            v.then((val) => {
              this.updateItemValue(val, item, section, i)
            })
            return ''
          }
          return v
        })
        return value
      }
      return value
    },
    async getFinalValue(value) {
      if (value instanceof Promise) {
        return await value
      }
      return value
    },
    parseDefaultValue(item, section = {}, getRaw) {
      const defaultItem = {value: item.defaultValue, parseData: this.$$fv(item.parseDefault, false)}
      const defaultValue = this.parseValue(defaultItem, section, getRaw)
      return defaultValue
    },

    showValue(value, item, section) {
      return notNil(value)
    },
    showDefaultValue(value, item, section) {
      return !this.showValue(value, item, section) && notNil(item.defaultValue)
    },

    probeIf(def, value, item, section, isParseValue) {
        if (isParseValue) {
          value = this.parseValue(item, section, true)
        }
        const data = this.parseSourceData(this.getDataSource(item, section), value)
        return this.probeReactiveIf(def, data)
    },

    getDataSource(item, section) {
      item = item || {}
      section = section || {}
      return fv(item.data, section.data, this.dataSource, {})
    },
    parseSourceData(data, value) {
      if (_.isFunction(data)) {
        data = data() || {}
      }
      return {
        ...data,
        '$': value,
        '$data': data,
        '$context': this.context || this.$parent,
        '$sections': this.sections,
      }
    },
    parseDynamicAttrs(attrs, item = {}, section = {}) {
      const values = _.mapValues(attrs, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def, item, section)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.parseSourceData(this.getDataSource(item, section))
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    getComponentExtraClasses() {
      const options = this.options || {}
      let classes = options.classes || {}
      if (_.isObject(classes) && !_.isArray(classes)) {
        classes = this.parseDynamicAttrs(classes)
      }
      return [
        classes
      ]
    }
  },
}
