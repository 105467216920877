<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Get started absolutely free
      .b-central__subtitle Free forever. No credit card needed.

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        form-group(:validator="$v.email" label="Work email address:" attribute="email" v-slot="props" :messages="{ backendError }")
          input(
            v-model="$v.email.$model"
            :class="props.classes"
            type="email"
            placeholder="Your work email address"
            @input="input"
          )

      .b-central__footer.b-central__footer--wide-btn.b-central__footer--before-divider
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$dirty && $v.$invalid") Sign up

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        .b-central__divider
          span or

      .b-central__footer.b-central__footer--wide-btn.m-b-15
        button.btn.btn-almost-sm.btn-primary.btn-facebook(@click.prevent="social('facebook')") Sign up with Facebook
          img.btn-icon-left(:src="`${config.baseUri}/images/client/saas-onboarding/facebook-icon.svg`")

      .b-central__footer.b-central__footer--wide-btn
        button.btn.btn-almost-sm.btn-primary.btn-google(@click.prevent="social('google')") Sign up with Google
          img.btn-icon-left(:src="`${config.baseUri}/images/client/saas-onboarding/google-icon.svg`")

      .b-central__message.text-danger.m-t-25(v-if="socialBackendError") {{ socialBackendError }}

</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    backendError: null,
    socialBackendError: null,
    submitting: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        let { data } = await axios.post(`${this.config.baseUri}/api/onboarding/email-verification/send`, {
          email: this.email
        })
        this.$router.push({ name: 'onboarding-email-verification', query: { email: this.email } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    async social(provider) {
      try {
        let response = await this.$auth.authenticate(provider)

        let { data } = await this.$http.post(`${this.config.baseUri}/api/onboarding/social/${provider}`, response)

        this.$router.push({ name: 'onboarding-register', query: { token: data.token, email: data.email } })
      } catch (e) {
        this.socialBackendError = getFirstErrorMessage(e)
      }
    },

    input() {
      this.backendError = null
      this.socialBackendError = null
    }
  },
}
</script>
