import {Vue} from '~/addiesaas'
//old
import Button from './buttons/Button'
Vue.component(Button.name, Button)

import ReloadRouterLink from './buttons/ReloadRouterLink'
Vue.component(ReloadRouterLink.name, ReloadRouterLink)

import ReButton from './buttons/re-button'
Vue.component(ReButton.name, ReButton)

import ReLink from './buttons/re-link'
Vue.component(ReLink.name, ReLink)

import ReSocialAuthButton from './buttons/re-social-auth-button'
Vue.component(ReSocialAuthButton.name, ReSocialAuthButton)

import ReCopyButton from './buttons/re-copy-button'
Vue.component(ReCopyButton.name, ReCopyButton)

import formComponents from './form'

export default {
  Button,
  ReloadRouterLink,
  ReButton,
  ReSocialAuthButton,
  ReCopyButton,
  ReLink,
  ...formComponents
}
