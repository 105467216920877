/*global _ */
import ComponentMixin from '../../../mixins/components/complex'
import BaseInputMixin from '../../../mixins/form/base-input'
import Countdown from '../../../classes/utils/timer/Countdown'
import {getTime} from '../../../lib/dates'
import {seconds2Hours} from '../../../lib/formatters'

const mixins = [ComponentMixin, BaseInputMixin]

const trimZeroHours = (v) => {
  return _.trimStart(_.trimStart(v, '0'), ':')
}

export default {
  name: 're-countdown',
  mixins,
  props: {
    duration: {},
    message: {},
    timeFormat: {
      default: 'auto'
    },
    classes: {},
    component: {},
    getElapsedAsModel: {}
  },
  data() {
    return {
      timer: null,
      elapsed: null,
      remaining: null,
      completed: false,
    }
  },

  computed: {
    countdownComponent() {
      return this.component || 'div'
    },
    timerDuration() {
      return this.duration
    },
    formattedMessage() {
      if (this.remainingTimeFormatted) {
        return (this.message || '').replace('{time}', this.remainingTimeFormatted)
      }
      return ''
    },
    remainingTimeFormatted() {
      const value = this.remaining
      if (_.isNil(value)) {
        return ''
      }
      const format = this.timeFormat || 'auto'
      if (format === 'auto') {
        return trimZeroHours(seconds2Hours(value))
      }
      return getTime(value * 1000, format)
    },
    slotAttrs() {
      return {
        timer: this.timer,
        remaining: this.remaining,
        elapsed: this.elapsed,
        completed: this.completed,
        duration: this.timerDuration,
        formattedMessage: this.formattedMessage,
        remainingTimeFormatted: this.remainingTimeFormatted,
        message: this.message,
        classes: this.classes,
        timeFormat: this.timeFormat,
      }
    }
  },

  created() {
    this.init()
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    stop() {
      if (this.timer) {
        this.timer.reset()
      }
    },
    onTimerEvent(name, ...args) {
      this.$emit(name, ...[...args, this])
      if (['start', 'tick'].includes(name)) {
        this.remaining = args[0]
        this.elapsed = args[1]
        if (this.getElapsedAsModel) {
          this.model = this.elapsed
        } else {
          this.model = this.remaining
        }
      }
    },
    init() {
      this.stop()
      const timer = this.timer = new Countdown(this.timerDuration, this)
      timer.onTimeout(async (obj) => {
        this.completed = true
        this.remaining = 0
        await this.$nextTick()
        this.$emit('complete', obj, this)
      })
    }
  }
}
