<template lang="pug">
  .b-onboarding__right
    .b-onboarding__title Where would you like to start?
    .b-onboarding__subtitle This is just to get you started off,  if you have multiple locations but want to start with a single location you can add more locations at any time.

    .b-start-blocks
      template(v-for="item in options.startBlocks")
        .b-start-blocks__block(@click="select(item)" :class="{ 'b-start-blocks__block--selected': (onboarding.start == item.key) }")
          .b-start-blocks__block-icon
            img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/blocks/${item.icon}`")
          .b-start-blocks__block-label {{ item.label }}
          .b-start-blocks__block-text {{ item.text }}

    onboarding-back-and-forth(:disabled="!onboarding.start")

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    async select(item) {
      this.update({ start: item.key })
    }
  }
}
</script>
