/*global _ */
const EventBus = window.VueEventBus
import * as types from '../mutation-types/shop'
import {RE} from '../../lib/config'
import {notNils, pluck} from '../../lib/formatters'

// set this true to namespace all getters, mutations, actions etc.
const namespaced = true
export const namespace = 'Shop'
const rootNamespace = 'REUtils'
export const fullNamespace = [rootNamespace, namespace].join('/')
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

// state
const state = {
  location_id: null,
  pagination: {
    enabled: false,
    limit: null,
    page: null,
    init: false,
    result: {},
  },
  controller: null,
  loader: {
    enabled: false,
    paginate: false,
    limit: null,
    page: null,
    order: null,
    orderBy: null,
    filters: null,
    search: null,
    category: null,
    type: null,
  },
  products: [],
}

// mutations
const mutations = {
  [types.SET_SHOP_PRODUCTS](state, products) {
    state.products = products
  },
  [types.SET_SHOP_PAGINATION](state, pagination) {
    state.pagination = pagination
  },
  [types.SET_SHOP_PAGINATION_PROPERTY](state, item) {
    state.pagination = {...state.pagination, ...item}
  },
  [types.SET_SHOP_LOADER](state, loader) {
    state.loader = loader
  },
  [types.SET_SHOP_LOADER_PROPERTY](state, item) {
    state.loader = {...state.loader, ...item}
  },
  [types.SET_SHOP_LOADER](state, loader) {
    state.loader = loader
  },
  [types.SET_SHOP_CONTROLLER](state, controller) {
    state.controller = controller
  },
  [types.SET_SHOP_LOCATION](state, id) {
    state.location_id = id
    EventBus.$emit('shop.location_id.changed', id)
  }
}

const actions = {
  setShopProducts({commit}, payload) {
    commit(types.SET_SHOP_PRODUCTS, payload)
  },
  setShopPagination({commit}, payload) {
    commit(types.SET_SHOP_PAGINATION, payload)
  },
  setShopPaginationProperty({commit}, payload) {
    commit(types.SET_SHOP_PAGINATION_PROPERTY, payload)
  },
  setShopProductsLoader({commit}, payload) {
    commit(types.SET_SHOP_LOADER, payload)
  },
  setShopProductsLoaderProperty({commit}, payload) {
    commit(types.SET_SHOP_LOADER_PROPERTY, payload)
  },
  setShopProductsController({commit}, payload) {
    commit(types.SET_SHOP_CONTROLLER, payload)
  },
  setShopLocationId({commit}, payload) {
    commit(types.SET_SHOP_LOCATION, payload)
  }
}

// getters
const getters = {
  shopLocationId: state => state.location_id,
  shopProductsPagination: state => state.pagination,
  shopProducts: state => state.products,
  shopProductsLoaderProperties: state => state.loader,

}

const loadProducts = _.debounce(async function (payload, {dispatch}) {
  const [data] = await state.controller('products.read', payload)
  const result = _.omit(data, ['data'])
  dispatch('setShopPaginationProperty', {result})

  const allProducts = RE.products || (RE.products = {})
  const types = allProducts.types || (allProducts.types = {})
  const {type} = payload
  const products = types[type] = pluck(data.data, 'data')
  dispatch('setShopProducts', products)

}, 250)

const watchers = {
  'pagination.page': function (page, oldVal, {dispatch}) {
    if (page !== oldVal) {
      dispatch('setShopProductsLoaderProperty', {page})
    }
  },
  'pagination.limit': function (limit, oldVal, {dispatch}) {
    if (limit !== oldVal) {
      dispatch('setShopProductsLoaderProperty', {limit})
    }
  },
  'pagination.init': function (enabled, oldVal, {dispatch}) {
    if (enabled && enabled !== oldVal) {
      dispatch('setShopProductsLoaderProperty', {paginate: true, enabled})
    }
  },
  'loader.category': function (val, oldVal, {dispatch}) {
    if (val !== oldVal) {
      dispatch('setShopPaginationProperty', {page: 1})
    }
  },
  'loader.filter': function (val, oldVal, {dispatch}) {
    if (val !== oldVal) {
      dispatch('setShopPaginationProperty', {page: 1})
    }
  },
  'loader': function (val, oldVal, options) {
    if (JSON.stringify(notNils(val)) !== JSON.stringify(notNils(oldVal)) && val.enabled) {
      loadProducts(val, options)
    }
  },
}

const plugins = [
  function (store) {

    const dispatch = async function (type, payload) {
        return await store.dispatch(absoluteNamespace + type, payload)
    }

    _.forOwn(watchers, (callback, property) => {
      store.watch(function () {
          return _.get(state, property)
        },
        function (val, oldVal) {
          if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
            callback(val, oldVal, {store, dispatch})
          }
        },
        {deep: true}
      )
    })
  }
]

const cart = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, fullNamespace, plugins}
export default cart
