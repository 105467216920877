<template lang="pug">
  .b-error
    .b-error__modal(:class="{ 'b-error__modal--intransparent': !transparent }")
      .text-center.m-b-20
          .b-error__state.b-error__state--danger Error
            img.b-error__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-danger-icon.svg`")
          .b-error__state-subtitle {{ error }}
      .b-error__divider.m-b-10
      v-button.btn.btn-info.btn-almost-sm.btn-block.m-t-20(@click="close") Back

</template>

<script>
export default {
  name: 'error-state',

  props: {
    error: {
      type: String,
    },
    transparent: {
      type: Boolean,
      default: true,
    }
  },

  data: () => ({
    config: window.addiesaas.config,
  }),

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.b-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 108px 30px;
  background-color: rgba(55,52,81,0.7);
  .b-error__modal {
    background-color: rgba(55,52,81,0.7);
    border-radius: 3px;
    padding: 15px 23px;
    &.b-error__modal--intransparent {
      background-color: rgb(55, 52, 81);
    }
  }
  &__state {
    display: inline-block;
    border-radius: 15px;
    line-height: 20px;
    padding: 5px 10px;
    &--danger {
      color: #EF3278;
      background-color: rgba(239,50,120,0.1);
    }
    &-icon {
      float: left;
    }
    &-subtitle {
      color: #EF3278;
      font-size: 11px;
      margin-top: 2px;
    }
  }
  &__divider {
    border-top: 1px solid white;
  }
}
</style>
