<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Create Password
      .b-central__subtitle Free forever. No credit card needed.

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        form-group(
          :validator="$v.password"
          label="Password:"
          attribute="password"
          v-slot="props"
          :messages="{ backendError }"
        )
          input(
            v-model="$v.password.$model"
            :class="props.classes"
            type="password"
            placeholder="Your new password"
            @input="input"
          )

        form-group(
          :validator="$v.passwordConfirmation"
          label="Confirm password:"
          attribute="password confirmation"
          v-slot="props"
          :messages="passwordConfirmationMessages"
        )
          input(
            v-model="$v.passwordConfirmation.$model"
            :class="props.classes"
            type="password"
            placeholder="Confirm your new password"
            @input="input"
          )

      .b-central__footer.b-central__footer--navigation-btn
        a.btn.btn-almost-sm.btn-secondary(@click.prevent="$router.go(-1)") Back
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$invalid") Continue

</template>

<script>
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    password: '',
    passwordConfirmation: '',
    backendError: null,
    waitingForVerification: false,
    passwordConfirmationMessages: { 'sameAsPassword': 'Password doesn\'t match.' },
    submitting: false,
  }),

  validations: {
    password: {
      required,
      backendError() {
        return !this.backendError
      },
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/password`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.password,
        })
        this.$router.push({ name: 'onboarding-register', query: this.$route.query })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
