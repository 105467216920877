/*global _, APP_ENV, JS_DEBUG, JS_DEBUG_LOG, APP_TIMEZONE */
import {Vue} from '~/addiesaas'
import spaces from '../../../../../../spaces.json'

export {spaces}
const ad = window.addiesaas || (window.addiesaas = {})
export const RE = ad

const toBool = val => {
  const retVal = {'false': false, 'true': true}[val]
  return _.isBoolean(retVal) ? retVal : val
}
const firstCondition = exp => toBool(_.castArray(exp).find(i => !_.isNil(i)))

const CONFIG = ad.config = Object.assign({
  env: APP_ENV,
  jsDebug: toBool(JS_DEBUG),
  jsDebugLog: toBool(JS_DEBUG_LOG),
  TIMEZONE: APP_TIMEZONE,
}, ad.config || {})

export const env = CONFIG.env
export const jsDebug = CONFIG.jsDebug
export const jsDebugLog = CONFIG.jsDebugLog
export const isLocalDev = /local|development/i.test(env)
export const isEnv = (q) => {
  return _.castArray(q).includes(env)
}
export const routes = _.keyBy(ad['routes'] || [], 'name')

export const debug = firstCondition([jsDebug, isLocalDev])

export const debugLog = (() => {
  const needsLog = firstCondition([jsDebugLog, debug])
  const unfn = () => {
  }
  if (needsLog) {
    // eslint-disable-next-line no-console
    return console && console.log || unfn
  }
  return unfn
})()

export const get = (path, defaultValue, location) => {
  let value
  if (location) {
    value = _.get(ad, `locations.${location}.${path}`)
  }
  if (_.isNil(value)) {
    value = _.get(ad, path)
  }
  return _.isNil(value) ? defaultValue : value
}

export const config = Object.assign({}, {
  debug,
  env,
  isEnv,
  routes,
  RE,
  CONFIG,
  spaces,
  get
})

Vue.prototype.$$config = get
Vue.prototype.$$debug = debug
Vue.prototype.$$env = env
Vue.prototype.$$debugLog = debugLog
window.debugLog = window.debugLog || debugLog

const isDevTools = firstCondition([jsDebug, debug])
if (isDevTools) {
  debugLog('Enable Vue Dev Tools')
  Vue.config.devtools = true
}

export default config

