/*global _ */
import {fv} from '../../../../lib/utils'
import {optionsHydrator} from '../../../../lib/form/input'

import props from './../../../../props/inputs'
import optionsProps from './../../../../props/inputs-options'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-radios',
  mixins,
  props: {
    ...props,
    ...optionsProps,
    name: {
      type: String,
      require: false,
    },
    radioValue: {
      type: [String, Number],
      default: null
    },
    inputClass: {
      type: String,
      default: 'form-control-radios'
    },
  },
  data() {
    return {
      innerOptions: this.options,
      optionsPropName: OPTIONS_PROP,
    }
  },
  computed: {
    optionsList() {
      let source = this.innerOptions
      const sourceIsArray = _.isArray(source)
      const itemIsObject = sourceIsArray && _.isPlainObject(_.first(source))
      const useArrayOptions = this.useArrayOptions
      if (sourceIsArray && !useArrayOptions && !itemIsObject) {
        source = _.mapKeys(source)
      }
      return _.reduce(source, (o, i, k) => {
        const itemIsObject = _.isObject(i)
        let item = itemIsObject ? i : {}
        const label = fv(item.label, item.name, item.key, itemIsObject ? '' : i, k)
        const value = fv(item.value, item.key, k, label)
        o.push({...item, label, value})
        return o
      }, [])
    },
    hasBlankOption() {
      return !_.isNil(this.blankOption)
    },
    modelLabel() {
      return (_.find(this.optionsList, o => o.value === this.model) || {}).label
    }
  },
  watch: {
    options: {
      handler(newValue) {
        this.innerOptions = newValue
      },
      deep: true
    }
  },
  methods: {
    getInputProps(item, key) {
      const label =  _.isString(item) ? item : fv(item.label || _.startCase(key))
      const radioValue = fv(item.value, key)
      const classes = [
        _.kebabCase(`form-field-${label}`),
        {
          selected: radioValue === this.model
        }
      ]

      return {
        multi: true,
        name: this.name,
        id: item.id || null,
        radioValue,
        label,
        class: classes,
        tabindex: this.tabindex
      }
    },
    onInput(value, item) {
      this.isDirtyState = true
      this.innerValue = value
      this.$emit('input', value, item)
    },
    getLabelExtraClasses() {
      return [`re-input-label-radios`]
    },
    hydrator: optionsHydrator
  }
}
