export const name = 're-embed'

import '../../../lib/utils'
import '../../../lib/lang'
import '../../../lib/settings'

import props from './props'
export {props}

import ComponentMixin from '../../../mixins/components/complex-reactive'

export const mixins = [ComponentMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus
}
