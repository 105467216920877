/* global _ */
import F from '../../../../lib/formatters'

import {
  name,
  mixins,
  props,
  data,
  PARSE_MAP,
  ITEM_PARSE_MAP
} from './config'

export default {
  name,
  props,
  mixins,
  events: 'user.profile.promotions',
  data() {
    return {
      ...data,
      filter: '',
    }
  },
  computed: {
    parseMap() {
      // title, description, coupon_code,
      // expire_at, is_expired,
      // claim_url, is_claimable,
      // is_used,
      // discount, $discount

      //  @if
      //    is_used (= claimed) = NOT ACTIVE
      //      discount, claim_message
      //  @else
      //    is_expired/expire_at (NOT ACTIVE)
      //    is_claimable => show claim button
      //
      const mapTranslations = this.$$t('parseMap', {})
      const mapSettings = this.$$s('parseMap', {})
      return _.merge({}, PARSE_MAP, mapTranslations, mapSettings)
    },
    itemParseMap() {
      const mapTranslations = this.$$t('itemParseMap', {})
      const mapSettings = this.$$s('itemParseMap', {})
      return _.merge({}, ITEM_PARSE_MAP, mapTranslations, mapSettings)
    },
    items() {
      const promotions = this.User.promotions
      if (!_.isEmpty(promotions)) {
        const items = this.parsePromotionItems(this.User.promotions)
        const params = {items}
        this.$$applyDataFilters(`${this.eventSpace}.items`, params)
        return params.items
      }
      return []
    },
    itemsCount() {
      return _.size(this.items || [])
    },
    hasItems() {
      return !!this.itemsCount
    },
    hasVisibleData() {
      return !!this.filteredItemsCount
    },
    filteredItemsCount() {
      return _.size(this.filteredItems || [])
    },
    filteredItems() {
      return this.applyFilter(this.items)
    },
    tableData() {
      const filtered = this.filteredItems
      let start = this.pageIndex * this.pageSize
      if (start >= filtered.length) {
        this.pageIndex = 0
        start = 0
      }
      const end = start + this.pageSize
      const visible = _.slice(filtered, start, end)
      return visible
    },
    showNoItems() {
      return !this.isPromising && (!this.hasItems || !this.hasVisibleData)
    },
    canShowHeaderButtons() {
      return this.hasItems && !this.isPromising
    },
    noPromotionsMessage() {
      let message = this.$$t('noPromotions.message', 'You have not collected any promotion.')
      if (this.filter && !this.hasVisibleData) {
        const filter = _.find(this.filterComponentAttrs.options, option => option.value === this.filter)
        if (filter && filter.noDataError) {
          message = filter.noDataError
        }
      }
      return message
    }
  },

  async created() {
    await this.fetch()
  },

  methods: {
    parsePromotionItems(items) {
      const map = this.parseMap
      const itemMap = this.itemParseMap
      if (_.isEmpty(map)) {
        return items
      }
      return _.filter(_.map(items, item => {
        const parsed = _.mapValues(map, def => {
          return this.parseReactiveValues(def, {...item, $: item})
        }) || {}
        _.forOwn(itemMap, (def, key) => {
          parsed[key] = this.parseReactiveValues(def, {...parsed, $: parsed, $$: item})
        })
        return parsed
      }))
    },
    parseClaimedMessage(data) {
      const map = this.parseMap
      return this.parseReactiveValuesByTemplate(map.claim_message.message, data)
    },
    hasExpired(data) {
      if (_.isObject(data)) {
        if (data.is_used) {
          return false
        }
        data = data.expire_at
        const expired = data.is_expired
        if (expired) {
          return expired
        }
      }
      return F.isPast(data)
    },
    isActive(item) {
      return !this.hasExpired(item) && !this.hasClaimed(item)
    },
    hasClaimed(item) {
      if (!_.isObject(item)) {
        return false
      }
      return item.action === "assign_coupon" ? item.is_used : item.is_applied
    },
    canClaim(item) {
      if (!_.isObject(item)) {
        return false
      }
      return item.is_claimable && this.isActive(item) && item.claim_url
    },
    canManualClaim(item) {
      if (!_.isObject(item)) {
        return false
      }
      return !this.canClaim(item) && this.isActive(item) &&
        !item.is_applied && item.manual_apply_url
    },
    async fetch(autoloadAll) {
      this.emit('fetch.init')
      try {
        if (!this.hasVisibleData) {
          this.startPromising(this.loadingMessage)
        }
        if (autoloadAll) {
          await this.autoLoadAll()
        }
        await this.initialAutoLoadCompleted()
        const items = await this.autoLoadPromotions()
        this.$emit('success', items)
        this.emit('fetch.success', items)
      } catch (e) {
        this.$emit('error', e)
        this.emit('fetch.error', e)
      } finally {
        this.emit('fetch.end')
        this.stopPromising()
      }
    },
    async claim(...args) {

    }
  }
}
