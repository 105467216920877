export const LOYALTY_PARSE_MAP = {
 $loyaltyPointsEarned: {
    value: '{[action_options.reward_points]}'
  },
 }

export const LOYALTY_MESSAGES = {
  'loyalty_program_points_reward': `You have received {{$loyaltyPointsEarned}} loyalty points{{$locations}}.
      {{br}}
      {{$expiringIn}}`
}

export const LOYALTY_ERROR_MESSAGES = {}
