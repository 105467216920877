/*global _, addiesaas */
import { mapGetters, mapActions } from 'vuex'
import Cookies from 'js-cookie'
import {validateAuth, userProfile} from '../services/main'

export default {
  computed: {
    ...mapGetters(['authUser', 'authToken', 'authCheck', 'shadowToken', 'afterLoginRoute']),
    user() {
      return this.authUser
    },
    userDataMeta() {
      return this.user && {...this.user.meta || {}, ...this.user} || {}
    },
    authenticated() {
      return this.authCheck
    },
    needsLogin() {
      return !this.authCheck
    },
    needsVerification() {
      return this.user && !(1 * this.user.verified)
    }
  },
  created() {
    if (this.isDesignerMode || this.designerMode) {
      return true
    }
    this.validateAuthCookie()
  },
  mounted() {
    if (this.isDesignerMode || this.designerMode) {
      return true
    }
    window.addEventListener("storage", e => {
      if (e.key !== "auth" || e.newValue === e.oldValue) {
        return
      }
      this.syncAuthStore(e.newValue)
    })
  },

  methods: {
    validateAuthCookie() {
      if (this.isDesignerMode || this.designerMode) {
        return true
      }
      const token = Cookies.get('token') || null
      if (!token) {
        if (addiesaas && addiesaas.store) {
          this.logout()
        }
        return false
      }
      return true
    },
    async pingUserSilent() {
      if (this.isDesignerMode || this.designerMode) {
        return true
      }
      await validateAuth()
    },
    async fetchUserSilent() {
      if (this.isDesignerMode || this.designerMode) {
        return true
      }
      const user = await userProfile()
      if (_.isObject(user)) {
        this.updateUser({user})
      }
    },
    syncAuthStore: _.debounce(function (storeValue) {
      if (this.isDesignerMode || this.designerMode) {
        return true
      }
      storeValue = storeValue || (window && window.localStorage && window.localStorage.auth)
      if (storeValue) {
        const store = JSON.parse(storeValue) || {}
        const data = store['system/authentication'] || {}
        this.syncAuthDetails(data)
      }
    }, 250),
    ...mapActions([
      'saveToken', 'saveShadowToken', 'rememberAfterLoginRoute',
      'pingUser', 'fetchUser', 'updateUser',
      'authGuard', 'logout',
      'syncAuthDetails'
    ])
  }
}
