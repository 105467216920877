import component from '../../../../../../../props/component'
import form from '../../../../../../../props/form'
import processing from '../../../../../../../props/processing'

const localProps = {
  item: {}, // subscription product
  plan: {}, // plan of the subscription product

  mode: {
    type: [String],
    default: 'shop' // profile
  },
  subscription: {}, // purchased subscription order details

  view: {
    type: [String],
    default() {
      return this.$options.$$s('view.type', 'grid')
    }
  },
  showFeaturedImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.featured.show', true)
    }
  },
  showBackgroundImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.background.show', true)
    }
  },
}
export const props = {
  ...component,
  // ...form,
  ...processing,
  ...localProps
}
export default props
