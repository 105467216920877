import MountMixin from '../../../mixins/custom-mount'

export default {
  name: 're-loading',
  mixins: [MountMixin],
  props: {
    classes: {
      type: [String, Array, Object],
      default: null
    },
    withLoader: {
      type: [Boolean, Number, String],
      default: true
    },
    loadingMessage: {
      type: String,
      default: ''
    },
    local: {
      type: [Boolean, Number, String],
    }
  },
  data() {
    return {
      MOUNT_TARGET: this.local ? null : this.$$s('globalLoader.appendTo.target'),
      MOUNT_CONTAINER: this.$$s(
        'globalLoader.appendTo.container',
        '.rocket-effect-container.rocketeffect-global-loader-container'
      ),
      MOUNT_CONTAINER_CLASSES: this.$$s(
        'globalLoader.appendTo.classes',
        'rocket-effect-container rocketeffect-global-loader-container'
      ),
      MOUNT_CONTAINER_CLEAR: this.$$s(
        'globalLoader.appendTo.clear',
        true
      ),
    }
  },
  computed: {
    loaderClasses() {
      return [
        this.classes,
        {
          'local-loader': this.local
        }
      ]
    }
  }
}
