<template lang="pug">

  modal(v-if="form.successful", @close="close")
    .re-modal-title
      slot(name="title")
    .re-modal-message
      slot(name="message")
    .re-modal-buttons
      slot(name="buttons")

</template>

<script>
import ModalMixin from '../../mixins/modal'

export default {
  name: 'form-success-modal',
  type: 'form-success',
  mixins: [ModalMixin],
  props: {
    form: {}
  },

  methods: {
    close() {
      this.form.successful = false;
      this.$emit('close');
    }
  }
}
</script>
