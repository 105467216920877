/*global _ */
import {default as superStore, namespace as superNamespace} from './modules/super'
import {default as designer, namespace as designerNamespace} from '../designer/store'
import {default as mashupBuilder, namespace as mashupBuilderNamespace} from './modules/mashup-builder'
import {default as pages, namespace as pageNamespace} from './modules/pages'
import {default as timer, namespace as timerNamespace} from './modules/timer'
import {default as cart, namespace as cartNamespace} from './modules/cart'
import {default as shop, namespace as shopNamespace} from './modules/shop'
import {default as profile, namespace as profileNamespace} from './modules/profile'
import {default as modals, namespace as modalsNamespace} from './modules/modals'

const namespaced = true
const namespace = 'REUtils'

const modules = {
  [superNamespace]: superStore,
  [designerNamespace]: designer,
  [mashupBuilderNamespace]: mashupBuilder,
  [pageNamespace]: pages,
  [timerNamespace]: timer,
  [shopNamespace]: shop,
  [cartNamespace]: cart,
  [profileNamespace]: profile,
  [modalsNamespace]: modals
}

// plugins
const plugins = _.reduce(
  modules,
  (items, module) => [...items, ...module.plugins || []],
  []
)

export {namespaced, namespace, plugins, modules}

export default {namespaced, namespace, plugins, modules}
