export const formProps = {

  showPersonalDetails: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.personalDetails.show', true)
    }
  },
  personalDetailsTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.personalDetails.title', 'Profile')
    }
  },
  showPersonalDetailsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.personalDetails.showTitle', true)
    }
  },
  personalDetailsSubTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.personalDetails.subTitle', '')
    }
  },
  showPersonalDetailsSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.personalDetails.showSubTitle', false)
    }
  },
  personalDetailsFormOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('forms.personalDetails.options', {})
    }
  },

  showAddressDetails: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.addressDetails.show', false)
    }
  },
  addressDetailsTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.addressDetails.title', 'Address')
    }
  },
  showAddressDetailsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.addressDetails.showTitle', true)
    }
  },
  addressDetailsSubTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.addressDetails.subTitle', '')
    }
  },
  showAddressDetailsSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.addressDetails.showSubTitle', false)
    }
  },
  addressDetailsFormOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('forms.addressDetails.options', {})
    }
  },
  showDelete: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.delete.show', false)
    }
  },
  deleteButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.delete.button.title', 'Delete')
    }
  },

  showPasswordDetails: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.passwordDetails.show', true)
    }
  },
  passwordDetailsTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.passwordDetails.title', 'Password')
    }
  },
  showPasswordDetailsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.passwordDetails.showTitle', true)
    }
  },
  passwordDetailsSubTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.passwordDetails.subTitle', '')
    }
  },
  showPasswordDetailsSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.passwordDetails.showSubTitle', false)
    }
  },
  passwordDetailsFormOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('forms.passwordDetails.options', {})
    }
  },

  showSubscribeDetails: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.subscribeDetails.show', false)
    }
  },
  subscribeDetailsTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.subscribeDetails.title', 'Subscribe')
    }
  },
  showSubscribeDetailsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.subscribeDetails.showTitle', true)
    }
  },
  subscribeDetailsSubTitle: {
    type: String,
    default() {
      return this.$options.$$t('forms.subscribeDetails.subTitle', '')
    }
  },
  showSubscribeDetailsSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forms.subscribeDetails.showSubTitle', false)
    }
  },
  subscribeDetailsFormOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('forms.subscribeDetails.options', {})
    }
  },
  withBirthDayInputs: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputs', false)
    }
  },
  birthdayInputsOrder: {
    type: [Array, String],
    default() {
      return this.$options.$$s('birthdayInputsOrder', 'month,day,year')
    }
  },
  withBirthDayInputButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputButton', true)
    }
  },

  subscribeLabel: {
    type: String,
    default() {
      return this.$options.$$t('forms.subscribeDetails.fields.subscribe.label',
        `By registering with us you are opting-in to
        receive news and special offers. To change your email preferences,
        visit account profile.`
      )
    }
  },
  subscribeSmsLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.subscribeDetails.fields.sms_subscribe.label',
        `By registering with us you are opting-in to
        receive news and special offers vis SMS. To change your SMS preferences,
        visit account profile.`
      )
    }
  },
}
export default formProps
