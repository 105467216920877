/*global _ */
import {getNow, Duration, parseRelativeDateTime} from '../../../lib/dates'

import {fv} from '../../../lib/utils'

import Base from '../../Base'
import eventsFactory from '../../mixins/factory/events'

export const NAMESPACE = 'timer'
export const EVENTS = {
  START: 'start',
  STOP: 'stop',
  RESUME: 'resume',
  CANCEL: 'cancel',
  TICK: 'tick',
  TIMEOUT: 'timeout',
  READY: 'ready',
}

export class Countdown extends Base {
  constructor(data, vm) {
    super(data, vm)
    this.init(data, vm)
  }

  get id() {
    return this.$_id
  }

  calcDuration(duration) {
    duration = fv(duration, this.$$data)

    // parse
    if (_.isString(duration)) {
      let possibleDuration = 1 * duration
      if (_.isFinite(possibleDuration)) {
        // if numeric, value represents seconds
        duration = possibleDuration
      } else {
        let possibleDate = parseRelativeDateTime(duration)
        if (possibleDate && possibleDate.isValid) {
          duration = possibleDate.toJSDate()
        } else {
          duration = 0
        }
      }
    }

    // if duration is a number it is expected to be in seconds
    // and hence setting it to milliseconds as per DateTime.plus format
    if (duration instanceof Date) {
      const endTime = duration.valueOf()
      const startTime = getNow().valueOf()
      duration = this.duration = endTime - startTime
      this.durationSeconds = duration / 1000

    } else if (duration instanceof Duration) {
      this.durationSeconds = duration.valueOf() / 1000

    } else if (duration instanceof Object) {
      this.durationSeconds = Duration.fromObject(duration).valueOf() / 1000

    } else {
      // numeric value represents seconds
      this.durationSeconds = duration
      duration = this.duration = duration * 1000
    }

    this.duration = duration
    return duration
  }

  init(data) {
    this.listeners = fv(this.listeners, {})
    this.$_id = fv(this.$_id, _.uniqueId(`${NAMESPACE}_`))
    this.reset()
    // this.emit(EVENTS.READY, this)
    if (!_.isNil(data)) {
      this.start()
    }
    return this
  }

  reset() {
    this.duration = null
    this.durationSeconds = null
    this.startTime = null
    this.startValue = null
    this.endTime = null
    this.endValue = null
    this.elapsed = null
    this.remaining = null

    this.timeout = false

    this.running = false
    return this
  }

  start(duration = null) {
    if (!_.isNil(duration)) {
      this.$$data = duration
    }
    duration = this.calcDuration()
    const now = getNow()

    const end = now.plus(duration)
    this.startTime = now
    this.startValue = now.valueOf()
    this.endTime = end
    this.endValue = end.valueOf()
    this.duration = duration
    this.timeout = false

    this.running = true
    this.elapsed = 0
    this.remaining = this.durationSeconds
    this.emit(EVENTS.START, this.remaining, this.elapsed, this)
    this.tick()
    return this
  }

  stop() {
    this.running = false
    this.emit(EVENTS.STOP, this)
    return this
  }

  resume() {
    this.running = true
    this.emit(EVENTS.RESUME, this)
    this.tick()
    return this
  }

  cancel() {
    this.reset()
    this.emit(EVENTS.CANCEL, this)
    return this
  }

  calc() {
    if (this.running) {
      const nowDate = getNow()
      const now = nowDate.valueOf()

      const remaining = this.remaining = Math.floor(Math.max(0, (this.endValue - now) / 1000))
      if (remaining <= 0) {
        this.reset()
        this.timeout = true
        this.emit(EVENTS.TIMEOUT, this)
      } else {
        const elapsed = this.elapsed = Math.floor((now - this.startValue) / 1000)
        this.emit(EVENTS.TICK, remaining, elapsed, this)
      }
    }
    return this
  }

  tick() {
    if (!this.timeout) {
      this.calc()
      if (this.running) {
        window.setTimeout(() => {
          this.tick()
        }, 1000)
      }
    }
    return this
  }
}

const listenersMixin = eventsFactory(NAMESPACE, EVENTS)
Object.assign(Countdown.prototype, listenersMixin)

export default Countdown
