/* global _ */
import {Vue} from '~/addiesaas'
import {name, mixins, props, data, EventBus, validationsRules, parseValidations, parseValidationSetup} from './config'

import MainService from '../../../../../services/main'

const validationGroupRules = {
  guest: [
    'guestEmailFields.email', 'guestEmailFields.email_confirmation'
  ],
}

export default {
  name,
  props,
  mixins,

  data() {
    return {
      ...data,
      currentStep: 'cart',
      showClearCartPrompt: false,
      showCheckoutAsGuestModal: false,
      showLoginPrompt: false,

      guestEmailRegistered: false,
      isCheckingGuestEmail: false,
      guestEmailFields: {
        email: null,
        email_confirmation: null
      },
    }
  },

  validations() {
    return {
      guestEmailFields: parseValidations(validationsRules),
      ...validationGroupRules
    }
  },

  async created() {
    this.validations = parseValidationSetup(validationsRules, this)

    EventBus.$on('user.logout', async () => {
      this.validateRepeatCount = 0
      await this.validateCart()
    })
  },
  async mounted() {
    await this.$nextTick(async () => {
      this.validateRepeatCount = 0
      await this.validateCart()
    })
  },

  computed: {
    hasGroups() {
      return this.orderItemsByGroup
    },
    hasHeaders() {
      return this.showCartHeader !== false
    },
    showGroupHeaders() {
      return this.hasGroups && this.hasHeaders
    },
    showGeneralHeader() {
      return !this.hasGroups && this.hasHeaders
    },
    cartItemsOrderedByType() {
      const all = this.currentCartItems
      if (!this.hasGroups) {
        return {all}
      }

      let items = {}
      _.forOwn(all, (item, id) => {
        const group = item.type
        items[group] = items[group] || {}
        items[group][id] = item
      })
      return items
    },

    showClearCartModal() {
      return !this.currentCartIsEmpty && this.showClearCartPrompt
    },
    showRedemptions() {
      return !this.noRedemptions
    },
    showLoginPromptModal() {
      return !this.currentCartIsEmpty && this.showLoginPrompt
    },
    showSummaryBox() {
      return this.alwaysShowSummary || (!_.isEmpty(this.costDetails) && this.costDetails.price)
    },
    errorMessage() {
      return this.validationError
    }
  },

  methods: {
    getViewerComponent(type, suffix = '', prefix = '') {
      if (this.useDefaultItemViewer) {
        return null
      }
      const componentName = (prefix || '') + _.kebabCase(type || '') + (suffix || '')
      return this.getComponentByName(componentName)
    },
    // movie-cart-item // reservation-cart-item
    getCartItemViewer(item, suffix = '', prefix = '') {
      let component = null
      if (!this.useDefaultItemViewer) {
        const componentName = (prefix || this.itemComponentPrefix || '') +
          _.kebabCase(item.type || '') +
          (suffix || this.itemComponentSuffix || '')
        component = this.getComponentByName(componentName)
      }
      if (!component) {
        const type = item.type
        let defaultComponent = 're-cart-item'
        if (type.includes('card')) {
          defaultComponent = 're-cart-item-card'
          component = !_.isEmpty(this.components)
            ? this.components.itemCard  || defaultComponent
            : defaultComponent
        } else if (type.includes('reservations')) {
          defaultComponent = 're-cart-item-booking'
          component = !_.isEmpty(this.components)
            ? this.components.itemBooking  || defaultComponent
            : defaultComponent
        } else {
          component = !_.isEmpty(this.components)
            ? this.components.item  || defaultComponent
            : defaultComponent
        }
      }
      return component
    },
    getComponentByName(componentName) {
      if (componentName in Vue.options.components || componentName in this.$options.components) {
        return componentName
      }
    },

    getGroupTitle(group, items) {
      return this.productGroupNames[group] || _.startCase(group)
    },

    async cartSubmitButtonClicked($event) {
      const args = {$event, items: this.currentCartItems, cart: this, click: {}}

      await EventBus.$$emitAsync('cart.process.before', args)

      if (args.click.cancel) {
        return
      }
      if (this.validateBeforeBilling) {
        await this.validateCart()
      }
      if (this.valid) {
        let nativeMethodCall = true
        nativeMethodCall = EventBus.$emit('cart.process', args) !== false

        if (nativeMethodCall) {
          this.$emit('proceed')
        }
        await this.tryGotoBilling()
      }
    },

    cleanCurrentCart() {
      this.showClearCartPrompt = true
    },
    finallyCleanCurrentCart() {
      this.clearCartItems()
      this.showClearCartPrompt = false
    },
    finallyDontCleanCurrentCart() {
      this.showClearCartPrompt = false
    },

    async tryGotoBilling() {
      if (this.authUser || this.noAuthCheck) {
        await this.gotoBilling()
      } else {
        if (this.allowGuestCheckout && this.hasAllGuestCheckoutProducts(this.guestCheckoutTypes)) {
          this.showCheckoutAsGuestModal = true
        } else {
          this.showLoginPrompt = true
        }
      }
    },

    async checkGuestEmail() {
      if (this.validateBeforeBilling) {
        await this.validateCart()
        if (!this.valid) {
          return
        }
      }
      EventBus.$emit('cart.before.check-guest-email')
      try {
        this.isCheckingGuestEmail = true
        const payload = { email: this.guestEmailFields.email }
        EventBus.$emit('cart.check-guest-email', payload)
        await MainService.validateEmail(payload)
        this.showCheckoutAsGuestModal = false
        this.guestEmailRegistered = false
        await this.gotoBilling()
      } catch (e) {
        EventBus.$emit('cart.check-guest-email.error', e)
        this.guestEmailRegistered = true
      } finally {
        this.isCheckingGuestEmail = false
        EventBus.$emit('cart.check-guest-email.finally')
      }
    },

    async gotoBilling() {
      this.setValidationError()
      EventBus.$emit('cart.go-to-billing', this)
      if (this.skipBillingIfNoPayment && !this.billingEnabled) {
        if (!this.allowGuestCheckout && !this.authCheck) {
          this.setCartStep('billing')
        } else {
          await this.submitCart({})
        }
      } else {
        await this.pingUser()
        this.setCartStep('billing')
      }
    },
    gotoLoginPage() {
      EventBus.$emit('cart.goto.login')
      this.saveAndGotoLogin()
    },
    gotoSignUpPage() {
      EventBus.$emit('cart.goto.sign-up')
      this.saveAndGotoRegistration()
    },

  },
}
