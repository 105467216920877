/*global _ */
export const name = 're-shop-products-sort'
import component from '../../../../../../props/component'
import form from '../../../../../../props/form'
import {queryVars} from '../../base/props'
export const props = {
  ...queryVars,
  ...component,
  ...form,

}
import BaseMixin from '../../base/mixin'
import MashupBuilderParserMixin from '../../../../../../mixins/mashup-builder/parser'
import ComponentMixin from '../../../../../../mixins/components/complex'
import ProductsMixin from '../../../../../../mixins/shop/products'
export const mixins = [ComponentMixin, BaseMixin, ProductsMixin, MashupBuilderParserMixin]

export const data = {}

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      builderListeners: {},
      eventSpace: 'shop.products.sort',
      fields: {},
    }
  },

  computed: {
    hasStats() {
      return !_.isEmpty(this.shopProductsPagination.result)
    },
    stats() {
      return this.shopProductsPagination.result || {}
    },
    loaderStats() {
      return this.shopProductsLoaderProperties || {}
    },
  },

  async created() {
    this.fields = _.pick(this.loaderStats, ['order', 'orderBy'])
    this.builderListeners = {
      'fields:changed': this.fieldsChanged,
    }
  },

  mounted() {

  },
  methods: {
    fieldsChanged(name, val) {
      this.setShopProductsLoaderProperty({[name]: val})
    },
    getParseBuilderOptions() {
      return _.merge(
        {},
        this.$$clone(this.$$pt(`sort`, {})),
        this.$$clone(this.$$ps(`sort`, {}))
      )
    },
  }
}
