/*global _ */
export default {
  methods: {
    waitForUserCards(type = 'playcard') {
      return new Promise(resolve => {
        const getCards = () => {
          const cards = _.get(this.User, `cards.${type}`)
          if (_.isEmpty(cards)) {
            setTimeout(getCards, 200)
          } else {
            resolve(cards)
          }
        }
        getCards()
      })
    },
    async getUserCardsLocations(type = 'playcard') {
      const cards = await this.waitForUserCards(type)
      return _.reduce(cards, (locations, card) => {
        const location = _.get(card, 'location.data')
        if (location) {
          locations[location.id] = location.display_name || location.name
        } else {
          const id = card.location_id
          if (id) {
            const location = _.get(window.addiesaas, `locations.${id}`)
            locations[id] = location.display_name || location.name
          }
        }
        return locations
      }, {})
    },
    async getUserPlayCardsLocations() {
      return await this.getUserCardsLocations('playcard')
    }
  }
}
