import {name, mixins, props, data} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },
  methods: {
    async addCustomItemToCart() {
      await this.$validate()
      if (this.isAnyInvalid) {
        this.scrollToFirstErrorElement()
      } else {
        const item = this.buildCartItem()
        this.isBeingProcessed = true
        const success = await this.addCartItem(item)
        if (success) {
          this.goto('cart')
        } else {
          this.isBeingProcessed = false
        }
      }
    },
    getComponentExtraClasses() {
      return []
    }
  },
}
