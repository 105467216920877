export default {
  data() {
    return {
      builderErrorMessage: null,
    }
  },
  mixins: [],

  computed: {
    // pagesLength, pageIndex, pageKey,
    page() {
      return this.pages[this.pageIndex]
    },
    pagesLength() {
      return this.pages.length
    },
    pageKey() {
      return this.page.key
    },
    showPreviousPageButton() {
      return _.get(this.page, 'buttons.prev.show') !== false
    },
    hasPreviousPage() {
      return this.pageIndex >= 1
    },
    showNextPageButton() {
      return _.get(this.page, 'buttons.next.show') !== false
    },
    hasNextPage() {
      return this.pageIndex < (this.pagesLength - 1)
    },
    showSubmitButton() {
      return _.get(this.builderStructures, 'options.buttons.finalize.show') !== false
    },
    canShowSubmitButton() {
      return !this.hasNextPage && !this.isAnyInvalid
    },
  },
  created() {
    this.pages = _.values(this.builderStructures.pages || {})
  },
  methods: {

  }
}
