/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E} from '../../../lib/constants'
import {getBuilderComponentEmitter} from '../../../lib/utils'
import prepare from './prepare'
import setStates from './states'

export default function () {
  const bus = this
  const globalEmit = getBuilderComponentEmitter(bus)
  const emit = (name, ...params) => {
    bus.$emit(name, ...params)
    globalEmit(name, ...params)
  }
  // just after vue component is rendered (vue-instance and vue-component)
  //{data, component:json}
  bus.$on(E.VUE_CREATED + ':before', (...args) => {
    // INCEPTION OF EVENT: PREPARE
    // The most crucial event to component to prepare all properties
    prepare.call(bus, ...args)
  })

  // after component init (first access to component object)
  bus.$on(E.INIT + ':before', (...args) => {
    const [{component, editor}] = args

    bus.$set(bus.designerObject, 'component', component)

    setStates(component, editor, bus)
    registerChildEvents(component, editor)
  })

  const registerChildEvents = function (component, editor) {
    const parentId = component.getId()
    const type = component.get('type')
    // DESIGNER_ROOT_EVENTS children:component:create ...
    const events = [
      'component:create',
      'component:mount',
      'component:add',
      'component:remove',
      'component:clone'
    ]
    events.forEach((e) => {
      editor.on(e, (model) => {
        if (model && model.closestType(type)) {
          const closest = model.closestType(type)
          const closestId = closest.getId()
          if (closestId === parentId) {
            //component, editor, vm
            const params = {component, editor, vm: bus, child: model}
            emit('children:' + e, params)
          }
        }
      })
    })
  }
}
