<template lang="pug">
  .b-voucher(v-if="voucher" :class="{ 'b-voucher--intransparent': !transparent }")
    .text-center.m-b-20
      template(v-if="redeemed")
        .b-voucher__state.b-voucher__state--success(v-if="!alreadyUsed") Redeemed
          img.b-voucher__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-ok-icon.svg`")
        .b-voucher__state.b-voucher__state--danger(v-if="alreadyUsed") Cannot be Redeemed
          img.b-voucher__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-danger-icon.svg`")
        .b-voucher__state-subtitle(v-if="alreadyUsed") Voucher has already been used.
      template(v-else)
        .b-voucher__state.b-voucher__state--warning Not Claimed
          img.b-voucher__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-dollar-icon.svg`")
    .b-voucher__items
      .b-voucher__title.m-b-10 Voucher \#{{ voucher.number }}
      .b-voucher__item.m-b-10(v-for="item of items")
        .b-voucher__flex-row
          span {{ item.title }}
          span {{ item.price * item.count | currency }}
        .b-voucher__flex-row
          .b-voucher__item-redeemed {{ item.redeemed + item.redeeming }}/{{ item.count }}
          .b-voucher__item-redeeming
            .b-voucher__item-redeeming-minus(@click="redeemingMinus(item)" :disabled="item.redeeming <= 0") –
            .b-voucher__item-redeeming-count {{ item.redeeming }}
            .b-voucher__item-redeeming-plus(@click="redeemingPlus(item)" :disabled="item.redeeming >= item.redeemable.length") +
    .b-voucher__divider.m-b-10
    .b-voucher__flex-row
      span Total
      span {{ total | currency }}
    v-button.btn.btn-info.btn-almost-sm.btn-block.m-t-20(v-if="!redeemed", @click="redeem", :loading="redeeming") Redeem

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import baseUrl from '../mixins/base-url'

export default {
  name: 'voucher-state',

  props: {
    voucher: {
      type: Object,
    },
    transparent: {
      type: Boolean,
      default: true,
    }
  },

  mixins: [
    baseUrl,
  ],

  data: () => ({
    config: window.addiesaas.config,
    alreadyUsed: false,
    redeemed: false,
    redeeming: false,
    items: {},
  }),

  computed: {
    ...mapGetters({
      settings: 'VoucherMobileScanner/settings',
    }),

    total() {
      return this.voucher.items.data.reduce((a, x) => a + Number(x.product.data.price), 0)
    },
  },

  watch: {
    async voucher() {
      await this.voucherChanged()
    },
  },

  async created() {
    await this.voucherChanged()
  },

  methods: {
    async voucherChanged() {
      if (this.voucher) {
        this.alreadyUsed = this.voucher.is_redeemed
        this.redeemed = this.voucher.is_redeemed

        if (this.settings.autoRedeem && !this.alreadyUsed) {
          await this.redeem()
        }

        this.items = this.voucher.items.data.reduce((a, x) => {
          const pid = x.product.data.id
          if (!(pid in a)) {
            a[pid] = {
              title: x.product.data.title,
              price: x.product.data.price,
              count: 0,
              redeemed: 0,
              redeeming: 0,
              redeemable: [],
            }
          }
    
          a[pid].count++
    
          if (x.is_redeemed) {
            a[pid].redeemed++
          } else {
            a[pid].redeeming++
            a[pid].redeemable.push(x.id)
          }

          return a
        }, {})

        this.$emit('mounted')
      }
    },
  
    async redeem() {
      try {
        this.redeeming = true

        let itemsRemain = false

        const requests = []
        for (let item of Object.values(this.items)) {
          while (item.redeeming > 0) {
            const id = item.redeemable.pop()
            requests.push(axios.post(`${this.baseUrl}/api/admin/voucher/${this.voucher.id}/item/${id}/redeem`))
            item.redeeming--
          }
          this.$set(item, 'redeeming', item.redeemable.length)
          this.$set(item, 'redeemed', item.count - item.redeemable.length)

          itemsRemain = itemsRemain || item.redeemable.length > 0
        }

        await axios.all(requests)

        this.redeemed = !itemsRemain

        this.redeeming = false
      } catch (e) {
        console.error(e)
        if (e.response && e.response.status == 401) {
          await window.addiesaas.store.dispatch('logout')
          this.$router.push({ name: 'vouchermobilescanner-sign-in' })
        }
      } finally {
        this.redeeming = false
      }
    },

    redeemingMinus(item) {
      if (item.redeeming > 0) {
        this.$set(item, 'redeeming', item.redeeming - 1)
      }
    },

    redeemingPlus(item) {
      if (item.redeeming < item.redeemable.length) {
        this.$set(item, 'redeeming', item.redeeming + 1)
      }
    }
  },
}
</script>

<style lang="scss">
.b-voucher {
  position: absolute;
  z-index: 1;
  top: 108px;
  bottom: 108px;
  left: 30px;
  right: 30px;
  background-color: rgba(55,52,81,0.7);
  border-radius: 3px;
  padding: 15px 23px;
  &--intransparent {
    background-color: rgb(55, 52, 81);
  }
  &__state {
    display: inline-block;
    border-radius: 15px;
    line-height: 20px;
    padding: 5px 10px;
    &--success {
      color: #25C980;
      background-color: rgba(81,208,151,0.14);
    }
    &--warning {
      color: #EDBB26;
      background-color: rgba(247,212,108,0.2);
    }
    &--danger {
      color: #EF3278;
      background-color: rgba(239,50,120,0.1);
    }
    &-icon {
      float: left;
    }
    &-subtitle {
      color: #EF3278;
      font-size: 11px;
      margin-top: 2px;
    }
  }
  &__title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  &__items {
    max-height: calc(100vh - 387px);
    overflow-y: scroll;
    padding-right: 16px;
    margin-right: -16px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: #8E8EEE;
      border-radius: 2px;
    }
  }
  &__item {
    &-redeemed,
    &-redeeming {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    &-redeemed {
      color: #878DA9;
    }
    &-redeeming {
      font-weight: 600;
      font-size: 16px;
      &-minus,
      &-count,
      &-plus {
        display: inline-block;
      }
      &-minus,
      &-plus {
        font-size: 24px;
        font-weight: 700;
        &[disabled] {
          color: gray;
        }
      }
      &-count {
        width: 25px;
        border-radius: 2px;
        color: #75ACF1;
        background-color: rgba(135, 187, 253, 0.5);
        margin-left: 6px;
        margin-right: 6px;
        text-align: center;
      }
    }
  }
  &__divider {
    border-top: 1px solid white;
  }
  &__flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
