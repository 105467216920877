export const name = 're-cart-list'

// generic setup
import {
  allValidationsRules as validationsRules,
  defaultFormFields,
  parseValidations,
  parseValidationSetup
} from './form'

import props from './props'

export {props, parseValidations, parseValidationSetup, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../../mixins/components/complex-reactive'
import FormMixin from '../../../../../mixins/form'
import CartMixin from '../../../../../mixins/cart'
export const mixins = [ComponentMixin, FormMixin, CartMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  parseValidations,
  parseValidationSetup,

  EventBus,
}
