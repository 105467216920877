const localProps = {
  translations: {},
  settings: {},
  data: {}, // data,
            // pages[sections[groups[form[fields]|list]|form|list]|tabs[*section]],
            // components,
            // options
            // form[fields, validations, options],
            // section, page, tab, group
}
export const props = {
  ...localProps
}
export default props
