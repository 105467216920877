const defaultSteps = [['terms', 'product', 'personal', 'recipient'], ['preview']]

class StepManager {
  constructor(vm) {
    this.vm = vm
    this.steps = vm.customSteps || defaultSteps
    this.allSteps = _.flatten(this.steps)
  }

  isPreviousInvalid(step) {
    return true
  }
  isNextInvalid(step) {
    return true
  }
  get areAllValid() {
    return true
  }

  get all() {
    return []
  }
  get allFlatten() {
    return []
  }

}

export default {
  data() {
    return {
      steps: null
    }
  },
  computed: {
    showAgreeToTermsBox() {
      return !this.isCompactMode || !this.noAgreeToTerms
    },
    isTermAgreed() {
      return !this.showAgreeToTermsBox || this.termsAgreed !== 'yes'
    },
    disabledAmountBox() {
      if (this.showProductAtTop) {
        return !this.isTermAgreed
      }
      return this.isPersonalDetailsInvalid
        || this.isLocationInvalid
    },
    disabledPersonalDetails() {
      if (this.showProductAtTop) {
        return !this.productId
      }
      return !this.isTermAgreed

    },
    isLocationDisabled() {
      if (this.showProductAtTop) {
        return !this.isTermAgreed
      }
      return this.isPersonalDetailsInvalid
    },
    isLocationInvalid() {
      if (this.useLocation) {
        return !this.location_id
      }
      return false
    },
    isQuantityDisabled() {
      return !this.productId
    },
    isQuantityInvalid() {
      if (!this.noQuantity) {
        return _.toFinite(this.count) <= 0
      }
      return false
    },
    isPersonalDetailsInvalid() {
      return this.noPhoneInput ? this.$v.personalDetails.$invalid : this.$v.personalDetailsWithPhone.$invalid
    },
    disabledRecipientBox() {
      return this.disabledPersonalDetails || this.isPersonalDetailsInvalid
    },
    disabledRecipientDetails() {
      return !this.giftee
    },
    isGifteeDetailsInvalid() {
      return this.giftee && (this.noPhoneInput ? this.$v.gifteeDetails.$invalid : this.$v.gifteeDetailsWithPhone.$invalid)
    },
    isPersonalOrGifteeDetailsInvalid() {
      return this.isGifteeDetailsInvalid ||  this.isPersonalDetailsInvalid
    },
    isGifteeNoteInvalid() {
      return this.giftee && this.$v.giftNote && this.$v.giftNote.$invalid
    },
    disabledCardProcessor() {
      return this.disabledRecipientBox || this.isGifteeDetailsInvalid || this.isGifteeNoteInvalid ||
        this.isQuantityInvalid || this.isLocationInvalid
    },
    showPhoneInput() {
      return !this.noPhoneInput
    },
  },
  created() {
    this.steps = new StepManager(this)
  }
}
