import {nils, someNil} from '../../../lib/formatters'
import {registerPromise as service, EventBus, DataFilter} from '../../../lib/utils'

export const serviceCall = async function (name, ...args) {
  const [data] = await service(name, ...args)
  return data
}
export const validateServiceCallArguments = function (required, all, options) {
  if (someNil(required)) {
    const missing = nils(required)
    const vm = this.$$vm()
    vm.$$debugLog('MissingArgumentsError', {missing})
    throw new this.$$ERRORS.MissingArgumentsError('Missing arguments', null, missing)
  }
}

export default {
  serviceCall,
  validateServiceCallArguments
}
