import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      eventSpace: 'user.profile.loyalty',
    }
  },
  computed: {
    showingHistory: {
      get() {
        return _.get(this.storedProfileData, 'states.loyalty.showingHistory')
      },
      set(value) {
        this.updateProfileDataItem({'states.loyalty.showingHistory': value})
      }
    },
  },
  async created() {
    await this.fetch()
    await this.initialAutoLoadCompleted()
    await this.prepareRewards(true)
  },
  async activated() {
    await this.fetch()
    await this.initialAutoLoadCompleted()
    await this.prepareRewards(true)
  },
  watch: {
    async earnedLoyaltyPoints(value, oldValue) {
      value = value || 0
      if (value !== oldValue) {
        await this.prepareRewards(true)
      }
    },
  },
  methods: {
    async fetch(autoloadAll) {
      try {
        const autoLoads = this.autoLoad
        if (autoLoads) {
          await this.autoLoadAll(autoLoads)
        } else {
          if (autoloadAll) {
            await this.autoLoadAll(autoLoads)
          }
        }
      } finally {
        //
      }
    },
    getComponentExtraClasses() {
      return [
        {
          'showing-history': this.showingHistory
        }
      ]
    },
    showHistory() {
      this.showingHistory = true
    },
    hideHistory() {
      this.showingHistory = false
    },
  }
}
