export const name = 're-profile-orders'

// custom setup

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
import props from './props'

export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex-reactive'
import ProfileMixin from '../../../../mixins/profile'
import ResponsiveMixin from '../../../../mixins/responsive'
import TableMainMixin from '../../../../mixins/table'
import TableMixin from './table'
import PaginateMixin from './paginate'
export const mixins = [
  ComponentMixin,
  ProfileMixin,
  TableMainMixin,
  TableMixin,
  PaginateMixin,
  ResponsiveMixin
]

export const EventBus = window.VueEventBus

// data
const orders = []
const propDefs = props
export const data = {
  orders,
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  EventBus,
}
