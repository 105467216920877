/*global _ */
import {name, mixins, props, data, EventBus} from './config'
import {get as getConfig} from '../../../../lib/config'

export default {
  name,
  props,
  mixins,

  data() {
    return {
      ...data,
      isSubmitting: false,
      termsAgreed: 'no',
      editMode: false,
      editItem: null,
      cardsList: [],
    }
  },

  computed: {
    showBonus() {
      return this.showBonusBox && this.playcardProduct && this.playcardQuantity
    },
    bonus() {
      return this.playcardProduct.balance.bonus_cash * this.playcardQuantity
    },
    maxQuantity() {
      const max = this.limitQuantity
      if (max) {
          if (_.isObject(max)) {
            const value = getConfig(max.valuePath)
            if (value) {
              return 1 * value
            }
          } else {
            return 1 * max || false
          }
      }
      return false
    },
    maxQuantityByLocation() {
      const max = this.limitQuantityByLocation
      if (max) {
          if (_.isObject(max)) {
            const value = getConfig(max.valuePath)
            if (value) {
              return 1 * value
            }
          } else {
            return 1 * max || false
          }
      }
      return false
    },
    userCardsQuantity() {
      const type = this.productType || 'playcard_sale'
      const editItem = this.editItem
      let count = 0
      const path = this.$$s('user.quantity.valuePath')
      if (path) {
        count += _.toFinite(_.get(this.user, path, 0))
        const cartItems = _.pickBy(this.cartItems, i => {
            return (!editItem || editItem.id !== i.id) && (i.type === type)
        })
        count = _.reduce(cartItems, (c, i) => {
          return c + _.toFinite(_.get(i, 'payload.count', 0))
        }, count)
      }
      return count
    },
    userPerLocationCardsQuantity() {
      let items = {}
      const type = this.productType || 'giftcard'
      const editItem = this.editItem
      const path = this.$$s('user.quantityPerLocation.valuePath')
      if (path) {
        const cartItems = _.pickBy(this.cartItems, i => i.type === type)
        items = _.cloneDeep(_.get(this.user, path, {}))
        _.forOwn(cartItems, i => {
          if (!editItem || editItem.id !== i.id) {
            const locationId = _.get(i, 'payload.options.location_id')
            const qty = _.get(i, 'payload.count')
            if (locationId) {
              items[locationId] = _.toFinite(items[locationId]  || 0) + _.toFinite(qty)
            }
          }
        })
      }
      return items
    },
    buyComponentAttrs() {
      const attrs = {
        ...this.mergedAttr || {},
        ..._.pick(this, [
          'termsAgreed', 'editMode', 'cardsList',
          'maxQuantity', 'maxQuantityByLocation', 'userCardsQuantity',
          'userPerLocationCardsQuantity'
        ]),
        settings: this.$$$s,
        translations: this.$$$t,
        editModeData: this.editItem,
      }
      return attrs
    }
  },
  async created() {
    this.playcardQuantity = 1

    this.editMode = !!this.token || !!this.item

    if (this.editMode) {
      this.termsAgreed = 'yes'
      this.editItem = this.currentCartItems[this.token || this.item]
    }

    this.playcardProducts = this.getProducts(this.products)
    this.cardsList = _.cloneDeep(this.playcardProducts)

    EventBus.$on('buy-card.change.productId', productId => {
      this.playcardProductId = productId
    })
    EventBus.$on('buy-card.change.quantity', quantity => {
      //this.playcardQuantity = quantity
    })

    EventBus.$on('buy-card.add-to-cart', async (data, options) => {
      const {preventCheckout} = options || {}
      const item = this.getPlaycardCartItem(data)

      if (!preventCheckout) {
        this.isSubmitting = true
      }

      let success
      if (this.editMode) {
        success = await this.updateCartItem(item)
      } else {
        success = await this.addCartItem(item)
      }

      if (!success) {
        this.isSubmitting = false
      }

      if (success) {
        this.init()
        await EventBus.$$emitAsync('buy.giftcard.add-to-cart', options)
        if (preventCheckout) {
          await this.showInsertSuccessMessage(data)
        } else {
          this.$router && this.$router.push({ name: 'cart' })
        }
      }
    })

    if (this.user) {
      await this.fetchUser()
    }
  },
  methods: {
    init() {
      this.$refs.buyCard.init()
    },
    mergeCartEditItemProduct(products){
      if (!_.isEmpty(this.editItem)) {
        const itemProduct = _.get(this.editItem, 'attributes.original_product',
          _.get(this.editItem, 'attributes.product'))

        if (itemProduct) {
          products = _.map(products, product => {
            if (product.id === itemProduct.id) {
              return _.merge({}, product, _.cloneDeep(itemProduct))
            }
            return product
          })
        }
      }
      return products
    },
    getProducts(products) {
      products = this.parseProducts(products || window.addiesaas.products.types[this.productType])

      products = this.autoApplyFilterOnProducts(_.values(products))
      products = this.mergeCartEditItemProduct(products)

      const params = {products, vm: this}
      this.$$applyDataFilters('buy.giftcard.prepare.products', params)
      return params.products
    },
    parseProducts(products) {
      products = _.cloneDeep(_.filter(_.values(products), p => {
        return !(1 * _.get(p, 'custom.skip-regular'))
      }))

      const additionalProperties = this.$$s('product.additionalProperties') || {}
      _.forOwn(products, product => {
        if (!_.has(product, 'upsellProduct')) {
          product.upsellProduct = null
        }
        _.forOwn(additionalProperties, (value, key) => {
          if (!_.has(product, key)) {
            product[key] = value
          }
        })
      })
      return products
    },
    getPlaycardCartItem(data) {
      const product = data.product
      const id = _.uniqueId(`giftcard_` + (new Date().valueOf()))
      const otherOptions = {}
      otherOptions[this.customAmountCartItemProperty] = data.custom_amount

      let item = this.editMode ? this.editItem : {
        id,
        type: product.type || this.productType || 'giftcard_sale',
        editRoute: {name: 'giftcard.buy'}
      }
      item = _.merge({}, item, {
        editable: this.cartItemEditable,
        total: data.product.price * data.quantity,
        count: data.quantity,
        payload: {
          product_id: data.product.id,
          count: data.quantity,
          options: {
            location_id: data.location_id,
            ...otherOptions,
          }
        },
        attributes: {
          product: data.product,
        },
      })

      return item
    },
    async showInsertSuccessMessage(data) {
      const showAs = this.showInsertSuccessMessageAs
      if (showAs) {
        const message = this.parsePluralize(this.insertSuccessMessage, data)
        const options = this.insertSuccessMessageOptions
        if (showAs === 'alert') {
          await this.successAlertAsync(message, options)
        } else {
          toastAlert(message, 'success', options)
        }
      }
    }
  }
}
