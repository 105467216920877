/*  global _ */
/*eslint no-console: 0*/

const EventBus = window.VueEventBus
import {getEmitter} from '../../../lib/utils'

const emit = getEmitter('re-toast-alert')

export default {
  name: 're-toast-alert',
  props: {
    id: {
      type: [String, Function],
      default: ''
    },
    message: {
      type: [String, Function],
    },
    type: {
      type: [String, Function],
      default: 'info' // success, warning, danger
    },
    size: {
      type: [String, Number, Function],
      default: 'full' // full, fit, medium, small, mini, micro, nano, tiny, large, {#}%, {#}px
    },
    toast: {
      type: [Boolean, Function],
      default: false
    },
    center: {
      type: [Boolean, Function],
      default: false
    },
    noIcon: {
      type: [Boolean, Function],
      default: false
    },
    clickToClose: {
      type: [Boolean, Function],
      default: false
    },
    clickOverlayToClose: {
      type: [Boolean, Function],
      default: false
    },
    dontDestroy: {
      type: [Boolean, Function],
      default: false
    },
    block: {
      type: [Boolean, Function],
      default: false
    },
    closer: {
      type: [Boolean, Function],
      default: false
    },
    containerClass: {
      type: [Array, String, Object, Function],
      default: ''
    },
    classes: {
      type: [Array, String, Object, Function],
      default: ''
    },
  },
  data() {
    return {}
  },
  computed: {
    me() {
      return this
    },
    myClasses() {
      return [
        this.classes || '',
        {
          're-toast-alert-fly': this.toast,
          're-toast-alert-inline': !this.toast,
          're-toast-alert-center': this.center,
          're-toast-alert-no-center': !this.center,
          're-toast-alert-no-icon': this.noIcon,
          're-toast-alert-clickable': this.clickToClose,
          ['re-toast-alert-' + this.type]: true,
          ['re-toast-alert-size-' + this.size]: !this.getSizeStyle(),
        }
      ]
    },
    myStyles() {
      return [this.getStyles()]
    },
    containerClasses() {
      return [
        this.containerClass || '',
        {
          'blocked-screen': this.block,
        }
      ]
    },
  },

  // beforeCreate() {
  //   emit('beforeCreate', {}, this)
  // },
  created() {
    emit('created', {}, this)
  },
  beforeMount() {
    emit('beforeMount', {}, this)
  },
  mounted() {
    emit('mounted', {}, this)
  },
  beforeUpdate() {
    emit('beforeUpdate', {}, this)
  },
  updated() {
    // emit('updated', {}, this)
  },
  beforeDestroy() {
    emit('beforeDestroy', {}, this)
  },
  destroyed() {
    emit('destroyed', {}, this)
  },
  errorCaptured(error, component, info) {
    emit('errorCaptured', {error, component, info}, this)
  },
  methods: {
    close() {
      this.closeMe()
    },
    update(data) {
      if (data.message) {
        this.message = data.message
      }
      if (data.type) {
        this.type = data.type
      }
    },
    getStyles() {
      return {...this.getSizeStyle()}
    },
    getSizeStyle() {
      const size = this.size || ''
      const isStyle = /^(\d+?)?(\.\d+?)?(px|%)?$/.test(size)
      if (isStyle) {
        const width = (size + '').replace(/(\d$)/, '$1px')
        return {width}
      }
      return null
    },
    closeMe() {
      if (!this.dontDestroy) {
        this.$el.remove()
        this.$destroy()
      }
    },
    overlayClicked(event) {
      emit('overlay.clicked', {event}, this)
      if (this.clickOverlayToClose) {
        event.stopPropagation()
        this.closeMe()
      }
    },
    bodyClicked(event) {
      emit('clicked', {event}, this)
      if (this.clickToClose) {
        event.stopPropagation()
        this.closeMe()
      }
    },
    closeClicked(event) {
      event.stopPropagation()
      emit('close', {event}, this)
      this.closeMe()
    }
  }
}
