export const name = 're-buy-playcard'

// generic setup
import {
  allValidationsRules as validationsRules,
  defaultFormFields,
} from './form'

import props from './props'

export {props, validationsRules, defaultFormFields}
// noinspection NpmUsedModulesInstalled
import BuyPlaycardFormMixin from '../../../../mixins/external/buy-playcard-form'
import CartMixins from '../../../../mixins/cart'
import ComponentMixin from '../../../../mixins/components/complex-reactive'
import FormMixin from '../../../../mixins/form'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'
import ProductsFilterMixin from '../../../../mixins/store/products-filter'
import ShopFailingLocationMixin from '../../../../mixins/shop/failing-location'
import DesignerMixin from './designer'

export const mixins = [
  ComponentMixin,
  FormMixin,
  BuyPlaycardFormMixin,
  CartMixins,
  FormForceUpdateMixin,
  ProductsFilterMixin,
  ShopFailingLocationMixin,
  DesignerMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
