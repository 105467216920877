/*global _ */
import {getFirstByKeys} from '../../lib/formatters'

import AlertMixin from '../alert'

export default {
  mixins: [AlertMixin],
  props: {
    multiLocationItems: {
      default() {
        /**
         * {
         *    disabled: false, // false: allow multi-location cart items
         *    location: {probe: []}}, // array of path e.g., location_id
         *            (to be searched in item.payload.options, item.payload, item.options and item)
         *    add: {error: {message, title}}
         *    update: {error: {message, title}}
         *  }
         *
         */
        const $t = this.$options.$$t('multiLocationItems', {})
        const $s = this.$options.$$s('multiLocationItems', {})
        return _.merge({}, $t, $s)
      }
    }
  },
  methods: {
    mayAddLocationToOrder(promiseId, order, options) {
      const config = this.multiLocationItems || {}
      const prevent = _.get(config, 'disabled', false)
      if (prevent) {
        const locationPaths = _.castArray(
          _.get(config, 'location.probe', ['location_id'])
        )
        const item = _.find(order.items, item => !!getItemLocation(item, locationPaths))
        if (item) {
          const locationId = getItemLocation(item, locationPaths)
          if (locationId) {
            _.set(options, 'config.headers.X-Location', locationId)
            _.set(order, 'location', locationId)
          }
        }
      }
    },
    async mayPreventUpdateCartItem(item, items) {
      await this.mayPreventAddToCart(item, items, true)
    },
    async mayPreventAddToCart(item, items, isUpdate) {
      const options = this.multiLocationItems || {}
      const prevent = _.get(options, 'disabled', false)

      if (prevent) {
        const mode = isUpdate ? 'update' : 'add'

        const locationPaths = _.castArray(
          _.get(options, 'location.probe', ['location_id'])
        )

        const itemLocation = getItemLocation(item, locationPaths)
        if (itemLocation) {
          const hasDifferentLocation = _.some(items, cartItem => {
            const otherLocation = getItemLocation(cartItem, locationPaths)
            return otherLocation && cartItem.id !== item.id && otherLocation !== itemLocation
          })

          if (hasDifferentLocation) {
            const message = _.get(options, `${mode}.error.message`,
              'An item already exists for a location different from the selected location.'
            )
            const title = _.get(options, `${mode}.error.title`,
              `Failed to ${mode} product to cart.`
            )
            const uniqueId = 'noMultiLocationCartItem'
            this.alertAsync(message, title, uniqueId, {uniqueId})
            const e = new Error(message)
            e.data = {item}
            throw e
          }
        }
      }
    }
  }
}

export const getItemLocation = (item, locationPaths) => {
  for (let path of locationPaths) {
    const value = _.isFunction(path) ? path(item) :
      getFirstByKeys(
        item,
        ...[`payload.options.${path}`, `payload.${path}`, `options.${path}`, path]
      )
    if (!_.isUndefined(value)) {
      return value
    }
  }
}
