/*global _ */
import VueCore from 'vue'
import Vuex from 'vuex'

const AD = 'addiesaas'
const findOrCreate = (object, path, value, callback) => {
  if (_.isString(object)) {
    callback = value
    value = path
    path = object
  }
  if (_.isString(object) || !object) {
    object = addiesaas
  }
  const found = _.has(object, path)
  if (!found) {
    _.set(object, path, value)
  }
  const item = _.get(object, path)
  if (_.isFunction(callback)) {
    callback({item, found})
  }
  return item
}
export const addiesaas = findOrCreate(window, AD, {})
findOrCreate('$fn.findOrCreate', findOrCreate)
findOrCreate('$fn._', _)
export const Vue = findOrCreate('$core.Vue', window.Vue || (window.Vue || VueCore))
export {Vuex}
window.Vue = Vue
export default addiesaas
