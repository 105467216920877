/*global _ */
import FormMixin from '../../../../../mixins/form'
import FailingCardLocationMixin from '../../../../../mixins/shop/card/failing-location'

import V, {maxValue, setValidationRule} from '../../../../../lib/validations'
const fields = {
  from: {
    label: 'From',
    type: 'select',
    blankOption: 'Select Card',
    // filter: ['{[context.fields.to]}', 'notEquals', '{[item.id]}'],
    reduceValue: '{[id]}',
    reduceLabel: '{[number]}',
    validations: setValidationRule('fields.from', {
      required: {error: 'From is required.'}
    })
  },
  to: {
    label: 'To',
    type: 'select',
    blankOption: 'Select Card',
    // filter: ['{[context.fields.from]}', 'notEquals', '{[item.id]}'],
    reduceValue: '{[id]}',
    reduceLabel: '{[number]}',
    validations: setValidationRule('fields.to', {
      required: {error: 'To is required.'},
      different: {
        error: 'From card and To card must be different.',
        ruleFn: V.different('from')
      }
    })
  },
  balance: {
      label: 'Balance',
      validations: setValidationRule('fields.balance', {
        required: {error: 'Balance is required.'}
      })
    }
}

export default {
  mixins: [FormMixin, FailingCardLocationMixin],

  data() {
    return {
      selectedCardProperty: 'fromCard',
      selectedCardField: 'fields.from',
      fields: {
        ...(this.inputs || {}),
        balance: this.balance || null,
        from: this.from || null,
        to: this.to || null,
      },
      transferLimits: {
        balance: null
      }
    }
  },

  computed: {
    form() {
      return _.merge({}, {fields}, this.formOptions)
    },
    formFields() {
      const fields = this.form.fields
      return _.mapValues(fields, (field, key) => {
        field.name = field.name || key
        if (!field.hydrate) {
          if (field.name === 'from') {
            field.options = this.getFromCards
          }
          if (field.name === 'to') {
            field.options = this.getToCards
          }
        }
        return field
      })
    },
    formFieldOrder() {
      return this.form.$fieldsOrder || this.form.fieldsOrder || this.form.order ||
        _.reduce(this.formFields, (names, field, key) => {
          names.push(field.name || key)
          return names
        }, [])
    },
    formFieldOrdered() {
      return _.reduce(this.formFieldOrder, (fields, fieldName) => {
        const field = this.formFields[fieldName]
        if (field) {
          fields.push(field)
        }
        return fields
      }, [])
    },
    allCards() {
      const cards = _.cloneDeep(_.filter(_.values(this.cards), card => !card.is_locked))
      const params = {cards, vm: () => this}
      this.$$applyDataFilters(`playcard.cards`, params)
      this.$$applyDataFilters(`${this.eventSpace}.cards`, params)
      return params.cards
    },
  },
  watch: {
    'fields.from'() {
      this.transferLimits = this.getTransferLimits()
    }
  },
  created() {
    this.setupAutoForm()
    this.init()
    this.$v.$reset()
  },
  methods: {
    init() {
      this.transferLimits = this.getTransferLimits()
    },
    getTransferLimits() {
      const limitsDefs = this.transferLimitDefs
      return _.mapValues(limitsDefs, (def) => {
          return _.reduce(_.castArray(def), (sum, prop) => {
            return sum + ((1 * _.get(this.fromCard, prop)) || 0)
          }, 0)
      })
    },
    getTransferLimit(prop) {
      return _.get(this.transferLimits, prop)
    },
    getLoadedCard(number) {
      return this.findCard(number)
    },
    getToCards() {
      let cards = this.allCards
      if (this.filterCardByLocation) {
        const fromCard = this.fromCard
        const fromLocation = fromCard && fromCard.location_id
        const toCard = this.toCard
        const toLocation = toCard && toCard.location_id
        if (fromLocation) {
          cards  = cards.filter(card => card.id !== fromCard.id && card.location_id === fromCard.location_id)
          if (toLocation && toLocation !== fromLocation) {
            this.fields.to = null
          }
        }
      }
      const params = {cards, vm: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.to.cards`, params)
      return params.cards
    },
    getFromCards() {
      let cards = this.allCards
      if (this.filterCardByLocation) {
        const toCard = this.toCard
        const toLocation = toCard && toCard.location_id
        if (_.isEmpty(this.fromCard) && toLocation) {
          cards  = cards.filter(card => card.id !== toCard.id && card.location_id === toLocation)
        }
      }
      const params = {cards, vm: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.from.cards`, params)
      return params.cards
    },

    getFormFieldAttrs(field) {
      const key = field.name
      const $$v = this.$v
      const validations = this.validations[key] || {}
      return {
        key,
        validations,
        $$v,
        field,
        vRoot: $$v.fields,
        v: $$v.fields[key],
        context: this,
        classes: this.getFieldContainerClasses(key),
        customErrors: this.fieldCustomErrors[key],
      }
    },
    parseDynamicAttrs(attrs) {
      const values = _.mapValues(attrs, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.formatterDataSource
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    resetFields() {
      _.forOwn(this.fields, (value, key) =>  {
        this.fields[key] = null
      })
    },
    setupValidationRules(validationRules, fieldKey, parseValidations) {
      if (this.validateFailingCardLocation) {
        this.setupFailingCardLocationValidations(validationRules.from.items)
      }
      this.setValidationRules = function () {
        // set dynamic maxValue rules
        // @todo: Find a dynamic way to achieve this
        const rules = parseValidations(validationRules)
        const limitsDefs = this.transferLimitDefs
        _.forOwn(limitsDefs, (def, field) => {
          if (_.has(rules[field], 'maxValue')) {
              rules[field]['maxValue'] = maxValue(this.transferLimits[field])
          }
        })
        const validations = { [fieldKey]:  rules}
        return validations
      }
    },
    getFieldContainerExtraClasses(fieldName) {
      // disable - when location of card is_failing
      const classes = {}
      if (this.validateFailingCardLocation && fieldName !== 'from') {
        classes.disabled = this.isCardLocationFailing(this.fields.from)
      }
      return [classes]
    }
  },
}
