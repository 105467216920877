import {Vue} from '~/addiesaas'
import * as types from '../mutation-types/mashup-builder'

// set this true to namespace all getters, mutations, actions etc.
const namespaced =  true
export const namespace = 'Builder'

// state
//const state = {
const state = () => ({

  builder: {
    isProcessing: false,
    processingMessage: '',
    pageIndex: 0,
    pages: [],
  },

  builderForm: {
    fields: {},
    validations: {},
    options: {},
  },

  builderValidations: {
    validations: {},
    messages: {},
  },
  builderConfig: {},

  builderValues: {},
})

// mutations
const mutations = {
  [types.SET_FORM_FIELD_PROP](state, field) {
    const name = field.name
    if (!_.has(state.builderForm.fields, name)) {
      Vue.set(state.builderForm.fields, name, {})
    }
    let data = state.builderForm.fields[name]
    data = _.merge({}, data, field)
    Vue.set(state.builderForm.fields, name, data)
  },
  [types.SET_FORM_FIELD](state, field) {
    Vue.set(state.builderForm.fields, field.name, field)
  },
  [types.SET_FORM_FIELDS](state, fields) {
    Vue.set(state.builderForm, 'fields', fields)
  },
  [types.SET_FORM](state, payload) {
    state.builderForm = payload
  },
  [types.SET_CONFIG](state, {key, value}) {
    const data = state.builderConfig
    _.set(data, key, value)
    Vue.set(state, 'builderConfig', data)
  },
  [types.SET_CONFIGS](state, payload) {
    state.builderConfig = payload
  },
  [types.SET_VALUES](state, payload) {
    state.builderValues = payload
  },
  [types.SET_VALUE](state, {key, value}) {
    const data = state.builderValues
    _.set(data, key, value)
    Vue.set(state, 'builderValues', data)
  },
  [types.SET_VALIDATIONS](state, payload) {
    state.builderValidations = payload
  },
  [types.SET_VALIDATION](state, {key, value}) {
    const data = state.builderValidations
    _.set(data, key, value)
    Vue.set(state, 'builderValidations', data)
  },
  [types.SET_STORE_VALUE](state, item) {
    const storage = _.get(state, item.accessor, state)
    Vue.set(storage, item.key, item.value)
  },
}

const actions = {
  setBuilderFormFieldProp({commit}, payload) {
    commit(types.SET_FORM_FIELD_PROP, payload)
  },
  setBuilderFormField({commit}, payload) {
    commit(types.SET_FORM_FIELD, payload)
  },
  setBuilderFormFields({commit}, payload) {
    commit(types.SET_FORM_FIELDS, payload)
  },
  setBuilderForm({commit}, payload) {
    commit(types.SET_FORM, payload)
  },

  setBuilderConfigs({commit}, payload) {
    commit(types.SET_CONFIGS, payload)
  },
  setBuilderConfig({commit}, payload) {
    commit(types.SET_CONFIG, payload)
  },

  setBuilderValues({commit}, payload) {
    commit(types.SET_VALUES, payload)
  },
  setBuidlerValue({commit}, payload) {
    commit(types.SET_VALUE, payload)
  },

  setBuilderValidations({commit}, payload) {
    commit(types.SET_VALIDATIONS, payload)
  },
  setBuilderValidation({commit}, payload) {
    commit(types.SET_VALIDATION, payload)
  },

  setBuilder({commit}, payload) {
    commit(types.SET_BUILDER, payload)
  },
  setBuilderProp({commit}, payload) {
    commit(types.SET_BUILDER_PROP, payload)
  },
  setStoreValue({commit}, payload) {
    commit(types.SET_STORE_VALUE, payload)
  },
}

// getters
const getters = {
  builder: state => state.builder,
  builderForm: state => state.builderForm,
  builderFormFields: state => state.builderForm.fields,
  builderValidations: state => state.builderValidations,
  builderValues: state => state.builderValues,
  builderConfig: state => state.builderConfig,
  getStoreValue(state) {
    return function (accessor, key) {
      const storage = accessor ? state[accessor] : state
      return _.get(storage, key)
    }
  },
}

const pages = { namespaced, namespace, state, mutations, actions, getters }
export default pages
