/*global _ */
import props from '../../../../props/validations'

export default {
  name: 're-validation',
  props,
  data() {
    return {
      customErrorItems: null
    }
  },
  computed: {
    hasValidator() {
      return !_.isEmpty(this.validator) || !_.isEmpty(this.validations)
    },
    hasError() {
      const canCheckInvalid = this.checkInvalid && (
        this.isDirty || this.checkInvalidOnClean
      )
      const v = this.$getV()
      return v ?
        (canCheckInvalid ? v.$invalid : v.$anyError) :
        (
          this.hasValidator ?
            _.some(this.vItems, (i, rule) => this.vCheck(this.vName, rule))
            : false
        )
    },
    isInvalid() {
      return this.v ? this.v.$invalid : false
    },
    hasCustomError() {
      return !_.isEmpty(this.allCustomErrors)
    },
    hasErrors() {
      return this.hasError || this.hasCustomError
    },
    vName() {
      return _.get(this.validations, 'name')
    },
    vItems() {
      return _.get(this.validations, 'items', {})
    },
    allCustomErrors() {
      let errors = this.customErrorItems
      if (!_.isEmpty(this.errors) && _.isString(errors)) {
        errors = [errors]
      }
      return errors
    },
  },
  watch: {
    value: {
      handler(value) {
        this.customErrorItems = {}
        this.$emit('input', value)
      },
    },
    hasError(isError) {
      if (isError) {
        this.$emit('inputError', {name: this.vName, value: this.value})
      }
    },
    customErrors: {
      handler(value) {
        this.customErrorItems = value
      },
      immediate: true
    }
  },
  methods: {
    $getV() {
      return this.getV ? this.getV() : this.v
    },
    vCheck(vName, rule, ...rest) {
      const v = this.$getV()
      if (v) {
        const vResult = v[rule]
        if (_.isBoolean(vResult)) {
          return !vResult
        }
      }
      const validator = this.validator || _.get(this.validations, 'validator')
      if (_.isFunction(validator)) {
        return validator(vName, rule, ...rest)
      }
      return null
    },
    getCustomErrorMessage(error, key) {
      if(error instanceof Error || _.isObject(error)) {
        error = error.message
      }
      return this.parseErrorMessage(error, key)
    },
    parseErrorMessage(error, rule) {
      const v = this.$getV()
      const params = _.get(v, `$params.${rule}`, {})
      error = error.replace(`:value`, this.value)
      _.forOwn(params, (val, key) => {
        error = error.replace(`{${key}}`, val)
        error = error.replace(`:${key}`, val)
      })
      return error
    }
  },
}
