/* global _ */
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      isDisabled: this.disabled,
      isReadonly: this.readonly,
      isBeingProcessedMessage: 'Loading',
      dataFilterSpace: null,
      ...data
    }
  },
  computed: {
    tableContainerOptions() {
      return _.get(this.options, 'container', {})
    },
    tableContainerAttrs() {
      const attrs = this.tableContainerOptions.attrs || {}

      const tableView = this.isGrid ? 'grid' : 'list'
      const viewName = `view-${tableView}`
      const additionalClasses = {
        [viewName]: true,
        readonly: this.isReadonly,
        disabled: this.isDisabled,
      }
      let classes = this.parseAttrClasses(attrs, additionalClasses)

      return {
        ...attrs,
        class: classes,
      }
    },
    tableOptions() {
      return _.get(this.options, 'table', {})
    },
    tableAttrs() {
      return {
        ...this.tableOptions.attrs || {}
      }
    },
    tableHeadOptions() {
      return _.get(this.options, 'head', {})
    },
    tableHeadAttrs() {
      return {
        ...this.tableHeadOptions.attrs || {}
      }
    },
    showTableHead() {
      return this.tableHeadOptions.show !== false
    },
    tableBodyOptions() {
      return _.get(this.options, 'body', {})
    },
    tableBodyAttrs() {
      return {
        ...this.tableBodyOptions.attrs || {}
      }
    },
    tableViewOptions() {
      return _.get(this.options, 'view', 'list')
    },
    tableView() {
      // auto/list/grid
      return this.tableViewOptions.type || 'list'
    },
    autoGrid() {
      return this.tableView == 'auto'
    },
    autoGridMaxWidth() {
      return _.get(this.tableViewOptions, 'auto.grid.maxWidth', 992)
    },
    isListViewNotPossible() {
      return this.autoGrid && this.appWidth <= this.autoGridMaxWidth
    },
    calculatedTableView() {
      const views = {grid: 'grid', list: 'list', auto: 'list', '': 'list'}
      const view = views[this.tableView || '']
      return this.isListViewNotPossible ? 'grid' : view
    },
    isGrid() {
      return this.calculatedTableView == 'grid' || this.isRepeater
    },
  },
  watch: {
    disabled(value) {
      this.isDisabled = value
    },
    readonly(value) {
      this.isReadonly = value
    },
    calculatedTableView: {
      handler(view) {
        if (view) {
          this.emitTableEvent('view', view, this)
        }
      },
      immediate: true
    },
    appWidth: {
      handler(width, previousWidth) {
        if (width) {
          this.emitTableEvent('width', width, previousWidth, this)
        }
      },
      immediate: true
    }
  },
  created() {
    const spaceName = `re-table`
    this.eventSpace = this.options.eventSpace || spaceName
    this.dataFilterSpace = this.options.dataFilterSpace || this.eventSpace
    this.emitTableEvent(`created`, this)
  },
  beforeDestroy() {
    this.emitTableEvent(`before-destroy`, this)
  },
  methods: {
    emitTableEvent(name, ...args) {
      this.emitSlim(name, ...args)
      const tableName = this.name
      if (tableName) {
        this.emitSlim(`${tableName}.${name}`, ...args)
      }
    },
    applyDataFilter(name, ...args) {
      const filterName = `${this.dataFilterSpace}.${name}`
      this.$$applyDataFilters(filterName, ...args)
      const tableName = this.name
      if (tableName) {
        this.$$applyDataFilters(filterName, ...args)
        this.$$applyDataFilters(`${tableName}.${filterName}`, ...args)
      }
    },
    probeIf(def, contraDef, defaultValue) {
      if (_.isBoolean(def)) {
        return def
      }
      if (_.isBoolean(contraDef)) {
        return def
      }

      const data = this.parseSourceData()

      if (_.isObject(def) && def['if']) {
        return this.probeReactiveIf(def['if'], data)
      }

      if (_.isObject(contraDef) && contraDef['if']) {
        return !this.probeReactiveIf(contraDef['if'], data)
      }

      return defaultValue
    },
    parseDynamicAttrs(attrs, value, mergeData = {}) {
      const data = {$: value, this: this, ...mergeData}
      const values = _.mapValues(attrs || {}, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def, value, mergeData)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
  }
}
