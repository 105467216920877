import basicComponents from './basic'
import elementComponents from './elements'
import modalComponents from './modals'
import complexComponents from './complex'
import pageComponents from './pages'

export default {
  ...basicComponents,
  ...elementComponents,
  ...modalComponents,
  ...complexComponents,
  ...pageComponents
}
