/*global _ */
export const name = 're-core-page-user-forgot-password'
export const component = 're-core-page-user-login'

import {factory} from '../../../../lib/page'

const moduleOptions = factory(name, {component})
export const {translations, settings} = moduleOptions

const props = {email: {}, ..._.pick(moduleOptions.props, ['translations', 'settings'])}

// export default module
export default {
  ..._.pick(moduleOptions, ['name', 'mixins', 'events', 'data']),
  props
}
