import {Vue} from '~/addiesaas'
//cart
import ReShoppingCart from './re-shopping-cart'
Vue.component(ReShoppingCart.name, ReShoppingCart)

import ReCartEmpty from './general/re-cart-empty'
Vue.component(ReCartEmpty.name, ReCartEmpty)

import ReCartList from './general/re-cart-list'
Vue.component(ReCartList.name, ReCartList)

import ReCartBilling from './general/re-cart-billing'
Vue.component(ReCartBilling.name, ReCartBilling)

import ReCartBillingExtended from './general/re-cart-billing-extended'
Vue.component(ReCartBillingExtended.name, ReCartBillingExtended)

import ReCartFinal from './general/re-cart-final'
Vue.component(ReCartFinal.name, ReCartFinal)

import ReCartError from './general/cart-elements/re-cart-error'
Vue.component(ReCartError.name, ReCartError)

import ReCartPaymentInfo from './general/cart-elements/re-cart-payment-info'
Vue.component(ReCartPaymentInfo.name, ReCartPaymentInfo)

import ReCartRedemption from './general/cart-elements/re-cart-redemption'
Vue.component(ReCartRedemption.name, ReCartRedemption)

import ReCartTimer from './general/cart-elements/re-cart-timer'
Vue.component(ReCartTimer.name, ReCartTimer)

import ReCartSummary from './general/cart-elements/re-cart-summary'
Vue.component(ReCartSummary.name, ReCartSummary)

import ReCartItem from './cart-items/re-cart-item'
Vue.component(ReCartItem.name, ReCartItem)

import ReMiniCart from './re-mini-cart'
Vue.component(ReMiniCart.name, ReMiniCart)

import ReCartItemCard from './cart-items/re-cart-item-card'
Vue.component(ReCartItemCard.name, ReCartItemCard)

import ReCartItemBooking from './cart-items/re-cart-item-booking'
Vue.component(ReCartItemBooking.name, ReCartItemBooking)

export default {
  ReShoppingCart,
  ReCartEmpty,
  ReCartList,
  ReCartBilling,
  ReCartBillingExtended,
  ReCartFinal,
  ReCartError,
  ReCartPaymentInfo,
  ReCartRedemption,
  ReCartTimer,
  ReCartSummary,
  ReCartItem,
  ReMiniCart,
  ReCartItemCard,
  ReCartItemBooking,
}
