/* global _ */
import {name, mixins, props, data, validationsRules, parseValidations, parseValidationSetup, EventBus} from './config'
import MainService from '../../../../../../services/main'

const validationGroupRules = {
  giftcardGroup: [
    'fields.giftcard_number', 'fields.giftcard_cvv'
  ],
  playcardGroup: [
    'fields.playcard_number', 'fields.playcard_cvv'
  ],
  couponGroup: [
    'fields.coupon'
  ]
}

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      showInfoModal: false,
      isGiftcard: false,
      isNumberInfo: false,
      showResendModal: false,
      recoverSuccess: false,
      isProgressing: false,
      isFormHidden: this.hideForm === true,
      validations: {},
      giftcard: {}, //@todo: added when giftcard mixin is absent, remove if does not work otherwise
    }
  },

  validations() {
    return {
      fields: parseValidations(validationsRules),
      ...validationGroupRules
    }
  },

  watch: {
    resendModalSuccess(newVal) {
      this.recoverSuccess = newVal
    }
  },

  created() {
    this.validations = parseValidationSetup(validationsRules, this)
    this.setValidationError(null)
  },

  computed: {
    isLoading() {
      return this.isPromising || !!this.loadingMessage
    },
    infoTitle() {
      let text = ''
      if (this.isGiftcard) {
        text = this.isNumberInfo ?  this.giftcardNumberInfoTitle : this.giftcardCodeInfoTitle
      } else {
        text = this.isNumberInfo ?  this.playcardNumberInfoTitle : this.playcardCodeInfoTitle
      }
      return text
    },
    infoHeaderText() {
      let text = ''
      if (this.isGiftcard) {
        text = this.isNumberInfo ?  this.giftcardNumberInfoHeader : this.giftcardCodeInfoHeader
      } else {
        text = this.isNumberInfo ?  this.playcardNumberInfoHeader : this.playcardCodeInfoHeader
      }
      return text
    },
    infoFooterText() {
      let text = ''
      if (this.isGiftcard) {
        text = this.isNumberInfo ?  this.giftcardNumberInfoFooter : this.giftcardCodeInfoFooter
      } else {
        text = this.isNumberInfo ?  this.playcardNumberInfoFooter : this.playcardCodeInfoFooter
      }
      return text
    },
    infoImgSrc() {
      let text = ''
      if (this.isGiftcard) {
        text = this.isNumberInfo ?  this.giftcardNumberInfoImgSrc : this.giftcardCodeInfoImgSrc
      } else {
        text = this.isNumberInfo ?  this.playcardNumberInfoImgSrc : this.playcardCodeInfoImgSrc
      }
      return text
    },
    displayResendModal() {
      return (this.hasGiftcardForgotCodeLink || this.hasHelpPlaycardNumberLink) && this.showResendModal
    },

    showGiftcard() {
      return this.showGiftcardRedmptions && !this.hasOnlyGiftcards
    },
    disabledGiftcardApllyButton() {
      return this.$v.giftcardGroup.$invalid
    },
    disabledPlaycardApllyButton() {
      return this.$v.playcardGroup.$invalid
    },
    disabledCouponApplyButton() {
      return !this.$v.fields.coupon.required
    },
    isLoadingGiftcard() {
      return _.get(this, 'giftcard.isAdding')
    },
    isLoadingPlaycard() {
      return this.isProgressing  && !this.isGiftcard
    },
    isLoadingCoupon() {
      return this.isProgressing
    },
    showErrorMessage() {
      return this.giftcard.error && this.giftcard.errorType !== 'number' && this.giftcard.errorType !== 'cvv'
    },
    giftcardErrorMessage() {
      return this.giftcard.error || ''
    },
    discountApplied() {
      return !_.isEmpty(this.cartDiscounts)
    },
    formButtonTitle() {
      return this.discountApplied ? this.showFormButtonTitleAfterApply :
        this.showFormButtonTitle
    },
    formButtonMessage() {
      return !this.isFormHidden || !this.discountApplied ?
        this.couponRedemptionTitle :
        this.couponRedemptionAppliedTitle
    },
    hasRedemptionErrors() {
      return !_.isEmpty(this.redemptionErrors)
    },
    couponDiscountErrors() {
      const allItems = this.redemptionErrors.discounts
      const items = _.pickBy(allItems, item => item.type === 'coupon')
      return _.isEmpty(items) ? null : items
    },
    redemptionErrors() {
      const validationErrors = this.validationErrors
      if (validationErrors) {
        const errorMessages = _.reduce(validationErrors.errors, (carry, item, path) => {
          _.set(carry, path, item)
          return carry
        }, {})

        const discounts = _.cloneDeep(_.values(this.cartDiscounts))
        const errors = _.mapValues(errorMessages, details => {
          return _.mapValues(details, (error, index) => {
            return {
              ...(discounts[index] || {}),
              error,
            }
          })
        })
        return _.pick(errors, ['discounts'])
      }
      return {}
    }
  },

  methods: {
    giftcardNumberInfo() {
      this.showInfoModal = true
      this.isGiftcard = true
      this.isNumberInfo = true
    },
    giftcardCodeInfo() {
      this.showInfoModal = true
      this.isGiftcard = true
      this.isNumberInfo = false
    },
    playcardNumberInfo() {
      this.showInfoModal = true
      this.isGiftcard = false
      this.isNumberInfo = true
    },
    playcardCodeInfo() {
      this.showInfoModal = true
      this.isGiftcard = false
      this.isNumberInfo = false
    },
    closeInfoModal () {
      this.showInfoModal = false
      this.isGiftcard = false
      this.isNumberInfo = false
    },

    giftcardForgotCode() {
      this.showResendModal = true
      this.isGiftcard = true
    },
    playcardForgotCode() {
      this.showResendModal = true
      this.isGiftcard = false
    },
    closeResendModal() {
      this.showResendModal = false
      this.isGiftcard = false
      this.recoverSuccess = false
    },
    recoverByEmail(data) {
      this.$emit('recoverByEmail', data)
      EventBus.$emit('cart.resend-code.by-email', data)
    },
    recoverByNumber(data) {
      this.$emit('recoverByNumber', data)
      EventBus.$emit('cart.resend-code.by-number', data)
    },
    async applyGiftcard(){
      this.$v.giftcardGroup.$touch()
      this.formSubmitting = true
      if (!this.$v.giftcardGroup.$anyError) {
        this.giftcard.number = this.giftcard_number
        this.giftcard.cvv = this.giftcard_cvv
        EventBus.$emit('cart.redemption.before.apply-giftcard', this.giftcard)
        try {
          await this.addGiftcard()
          this.formSubmitting = false
          EventBus.$emit('cart.redemption.apply-giftcard', this.giftcard)
        } catch(e) {
          EventBus.$emit('cart.redemption.apply-giftcard.error', e)
        }
      }
    },
    applyPlaycard(){
      this.$v.playcardGroup.$touch()
      this.formSubmitting = true
      if (!this.$v.playcardGroup.$anyError) {
        const request = {
          number: this.fields.playcard_number,
          cvv: this.fields.playcard_cvv
        }
        EventBus.$emit('cart.redemption.before.apply-playcard', request)
        this.$emit('applyPlaycard', request)
        EventBus.$emit('cart.redemption.apply-playcard')
      }
    },
    async applyCoupon() {
      this.$v.couponGroup.$touch()
      this.formSubmitting = true
      if (!this.$v.couponGroup.$anyError) {
        const code = this.fields.coupon
        EventBus.$emit('cart.redemption.before.apply-coupon', {code})
        this.$emit('applyCoupon', {code})
        EventBus.$emit('cart.redemption.apply-coupon')
        try {
          this.loadingMessage = 'Applying coupon'
          const data = await this.promise('cart.discounts.coupon.check', {code})
          this.addDiscount(this.createDiscountCoupon(code))
          this.loadingMessage = ''
          await this.validateCart(true)
          this.$v.couponGroup.$reset()
          this.fields.coupon = ''
          if (this.hideForm) {
            this.showForm(false)
          }
        } catch (e) {
          this.loadingMessage = ''
          // this.alertAsync('Error in coupon code!')
          const message = MainService.getFirstErrorMessage(e, e.message || 'Unknown error!')
          this.alertAsync(message)
          this.scrollTo('.re-redemption-card-coupon')
        } finally {
          //
        }

      } else {
        this.scrollTo('.re-redemption-card-coupon')
      }
    },
    showForm(state = true) {
     this.isFormHidden = !state
    },
    getClasses() {
      return [
        {
          'with-errors': this.hasRedemptionErrors
        }
      ]
    },
    getCouponDiscountErrorMessage(discount)  {
      discount.code = _.get(discount, 'payload.options.code')
      discount.message = _.get(discount, 'error.code').join(', ')
      const template = this.$$t('coupon.discounts.errors.message',
        'An error occurred with coupon - {{code}}. {{message}}. ' +
        'But you can still complete your order without discount.')
      return this.parseReactiveValuesByTemplate(template, discount)
    },
    async removeCouponDiscount(coupon) {
      this.removeCartDiscount(coupon)
      await this.validateCart(true)
    },
  },
}
