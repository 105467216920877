/*global _ */
import {getFirst} from '../../lib/formatters'
import ResponsiveMixin from '../../mixins/responsive'
import ReactiveMixin from '../../mixins/mashup-builder/reactives'

export const EventBus = window.VueEventBus

export default {
  mixins: [ReactiveMixin, ResponsiveMixin],
  data() {
    return {
      SELECT_EVENT: 'profile.select-tab',
      MENU_CLASS_PREFIX: 'tab-menu-item',
      selected: null,
      selectedDropdown: null,
    }
  },
  computed: {
    hasMenuHeadComponent() {
      return !!this.menuHeadComponent
    },
    hasMenuFootComponent() {
      return !!this.menuFootComponent
    },
    menuHeadComponentAttributes() {
      const attrs = this.menuHeadComponentAttrs || {}
      let parseAttrs = this.parseDynamicAttrs(this.menuHeadComponentParseAttrs)
      return {
        ...attrs,
        ...parseAttrs,
      }
    },
    menuHeadComponentOn() {
      return this.getComponentListeners(this.menuHeadComponentListeners)
    },
    menuFootComponentAttributes() {
      const attrs = this.menuFootComponentAttrs || {}
      let parseAttrs = this.parseDynamicAttrs(this.menuFootComponentParseAttrs)
      return {
        ...attrs,
        ...parseAttrs,
      }
    },
    menuFootComponentOn() {
      return this.getComponentListeners(this.menuFootComponentListeners)
    },
    allMenus() {
      return _.keyBy(this.menu, 'key')
    },
    selectedMenu() {
      return this.allMenus[this.selected]
    },
  },
  methods: {
    getProfileLink(hash) {
      if (this.routeIsProfile) {
        return '#' + (hash || this.selected || ((this.getSelectedMenuFromUrl() || {}).key || ''))
      }
      const route = this.profileLink || {name: 'user.profile'}
      const routeName = _.isString(route) ? route : route.name
      return this.getRouteUrl(routeName, {}, hash)
    },
    async initLogout() {
      const confirmLabel = this.logoutModalConfirmButtonTitle
      const denyLabel = this.logoutModalCancelButtonTitle
      const confirm = await this.confirmAsync(
        this.logoutModalTitle,
        '',
        {
          confirmLabel,
          denyLabel
        }
      )
      if (confirm) {
        this.logoutProfile()
        return true
      }
    },
    logoutProfile() {
      this.logout()
    },
    getMenuItemExtraClasses(key) {
      return []
    },
    getMenuItemClass(key) {
      return [
        this.menuItemClasses,
        this.MENU_CLASS_PREFIX,
        _.kebabCase(`${this.MENU_CLASS_PREFIX}-${key}`),
        _.get(this.allMenus, `${key}.class`),
        {
          active: this.active(key)
        },
        ..._.castArray(this.getMenuItemExtraClasses(key))
      ]
    },
    getSelectedMenuFromUrl() {
      if (this.routeIsProfile) {
        const hash = window.location.hash.replace(/^#/, '')
        return this.menu.find(x => x.key == hash) || this.defaultTab ||  this.menu[0] || {}
      }
      return {}
    },
    selectDefault() {
      const menu = this.getSelectedMenuFromUrl()
      this.select(menu)
    },
    select(item, skipHash) {
      if (_.isObject(item)) {
        item = item.key
      }
      if (this.routeIsProfile && !skipHash) {
        if (!this.isDesignerMode &&  !this.designerMode) {
          window.location.hash = item
        }
      }
      this.selected = item
      this.selectedDropdown = item
      EventBus.$emit(this.SELECT_EVENT, item)
    },
    async dropdownMenuSelect(item) {
      if (item === 'logout') {
        const confirm = await this.initLogout()
        if (!confirm) {
          await this.$nextTick()
          this.selectedDropdown = this.selected
        }
        return
      }
      this.select(item)
    },
    active(item) {
      return this.selected === item
    },
    parseSourceData() {
      return {
        'this': this,
        '$': this.selectedMenu,
      }
    },
    parseDynamicAttrs(attrs) {
      const values = _.mapValues(attrs || {}, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.parseSourceData()
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    getComponentListeners(listeners) {
      listeners = _.mapValues(listeners, (listener) => {
        if (_.isObject(listener)) {
          const data = this.parseSourceData()
          const method = this.probeReactiveFunctions(listener, data, true)
          if (method) {
            listener = method
          }
        }
        if (_.isString(listener)) {
          listener = listener.replace(/^@/, '')
          listener = getFirst(listener, this, this.$parent)
        }
        return listener
      })

      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      return listeners
    },
  }
}
