/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E} from '../../../lib/constants'

export default function () {
  const bus = this
  const vm = this

  bus.$on(E.VIEW_DESTROY + ':after', (...args) => {
    return
    // const arg = _.first(args)
    // arg.vm = vm
    // //@todo: Fix vue instance cleanup
    // const {component} = args[0] || {}
    // const el = vm.$el
    // const parentNode = el && el.parentNode
    // vm.$destroy && vm.$destroy()
    // if (parentNode) {
    //   parentNode.removeChild && parentNode.removeChild(el)
    // } else {
    //   vm.$el = null
    // }
    // const cleanup = component.get('destroyVueInstance')
    // if (_.isFunction(cleanup)) {
    //   cleanup()
    // }
  })
}
