import {
  setValidationRule,
  truthyRule,
  helpers,
  parseValidations,
  parseValidationSetup
} from '../../../../lib/validations'

export const defaultFormFields = {
  reloadType: '',
  cardType: '',
  card: '',
  product: '',
  number: '',
  cvv: '',
  terms: '',
  custom_amount: null,
  location_id: null
}

export const allValidationsRules = {
  reloadType: setValidationRule('fields.reloadType'),

  card: setValidationRule('fields.card'),
  product: setValidationRule('fields.product'),
  number: setValidationRule('fields.number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Card code is required.'},
    digits: {error: 'Card code is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  terms: setValidationRule('fields.terms', {
    truthy: {error: 'Please agree to the Terms & Conditions', rule: truthyRule},
  }),
  custom_amount: {
    name: 'fields.custom_amount'
  },
  location_id: {
    name: 'fields.location_id'
  },
}

export {parseValidations, parseValidationSetup}
export default {defaultFormFields, allValidationsRules, parseValidations, parseValidationSetup}

