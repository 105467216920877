/* global _ */
import FormMixin from '../../../../../../mixins/form'
import ProductMixin from '../../../../../../mixins/store/products'
import ProductFilterMixin from '../../../../../../mixins/store/products-filter'

export default {
  mixins: [FormMixin, ProductMixin, ProductFilterMixin],
  data() {
    return {
      fields: {},
      reactiveFields: {}
    }
  },
  computed: {
    formOptions() {
      const $s = this.$$s('form', {})
      const $t = this.$$t('form', {})
      return _.merge({}, $t, $s)
    },
    form() {
      return this.formOptions
    },
    formFields() {
      const fields = this.form.fields
      return _.mapValues(fields, (field, key) => {
        field.name = field.name || key
        return field
      })
    },
    formFieldOrder() {
      return this.form.$fieldsOrder || this.form.fieldsOrder || this.form.order ||
        _.reduce(this.formFields, (names, field, key) => {
          names.push(field.name || key)
          return names
        }, [])
    },
    formFieldOrdered() {
      return _.reduce(this.formFieldOrder, (fields, fieldName) => {
        const field = this.formFields[fieldName]
        if (field) {
          fields.push(field)
        }
        return fields
      }, [])
    },
    products() {
      const filters = this.$$s('products.filters', {})
      return this.applyFilterOnProducts(filters, null, true)
    },
  },
  created() {
    this.setupAutoForm()
    this.setupReactives()
  },
  methods: {
    buildCartItem() {
      const $s = this.$$s('cart.item', {})
      const $t = this.$$t('cart.item', {})
      const template = _.merge({}, $t, $s)
      const data = this.getReactiveData()
      const item = this.parseData(template, data)
      const uid = _.uniqueId((new Date()).valueOf() + '_')
      const id = _.filter([item.payload.product_id, item.payload.options.location_id, uid]).join('_')
      item.id = id
      item.total = (item.attributes.product.rate || item.attributes.product.price) * item.payload.count
      return item
    },
    parseData(def, data) {
      if (_.isArray(def)) {
        return _.map(def, value => this.parseData(value, data))
      }
      if (_.isObject(def)) {
        if (def.$parse) {
          const parsed = this.parseReactiveAttrs(def.$parse, data)
          delete def.$parse
          Object.assign(def, parsed)
        }
        return _.mapValues(def, value => this.parseData(value, data))
      }
      return def
    },
    getReactiveData() {
      return {
        this: this,
        fields: this.fields,
        ...this.fields,
      }
    },
    setupReactives() {
      const valueGetter = (v) => {
        return this.fields[v]
      }
      const propGetter = (v) => {
        return this.formFields[v]
      }
      const valueSetter = (k, v) => {
        this.$set(this.fields, k, v)
      }
      const propSetter = () => {}
      const namespace = 'fields'

      this.setupReactiveItems(this.formFields, {
        data: this.getReactiveData,
        namespace,
        valueGetter,
        propGetter,
        valueSetter,
        propSetter
      })
    },
    hydrateFields(fields, fieldsKey = 'fields') {
      let hydrateUser = this.form.hydrateUser || {}
      if (hydrateUser === true) {
        let values = this.userDataMeta || {}
        hydrateUser = _.keys(values)
      }
      if (_.isArray(hydrateUser)) {
        hydrateUser = _.reduce(hydrateUser, (all, key) => {
          all[key] = 'userDataMeta.' + key
          return all
        }, {})
      }
      const hydrates = {...hydrateUser, ...(this.form.hydrate || {})}
      const form = this[fieldsKey] || this
      _.forOwn(hydrates, (source, key) => {
        if (_.has(form, key)) {
          const value = _.get(this, source)
          if (!_.isNil(value)) {
            form[key] = value
          }
        }
      })
    },

    getFieldContainerExtraClasses(fieldName, fieldsPath, field) {
      return [field.containerClasses]
    },
    getFormFieldAttrs(field) {
      const key = field.name
      const overrides = _.get(this.reactiveFields, key, {})
      field = _.merge({}, field, overrides)
      if (field.$parse) {
        const data = {}
        const values = this.parseReactiveAttrs(field.$parse, data)
        delete field.$parse
        field = _.merge({}, field, _.omitBy(values, v => _.isNil(v)))
      }
      const $$v = this.$v
      const validations = this.validations[key] || {}
      return {
        key,
        validations,
        $$v,
        field,
        vRoot: $$v.fields,
        v: $$v.fields[key],
        classes: this.getFieldContainerClasses(key),
        customErrors: this.fieldCustomErrors[key],
      }
    },
  }
}
