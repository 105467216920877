<template lang="pug">
  .b-onboarding.b-onboarding--guide
    .b-onboarding__left
      .b-quickstart-guide-left
        .b-quickstart-guide-left__title {{ guide.title }}
        .b-quickstart-guide-left__subtitle {{ guide.subtitle }}
        img.b-quickstart-guide-left__prev-topic(@click="prevTopic" :src="`${config.baseUri}/images/client/saas-onboarding/onboarding-topic-prev-icon.svg`")
        template(v-for="(item, index) in guide.topics")
          .b-quickstart-guide-left__topic(@click="sectionIndex = index" :class="{ active: sectionIndex == index }") {{ item.label }}
            i
              img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/topic-arrow.svg`")
        img.b-quickstart-guide-left__prev-topic(@click="nextTopic" :src="`${config.baseUri}/images/client/saas-onboarding/onboarding-topic-next-icon.svg`")

    .b-onboarding__right
      .b-onboarding__title {{ section.title }}
      .b-onboarding__subtitle {{ section.subtitle }}

      .b-quickstart-guide-right
        .b-quickstart-guide-right__video
          youtube.b-video-youtube(video-id="Y0Ve5Z0xIHQ" player-width="100%")
          //- .b-video.b-video--md
          //-   .b-video__play-container
          //-     img.b-video__play-button(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/video-play-md.svg`")
        .b-quickstart-guide-right__help-label Need help?
        .text-center
          a.b-quickstart-guide-right__schedule-btn(href="https://www.rocketeffect.com/" target="_blank") schedule a free support session!
      
      .b-onboarding__footer
        .b-onboarding__footer-right
          span.btn.btn-primary.btn-lg(@click="getStarted()") Get started
            i
              img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-btn-next.svg`")


</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  data: () => ({
    sectionIndex: 0,
  }),

  computed: {
    guide() {
      return this.options.guides[this.onboarding.start ?? 'collectCustomerData']
    },
    section() {
      return this.guide.topics[this.sectionIndex]
    }
  },

  methods: {
    prevTopic() {
      if (this.sectionIndex > 0) {
        this.sectionIndex--
      }
    },
    nextTopic() {
      if (this.sectionIndex < this.guide.topics.length - 1) {
        this.sectionIndex++
      }
    },
    async getStarted() {
      const { domain } = await this.update({ complete: true })
      window.location.href = `http://${domain}/login/tenant/sso?token=${this.$store.getters.authToken}&redirect_path=${this.guide.path}`
    },
  }
}
</script>
