/*global _ */
import formatters from './formatters'

export const operators = {
  'empty': {
    label: 'Is empty',
    noValue: true,
    fn: 'emptyValue'
  },
  'notEmpty': {
    label: 'Is Not empty',
    noValue: true,
    fn: 'notEmptyValue'
  },
  'equals': {
    label: 'Equals'
  },
  'notEquals': {
    label: 'Not equals'
  },
  'contains': {
    label: 'Contains'
  },
  'notContains': {
    label: 'Not contains'
  },
  'greater': {
    label: 'Greater'
  },
  'less': {
    label: 'Less'
  },
  'greaterOrEquals': {
    label: 'Greater or equals'
  },
  'lessOrEquals': {
    label: 'Less or equals'
  },
  'oneOf': {
    label: 'Any of'
  },
  'noneOf': {
    label: 'None of'
  }
}

export const groupOperators = {
  'some': {
    label: 'One'
  },
  'every': {
    label: 'All'
  },
}

export const parser = function (conditionValue, operator, value, defaultValue = false) {
  let result = defaultValue
  if (operator) {
    if (operator === 'any') {
      return true
    }
    const operatorDef = operators[operator]
    if (operatorDef) {
      const params = [value]
      if (!operatorDef.noValue) {
        params.push(conditionValue)
      }

      let fn = operatorDef.fn || operator
      result = executeCondition.call(this, fn, params, defaultValue)

    } else {
      let fn = operator
      const params = [conditionValue, ...(_.isUndefined(value) ? [] : _.castArray(value))]
      result = executeCondition.call(this, fn, params, defaultValue)
    }
  } else {
    result = conditionValue
  }

  return result
}

const executeCondition = function (fn, params, defaultValue = false) {
  if (!_.isFunction(fn)) {
    fn = formatters.getFirst(fn, formatters,  _, this, this && this.context)
  }

  if (_.isFunction(fn)) {
    return fn.apply(null, params)
  }
  return defaultValue
}

export default {parser, operators, groupOperators}
