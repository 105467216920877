const EventBus = window.VueEventBus
import {first, empty, notEmpty, nil, get, some} from '../../../../../../../lib/formatters'
import {attrs as validationAttrs} from '../../../../../../../props/validations'
import ReValidation from '../../../../../../basic/form/re-validation'
import props from '../../../../../../../props/inputs'

import InputMixin from '../../../../../../../mixins/form/input'
import ComponentMixin from '../../../../../../../mixins/components/complex'
export const mixins = [ComponentMixin, InputMixin]

export const components = {ReValidation}

export default {
  name: 're-reservation-timeslot-input',
  components,
  mixins,
  props: {
    ...props,
    value: {},
    options: {},
    validations: {
      type: [Object],
      default() {
        return {}
      }
    },
    v: {},
    vRoot: {},
    $$v: {},
    classes: {},
  },
  data() {
    return {
      propDefs: props,
      innerValue: this.value
    }
  },

  watch: {
    value(val) {
      this.innerValue = val
    }
  },
  computed: {
    allSettings() {
      return this.options || {}
    },
    date() {
      return this.allSettings.date
    },
    unavailables() {
      return this.allSettings.unavailable
    },
    hasUnavailable() {
      return !nil(this.unavailables)
    },
    duration() {
      return this.allSettings.duration
    },
    unit() {
      return this.allSettings.unit
    },
    groups() {
      return get(this.allSettings, 'groups', {allday: {"from": "0:00", "to": "11:59:59", label: ''}})
    },
    hasTab() {
      return _.size(this.timeSlotTabs) > 1
    },
    timeSlotTabs() {
      const timeSlots = this.timeSlots
      if (empty(timeSlots)) {
        return {}
      }
      const groups = _.groupBy(timeSlots, 'group')
      let tabs = _.mapValues(_.cloneDeep(this.groups), (g, k) => {
        g.slots = groups[k] || {}
        g.title = g.label
        return g
      })
      tabs = _.omitBy(tabs, t => empty(t.slots))
      if (!empty(tabs)) {
        first(tabs).active = true
      }
      return tabs
    },
    timeSlots() {
      let items = {}
      if (this.hasUnavailable) {
        const date = this.parseDateTime(this.date)
        if (date && date.isValid) {
          const groups = this.groups || {}
          _.forOwn(groups, (group, name) => {
            const {from, to} = group
            const slots = this.generateTimeslots(
              this.date,
              from, to,
              this.duration,
              this.unit,
              name,
              this.unavailables
            )
            items = {...items, ...slots}
          })
        }
        if (!some(items, ({value}) => value === this.value)) {
          this.selectTimeSlot({value: null})
        }
      }
      return items
    },
    hasTimeSlots() {
      return notEmpty(this.timeSlots)
    },
    noTimeSlot() {
      return this.hasUnavailable && !this.hasTimeSlots
    },
    '$v': function() {
      return this.vRoot
    },
    validationAttrs() {
      return {
        ..._.pick({...this.$props, ...this.$attrs}, validationAttrs),
        value: this.innerValue,
        tabindex: null,
        customErrors: this.getCustomErrors(),
      }
    }
  },
  created() {

  },
  methods: {

    getLabelExtraClasses() {
      return [`re-input-reservation-timeslots`]
    },
    generateTimeslots(date, from, to, duration, unit, group, omits) {
      const slots = {}
      const start = `${date} ${from}`
      const startDate = this.parseDateTime(start)
      const end = `${date} ${to}`
      const endDate = this.parseDateTime(end)
      let newTime = startDate
      while (newTime <= endDate) {
        const time = newTime.toFormat('H:mm')
        const timeHH = newTime.toFormat('HH:mm')
        const formattedTime = newTime.toFormat('h:mm a')
        slots[time] = {value: timeHH, label: formattedTime, group}
        newTime = newTime.plus({[unit]: duration})
      }
      return _.omit(slots, omits || [])
    },
    selectTimeSlot({value}) {
      this.innerValue = value
      this.$emit('input', value)
    },
    getCustomErrors() {
      const errors = this.customErrors || {}
      if (empty(errors) && this.noTimeSlot) {
        errors.noSlot = this.$$t('customErrors.noTimeSlot', 'No timeslot found')
      }
      return errors
    }
  },
}
