export const name = 're-core-page-shop-buy-gift-card'
export const component = 're-buy-giftcard'

import {factory} from '../../../../../lib/page'

const props = {
  terms: {},
  item: {},
  token: {}
}

const def = {
  created() {
    window.VueEventBus.$on('buy.giftcard.add-to-cart', () => {
      this.$router && this.$router.push({ name: 'cart' })
    })
  }
}
export default factory(name, {props, component, def})
