import {fv} from '../../../../lib/utils'
export default {
  name: 're-link',

  props: {
    to: {
      type: [String, Object],
      default: null
    },
    url: {},
    label: {},
    title: {},
    classes: {
      type: [String, Array, Object],
      default: null
    },
    noDefaultClass: {
      type: Boolean,
      default: false
    },
    asLink: {}, // renders <a> without href allowing listeners to work
    asRouter: {}, // applicable when using `to` prop. If true, <router-link> will be
    asHref: {}, // applicable when using `to` prop. If true, <a href> will be used instead of <re-router-link>
    value: {}
  },

  data() {
    return {}
  },

  computed: {
    isRoute() {
      return !this.asHref && _.isObject(this.to)
    },
    isPath() {
      return !_.isEmpty(this.to)
    },
    isUrl() {
      return !!this.url
    },
    path() {
      return this.isPath ? this.$router.match(this.to).fullPath : null
    },
    linkClasses() {
      const defaultClasses = []
      if (!this.noDefaultClass) {
        defaultClasses.push('btn')
        defaultClasses.push('btn-link')
      }
      defaultClasses.push(this.classes)
      return defaultClasses
    },
    linkLabel() {
      return fv(this.title, this.label, 'click')
    }
  },
  methods: {
    onClick() {
      this.$emit('input', this.value)
    }
  },
}
