/*global _ */
import {name, mixins, props, data, EventBus, getFirstCombination} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },

  computed: {
  },

  async created() {

  },

  mounted() {

  },
  methods: {
    getProductPlanItemComponent() {
      const items = [
        `product.types.subscription-plan`
      ]
      return getFirstCombination(items, this.$$s, true, 'components', null, this.itemComponent)
    },
    getPlanItemAttrs(plan) {
      const item = this.productItem || {}
      const otherAttrs = this.mergedAttr
      const translations = _.merge({},
        this.$$clone(this.$$t(`item`, {})),
        this.$$clone(this.$$t(`plan`, {})),
        this.$$clone(this.$$t(`product.types.subscription.item`, {})),
        this.$$clone(this.$$t(`product.types.subscription.plan`, {}))
      )
      const settings = _.merge({},
        this.$$clone(this.$$s(`item`, {})),
        this.$$clone(this.$$s(`plan`, {})),
        this.$$clone(this.$$s(`product.types.subscription.item`, {})),
        this.$$clone(this.$$s(`product.types.subscription.plan`, {}))
      )
      const attr = {
        item,
        plan,
        translations,
        settings,
        class: this.getPlanItemClasses(plan, item)
      }
      return attr
    },
    getPlanItemClasses(plan, item) {
      return [
        ...this.getPlanItemExtraClasses(plan, item) || []
      ]
    },
    getPlanItemExtraClasses(plan, item) {
      return [

      ]
    },
    getComponentExtraClasses() {
      return [
      ]
    }
  },
}
