/*global _ */
import {EventBus, DataFilter} from '../../lib/utils'
import formatters, {mask} from '../../lib/formatters'
import {updatePlaycard, discardCard} from '../../services/card'

export default {
  data() {
    return {
      cardsList: [],
      filteredCards: [],
      isLoading: false,
      isAdding: false,
    }
  },
  computed: {
    componentName() {
      return this.component || 're-profile-cards'
    },
    componentAttrs() {
      const attr = this.componentAttributes || {}; //this.mergedAttr;
      const componentAttrs = {
        context: this,
        cardsList: this.cardsList,
        cardType: this.cardType,

        classes: this.classes,
        emptyCardMessage: this.emptyCardMessage,

        reloadCardRoute: this.reloadCardRoute,
        reloadCardType: this.reloadCardType,
        initAddCardButtonTitle: this.initAddCardButtonTitle,
        purchaseCardButtonTitle: this.purchaseCardButtonTitle,
        archivePurchaseCardButtonTitle: this.archivePurchaseCardButtonTitle,
        purchaseCardButtonLink: this.purchaseCardButtonLink,

        // add card
        addCardClass: this.addCardClass, //@fix
        addCardTitle: this.addCardTitle, //@fix
        addCardShowTitle: this.addCardShowTitle, //@added

        isProcessingAddCard: this.isAdding, // applied from  giftcard-list or playcard-list mixins
        addingMessage: this.addingMessage, //@fix
        addingErrorMessage: this.addingErrorMessage, // applied from  giftcard-list or playcard-list mixins

        addCardNumberLabel: this.addCardNumberLabel, //@fix
        addCardNumberPlaceholder: this.addCardNumberPlaceholder, //@fix
        addCardNumberClass: this.addCardNumberClass, //@fix
        addCardCodeLabel: this.addCardCodeLabel, //@fix
        addCardCodePlaceholder: this.addCardCodePlaceholder, //@fix
        addCardCodeClass: this.addCardCodeClass, //@fix

        addCardButtonTitle: this.addCardButtonTitle,
        addCardButtonClass: this.addCardButtonClass,
        addCardShowCancelButton: this.addCardShowCancelButton,//@fix
        addCardCancelButtonTitle: this.addCardCancelButtonTitle, //@fix
        addCardCancelButtonClass: this.addCardCancelButtonClass, //@fix

        addCardHasResendCode: this.addCardHasResendCode, //@fix
        addCardResendCodeMessage: this.addCardResendCodeMessage, //@fix
        addCardResendCodeLinkLabel: this.addCardResendCodeLinkLabel, //@fix
        addCardHasHelpNumberLink: this.addCardHasHelpNumberLink, //@fix
        addCardHelpNumberLinkLabel: this.addCardHelpNumberLinkLabel, //@fix
        addCardHasHelpCodeLink: this.addCardHasHelpCodeLink, //@fix
        addCardHelpCodeLinkLabel: this.addCardHelpCodeLinkLabel, //@fix

        showAddCardInModal: this.showAddCardInModal,
        addCardModalTitle: this.addCardModalTitle,
        addCardSuccessModalTitle: this.addCardSuccessModalTitle,

        modalClasses: this.modalClasses,
        infoButtonTitle: this.infoButtonTitle,
        infoButtonClass: this.infoButtonClass,
        numberInfoModalTitle: this.numberInfoModalTitle,
        numberInfoModalHeader: this.numberInfoModalHeader,
        numberInfoModalFooter: this.numberInfoModalFooter,
        numberInfoModalImgSrc: this.numberInfoModalImgSrc,
        codeInfoModalTitle: this.codeInfoModalTitle,
        codeInfoModalHeader: this.codeInfoModalHeader,
        codeInfoModalFooter: this.codeInfoModalFooter,
        codeInfoModalImgSrc: this.codeInfoModalImgSrc,
        resendModalTitle: this.resendModalTitle,
        resendModalClasses: this.resendModalClasses,
        resendModalHeader: this.resendModalHeader,
        resendModalButtonTitle: this.resendModalButtonTitle,
        resendModalButtonClasses: this.resendModalButtonClasses,
        resendModalErrorMessage: this.resendModalErrorMessage,
        resendModalRecoveredEmail: this.resendModalErrorMessage,
        resendModalSuccessMessage: this.resendModalSuccessMessage,
        resendModalSuccess: this.resendModalSuccess,
        resendModalResendMessage: this.resendModalResendMessage,

        showProductImage: this.showProductImage,
        productImageClasses: this.productImageClasses,
        allowTransferBalance: this.allowTransferBalance,

        isLoading: this.isLoading,
        loadingMessage: this.loadingCardsMessage, //@added:
        errorMessage: this.errorMessage,
        settings: this.$$$s,
        translations: this.$$$t,
        autoLoad: [this.cardType, ...this.autoLoad || []],
      }
      return _.pickBy({...attr, ...componentAttrs}, i => !_.isNil(i))
    },
    noCards() {
      return this.noFilteredCards || this.noUserCards
    },
    noUserCards() {
      return !this.cardsList || _.isEmpty(this.cardsList)
    },
    noFilteredCards() {
      return _.isEmpty(this.filteredCards)
    }
  },
  async created() {
    this.registerEventListeners()
  },
  async activated() {
    await this.fetch()
  },

  methods: {
    async fetch() {
      if (!_.isEmpty(this.cards)) {
        this.cardsList = this.parseCards(this.cards)
      } else {
        try {
          this.isLoading = true
          await this.load()
        } finally {
          this.isLoading = false
        }
      }
    },
    registerEventListeners() {
    },
    getProductImage(item) {
      return _.get(item, 'media.image',
        this.$$s(
          `media.image`,
          this.$$s(`product.${item.type}.image.src`,
            this.$$s(`product.stub.image.src`)
          )
        )
      )
    },
    parseCardMoreAbilities(card, cards) {
      return card
    },
    parseCardDataSources(card, cards) {
      const sources = this.$$s('card.data.sources', {})
      if (!_.isEmpty(sources)) {
        _.forOwn(sources, (path, key) => {
          if (_.isArray(path)) {
            const isWindow = path[0] === '$'
            path = formatters.templify(path[1], card)
            const source = isWindow ? window : card
            const value = _.get(source, path)
            if (!_.isNil(value)) {
              card[key] = value
            }
          } else {
            const value = _.get(card, path)
            if (!_.isNil(value)) {
              card[key] = value
            }
          }
        })
      }
      return card
    },
    parseCardAbilities(card, cards) {
      const mixin = this
      card.$can = {
        showStatus: this.showStatus,
        showBalance: this.forceShowBalance || this.showBalance,
        showNumber: this.showNumber,
        showNumberMasked: this.showNumberMasked && card.is_virtual,
        recoverCode: this.allowForgotCode,
        resend: this.allowResend,
        archive: this.allowArchive && !card.is_locked,
        reload: this.allowReload && !card.is_locked,

        showTicket: this.showTicket && !_.isEmpty(card.tickets),
        showBonus: this.showBonus,
        showPoints: this.showPoints,
      }

      card.$actions = {
        archive: async () => await mixin.archive(card),
        discard: async (...args) => await this.discardCard(card, ...args),
        updateFamilyMember: async (...args) => await this.cardUpdateFamilyMember(card, ...args)
      }

      card = this.parseCardMoreAbilities(card, cards)

      if (card.$can.showNumberMasked && this.$$trim(this.maskedNumber)) {
        card.maskedNumber = card.maskedNumber || mask(card.number, this.maskedNumber)
      }

      return card
    },
    parseCards(cards) {
      cards = _.mapValues(_.cloneDeep(cards) || [], item => {
        item.media = item.media || {}
        item.media.image = item.media.image || this.getProductImage(item)
        item = this.parseCardAbilities(item, cards)
        item = this.parseCardDataSources(item, cards)
        DataFilter.apply('profile.card', item, cards, this)
        return item
      })
      DataFilter.apply('profile.cards', cards, this)
      return _.values(cards)
    },
    getComponentExtraClasses() {
      return [{
        'no-cards': this.noCards,
        'no-user-cards': this.noUserCards,
        'no-filtered-cards': this.noFilteredCards
      }]
    },
    async cardUpdateFamilyMember(card, family_member_id) {
      const payload = {
        id: card.id,
        family_member_id,
      }
      const {data} = await updatePlaycard(payload)
      if (this.autoLoadAll) {
        await this.autoLoadAll([this.cardType, ...this.autoLoad || []])
      }
      return data
    },
    async discardCard(card, options) {
      const payload = {
        id: card.id,
        ...(options || {})
      }
      const {data} = await discardCard(payload)
      if (this.autoLoadAll) {
        await this.autoLoadAll([this.cardType, ...this.autoLoad || []])
      }
      return data
    }
  }
}

