export const name = 're-loyalty-rewards-confirmation-achievements'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../../../../mixins/components/complex'
import LoyaltyMixin from '../../../../../../../mixins/profile/loyalty'
import ResponsiveMixin from '../../../../../../../mixins/responsive'
import ClaimerMixin from '../../base/claim'

export const mixins = [
  ComponentMixin,
  LoyaltyMixin,
  ResponsiveMixin,
  ClaimerMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  type: 'achievements',
  mode: 'confirmation',
  errorMessage: ''
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
