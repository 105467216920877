import component from '../../../../../../../props/component'
import form from '../../../../../../../props/form'
import processing from '../../../../../../../props/processing'

const localProps = {
  item: {},
  view: {},
  token: {},

  showImageGallery: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.gallery.show', true)
    }
  },
  imageGalleryLimit: {
    type: [Number],
    default() {
      return this.$options.$$s('images.gallery.limit', 4)
    }
  },
  useImageSlider: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.slider.use', true)
    }
  },
  imageSliderOptions: {
    type: [Object],
    default() {
      return this.$options.$$s('images.slider.options', {})
    }
  },
  imageSliderMaxWidth: {
    type: [Number],
    default() {
      return this.$options.$$s('images.slider.maxWidth', 768)
    }
  },
  imageSliderMinWidth: {
    type: [Number],
    default() {
      return this.$options.$$s('images.slider.minWidth', 0)
    }
  }

}
export const props = {
  ...component,
  // ...form,
  ...processing,
  ...localProps
}
export default props
