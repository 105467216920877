export const name = 're-loyalty-reward-claim-general'

import props from './props'

export {props}

import ClaimerMixin from '../base/claimer'

export const mixins = [
  ClaimerMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
