export default {
  translations: {
    type: [Object, Array, String],
    default: null
  },
  settings: {
    type: [Object, Array, String],
    default: null
  },
  pageTitle: {
    type: String,
    componentProp: 'title'
  },
  showPageTitle: {
    type: [Boolean, Number, String],
    default: null,
    componentProp: 'showTitle'
  },
  pageSubTitle: {
    type: String,
    componentProp: 'subTitle',

  },
  showPageSubTitle: {
    type: [Boolean, Number, String],
    default: null,
    componentProp: 'showSubTitle'
  },
  pageNotice: {
    type: String,
    componentProp: 'notice'
  },
  showPageNotice: {
    type: [Boolean, Number, String],
    default: null,
    componentProp: 'showNotice'
  },
  pageClasses: {
    type: [String, Array, Object],
    componentProp: 'classes'
  },
  pageError: {
    type: String,
    componentProp: 'error'
  },
}
