import {TIMEZONE, formatDate, parseDateTime, parseDateInterval, getNow, now, getNowSeconds} from '../lib/dates'

export default {
  data: () => ({
    TIMEZONE
  }),
  computed: {
    now() {
      return now
    }
  },
  methods: {
    getNow,
    formatDate,
    getNowSeconds,
    parseDateTime,
    parseDateInterval,
  },

}

