/*global _, toastAlert, debugLog */

/**
 * Optimization Notes:
 * 1. The following functions are moved from this module to lib/html.js
 *    due to their dependency on jQuery which is unnecessary for many dependents of this module
 *    - get$
 *   - getv$
 *   - set$
 *   - title$
 *   - style$
 *   - html$
 *   - attr$
 *   - prop$
 *   - class$
 *   - copyToClipboard
 *
 * 2. The following function of this module has been moved to lib/lodash-extras.js
 *    due to it's dependency on lodash & lodash.combinations which is unnecessary for many dependents of this module
 *  - getFirstCombination
 */

import {Vue} from '~/addiesaas'
import './data-filter'
import './event-bus'

import config from './config'

import {getErrorMessagesWithKeys} from '~/lib/axios'

import mustache from 'mustache'
// Prevent HTML escape of rendered values
mustache.escape = (t) => t

const { UsaStates } = require('usa-states/index.js')
export const countries = require('country-list/data.json')
export const states = new UsaStates().states

export const EventBus = window.VueEventBus
export const DataFilter = window.VueDataFilter || {
  add: () => null,
  apply: () => null,
}
// const $ = window.jQuery

export const parseNumber = val => (1 * val) || 0

export const getQS = (keys) => {
  let qs = {}
  let search = (window.location.search || '')
    .replace(/^\?/, '') + '&'
  search
    .replace(/^\?/, '')
    .replace(/([^=]+?)=(.+?)&/g, (f, n, v) => qs[n] = decodeURIComponent(v))
  if (keys) {
    _.pick(qs, keys)
  }
  return qs
}

export const forceUpdateOnResize = (vm, wait = 500, force = true) => {

  const debouncedResize = () => {
    if (vm.setAppWidth) {
      vm.setAppWidth(window.innerWidth)
    }
    if (force) {
      vm.$forceUpdate()
    }
  }

  observeWindowResize( _.debounce(debouncedResize, wait))
}

export const observeWindowResize = (callback) => {
  window.addEventListener('resize', callback)
}

export const observeResize = (element, callback, options) => {

  const resizeObserver = new ResizeObserver((entries, observer) => {
    if (callback) {
      callback(element, entries, observer)
    }
  })
  options = options || {}
  if (element instanceof Element) {
    resizeObserver.observe(element, {box: 'border-box', ...options})
  }
  return resizeObserver
}

// convert value to boolean returns the converted value
// Boolean value is returned back as it is
// 1. string `true` and `false` are converted to true and false respectively
//    if strict it only checks for true/false/`true/`false`
// 2. If `strict = false` it coverts a host of other values
//      `true`,`yes`, `on`, `1` are converted to true and  `false`, `no`, `off`, `0` to false
//       Rest of the values are converted using !! 1 * value
export const coerceBoolean = (value, strict) => {
  if (strict) {
    if (['true', 'false'].includes(value)) {
      return JSON.parse(value)
    }
    return value
  }

  if (_.isBoolean(value)) {
    return value
  }
  const map = {
    'true': true,
    'false': false,
    'yes': true,
    'no': false,
    'on': true,
    'off': false,
    '0': false,
    '1': true,
  }
  let newValue = map[(''+value).toLowerCase()]
  if (_.isNil(newValue)) {
    newValue = !!(1 * value)
  }
  return newValue
}

export const pushState = (path, state, title) => {
  state = state || history.state || {}
  window.history.pushState(state, title, path)
}

export const formatPrice = (amount, decimals = 2, truncateZeroDecimals = true) => {
  let value = (1 * amount || 0).toFixed(decimals)
  if (truncateZeroDecimals) {
    value = value.replace(/\.0+?$/, '')
  }
  return value
}

export const alert = (message, options) => {
  const config = Object.assign({type: 'modal', block: true, toast: false, containerClass: 're-container'}, options || {})
  return toastAlert(message, config.type, config)
}

export const emit = function(name, ...args) {
  if (name) {
    args = [...(args || []), this]
    EventBus.$emit(name, ...args)
    if (this) {
      if (this.$emit) {
        this.$emit(name, ...args)
      }
      if (this.id) {
        EventBus.$emit(`${name}.${this.id}`, ...args)
      }
    }
  }
}

export const getEmitter = (namePrefix) => {
  return (name, args, vm) => {
    const eventName = namePrefix ? `${namePrefix}.${name}` : name
    emit.call(vm, eventName, args)
  }
}

export const emitAsync = async function (event, ...args) {
  const listeners = this?._events[event] || []
  const results = []
  const promises = []

  for (const listener of listeners) {
    const result = listener(...args)
    if (result instanceof Promise) {
      promises.push(result)
      result.then(r => results.push(r))
    }
    else {
      results.push(result)
    }
  }

  await Promise.all(promises)

  return results
}

export const promiseFactory = function (handler, options) {
  const thener = options.then
  const catcher = options.catch
  const executor = (resolve, reject) => {
    options.then = (resolution) => {
      thener && thener.call(this, resolution)
      resolve(resolution)
    }
    try {
      handler.call(this, options)
    } catch (e) {
      catcher && catcher.call(this, e)
      reject(e)
    }
  }
  return new Promise(executor)
}

export const registerPromise = (() => {
  let pid = 0
  const getId = () => ++pid
  return (name, ...args) => {
    const executor = (resolve, reject) => {
      const promiseName = `promise.${name}`
      const id = getId()
      const initEvent = `${promiseName}.init`
      const runEvent = `${promiseName}.run`
      const doneEvent = `${promiseName}.done.${id}`
      const errorEvent = `${promiseName}.error.${id}`

      EventBus.$once(doneEvent, (...eventArgs) => resolve(eventArgs))
      EventBus.$once(errorEvent, (err) => reject(err))

      EventBus.$$emitAsync(initEvent, id, ...args).then(function () {
        EventBus.$emit(runEvent, id, ...args)
      })
    }
    return new Promise(executor)
  }
})()

export const handlePromise = async (name, handler, errorHandler)  => {
  const promiseName = `promise.${name}`
  EventBus.$on(`${promiseName}.run`, async (promiseId, ...args) => {
    try {
      const data = await handler(...args)
      EventBus.$emit(`${promiseName}.done.${promiseId}`, data)
    } catch (e) {
      debugLog({error: e});
      const all = getErrorMessagesWithKeys(e)
      const error = new Error((errorHandler ? errorHandler(e) : e.message) || 'Unknown error!')
      error.all = all
      error.code = _.toFinite(_.get(e, 'response.status'))
      error.$e = _.get(e, 'response.data', {})
      error.$$e = e
      EventBus.$emit(`${promiseName}.error.${promiseId}`, error)
    }
  })
}

// @deprecated - use setTimeoutAsync
export const setTimeoutPromise =  async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const setTimeoutAsync = setTimeoutPromise

// first value (which is not undefined or null)
export const fv = (...args) => {
  for(const v of args) {
    if (!_.isNil(v) && !_.isNaN(v)) {
      return v
    }
  }
  return null
}

// first non empty object
export const fo = (...args) => {
  for(const v of args) {
    if (_.isObject(v) && !_.isEmpty(v)) {
      return v
    }
  }
  return null
}
// first non empty string (which is not undefined or null or blank string)
export const fs = (...args) => {
  for(const v of args) {
    if (!_.isNil(v) && !_.isNaN(v) && v !== '') {
      return v
    }
  }
  return ''
}

// first number (which is a number and not NaN)
export const fn = (...args) => {
  for(const v of args) {
    if (_.isFinite(v) && !_.isNaN(v)) {
      return v
    }
  }
  return 0
}

export const cloneMerge = (object, path = '', merge = {}) => {
  let newObject = _.cloneDeep(object || {})
  if (path) {
    newObject = _.get(newObject, path, {})
  }
  newObject = _.merge({}, newObject, _.cloneDeep(merge || {}))
  return newObject
}

export const templify = function(template, data, tags, partials = {}) {
  const allData = {this: this || {}, ...data || {}}
  if (_.isArray(template)) {
    return template.map(item => templify.call(this, item, data, tags, partials))
  }
  if (_.isObject(template)) {
    return _.mapValues(template, item => templify.call(this, item, data, tags, partials))
  }
  if (!tags) {
    tags = ['{{', '}}']
    let hasTemplate = /{{[^}]+?}}/.test(template)
    if (hasTemplate) {
      template = mustache.render(_.toString(template), allData, partials, tags)
    }
    tags = ['[[', ']]']
    hasTemplate = /\[\[[^\]]+?\]\]/.test(template)
    if (hasTemplate) {
      template = mustache.render(_.toString(template), allData, partials, tags)
    }
    return template
  }
  return mustache.render(_.toString(template), allData, partials, tags)
}

export const hasThisBracketsTemplate = (template) => {
  return /\[\[this\.[^\]]+?\]\]/.test(template)
}

export const hasThisMustacheTemplate = (template) => {
  return /{{this\.[^}]+?}}/.test(template)
}

export const hasThisTemplate = (template) => {
  return hasThisMustacheTemplate(template) || hasThisBracketsTemplate(template)
}

export const hasTcBracketsTemplate = (template) => {
  return /\[#[^#]+?#\]/.test(template)
}
export const splitByTcBracketsTemplate = (template) => {
  return template.split(/(\[#[^#]+?#\])/)
}

export const hasTemplate = (template) => {
  return /{[^}]+?}/.test(template)
}

/**
 *
 * @param options object {state: <vuex state>, accessor: 'path', items: ['path', 'path2'...], prefix: '', suffix: ''}
 *
 * use this function to return computed properties from a Vuex State object with gettter and setter
 *
 * Examples:
 * 1.  ...mapState({state, items: ['isLoading', 'user']})

 *    => This will create computed props(with getter and setter) for `isLoading` and `user` store properties
 *
 *
 * 2. ..._.reduce(['user', 'nav'], (c, accessor) => ({...c, ...mapState({state, accessor})}), {})

 *    => This will create computed props(with getter and setter) for all the properties (1 level deep) of user and nav store objects
 *    For example, if user has a property email, then you can directly get `this.email` or set using this.email = 'foo@boo.coo'
 *
 *    WARNING: This may create breaking conflict with existing props or data options
 *
 *  PREREQUISITES:
 *
 *   1. define an action to set value:
 *
 *      setStoreValue{accessor, key, value} => {
 *        const storage = _.get(state, item.accessor, state)
 *        Vue.set(storage, item.key, item.value)
        }
 *
 *   2. define a getter and return a function to get value
 *
 *    getStoreValue(state) {
 *      return function (accessor, key) {
 *        const storage = accessor ? state[accessor] : state
 *        return _.get(storage, key)
 *      }
 *    },
 *
 *
 * @returns {*}
 */
export const mapVuexState = (options) => {
  const {state, prefix = '', suffix = '', accessor = '', items = null} = options
  const storePath = accessor ? state[accessor] : state
  const states =  _.reduce(storePath, (fns, item, key) => {
    let propName = key
    if (prefix) {
      propName = _.camelCase(prefix + '_' + propName)
    }
    if (suffix) {
      propName = _.camelCase(propName + '_' + suffix)
    }
    if (!items || _.indexOf(items || [], key) !== -1) {
      fns[propName] = {
        get() {
          return this.getStoreValue(accessor, key)
        },
        set(value) {
          this.setStoreValue({accessor, key, value})
        }
      }
    }
    return fns
  }, {})
  return states
}

export const addiesaasReady = (callback, prepend) => {
  const ad = window.addiesaas || (window.addiesaas = {})
  const ready = ad.ready || (ad.ready = {callbacks: []})
  const callbacks = ready.callbacks
  const readyType = typeof ready
  if (readyType === 'function') {
    ready(callback)
  } else {
    if (prepend) {
      callbacks.unshift(callback)
    } else {
      callbacks.push(callback)
    }
  }
}

export const addiesaasBeforeReady = (callback, prepend) => {
  const ad = window.addiesaas || (window.addiesaas = {})
  const ready = ad.ready || (ad.ready = {callbacks: []})
  const callbacks = ad.beforeReady || (addiesaas.beforeReady = [])
  const readyType = typeof ready
  if (readyType === 'function') {
    ready(callback)
  } else {
    if (prepend) {
      callbacks.unshift(callback)
    } else {
      callbacks.push(callback)
    }
  }
}


Vue.prototype.$$trim = function (val, chars = '\u200B') {
  return _.trim(_.trim(val, chars))
}

Vue.prototype.$$get = function (path, defaultValue, data = {}) {
  return _.get(this, path, _.get(data, path, defaultValue))
}

Vue.prototype.$$attrs = function () {
  return _.omitBy(this.$attrs || {}, value => _.isNil(value))
}

Vue.prototype._ = _

Vue.prototype.$$fv = fv
Vue.prototype.$$fs = fs
Vue.prototype.$$fn = fn
Vue.prototype.$$log = console.log
Vue.prototype.$$clone = cloneMerge
Vue.prototype.$$templify = templify
Vue.prototype.$$emitAsync = emitAsync

export default {
  EventBus,
  DataFilter,
  fv,
  fo,
  fs,
  fn,
  countries,
  states,
  parseNumber,
  getQS,

  coerceBoolean,
  pushState,
  formatPrice,
  alert,
  emit,
  emitAsync,
  getEmitter,
  promiseFactory,
  registerPromise,
  handlePromise,
  setTimeoutPromise,
  setTimeoutAsync,
  cloneMerge,
  templify,
  hasThisBracketsTemplate,
  hasThisMustacheTemplate,
  hasThisTemplate,
  hasTemplate,
  hasTcBracketsTemplate,
  splitByTcBracketsTemplate,
  observeWindowResize,
  observeResize,

  mapVuexState,
  addiesaasReady,
  addiesaasBeforeReady
}
