/*global _ */
/**
 * components which needs to implement social auth buttons may use this mixin
 * It allows to add one or more social auth buttons through settings.socialAuths and translations.socialAuths
 *
 * e.g. settings.socialAuths: defines facebook button only with registration option.
 * "socialAuths": {
 *         "facebook": {
 *             "buttonAttrs": {
 *                 "register": true
 *             }
 *         }
 *     }
 *
 * e.g. translations.socialAuths: defines facebook button only. Defines the button title.
 *  "socialAuths": {
 *         "facebook": {
 *             "buttonAttrs": {
 *                 "title": "Sign in with Facebook"
 *             }
 *         }
 *     }
 *
 * NOTE: Use `UI/WEB/Resources/Assets/js/view/layouts/profile/social/buttons.pug` for the buttons template
 */
import {getFirstErrorMessage} from '../../../services/main'
import {fv} from '../../../lib/utils'

export default {
  props: {
    socialAuths: {
      type: [Array, String, Object],
      default() {
        return this.$options.$$s('socialAuths', null)
      }
    },
    socialAuthsLang: {
      type: [Array, String, Object],
      default() {
        return this.$options.$$t('socialAuths', null)
      }
    },
    showSocialAuthErrors: {
      type: [Boolean, String, Number],
      default() {
        return this.$options.$$t('showSocialAuthErrors', null)
      }
    },
    showSocialAuthValidationErrors: {
      type: [Boolean, String, Number],
      default() {
        return this.$options.$$t('showSocialAuthValidationErrors', true)
      }
    },
  },
  computed: {
    socialAuthAttrs() {
      let auths = this.socialAuths
      let authsLang = this.socialAuthsLang
      if (auths || authsLang) {
        auths = auths || {}
        authsLang = authsLang || {}
        if (_.isString(auths)) {
          auths = _.castArray(auths)
        }
        if (_.isArray(auths)) {
          auths = _.mapKeys(auths)
          auths = _.mapValues(auths, () => {
          })
        }

        if (_.isString(authsLang)) {
          authsLang = _.castArray(authsLang)
        }
        if (_.isArray(authsLang)) {
          authsLang = _.mapKeys(authsLang)
          authsLang = _.mapValues(authsLang, () => {
          })
        }

        return _.merge({}, auths, authsLang)
      }

      return []
    },
    hasSocialAuths() {
      return !_.isEmpty(this.socialAuthAttrs)
    }
  },

  methods: {
    async redirectOnAuth(isPreAuthenticated) {
      const goto = this.redirectTo || this.afterLoginRoute || 'user.profile'
      this.rememberAfterLoginRoute({route: null})
      if (!isPreAuthenticated) {
        await this.$$gemitAsync('auth.redirect', this, {user: this.user})
        await this.setTimeoutAsync(0)
      }
      this.goto(goto)
    },
    async finalizeLogin(data) {
      this.saveToken({
        token: data.token || data.access_token
      })
      await this.fetchUser()
      if (!this.noRedirect) {
        await this.redirectOnAuth()
      }
    },
    async successPopupResponse(response, provider) {
      // debugLog({successSocialAuth: response, provider, response})
      const def = this.socialAuthAttrs[provider] || {}
      const isRegister = !!_.get(def, 'buttonAttrs.register', def.register)
      const service = isRegister ? 'user.social.register' : 'user.social.login'
      try {
        this.emitSlim('social.init', provider, {register: isRegister})
        const data = await this.invokeService(service, {provider, ...response})
        const user = data.user
        if (isRegister) {
          if (this.hydrateFields) {
            const hydrateData = {
              ..._.omit(user || {}, ['id']),
              social: {
                provider,
                ..._.omit(data, ['user'])
              }
            }

            this.hydrateFields(hydrateData)
            if (this.stopProcessing) {
              this.stopProcessing()
            }
          }
          this.emitSlim('social.registered', provider, user)
        } else {
          await this.finalizeLogin(data)
          this.emitSlim('social.success', provider, user)
        }
      } catch (e) {
        if (this.stopProcessing) {
          this.stopProcessing()
        }
        await this.errorSocialAuth(e, provider, response)
      }
      this.emitSlim('social.end', provider)
      // debugLog({successSocialAuth: response, provider, response})
    },
    async successSocialAuth(response, provider) {
        if (this.stopProcessing) {
          this.stopProcessing()
        }
        if (this.afterSuccessSocialAuth) {
          await this.afterSuccessSocialAuth(response, provider)
        }
    },
    async errorSocialAuth(e, provider, response) {
      const errors = e.all || {}
      const def = this.socialAuthAttrs[provider] || {}
      const defaultMessage = _.get(def, 'processing.error')
      const title = _.get(def, 'processing.title')
      const message = getFirstErrorMessage(e, defaultMessage)

      // debugLog({errorSocialAuth: e, provider, errors, def})

      // User not registered - handler may redirect to registration page
      if (errors['login-user-not-registered']) {
        return await this.handleSocialUserNotRegisteredError(e, {errors, message, title, response, provider, def})
      }

      // hide processing animation
      // strategically placed after login-user-not-register as it may redirect to registration page with the animation on
      if (this.stopProcessing) {
        this.stopProcessing()
      }

      // user email verification error - the error message = email address of the user
      if (errors['login-user-not-confirmed']) {
        return await this.handleSocialUserNotVerifiedError(e, {errors, message, title, response, provider, def})
      }

      this.emitSlim('social.error', e, provider)

      const showErrors = fv(def.showErrors, this.showSocialAuthErrors)
      const showValidationErrors = fv(def.showValidationErrors, this.showSocialAuthValidationErrors)
      const isValidationError = e.code === 422

      if (isValidationError) {
        if (showValidationErrors) {
          this.errorAlertAsync(message, title)
        }
      } else {
        if (showErrors) {
          this.errorAlertAsync(message, title)
        }
      }
    },
    async handleSocialUserNotRegisteredError(e, {provider, errors, title, message, response, def}) {
      this.emitSlim('social.user.not-registered', e, provider)
      if (def.registerOnFailedAuth !== false) {
        const data = JSON.parse(errors['login-user-data']) || {}
        this.$router.push({ name: 'user.register', query: {...data, social_provider: provider}});
      } else {
        const showValidationErrors = fv(def.showValidationErrors, this.showSocialAuthValidationErrors)
        if (showValidationErrors) {
          this.errorAlertAsync(message, title)
        }
      }
    },
    async handleSocialUserNotVerifiedError(e, {provider, errors}) {
      const email = errors['login-user-not-confirmed-email']
      this.emitSlim('social.user.not-verified', e, provider)
      if (this.showVerificationError) {
        this.showVerificationError(email)
      }
    },
    async initSocialAuth(provider) {
      this.emitSlim('social.start', provider)
      const message = _.get(this.socialAuthAttrs[provider], 'processing.message')
      if (this.startProcessing) {
        this.startProcessing(message)
      }
    }
  },
}

