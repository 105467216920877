/*global _*/

export default {
  data() {
    return {
      builder: {
        isProcessing: false,
        processingMessage: '',
        pageIndex: 0,
        pages: [],
      },

      builderForm: {
        fields: {},
        validations: {},
        options: {},
      },

      builderValidations: {
        validations: {},
        messages: {},
      },
      builderConfig: {},

      builderValues: {},
    }
  },
  mixins: [],
  computed: {

    builderFormFields() {
      return this.builderForm.fields
    },
    getStoreValue() {
      return this.builderForm.fields
    },

    // builderData() {
    //
    // },

    isProcessing: {
      get() {
        return this.builder.isProcessing
      },
      set(v) {
        this.builder.isProcessing = v
      }
    },
    processingMessage: {
      get() {
        return this.builder.processingMessage
      },
      set(v) {
        this.builder.processingMessage = v
      }
    },
    pageIndex: {
      get() {
        return this.builder.pageIndex
      },
      set(v) {
        this.builder.pageIndex = v
      }
    },
    pages: {
      get() {
        return this.builder.pages
      },
      set(v) {
        this.builder.pages = v
      }
    },
  },
  created() {
  },
  methods: {
    setBuilderFormFieldProp(field){
    const name = field.name
    if (!_.has(this.builderForm.fields, name)) {
      Vue.set(this.builderForm.fields, name, {})
    }
    let data = this.builderForm.fields[name]
    data = _.merge({}, data, field)
    Vue.set(this.builderForm.fields, name, data)
    },
    setBuilderFormField(field){
      Vue.set(state.builderForm.fields, field.name, field)
    },
    setBuilderFormFields(data){
      Vue.set(state.builderForm, 'fields', data)
    },
    setBuilderForm(data){
      Vue.set(this, 'builderForm', data)
    },
    setBuilderConfigs(data){
       Vue.set(this, 'builderConfig', data)
    },
    setBuilderConfig({key, value}){
      const data = this.builderValues
      _.set(data, key, value)
      Vue.set(this, 'builderConfig', data)
    },
    setBuilderValues(data){
      Vue.set(this, 'builderValues', data)
    },
    setBuidlerValue({key, value}){
      const data = this.builderValues
      _.set(data, key, value)
      Vue.set(this, 'builderValues', data)
    },
    setBuilderValidations(payload){
       Vue.set(this, 'builderValidations', payload)
    },
    setBuilderValidation({key, value}){
      const data = this.builderValidations
      _.set(data, key, value)
      Vue.set(this, 'builderValidations', data)
    },
    setBuilder(payload){
      Vue.set(this, 'builder', payload)
    },
    setBuilderProp({key, value}){
      const data = this.builder
      _.set(data, key, value)
      Vue.set(this, 'builder', data)
    },
    setStoreValue(item){
      const storage = _.get(this, item.accessor, this)
      Vue.set(storage, item.key, item.value)
    },
  }
}
