import component from '../../../props/component'
import processing from '../../../props/processing'
import {context} from '../../../props/context'

export const props = {
  ...component,
  ...processing,
  context,
  // value: {}, // add by base-input mixin
  options: {},
  activeRow: {},
  readonly: {},
  disabled: {},
  name: {}
}

export default props
