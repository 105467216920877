import * as types from './mutation-types'

// set this true to namespace all getters, mutations, actions etc.
const namespaced =  true
export const namespace = 'Designer'

// state
export const state = {
  isDesignerMode: false,
}

// mutations
export const mutations = {
  [types.SET_DESIGNER_MODE](state, field) {
    state.isDesignerMode = field
  },
}

export const actions = {
  enableDesignerMode({commit}, isEnabled = true) {
    commit(types.SET_DESIGNER_MODE, isEnabled)
  },
  disableDesignerMode({commit}) {
    commit(types.SET_DESIGNER_MODE, false)
  }
}

// getters
export const getters = {
  isDesignerMode: state => state.isDesignerMode
}

const pages = { namespaced, namespace, state, mutations, actions, getters }
export default pages
