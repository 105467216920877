<template lang="pug">
  fragment
    .b-select-blocks-search
      input.b-select-blocks-search__input(v-model="search" placeholder="Find ...")
      img.b-select-blocks-search__icon(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/search-icon.svg`")
    .b-select-blocks-container
      p.b-select-blocks-search-empty(v-if="!filteredBlocks.length") No items were found to match your search.
      template(v-for="item in filteredBlocks")
        .b-select-blocks-block(
          @click="select(item)"
          @mouseover="mouseover(item)"
          @mouseleave="mouseleave(item)"
          :class="{ 'b-select-blocks-block--selected': isSelected(item) }"
        )
          .b-select-blocks-block__text {{ item.label }}
          .b-select-blocks-block__icon
            inline-svg(v-if="('hoverIcon' in item) && (isSelected(item) || isHover(item))" :src="require(`../../../public/images/client/saas-onboarding/onboarding/blocks/${item.hoverIcon}`)")
            inline-svg(v-else :src="require(`../../../public/images/client/saas-onboarding/onboarding/blocks/${item.icon}`)")
</template>

<script>
import { Fragment } from 'vue-fragment'

export default {
  name: 'onboarding-select-blocks',

  components: {
    Fragment
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array|String,
    },
    single: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    search: '',
    hover: null,
  }),

  computed: {
    filteredBlocks() {
      return this.blocks.filter(block => block.label.toLowerCase().includes(this.search.toLowerCase()))
    },
  },

  methods: {
    select(item) {
      if (this.single) {
        this.$emit('select', item.label)
      } else {
        if (this.selected.includes(item.label)) {
          this.selected.splice(this.selected.indexOf(item.label), 1)
        } else {
          this.selected.push(item.label)
        }
        this.$emit('select', this.selected)
      }
    },
    mouseover(item) {
      this.hover = item.label
    },
    mouseleave(item) {
      this.hover = null
    },
    isSelected(item) {
      return this.single ? this.selected == item.label : this.selected.includes(item.label)
    },
    isHover(item) {
      return this.hover == item.label
    },
  },
}
</script>
