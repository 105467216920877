export const queryVars = {
  // query vars (
  type: {},
  subtype: {},
  provider: {},
  providertype: {},
  category: {},
  customtype: {},
  view: {},
  token: {},
  _page: {},
}

export default {...queryVars}
