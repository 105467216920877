export const props = {
  value: {
    type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
    default: null
  },
  groupClass: {
    type: String,
    default: 'form-field'
  },
  groupErrorClass: {
    type: String,
    default: 'is-invalid form-group-error'
  },
  errorClass: {
    type: String,
    default: 'invalid-feedback'
  },

  validations: {
    type: Object,
    default: null
  },
  validator: {
    type: Function,
    default: null
  },
  tabindex: {
    default: null
  },
  hint: {
    type: String,
    default: null
  },

  customErrors: {
    type: [String, Array, Object, Error],
    default: null
  },
  v: {
    type: Object,
    default: null
  },
  vRoot: {
    type: Object,
    default: null
  },
  getV: {},
  getVRoot: {},

  isDirty: {
    type: [String, Number, Boolean],
    default: false
  },
  checkInvalid: {
    type: [String, Number, Boolean],
    default: false
  },
  checkInvalidOnClean: {
    type: [String, Number, Boolean],
    default: false
  },
}
export const attrs = _.keys(props)
export default props
