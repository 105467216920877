export const name = 're-cart-redemption'

// generic setup
import {allValidationsRules,
  defaultFormFields as formFields,
  parseValidations,
  parseValidationSetup
} from './form'

export {parseValidations, parseValidationSetup}
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../../mixins/components/complex-reactive'
import FormMixin from '../../../../../../mixins/form'
import CartMixin from '../../../../../../mixins/cart'
import GiftcardApplyMixin from '../../../../../../mixins/external/giftcard-apply'
export const mixins = [ComponentMixin, FormMixin, CartMixin, GiftcardApplyMixin]

export const EventBus = window.VueEventBus

export const data = {
  propDefs,
  fields: defaultFormFields,
  loadingMessage: '',
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  parseValidations,
  parseValidationSetup,

  EventBus
}
