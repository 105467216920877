export const name = 're-cart-item-card'

// generic setup
import {allValidationsRules, defaultFormFields as formFields} from './form'
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../mixins/components/complex'
import FormMixin from '../../../../../mixins/form'
import CartMixin from '../../../../../mixins/cart'
export const mixins = [ComponentMixin, FormMixin, CartMixin]

export const EventBus = window.VueEventBus
export const DataFilter = window.VueDataFilter
import {applyStandardBonusOnProduct} from '../../../../../lib/shop/cards/standard-bonus'
export {applyStandardBonusOnProduct}

export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus,
  DataFilter,
  applyStandardBonusOnProduct
}
