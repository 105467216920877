
export class BaseError extends Error {
  constructor(message, code, params, previous) {
    super(message || 'Error.')
    this.code = code
    this.params = params
    this.previous = previous
  }
}

export default BaseError
