export default {
  props: {
    value: {}
  },
  data() {
    return {
      innerValueName: 'innerValue',
      innerValue: this.value
    }
  },
  computed: {
    allAttrs() {
      return {...this.$props, ...this.$attrs}
    },
    model: {
      get() {
        return this.innerValue
      },
      set(value) {
        this.innerValue = value
        this.$emit('input', value)
      }
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.innerValue = newValue
      },
      deep: true
    },
  },

  methods: {
    onInput(event) {
      this.model = event
    },
    onChange(event) {
      this.model = event
    },
  }
}

