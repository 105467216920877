/*global _ */
import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const localProps = {
  items: {
    type: Object,
    default: null
  },
  order: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Your order is confirmed')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice',
        `We sent your confirmation to {{email}}.<br/>
        See your <a href="{{this.invoiceUrl}}">payment details</a> for more information.`)
    }
  },
  languageParserNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('languageParserNotice', true)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', `Thank you {{first_name}} {{last_name}},`)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', true)
    }
  },
  confirmationMessage: {
    type: String,
    default() {
      return this.$options.$$t('confirmationMessage',
        `If you have not received an email with the order confirmation please click on the`)
    }
  },
  showConfirmationMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showConfirmationMessage', true)
    }
  },
  confirmationResendLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('confirmationResendLink.label',
        'Resend')
    }
  },
  showConfirmationResendLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showConfirmationResendLink', true)
    }
  },
  showPrintButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('print.showButton', true)
    }
  },
  printButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('print.buttonTitle',
        'Print confirmation')
    }
  },
  showConfirmationButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('confirmation.button.show', false)
    }
  },
  confirmationButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('confirmation.button.title', 'Go to account')
    }
  },
  confirmationButtonAttrs: {
    type: [String, Object, Array],
    default() {
      return _.merge(
        {
          classes: ['btn'],
          noDefaultClass: true,
          to: {name: 'user.profile'}
        },
        this.$options.$$t('confirmation.button.attrs', {}),
        this.$options.$$s('confirmation.button.attrs', {})
      )
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Processing')
    }
  },
  orderEmailSuccessTitlePrefix: {
    type: String,
    default() {
      return this.$options.$$t('orderEmail.success.titlePrefix',
        'The order confirmation has been resent to the')
    }
  },
  orderEmailSuccessTitlePostfix: {
    type: String,
    default() {
      return this.$options.$$t('orderEmail.success.titlePostfix',
        'following email address')
    }
  },
  orderEmailSuccessResendTitle: {
    type: String,
    default() {
      return this.$options.$$t('orderEmail.success.resendTitle',
        'The order confirmation has been resent.')
    }
  },
  orderEmailSuccessButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('orderEmail.success.buttonTitle',
        'Resend one more time')
    }
  },
  userVerificationEmailSuccessResendMessage: {
    type: String,
    default() {
      return this.$options.$$t('userVerificationEmail.successResendMessage',
        'The registration confirmation has been resent.')
    }
  },
  userVerificationEmailSuccessPrefixMessage: {
    type: String,
    default() {
      return this.$options.$$t('userVerificationEmail.successPrefixMessage',
        'The registration confirmation has been resent to the ')
    }
  },
  userVerificationEmailSuccessPostfixMessage: {
    type: String,
    default() {
      return this.$options.$$t('userVerificationEmail.successPostfixMessage',
        'following email address ')
    }
  },
  userVerificationEmailSuccessButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('userVerificationEmail.successButtonTitle',
        'Resend one more time')
    }
  },
  verificationPromptTitle: {
    type: String,
    default() {
      return this.$options.$$t('verificationPrompt.title',
        `We also created an account for you. The confirmation
         has been sent to the following email address `)
    }
  },
  showVerificationPromptTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('verificationPrompt.showTitle', true)
    }
  },
  verificationPromptMessage: {
    type: String,
    default() {
      return this.$options.$$t('verificationPrompt.message',
        `To complete your account registration, please click on the
        verification link contained inside the registration email.
        If you have not received the registration confirmation email please click here to`)
    }
  },
  showVerificationPromptMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('verificationPrompt.showMessage', true)
    }
  },
  verificationPromptButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('verificationPrompt.buttonTitle',
        'resend link.')
    }
  },
  showVerificationPromptButtonTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('verificationPrompt.showButton', true)
    }
  },
  contentBeforePrintButton: {
    type: String,
    default() {
      return this.$options.$$t('print.messageBefore', '')
    }
  },
  contentAfterPrintButton: {
    type: String,
    default() {
      return this.$options.$$t('print.messageAfter', '')
    }
  },
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
