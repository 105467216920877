/*global _ */
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data
    }
  },
  computed: {
    canShowHeaderButtons() {
      return !this.isPromising && (this.showLocationSelector || this.showHistoryButton)
    },
    rewardItems() {
      return [...this.loyaltyAchievementRewards || []]
    },
    rewardsInfo() {
      const messages = []
      if (this.hasUnlockedAchievementRewards) {
        messages.push(this.unlockedRewardsMessage)
        const hasRemaining = this.lockedAchievementRewards.length > 0
        messages.push(
          hasRemaining ?
            this.unlockedRemainingRewardsMessageSuffix:
            this.unlockedNoRemainingRewardsMessageSuffix
        )
      }
      return messages.join('')
    },
    rewardsTableTitle() {
      return this.parseReactiveValues(this.rewardsTitle, this.parseSourceData())
    },
    showingHistory() {
      return _.get(this.storedProfileData, 'states.loyalty.showingHistory')
    },
    historyButtonAttrs() {
      return {
        title: this.historyButtonTitle,
        class: this.historyButtonClasses
      }
    },
    hasNoLockedReward() {
      return !this.lockedAchievementRewards.length
    },
  },
  methods: {
    showHistory() {
      this.emitSlim('show-history', this)
    }
  }
}
