<template lang="pug">
  .b-onboarding.b-onboarding--welcome
    .b-onboarding__left
      img.b-onboarding__left-logo(:src="`${config.baseUri}/images/client/saas-onboarding/logo.sm.white.svg`")
      .b-welcome-left

        .b-welcome-left__quote
          img.b-welcome-left__quote-symbol(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-quote.svg`")
          .b-welcome-left__quote-text Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi porta dictum convallis neque aliquam libero orci placerat. Eleifend nunc adipiscing massa arcu euismod

        img.b-welcome-left__signature(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-signature.svg`")
        img.b-welcome-left__silhouette(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-silhouette-full.svg`")
    .b-onboarding__right
      .b-onboarding__title Hi, I’m Greg Moreno!
      .b-onboarding__subtitle CEO and Founder of Rocket Effect!
      
      .b-welcome-right
        youtube.b-video-youtube(video-id="Y0Ve5Z0xIHQ" player-width="100%")

      .b-onboarding__footer
        .b-onboarding__footer-left
          p
            template(v-for="item in contact")
              onboarding-contact-line(:line="item")
              br
          .b-welcome-contact-buttons
            v-button.btn.btn-secondary(@click="sendToPhone" :loading="sendingToPhone")
              .b-welcome-contact-button__text Send To Phone
              img.b-welcome-contact-button__icon(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-contact-send-icon.svg`")
            button.btn.btn-secondary.m-l-15(v-clipboard:copy="copyMessage")
              .b-welcome-contact-button__text Copy
              img.b-welcome-contact-button__icon(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-contact-copy-icon.svg`")
            a.btn.btn-secondary.m-l-15(href="vcard")
              .b-welcome-contact-button__text Download
              img.b-welcome-contact-button__icon(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-welcome-contact-send-icon.svg`")
        .b-onboarding__footer-right
          router-link(:to="{ name: 'onboarding-step-one' }")
            span.btn.btn-primary.btn-lg Next
              i
                img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-btn-next.svg`")

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'
import OnboardingContactLine from '../components/OnboardingContactLine'

export default {
  mixins: [
    OnboardingStep
  ],

  components: {
    OnboardingContactLine,
  },

  data: () => ({
    sendingToPhone: false,
  }),

  computed: {
    contact() {
      return this.config.translations.onboarding.contact
    },
    copyMessage() {
      return this.contact.join("\n")
    },
  },

  methods: {
    async sendToPhone() {
      try {
        this.sendingToPhone = true
        await axios.post(`${this.config.baseUri}/api/onboarding/send-contact-to-phone`)
      } finally {
        this.sendingToPhone = false
      }
    },
  }
}
</script>
