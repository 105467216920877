export const name = 're-core-page-shop-product-editor'
export const component = 're-shop-product-editor'

import {factory} from '../../../../../lib/page'

const props = {
  product: {},
  token: {},
}

const data = {
  renameMergeAttrProps: {
    product: 'item'
  }
}

export default factory(name, {props, component, data})


