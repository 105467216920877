export const name = 're-core-page-user-reset-password'
export const component = 're-reset-password'

import {factory} from '../../../../lib/page'

const props = {
  token: {},
  email: {},
}
export default factory(name, {props, component})
