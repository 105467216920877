/*global _ */
import {Vue} from '~/addiesaas'
import componentConfig, {socialConfig} from './config'
import Service from '../../../../../services/social/media/storage'
import {setTimeoutAsync} from '../../../../../lib/utils'

// @todo: Remove this implementation
// Was added since `auth` vuex store was being cleaned after storage social login
// This seems to be due to the social auth redirect page which also
// subscribes to the `auth` vuexstore.
// The page (in the social auth redirect popup/tab) is closed immediately
// probably before the `auth`'s persistent storage can refresh the stored data to vuex
let cachedToken

export default {
  ...componentConfig,
  data() {
    return {
      isCheckingProviderStatus: false,
      isShowingLocalLoader: false,
      isFetchingFiles: false,
      isConnected: false,
      connectorErrorMessage: null,
      selectorErrorMessage: null,

      folders: [],
      currentFolder: 'root',
      parentFolders: {},
      breadcrumbs: [],

      items: [],
      selected: {},
      pagination: {
        page: 0,
        itemsPerPage: 1,
        completed: false
      }
    }
  },
  computed: {
    servicePrefix() {
      return `user.social.storage`
    },
    providerAttrs() {
      const provider = this.provider
      return _.merge({}, socialConfig[provider] || {}, this.providerOptions || {})
    },
    connectButtonAttributes() {
      const provider = this.provider
      // const provider = this.providerAttrs.provider || this.provider
      const attrs = this.connectButtonAttrs || {}
      const buttonAttrs = attrs.buttonAttrs || {}
      if (this.isCheckingProviderStatus) {
        buttonAttrs.loading = true
        buttonAttrs.disabled = true
      }

      return {
        provider,
        providerAttrs: this.providerAttrs,
        ...attrs,
        buttonAttrs,
        class: this.parseAttrClasses(attrs, [`re-social-media-storage-button--${provider}`]),
      }
    },
    confirmButtonAttributes() {
      const provider = this.providerAttrs.provider || this.provider
      const attrs = this.confirmButtonAttrs || {}

      return {
        ...attrs,
        disabled: !this.selectedItems.length,
        class: this.parseAttrClasses(attrs, [`re-social-media-selector-button--${provider}`]),
      }
    },
    disconnectButtonAttributes() {
      const provider = this.providerAttrs.provider || this.provider
      const attrs = this.disconnectButtonAttrs || {}

      return {
        ...attrs,
        class: this.parseAttrClasses(attrs, [`re-social-media-selector-button--${provider}`]),
      }
    },
    navBackButtonAttributes() {
      const provider = this.providerAttrs.provider || this.provider
      const attrs = this.navigateBackButtonAttrs || {}

      return {
        ...attrs,
        class: this.parseAttrClasses(attrs, [`re-social-media-selector-button--${provider}`]),
      }
    },
    selectedItems() {
      return _.values(this.selected || {})
    },
    isRootFolder() {
      return this.currentFolder === 'root'
    },
    hasFolders() {
      return !!this.folders.length
    },
  },
  watch: {
    async currentFolder(folder, previousFolder) {
      if (previousFolder) {
        this.currentFolder = folder
        this.resetFiles()
        await this.navigate()
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    getComponentExtraClasses() {
      return [
        `re-social-media-storage-selector-container--${this.provider}`,
        {
          connected: this.isConnected,
          'not-connected': !this.isConnected,
        }
      ]
    },
    getItemAttrs(item) {
      return {
        class: [{selected: this.isItemSelected(item)}],
      }
    },
    isItemSelected(item) {
      return this.selected[item.id]
    },
    selectItem(item) {
      this.$set(this.selected, item.id, item)
    },
    deselectItem(item) {
      this.$delete(this.selected, item.id)
    },
    toggleItemSelection(item) {
      if (this.isItemSelected(item)) {
        this.deselectItem(item)
      } else {
        if (!this.limit || this.limit < this.selectedItems.length) {
          this.selectItem(item)
        }
      }
    },
    disconnect(localOnly) {
      if (!localOnly) {
        this.callService('disconnect', {})
      }
      this.isConnected = false
    },
    setConnected() {
      this.isConnected = true
    },
    onScroll: _.debounce(async function (e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      const isBottom = (scrollTop + offsetHeight) >= (scrollHeight - this.scrollBottomOffset)
      if (isBottom) {
        await this.fetchFiles()
      }
    }, 200, {leading: false, trailing: true}),
    async callService(name, payload = {}) {
      let token = _.get(window, 'addiesaas.store.getters.authToken')
      debugLog({SOCIAL_API_CALL_TOKEN: name, token, cachedToken, window})
      if (!token) {
        token = cachedToken
        if (token) {
          await this.saveToken({token})
        }
        token = _.get(window, 'addiesaas.store.getters.authToken')
        debugLog({SOCIAL_API_CALL_TOKEN_FROM_CACHE: name, token, cachedToken, window})
      }
      cachedToken = token
      payload.id = this.provider
      // const service = `${this.servicePrefix}.${name}`
      const service = {'folders.get': 'getFolders', 'files.get': 'getFiles'}[name] || name
      return await Service[service](payload)
    },
    async init() {
      this.resetFiles()
      this.isCheckingProviderStatus = true
      this.disconnect(true)
      try {
        const data = await this.callService('get', {})
        if (data.authenticated) {
          this.setConnected()
          await this.navigate()
        }
      } finally {
        this.isCheckingProviderStatus = false
      }
    },
    async afterSuccessSocialAuth(data) {
      this.resetFiles()
      this.isShowingLocalLoader = true
      data.redirect_uri = _.get(this.providerAttrs, 'redirect')
      try {
        await setTimeoutAsync(this.preAuthDelay || 0)
        await this.callService('authorize', {data})
        await this.$nextTick()
        await setTimeoutAsync(this.postAuthDelay || 0)
        this.setConnected()
        this.isShowingLocalLoader = false
        await this.navigate()
      } finally {
        this.isShowingLocalLoader = false
      }
    },
    getFolderItemAttrs(folder) {
      return {
        class: [{
        }],
      }
    },
    async navigateFolder(folder, isBreadcrumb) {
      const id = folder.id
      if (this.currentFolder !== id && !this.isFetchingFiles) {
        if (!isBreadcrumb) {
          Vue.set(this.parentFolders, id, this.currentFolder)
          this.breadcrumbs.push(folder)
        }
        this.currentFolder = id
      }
    },
    async navigateBack(breadcrumbId) {
      const selectedFolder = breadcrumbId
      if (!this.isRootFolder && !this.isFetchingFiles) {
        const folder = this.currentFolder
        const previousId = selectedFolder || this.parentFolders[folder]
        if (previousId) {
          // Vue.delete(this.parentFolders, id)
          this.currentFolder = previousId
        }
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = this.breadcrumbs
      if (_.isEmpty(breadcrumbs)) {
        this.breadcrumbs = [{
          id: 'root',
          name: this.navigateRootFolderTitle
        }]
      } else {
        const id = this.currentFolder
        const index = _.findIndex(breadcrumbs, item => item.id === id)
        if (index >= 0) {
          this.breadcrumbs.splice(index + 1)
        }
      }
    },
    async navigate(isSilent) {
      if (this.useNavigation) {
        await this.updateBreadcrumbs()
        await this.fetchFolders(isSilent)
        await this.fetchFiles(isSilent)
      } else {
        await this.fetchFiles(isSilent)
      }
    },
    async fetchFolders(isSilent) {
      if (this.isFetchingFiles) {
        return
      }
      this.folders = []
      try {
        this.isFetchingFiles = !isSilent
        const folder = this.currentFolder || 'root'
        this.folders = await this.callService('folders.get', {folder})
      } finally {
        this.isFetchingFiles = false
      }
    },
    resetFiles() {
      this.resetErrors()
      this.items = []
      this.pagination = {
        page: 0,
        itemsPerPage: this.pageSize,
        completed: false
      }
    },
    async fetchFiles(isSilent) {
      const folder = this.useNavigation ? this.currentFolder : null
      const pagination = this.pagination
      if (this.isFetchingFiles || pagination.completed) {
        return
      }
      const count = pagination.itemsPerPage
      const offset = this.items.length
      try {
        this.isFetchingFiles = !isSilent
        const newItems = await this.callService('files.get', {count, offset, folder})
        if (!newItems.length) {
          pagination.completed = true
          if (!this.items.length) {
            if (this.useNavigation && !this.isRootFolder) {
              this.showSelectorErrorMessage('NODATAINFOLDER')
            } else {
              this.showSelectorErrorMessage('NODATA')
            }
          }
        } else {
          this.items = [...this.items, ...newItems]
        }
      } finally {
        this.isFetchingFiles = false
      }
    },
    confirm() {
      this.$emit('input', this.selectedItems)
      return this.$emit('close', this.selectedItems)
    }
  },
}
