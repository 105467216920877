export default {
  props: {
    containerAttrs: {
      default() {
        return this.$options.$$s('attrs', {})
      }
    },
    pagesComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.pages', null)
      }
    },
    pagesAttrs: {
      default() {
        return this.$options.$$s('attr.page', {})
      }
    },

    pageComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.pages', null)
      }
    },
    pageAttrs: {
      default() {
        return this.$options.$$s('attr.page', {})
      }
    },
    pageListeners: {
      default() {
        return {}
      }
    },

    pageNumberComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.number', null)
      }
    },
    pageNumberAttrs: {
      default() {
        return this.$options.$$s('attr.pageNumber', {})
      }
    },
    pageNumberListeners: {
      default() {
        return {}
      }
    },

    pageGapComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.gap', null)
      }
    },
    pageGapAttrs: {
      default() {
        return this.$options.$$s('attr.gap', {})
      }
    },

    prevComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.prev', null)
      }
    },
    prevAttrs: {
      default() {
        return this.$options.$$s('attr.prev', {})
      }
    },
    prevListeners: {
      default() {
        return {}
      }
    },

    nextComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.next', null)
      }
    },
    nextAttrs: {
      default() {
        return this.$options.$$s('attr.next', {})
      }
    },
    nextListeners: {
      default() {
        return {}
      }
    },

    messageComponent: {
      type: [String],
      default() {
        return this.$options.$$s('components.message', null)
      }
    },
    messageAttrs: {
      default() {
        return this.$options.$$s('attr.message', {})
      }
    },
    messageListeners: {
      default() {
        return {}
      }
    },

  },
  computed: {
    pagesComponentAttrs() {
      const baseAttrs = {
        class: [
          (this.pagesAttrs || {}).classes
        ]
      }
      if (this.pagesComponent) {
        return {
          ...baseAttrs,
          ...this.pagesAttrs || {}
        }
      }
      return baseAttrs
    },
    pagesComponentListeners() {
      if (this.pagesComponent) {
        return this.$listeners
      }
      return {}
    },

    pageComponentAttrs() {
      const baseAttrs = {
        class: [
          (this.pageAttrs || {}).classes
        ]
      }
      if (this.pageComponent) {
        return {
          ...baseAttrs,
          ...this.pageAttrs || {}
        }
      }
      return baseAttrs
    },
    pageComponentListeners() {
      if (this.pageComponent) {
        return this.pageListeners
      }
      return {}
    },

    pageNumberComponentAttrs() {
      const baseAttrs = {
        class: [
          (this.pageNumberAttrs || {}).classes
        ]
      }
      if (this.pageNumberComponent) {
        return {
          ...baseAttrs,
          ...this.pageNumberAttrs || {}
        }
      }
      return baseAttrs
    },
    pageNumberComponentListeners() {
      if (this.pagesComponent) {
        return this.pageNumberListeners
      }
      return {}
    },

    pageGapComponentAttrs() {
      const baseAttrs = {
        class: [
          (this.pageGapAttrs || {}).classes
        ]
      }
      if (this.pageGapComponent) {
        return {
          ...baseAttrs,
          ...this.pageGapAttrs || {}
        }
      }
      return baseAttrs
    },

    prevComponentAttrs() {
      const baseAttrs = {
        class: [
          {'no-nav': !this.canPrev},
          (this.prevAttrs || {}).classes
        ]
      }
      if (this.prevComponent) {
        return {
          ...baseAttrs,
          ...this.prevAttrs || {}
        }
      }
      return baseAttrs
    },
    prevComponentListeners() {
      if (this.prevComponent) {
        return this.prevListeners
      }
      return {}
    },

    nextComponentAttrs() {
      const baseAttrs = {
        class: [
          {'no-nav': !this.canNext},
          (this.nextAttrs || {}).classes
        ]
      }
      if (this.nextComponent) {
        return {
          ...baseAttrs,
          ...this.nextAttrs || {}
        }
      }
      return baseAttrs
    },
    nextComponentListeners() {
      if (this.nextComponent) {
        return this.nextListeners
      }
      return {}
    },

    messageComponentAttrs() {
      const baseAttrs = {
        class: [
          (this.messageAttrs || {}).classes
        ]
      }
      if (this.messageComponent) {
        return {
          ...baseAttrs,
          ...this.messageAttrs || {}
        }
      }
      return baseAttrs
    },
    messageComponentListeners() {
      if (this.messageComponent) {
        return this.messageListeners
      }
      return {}
    },

    slotAttrs() {
      return {
        paginate: this,
      }
    },
    prevSlotAttrs() {
      return {
        ...this.slotAttrs,
      }
    },
    nextSlotAttrs() {
      return {
        ...this.slotAttrs,
      }
    },
    pageSlotAttrs() {
      return {
        ...this.slotAttrs,
      }
    },
    pageNumberSlotAttrs() {
      return {
        ...this.slotAttrs,
      }
    },
    messageSlotAttrs() {
      return {
        ...this.slotAttrs,
        message: this.message,
      }
    }
  },
}
