import ConfigMixin from './config'
import UtilsMixin from './utils'
import EventsMixin from './events'
import AlertMixin from './alert'
import ToastAlertMixin from './toast-alert'
import ScrollerMixin from './scroller'
import ProcessorMixin from './processor'
import DatesMixin from './dates'
import PagesMixin from './pages'
import AuthMixin from './auth'
import LocationMixin from './location'
import ServiceMixin from './service'
import StorageMixin from './storage'
import DesignerMixin from '../designer/mixins'

export default {
  mixins: [
    StorageMixin,
    ConfigMixin,
    UtilsMixin,
    EventsMixin,
    ServiceMixin,
    AlertMixin,
    ToastAlertMixin,
    ScrollerMixin,
    ProcessorMixin,
    DatesMixin,
    PagesMixin,
    AuthMixin,
    LocationMixin,
    DesignerMixin
  ],
  data() {
    return {
      copyMergeAttrProps: {},
      renameMergeAttrProps: {},
      omitMergeAttrProps: [],
    }
  },
  computed: {
    mergedAttr() {
      const defs = this.propDefs || {}
      const props = _.mapKeys(this.$props, (value, key) => _.get(defs, `${key}.componentProp`, key))
      const attrs = _.mapKeys(this.$attrs, (value, key) => _.get(defs, `${key}.componentProp`, key))
      const copyMergeAttrProps = this.copyMergeAttrProps || {}
      const renameMergeAttrProps = this.renameMergeAttrProps || {}
      const omitMergeAttrProps = _.keyBy(this.omitMergeAttrProps || [])
      const merged = {...attrs, ...props}
      _.forOwn({...copyMergeAttrProps, ...renameMergeAttrProps}, (changedKey, key) => {
        if (key in merged) {
          merged[changedKey] = merged[key]
        }
      })
      _.forOwn(renameMergeAttrProps, (changedKey, key) => {
        if (key in merged) {
          Vue.delete(merged, key)
        }
      })

      const all = _.omitBy(merged, (value, key) => key in omitMergeAttrProps || _.isNil(value))
      return all
    },
    mergedAttrExcludeTranslations() {
      return _.omit(this.mergedAttr, ['translations'])
    },
    mergedAttrExcludeSettings() {
      return _.omit(this.mergedAttr, ['settings'])
    },
    mergedAttrExcludeTranslationsSettings() {
      return _.omit(this.mergedAttr, ['settings', 'translations'])
    }
  },
}

