import $ from 'jquery/dist/jquery.slim.min'

export const get$ = (selector) => $(selector)
export const getv$ = (prop, selector) => get$(selector)[prop]
export const set$ = (prop, value, selector = 'body') => $(selector)[prop](value)
export const title$ = title => document.title = title
export const html$ = (html, selector) => set$('html', html, selector)
export const style$ = (styles, selector) => set$('css', styles, selector)
export const attr$ = (attr, selector = 'body') => set$('attr', attr, selector)
export const prop$ = (prop, value, selector = 'body') => $(selector).prop(prop, value)
export const class$ = (className, remove = false, selector = 'body') => {
  if (remove) {
    set$('removeClass', className, selector)
  } else {
    set$('addClass', className, selector)
  }
}
export const blockUI = (elm, options) => {
  elm = elm || 'body'
  options = options || {}
  const name = options.name
  const $elm = $(elm)
  let selector = '.blockUI[data-block-ui]'
  if (name) {
    selector += '[data-block-name="' + name + '"]'
  }

  $elm.css({position: 'relative'})
  let ui = $elm.find(selector)
  if (!ui.length) {
    ui = $("<div class='blockUI zindex10000' data-block-ui></div>")
    $elm.append(ui)
  }
  if (options.loader !== false) {
    ui.addClass('any-loader')
  }
  if (name) {
    ui.attr('data-block-name', name)
  }
  ui.show()
  return ui
}

export const unblockUI = function (elm, options) {
  elm = elm || 'body'
  options = options || {}
  const name = options.name
  const $elm = $(elm)
  let selector = '.blockUI[data-block-ui]'
  if (name) {
    selector += '[data-block-name="' + name + '"]'
  }
  const ui = $elm.find(selector)
  ui.hide().remove()
  return ui
}

export const copyToClipboard = (value, options) => {
  const $temp = $('<input>')
  $('body').append($temp)
  $temp.val(value).select()
  document.execCommand("copy")
  $temp.remove()
}

export default {
  get$,
  getv$,
  set$,
  title$,
  style$,
  html$,
  attr$,
  prop$,
  class$,
  copyToClipboard
}
