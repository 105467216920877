/*global $ */
const select2SelectionTemplate = (state) => {
  return state.text
}
const select2OptionTemplate = (state) => {
  if (state.required === true) {
    const option = $(`<span class="select2-option-required"></span>`)
    option.text(state.text)
    return option
  }
  return state.text
}

export const general = {
  allowClear: true,
  closeOnSelect: true,
  width: '100%',
  debug: false,
}
export const bootstrap = {
  ...general,
  theme: 'bootstrap'
}

export const re = {
  ...bootstrap,
  templateSelection: select2SelectionTemplate,
  templateResult: select2OptionTemplate
}

export default {
  default: general, bootstrap, re,
}
