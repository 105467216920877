export const name = 're-profile-family'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../mixins/components/complex-reactive'
import ProfileMixin from '../../../../mixins/profile'
import TableMainMixin from '../../../../mixins/table'
import TableMixin from './table'
import EditMixin from './edit'
import FormMixin from './form'
import ResponsiveMixin from '../../../../mixins/responsive'
import CoreFormMixin from '../../../../mixins/form'
import ProfileLocationMixin from '../../../../mixins/profile/location'
import CardLocationMixin from '../../../../mixins/profile/cards/locations'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'

export const mixins = [
  ComponentMixin,
  ProfileMixin,
  TableMixin,
  TableMainMixin,
  EditMixin,
  FormMixin,

  ProfileLocationMixin,
  CoreFormMixin,
  FieldsMixin,
  CardLocationMixin,

  ResponsiveMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
