import createPersistedState from 'vuex-persistedstate'
import * as types from './mutation-types'

const namespaced = true
const namespace = 'MultiTenancy'

const state = {
  tenant: {
    url: window.addiesaas.config.baseUri,
  },
}

const mutations = {
  [types.SAVE_TENANT] (state, { tenant }) {
    state.tenant = tenant
  },
}

const actions = {
  saveTenant ({ commit }, payload) {
    commit(types.SAVE_TENANT, payload)
  },
}

const getters = {
  tenant: state => state.tenant,
}

const modules = {
  [namespace]: {
    state,
    mutations,
    actions,
    getters,
  },
}

const plugins = [
  createPersistedState({
    paths: [namespace],
    key: namespace,
  }),
]

export { namespaced, namespace, plugins, modules }
