/* global _ */
import {fv} from '../../../lib/utils'

export default {
  computed: {
    tableColumns() {
      let columns = _.get(this.options, 'columns')
      if (_.isNil(columns)) {
        const firstRow = _.head(_.values(this.innerValue || {}))
        columns = _.map(_.keys(firstRow), name => ({name}))
      }
      return columns
    },
    tableColumnOptions() {
      return _.get(this.options, 'column', {})
    },
    tableTitleOptions() {
      return _.get(this.options, 'title', {})
    },
    tableTitleRowOptions() {
      return _.get(this.tableTitleOptions, 'row', {})
    },
    titleRowAttrs() {
      return this.tableTitleRowOptions.attrs || {}
    },
    tableTitleCellOptions() {
      return _.get(this.tableTitleRowOptions, 'cell', {})
    },
    titleCellAttrs() {
      return this.tableTitleCellOptions.attrs || {}
    },
    titleComponentName() {
      return this.tableTitleOptions.component
    },
    titleComponentAttrs() {
      return this.tableTitleOptions.componentAttrs || {}
    },
    titleComponentListeners() {
      return this.tableTitleOptions.componentListeners || {}
    }
  },

  methods: {
    getColumn(index) {
      return this.tableColumns[index]
    },
    showColumnTitle(data, index) {
      data = fv(data, this.getColumn(index))
      const showTitleGlobal = this.tableTitleOptions.showTitle !== false
      const showTitle = data.showTitle
      return fv(showTitle, showTitleGlobal)
    },
    showColumnGridTitle(data, index) {
      data = fv(data, this.getColumn(index))
      const showTitle = this.showColumnTitle(data, index)
      const showGridTitle = data.showGridTitle
      const showGridTitleGlobal = this.tableColumnOptions.showGridTitle
      return fv (showGridTitle, showGridTitleGlobal, showTitle)
    },
    getTitleCellAttrs(data, index) {
      const name = fv(data.name, data.key, index)
      return {
        ...this.titleCellAttrs,
        'data-column-index': index,
        'data-column-name': name,
      }
    },
    titleComponent(data, index) {
      data = fv(data, this.getColumn(index))
      return data.titleComponent || this.titleComponentName
    },
    getTitleComponentAttrs(data, index) {
      data = fv(data, this.getColumn(index))
      return {
        key: `title-component-${index}`,
        ...this.titleComponentAttrs,
        ...data,
      }
    },
    getTitleComponentListeners(data, index) {
      data = fv(data, this.getColumn(index))
      return this.titleComponentListeners
    },
    getTitleValueAttrs(data, index) {
      data = fv(data, this.getColumn(index))
      return {
        ...data.attrs || {}
      }
    },
    getTitleValue(data, index) {
      data = fv(data, this.getColumn(index))
      const value = data.title // fv(, _.startCase(data.name || data.key))
      const column = this.getColumn(index)
      const params = {value, vm:this, column, index}
      this.applyDataFilter('column-title', params)
      return params.value
    },
    columnHasRowActions(column, index) {
      column = fv(column, this.getColumn(index))
      return column.rowActions
    }
  }
}
