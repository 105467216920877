import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  email: {},
  firstName: {},
  lastName: {},
  socialProvider: {}, // social provider
  noSuccessScreen: {
    type: [Boolean, Number, String],
    default: null
  },
  autoLogin: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('autoLogin', false)
    }
  },
  hideActions: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('hideActions', false)
    }
  },
  disableActions: {},
  triggerSubmit: {},

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Create an account')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', 'Please complete the form below to create an account.')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', this.$options.$$s('submitButtonTitle', 'Submit'))
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },
  withBirthDayInputs: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputs', false)
    }
  },
  birthdayInputsOrder: {
    type: [Array, String],
    default() {
      return this.$options.$$s('birthdayInputsOrder', 'month,day,year')
    }
  },
  withBirthDayInputButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputButton', true)
    }
  },
  withRacerName: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.racername', this.$options.$$s('withRacerName', false))
    }
  },
  withEmailConfirmation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.email_confirmation', this.$options.$$s('withEmailConfirmation', false))
    }
  },
  withPhoneConfirmation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.phone_confirmation', this.$options.$$s('withPhoneConfirmation', false))
    }
  },
  withHowDoYouKnow: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.discovery', this.$options.$$s('withHowDoYouKnow', false))
    }
  },
  howDoYouKnowOptions: {
    type: Array,
    default() {
      return this.$options.$$s('howDoYouKnowOptions', null)
    }
  },
  withSubscribe: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.subscribe', this.$options.$$s('withSubscribe', false))
    }
  },
  withSubscribeSms: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.subscribeSms', this.$options.$$s('withSubscribeSms', false))
    }
  },

  subscribeLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.subscribe.label',
        this.$options.$$t('subscribeLabel',
            `By registering with us you are opting-in to
            receive news and special offers. To change your email preferences,
            visit account profile.`
        ))
    }
  },
  subscribeSmsLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.sms_subscribe.label',
       this.$options.$$t('form.fields.subscribe_sms.label',
       this.$options.$$t('subscribeSmsLabel',
          `By registering with us you are opting-in to
          receive news and special offers vis SMS. To change your SMS preferences,
          visit account profile.`
      )))
    }
  },

  showSuccessPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSuccessPopup', false)
    }
  },
  successTitle: {
    type: [String],
    default() {
      return this.$options.$$t('successTitle' , this.$options.$$s('successTitle', null))
    }
  },
  successSubTitle: {
    type: [String],
    default() {
      return this.$options.$$t('successSubTitle' , this.$options.$$s('successSubTitle', null))
    }
  },
  confirmationInstruction: {
    type: [String],
    default() {
      return this.$options.$$t('confirmationInstruction' , this.$options.$$s('confirmationInstruction', null))
    }
  },
  showGotoHomeButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showGotoHomeButton', false)
    }
  },
  gotoHomeButtonTitle: {
    type: [String],
    default() {
      return this.$options.$$t('gotoHomeButtonTitle' , this.$options.$$s('gotoHomeButtonTitle', 'Go to home page'))
    }
  },

  showResendConfirmationMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$t('resendConfirmationMessage') || this.$options.$$s('showResendConfirmationMessage', null)
    }
  },
  showResendConfirmationButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showResendConfirmationButton', false)
    }
  },
  resendConfirmationButtonTitle: {
    type: [String],
    default() {
      return this.$options.$$t('resendConfirmationButtonTitle', this.$options.$$s('resendConfirmationButtonTitle', 'Resend Email'))
    }
  },

  showLoginBox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLoginBox', false)
    }
  },
  showLoginBoxAtTop: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLoginBoxAtTop', false)
    }
  },
  showLoginProposalWhenEmailExists: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLoginProposalWhenEmailExists', true)
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', true)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  submitOnEnter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submitOnEnter', false)
    }
  }
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
