/*global _ */
import {fv} from '../../../lib/utils'

import Base from '../../Base'
import eventsFactory from '../../mixins/factory/events'

export const NAMESPACE = 'paginator'
export const EVENTS = {
  FIRST: 'first',
  LAST: 'last',
  NEXT: 'next',
  ERROR_NEXT: 'error:next',
  PREVIOUS: 'previous',
  ERROR_PREVIOUS: 'error:previous',
  INDEX: 'index',
  CHANGE_INDEX: 'change:index',
  PAGE: 'page',
  CHANGE_PAGE: 'change:page',
  NAVIGATE: 'navigate',
  NAV_PAGES: 'navPages',
  ERROR_NAVIGATE: 'error:navigate',
  JUMP: 'jump',
  ERROR_JUMP: 'error:jump',
  CHANGE: 'change',
  ERROR: 'error',
  READY: 'ready',
}

export class Paginator extends Base {
  /**
   *
   * @param data  Number|Array|Object Number is considered as total number of pages
   *                    Array represents the pages as array
   *                    Object can have `total`, `min`, `max`, `step`,
   *                                    `pages`, `value` or `page` properties
   *                      as per define in `options` param
   * @param options Object  {
   *                          min: first page number,
   *                          max: last page number,
   *                          step: steps of page increment (default: 1),
   *                          total: total number of pages (ignores max, min is set to 1)
   *                          pages: an array of pages (ignores min, max, step, total and re-calculates these)
   *                          value: current page,
   *                          id: id of the paginator (helps in namespacing global events),
   *                          navPages: {width, center, noGap, gapMark} -
   *                                Options to generate navigation array reducing pages array for renderer.
   *                                Generated by static method `generateNav`.
   *                                NOTE: Disable automatic generation by setting navPages to false*
   *                      }
   * @param vm  Object|Vue
   */
  constructor(data, options, vm) {
    super(data, vm)
    this.init(data, options)
  }

  /** Generates navigation array of page indices reducing pages array. To be used by renderer to show page navigation.
   *  e.g.
   *    Pages with 0 to 9 (index) or total 10, with width 7 and current page 5 will create:
   *      [0,null,4,5,6,null,9]
   *      Here: 0 and 9 are periphery indices
   *            null represents gaps
   *            4, 5, 6 are central indices
   *
   *    Pages with 0 to 99 (index) or total 100 with width 15 and current page 50 will create:
   *      [0,1,2,null,47,48,49,50,51,52,53,null,97,98,99]
   *
   *  NOTE:
   *    1. Skips generation if current is non numeric. Returns a blank array
   *    2. Skips generation and returns all the indices of pages array if total is <= width
   *
   *
   * @param total   number|array  Number represents total or array as pages
   *
   * @param current number  Current page index.
   *                        Falls back to the highest index if current value is greater than highest index
   *                        Falls back to the lowest index (0) if current is a negative number
   * @param options Object  nav options object {width, center, gapMark noGap}
   *
   *                            width: (default: 7) - number of items to show, including gaps,
   *                                any thing less than 7 may create array with UX issues
   *
   *                            center: (default: false) - if set to true, only first page and last page are
   *                                    shown at the peri
   *
   *                            gapMark: (default: null) - mark for gaps for renderer to identify.
   *                              Value must not be undefined or zero or positive integers
   *                              Suggested value: -1, null, false or any non numeric strings
   *
   *                            noGap: (default: false) - if true, gaps along with periphery indices are not generated
   *
   * @returns array
   */
  static generateNav(total, current, options) {
    const MIN_WIDTH = 7
    const {min, max, floor, ceil, abs} = Math
    options = _.merge({}, options || {})
    let {width = MIN_WIDTH, center = false, gapMark = null, noGap} = options
    width = parseInt('' + width) || 0

    let pages
    if (_.isArray(total)) {
      pages = total
      total = pages.length
    } else {
      pages = _.range(0, parseInt(total))
    }

    const pageIndices = _.keys(pages).map(i => i * 1)

    if (!total || total <= width) {
      return pageIndices
    }

    const MIN = 0
    const MAX = total - 1
    current = max(MIN, min(current, MAX))
    let length = min(total, width)
    let delta = (length - 1) / 2
    let end = min(total, current + ceil(delta + 1))
    let start = end - length
    if (start < 0) {
      end += abs(start)
      start = 0
    }

    let items = _.slice(pageIndices, start, end)

    if (noGap) {
      return items
    }

    const startGap = current - start
    const canHaveStartGap = startGap > 2
    const endGap = (end - 1) - current
    const canHaveEndGap = endGap > 2
    let lLength
    if (canHaveStartGap) {
      let lGap = 1
      let lSpace = startGap - lGap
      lLength = center ? 1 : floor(lSpace / 2)
      const leftItems = _.concat(_.take(pageIndices, lLength), [gapMark])
      _.merge(items, leftItems)
    }
    let rLength
    if (canHaveEndGap) {
      let rGap = 1
      let rSpace = endGap - rGap
      rLength = center ? 1 : floor(rSpace / 2)
      const rightItems = _.concat(
        new Array(items.length - (rLength + 1)),
        [gapMark],
        _.takeRight(pageIndices, rLength)
      )
      _.merge(items, rightItems)
    }
    return items
  }

  get id() {
    return this.$_id
  }

  get count() {
    return this.pages.length
  }

  get hasPages() {
    return this.count > 1
  }

  get currentPage() {
    return this.page
  }

  get canNext() {
    return this.index < this.maxIndex
  }

  get canPrevious() {
    return this.index > this.minIndex
  }

  init(data, options) {
    this.listeners = fv(this.listeners, {})
    if (!_.isNil(data)) {
      this.$$data = data
    }
    data = this.$$data

    if (_.isArray(data)) {
      data = {pages: data}
    }
    if (!_.isObject(data)) {
      data = (1 * data) || 0
      data = {total: data}
    }

    options = this.options = _.merge({}, data, options)
    this.$_id = fv(this.$_id, options.id, _.uniqueId(`${NAMESPACE}_`))

    const hasPages = _.isArray(options.pages)
    if (hasPages) {
      this.pages = options.pages
      this.step = 1
      this.min = _.head(this.pages)
      this.max = _.last(this.pages)
    } else {
      this.step = options.step || 1
      this.max = options.max || options.total || 1
      this.min = !_.isNil(options.total) ? 1 : (options.min || 1)
      const noPage = this.max - this.min === 0
      this.pages = noPage ? [] : _.range(this.min, this.max + this.step, this.step)
    }
    this.minIndex = 0
    this.maxIndex = Math.max(0, this.pages.length - 1)
    this.index = null

    this.navPagesOpions = fv(this.navPagesOpions, options.navPages, {})
    this.navGapMark = fv(this.navPagesOpions.gapMark, null)
    this.navPages = []

    this.emit(EVENTS.READY, this)

    const initIndex = options.value
    if (!_.isNil(initIndex)) {
      this.index = initIndex
      this.navigateIndex(initIndex)
    }
    return this
  }

  first() {
    this.navigateIndex(this.minIndex)
    this.emit(EVENTS.FIRST, this)
    return this
  }

  last() {
    this.navigateIndex(this.maxIndex)
    this.emit(EVENTS.LAST, this)
    return this
  }

  next(distance = 1, eventName = EVENTS.NEXT) {
    distance = fv(distance, 1)
    const index = this.index + Math.abs(distance)
    if (index > this.maxIndex) {
      if (this.options.rewind) {
        return this.start()
      }
      this.emit(`error:${eventName}`, distance, this)
      return this
    }
    this.navigateIndex(index)
    this.emit(eventName, distance, this)
    return this
  }

  previous(distance = 1, eventName = EVENTS.PREVIOUS) {
    distance = fv(distance, 1)
    const index = this.index - Math.abs(distance)
    if (index < this.minIndex) {
      if (this.options.tailwind) {
        return this.end()
      }
      this.emit(`error:${eventName}`, distance, this)
      return this
    }
    this.navigateIndex(index)
    this.emit(eventName, distance, this)
    return this
  }

  navigateIndex(index) {
    const previousIndex = this.index
    this.index = index
    if (previousIndex === index) {
      return this
    }
    const genNavPages = this.navPagesOpions !== false
    if (genNavPages) {
      this.navPages = Paginator.generateNav(this.pages, index, this.navPagesOpions)
    }

    const previousPage = this.page
    const page = this.page = this.pages[this.index]

    this.emit(EVENTS.INDEX, index, previousIndex, this)
    this.emit(EVENTS.CHANGE_INDEX, index, previousIndex, this)
    this.emit(EVENTS.PAGE, page, previousPage, this)
    this.emit(EVENTS.CHANGE_PAGE, page, previousPage, this)
    if (genNavPages) {
      this.emit(EVENTS.NAV_PAGES, this.navPages, index, page, this)
    }
    return this
  }

  navigate(page) {
    const index = _.indexOf(this.pages, page)
    if (index === -1) {
      this.emit(EVENTS.ERROR_NAVIGATE, page, this)
      return this
    }
    this.navigateIndex(index)
    this.emit(EVENTS.NAVIGATE, page, this)
    return this
  }

  jump(distance) {
    if (distance > 1) {
      return this.next(distance, EVENTS.JUMP)
    }
    if (distance < 1) {
      return this.previous(distance, EVENTS.JUMP)
    }
    return this
  }
}

const listenersMixin = eventsFactory(NAMESPACE, EVENTS)
Object.assign(Paginator.prototype, listenersMixin)

export default Paginator

