/*global _ */
import {
  Validators,
  setValidationRule,
  phoneRuleUSOrCanadaIfNotEmpty,
  phoneRulesUSOrCanada,
  parseValidations,
  parseValidationSetup
} from '../../../../lib/validations'

export {parseValidations, parseValidationSetup}

export const defaultFormFields = {
  productId: '',
  firstName: '',
  lastName: '',
  email: '',
  emailConfirmation: '',
  phone: '',
  phoneConfirmation: '',
  gifteeFirstName: '',
  gifteeLastName: '',
  gifteeEmail: '',
  gifteeEmailConfirmation: '',
  gifteePhone: '',
  gifteePhoneConfirmation: '',
}

export const allValidationsRules = {
  productId: setValidationRule('productId', {
    required: {error: 'Select gift card amount.'}
  }),
  count: setValidationRule('quantity'),
  location_id: setValidationRule('location'),
  custom_amount: setValidationRule('custom_amount'),
  firstName: setValidationRule('firstName'),
  lastName: setValidationRule('lastName'),
  email: setValidationRule('email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  emailConfirmation: setValidationRule('emailConfirmation', {
    confirmed: {error: 'Email is not confirmed.', rule: Validators.sameAs('email')},
  }),
  phone: setValidationRule('phone', phoneRulesUSOrCanada),
  phoneConfirmation: setValidationRule('phoneConfirmation', {
    confirmed: {error: 'Phone is not confirmed.', rule: Validators.sameAs('phone')},
  }),

  gifteeFirstName: setValidationRule('gifteeFirstName', {
    required: {error: 'Recipient\'s first name is required.', rule: Validators.requiredIf('giftee')}
  }),
  gifteeLastName: setValidationRule('gifteeLastName', {
    required: {error: 'Recipient\'s last name is required.', rule: Validators.requiredIf('giftee')}
  }),
  gifteeEmail: setValidationRule('gifteeEmail', {
    required: {error: 'Recipient\'s email is required.', rule: Validators.requiredIf('giftee')},
    email: {error: 'Recipient\'s email is not valid.'},
  }),
  gifteeEmailConfirmation: setValidationRule('gifteeEmailConfirmation', {
    confirmed: {error: 'Email is not confirmed.', rule: Validators.sameAs('gifteeEmail')},
  }),
  gifteePhone: setValidationRule('gifteePhone', {
    required: {error: 'Recipient\'s phone is required.', rule: Validators.requiredIf('giftee')},
    phone: {error: 'Recipient\'s phone is not valid.', rule: phoneRuleUSOrCanadaIfNotEmpty}
  }),
  gifteePhoneConfirmation: setValidationRule('gifteePhoneConfirmation', {
    confirmed: {error: 'Recipient\'s Phone is not confirmed.', rule: Validators.sameAs('gifteePhone')},
  }),
}

export default {defaultFormFields, allValidationsRules, parseValidations, parseValidationSetup}
