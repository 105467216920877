/*global _ */
export const EventBus = window.VueEventBus
export const DataFilter = window.VueDataFilter

import {flatten, unflatten} from 'flat'
import dates, {
  parseDateTime,
  parseRelativeDateTime,
  parseDateTimeFromFormats,
  formatDate,
  formatDateByMethod,
  getNow,
  getNowSeconds,
  getDate,
  getTime,
  getDayDate,
  getDayDateYear,
  getDateTime,
  getDateTimeYear,
  time2Min,
  DateTime,
  Settings as DateTimeSettings,
  Duration,
  Interval
} from './dates'

export {
  parseDateTime,
  parseRelativeDateTime,
  parseDateTimeFromFormats,
  formatDate,
  formatDateByMethod,
  getDate,
  getNow,
  getNowSeconds,
  getTime,
  getDayDate,
  getDayDateYear,
  getDateTime,
  getDateTimeYear,
  time2Min,
  DateTime,
  DateTimeSettings,
  Duration,
  Interval
}

import {getFirstCombination} from './lodash-extras'

import {
  copyToClipboard,
  get$, getv$, set$, title$, style$, html$, attr$, prop$, class$
} from './html'

import utils, {
  templify,
  fv, fo, fn, fs,
  formatPrice, parseNumber, coerceBoolean,
  getQS,
  states, countries,
  registerPromise,
  setTimeoutPromise, // @deprecated: use setTimeoutAsync instead
  setTimeoutAsync,
} from './utils'

export {
  templify,
  fv, fo, fn, fs,
  formatPrice, parseNumber, coerceBoolean,
  get$, getv$, set$, title$, style$, html$, attr$, prop$, class$,
  getQS,
  states, countries,
  registerPromise,
  setTimeoutPromise,  // @deprecated: use setTimeoutAsync instead
  setTimeoutAsync,
  getFirstCombination,
  copyToClipboard,
  flatten, unflatten
}

import {loadSettings} from './settings'
export {loadSettings}
import {trans as loadTranslations} from './lang'
export {loadTranslations}

import {cache} from './cache'
export {cache}

import {autocomplete} from './autocomplete'
export {autocomplete}

const formatters = {
  _,
  parseDateTime,
  parseRelativeDateTime,
  parseDateTimeFromFormats,
  formatDate,
  formatDateByMethod,
  getNow,
  getNowSeconds,
  getDate,
  getTime,
  getDayDate,
  getDayDateYear,
  getDateTime,
  getDateTimeYear,
  time2Min,
  templify,
  DateTime,
  DateTimeSettings,
  Duration,
  Interval,
  fv, fo, fn, fs,
  formatPrice, parseNumber, coerceBoolean,
  get$, getv$, set$, title$, style$, html$, attr$, prop$, class$,
  getQS,
  states, countries,
  registerPromise,
  setTimeoutPromise,  // @deprecated: use setTimeoutAsync instead
  setTimeoutAsync,
  getFirstCombination,
  copyToClipboard,
  flatten,
  unflatten,
  loadSettings,
  loadTranslations,
  cache,
  autocomplete
}

export const firstString = formatters.firstString = (...args) => {
  for(const v of args) {
    if (!_.isNil(v) && !_.isNaN(v) && !_.isObject(v) && v !== '') {
      return v
    }
  }
  return ''
}
// parse a string value
//   - To object when string is `{..}` (not when  `{{}}` though)
//   - To array when `[...]`
//   - To true when `true`
//   - To false when `false`
// Does not parse if not a string
export const parseJSONString = formatters.parseJSONString = (value) => {
  if (_.isString(value)) {
    if (
      value === 'true' ||
      value === 'false' ||
      (_.startsWith(value, '[') && _.endsWith(value, ']')) ||
      (_.startsWith(value, '{') && !_.startsWith(value, '{{') &&
        _.endsWith(value, '}') && !_.endsWith(value, '}}'))
    ) {
      return JSON.parse(value)
    }
  }
  return value
}

// apply template parsing from data on a string or array or objects
export const templiParse = formatters.templiParse = function (v, data, defaultValue) {
  if (_.isObject(v)) {
    return map(v, d => templiParse.call(this, d, data))
  }
  if (_.isString(v)) {
    // {[...]}
    if (/^{\[[^\]]+?\]}$/.test(v)) {
      const key = v.replace(/^{\[|\]}$/g, '')
      return _.get(data, key, _.get(this, key, defaultValue))
    }
  }
  return templify.call(this, v, data || {})
}

// mapValues + map
export const map = formatters.map = (data, callback) => {
  if (_.isEmpty(data) || _.isFunction(data) || !(_.isObject(data) || _.isArray(data))) {
    return data
  }
  if (_.isArray(data)) {
    return _.map(data, callback)
  }
  return _.mapValues(data, callback)
}

// omitBy + filter
export const removeBy = formatters.removeBy = (data, callback) => {
  if (_.isArray(data)) {
    return _.filter(data, (i, k, o) => !callback(i, k, o))
  }
  return _.omitBy(data, callback)
}

// getters
export const get = formatters.get = _.get
export const compact = formatters.compact = _.compact
export const first = formatters.first = (v) => _.head(_.values(v))
export const firstKey = formatters.firstKey = (v) => _.head(_.keys(v))
export const last = formatters.last =  (v) => _.last(_.values(v))
export const lastKey = formatters.lastKey =  (v) => _.last(_.keys(v))
export const size = formatters.size = _.size
export const nils = formatters.nils = v => _.pickBy(v, nil)
export const notNils = formatters.notNils = v => _.omitBy(v, nil)
export const findByKey = formatters.findByKey = (key, ...items) => _.find(items, i => {
  return _.get(i, key)
})

export const getFirstByKeys = formatters.getFirstByKeys = (item, ...keys) => {
  for(let key of keys) {
    const value = _.get(item, key, undefined)
    if (!_.isUndefined(value)) {
      return value
    }
  }
}

export const getFirst = formatters.getFirst = (key, ...items) => {
  const obj = findByKey(key, ...items)
  return _.get(obj, key)
}

export const getFirstByKeyValue = formatters.getFirstByKeyValue = (items, key, value) => {
  return _.find(items, (obj) => {
    return _.get(obj, key) == value
  })
}
export const filterByKeyValue = formatters.filterByKeyValue = (items, key, value) => {
  return _.filter(_.values(items), (obj) => {
    return _.get(obj, key) == value
  })
}

// specials
// pickBy similar to `key` + * wildcard
export const pickByKeyPrefix = formatters.pickByKeyPrefix = (v, prefix) => _.pickBy(v, (val, k) => _.startsWith(k, prefix))

// replace a part of the key
export const mapKeysReplace = formatters.mapKeysReplace = (v, needle, replacer = '') => _.mapKeys(v, (val, k) => k.replace(needle, replacer))

export const pickKeyPrefixMapKeysReplace = formatters.pickKeyPrefixMapKeysReplace = (v, prefix, replacer = '') => {
  return mapKeysReplace(pickByKeyPrefix(v, prefix), prefix, replacer)
}

//
export const groupByKeyPrefix = formatters.groupByKeyPrefix = (v, prefix, delimiter = '_', valueMapper, returnEmptyOnEmpty) => {
  let values = pickKeyPrefixMapKeysReplace(v, prefix + delimiter)
  if (returnEmptyOnEmpty && empty(values)) {
    return {}
  }
  if (_.isFunction(valueMapper)) {
    values = map(values, valueMapper)
  }
  return {[prefix]: values}
}

/***
 * Iterates through array or object containing similar set of values like database records
 * picks only one value per item based on key (property path with . separator)
 *
 *  e.g.,
 *  PLUCK AS ARRAY
 *  pluck([{name: `x`, title: 'xx'}, {name: `y`, title: 'yy'}], 'name')
 *    results in => [`x`, `y`]
 *  pluck([{name: `x`, title: 'xx'}, {name: `y`, title: 'yy'}], false, 'name')
 *    results in => [`x`, `y`]
 *
 *  pluck([{name: `x`, prop: {title: 'XX'}}, {name: `y`, prop: {title: 'YY'}}], 'name', 'prop')
 *    => {x: {title: 'XX'}, y: {title: 'YY'}}
 *
 *  pluck([{name: `x`, prop: {title: 'XX'}}, {name: `y`, prop: {title: 'YY'}}], 'prop.title')
 *    results in => [`XX`, `YY`]
 *
 *  PLUCK AS OBJECT WITH KEYS
 *  pluck([{name: `x`, title: 'xx'}, {name: `y`, title: 'yy'}], 'name', 'title')
 *    => {x: 'xx', y: 'yy'}
 *
 *  pluck([{name: `x`, prop: {title: 'XX'}}, {name: `y`, prop: {title: 'YY'}}], 'name', 'prop.title')
 *    => {x: 'XX', y: 'YY'}
 *
 *  pluck([{name: `x`, prop: {title: 'XX'}}, {name: `y`, prop: {title: 'YY'}}], 'prop.title', 'name')
 *    => {XX: 'x', YY: 'y'}
 *
 * FIELD KEY MAPPING IF plucked values are objects
 *    pluck([{name: `x`, prop: {title: 'XX'}}, {name: `y`, prop: {title: 'YY'}}], 'name', 'prop', {title: 'TITLE'})
 *        => [x: {TITLE: 'XX'}, y: {TITLE: 'YY'}]
 *
 * @param values array|object
 * @param key string|boolean|array|function key mapping criteria. false or nil returns indexed array
 * @param paths array|string
 * @param fieldMap object Key replacer
 *
 *
 */
export const pluck = formatters.pluck = (values, key, paths, fieldMap) =>  {
  if (!fieldMap && (nil(key) || (key === false && !paths))) {
    return values
  }
  if (!paths) {
    paths = key
    key = false
  }
  const keyedValues = key === false || nil(key) ? _.values(values) : _.mapKeys(values, key)
  const pluckedValues = map(keyedValues, v => {
    return _.isArray(paths) ? _.pick(v, paths) : (_.isBoolean(paths) || nil(paths) ? v : _.get(v, paths))
  })
  if (fieldMap) {
    return map(pluckedValues, v => {
      if (_.isObject(v)) {
        const newV = _.omit(v, _.keys(fieldMap))
        _.forOwn(fieldMap, (replaceKey, findKey) => {
          if (_.has(v, findKey)) {
            _.set(newV, replaceKey, _.get(v, findKey))
          }
        })
        return newV
      }
      return v
    })
  }
  return pluckedValues
}

/**
 * enhance _.pick.
 * Retains original _.pick if `paths` is string or array
 * Use _.pickBy if `paths` is function
 *
 * if `paths` is object:
 *    -  first it picks by _.keys(items)
 *    - then creates a new object whose keys are from _.values(items)
 *    - retains the original key if the value is not string
 */
export const pick = formatters.pick = (values, paths) =>  {
  if (_.isString(paths) || _.isArray(paths)) {
    return _.pick(values, paths)
  }
  if (_.isFunction(paths)) {
    return _.pickBy(values, paths)
  }
  if (!_.isObject(paths)) {
    paths = _.mapValues(paths, (v, k) => _.isString(v) ? v : k)
    const results = _.pick(values, _.keys(paths))
    const finalValues = {}
    _.forOwn(paths, (finalKey, searchKey) => {
      if (_.has(results, searchKey)) {
        _.set(finalValues, finalKey, _.get(results, searchKey))
      }
    })
    return finalValues
  }
  return {}
}

// accepts 2 objects
// shallow compares values, checks if keys from oldObj are removed from newObj
// and returns an object
// each value of is an array containing [value from OldObj, value from newObj]
// keys for equal values skipped
export const getChanges = formatters.getChanges = (oldObj, newObj) =>  {
  const removed = _.difference(_.keys(oldObj), _.keys(newObj))
  _.forOwn(removed, (key) => {
    newObj[key] = null
  })

  return _.reduce(newObj, (changes, newValue, key) => {
    let oldValue = oldObj[key]
    let hasChanged = newValue !== oldValue
    if (_.isObject(newValue)) {
      hasChanged = JSON.stringify([newValue]) !== JSON.stringify([oldValue])
    }
    if (hasChanged) {
      changes[key] = [mayToBoolean(oldValue), mayToBoolean(newValue)]
    }
    return changes
  }, {})
}

// compares two values and returns true of different
// @todo: replace using !_.isEqual
export const areDifferent = formatters.areDifferent = (value, otherValue) => {
  if (!_.isObject(value) && !_.isObject(otherValue)) {
    return value !== otherValue
  }
  return JSON.stringify([value]) !== JSON.stringify([otherValue])
}

export const getLocations = formatters.getLocations = async () => await invokeService('locations.listBy.id')
export const getLocationsSync = formatters.getLocationsSync = () => {
  const locations = _.get(window, 'addiesaas.locations')
  return _.mapValues(locations, location => _.isString(location) ? location : _.get(location, 'display_name'))
}
export const getLocationsFull = formatters.getLocationsFull = async () => await invokeService('locations.list')
export const getLocationsFullSync = formatters.getLocationsFullSync = () => _.get(window, 'addiesaas.locations')

export const getLocationName = formatters.getLocationName = async (code) => ((await getLocations()) || {})[code]
export const getLocationNameSync = formatters.getLocationNameSync = code => (getLocationsSync() || {})[code]

export const getUSStates = formatters.getUSStates = () => pluck(states, 'abbreviation', 'name')
export const getCountryStates = formatters.getCountryStates = (country) => {
  return !country || country === 'US' ? getUSStates() : {}
}
export const getUSStateName = formatters.getUSStateName = (code) => getUSStates()[code] || code
export const getStateName = formatters.getStateName = (code) => getUSStateName(code)
export const getCountries = formatters.getCountries = () => pluck(countries, 'code', 'name')
export const getCountryName = formatters.getCountryName = (code) => getCountries()[code] || code

export const genders = formatters.genders = fo(loadTranslations('shared.genders', false), {
  'na': 'NA',
  'm': 'Male',
  'f': 'Female',
  'o': 'Other',
  '2slgbtqi+': '2SLGBTQI+',
})

export const getGenders = formatters.getGenders = () => genders
export const getGenderName = formatters.getGenderName = (code) => genders[code] || code

export const getFamilyRelations = formatters.getFamilyRelations = () => _.get(
  window.addiesaas,
  'user_data.family_member_relations'
)

// service
export const invokeService = formatters.invokeService = async (...args) => {
  const [data] = await registerPromise(...args)
  return data
}

// convert // calculate
export const join = formatters.join = (a, s = ' ') => _.join(a, s)
export const split = formatters.split = _.split
export const add = formatters.add = _.add // 2 params
export const sum = formatters.sum = _.sum //array
export const nsum = formatters.nsum = (args) => sum(map(args, a => (a * 1 || 0))) //array
export const subtract = formatters.subtract = _.subtract //2 params
export const multiply = formatters.multiply = _.multiply
export const divide = formatters.divide = _.divide
export const max = formatters.max = _.max
export const mean = formatters.mean = _.mean
export const min = formatters.min = _.min
export const nth = formatters.nth = _.nth

export const floor = formatters.floor = _.floor
export const ceil = formatters.ceil = _.ceil
export const pad = formatters.pad = _.pad
export const padEnd = formatters.padEnd = _.padEnd
export const padStart = formatters.padStart = _.padStart
export const parseInt = formatters.parseInt = _.parseInt
export const random = formatters.random = _.random
export const range = formatters.range = _.range
export const reverse = formatters.reverse = _.reverse
export const repeat = formatters.repeat = _.repeat
export const replace = formatters.replace = _.replace
export const round = formatters.round = _.round

export const trim = formatters.trim = _.trim
export const trimStart = formatters.trimStart = _.trimStart
export const trimEnd = formatters.trimEnd = _.trimEnd
export const truncate = formatters.truncate = _.truncate
export const upperFirst = formatters.upperFirst = _.upperFirst
export const kebabCase = formatters.kebabCase = _.kebabCase
export const startCase = formatters.startCase = _.startCase
export const snakeCase = formatters.snakeCase = _.snakeCase
export const unescape = formatters.unescape = _.unescape
export const escape = formatters.escape = _.escape
export const toBlank = formatters.toBlank = () => ''

export const toInteger = formatters.toInteger = _.toInteger
export const toNumber = formatters.toNumber = _.toNumber
export const number = formatters.number = _.toFinite
export const toString = formatters.toString = _.toString
export const toBoolean = formatters.toBoolean = coerceBoolean
export const mayToBoolean = formatters.mayToBoolean = (v) => coerceBoolean(v, true)

// logical
export const isArrayJSONObject = formatters.isArrayJSONObject = v => !_.isArray(v) && _.isObject(v) && _.has(v, '0')
// is Empty?
export const empty = formatters.empty = _.isEmpty
// is recursively empty?
export const emptyRecursive = formatters.emptyRecursive = (def) => {
  if (!_.isObject(def)) {
    return nil(def)
  }
  return !_.some(def, v => _.isObject(v) ? !emptyRecursive(v) : !nil(v))
}
export const isBlank = formatters.isBlank = v => v === ''
export const emptyValue = formatters.emptyValue = v => nil(v)
export const notEmpty = formatters.notEmpty = v => !_.isEmpty(v)
export const notEmptyValue = formatters.notEmptyValue = v => !emptyValue(v)
export const every = formatters.every = _.every
export const some = formatters.some = _.some
export const oneOf = formatters.oneOf = (value, list = []) => some(list, i => i === value)
export const noneOf = formatters.noneOf = (value, list) => !oneOf(value, list)

export const truthy = formatters.truthy = v => !!mayToBoolean(v)
export const truthyLoose = formatters.truthyLoose = v => !!toBoolean(v)
export const falsy = formatters.falsy = v => !truthy(v)
export const falsyLoose = formatters.falsyLoose = v => !truthyLoose(v)

export const yes = formatters.yes = v => truthy(v) && 'yes' || null
export const no = formatters.no = v => falsy(v) && 'no' || null
export const yesno = formatters.yesno = v => yes(v) || no(v)

export const nan = formatters.nan = _.isNaN
export const nil = formatters.nil = v => _.isNil(v) || v === '' || nan(v)
export const someNil = formatters.someNil = v => some(v, nil)
export const everyNil = formatters.everyNil = v => every(v, nil)
export const notNil = formatters.notNil = v => !nil(v)
export const isZero = formatters.isZero = v => ((1 * v) || 0) === 0
export const isPositiveNumber = formatters.isPositiveNumber = v => v > 0
export const isNegativeNumber = formatters.isNegativeNumber = v => v < 0

export const equals = formatters.equals = (v, otherV) => v == otherV
export const equalsStrict = formatters.equalsStrict = (v, otherV) => v === otherV
export const notEquals = formatters.notEquals = (v, otherV) => v != otherV
export const notEqualsStrict = formatters.notEqualsStrict = (v, otherV) => v !== otherV
export const greater = formatters.greater = (v, otherV) => v > otherV
export const greaterOrEquals = formatters.greaterOrEquals = (v, otherV) => v >= otherV
export const less = formatters.less = (v, otherV) => v < otherV
export const lessOrEquals = formatters.lessOrEquals = (v, otherV) => v !== otherV
export const endsWith = formatters.endsWith = _.endsWith
export const startsWith = formatters.startsWith = _.startsWith
export const has = formatters.has = _.has
export const isMatch = formatters.isMatch = _.isMatch
export const findKey = formatters.findKey = _.findKey

export const hasSome = formatters.hasSome = (h, ns) => some(ns, n => has(h, n))
export const hasEvery = formatters.hasEvery = (h, ns) => every(ns, n => has(h, n))
export const contains = formatters.contains = (h, n) => ((fv(h, '')+'').toLowerCase()).includes((fv(n, '')+'').toLowerCase())
export const notContains = formatters.notContains = (h, n) => !contains(h, n)

// value formatting and template parsing
const that = {
  $$templify: templify
}

export const getFormatter = formatters.getFormatter = function (format, ...sources) {
  if (_.isFunction(format)) {
    return format
  }
  const me = this || that
  return getFirst(
    format,
    ...[
      ...(sources || []),
      me.context,
      me,
      formatters,
      _,
      window
    ]
  )
}

export const baseFormat = formatters.baseFormat = function (value, template, params, allowParseFallback, data, parseParams) {
  const me = this || that
  params = params || []
  const formatter = getFormatter.call(me, template)
  if (parseParams) {
    params = map(params, param => templiParse.call(me, param, data))
  }
  if (_.isFunction(formatter)) {
    value = formatter.call(me, value, ..._.castArray(params))
    return value
  } else {
    // if value is not formatted - the format may be a template to be templified
    if (allowParseFallback) {
      data = data || {}
      value = (me.$$templify || templify)(template, {...data, value})
    }
  }
  return value
}

export const format = function (value, defaultValue, config = {}, data = {}) {
  let {
    format: template = null,
    formatParams = [],
    params = formatParams,
    parseParams,

    formatEach,
    formatEachParams,
  } = config

  const me = this || that
  const allData = {...data, value}

  allData.value = value = (me.$$templify || templify)(value, allData)

  // formatEach is applied to multiple values
  if (formatEach) {
    const eachConfig = {
      format: formatEach,
      params: formatEachParams || params,
      parseParams
    }
    if (_.isObject(value)) {
      value = map(value, (val) => {
        return format.call(me, val, defaultValue, eachConfig, data)
      })
    }
  }

  if (!template) {
    return fv(value, defaultValue)
  }

  // apply multiple formatting function using array
  // the array must be a multi-dimensional array
  // item[0] = ["formatFunctionName", [...params as array]]
  if (_.isArray(template)) {
    return _.reduce(template, (formattedValue, formatItem) => {
      const [formatTemplate, formatterParams, fallback] = formatItem || []
      return baseFormat.call(me, formattedValue, formatTemplate, formatterParams, fallback, data, parseParams)
    }, value)
  }

  return baseFormat.call(me, value, template, params || [], true, data, parseParams)
}

export const groupFormat = function (values, defaultValue, config = {}, data = {}) {
  const {
    preGroupFormat = null,
    preGroupFormatParams: pgParams = [],
    parsePreGroupParams,

    group = null,
    groupParams = [],
    parseGroupParams,

    format: gFormat = null,
    formatParams = [],
    params: gFParams = formatParams,
    parseParams,

    groupFormat = gFormat,
    groupFormatParams = gFParams,

  } = config

  if (!group) {
    return fv(values, defaultValue)
  }
  const me = this || that
  if (preGroupFormat) {
    values = format.call(me, values, _.castArray(defaultValue), {formatEach: preGroupFormat, params: pgParams, parseParams: parsePreGroupParams}, data)
  }

  let value = format.call(me, values, defaultValue, {format: group, params: groupParams, parseParams: parseGroupParams}, data)

  value = format.call(me, value, defaultValue, {format: groupFormat, params: groupFormatParams, parseParams}, data)
  return value
}

export const valueFormat = formatters.valueFormat = formatters.groupFormat =
  formatters.format = function (value, defaultValue, config = {}, data = {}) {
  const me = this || that
  value = format.call(me, value, defaultValue, {}, data)

  if (config.group) {
    value = groupFormat.call(me, _.castArray(value), defaultValue, config, data)
  }
  const formatterConfig = _.pick(
    config,
    ['format', 'formatParams', 'params', 'formatEach', 'formatEachParams', 'parseParams']
  )
  value = format.call(me, value, defaultValue, formatterConfig, data)
  return fv(value, defaultValue)
}

export const formatPath = formatters.formatPath = function (path, data, tags = ['{{', '}}']) {
  if (_.isObject(path)) {
    return map(path, p => formatPath.call(this, p, data, tags))
  }
  if (!/{{[^}]+?}}/.test(path)) {
    path = `{{${path}}}`
  }
  return templify.call(this, path, data, tags)
}

export const formatNumber = formatters.formatNumber = function (value, format) {
  const i18n = this && this.$i18n || window.addiesaas.i18n || {}
  if (i18n && format) {
    return i18n.n(value, format)
  }
  return value
}

export const currency = formatters.currency = function (value, format = 'currency') {
  try {
    return formatNumber.call(this, value, format)
  } catch (e) {
    const symbol = getCurrencySymbol.call(this)
    return symbol + _.round(value, 2)
  }
}

export const currencyRounded = formatters.currencyRounded = function (value, format = 'currencyRounded') {
  try {
    return currency.call(this, value, format)
  } catch (e) {
    const symbol = getCurrencySymbol.call(this)
    return symbol + _.round(value, 0)
  }
}
export const getCurrencySymbol = formatters.getCurrencySymbol = function () {
  const symbolPath = 'currency.symbol'
  const i18n = this && this.$i18n || window.addiesaas.i18n || {}
  const hasSymbol = i18n.te(symbolPath)
  return hasSymbol ? i18n.t(symbolPath) : '$'
}

export const formatDateTime = formatters.formatDateTime = function (value, format) {
  const i18n = this && this.$i18n || window.addiesaas.i18n || {}
  if (!(value instanceof Date)) {
    let parsedDate = parseDateTime(value)
    if (!parsedDate.isValid) {
      value = parsedDate.toJSDate()
    }
  }
  try {
    if (i18n && format) {
      return i18n.d(value, format)
    }
  } catch (e) {
    //
  }
  return value
}

export const shortDate = formatters.shortDate = function (value) {
  return formatDateTime.call(this, value, 'short')
}

export const longDate = formatters.longDate = function (value) {
  return formatDateTime.call(this, value, 'long')
}

export const toDBDate = formatters.toDBDate = function (value) {
  return formatDate.call(this, value, 'yyyy-MM-dd')
}

export const toDBTime = formatters.toDBTime = function (value) {
  return getTime.call(this, value, 'HH:mm:ss')
}

export const toDBDateTime = formatters.toDBDateTime = function (value) {
  if (_.isString(value)) {
    value = trim(value)
    const parsed = parseDateTime(value)
    if (parsed && parsed.isValid) {
      value = parsed
    } else {
      value = parseDateTimeFromFormats(trim(value), dates.formats.DATETIME)
    }
  }
  return formatDate.call(this, value, 'yyyy-MM-dd HH:mm:ss')
}

export const seconds2Hours = formatters.seconds2Hours = function (value) {
  const seconds = floor(value % 60)
  const minutes = floor(value / 60) % 60
  const hours = floor(value / 3600)
  const formatted = [padStart(hours, 2, 0), padStart(minutes, 2, 0), padStart(seconds, 2, 0)]
  return formatted.join(':')
}

export const isPast = formatters.isPast = function (value) {
  const date = value && parseDateTime(value)
  if (date && date.isValid) {
    return date <= getNow()
  }
  return false
}

export const isFuture = formatters.isFuture = function (value) {
  const date = value && parseDateTime(value)
  if (date && date.isValid) {
    return date > getNow()
  }
  return false
}

export const mask = formatters.mask = (value, template, options) => {
  options = options || {}
  if (options.right) {
    return maskRight(value, template, options)
  }
  if (options.left) {
    return maskLeft(value, template, options)
  }
  if (!template) {
    return value
  }
  value = '' + (fv(value, ''))
  return _.mergeWith([], value, template, function (a,b) {
    return b == 0 ? a : b;
  }).join('')
}

export const maskRight = formatters.maskRight = (value, template, options) => {
  options = _.omit(options || {}, ['left'])
  if (!template) {
    return value
  }
  const clip = options.clip !== false
  value = '' + (fv(value, ''))
  if (clip) {
    value = value.substring(0, template.length)
  } else {
    template = _.padEnd(template, value.length, template[template.length -1])
  }
  return mask(value, template, options)
}

export const maskLeft = formatters.maskLeft = (value, template, options) => {
  options = _.omit(options || {}, ['right'])
  if (!template) {
    return value
  }

  value = ('' + (fv(value, ''))).split('').reverse().join('')
  template = template.split('').reverse().join('')
  const masked = maskRight(value, template, options)
  return masked.split('').reverse().join('')
}

export const applyDataFilters = formatters.applyDataFilters = (value, filterName, ...params) => {
  DataFilter.apply(filterName, value, ...params)
  return value
}

export const emitGlobalEvent = formatters.emitGlobalEvent = (value, eventName, ...params) => {
  EventBus.$emit(eventName, value, ...params)
  return value
}

formatters.log = (...args) => console.log(...args)
formatters.debugLog = debugLog

const ad = window.addiesaas = (window.addiesaas || {})
const adfn = ad.$fn || (ad.$fn = {})
adfn.formatters = formatters
adfn.utils = utils

export default formatters
