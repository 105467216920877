import VueScrollTo from 'vue-scrollto'

export default {
  methods: {
    scrollToTop(duration, options) {
      this.scrollTo('.rocket-effect-container', duration, options)
    },
    lazyScrollToTop(duration, options) {
      this.lazyScrollTo('.rocket-effect-container', duration, options)
    },
    scrollTo(element, duration, options) {
      options = options || this.$$s && this.$$s('scroll') || {}
      const offset = this.$$s && this.$$s('scroll.offset')
        if (!_.isNil(offset)) {
          options.offset = offset
        }
      VueScrollTo.scrollTo(element, duration, options)
    },
    lazyScrollTo(element, duration, options) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.scrollTo(element, duration, options)
        })
      })
    },
    scrollToBodyTop(duration, options) {
      this.scrollTo('body', duration, options)
    },
  },

}

