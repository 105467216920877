import ModalMixin from '../../../mixins/modal'

export default {
  name: 're-info',
  type: 'info',
  mixins: [ModalMixin],
  props: {
    headerText: {
      type: [String],
      default: null
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: 'image'
    },
    footerText: {
      type: [String],
      default: null
    },
    buttonClass: {
      type: [String, Array, Object],
      default: null
    },
  }
}
