export default {
  name: 're-tab-item',

  props: {
    title: {required: true},
    name: {default: null},
    hash: {default: null},
    active: {default: false, type: Boolean},
    visible: {default: true, type: Boolean},
    disabled: {default: false, type: Boolean},
    hideTitle: {default: false, type: Boolean}
  },

  data() {
    return {
    }
  },
  computed: {
    activeTab() {
      return this.$parent.activeTab
    },
    isActive() {
      return this.activeTab === this.tabName
    },
    amIActive() {
      return this.isActive
    },
    tabName() {
      return this.name || this.title.toLowerCase().replace(/\W/g, '').replace(/\s/g, '_')
    },
    tabHash() {
      return '#' + this.tabName
    },
    isDisabled() {
      return this.disabled
    },
    isTabItem() {
      return true
    },
    isVisible() {
      return this.visible
    }
  }

}
