import BaseMixin from './base'
import ComponentBaseMixin from './component-base'

export default {
  data() {
    return {
      propDefs: {}
    }
  },
  mixins: [BaseMixin, ComponentBaseMixin]
}

