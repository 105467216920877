import {Vue} from '~/addiesaas'
import ReCorePageShopProductEditor from './product/editor'
Vue.component(ReCorePageShopProductEditor.name, ReCorePageShopProductEditor)

import ReCorePageShopProduct from './product'
Vue.component(ReCorePageShopProduct.name, ReCorePageShopProduct)

import ReCorePageShopProducts from './products'
Vue.component(ReCorePageShopProducts.name, ReCorePageShopProducts)

import ReCorePageShopProductsFilters from './products/filters'
Vue.component(ReCorePageShopProductsFilters.name, ReCorePageShopProductsFilters)
import ReCorePageShopProductsSort from './products/sort'
Vue.component(ReCorePageShopProductsSort.name, ReCorePageShopProductsSort)
import ReCorePageShopProductsPagination from './products/pagination'
Vue.component(ReCorePageShopProductsPagination.name, ReCorePageShopProductsPagination)
import ReCorePageShopProductsPaginationStatus from './products/pagination/status'
Vue.component(ReCorePageShopProductsPaginationStatus.name, ReCorePageShopProductsPaginationStatus)

import ReCorePageShopSubscription from './subscription'
Vue.component(ReCorePageShopSubscription.name, ReCorePageShopSubscription)

import ReCorePageShopCart from './cart'
Vue.component(ReCorePageShopCart.name, ReCorePageShopCart)

import ReCorePageShopBuyGiftCard from './giftcard/buy'
Vue.component(ReCorePageShopBuyGiftCard.name, ReCorePageShopBuyGiftCard)
import ReCorePageShopBuyGiftCardExtended from './giftcard-extended/buy'
Vue.component(ReCorePageShopBuyGiftCardExtended.name, ReCorePageShopBuyGiftCardExtended)

import ReCorePageShopBuyPlayCard from './playcard/buy'
Vue.component(ReCorePageShopBuyPlayCard.name, ReCorePageShopBuyPlayCard)

import ReCorePageReloadACard from './playcard/reload'
Vue.component(ReCorePageReloadACard.name, ReCorePageReloadACard)

export default {
  ReCorePageShopProductEditor,
  ReCorePageShopProduct,
  ReCorePageShopSubscription,

  ReCorePageShopProducts,
  ReCorePageShopProductsFilters,
  ReCorePageShopProductsSort,
  ReCorePageShopProductsPagination,
  ReCorePageShopProductsPaginationStatus,

  ReCorePageShopCart,
  ReCorePageShopBuyGiftCard,
  ReCorePageShopBuyPlayCard,
  ReCorePageReloadACard,
}
