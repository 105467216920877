/*global _, addiesaas, $, debugLog */

import {operators as allOperators, parser as evaluate} from '../../../../lib/conditionals'
import formatters, {emptyRecursive} from '../../../../lib/formatters'

// typeComponents define which type of input control to show to enter values in this interface
// based on the Type of field - Type of field is defnied in each vue component's data 's inputType or inputTypeDef
// default is text (re-input)
// For example, for dropdowns (select type) the choice of values wil be shown using re-select
const typeComponents = {
  select: 're-select',
  checkbox: 're-checkbox',
  checkboxes: 're-select',
  radios: 're-select',
  textarea: 're-textarea',
}

// `types` in operator definition helps determine which operators will be visible in the dropdown for which type of field
// The value is either a string or an array of strings or false
// if types is set to false, the operator will not be shown anywhere
// // some fields have subtypes which needs to be listed as type.subType
const numberTypes = ['text.number', 'input.number', 'text.range', 'input.range']

// `excludeTypes` in operator definition is opposite of types. It may be required to allow exclude operator from some types

/**
 * The operator keys are names of conditional functions defined in formatters/lodash lib
 * Custom functions or function names from formatters/lodash can be added using `fn` property of the object
 */
export const operators = _.merge({}, allOperators, {
  'empty': {
    valueClasses: ['hidden-column'],
    required: false,
    excludeTypes: ['checkbox'],
  },
  'notEmpty': {
    valueClasses: ['hidden-column'],
    required: false,
    excludeTypes: ['checkbox'],
  },
  'equals': {
    typeComponents,
    excludeTypes: ['checkboxes', 'select', 'radios'],
  },
  'notEquals': {
    typeComponents,
    excludeTypes: ['checkboxes', 'select', 'radios'],
  },
  'contains': {
    excludeTypes: ['checkbox'],
  },
  'notContains': {
    excludeTypes: ['checkbox'],
  },
  'greater': {
    types: numberTypes
  },
  'less': {
    types: numberTypes
  },
  'greaterOrEquals': {
    types: numberTypes
  },
  'lessOrEquals': {
    types: numberTypes
  },
  'oneOf': {
    types: ['select', 'checkboxes', 'radios'],
    component: 're-checkboxes'
  },
  'noneOf': {
    types: ['select', 'checkboxes', 'radios'],
    component: 're-checkboxes'
  },

})

//@todo: Use {parser as evaluate} from '../../../../lib/conditionals'
export const parser = function (def, data, defaultValue = false) {
  if (emptyRecursive(def)) {
    return defaultValue
  }
  const {parsed} = _.transform(def, (result, [fieldName, operator, conditionValue]) => {
    if (fieldName && operator) {
      const value = data[fieldName]
      const operatorDef = operators[operator]
      if (operatorDef) {
        const params = [value]
        if (!operatorDef.noValue) {
          params.push(conditionValue)
        }

        let fn = operatorDef.fn || operator
        if (!_.isFunction(fn)) {
          fn = _.get(formatters, fn, _.get(_, fn))
        }

        if (_.isFunction(fn)) {
          result.parsed = fn.apply(null, params)
        }
      }
    }

    if (result) {
      return false
    }
  }, {parsed: 0})

  return parsed === 0 ? defaultValue : parsed
}

export default parser
