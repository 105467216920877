const EventBus = window.VueEventBus
import ProductsMixins from '../../shop/products'
import ProductMixins from '../../shop/product'

export default {
  mixins: [
    ProductsMixins,
    ProductMixins,
  ],

  data() {
    return {
      productItem: null
    }
  },
  created() {
    this.productQuantity = _.get(this.itemPayload, 'count')
    this.productItem = this.getCartItemProduct()
  },
  watch: {
    productQuantity: _.debounce(async function (newVal, oldVal) {
        await this.updateItemQuantity(newVal)
      }, 250),
  },
  computed: {
    itemPayload() {
      return _.get(this.item || {}, 'payload', {})
    },
    itemOptions() {
      return _.get(this.itemPayload, 'options', {})
    },
    itemAttributes() {
      return _.get(this.item || {}, 'attributes', {})
    },
    canChangeCartQuantity() {
      const item = this.item
      const type = item.type
      const product = this.productItem
      const category = this.getProductCategory(product)
      const canChange = this.$$fs(
        _.get(item, 'cartCanChangeQuantity'),
        _.get(product, 'cartCanChangeQuantity'),
        _.get(product, 'custom.cartCanChangeQuantity'),
        this.$$s(`cartCanChangeQuantity`),
        this.$$s(`product.${type}.cartCanChangeQuantity`),
        this.$$s(`product.types.${type}.cartCanChangeQuantity`),
        this.$$s(`product.categories.${category}.cartCanChangeQuantity`),
        this.$$s(`product.types.${type}.categories.${category}.cartCanChangeQuantity`)
      )
      return !!(1 * canChange)
    },
    showDeleteButton() {
      return this.item.deletable === true
    },
    showEditButton() {
      return this.item.editable === true
    },
    disabledDeleteButton() {
      return this.item.deletable === false
    },
    productImageSrc() {
      const item = this.productItem
      return this.getProductImage(item)
    },
    productTitle() {
      return this.item.title || _.get(this.productItem, 'title', '')
    },
    cartItemSubTitle() {
      return _.get(this.productItem, 'subTitle', '')
    },
    cartItemNotes() {
      return _.get(this.productItem, 'notes', '')
    },
    cartItemInfo() {
      return _.get(this.itemAttributes, 'info', [])
    },
  },
  methods: {
    getCartItemProduct() {
      return this.itemAttributes.product || {}
    },
    async updateItemQuantity(quantity, product, item) {
      item = item || this.item
      product = this.productItem || product
      const updatedItem = _.pick(item, ['payload.count', 'total', 'id'])
      _.set(updatedItem, 'payload.count', quantity)
      updatedItem.total = quantity * product.price
      const success = await this.updateCartItem(updatedItem)
      if (success) {
        this.validateRepeatCount = 0
        await this.validateCart()
      }
    },
    itemDelete($event) {
      const item = this.item
      this.cartItemButtonClicked({
        $event,
        actionName: 'delete',
        id: item.id,
        item,
        cartItems: this.currentCartItems,
        button: {}
      })
      EventBus.$emit('cart-item-deleted', item, this, this.$parent)
      EventBus.$emit('cart-item-deleted-' + item.type, item, this, this.$parent)
    },
    itemEdit($event) {
      const item = this.item
      this.cartItemButtonClicked({
        $event,
        item,
        actionName: 'edit',
        id: item.id,
        cartItems: this.currentCartItems,
        button: {}
      })
      EventBus.$emit('cart-item-edit', item, this, this.$parent)
      EventBus.$emit('cart-item-edit-' + item.type, item, this, this.$parent)
    },
  },
}
