import Base from '../Base'

export class Product extends Base {

  constructor(data, vm) {
    super(data, vm)
    this.details = this.parseCustom()
  }

  get id() {
    return this.data.id
  }

  get title() {
    return this.data.title
  }

  get description() {
    return this.data.description
  }

  get price() {
    return this.data.price * 1
  }

  get tax() {
    return this.data.tax * 1
  }

  get type() {
    return this.data.type
  }

  get category() {
    const data = this.data
    return data.category_slug || data.category || data[this.type + '_type']
  }

  get location() {
    return this.data.location_id
  }

  get features() {
    // stored in part of `custom` meta as either `features`[array] property
    // or as `items`[array]  property
    // these can be defined in custom crud field as feature.0, feature.1 ...feature.n or items.0, items.1, ... item.n
    return _.get(this.details, 'features', _.get(this.details, 'items'))
  }

  parseCustom() {
    const custom = this.data.details || {}
    _.forOwn(this.data.custom || {}, (value, key) => {
      _.set(custom, key, value)
    })
    return custom
  }
}

export default Product
