/*global _ */
import {fv} from '../../../../lib/utils'
import {optionsHydrator} from '../../../../lib/form/input'

import props from './../../../../props/inputs'
import optionsProps from './../../../../props/inputs-options'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-checkboxes',
  mixins,
  props: {
    ...props,
    ...optionsProps,
    name: {
      type: String,
      require: false,
    },
    value: {
      type:[Array, Object, String, Boolean, Number],
      default: null
    },
    inputClass: {
      type: String,
      default: 'form-control-checkboxes'
    },
  },
  data() {
    return {
      innerValue: _.isObject(this.value) ? _.values(this.value) : _.castArray(fv(this.value, [])),
      innerOptions: this.options,
      optionsPropName: OPTIONS_PROP
    }
  },
  computed: {
    optionsList() {
      // [1,2,3]
      //[{value, label}, {}, {}]
      //{value: label, value2: label2}
      let source = this.innerOptions
      if (_.isString(source)) {
        source = [source]
      }
      const sourceIsArray = _.isArray(source)
      const itemIsObject = sourceIsArray && _.isPlainObject(_.first(source))

      const useArrayOptions = this.useArrayOptions
      if (sourceIsArray && !useArrayOptions && !itemIsObject) {
        source = _.mapKeys(source)
      }
      return _.reduce(source, (o, i, k) => {
        const isObject = _.isObject(i)
        let item = isObject ? i : {}
        const label = fv(item.label, item.name, item.key, isObject ? '' : i, k)
        const value = fv(item.value, item.key, k, label)
        const checked = this.innerValue.includes(value)
        o.push({...item, label, value, checked})
        return o
      }, [])
    }
  },
  watch: {
    options: {
      handler(newValue) {
        this.innerOptions = newValue
      },
      deep: true
    }
  },
  methods: {
    getInputProps(item, key) {
      return {
        multi: true,
        name: this.name,
        tabindex: this.tabindex,
        id: item.id || null,
        label: item.label
      }
    },
    onInput(value, item) {
      this.isDirtyState = true
      const values = _.values(_.map(_.pickBy(this.optionsList, i => i.checked), o => o.value))
      this.model = values
    },
    getLabelExtraClasses() {
      return ['re-input-label-checkboxes']
    },
    hydrator: optionsHydrator
  }
}
