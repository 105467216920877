import {copyToClipboard} from '../../../../lib/html'

export default {
  name: 're-copy-button',
  props: {
    messages: {},
    value: {},
    showValue: {
      default: false
    },
    editValue: {
      default: false
    },
    viewComponent: {
      default: 're-text'
    },
    editComponent: {
      default: 're-input'
    },
    componentAttrs: {
      default: () => ({})
    },
    buttonAttrs: {
      default: () => ({})
    }
  },
  computed: {
    viewComponentAttrs() {
      return this.componentAttrs || {}
    },
    editComponentAttrs() {
      return this.componentAttrs || {}
    },
    btnAttrs() {
      return {
        title: 'Copy',
        ...this.$attrs || {},
        ...this.buttonAttrs || {}
      }
    },
  },
  methods: {
    copy() {
      copyToClipboard(this.value)
    }
  }
}
