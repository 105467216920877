export const name = 're-core-page-shop-products'
export const component = 're-shop-products'

import {factory} from '../../../../lib/page'
import {queryVars} from '../../../complex/shop/products/base/props'

const props = {
  ...queryVars,
  forceType: {}
}

export default factory(name, {props, component})

