/*global _ */

/*
DEVELOPER NOTE: A rule that does not require a parameter is called a standalone rule,
                others with parameters are parameterized rules.
1. Make sure to define a standalone rule as a function which takes the value as the first param
2. Add the name of the standalone rule in list of standalone rules below
3. Parameterized rules e.g., max, must take one or more params in the wrapping function
4. A parameterized rule may break due to null or undefined passed as params
  In that case developer can take precaution to check if the parameter values are null or undefined
   and return alwaysTrue rule instead
 */

import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import {parsePhoneNumber} from 'awesome-phonenumber'
export {postcodeValidator, postcodeValidatorExistsForCountry, parsePhoneNumber}

import props, {attrs as propKeys} from '../../props/validations'
export {props, propKeys}

import formatters, {some, toBoolean, nil} from '../formatters'
import vMixin from '../../mixins/validation'
import {parseDateTime, parseRelativeDateTime, now} from '../dates'

export const ValidationMixins = vMixin
export const Validators = require('vuelidate/lib/validators')
export const {
  required,
  requiredIf,
  requiredUnless,
  minLength,
  maxLength,
  minValue,
  maxValue,
  between,
  alpha,
  alphaNum,
  numeric,
  integer,
  decimal,
  email,
  sameAs,
  url,
  or,
  and,
  not,
  helpers,
  macAddress,
  ipAddress
} = Validators

export const { ref, len, regex, req, withParams } = helpers

export const setValidationRule = (name, items, validator = 'submittingInvalidRule') => {
  if (!items) {
    items = _.startCase(name.replace(/^[\s\S]*\./, ''))
  }
  if (items && _.isString(items)) {
    items = {required: {error: `${items} is required.`}}
  }
  return {name, validator, items}
}

export const parseValidations = (validations) => {
  return _.reduce(validations, (items, item, key) => {
    items[key] = _.reduce(item.items, (v, ruleItem, ruleName) => {
      let {rule = Validators[ruleName]} = ruleItem
      if (rule) {
        _.set(v, ruleName, rule)
      }
      return v
    }, {})
    return items
  }, {})
}

export const parseValidationSetup = (validations, vm) => {
  _.forOwn(validations, (item) => {
      if (!_.isFunction(item.validator)) {
        const validator = vm[item.validator]
        if (validator && _.isFunction(validator)) {
          item.validator = validator
        }
      }
  })
  return validations
}

// used by validation functions to force validate
export const alwaysTrueRule = () => true

/**
 * validates a value using rules defined as string/array/object
 *
 * @param value
 * @param rules string|array|object|function
 *              function: Passed the value
 *              string: name of a validation rule - value is passed to it e.g., isNull
 *                 - Rule function is retrieved from Validators, exportDefaults, formatters, lodash, this
 *              array: [validation rule name, params...] e.g., ['digitsBetween', 3, 5]
 *
 */
export const isValid = (value, rules) => {
  if (_.isFunction(rules)) {
    return rules(value)
  }
  if (_.isString(rules)) {
    rules = [rules]
  }
  if (_.isArray(rules) && !_.isEmpty(rules)) {
    const [ruleName, ...params] = rules
    const rule = Validators[ruleName] || exportDefault[ruleName] || formatters[ruleName] || _[ruleName]
    if (_.isFunction(rule)) {
      const isStandalone = standaloneRules.includes(ruleName)
      if (isStandalone) {
        return rule(value)
      } else {
        const validator = rule(...params)
        return validator(value)
      }
    }
  }
  return true
}

export const truthyRule = (value) => toBoolean(value)
export const trueRule = sameAs(() => true)
export const falsyRule = (value) => !toBoolean(value)
export const falseRule = sameAs(() => false)

export const different = (equalTo) =>
  withParams({ type: 'different', eq: equalTo }, function(value, parentVm) {
    return value !== ref(equalTo, this, parentVm)
  })

export const regexp = (exp, flags, name) => {
  return regex(name || _.uniqueId('vregex_'), new RegExp(exp, flags || ''))
}

export const notRegexp = (exp, flags, name) => {
  const rule = regex(name || _.uniqueId('vnregex_'), new RegExp(exp, flags || ''))
  return (value) => !req(value) || !rule(value)
}

export const digitsBetween = (min, max) => {
  return withParams({type: 'digitsBetween', min, max}, function (value, parentVm) {
    const minVal = Math.pow(10, Math.max(0, min - 1))
    const maxVal = Math.pow(10, Math.max(0, max - 1))
    return !req(value) || !_.isFinite(1 * value) || (value >= minVal && value < maxVal)
  })
}

export const requiredWhen = (prop, ...equalTos) => {
  return withParams({type: 'requiredWhen', prop, eq: equalTos}, function (value, parentVm) {
    const propValue = ref(prop, this, parentVm)
    return req(value) || !(some(equalTos, v => propValue === v))
  })
}

export const requiredNotWhen = (prop, ...equalTos) => {
  return withParams({type: 'requiredNotWhen', prop, eq: equalTos}, function (value, parentVm) {
    const propValue = ref(prop, this, parentVm)
    return req(value) || (some(equalTos, v => propValue === v))
  })
}

export const requiredWith = (prop, rules) => {
  return withParams({type: 'requiredWith', prop, rules}, function (value, parentVm) {
    const propValue = ref(prop, this, parentVm)
    return req(value) || !(isValid(propValue, rules))
  })
}

export const requiredNotWith = (prop, rules) => {
  return withParams({type: 'requiredNotWith', prop, rules}, function (value, parentVm) {
    const propValue = ref(prop, this, parentVm)
    return req(value) || (isValid(propValue, rules))
  })
}

export const charsOnly = chars => regexp(`^[${chars}]*$`, '', 'charsOnly')

export const charsExcept = chars => notRegexp(`[${chars}]`, '', 'charsExcept')

export const passwordRules = {
  required: {error: 'Password is required.'},
  minLength: {
    error: 'Password must be 8 characters minimum.',
    rule: minLength(8)
  },
  hasAlpha: {
    error: 'Password must contain a letter.',
    rule: regex('hasAlpha', /[a-zA-Z]/)
  },
  hasNum: {
    error: 'Password must contain a number.',
    rule: regex('hasNum', /[0-9]/)
  },
  hasSym: {
    error: 'Password must contain a symbol.',
    rule: regex('hasSym', /[\W]/)
  },
  hasCap: {
    error: 'Password must contain a capital letter.',
    rule: regex('hasCap', /[A-Z]/)
  },
}

export const sometimesPasswordRules = _.omit(passwordRules, ['required'])

export const passwordConfirmationRules = {
  // required: {error: 'Confirm Password is required.'},
  sameAsPass: {error: 'Confirm Password needs to be same as Password.', rule: sameAs('password')},
}

export const emailConfirmationRules = {
  sameAsPass: {error: 'Confirm Email Address needs to be same as Email.', rule: sameAs('email')},
}

export const phoneConfirmationRules = {
  sameAsPass: {error: 'Confirm Mobile phone needs to be same as Mobile phone.', rule: sameAs('phone')},
}

export const validDate = function (value, fields) {
  if (!nil(value)) {
    try {
      const datetime = parseDateTime(value)
      return datetime.isValid
    } catch (e) {
      return false
    }
  }
  return true
}

export const validDateBeforeToday = function (value, fields) {
  if (value) {
    const date = parseDateTime(value)
    if (date.isValid && date >= now) {
      return false
    }
  }
  return true
}

// @param limit +2 days,//-1 year//date plus 10 years
// @param falseOnInvalid - if set to true, returns false when dates are invalid or blank
export const minDate = (limit, falseOnInvalid) => {
  return withParams({type: 'minDate', limit, falseOnInvalid}, function (value) {
    if (value) {
      const date = parseDateTime(value)
      if (!date.isValid) {
        return !falseOnInvalid
      }
      let limitDate = parseRelativeDateTime(limit)
      if (limitDate.isValid) {
        return date >= limitDate
      }
    }
    return !falseOnInvalid
  })
}

export const maxDate = (limit, falseOnInvalid) => {
  return withParams({type: 'maxDate', limit, falseOnInvalid}, function (value) {
    if (value) {
      const date = parseDateTime(value)
      if (!date.isValid) {
        return !falseOnInvalid
      }

      let limitDate = parseRelativeDateTime(limit)
      if (limitDate.isValid) {
        return date <= limitDate
      }
    }
    return !falseOnInvalid
  })
}

export const dateBetween = (start, end, falseOnInvalid) => {
  return withParams({type: 'dateBetween', start, end}, function (value) {
    if (!end) {
      return !req(value) || (minDate(start, falseOnInvalid)(value))
    }
    return !req(value) || ((maxDate(end, falseOnInvalid)(value)) && (minDate(start, falseOnInvalid)(value)))
  })
}

/*
  https://letstalkremote.atlassian.net/browse/OCRW-181
  PHONE NUMBER FORMATS ALLOWED FOR ENHANCED VALIDATION

    For USA

      1. Number of digits = 10 (not less than 10 nor more than 10).
        In case of International format, 10 digits + dial code (+1)

      2. Can have these formats (with dummy number as example)

        2.1 National format (202) 555-0100

        2.2 International format +1 202-555-0100

        2.3 E164 format +12025550100

        2.4 other format 2025550100

      3. Can have extension at the end with: ext. or x. + extension number as suffix e.g.

        3.1 (202) 555-0100 ext.1234

        3.2 (202) 555-0100 x.1234

    For Canada

      1. Number of digits = 10 (not less than 10 nor more than 10). In case of International format, 10 digits + dial code (+1)

      2. Formats:

        2.1 National format 819-555-5555

        2.2 International format + 1 819 555 5555

        2.3 E164 format +18195555555

        2.4 Other formats 8195555555

 */
export const phoneRule = function (value, fields) {
  const country = (fields || {}).country || null
  const phoneNumber = parsePhoneNumber(_.trim(value || ''), country)
  const valid = phoneNumber && phoneNumber.isValid()
  return valid
}
export const phoneRuleIfNotEmpty = function (value, fields) {
  return value ? phoneRule(value, fields) : true
}
export const phoneRuleUSOrCanada = function (value) {
  return phoneRule(value, {country: 'US'}) || phoneRule(value, {country: 'CA'})
}
export const phoneRuleUSOrCanadaIfNotEmpty = function (value) {
  return value ? phoneRuleUSOrCanada(value) : true
}

export const phoneRules = {
  required: {error: 'Phone is required.'},
  phone: {error: 'Invalid phone number.', rule: phoneRuleIfNotEmpty}
}
export const phoneRulesStrict = {
  phone: {error: 'Invalid phone number.', rule: phoneRule}
}
export const phoneRulesUSOrCanada = {
  required: {error: 'Phone is required.'},
  phone: {error: 'Invalid phone number.', rule: phoneRuleUSOrCanadaIfNotEmpty}
}
export const phoneRulesUSOrCanadaStrict = {
  phone: {error: 'Invalid phone number.', rule: phoneRuleUSOrCanada}
}

/*
  https://letstalkremote.atlassian.net/browse/OCRW-181
  ZIP CODE FORMATS ALLOWED FOR ENHANCED VALIDATION

  In USA – 5 digits (not less than 5 nor more than 5)

  In Canada - X1X 1X1 (alphanumeric string of characters where X is a letter and 1 is a digit)

 */
export const zipRule = function (value, fields) {
  const country = (fields || {}).country || 'INTL'
  // some country code e.g. for UAE throwing exceptions
  try {
    if (!postcodeValidatorExistsForCountry(country)) {
      return postcodeValidator(_.trim(value || ''))
    }
    return postcodeValidator(_.trim(value || ''), country)
  } catch (e) {
    //
  }
  return true
}
export const zipRuleIfNotEmpty = function (value, fields) {
  return nil(value) ? true : zipRule(value, fields)
}
export const zipRuleUSOrCanada = function (value) {
  value = _.trim(value || '')
  return postcodeValidator(value, 'US') || postcodeValidator(value, 'CA')
}
export const zipRuleUSOrCanadaIfNotEmpty = function (value) {
  return nil(value) ? true : zipRuleUSOrCanada(value)
}

export const zipRules = {
  required: {error: 'Zip is required.'},
  zip: {error: 'Invalid zip.', rule: zipRuleIfNotEmpty},
}
export const zipRulesStrict = {
  zip: {error: 'Invalid zip.', rule: zipRule},
}

export const zipRulesUSOrCanada = {
  required: {error: 'Zip is required.'},
  zip: {error: 'Invalid zip.', rule: zipRuleUSOrCanadaIfNotEmpty},
}
export const zipRulesUSOrCanadaStrict = {
  zip: {error: 'Invalid zip.', rule: zipRuleUSOrCanada},
}

export const urlWithoutSchemeRule = function (value, fields) {
  value = _.trim(value || '')
  if (!value || url(value)) {
    return true
  }
  const schemeAdded = 'http://' + value
  const canAddScheme = url(schemeAdded)
  return !canAddScheme
}

const exportDefault = {
  props,
  propKeys,
  ValidationMixins,
  Validators,

  required,
  requiredIf,
  requiredUnless,
  minLength,
  maxLength,
  minValue,
  maxValue,
  between,
  alpha,
  alphaNum,
  numeric,
  integer,
  decimal,
  email,
  sameAs,
  url,
  or,
  and,
  not,
  helpers,
  macAddress,
  ipAddress,

  ref,
  regex,
  req,
  len,
  withParams,
  regexp,
  notRegexp,

  setValidationRule,
  parseValidations,
  parseValidationSetup,

  requiredWhen,
  requiredNotWhen,
  requiredWith,
  requiredNotWith,
  charsOnly,
  charsExcept,

  trueRule,
  truthyRule,
  falseRule,
  falsyRule,
  different,

  passwordRules,
  sometimesPasswordRules,
  passwordConfirmationRules,
  validDate,
  validDateBeforeToday,
  minDate,
  maxDate,
  dateBetween,
  phoneRule,
  phoneRuleIfNotEmpty,
  phoneRuleUSOrCanada,
  phoneRuleUSOrCanadaIfNotEmpty,
  phoneRules,
  phoneRulesStrict,
  phoneRulesUSOrCanada,
  phoneRulesUSOrCanadaStrict,

  zipRule,
  zipRuleIfNotEmpty,
  zipRuleUSOrCanada,
  zipRuleUSOrCanadaIfNotEmpty,
  zipRules,
  zipRulesStrict,
  zipRulesUSOrCanada,
  zipRulesUSOrCanadaStrict,

  emailConfirmationRules,
  phoneConfirmationRules,

  urlWithoutSchemeRule,

  postcodeValidator,
  postcodeValidatorExistsForCountry,
  parsePhoneNumber,

  isValid
}

export const standaloneRules = exportDefault.standaloneRules = [
  'nullable',
  'required',
  'alpha',
  'alphaNum',
  'numeric',
  'integer',
  'decimal',
  'email',
  'url',
  'macAddress',
  'ipAddress',

  'truthyRule',
  'falsyRule',
  'validDate',
  'validDateBeforeToday',
  'phoneRule',
  'phoneRuleIfNotEmpty',
  'phoneRuleUSOrCanada',
  'phoneRuleUSOrCanadaIfNotEmpty',
  'zipRule',
  'zipRuleIfNotEmpty',
  'zipRuleUSOrCanada',
  'zipRuleUSOrCanadaIfNotEmpty',
  'urlWithoutSchemeRule',
]

const ad = window.addiesaas = (window.addiesaas || {})
const adfn = ad.$fn || (ad.$fn = {})
const adValidators = adfn.validators || (adfn.validators = {})
Object.assign(adValidators, exportDefault)

export default exportDefault
