export default {
  data() {
    return {
      isBeingProcessed: this.processing,
      isBeingProcessedMessage: null,
      processingErrorMessage: this.processingError,
      processors: {}
    }
  },
  watch: {
    processing(newValue) {
      this.isBeingProcessed = newValue
    },
    processingError(newValue) {
      this.processingErrorMessage = newValue
    }
  },
  computed: {
    isProcessingFailed() {
      return !!this.processingErrorMessage
    }
  },
  methods: {
    startProcessing(msg) {
      this.isBeingProcessed = true
      this.isBeingProcessedMessage = msg
    },
    stopProcessing() {
      this.isBeingProcessed = false
      this.isBeingProcessedMessage = ''
    },
    setProcessing(key, value = true) {
      if (!key) {
        return this.isBeingProcessed = !!value
      }
      Vue.set(this.processors, key, !!value)
    },
    isItProcessing(key) {
      if (!key) {
        return this.isBeingProcessed
      }
      return _.get(this.processors, key)
    }
  }
}
