import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

import config from '../../../../../lib/config'
const {BASE_IMAGES: reImagePath = ''} = config

const headersDefault = [
  {class:'header-item-title', label: 'Title'},
  {class:'header-item-quantity', label: 'Quantity'},
  {class:'header-item-amount', label: 'Price'},
]
const headersUnitDefault = [
  {class:'header-item-title', label: 'Item'},
  {class:'header-item-price', label: 'Price'},
  {class:'header-item-quantity', label: 'Quantity'},
  {class:'header-item-amount', label: 'TotalPrice'},
]

const productGroupNamesDefault = {
  giftcard: 'Gift card',
  giftcard_reload: 'Gift card reload',
  playcard: 'Play card',
  playcard_sale: 'Play card',
  playcard_reload: 'Play card reload',
  playcard_voucher: 'Play card voucher',
  reservation: 'Party Booking',
  movie: 'Movie Tickets',
}

const paymentMethodsDefault = {
  message: null,
  items: {
    'visa': {
      'image': `${reImagePath}payment-card-visa.png`,
      'title': 'Visa',
      'url': '//usa.visa.com/',
      'cssClass': [],
    },
    'mastercard': {
      'image': `${reImagePath}payment-card-master.png`,
      'title': 'Mastercard',
      'url': '//www.mastercard.us/',
      'cssClass': [],
    },
    'americanexpress': {
      'image': `${reImagePath}payment-card-ae.png`,
      'title': 'American Express',
      'url': '//www.americanexpress.com/',
      'cssClass': [],
    },
    'discover': {
      'image': `${reImagePath}payment-card-discover.png`,
      'title': 'Discover',
      'url': '//www.discover.com/',
      'cssClass': [],
    },
  }
}

const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'SHOPPING CART')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  showCartHeader: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showCartHeader', true)
    }
  },
  cartComponentSuffix: {
    type: String,
    default() {
      return this.$options.$$s('cartComponentSuffix', '')
    }
  },
  cartComponentPrefix: {
    type: String,
    default() {
      return this.$options.$$s('cartComponentPrefix', 'custom-')
    }
  },

  headers: {
    type: [Array, Object],
    default() {
      const showUnitPrice = !!this.$options.$$s('showUnitPrice', this.$options.$$t('item.showUnitPrice', false));
      const defaults = showUnitPrice ? headersUnitDefault : headersDefault
      const headers = _.merge([], this.$options.$$t('headers', []), this.$options.$$s('headers', []))
      return _.isEmpty(headers) ? defaults : headers
    }
  },
  allowGuestCheckout: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowGuestCheckout', false)
    }
  },
  guestCheckoutTypes: {
    type: [Object, Array, String],
    default() {
      return this.$options.$$s('guestCheckoutTypes',  [])
    }
  },

  skipBillingIfNoPayment: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('skipBillingIfNoPayment', false)
    }
  },

  showPaymentMethodsInfo: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPaymentMethodsInfo', true)
    }
  },
  paymentMethods: {
    type: Object,
    default() {
      const paymentMethods = _.merge({}, this.$options.$$t('paymentMethods', {}), this.$options.$$s('paymentMethods', {}))
      return _.isEmpty(paymentMethods) ? paymentMethodsDefault : paymentMethods
    }
  },
  paymentMethodsClasses: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$s('paymentMethods.class', null)
    }
  },

  costDetailsInfo: {
    type: Object,
    default() {
      return this.$options.$$s('summary.sections', null) //@change: costDetails.info
    }
  },
  costDetailsOrder: {
    type: [Array, Object],
    default() {
      return this.$options.$$s('summary.sections.$order', ['price', 'tax', 'discounts', 'price_discount', 'total']) //@change: costDetails.order
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submit.buttonTitle', 'Submit')
    }
  },
  submitButtonTitleIncludeAmount: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submit.buttonTitle.withAmount', true)
    }
  },
  submitButtonContainerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submit.buttonContainerClass', null)
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submit.buttonClass',
        ['btn', 'btn-next', 'btn-accent'])
    }
  },
  submitButtonImageSrc: {
    type: String,
    default() {
      return this.$options.$$s('submit.buttonImageSrc', null)
    }
  },
  submitButtonInFooter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submit.buttonInFooter', false)
    }
  },

  errorContainerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('errorContainer.class', null)
    }
  },

  loginUri: {
    type: [String, Object],
    default() {
      return this.$options.$$s('login.url', '/sign-in')
    }
  },
  loginText: {
    type: String,
    default() {
      return this.$options.$$t('login.label', 'Sign In')
    }
  },
  registerUri: {
    type: [String, Object],
    default() {
      return this.$options.$$s('register.url', '/register')
    }
  },
  registerText: {
    type: String,
    default() {
      return this.$options.$$t('register.label', 'Register')
    }
  },
  itemComponentPrefix: {
    type: String,
    default() {
      return this.$options.$$s('itemComponent.prefix' , null)
    }
  },
  itemComponentSuffix: {
    type: String,
    default() {
      return this.$options.$$s('itemComponent.suffix' , '-cart-item')
    }
  },
  useDefaultItemViewer: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useDefaultItemViewer', false)
    }
  },

  productGroupNames: {
    type: Object,
    default() {
      return _.merge(
        {},
        productGroupNamesDefault,
        this.$options.$$s('productGroupNames', {}),
        this.$options.$$t('productGroupNames', {})
      )
    }
  },
  orderItemsByGroup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('orderItemsByGroup', true)
    }
  },
  showGroupTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showGroupTitle', true)
    }
  },
  noAuthCheck: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noAuthCheck', true)
    }
  },

  clearCartLabel: {
    type: String,
    default() {
      return this.$options.$$t('clearCart.label', 'Clear Cart')
    }
  },
  clearCartModalMessage: {
    type: String,
    default() {
      return this.$options.$$t('clearCart.modal.message',
        'Do you want to clear the Cart?')
    }
  },
  clearCartModalConfirmLabel: {
    type: String,
    default() {
      return this.$options.$$t('clearCart.modal.confirmLabel', 'Yes')
    }
  },
  clearCartModalDenyLabel: {
    type: String,
    default() {
      return this.$options.$$t('clearCart.modal.denyLabel', 'No')
    }
  },
  noRedemptions: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noRedemptions', false)
    }
  },
  checkoutAsGuestModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('checkoutAsGuestModal.title',
        `You are checking out as a guest.
        Please specify an email that will be used
        for sending billing confirmation and Gift Card details.`)
    }
  },
  checkoutAsGuestModalErrorPrefix: {
    type: String,
    default() {
      return this.$options.$$t('checkoutAsGuestModal.error.prefix',
        'This email is already registered. Please')
    }
  },
  checkoutAsGuestModalErrorLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('checkoutAsGuestModal.error.linkLabel',
        'sign in')
    }
  },
  checkoutAsGuestModalErrorPostfix: {
    type: String,
    default() {
      return this.$options.$$t('checkoutAsGuestModal.error.postfix',
        'or use another email.')
    }
  },
  checkoutAsGuestModalButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('checkoutAsGuestModal.buttonTitle', 'Proceed')
    }
  },
  loginPromptModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('loginPromptModal.title',
        'Please sign in to your account to <br> complete the billing.')
    }
  },
  loginPromptModalButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('loginPromptModal.buttonTitle',
        'LOGIN TO EXISTING ACCOUNT')
    }
  },
  loginPromptModalNote: {
    type: String,
    default() {
      return this.$options.$$t('loginPromptModal.note',
        'If you don\'t have an account, you can create it')
    }
  },
  timerComponent: {
    type: String,
    default() {
      return this.$options.$$s('timerComponent', 're-cart-timer')
    }
  },
  showTimer: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTimer', true)
    }
  },
  paymentInfoComponent: {
    type: String,
    default() {
      return this.$options.$$s('paymentInfoComponent', 're-cart-payment-info')
    }
  },
  showRedemption: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showRedemption', true)
    }
  },
  redemptionComponent: {
    type: String,
    default() {
      return this.$options.$$s('redemptionComponent', 're-cart-redemption')
    }
  },
  summaryComponent: {
    type: String,
    default() {
      return this.$options.$$s('summaryComponent', 're-cart-summary')
    }
  },
  errorComponent: {
    type: String,
    default() {
      return this.$options.$$s('errorComponent', 're-cart-error')
    }
  },
  validationMessage: {
    type: String,
    default: 'Validating'
  },
  alwaysShowSummary: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('alwaysShowSummary', false)
    }
  },
  validateBeforeBilling: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateBeforeBilling', false)
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
