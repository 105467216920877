import {Vue} from '~/addiesaas'
import ReCorePageUserRegistration from './registration'
Vue.component(ReCorePageUserRegistration.name, ReCorePageUserRegistration)

import ReCorePageUserEmailVerification from './email-verification'
Vue.component(ReCorePageUserEmailVerification.name, ReCorePageUserEmailVerification)

import ReCorePageUserLogin from './login'
Vue.component(ReCorePageUserLogin.name, ReCorePageUserLogin)

import ReCorePageUserForgotPassword from './forgot-password'
Vue.component(ReCorePageUserForgotPassword.name, ReCorePageUserForgotPassword)

import ReCorePageUserResetPassword from './reset-password'
Vue.component(ReCorePageUserResetPassword.name, ReCorePageUserResetPassword)

import ReCorePageUserProfile from './profile'
Vue.component(ReCorePageUserProfile.name, ReCorePageUserProfile)

import ReCorePageUserClaimPromotionReward from './claim-reward'
Vue.component(ReCorePageUserClaimPromotionReward.name, ReCorePageUserClaimPromotionReward)

export default {
  ReCorePageUserRegistration,
  ReCorePageUserEmailVerification,
  ReCorePageUserLogin,
  ReCorePageUserForgotPassword,
  ReCorePageUserResetPassword,
  ReCorePageUserProfile,
  ReCorePageUserClaimPromotionReward
}
