export const name = 're-shop-product-item-subscription-plan'

import Subscription from '../../../../../../../classes/user/Subscription'
import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields, Subscription}

import ComponentMixin from '../../../../../../../mixins/components/complex'
import ResponsiveMixin from '../../../../../../../mixins/responsive'
import SubscriptionMixin from '../../../../../../../mixins/shop/subscription'
export const mixins = [ComponentMixin, ResponsiveMixin, SubscriptionMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  Subscription
}
