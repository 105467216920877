export const COUPON_CODE_PARSE_MAP = {
  $couponCode: {
    valuePath: 'action_options.coupon_code'
  },
  $couponLocations: {
    value: '{[action_options.locations.0]}',
    formatEach: [['get', ['name']]],
    format: [
      ['join', [', ']],
      [' at {{value}}', [], true]
    ],
    skipNil: true,
    defaultValue: '',
  },
}

export const COUPON_CODE_MESSAGES = {
  'coupon_code': `Use {{$couponCode}} for discount{{$couponLocations}}.
      {{br}}
      {{$expiringIn}}`,
}

export const COUPON_CODE_ERROR_MESSAGES = {}
