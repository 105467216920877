export const name = 're-loyalty-points-history'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../../../mixins/components/complex-reactive'
import ResponsiveMixin from '../../../../../../mixins/responsive'
import ProfileBaseMixin from '../../../../../../mixins/profile/base'
export const mixins = [
  ComponentMixin,
  ResponsiveMixin,
  ProfileBaseMixin,
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  errorMessage: ''
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
