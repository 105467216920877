/*global _ */

import {fv, getQS} from '../../../lib/utils'
import ComponentMixin from '../../../mixins/components/complex'
import BaseInputMixin from '../../../mixins/form/base-input'
import CustomComponentsMixin from './custom-components'
import CalcMixin from './calc'
import props from './props'

const mixins = [ComponentMixin, BaseInputMixin, CustomComponentsMixin, CalcMixin]

export default {
  name: 're-paginate',
  mixins,
  props,
  created() {
    this.init()
  },
  watch: {
    total() {
      this.pager = this.buildPager()
    },
    pages() {
      this.pager = this.buildPager()
    },
    nameSpace() {
      this.pager = this.buildPager()
    },
  },
  methods: {
    init() {
      this.pager = this.buildPager(true)
      this.loadPage()
    },
    loadPage() {
      const qsPage = this.loadPageFromQS() - 1
      const index = fv(this.persistedPageIndex, qsPage, this.value, 0)
      if (index >= 0) {
          this.navigate(index)
      }
    },
    loadPageFromQS() {
      const qs = getQS()
      return qs[this.queryVar || 'page']
    },
    onPaginatorEvent(event, ...params) {
      if (event === 'index') {
        const value = params[0]
        this.persistedPageIndex = value
        this.model = value
      }
    },
  }
}
