export default {
  methods: {
    async fetchCards(options, filters){
      filters = filters || {}
      const {giftcard, playcard} = await this.invokeService('user.card.cards.get', {}, options)

      const giftcardFilters = filters.giftcard || {omit: {state: 'given', is_virtual: true}}
      const playcardFilters = filters.playcard || {omit: {}}
      const claimedGiftcards = cardFilter(giftcard || [], giftcardFilters)
      const claimedPlaycards = cardFilter(playcard || [], playcardFilters)

      const playcardParams = {cards: claimedPlaycards, vm: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.playcard.cards`, playcardParams)
      this.$$applyDataFilters(`playcard.cards`, playcardParams)

      const giftcardParams = {cards: claimedGiftcards, vm: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.giftcard.cards`, giftcardParams)
      this.$$applyDataFilters(`giftcard.cards`, giftcardParams)

      const allCards = (playcardParams.cards || []).concat(giftcardParams.cards)
      const allCardsTyped = {giftcard: giftcardParams.cards || [], playcard: playcardParams.cards || []}

      return {
        allCards,
        allCardsTyped
      }
    }
  }
}

const cardFilter = (cards, filters) => {
  const includeFilters = _.omit(filters, ['omit'])
  const omitFilters = filters.omit || {}
  return _.filter(cards, (card) => {
    let include = true
    if (!_.isEmpty(omitFilters)) {
      include = !_.some(omitFilters, (value, key) => {
        if (_.isFunction(value)) {
          return value(card)
        }
        return _.get(card, key) == value
      })
    }
    if (include && !_.isEmpty(includeFilters)) {
      include = _.some(includeFilters, (value, key) => {
        return _.get(card, key) == value
      })
    }
    return include
  })
}
