import {createNamespacedHelpers } from 'vuex'
import { namespace } from '../../vuexstore'
import { default as store, namespace as cartNamespace } from '../../vuexstore/modules/profile'

const { mapGetters, mapActions } = createNamespacedHelpers([namespace, cartNamespace].join('/'))

export default {
  computed: {
    ...mapGetters(Object.keys(store.getters)),
  },
  methods: {
    ...mapActions(Object.keys(store.actions)),
  }
}

