/*global _ */
import component from '../../../../../props/component'

const localProps = {
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('status.classes', '')
    }
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('status.title', '')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.showTitle', false)
    }
  },
  showProgressBar: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.showProgressBar', true)
    }
  },
  showValues: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.showValues', true)
    }
  },
  showMessages: {
    type: [Boolean, Number, String],
    default() {
      const $s = this.$options.$$s('status.showMessages', null)
      return _.isNil($s) ? $s : !!$s
    }
  },
  showShortMessage: {
    type: [Boolean, Number, String],
    default() {
      const $s = this.$options.$$s('status.showShortMessage', null)
      return _.isNil($s) ? $s : !!$s
    }
  },
  shortMessageTemplate: {
    type: String,
    default() {
      return this.$options.$$t('status.shortMessageTemplate', null)
    }
  },
  showLongMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.showLongMessage', true)
    }
  },
  longMessageTemplate: {
    type: String,
    default() {
      return this.$options.$$t('status.longMessageTemplate', null)
    }
  },
  showFulfilledMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.showFulfilledMessage', true)
    }
  },
  fulfilledMessageTemplate: {
    type: String,
    default() {
      return this.$options.$$t('status.fulfilledMessageTemplate', null)
    }
  },

  longMessagePrefix: {
    type: String,
    default() {
      return this.$options.$$t('status.longMessagePrefix', null)
    }
  },
  longMessageSuffix: {
    type: String,
    default() {
      return this.$options.$$t('status.longMessageSuffix', null)
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('status.loadingMessage', 'Loading')
    }
  },
  announceRewards: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('status.announceRewards', false)
    }
  },
  skipAnnounceOnPages: {
    type: [String, Array],
    default() {
      return this.$options.$$s('status.skipAnnounceOnPages', ['sign-in'])
    }
  },
  // tier, achievements
  type: {
    type: String,
    default() {
      return this.$options.$$s('status.type', 'tier')
    }
  },
  // where this status is placed (page-header, menu, page-inline etc.)
  position: {}
}
export const props = {
  ...component,
  ...localProps
}
export default props
