<template lang="pug">
  .b-central(v-if="tenants")
    .b-central__content.b-central__content--center
      .b-central__title Select Account
      .b-central__panel.b-central__panel--w400.b-central__panel--center.m-t-50.b-central__panel--scrollable
        ul.b-btn-list(v-if="tenants")
          li.ul.b-btn-list__item(v-for="tenant in tenants" @click="select(tenant)") {{ tenant.name }}
            img.b-btn-list__arrow-right(:src="`${config.baseUri}/images/client/saas-onboarding/list-arrow-right.svg`" width="20" height="20" alt="")

</template>

<script>
import axios from 'axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    token: null,
    tenants: null,
  }),

  async created() {
    const { data } = await axios.get(`${this.config.baseUri}/api/onboarding/auth/user?include=tenants`)
    this.token = data.data.token

    const tenants = data.data.tenants.data

    if (tenants.length == 1) {
      await this.select(tenants[0])
    } else {
      this.tenants = tenants
    }
  },

  methods: {
    async select(tenant) {
      this.$store.dispatch('Onboarding/setTenantId', { id: tenant.id })

      const { data: { data: { onboarding } } } = await axios.get(`${this.config.baseUri}/api/onboarding/${tenant.id}`)

      if (onboarding.complete) {
        window.location.href = `http://${tenant.domain}/login/tenant/sso?token=${this.$store.getters.authToken}`
      } else {
        this.$router.push({ name: onboarding.route || 'onboarding-welcome' })
      }
    },
  },
}
</script>
