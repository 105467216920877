import ReToastAlert from '../components/elements/re-toast-alert'
import toastAlert from '../lib/dynamic/toast'

export default {
  components: {ReToastAlert},
  methods: {
    toastAlert
  }
}

