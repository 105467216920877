export const name = 're-core-page-shop-products-sort'
export const component = 're-shop-products-sort'

import {factory} from '../../../../../lib/page'
import {queryVars} from '../../../../complex/shop/products/base/props'

const props = {
  ...queryVars
}

export default factory(name, {props, component})

