/*global _ */
import {EXPIRATION_PATH, INLINE_TITLE_PATH} from '../../../../../../lib/profile/loyalty'

export const EARN_FROM_PURCHASE_TYPE = 'order_earn'
export const SPEND_FROM_PURCHASE_REFUNDED_TYPE = 'order_refunded'
export const EARN_FROM_PROMOTION_TYPE = 'promotion'
export const SPEND_VIA_PURCHASE_TYPE = 'order_spend'
import {formatDate, parseDateTime, getNow, Interval} from '../../../../../../lib/dates'

import {
  name,
  mixins,
  props,
  data,
} from './config'

import {getLocations} from '../../../../../../lib/formatters'

export default {
  name,
  props,
  mixins,
  events: 'user.profile.loyalty.history',
  data() {
    return {
      ...data,
      paginateSize: this.pageSize,
      page: 1,
      history: [],
      histories: {},
      total: null,
      totalPages: null,
    }
  },
  computed: {
    location() {
      return _.get(this.storedProfileData, 'loyalty.location_id')
    },
    noHistory() {
      return !this.isPromising && !_.isNil(this.total) && this.total <= 0
    },
    allPagesLoaded() {
      return _.isNil(this.totalPages) || this.page >= this.totalPages
    },
    closeButtonAttrs() {
      return {
        title: this.closeButtonTitle,
        class: this.closeButtonClasses,
      }
    },
    loadButtonAttrs() {
      return {
        title: this.loadButtonTitle,
        class: this.loadButtonClasses,
      }
    }
  },
  async created() {
    await this.initHistories()
    await this.loadHistory()
  },
  async activated() {
    await this.initHistories()
    if (_.isEmpty(this.history)) {
      await this.loadHistory()
    }
  },
  methods: {
    async initHistories() {
      const locations = await getLocations()
      _.forOwn(locations, (name, id) => {
        this.histories[id] = this.histories[id] || {
          items: [],
          page: 1,
          total: null,
          totalPages: null,
        }
      })
      const location = this.location
      if (location) {
        const data = this.histories[location]
        this.page = data.page
        this.total = data.total
        this.totalPages = data.totalPages
        this.history = data.items
      }
    },
    async loadHistory(isNext) {
      const location = this.location
      if (isNext && this.allPagesLoaded) {
        return
      }
      if (isNext) {
        this.page++
      }
      const payload = {
        page: this.page
      }
      if (this.paginateSize !== false) {
        payload.page_size = this.paginateSize
      }
      if (location) {
        _.set(payload, 'where.location_id', location)
      }

      this.startPromising('')
      try {
        const {data, meta} = await this.invokeService('user.loyaltyProgram.history', payload)

        this.total = _.get(meta, 'pagination.total')
        this.totalPages = this.total > 0 ?  _.get(meta, 'pagination.total_pages') : 0
        const items = this.parseItems(data)
        const params = {items,  vm: this}
        this.$$applyDataFilters(`${this.eventSpace}.items`, params)
        this.history = [...(isNext ? this.history: []), ...params.items]
        if (location) {
          this.histories[location].items = this.history
          this.histories[location].total = this.total
          this.histories[location].totalPages = this.totalPages
          this.histories[location].page = this.page
        }
      } catch (e) {
        if (isNext) {
          this.page--
        }
      }
      this.stopPromising()
    },
    parseItems(items) {
      const parsedItems = items.map(data => {
        const item = {}
        const parsedData = this.getParsableData(data, item)

        item.createdAt = this.getCreatedAt(data)
        item.points = this.getPoints(data)
        item.type = this.getType(data)

        item.pointTitle = this.getPointTitle(item, parsedData)
        item.title = this.getTitle(item, parsedData)
        item.description = this.getDescription(item, parsedData)
        item.date = this.getDate(item, parsedData)
        item.message = this.getItemMessage(item, parsedData)
        item.expiration = this.getExpirationMessage(item, parsedData)
        return item
      })
      const params = {items: parsedItems}
      this.$$applyDataFilters(`${this.eventSpace}.items`, params)
      return params.items
    },
    getCreatedAt(def) {
      return parseDateTime(def.created_at)
    },
    getPoints(def) {
      return Math.round(def.points)
    },
    getType(def) {
      const {points, type} = def
      if (type === EARN_FROM_PURCHASE_TYPE && points < 0) {
        return SPEND_FROM_PURCHASE_REFUNDED_TYPE
      }
      return def.type
    },
    getPointTitle($item, data) {
      const {points, type} = $item
      const defaultFormats = {
        [SPEND_VIA_PURCHASE_TYPE]: '{{points}} {{unit}}',
        [EARN_FROM_PURCHASE_TYPE]: ' {{points}} {{unit}}',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: ' {{points}} {{unit}}',
        [EARN_FROM_PROMOTION_TYPE]: ' {{points}} {{unit}}'
      }
      const formats = this.$$t('pointTitle', defaultFormats)
      const defaultUnits = {
        [SPEND_VIA_PURCHASE_TYPE]: 'point|points',
        [EARN_FROM_PURCHASE_TYPE]: 'point|points',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: 'point|points',
        [EARN_FROM_PROMOTION_TYPE]: 'point|points'
      }
      const unitFormats = this.$$t('pointUnit', defaultUnits)
      const unit = this.$tc(unitFormats[type], points)
      return this.parseReactiveValues(formats[type], {...data, points, unit})
    },
    getTitle($item, data) {
      const {type} = $item
      const defaults = this.$$t('titles', {
        [SPEND_VIA_PURCHASE_TYPE]: 'Redemption',
        [EARN_FROM_PURCHASE_TYPE]: 'Purchase',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: 'Cancelled Order',
        [EARN_FROM_PROMOTION_TYPE]: 'Promotion'
      })
      return this.parseReactiveValues(defaults[type], {...data})
    },
    getActionTitle(item, data) {
      const {type} = item
      const paths =  this.$$s('actionTitlePaths', {
        [SPEND_VIA_PURCHASE_TYPE]: {
          value: '{[product]}',
          parseParams: true,
          format: [[
            'firstString', [`{{product.${INLINE_TITLE_PATH}}}`, '{{product.title}}']
          ]]
        },
        [EARN_FROM_PURCHASE_TYPE]: '{{referable.total}}',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: '{{referable.total}}',
        [EARN_FROM_PROMOTION_TYPE]: '{{promotion.name}}'
      })

      return this.parseReactiveValues(paths[type], {...data})
    },
    getDescription(item, data) {
      const {type} = item
      const action = this.getActionTitle(item, data)
      const paths =  this.$$t('description', {
        [SPEND_VIA_PURCHASE_TYPE]: '{{action}}',
        [EARN_FROM_PURCHASE_TYPE]: '${{action}}',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: '${{action}}',
        [EARN_FROM_PROMOTION_TYPE]: '{{action}}'
      })
      return this.parseReactiveValues(paths[type], {...data, action})
    },
    getItemMessage(item, data) {
      const {type} = item
      const paths =  this.$$s('actionTitlePaths', {
        [SPEND_VIA_PURCHASE_TYPE]: '',
        [EARN_FROM_PURCHASE_TYPE]: '',
        [SPEND_FROM_PURCHASE_REFUNDED_TYPE]: '',
        [EARN_FROM_PROMOTION_TYPE]: ''
      })
      return this.parseReactiveValues(paths[type], {...data})
    },
    getExpirationMessage(item, data) {
      const expiration = this.getExpirationDays(item, data)
      if (!_.isNil(expiration)) {
        if (expiration < 0) {
          return this.$$t('expiredMessage', 'Expired!')
        }
        const template = this.$$t('expirationMessage', 'Expiring today|Expiring tomorrow|Expiring in {{expiration}} days')
        return this.$$tc(template, template, expiration, {expiration})
      }
      return ''
    },
    getExpirationDays(item, data) {
      const expiration = 1 * _.get(data, `product.${EXPIRATION_PATH}`)
      if (expiration) {
        const orderItem = data.orderItem || {}
        const state = orderItem.state
        const isDestroyed = state === 'destroyed'
        if (isDestroyed) {
          return -1 // expired
        }
        const isFinished = state === 'finished'
        const createdAt = item.createdAt
        if (createdAt.isValid) {
          const expiringAt = createdAt.plus({days: expiration})
          if (expiringAt.isValid) {
            const now = getNow()
            const hasExpired = now >= expiringAt
            if (hasExpired && isFinished) {
              return null // do not show anything if expired and not yet destroyed (indicating that points has been used at location)
            }
            if (hasExpired) {
              return -1 // expired
            }
            const interval = Interval.fromDateTimes(getNow(), expiringAt)
            return Math.floor(interval.length('days'))
          }
        }
      }
      return null
    },
    getDate(item) {
      const format = this.dateFormat || {
        method: 'toRelative', //toRelativeCalendar
        params: [{style: 'long'}] //"long", "short", or "narrow" //https://moment.github.io/luxon/api-docs/index.html#datetimetorelative
      }
      return formatDate(item.createdAt, format)
    },
    getParsableData($data, $item, merge) {
      const referable = _.get($data, 'referable.data', {})
      const product = _.get(referable, 'items.data.0.product.data', {})
      const orderItem = _.get(referable, 'items.data.0', {})
      const promotion = _.get(referable, 'promotion.data', {})
      return {referable, product, promotion, orderItem, $data, $item, ...(merge || {})}
    },
    dismiss() {
      this.emitSlim('dismiss', this)
    }
  }
}
