<template lang="pug">

  transition(name='re-modal')
    .re-modal-mask
      .re-modal-wrapper(@click="close")
        .re-modal-container(:class="containerClass", @click.stop="")
          button.re-modal-close-button(@click.prevent="close" v-if="!noCloseButton")
          .re-modal-body
            slot default

</template>

<script>
import ModalMixin from '../../mixins/modal'

export default {
  name: 'modal',
  type: 'modal',
  mixins: [ModalMixin],
  props: ['container-class', 'noCloseButton'],
  methods: {
    close() {
      this.$emit('close');
    },
    keyUp(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    }
  },
  created: function() {
    document.addEventListener('keyup', this.keyUp);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.keyUp);
  }
}
</script>

<style lang="scss">
.depreciated {

&.re-modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  .re-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .re-modal-container {
      display:  table;
      position: relative;
      margin: auto;
      width: 900px;
      min-height: 535px;
      padding: 0;
      background-color: #fff;
      font-family: Helvetica, Arial, sans-serif;
      .re-modal-close-button {
        position: absolute;
        top: 19px;
        right: 19px;
        width: 33px;
        height: 33px;
        background-image: url('../../../icons/modal-close.png');
        background-color: white;
      }
      .re-modal-body {
        padding: 50px 60px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        > * {
          margin-left: auto;
          margin-right: auto;
        }
        .re-modal-title {
          color: #282828;
          font-family: "Open Sans", sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          padding-bottom: 50px;
        }
        .re-modal-message {
          color: #282828;
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          padding-bottom: 40px;
        }
        .re-modal-buttons {
          .btn {
            padding: 0 60px;
            margin: 10px 10px;
            display: inline-block;
            line-height: 50px;
            &.btn-primary {
              height: 50px;
            }
            @media (max-width: 479px) {
              padding: 0 10px;
              margin: 10px 0;
              width: 100%;
              white-space: normal;
              span {
                display: inline-block;
                line-height: 20px;
                vertical-align: middle;
                padding-bottom: 3px;
              }
            }
          }
        }
      }

      @media (max-width: 900px) {
        width: 100%;
        .re-modal-body {
          padding: 50px 10px;
        }
      }
    }
  }
}

&.re-modal-enter, &.re-modal-leave-active {
  opacity: 0;
}

&.re-modal-enter-active, &.re-modal-leave-active {
  transition: opacity .4s;
}
  }
</style>
