/*global _, addiesaas */
export default {
  props: {
    customAddToCartAction: {
      default() {
        const $$s = this.$options.$$s
        if ($$s) {
          return $$s('customAddToCartAction', null)
        }
        return null
      }
    }
  },
  methods: {
    async executeCustomAddToCartAction() {
      if (this.customAddToCartAction) {
        const actions = this.customAddToCartAction.split('://')
        const actionMethod = _.camelCase('on_' + actions[0])
        debugLog({actionMethod, method: actionables[actionMethod], actions})
        if (actionables[actionMethod]) {
          actionables[actionMethod].call(this, actions[1])
          return true
        }
      }
    }
  }
}

const actionables = {
  onNextUrl(param) {
    const url = param
    window.location.assign(url)
  },
  onNextStep(param) {
    const switcher = _.get(addiesaas, 'pageService.actions')
    if (switcher && switcher.invokeAction) {
      const params = {
        action: 'render-content',
        contentId: param,
      }
      switcher.invokeAction(params)
    }
  },
  onNextView(param) {
    const switcher = _.get(addiesaas, 'pageService.actions')
    if (switcher && switcher.invokeAction) {
      const params = {
        action: 'switch-view',
        viewId: param
      }
      switcher.invokeAction(params)
    }
  },
}
