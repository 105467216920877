/*global _ */
import {valueFormat} from '../../lib/formatters'

export default {
  data: () => ({

  }),
  methods: {
    parseDynamicSections(sections, data, sectionsPath = 'sections') {

      if (!sections) {
        sections = this.$$s(sectionsPath)
      }
      return _.mapValues(sections, (section, sKey) => {

        const sectionPath = `${sectionsPath}.${sKey}`

        const title = this.$$t(`${sectionPath}.title`, section.title || _.startCase(sKey), data)
        const showTitle = _.get(section, 'showTitle', false)
        const subTitle = this.$$t(`${sectionPath}.subTitle`, section.subTitle, data)
        const showSubTitle = _.get(section, 'showSubTitle', false)
        const notice = this.$$t(`${sectionPath}.notice`, section.notice, data)
        const showNotice = _.get(section, 'showNotice', false)
        const classes = section.class || _.kebabCase(`section-${sKey}`)

        const sectionDef = {title, showTitle, subTitle, showSubTitle, notice, showNotice, classes}

        sectionDef['items'] = _.reduce(section.items || {}, (carry, item, key) => {
          const itemPath =  `${sectionPath}.items.${key}`

          const title = this.$$t(`${itemPath}.title`, item.title || _.startCase(key), data)
          const showTitle = _.get(item, 'showTitle', true)
          const classes = item.class || _.kebabCase(`item-${key}`)

          // manage values
          let langValues = this.$$t(`${itemPath}.value`, null, data)
          let valuePaths = item.valuePath
          if (valuePaths) {
            valuePaths = _.castArray(valuePaths).map(p => this.$$get(p, _.get(data, p)))
          }

          let values = langValues || valuePaths || item.value
          values = valueFormat.call(this, values, null, item, data)

          if (_.isNil(values) || (_.isArray(values) && !values.length)) {
            values = item.default
          }
          values = _.castArray(values)
          carry[key] = {title, showTitle, values, classes}
          return carry
        }, {})

        return sectionDef
      })
    }
  }
}
