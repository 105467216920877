<template lang="pug">
  .b-onboarding__right
    .b-onboarding__title How do you Intend to use rocket effect?
    .b-onboarding__subtitle Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

    .b-learn-blocks
      template(v-for="item in options.useBlocks")
        .b-learn-blocks__block(@click="selectUse(item.label)" :class="{ 'b-learn-blocks__block--selected': selectedUses.includes(item.label) }")
          .b-learn-blocks__block-text {{ item.label }}
          .b-learn-blocks__block-icon
            inline-svg(:src="require(`../../../public/images/client/saas-onboarding/onboarding/blocks/${item.icon}`)")
          .b-learn-blocks__block-link(@click="$bvModal.show(item.label)") Learn more >
          b-modal(:id="item.label" size="md")
            template(#modal-header="{ close }")
              .modal-title.b-learn-blocks__modal-title
                img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/blocks/${item.icon}`")
                span {{ item.label }}
              b-button.close(@click="close()") ×
            template(#default)
              p At pretium, amet, vulputate quam felis etiam sollicitudin elit. Commodo mollis donec nibh at. Velit iaculis mollis convallis tortor sed cras mauris aliquam eu. Et pretium scelerisque morbi non quis. Pharetra diam enim volutpat amet, semper sagittis turpis ut lectus. Metus viverra tincidunt elit commodo aliquet. Lectus porta sagittis urna id. Ut adipiscing amet sit tortor gravida. Commodo maecenas nulla at aenean condimentum pharetra. Tempor ut adipiscing eget consectetur mauris, lacus tempor, nisi nullam. Ultrices ornare sed id lacus. Integer aliquam ac sapien nisi, tellus elementum a.
            template(#modal-footer="{ hide }")
              b-button(variant="secondary" @click="hide()") Cancel
              b-button(variant="primary" @click="hide()") Got It

    .b-select-tags
      .b-select-tags__title What do you find most challenging about marketing your business?
      template(v-for="item in options.useTags")
        .b-select-tags__tag(@click="selectChallenge(item)" :class="{ 'b-select-tags__tag--checked': selectedChallenges.includes(item) }")
          .b-select-tags__tag-checkbox
            img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/tag-selected.svg`")
          .b-select-tags__tag-text {{ item }}

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  computed: {
    selectedUses() {
      return this.onboarding.uses || []
    },
    selectedChallenges() {
      return this.onboarding.challenges || []
    },
  },

  methods: {
    selectUse(item) {
      if (this.selectedUses.includes(item)) {
        this.selectedUses.splice(this.selectedUses.indexOf(item), 1)
      } else {
        this.selectedUses.push(item)
      }
      this.update({ uses: this.selectedUses })
    },

    selectChallenge(item) {
      if (this.selectedChallenges.includes(item)) {
        this.selectedChallenges.splice(this.selectedChallenges.indexOf(item), 1)
      } else {
        this.selectedChallenges.push(item)
      }
      this.update({ challenges: this.selectedChallenges })
    },
  }
}
</script>
