<template lang="pug">

  .form-group(:class="classes" :tabindex="null")
    label(v-if="label", :class="{ required: required }" v-html="label")
    slot
    has-error(:form="form", :field="name")

</template>

<script>
import { HasError } from 'vform'

export default {
  name: 'form-group-row',

  components: {
    HasError,
  },

  props: {
    form: {
      type: Object,
      required: true,
      default: () => null
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object,
      default: null
    }
  },

  computed: {
    classes() {
      const formError = this.form.errors.has(this.name);
      const validation = this.validation ? this.validation[this.name] : null;
      return {
        'is-invalid': formError || (validation && validation.$error),
        'is-valid': !formError && validation && validation.$dirty && !validation.$error
      };
    }
  },
}
</script>
