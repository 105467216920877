/*global _ */
import {name, mixins, props, data} from './config'
import {notNils, pluck} from '../../../../lib/formatters'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },

  computed: {
    appliedFilters() {
      const filters = _.merge({}, this.productFilters || {}, {
        type: this.productType,
        subType: this.productSubType,
        provider: this.productProvider,
        providerType: this.productProviderType,
        category: this.productCategory,
        customType: this.productCustomType,
        combo: this.useProductCombo
      })
      const location = this.filterByLocation && this.currentLocationId
      if (location) {
        filters.location = location
      }
      return _.pickBy(filters, i => i !== '' && !_.isNil(i))
    },

    filteredProducts() {
      if (this.shopProductsLoaderProperties.enabled) {
        return this.shopProducts
      }
      if (this.forceType) {
        return _.get(this.getAllProducts(), this.forceType, [])
      }
      const products = this.getFilteredProducts(this.appliedFilters)
      return products
    },
  },

  async created() {
    await this.setShopProductsController(this.promise)
    await this.setupLoader()
  },

  mounted() {
    this.initLoader()
  },
  methods: {
    initLoader() {

    },
    async setupLoader() {
      const lazy = this.lazyLoad
      const pagination = {
        ...this.$$ps('pagination', {}),
        ...notNils({
          lazy,
          limit: this.itemsPerPage,
          enabled: this.paginate,
          page: this._page,
        }),
        init: false,
        result: {}
      }
      if (!lazy && !pagination.enabled) {
        return
      }

      const sort = pluck(this.$$ps('sort.form.fields', {}), (v, k) => k, 'value');
      const filters = pluck(this.$$ps('filters.form.fields', {}), (v, k) => k, 'value');

      const {limit, page} = this.shopProductsPagination
      const payload = {
        limit,
        page,
        ...filters,
        ...sort,
        ...this.getProductVariationDetails()
      }
      await this.setShopProductsLoader(payload)

      if (!pagination.enabled) {
        if (lazy) {
          this.setShopProductsLoaderProperty({enabled: true})
        }
      } else {
        this.setShopPagination(pagination)
        this.setShopPaginationProperty({init: true})
      }
    },
    getProductItemAttrs(item) {
      item = item || this.productItem || {}
      const otherAttrs = this.mergedAttr

      const translations = this.getProductSpecificSettings(item, this.$$t, this.$$$t)
      const settings = this.getProductSpecificSettings(item)
      const attr = {
        item,
        view: this.productItemView,
        translations,
        settings,
        class: this.getProductClasses(item, ['re-shop-products-item'])
      }
      return attr
    },
    getComponentExtraClasses() {
      return [
        `${this.productItemView}-view`,
        _.kebabCase(`type-${this.productType}`),
        _.kebabCase(`subtype-${this.productSubType}`),
        _.kebabCase(`category-${this.productCategory}`),
        _.kebabCase(`custom-type-${this.productCustomType}`),
        _.kebabCase(`location-${this.currentLocationName}`),
      ]
    }
  },
}
