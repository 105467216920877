/*global _ */
import formatters from '../../lib/formatters'
import ComplexComponentMixin from './complex'

export default {
  mixins: [ComplexComponentMixin],
  data: () => ({
    pageWrapperComponent: true,
    defaultComponent: null,
    defaultComponents: [],
  }),
  computed: {
    hostPageTitle() {
      const show = formatters.toBoolean(this.$$s('host.page.config.showTitle')) !== false
      const title = show && (this.hostPageTitleConstant || this.$$t('host.page.title'))
      return title
    },
    hostPageHeading() {
      const show = formatters.toBoolean(this.$$s('host.page.config.showHeading')) !== false
      const heading = show && (this.hostPageHeadingConstant || this.$$t('host.page.heading'))
      return heading
    },
    hostPageProps() {
      const props = _.merge(
        {},
        this.$$clone(this.$$t('host.page.props', {})),
        this.$$clone(this.$$s('host.page.props', {}))
      )
      return props
    },


    componentName() {
      return this.$$s('component') || this.defaultComponent
    },
    componentNames() {
      return this.$$s('components') || this.defaultComponents
    },
  },
  created() {
    this.setupHostPage()
  },
  updated() {
    this.setupHostPage()
  },
  methods: {
    getPageClasses() {
      return ['re-page', this.pageClasses, ...this.getPageExtraClasses()]
    },
    getPageExtraClasses() {
      return []
    },
    setupHostPage() {
      this.setHostPageTitle()
      this.setHostPageHeading()
      this.setHostPageProps()
    },
    setHostPageTitle() {
      const title = this.hostPageTitle
      if (title) {
        formatters.title$(title)
      }
    },
    setHostPageHeading() {
      const heading = this.hostPageHeading
      const selector = this.$$s('host.page.config.heading.selector')
      if (heading && selector) {
        formatters.html$(heading, selector)
      }
    },
    setHostPageProps() {
      const props = this.hostPageProps || {}
      _.forOwn(props, (p, k) => {
        const selector = k.selector || k
        //@todo
      })
    }
  },
}

