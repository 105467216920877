import 'lodash.combinations'
// import _ from 'lodash'

export const getFirstCombination = (items, source, reverse, prefix, suffix, defaultValue) => {
  let combinations = _.flatMap(items, (v, i, a) => _.combinations(a, i + 1))
  if (reverse) {
    combinations = _.reverse(combinations)
  }
  let result = null
  for(let item of combinations) {
    let key = (prefix ? (prefix + '.') : '') + item.join('.') + (suffix ? (suffix + '.') : '')
    result = _.get(source || {}, key)
    if (!result) {
      if (_.isFunction(source)) {
        result = source(key)
      }
    }

    if(result) {
      return result
    }
  }
  return defaultValue
}

export default {
  getFirstCombination,
}
