import axios from 'axios'

import OnboardingBackAndForth from '../components/OnboardingBackAndForth'
import OnboardingSelectBlocks from '../components/OnboardingSelectBlocks'
import Options from '../const/Options'

export default {
  components: {
    OnboardingBackAndForth,
    OnboardingSelectBlocks,
  },

  async created() {
    const { data: { data } } = await axios.get(`${this.config.baseUri}/api/onboarding/${this.$store.getters['Onboarding/tenantId']}`)
    this.onboarding = data.onboarding || {}
  },

  async beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.update({ route: to.name })
    })
  },

  data: () => ({
    options: Options,
    onboarding: {},
  }),

  methods: {
    async update(onboarding) {
      const { data: { data } } = await axios.post(`${this.config.baseUri}/api/onboarding/${this.$store.getters['Onboarding/tenantId']}`, { onboarding })
      this.onboarding = data.onboarding
      return data
    }
  },
}
