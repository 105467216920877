/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-textarea',
  mixins,
  props,
  data() {
    return {
      optionsPropName: OPTIONS_PROP,
    }
  },
  computed: {
  },
  methods: {
    getLabelExtraClasses() {
      return ['re-input-label-textarea']
    }
  }
}
