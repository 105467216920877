/*global _ */
import {mapActions as rootMapActions, createNamespacedHelpers } from 'vuex'
import { namespace } from '../../../../../../vuexstore'
import {namespace as cartNamespace } from '../../../../../../vuexstore/modules/cart'
const { mapGetters } = createNamespacedHelpers([namespace, cartNamespace].join('/'))

const EventBus = window.VueEventBus
import SettingsTranslationsMixin from '../../../../../../mixins/components/settings-lang'

export default {
  name: 're-cart-error',
  mixins: [SettingsTranslationsMixin],
  props: {
    error: {
    },
    askToSignIn: {

    },
    errorContainerClass: {
      type: [String, Array, Object],
      default: ''
    },
    loginUri: {
      type: [String, Object],
      default: '/sign-in'
    },
    loginText: {
      type: String,
      default: 'Sign In'
    },
    registerUri: {
      type: [String, Object],
      default: '/register'
    },
    registerText: {
      type: String,
      default: 'Register'
    },
    skipErrors: {
      type: [String, Array],
      default(){
        return this.$options.$$s('errorComponentSkipErrors', null)
      }
    },
    settings: {},
    translations: {},
  },

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
    needsToSignIn() {
      return this.askToSignIn
    },
    errorMessage() {
      return this.error
    },
    canShowError() {
      const skip = this.skipErrors
      if (skip) {
        const errors = this.validationErrorMessages
        return !_.some(_.castArray(skip), path => _.has(errors, path))
      }
      return true
    },
    validationErrorMessages() {
      const errors = _.get(this.validationErrors, 'errors', {})
      return _.reduce(errors, (carry, item, path) => {
          _.set(carry, path, item)
          return carry
        }, {})
    },
    ...mapGetters(['validationErrors'])
  },

  methods: {
    ...rootMapActions(['rememberAfterLoginRoute']),

    storeAfterLoginRedirect() {
      EventBus.$emit('cart-error.set-post-login-page', 'page-checkout')
      this.rememberAfterLoginRoute({ route: 'cart', expires: 1 })
    },
  },
}
