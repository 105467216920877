import pageProps from '../../../../props/profile/page-props'
import tableProps from '../../../../props/profile/table-props'
import editProps from '../../../../props/profile/edit-props'

export const props = {
  ...pageProps,
  ...tableProps,
  ...editProps,

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading cards...')
    }
  },
  showLocationFilter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLocationFilter', false)
    }
  },
  alwaysShowLocationFilter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('alwaysShowLocationFilter', true)
    }
  },
  emptyFilteredCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyFilteredCardMessage', 'No card has been associated to this location.')
    }
  }
}

export default props
