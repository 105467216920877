/*global _ */
//import {$s} from './config'

import {
  Validators,
  setValidationRule,
  zipRules,
  truthyRule,
  phoneRules,
  passwordRules,
  validDate,
  validDateBeforeToday,
} from '../../../../lib/validations'

export const defaultFormFields = {first_name: '', last_name: '', email: '', social: {}}
export const allValidationsRules = {
  first_name: setValidationRule('fields.first_name'),
  last_name: setValidationRule('fields.last_name'),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  email_confirmation: setValidationRule('fields.email_confirmation', {
    confirmed: {error: 'Email is not confirmed.', rule: Validators.sameAs('email')},
  }),
  password: setValidationRule('fields.password', passwordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', {
    confirmed: {error: 'Confirm Password needs to be same as Password.', rule: Validators.sameAs('password')},
  }),
  birth_date: setValidationRule('fields.birth_date', {
    required: {error: 'Birth date is required.'},
    validDate: {error: 'Birth date is not valid.', rule: validDate},
    pastDate: {error: 'Birth date can not be a future date.', rule: validDateBeforeToday},
  }),
  address: setValidationRule('fields.address', 'Address'),
  city: setValidationRule('fields.city'),
  state: setValidationRule('fields.state'),
  zip: setValidationRule('fields.zip', zipRules),
  country: setValidationRule('fields.country'),
  phone: setValidationRule('fields.phone', phoneRules),
  phone_confirmation: setValidationRule('fields.phone_confirmation', {
    confirmed: {error: 'Phone is not confirmed.', rule: Validators.sameAs('phone')}
  }),
  terms: setValidationRule('fields.terms', {
    truthy: {error: 'Please agree to the Terms & Conditions', rule: truthyRule},
  }),
  social: {},
}

export default {defaultFormFields, allValidationsRules}
