/* global _ */
import {Vue} from '~/addiesaas'
import {name, mixins, props, data, defaultFormFields, validationsRules, EventBus} from './config'

export default {
  name,
  props,
  mixins,
  events: 'user.registration',
  data() {
    return {
      ...data,
      errorMessage: this.error,
      fields: {
        ...defaultFormFields,
        email: this.email,
        ...(this.firstName ? {first_name: this.firstName} : {}),
        ...(this.lastName ? {last_name: this.lastName} : {}),
      }
    }
  },
  created() {
    this.setupForm(validationsRules, _.cloneDeep(this.fields))
    this.termsUrl = this.withTerms && (this.terms || '/terms-of-use')
    EventBus.$on('user.registration.error', (e, fields) => {
      if (this.showLoginProposalWhenEmailExists) {
        if (_.get(e, 'all.email')) {
          this.showLoginProposal = true
        }
      }
    })
    this.$on('field-custom-error', this.handleFieldCustomError)
  },
  async mounted() {
    this.$nextTick()
    if (this.socialProvider) {
      if (_.has(this.fields, 'social')) {
        _.set(this.fields.social, 'provider', this.socialProvider)
      }
    }
  },
  watch: {
    'fields.first_name'(val) {
      if (this.withRacerName && !_.isEmpty(val)) {
        this.generateRacerName()
      }
    },
    'fields.last_name'(val) {
      if (this.withRacerName && !_.isEmpty(val)) {
        this.generateRacerName()
      }
    },
    async triggerSubmit(event) {
      if (event) {
        await this.initRegistration(event)
      }
    }
  },

  computed: {
    maxDateTime() {
      return this.getNow().toISODate()
    },
    showForm() {
      return this.showSuccessPopup || !this.isRegistered
    },
    showConfirmation() {
      return !this.noSuccessScreen && this.isRegistered
    },
    showRacerInput() {
      return this.withRacerName
    },
    showErrorMessage() {
      return !_.isEmpty(this.errorMessage)
    }
  },
  methods: {
    async trySubmitOnEnter() {
      if(this.submitOnEnter) {
        await this.initRegistration()
      }
    },
    getComponentExtraClasses() {
      return [{
        processed: this.isRegistered,
      }]
    },
    async initRegistration(event) {
      this.resetFieldsValidation()
      this.fixValidateInputs()
      this.$v.$touch()
      this.formSubmitting = true
      const fields = _.clone(this.fields)
      const publicFields =_.omit(fields, ['password', 'password_confirmation'])
      this.pass = fields.password
      this.emitSlim('before', publicFields)
      if (!this.isAnyInvalid) {
        this.emitSlim('valid', fields)
        if (this.disableActions) {
          return
        }
        try {
          this.emitSlim('init', publicFields)
          const payload = _.omitBy(fields, (v, k) => _.isNil(v) || v === '')
          const [data] = await this.promise('user.registration', payload)
          Vue.set(this, 'registered', publicFields)
          this.emitSlim('success', data, publicFields, this.pass)
          this.isRegistered = true
          this.resetForm()
        } catch (e) {
          this.showFormError(e, {'scrollTo': '.re-registration-box', flattenTopMessages: true})
          this.emitSlim('error', e, publicFields)
        } finally {
          this.emitSlim('end', publicFields)
        }
      } else {
        this.emitSlim('invalid', publicFields)
        this.scrollToFirstErrorElement()
      }
    },
    fixValidateInputs() {
      if (!this.withEmailConfirmation) {
        this.fields.email_confirmation = this.fields.email
      }
      if (!this.withPhoneConfirmation) {
        this.fields.phone_confirmation = this.fields.phone
      }
    },
    async resendConfirmation() {
      const payload = _.pick(this.registered, ['email'])
      this.$emit('resend', payload)
      EventBus.$emit('user.registration.resend-confirmation', payload)
      try {
        this.isResendingConfirmation = true
        const [data] = await this.promise('user.registration.resend-confirmation', payload)
        this.disableResend()
      } catch (e) {
        //
      } finally {
        this.isResendingConfirmation = false
      }
    },
    generateRacerName() {
      if (!this.racerNameTouched) {
        this.fields.racername = [this.fields.first_name, this.fields.last_name].join(' ').trim()
      }
    },
    hydrateFields(fields, fieldsKey = 'fields') {
      _.forOwn(fields, (value, fieldName) => {
        if (_.has(this[fieldsKey], fieldName)) {
          this[fieldsKey][fieldName] = value
        }
      })
    },
    handleFieldCustomError(fieldName, error) {
      if (!_.isEmpty(error)) {
        const value = this.fields[fieldName]
        let cache = this.fieldCustomErrorsCache[fieldName]
        if (!cache) {
          cache = this.fieldCustomErrorsCache[fieldName] = []
          this.$watch(`fields.${fieldName}`, (newValue) => {
            const cachedItem =_.find(cache, ['value', newValue]) || {};
            this.$set(this.fieldCustomErrors, fieldName, cachedItem.error || [])
          })
        }
        cache.push({value, error})
      }
    }
  }
}
