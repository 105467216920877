/*global _ */
import {Vue} from '~/addiesaas'
import * as types from '../mutation-types/import'
const namespaced = true
export const namespace = 'Import'

const EventBus = window.VueEventBus

const confDefaults = {
  sampleUrl: '',
  serviceHeaders: {},
  jobSettingId: null,
  jobSettingHashedId: null,
  showHeader: false,
  // entityName: '',
  // entityname: '',
  // entityNamePlural: '',
  // entitynameplural: '',
  importFinalizeStarted: null,
  importFinalized: null,
  // backUrl: ''
}

let defaults = {
  job: {},
  preview: {},
  nav: {
    step: 0,
    visitedTabs: [0],
    steps: [
      'init', // upload file
      'encoding', // select uploaded file encoding (can skip step for csv file upload)
      'processing', // select whether to add new or update or both, and skip based on field - unique field
      'mapping', // field mapping
      'end', // final status
    ],

    stepsConfig: [
      {isDropzone: true, showSampleDownload: true, resetOnBack: true},
      {isFileEncoder: true},
      {},
      {},
      {noTab: true, noBack: true},
    ],
    // additional configs for named steps
    // {component: '', config: {}}
    namedStepsConfig: {},
    stepComponents: [
      'dropzone',
      'encoder',
      'processor',
      'fields',
      'status',
    ],
    canSkipEncodingStep: null,

    stepIsValid: false,

    finalizingValidationData: {},
    finalizingValidationErrors: {},
    finalizingValidationFailed: false,

    validStepList: [
      false,
      false,
      false,
      false,
      false,
    ],
    noClick: (e) => {
      e.stopPropagation && e.stopPropagation()
    },
    noHeader: false,
    statusUrl: null,
  },
  props: {},
  uploader: {
    isUploading: false,
    uploadedFileName: '', // only when uploded from dropzone
    uploadProgressAmount: null,
    uploadCompleted: false, // true when successful, null on error
    showUploadedMessage: false,
    inputView: null, // text (textarea) or upload (file dropzone)
    uploadingData: '', // data being uploded from textarea instead of file
  },
  processor: {
    internalImportId: _.uniqueId('import-'),
    selectedEncoding: 'Auto Detect',
    importMode: 'add',
    skipEmptyValues: true,
    uniqueFields: '',
    finalizeFields: {},
    isValidating: false,
    isFinalizing: false,
    maxUploadSize: 5,

    isProcessing: false,
    processingMessage: '',

    mappingValue: {},
    processorConfig: {},
    finalizedData: {}
  },
  finalizeOptions: {
    customValues: {
      meta: {},
      fields: {},
    }
  }
}
// state
const state = {
  optionsLoaded: false,
  // computed props with get
  // customProps[getter] => state.props
  getterForMixin: ['getStoreValue', 'nav', 'conf', 'processor', 'uploader', 'customProps', 'finalizeOptions',
    'optionsLoaded', 'waitTillOptionsLoaded'],

  // computed props with get and set
  computers: ['job', 'preview', 'jobConfig', 'jobSetting', 'encodingTypes'],
  job: _.cloneDeep(defaults.job),
  preview: _.cloneDeep(defaults.preview),
  jobConfig: {},
  jobSetting: {},
  encodingTypes: {},

  // 1 level deep deep computed props with get and set. i.e. get/set forach item in conf.*, nav.* etc.
  // customProps[getter] => state.props
  deepComputers: ['conf', 'nav', 'uploader', 'processor', 'customProps'],
  conf: {
    entityName: '',
    entityname: '',
    entityNamePlural: '',
    entitynameplural: '',
    backUrl: '',
    ...confDefaults
  },
  nav: _.cloneDeep(defaults.nav),
  uploader: _.cloneDeep(defaults.uploader),
  processor: _.cloneDeep(defaults.processor),
  props: _.cloneDeep(defaults.props),
  finalizeOptions: _.cloneDeep(defaults.finalizeOptions),
  defaults
}

const refresh = (state, payload) => {
  defaults = state.defaults = payload
  _.forOwn(state.defaults, (item, key) => {
    Vue.set(state, key, _.cloneDeep(item))
  })
}
// mutations
const mutations = {
  [types.OPTIONS_LOADED](state) {
    state.optionsLoaded = true
  },
  [types.RESET_DEFAULTS](state) {
    refresh(state, _.merge({}, state.defaults))
  },
  [types.SET_DEFAULT](state, payload) {
    refresh(state, _.cloneDeep(payload))
  },
  [types.MERGE_DEFAULT](state, payload) {
    refresh(state, _.merge({}, state.defaults, payload))
  },
  [types.RESET_IMPORT_CONF](state) {
    state.conf = _.merge({}, state.conf, confDefaults)
  },
  [types.SET_STORE_CONFIG](state, item) {
    if (item.accessor) {
      if (!_.has(state, item.accessor)) {
        const parts = item.accessor.split('.')
        let storage = state
        for (let part of parts) {
          if (!_.has(storage, part)) {
            Vue.set(storage, part, {})
          }
          storage = storage[part]
        }
      }
    }
    const storage = item.accessor ? _.get(state, item.accessor) : state
    Vue.set(storage, item.key, item.value)
  },
  [types.SET_JOB](state, item) {
    state.job = item
  },
  [types.SET_JOB_CONFIG](state, item) {
    state.jobConfig = item
  },
  [types.UPDATE_JOB_CONFIG](state, item) {
    state.jobConfig = _.merge({}, state.jobConfig, item)
  },
  [types.SET_JOB_SETTING](state, item) {
    state.jobSetting = item
  },
  [types.STEP_UP_DOWN](state, direction) {
    const nav = state.nav
    const prevStep = nav.step
    const possibleStep = nav.step + direction
    if (nav.steps[possibleStep]) {
      Vue.set(nav, 'step', possibleStep)
      EventBus.$emit('import.nav', possibleStep, prevStep)
      EventBus.$emit('import.nav.' + (direction < 1 ? 'prev' : 'next'), possibleStep, prevStep)
    }
  },
  [types.SET_STEP](state, step) {
    const nav = state.nav
    const prevStep = nav.step
    if (nav.steps[step]) {
      Vue.set(nav, 'step', step)
      EventBus.$emit('import.nav', step, prevStep)
    }
  },
  [types.ADD_VISITED_TAB](state, step) {
    if (!state.nav.visitedTabs.includes(step)) {
      state.nav.visitedTabs.push(step)
    }
  }
}

// actions
const actions = {
  setOptionsLoaded({commit}) {
    commit(types.OPTIONS_LOADED)
  },
  resetDefaults({commit}) {
    commit(types.RESET_DEFAULTS)
  },
  setDefaults({commit}, payload) {
    commit(types.SET_DEFAULT, payload)
  },
  mergeDefaults({commit}, payload) {
    commit(types.MERGE_DEFAULT, payload)
  },
  setStoreValue({commit}, payload) {
    commit(types.SET_STORE_CONFIG, payload)
  },
  setJob({commit}, payload) {
    commit(types.SET_JOB, payload)
    EventBus.$emit('import.job.loaded', payload)
  },
  setJobConfig({commit}, payload) {
    commit(types.SET_JOB_CONFIG, payload)
    EventBus.$emit('import.job.config.loaded', payload)
  },
  setJobSetting({commit}, payload) {
    commit(types.SET_JOB_SETTING, payload)
    EventBus.$emit('import.job.setting.loaded', payload)
  },

  updateJobConfig({commit}, payload) {
    commit(types.UPDATE_JOB_CONFIG, payload)
    EventBus.$emit('import.job.config.updated', payload)
  },
  gotoStep({commit}, step) {
    commit(types.SET_STEP, step)
  },
  goPrevStep({commit}, step) {
    commit(types.STEP_UP_DOWN, -step)
  },
  goNextStep({commit}, step) {
    commit(types.STEP_UP_DOWN, step)
  },
  resetImportConf({commit}) {
    commit(types.RESET_IMPORT_CONF)
  },
  addVisitedTab({commit}, step)  {
    commit(types.ADD_VISITED_TAB, step)
  }
}

// getters
const getters = {
  optionsLoaded: state => state.optionsLoaded,
  waitTillOptionsLoaded: state =>  () => {
    return new Promise(resolve => {
      const check = () => {
        if (state.optionsLoaded) {
          resolve(state.optionsLoaded)
        }
      }
      check()
      if (state.optionsLoaded) {
        resolve(state.optionsLoaded)
      }
      if (!state.optionsLoaded) {
        setTimeout(check, 50)
      }
    })
  },
  job: state => state.job,
  jobConfig: state => state.jobConfig,
  jobSetting: state => state.jobSetting,
  customProps: state => state.props,
  finalizeOptions: state => state.finalizeOptions,
  nav: state => state.nav,
  conf: state => state.conf,
  uploader: state => state.uploader,
  processor: state => state.processor,
  getStoreValue(state) {
    return function (accessor, key) {
      const storage = accessor ? _.get(state, accessor) : state
      return _.get(storage, key)
    }
  },
}

// plugins
const plugins = [
  // createPersistedState({
  //   key: 'utils-import',
  // })
]

const cart = {namespaced, namespace, state, mutations, actions, getters, plugins}
export default cart
