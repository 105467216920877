import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const localProps = {
  isFormDisabled: {},
  viewPaymentDetails: {},
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Billing Information')
    }
  },
  note: {
    type: String,
    default() {
      return this.$options.$$t('note',
          `Please enter your billing details that
          will be associated to the payment.`)
    }
  },
  totals: {
    type: Object,
    default: null,
  },
  guestEmail: {
    type: String,
    default: null,
  },
  billingLoadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Your order is being processed...')
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Submit payment')
    }
  },
  backButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('backButtonTitle', 'Go back')
    }
  },

  firstNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.firstName.label', 'First Name')
    }
  },
  firstNamePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.firstName.placeholder', 'First Name')
    }
  },
  firstNameClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.firstName.class', 'form-field')
    }
  },
  lastNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.lastName.label', 'Last Name')
    }
  },
  lastNamePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.lastName.placeholder', 'Last Name')
    }
  },
  lastNameClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.lastName.class', 'form-field')
    }
  },
  cardLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.card.label',
        'Credit Card Number')
    }
  },
  cardPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.card.placeholder',
        'XXXX XXXX XXXX XXXX')
    }
  },
  cardClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.card.class',
        'form-field card-number')
    }
  },
  cvvLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.cvv.label',
        'Security code')
    }
  },
  cvvPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.cvv.placeholder', '****')
    }
  },
  cvvClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.cvv.class', 'form-field card-code')
    }
  },
  expMonthLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expMonth.label',
        'Expiration Month')
    }
  },
  expMonthPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expMonth.placeholder',
        'Exp Month')
    }
  },
  expMonthClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.expMonth.class',
        'form-field exp-month')
    }
  },
  expYearLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expYear.label', 'Expiration Year')
    }
  },
  expYearPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expYear.placeholder', 'Exp Year')
    }
  },
  expYearClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.expYear.class', 'form-field exp-year')
    }
  },
  hasBillingAddressBox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.addressBox.show', true)
    }
  },
  billingAddressTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.address.title',
          'Billing Address Details (Optional)')
    }
  },
  hasBillingCountry: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.country.show', true)
    }
  },
  countryLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.country.label', 'Country')
    }
  },
  countryPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.country.placeholder', 'Country')
    }
  },
  countryClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.country.class', 'form-field billing-country')
    }
  },
  hasBillingState: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.state.show', true)
    }
  },
  stateLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.state.label', 'State')
    }
  },
  statePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.state.placeholder', 'State')
    }
  },
  stateClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.state.class',
        'form-field billing-state')
    }
  },
  hasBillingZip: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.zip.show', true)
    }
  },
  zipLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.zip.label', 'Zip Code')
    }
  },
  zipPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.zip.placeholder', 'XXXXXXXX')
    }
  },
  zipClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.zip.class',
        'form-field billing-zip')
    }
  },
  hasBillingAddress: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.address.show', false)
    }
  },
  addressLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.address.label', 'Address')
    }
  },
  addressPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.address.placeholder', 'Address')
    }
  },
  addressClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.address.class',
        'form-field billing-address')
    }
  },
  hasBillingCity: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.city.show', false)
    }
  },
  cityLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.city.label', 'City')
    }
  },
  cityPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.city.placeholder', 'City')
    }
  },
  cityClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.city.class', 'form-field billing-city')
    }
  },
  errorComponent: {
    type: String,
    default() {
      return this.$options.$$s('errorComponent', 're-cart-error')
    }
  },
  validationMessage: {
    type: String,
    default: 'Validating'
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
