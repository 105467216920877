/*global _ */

export default {
  created() {
    window.addEventListener('load', (event) => {
        this.$forceUpdate()
    });
  },
}

