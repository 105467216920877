/*global _, addiesaas */
import {invokeService} from '../lib/formatters'
export default {
  methods: {
    async invokeService(name, ...args) {
      let data = []
      data = await invokeService(name, ...args)
      return data
    }
  }
}
