<template lang="pug">
  div
    img.b-logo-bg(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.lg.svg`")
    .b-central.b-central--mobile
      .b-central__content.b-central__content--center
        img.m-t-40.m-b-15(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.sm.svg`")
        .b-central__panel.b-central__panel--w260.b-central__panel--center.m-b-40
          .b-central__title.b-central__title--sm.text-center Forgot your password?
          .b-central__subtitle.text-center We’ll help you reset it and get back on track.

          form-group.m-b-50(
            :validator="$v.email"
            label="Email:"
            attribute="email"
            v-slot="props"
            :messages="{ backendError }"
          )
            input(
              v-model="$v.email.$model"
              :class="props.classes"
              type="email"
              placeholder="Enter your email"
              @input="input"
            )

          v-button.btn.btn-lg.btn-block.btn-primary.m-b-20(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Reset password
          a.btn.btn-lg.btn-block.btn-secondary(@click.prevent="$router.go(-1)") Back

</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    backendError: null,
    submitting: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/auth/forgot-password`, {
          email: this.email,
        })
        this.$router.push({ name: 'vouchermobilescanner-password-verify', query: { email: this.email } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
