import { mapGetters } from 'vuex'
import MainService from './../../../../services/main'
import ComponentMixin from '../../../../mixins/components/complex'
import {
  parseValidations,
  passwordConfirmationRules,
  passwordRules,
  setValidationRule,
  ValidationMixins,
  Validators
} from '../../../../lib/validations'
const {required} = Validators

const EventBus = window.VueEventBus

const validations = {
  old_password: setValidationRule('fields.old_password'),
  password: setValidationRule('fields.password', passwordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', passwordConfirmationRules),
}
export default {
  name: 're-profile-reset-password',
  props: {
    settings: {},
    translations: {},
    title: {
      type: String,
      default() {
        return this.$options.$$t('title', 'Change password')
      }
    },
    showTitle: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$t('showTitle', true)
      }
    },
    classes: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('classes', null)
      }
    },
    buttonTitle: {
      type: String,
      default() {
        return this.$options.$$t('buttons.submit.title', 'Change')
      }
    },
    buttonClasses: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('buttons.classes', '')
      }
    },
    isProcessing: {
      type: [Boolean, Number, String],
      default: false,
    },
    askOldPassword: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('askOldPassword', true)
      }
    },
    oldPasswordAttrs: {
      default() {
        return this.$options.$$s('form.fields.old_password.attr', {})
      }
    },
    passwordAttrs: {
      default() {
        return this.$options.$$s('form.fields.password.attr', {})
      }
    },
    passwordConfirmationAttrs: {
      default() {
        return this.$options.$$s('form.fields.password_confirmation.attr', {})
      }
    }
  },

  mixins: [ComponentMixin, ValidationMixins],

  data() {
    return {
      fields: {
        old_password: null,
        password: null,
        password_confirmation: null
      },
      isLoading: false,
      errorMessage: null,

      validations,
    }
  },

  validations: function () {
    let items = validations
    if (!this.askOldPassword) {
      items = _.omit(items, ['old_password'])
    }
    return {
      fields: parseValidations(items)
    }
  },

  watch: {
    isProcessing(value) {
      this.isLoading = value
    }
  },

  computed: {
    ...mapGetters({
      user: 'authUser',
    }),
    isRequestError() {
      return !_.isEmpty(this.errorMessage)
    },

  },

  methods: {
    async changePassword() {
      this.$v.$touch()

      this.formSubmitting = true
      if (!this.isAnyInvalid) {
        this.isLoading = true
        this.errorMessage = false
        const payload = this.fields
        EventBus.$emit('profile.before.password-reset', payload)

        try {
          const data = await MainService.updateUser(this.user.id, payload)
          this.cleanInputs()
          this.$emit('success')
          EventBus.$emit('profile.password-reset')
        } catch (e) {
          this.errorMessage = MainService.getFirstErrorMessage(e)
          this.$emit('error', e)
          EventBus.$emit('profile.password-reset.error', e)
        } finally {
          this.isLoading = false
        }
      }
    },
    cleanInputs() {
      this.old_password = null
      this.password = null
      this.password_confirmation = null
    }

  }
}
