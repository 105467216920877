// @todo: complete implementation (use templify) of label, linkLabel, buttonTitle instead of using i18n
// @todo: share props etc. into a mixin
import ComplexComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'

import {space} from '../../../../lib/page'

const {key: loginSpace = 'page.login'} = space('user-login') || {}

const name = 're-login-plug'
const props = {
  email: {},
  hideRegistration: {
  },
  hideForgotPassword: {},
  translations: {
    type: [String, Object, Array],
    default: null
  },
  settings: {
    type: [String, Object, Array],
    default: null
  },
  loginTranslations: {
    type: [String, Object, Array],
    default: null
  },
  loginSettings: {
    type: [String, Object, Array],
    default: null
  },
  loginEvent: {
    type: String,
    default: 'user.login.plug'
  },
  label: {
    type: String,
    default: null
  },
  linkLabel: {
    type: String,
    default: null
  },
  buttonTitle: {
    type: String,
    default: null
  },

  messagePath: {
    type: String,
    default: null
  },
  labelPath: {
    type: String,
    default: null
  },
  registerLabelPath: {
    type: String,
    default: null
  },
  buttonPath: {
    type: String,
    default: null
  },
  otherLinkLabelPath: {
    type: String,
    default: null
  },
  otherLink: {
    type: String,
    default: null
  },

  modalClasses: {
    type: [String, Array, Object],
    default: 're-modal-sm'
  },
  noRedirect: {
    type: [Boolean, Number, String],
    default: true
  },
  redirectOnPreAuth: {
    type: [Boolean, Number, String],
    default: false
  },

  buttonClass: {
    type: [String, Array, Object],
    default: null
  },
  linkClass: {
    type: [String, Array, Object],
    default: null
  },
  hideLinkWhenFormShown: {
    type: [Boolean, Number, String],
    default: true
  },
  hideActions: {
    type: [Boolean, Number, String],
    default: false
  },
  disableActions: {},
  triggerSubmit: {},
  submitOnEnter: {}
}

export default {
  name,
  mixins: [ComplexComponentMixin, FormMixin],
  props,
  events: 'plug.user.login',
  data() {
    return {
      demandShown: false,
    }
  },
  computed: {
    canShowForm() {
      return this.demandShown || this.formShown
    },
    formShown() {
      const placer = {
        'form': '[[FORM]]'
      }
      return /\[\[FORM\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    isNonDemandPopup() {
      const placer = {
        'popup': '[[POPUP]]',
      }
      return /\[\[POPUP\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    formShownInPopup() {
      const placer = {
        'popup': '[[POPUP]]',
        'popup-demand-link': '[[POPUP]]',
        'popup-demand-button': '[[POPUP]]',
      }
      return /\[\[POPUP\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    noCloseButtonForPopup() {
      return this.$$fv(this.$attrs['noCloseButton'], this.$attrs['no-close-button'], this.isNonDemandPopup)
    },
    loginBoxTranslations() {
      return this.loginTranslations || this.$$t('loginTranslations', loginSpace)
    },
    loginBoxSettings() {
      return this.loginSettings ||
        this.$$s('loginSettings', this.$$rootSettings('page.login', loginSpace))
    },
    messageI18nPath() {
      return this.messagePath || 'this.login.label'
    },
    registerLinkLabelPath() {
      return this.registerLabelPath || 'register.link.label'
    },
    otherLinkUrl() {
      return this.otherLink
    },
    generalLinkLabelPath() {
      return this.otherLinklabelPath || 'other.link.label'
    },
    linkLabelPath() {
      return this.labelPath || 'login.link.label'
    },
    buttonTitlePath() {
      return this.labelPath || 'login.button.title'
    },
    linkClasses() {
      return this.linkClass || this.$$s('register.link.class', this.$$s('link.class'))
    },
    buttonClasses() {
      return this.buttonClass || this.$$s('register.button.class', this.$$s('button.class'))
    },
    hideLink() {
      return this.hideLinkWhenFormShown && this.demandShown
    }
  },
  created() {
    if (this.isNonDemandPopup) {
      this.demandShown = true
    }
  },
  methods: {
    showDemand() {
      this.$emit('demand-init')
      this.emit('demand.init')
      this.demandShown = true
    },
    hideDemand() {
      this.$emit('demand-end')
      this.emit('demand.end')
      this.demandShown = false
    },
    onSuccess(...args) {
      this.hideDemand()
      // this.$$debugLog({'re-login-plug-success': args})
      this.$emit('success', ...args)
      this.emit('success', ...args)
    },
    onError(...args) {
      // this.$$debugLog({'re-login-plug-error': args})
      this.$emit('error', ...args)
      this.emit('error', ...args)
    }
  }

}
