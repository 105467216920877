<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title You've Been Invited
      .b-central__subtitle Please enter your new password

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        .row
          .col-6
            form-group(
              :validator="$v.firstName"
              label="First name:"
              attribute="first name"
              v-slot="props"
            )
              input(
                v-model="$v.firstName.$model"
                :class="props.classes"
                placeholder="Your first name"
                @input="input"
                name="first_name"
              )
          .col-6
            form-group(
              :validator="$v.lastName"
              label="Last name:"
              attribute="last name"
              v-slot="props"
            )
              input(
                v-model="$v.lastName.$model"
                :class="props.classes"
                placeholder="Your last name"
                @input="input"
                name="last_name"
              )

        form-group(
          :validator="$v.password"
          label="Password:"
          attribute="password"
          v-slot="props"
          :messages="{ backendError }"
        )
          input(
            v-model="$v.password.$model"
            :class="props.classes"
            type="password"
            placeholder="Your new password"
            @input="input"
          )

        form-group(
          :validator="$v.passwordConfirmation"
          label="Confirm password:"
          attribute="password confirmation"
          v-slot="props"
          :messages="passwordConfirmationMessages"
        )
          input(
            v-model="$v.passwordConfirmation.$model"
            :class="props.classes"
            type="password"
            placeholder="Confirm your new password"
            @input="input"
          )

      .b-central__footer.mt-5.mb-4
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Continue

      .b-central__message.text-danger(v-if="backendError") {{ backendError }}

</template>

<script>
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    backendError: null,
    passwordConfirmationMessages: { 'sameAsPassword': 'Password doesn\'t match.' },
    submitting: false,
    invitationLinkApproved: null,
  }),

  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    password: {
      required,
      backendError() {
        return !this.backendError
      },
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
    invitationLinkApproved: {
      checked: value => value === true
    },
  },

  async created() {
    try {
      const { data } = await axios.post(`${this.config.baseUri}/api/onboarding/invitation/check`, {
        email: this.$route.query.email,
        token: this.$route.query.token,
      })

      this.invitationLinkApproved = true
      this.firstName = data.first_name
      this.lastName = data.last_name
    } catch (e) {
      this.invitationLinkApproved = false
      this.$v.invitationLinkApproved.$touch()
      this.backendError = getFirstErrorMessage(e)
    }
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true

        await axios.post(`${this.config.baseUri}/api/onboarding/invitation/register`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
          first_name: this.firstName,
          last_name: this.lastName,
          password: this.password,
        })

        this.$router.push({ name: 'onboarding-sign-in' })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    },
  },
}
</script>
