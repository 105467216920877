/*global _ */
import {get$} from '../lib/html'
import ScrollMixins from './scroller'

export default {
  mixins: [ScrollMixins],
  data() {
    return {
      formSubmitting: false,
      formReadyForSubmit: false,
    }
  },
  computed: {
    isAnyInvalid() {
      return this.$v.$invalid
    }
  },
  methods: {
    async $validate(v) {
      v = v  || this.$v
      v.$touch()
      await this.$nextTick()
      return new Promise((resolve) => {
        const unwatch = this.$watch(
          () => v.$pending,
          (isPending) => {
            if (!isPending) {
              resolve(!v.$invalid)
              if (unwatch) {
                unwatch()
              }
            }
          },
          {
            immediate: true
          }
        )
      })
    },
    findAnyInvalid() {
      return this.$v.$invalid
    },
    getFirstValidationError() {
      return get$('.form-group.is-invalid')
    },
    scrollToFirstErrorElement(toView) {
      this.$nextTick(() => {
        const elm = this.getFirstValidationError()

        if (elm) {
          if (toView) {
            elm[0].scrollIntoView()
          } else {
            this.scrollTo(elm)
          }
        }
      })
    },
    submittingInvalid(fieldName) {
      return this.formSubmitting && this.dirtyInvalid(fieldName)
    },
    submittingInvalidRule(fieldName, rule) {
      return this.formSubmitting && this.dirtyInvalidRule(fieldName, rule)
    },
    invalidGroup(group) {
      return _.get(this.$v, group + '.$invalid')
    },
    dirty(fieldName) {
      return _.get(this.$v, fieldName + '.$dirty')
    },
    invalid(fieldName) {
      return _.get(this.$v, fieldName + '.$invalid')
    },
    dirtyInvalid(fieldName) {
      return this.dirty(fieldName) && this.invalid(fieldName)
    },
    invalidRule(fieldName, rule) {
      return !_.get(this.$v, fieldName + '.' + rule)
    },
    dirtyInvalidRule(fieldName, rule) {
      return this.dirty(fieldName) && this.invalidRule(fieldName, rule)
    },
    anyInvalids(fieldNames) {
      const fieldsNameList = fieldNames || Object.keys(this.fields || {})
      return _.some(fieldsNameList, fieldName => this.invalid(fieldName)) || false
    },
    getAllErrorMessages($v, $validations, force) {
      $v = $v || this.$v || this.v || {}
      $validations = $validations || this.validations || {}
      return getAllErrorMessages($v, $validations, force)
    }
  }
}

export const getAllErrorMessages = ($v, $validations, force) => {
  return _.filter(_.flatMapDeep($validations, (v) => {
    const name = v.name
    return _.flatMap(v.items || {}, (i, k) => {
      const isValid = force ? false : _.get($v, name + '.' + k)
      return isValid === false ? _.get(i, 'error') : null
    })
  }))
}
