import {getFirstErrorMessage} from "../../../../../services/base";

const defaultMessages = {
  NODATA: 'No picture found in your account.',
  NODATAINFOLDER: 'No picture found in this folder.'
}

export default {
  data() {
    return {
      connectorErrorMessage: null,
      selectorErrorMessage: null,
    }
  },
  methods: {
    resetErrors() {
      this.connectorErrorMessage = null
      this.selectorErrorMessage = null
    },
    getErrorMessage(type) {
      let message
      if (type instanceof Error) {
        message = getFirstErrorMessage(type)
      } else {
        const provider = this.provider
        message = this.$$t(
          `providers.${provider}.errors.${type}`,
          defaultMessages[type] || type
        )
      }
      return message
    },
    showConnectorErrorMessage(type) {
      this.connectorErrorMessage = this.getErrorMessage(type)
    },
    showSelectorErrorMessage(type) {
      this.selectorErrorMessage = this.getErrorMessage(type)
    }
  }
}
