import props from '../../../props/component'

export default {
  ...props,
  // value (added from mixin) - page index (starts at 0)
  pages: {}, // array of items - pages[array] or use `total` -  priority to `pages` if both `pages` and `total` are passed
  total: {}, // total number of pages positive integer - priority to `pages` if both `pages` and `total` are passed

  paginator: { // optional custom Paginator or paginator options - stored as `pager` instance property
    default() {
      return this.$options.$$s('paginator')
    }
  },

  reducer: {}, // optional function to create visible page items including gaps
  navigator: {}, // optional function called when navigated which overwrites default navigation

  messageParser: {}, // optional function to create message
  pageParser: {}, // optional function to create page number text/element

  queryVar: { // page number derived from query string
    default() {
      return this.$options.$$s('queryVar', 'page')
    }
  },
  nameSpace: { // id for the paginator (skipped if paginator is provided)
    default() {
      return this.$options.$$s('nameSpace', null)
    }
  },
  persistSpace: { // key for persistent storage. Disabled persistent storage if null
    default() {
      return this.$options.$$s('persistSpace', null)
    }
  },

  hidePrevNext: {
    default() {
      return this.$options.$$s('hidePrevNext') === true
    }
  },
  autoHidePrevNext: {
    default() {
      return this.$options.$$s('hidePrevNext.auto') === true
    }
  },
  autoHidePrev: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('hidePrevNext.auto.prev')
    }
  },
  autoHideNext: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('hidePrevNext.auto.next')
    }
  },
  message: { // template message
    type: [String],
    default() {
      return this.$options.$$t('message', null)
    }
  },
  showMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showMessage', false)
    }
  },
}
