/*global _ */
import baseMixins from './mixins/base'
import {serviceCall} from './mixins/services'

export class Base {

  constructor(data, vm = {}) {
    this.setData(data)
    this.initVM(vm)
  }

  static async staticServiceCall(...args) {
    return await serviceCall(...args)
  }

  setData(value) {
    // @deprecated this.data use: this.$$data instead
    this.data = value || {}
    this.$$data = _.cloneDeep(value || {})
    return this
  }
}

Object.assign(Base.prototype, ...baseMixins)

export default Base
