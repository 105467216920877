export const OPTIONS_PROP = 'advancedSettings'
const localProps = {
  translations: {},
  settings: {},
  [OPTIONS_PROP]: {},

  formId: {},
  formClass: {
    type: [String, Array, Object],
    default: ''
  },
  title: {
    type: String,
    default: null,
  },
  subTitle: {
    type: String,
    default: null,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  successMessage: {
    type: String,
    default: null,
  },
  noActions: {
    type: Boolean,
    default: false
  },
  submitButtonTitle: {
    type: String,
    default: 'Submit',
  },
  disableSubmitButton: {
    type: Boolean,
    default: false,
  },
  noResetButton: {
    type: Boolean,
    default: false
  },
  resetButtonTitle: {
    type: String,
    default: 'Reset',
  },
  disableResetButton: {
    type: Boolean,
    default: false
  },
  isSuccess: {
    type: Boolean,
    default: false
  },
  showSuccessPopup: {},
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return true
    }
  },
  autocomplete: {
    type: [Boolean, Number, String],
    default() {
      return true
    }
  },
  isProcessingExternally: {}
}

export const props = {
  ...localProps
}

export default props
