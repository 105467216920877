export default {
  name: 're-tab-container',

  props: {
    titleClass: { default: ''},
    tabContainerClass: { default: ''},
    value: {}
  },

  data() {
    return {
      tabs: [],
      tab: '',
    }
  },
  computed: {
    activeTab: {
      get() {
        if (this.tab) {
          return this.tab
        }
        for(let tab of this.tabs) {
          if (tab.active) {
            return this.tab = tab.tabName
          }
        }
        return ''
      },
      set(value) {
        this.tab = value
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.activeTab = val
        }
      },
      immediate: true
    },
    activeTab: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true
    }
  },
  created() {
      this.tabs = this.$children
  },
  methods: {
    hasVisibleTabs() {
      return _.some(this.tabs, item => item.isVisible && item.isTabItem)
    }
  }

}
