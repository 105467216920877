import OnboardingLayout from '../layouts/OnboardingLayout'
import OnboardingStepsLayout from '../layouts/OnboardingStepsLayout'

import Landing from '../views/Landing'
import Password from '../views/Password'
import Register from '../views/Register'
import Social from '../views/Social'
import EmailVerification from '../views/EmailVerification'
import PhoneVerification from '../views/PhoneVerification'
import Role from '../views/Role'
import GetStarted from '../views/GetStarted'
import SignIn from '../views/SignIn'
import InstanceSelect from '../views/InstanceSelect'
import PasswordForgot from '../views/PasswordForgot'
import PasswordVerify from '../views/PasswordVerify'
import PasswordReset from '../views/PasswordReset'
import Invitation from '../views/Invitation'

import Welcome from '../views/Welcome'
import StepOne from '../views/StepOne'
import StepTwo from '../views/StepTwo'
import StepThree from '../views/StepThree'
import StepFour from '../views/StepFour'
import StepFive from '../views/StepFive'
import StepSix from '../views/StepSix'
import StepSeven from '../views/StepSeven'
import QuickstartGuide from '../views/QuickstartGuide'

export default [
  { path: '/', redirect: { name: 'onboarding-sign-in' } },
  { path: '/register', name: 'onboarding-social', component: Social },
  { path: '/landing', name: 'onboarding', component: Landing },
  { path: '/password', name: 'onboarding-password', component: Password },
  { path: '/contact', name: 'onboarding-register', component: Register },
  { path: '/email-verification', name: 'onboarding-email-verification', component: EmailVerification },
  { path: '/phone-verification', name: 'onboarding-phone-verification', component: PhoneVerification },
  { path: '/role', name: 'onboarding-role', component: Role },
  { path: '/get-started', name: 'onboarding-get-started', component: GetStarted },
  { path: '/login', name: 'onboarding-sign-in', component: SignIn, meta: { signIn: true } },
  { path: '/instance', name: 'onboarding-instance-select', component: InstanceSelect, meta: { signIn: true } },
  { path: '/forgot-password', name: 'onboarding-password-forgot', component: PasswordForgot, meta: { signIn: true } },
  { path: '/password-verification', name: 'onboarding-password-verify', component: PasswordVerify, meta: { signIn: true } },
  { path: '/reset-password', name: 'onboarding-password-reset', component: PasswordReset, meta: { signIn: true } },
  { path: '/invitation', name: 'onboarding-invitation', component: Invitation },

  { path: '/onboarding/welcome', name: 'onboarding-welcome', component: Welcome, meta: { layout: OnboardingLayout } },
  { path: '/onboarding/step-one', name: 'onboarding-step-one', component: StepOne, meta: { layout: OnboardingStepsLayout, step: 1 } },
  { path: '/onboarding/step-two', name: 'onboarding-step-two', component: StepTwo, meta: { layout: OnboardingStepsLayout, step: 2 } },
  { path: '/onboarding/step-three', name: 'onboarding-step-three', component: StepThree, meta: { layout: OnboardingStepsLayout, step: 3 } },
  { path: '/onboarding/step-four', name: 'onboarding-step-four', component: StepFour, meta: { layout: OnboardingStepsLayout, step: 4 } },
  { path: '/onboarding/step-five', name: 'onboarding-step-five', component: StepFive, meta: { layout: OnboardingStepsLayout, step: 5 } },
  { path: '/onboarding/step-six', name: 'onboarding-step-six', component: StepSix, meta: { layout: OnboardingStepsLayout, step: 6 } },
  { path: '/onboarding/step-seven', name: 'onboarding-step-seven', component: StepSeven, meta: { layout: OnboardingStepsLayout, step: 7 } },
  { path: '/onboarding/guide', name: 'onboarding-guide', component: QuickstartGuide, meta: { layout: OnboardingLayout } },

]
