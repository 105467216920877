export const PLAYCARDS_BONUS_PARSE_MAP = {
  $appliedPlaycards: {
    value: '{[action_options.applied_playcard_numbers]}',
  },
  $unappliedPlaycards: {
    value: '{[action_options.unapplied_playcard_numbers]}',
    formatEach: [['maskLeft', ['XXXX0000']]],
    format: [
      ['join', [', ']],
      [' ending in {{value}}', [], true]
    ],
    skipNil: true,
    defaultValue: '',
  },
  $playcards: {
    value: '{[action_options.playcards]}',
    formatEach: [['get', ['number']], ['maskLeft', ['XXXX0000']]],
    format: [
      ['join', [', ']],
      [' ending in {{value}}', [], true]
    ],
    skipNil: true,
    defaultValue: '',
  },
  $playcardCount: {
    value: '{[action_options.playcards]}',
    format: 'size',
  },
  $cardName: {
    value: 'playcard|playcards',
    defaultValue: 'playcard|playcards',
    mergeData: {
      count: {
        value: '{[action_options.playcards.length]}'
      }
    },
    parseParams: true,
    format: [['$$tc', ['{{value}}', '{[count]}']]]
  },
  $failedCardName: {
    value: 'playcard|playcards',
    defaultValue: 'playcard|playcards',
    mergeData: {
      count: {
        value: '{[action_options.unapplied_playcard_numbers.length]}'
      }
    },
    parseParams: true,
    format: [['$$tc', ['{{value}}', '{[count]}']]]
  },
}

export const PLAYCARDS_BONUS_MESSAGES = {
  'playcards_bonus': `The promotion has been applied to your {{$cardName}}{{$playcards}}.
      {{br}}
      {{$expiringIn}}`,
}

export const PLAYCARDS_BONUS_ERROR_MESSAGES = {
  'failingLocation': `Failed to apply the promotion to your {{$failedCardName}}{{$unappliedPlaycards}}.
    {{br}}
    The {{$failingLocationsLabel}} {{$failingLocations}} {{$failingLocationsBe}} under maintenance.
    {{br}}
    Please try again after some time.`,
}
