import base from '../base/props'
import form from '../../../../../../props/form'

const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', '')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  error: {
    type: String,
  },
  modalClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('modalClasses', null)
    }
  },
}
export const props = {
  ...base,
  ...form,
  ...localProps
}
export default props
