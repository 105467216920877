/*global _ */
import {getErrorMessage} from '~/lib/axios'
import {fv} from '../../../../../../lib/utils'
import contextProps from '../../../../../../props/context'

import ComponentMixin from '../../../../../../mixins/components/complex-reactive'
import ProfileMixin from '../../../../../../mixins/profile/base'
import FormMixin from '../../../../../../mixins/form'
import ResponsiveMixin from '../../../../../../mixins/responsive'

export const props = {
  ...contextProps,
  value: {},
  options: {},
  settings: {},
  translations: {},
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },
  showFormNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormNotice', true)
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Submit')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },
  emailButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('emailButtonTitle', 'Email')
    }
  },
  emailButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('emailButtonClasses', null)
    }
  },
  sendEmailButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('sendEmailButtonTitle', 'Send')
    }
  },
  sendEmailButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('sendEmailButtonClasses', null)
    }
  },
  printButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('printButtonTitle', 'Print')
    }
  },
  printButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('printButtonClasses', null)
    }
  },
  backButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('backButtonTitle', 'Try again')
    }
  },
  backButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('backButtonClasses', null)
    }
  },
  expirationMessage: {
    type: String,
    default() {
      return this.$options.$$t('expirationMessage', 'Earned credits will expire in {{expiration}} days.')
    }
  },
  successMessage: {
    type: String,
    default() {
      return this.$options.$$t('successMessage', 'You have earned {{rewardClaimTitle}}. {{br}}{{expirationMessage}}')
    }
  },
  sendEmailMessage: {
    type: String,
    default() {
      return this.$options.$$t('sendEmailMessage', 'Enter your email address.')
    }
  },
  sendEmailSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('sendEmailMessage', 'The email has been sent to {{email}}.')
    }
  },
}

export const mixins = [
  ComponentMixin,
  ProfileMixin,
  FormMixin,
  ResponsiveMixin,
]

export default {
  mixins,
  props,
  events: 'user.profile.loyalty.claim',
  data() {
    return {
      state: 'init', // success // error // email
      order: null,
      errorMessage: '',
      error: null,
      sendOrderToEmail:  null,
      emailSent: false
    }
  },
  computed: {
    initState() {
      return this.state === 'init'
    },
    successState() {
      return this.state === 'success'
    },
    errorState() {
      return this.state === 'error'
    },
    emailState() {
      return this.state === 'email'
    },
    reward() {
      return this.value || {}
    },
    rewardTitle() {
      return this.reward.title
    },
    rewardClaimTitle() {
      return this.reward.claimMessageTitle
    },
    formattedNotice() {
      return this.buildMessages(this.formNotice)
    },
    formattedSuccessMessage() {
      return this.buildMessages(this.successMessage)
    },
    formattedErrorMessage() {
      const error = this.error
      const message = getErrorMessage(error)
      return this.buildMessages(message)
    },
    formatttedSendEmailMessage() {
      return this.buildMessages(this.sendEmailMessage)
    },
    formatttedSendEmailSuccessMessage() {
      return this.buildMessages(this.sendEmailSuccessMessage, {email: this.sendOrderToEmail})
    },
    selectedLoyaltyLocationId() {
      let id = _.get(this.storedProfileData, 'loyalty.location_id')
      if (!id) {
        id = this.cartLocationId
        if (!id) {
          id = _.get(this.user, 'meta.preferred_location_id')
        }
        if (!id) {
          id = this.currentLocationId
        }
        if (id) {
          this.updateProfileDataItem({'loyalty.location_id': id})
          this.updateProfileDataItem({'location_id': id})
        }
      }
      return id
    }
  },
  created() {
    this.sendOrderToEmail = this.user.email
  },
  methods: {
    async init() {
      this.state = 'init'
      this.$v.$reset()
    },
    async reInit() {
      await this.init()
    },
    async emailOrder() {
      if (this.order && this.sendOrderToEmail) {
        this.emailSent = false
        const payload = {
          id: this.order.id,
          email: this.sendOrderToEmail
        }

        this.startPromising()
        try {
          const {data} = await this.invokeService('user.order.notify', payload)
          this.emailSent = true
        } catch (e) {

        }
        this.stopPromising()
      }
    },
    async initEmailOrder() {
      this.state = 'email'
    },
    async submitReward() {
      const payload = this.getOrderPayload()
      const config = {
        headers: {
          'X-Location': this.selectedLoyaltyLocationId
        }
      }
      await this.placeClaimOrder(payload, {config})
    },
    getOrderPayload() {
      const reward = this.reward
      const item = {
        product_id: reward.product.id,
        count: 1,
        options: {
          location_id: this.selectedLoyaltyLocationId
        }
      }
      const items = [item]
      return {items}
    },

    async placeClaimOrder(payload, options) {
      this.$v.$touch()
      if (!this.isAnyInvalid) {
        this.startPromising()
        try {
          const {data} = await this.invokeService('shop.order', payload, options)
          this.order = data
          this.state = 'success'
          this.emitSlim('success', data)
        } catch (e) {
          this.error = e
          this.state = 'error'
        }
        this.stopPromising()
      }
    },
    buildMessages(template, data) {
      data = data || {}
      data.reward = this.reward
      data.rewardTitle = this.rewardTitle
      data.rewardClaimTitle = this.rewardClaimTitle
      data.expirationMessage = this.reward.expiration ? this.parseReactiveValues(this.expirationMessage, {...this.reward}): ''

      data.this = fv(data.this, this)
      data.br = fv(data.br, '</br>')
      const messages = _.filter(_.values(this.parseReactiveValues(_.castArray(template), data)))
      return '<span>' + _.castArray(messages).join('</span><span>') + '</span>'
    },
  }
}
