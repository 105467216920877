/*global _ */
import {notNils} from '../../../lib/formatters'

export default {
  name: 're-image',
  props: {
    to: {},
    url: {},
    title: {},
    label: {},
    value: {},
    src: {},
    alt: {},
    classes: {},
  },
  data() {
    return {
    }
  },

  computed: {
    imageSrc() {
      return this.value || this.src
    },
    hasLink() {
      return this.to || this.url
    },
    linkAttrs() {
      const props = notNils(this.$props)
      const attrs = notNils(this.$attrs)
      const merged = _.merge({}, props, attrs)
      return _.omit(merged, ['src', 'classes', 'alt'])
    }
  },

  created() {

  },
  methods: {
  }
}
