/*global _ */
import {name, mixins, props, data} from './config'

export default {
  name,
  mixins,
  props,
  data() {
    return {
      ...data,
      productName: this.getSubscriptionProductName()
    }
  },
  methods: {
    getSubscriptionProductName() {
      let productName = this.product
      const location = this.currentLocationName
      if (_.isNil(productName) || productName === '') {
        productName = this.$$fs(
          this.$$s(`${location}.product.subscription.default`),
          this.$$s('product.subscription.default')
        )
      }
      return productName
    }
  }
}
