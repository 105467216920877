import createPersistedState from 'vuex-persistedstate'

const EventBus = window.VueEventBus
import {Duration, getNow} from '../../lib/dates'
import * as types from '../mutation-types/timer'

// set this true to namespace all getters, mutations, actions etc.
const namespaced = true
export const namespace = 'Timer'
const rootNamespace = 'REUtils'
const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

const location = window.addiesaas.location || {}
const locationId = location.id || ''
const locationIdKey = locationId

let updater = null

// Set the name of the hidden property and the change event for visibility
let isHidden = false, hidden, visibilityChange
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}
function handleVisibilityChange() {
  isHidden = document.visibilityState !== 'visible'
}
if (typeof document.addEventListener && hidden) {
  document.addEventListener(visibilityChange, handleVisibilityChange, false);
}

// state
const state = {
  duration: null,
  durationSeconds: null,
  startTime: null,
  startValue: null,
  endTime: null,
  endValue: null,
  running: false,
  elapsed: null,
  remaining: null,
  updater: null,
  timeout: null,
  paused: false
}

function interval(func, wait, options) {
  let cancelled = false
  const callback = function (w, options) {
    return function () {
      if (!cancelled) {
        setTimeout(callback, w)
        try {
          func.call(null)
        } catch (e) {
          cancelled = true
          throw e.toString()
        }
      }
    }
  }(wait, options)

  const ret = {
    start() {
      callback()
      return ret
    },
    stop() {
      cancelled = true
      return ret
    },
    cancel() {
      cancelled = true
      return ret
    }
  }
  return ret
}

// @todo: Replace with Countdown class
const helpers = {
  startTimer(state, duration = null) {
    const store = this
    const now = getNow()
    // if duration is a number it is expected to be in seconds and hence setting it to milliseconds as per DateTime.plus format
    if (duration instanceof Duration) {
      state.durationSeconds = duration.valueOf() / 1000
    } else if (duration instanceof Object) {
      state.durationSeconds = Duration.fromObject(duration).valueOf() / 1000
    } else if (_.isNil(duration)) {
      duration = state.duration
    } else {
      state.durationSeconds = duration
      duration *= 1000
    }
    const end = now.plus(duration)
    state.startTime = now
    state.startValue = now.valueOf()
    state.endTime = end
    state.endValue = end.valueOf()
    state.duration = duration
    state.running = true
    state.timeout = false
    state.paused = false
    updater = state.updater = interval(() => {
        if  (!isHidden) {
          helpers.calculate.call(store, state);
        }

    }, 1000).start()
    EventBus.$emit('timer.started', state)
  },
  stopTimer(state) {
    const store = this
    if (state.updater && state.updater.cancel) {
      state.updater.cancel()
    }
    if (updater && updater.cancel) {
      updater.cancel()
    }
    state.running = false
    state.startTime = null
    state.startValue = null
    state.endTime = null
    state.endValue = null
    state.durationSeconds = null
    state.duration = null
    state.updater = updater = null
    state.paused = false
    EventBus.$emit('timer.stopped', state)
  },
  calculate(state) {
    const store = this
    if (state.running && !state.paused) {
      const nowDate = getNow()
      const now = nowDate.valueOf()

      const remaining = Math.max(0, (state.endValue - now) / 1000)
      store.commit(absoluteNamespace + types.SET_REMAINING, remaining)
      if (remaining <= 0) {
        store.commit(absoluteNamespace + types.STOP_TIMER)
        store.commit(absoluteNamespace + types.SET_TIMEOUT, true)
        EventBus.$emit('timer.timeout', state)
      } else {
        const elapsed = ((now - state.startValue) / 1000)
        store.commit(absoluteNamespace + types.SET_ELAPSED, elapsed)
        EventBus.$emit('timer.tick', remaining, elapsed, state)
      }
    }
  }
}

// mutations
const mutations = {

  [types.START_TIMER](state, duration) {
    helpers.startTimer.call(this, state, duration)
  },
  [types.SET_TIMEOUT](state, value) {
    state.timeout = !!value
    if (value) {
      EventBus.$emit('timer.timeout', state)
    }
  },

  [types.SET_ELAPSED](state, value) {
    state.elapsed = value
  },
  [types.SET_REMAINING](state, value) {
    state.remaining = value
  },

  [types.PAUSE_TIMER](state) {
    state.paused = true
  },
  [types.UNPAUSE_TIMER](state) {
    state.paused = false
  },
  [types.STOP_TIMER](state) {
    helpers.stopTimer.call(this, state)
  },
  [types.RESET_TIMER](state) {
    helpers.startTimer.call(this, state)
  },
  [types.RESET_TIMEOUT](state) {
    state.timeout = null
  },
  [types.RESUME_TIMER](state) {
    if (!state.updater || _.isEmpty(state.updater)) {
      state.updater = interval(() => {
        if  (!isHidden) {
          helpers.calculate.call(this, state);
        }

      }, 1000).start()
    }
  },
  [types.RESTART_TIMER](state, duration) {
    helpers.startTimer.call(this, state, duration)
  },
}

const actions = {
  forceTimeout({commit}) {
    commit(types.STOP_TIMER)
    commit(types.SET_TIMEOUT, true)
  },
  startTimer({commit}, duration) {
    commit(types.START_TIMER, duration)
  },
  pauseTimer({commit}) {
    commit(types.PAUSE_TIMER)
  },
  unpauseTimer({commit}) {
    commit(types.UNPAUSE_TIMER)
  },
  stopTimer({commit}) {
    commit(types.STOP_TIMER)
  },
  resetTimeout({commit}) {
    commit(types.RESET_TIMEOUT)
  },
  resetTimer({commit}) {
    commit(types.RESET_TIMER)
  },
  resumeTimer({commit}) {
    commit(types.RESUME_TIMER)
  },
  restartTimer({commit}, duration) {
    commit(types.RESTART_TIMER, duration)
  },
  setTimerElapsed({commit}, duration) {
    commit(types.SET_ELAPSED, duration)
  },
  setTimerRemaining({commit}, duration) {
    commit(types.SET_REMAINING, duration)
  },
}

// getters
const getters = {
  timerTimedout: state => state.timeout,
  timerUpdater: state => state.updater,
  timerIsRunning: state => state.running,
  timerIsPaused: state => state.paused,
  timerDuration: state => state.duration,
  timerStartTime: state => state.startTime,
  timerEndTime: state => state.endTime,
  timerRemaining: state => state.remaining,
  timerElapsed: state => state.elapsed,
  timerObject: state => state
}

const plugins = [
  createPersistedState({
    paths: [
      `${rootNamespace}.${namespace}`
    ],
    key: absoluteNamespace + locationIdKey,
  })
]

const timer = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}
export default timer
