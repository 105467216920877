<template lang="pug">
.b-camera(v-show="isLoaded")
  .b-camera__video
    video(poster="data:image/gif,AAAA" ref="scanner")
  .b-camera__overlay
    .b-camera__overlay-clip(:class="{ 'b-camera__overlay-clip--path': isScanning }")
    template(v-if="isScanning")
      .b-camera__overlay-top-left
      .b-camera__overlay-top-right
      .b-camera__overlay-bottom-right
      .b-camera__overlay-bottom-left
  .b-camera__text-above(v-if="isScanning") Focus the camera on your QR code
  .b-camera__laser(v-if="isScanning")
  .b-camera__zoom.text-center
    .b-camera__zoom-btn
      img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/camera-zoom-minus.svg`")
    .b-camera__zoom-slider
      b-form-slider(v-model="zoom" :debounce="100" :min="zoomMin" :max="zoomMax" @slide-stop="zoomChange")
    .b-camera__zoom-btn
      img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/camera-zoom-plus.svg`")

</template>

<script>
import { BrowserMultiFormatReader, Exception } from '@zxing/library'
import 'image-capture'
import bFormSlider from 'vue-bootstrap-slider/es/form-slider'

export default {
  name: 'camera-scanner',

  components: {
    bFormSlider,
  },

  props: {
    delayBetweenScans: {
      type: Number,
      default: 500,
    },
  },

  data: () => ({
    config: window.addiesaas.config,
    isLoaded: false,
    isScanning: false,
    codeReader: null,
    videoInputDevices: null,
    videoInputDeviceIndex: null,
    mediaStream: null,
    videoTrack: null,
    torchAvailable: null,
    flashlight: false,
    zoomAvailable: false,
    zoom: 0,
    zoomMin: 0,
    zoomMax: 0,
  }),

  watch: {
    flashlight() {
      this.$emit('flashlight', this.flashlight)
    }
  },

  async mounted() {
    this.$refs.scanner.oncanplay = event => {
      this.isLoaded = true
      this.$emit('loaded')
    };

    const isMediaStreamAPISupported =
      navigator &&
      navigator.mediaDevices &&
      'enumerateDevices' in navigator.mediaDevices

    if (!isMediaStreamAPISupported) {
      throw new Exception('Media Stream API is not supported')
    }

    this.codeReader = new BrowserMultiFormatReader()
    this.codeReader.timeBetweenScansMillis = this.delayBetweenScans

    this.videoInputDevices = await this.codeReader.getVideoInputDevices()

    console.log('videoInputDevices', this.videoInputDevices)

    if (!this.videoInputDevices.length) {
      throw new Exception('No video input device available')
    }

    this.videoInputDeviceIndex = await this.environmentFacingDeviceIndex(this.videoInputDevices)

    this.start()
  },

  beforeDestroy() {
    this.codeReader.reset()
  },

  methods: {
    async start() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => {
          track.stop()
        })
      }

      await this.codeReader.decodeFromVideoDevice(
        this.videoInputDevices[this.videoInputDeviceIndex].deviceId,
        this.$refs.scanner,
        result => {
          if (result) {
            this.$emit('decode', result.text)
          }
        }
      )

      this.mediaStream = this.$refs.scanner.srcObject
      console.log('mediaStream', this.mediaStream)

      this.videoTrack = this.mediaStream.getVideoTracks()[0]
      console.log('videoTrack', this.videoTrack)

      const capabilities = this.videoTrack.getCapabilities()
      console.log('capabilities', capabilities)
      
      this.zoomAvailable = 'zoom' in capabilities
      this.torchAvailable = 'torch' in capabilities

      this.zoomMin = this.zoomAvailable ? capabilities.zoom.min : 0
      this.zoomMax = this.zoomAvailable ? capabilities.zoom.max : 0
      this.zoom = this.zoomMin

      this.flashlight = false

      this.isScanning = true
    },
    pause() {
      this.codeReader.stopContinuousDecode()
      this.isScanning = false
    },
    resume() {
      if (!this.isScanning) {
        this.start()
      }
    },
    rotate() {
      this.videoInputDeviceIndex = (this.videoInputDeviceIndex + 1) % this.videoInputDevices.length
      this.start()
    },
    switchFlashlight() {
      if (this.torchAvailable) {
        this.videoTrack.applyConstraints({ advanced: [{ torch: !this.flashlight }] })
        this.flashlight = !this.flashlight
      }
    },
    zoomChange($event) {
      if (this.zoomAvailable) {
        console.log('zoom', this.zoom)
        this.videoTrack.applyConstraints({ advanced: [{ zoom: this.zoom }] })
      }
    },
  
    async environmentFacingDeviceIndex(videoInputDevices) {
      const allMediaDevices = await navigator.mediaDevices.enumerateDevices()

      console.log('allMediaDevices', allMediaDevices)

      const environmentFacingDevice = allMediaDevices.filter(x => x.kind == 'videoinput')
        .find(x => {
          const capabilities = x.getCapabilities()

          console.log('capabilities', capabilities)

          return 'facingMode' in capabilities && capabilities.facingMode.includes('environment')
        })

      if (environmentFacingDevice) {
        console.log('environmentFacingDevice', environmentFacingDevice)
        return videoInputDevices.findIndex(x => x.deviceId == environmentFacingDevice.deviceId)
      }

      return 0
    },
  },
}
</script>

<style lang="scss">
$slider-line-height: 16px;
$slider-border-radius: 0;
$slider-horizontal-width: 160px;
$slider-primary: #8f8cf1;

@import "bootstrap-slider/src/sass/bootstrap-slider.scss";

.slider.slider-horizontal .slider-track {
  height: 2px;
  margin-top: -1px;
}
.slider-selection {
  background-color: $slider-primary;
  background-image: none;
  box-shadow: none;
}
.slider-track {
  background-color: #d8d8d8;
  background-image: none;
  box-shadow: none;
}

.b-camera {
  position: absolute;
  top: 0;
  height: 100%;
  &__video {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__overlay {
    &-clip {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(55, 52, 81, 0.7);
      &--path {
        clip-path: polygon(
          0% 0%,
          0% 100%,
          21.875% 100%,
          21.875% calc(50% - 28.125vw),
          78.125% calc(50% - 28.125vw),
          78.125% calc(50% + 28.125vw),
          21.875% calc(50% + 28.125vw),
          21.875% 100%,
          100% 100%,
          100% 0%
        );
      }
    }
    &-top-left,
    &-top-right,
    &-bottom-right,
    &-bottom-left {
      width: calc(6.25vw);
      height: calc(6.25vw);
      border: 2px solid white;
      position: absolute;
      display: inline-block;
      z-index: 1;
    }
    &-top-left,
    &-top-right {
      border-bottom: none;
      top: calc(50% - 28.125vw - 2px);
    }
    &-top-left,
    &-bottom-left {
      border-right: none;
      left: calc(21.875% - 1px);
    }
    &-top-right,
    &-bottom-right {
      border-left: none;
      right: calc(21.875% - 1px);
    }
    &-bottom-right,
    &-bottom-left {
      border-top: none;
      bottom: calc(50% - 28.125vw - 0px);
    }
  }
  &__laser {
    width: 56.25%;
    margin-left: 21.875%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: calc(50% - 28.125vw - 1px);
    z-index: 2;
    box-shadow: 0 0 4px red;
    animation: scanning 2s infinite;
  }
  &__text-above {
    position: absolute;
    top: calc(50% - 30vh);
    width: 100%;
    text-align: center;
  }
  &__zoom {
    position: absolute;
    top: calc(50% + 23vh);
    width: 100%;
    &-btn {
      display: inline-block;
    }
    &-slider {
      display: inline-block;
      margin: 0 15px;
    }
  }
}
@keyframes scanning {
  50% {
    transform: translateY(calc(56.25vw - 1px));
  }
}
</style>
