import pageProps from '../../../../props/profile/page-props'
import tableProps from '../../../../props/profile/table-props'

import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  ...pageProps,
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading orders...')
    }
  },

  ...tableProps,
  view: { // @deprecated
    type: String,
    default() {
      return this.$options.$$t('view.type', 'list') //grid
    }
  },
  listViewMinWidth: {
    type: [Number, String],
    default() {
      return this.$options.$$s('view.list.minWidth', 992)
    }
  },
  useCustomTable: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useCustomTable', false)
    }
  },
  dateFormat: {
    type: [Number, String],
    default() {
      return this.$options.$$s('table.cell.dateFormat', null)
    }
  },
  skipStates: {
    type: [Boolean, Array, String], //false (to disable filtering) or string (name of state) or array of strings (names of states)
    default() {
      return this.$options.$$s('skipStates', ['failed'])
    }
  },
  paginateLazy: {
    type: [Number, Boolean, String],
    default() {
      return this.$options.$$s('table.pagination.lazy', false)
    }
  },
  showStatusFilters: {
    type: [Number, Boolean, String],
    default() {
      return this.$options.$$s('table.filter.show', false)
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
