<template lang="pug">

  input.form-control(v-model="model"
    :type="type" :name="name" :min="min" :readonly="readonly" :placeholder="placeholder" :autocomplete="autocomplete")

</template>

<script>
export default {
  name: 'form-input',

  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    form: {
      type: Object,
      required: true
    },
    validation: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
  },

  data: () => ({
    model: '',
  }),

  watch: {
    model(val) {
      if (this.validation) {
        this.$set(this.validation[this.name], '$model', val);
      } else {
        this.$set(this.form, this.name, val);
      }
    },
  },

  created() {
    const initialValue = this.validation ? this.validation[this.name].$model : this.form[this.name];
    if (initialValue) {
      this.model = initialValue;
    }
  },
}
</script>
