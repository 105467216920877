/*global _, addiesaas */
import initAxios from '~/vuestrap/axios'
const axios = initAxios(null, null)

import {getFirstErrorMessage, getErrorMessage, getErrorMessages, getErrorMessagesWithKeys} from '~/lib/axios'
import config from '../lib/config'
import {handlePromise, templify, registerPromise, addiesaasReady} from '../lib/utils'
import {root as rootSetting} from '../lib/settings'
import {cache} from '../lib/cache'

export const {services: cacheSettings = {}} = rootSetting('cache', {})
export const {endpoints = {}, services = {}, payloads = {}} = rootSetting('api', {})
export const {CONFIG: {apiUri: APIURI, clientSecret: client_secret, clientId: client_id}} = config

/** Load cached data related to services.
 *
 * @param item      string|object String can be name of the service as per defined in Frontend Settings - `cache.services`
 *                                Or, object with structure {name, serviceName, expires, expiresAt, forceExpire}
 *                                in which these are optional
 *                                  serviceName: defaults to `name`
 *                                  expires: defaults to never expire
 *                                  expiresAt: can be calculated based on `expires`
 *                                  forceExpire: can be set to clean cache
 *
 * @param callback  function  async function to load data from service
 * @returns {Promise<*>}
 */
export const loadCache = async (item, callback) => {
  let name = item.name
  if (_.isString(item)) {
    name = item
    item = cacheSettings[item] || {name}
  }

  const cacheName = `services.${name}`
  let cachedData = item.forceExpire ? null : cache(cacheName)
  if (_.isNil(cachedData) && callback) {
    cachedData = await callback()
    if (!_.isNil(cachedData)) {
      cache(cacheName, cachedData, item)
    }
  }
  return cachedData
}
// Auto load services data
export const autoLoad = function() {
  const services = _.values(cacheSettings)
  if (services && _.isArray(services)) {
    services.forEach(async (item) => {
      if (item && item.autoload) {
        const callback = async () => {
          const name = item.serviceName || item.name
          try {
            const [data] = await registerPromise(name, ...(item.params || []))
            return data
          } catch (e) {
            return null
          }
        }
        loadCache(item, callback)
      }
    })
  }
}

export const omitId = (data, paths = 'id') => _.omit(data, _.castArray(paths))

export const promise = async (name, handler) => await handlePromise(name, handler, getFirstErrorMessage)

export const setPromise = async (name, handler) => {
  const store = 'REUtils/Pages/'
  const storeGetters = addiesaas && addiesaas.store && addiesaas.store.getters || {}
  const promises = storeGetters[`${store}servicePromises`]
  if (promises && !promises[name]) {
    addiesaas.store.dispatch(`${store}setServicePromise`, name)
    if (/^main\.?/i.test(_.get(services, name, 'Main'))) {
      return await promise(name, handler)
    }
  }
}

export const getEndpoint = (name, data, defaultValue, options) => {
  options = options || {}
  const {absoluteEndPoint = null, endPoint = null} = options
  let endpoint = absoluteEndPoint || endPoint || _.get(endpoints, name, defaultValue || '')
  let apiUri = APIURI
  if (!apiUri) {
    apiUri = _.get(addiesaas, 'config.apiUri')
  }
  if (!(/:\/\//.test(endpoint))) {
    endpoint = apiUri + endpoint
  }
  return templify(endpoint, data || {}, ['{', '}'])
}

export {
  axios,
  getFirstErrorMessage,
  getErrorMessage,
  getErrorMessages,
  getErrorMessagesWithKeys,
  handlePromise,
}

export default {
  axios,
  getFirstErrorMessage,
  getErrorMessage,
  getErrorMessages,
  getErrorMessagesWithKeys,
  endpoints,
  services,
  payloads,

  getEndpoint,
  promise,
  handlePromise,
  setPromise,
}

addiesaasReady(autoLoad, true)
