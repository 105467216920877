// @todo: complete behaviour of auto login after registration and fetchUser
// @todo: complete implementation (use templify) of label, linkLabel, buttonTitle instead of using i18n
/*global _ */
import ComplexComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'

import {space} from '../../../../lib/page'

const {key: registerSpace = 'page.registration'} = space('user-registration') || {}

const name = 're-registration-plug'
const props = {
  email: {},
  firstName: {},
  lastName: {},
  socialProvider: {}, // social provider
  showLoginBox: {

  },
  translations: {
    type: [String, Object, Array],
    default: null
  },
  settings: {
    type: [String, Object, Array],
    default: null
  },
  registerTranslations: {
    type: [String, Object, Array],
    default: null
  },
  registerSettings: {
    type: [String, Object, Array],
    default: null
  },
  registerEvent: {
    type: String,
    default: 'user.registration.plug'
  },
  label: {
    type: String,
    default: null
  },
  linkLabel: {
    type: String,
    default: null
  },
  buttonTitle: {
    type: String,
    default: null
  },

  messagePath: {
    type: String,
    default: null
  },
  labelPath: {
    type: String,
    default: null
  },
  loginLabelPath: {
    type: String,
    default: null
  },
  buttonPath: {
    type: String,
    default: null
  },
  otherLinkLabelPath: {
    type: String,
    default: null
  },
  otherLink: {
    type: String,
    default: null
  },
  modalClasses: {
    type: [String, Array, Object],
    default: 're-modal-sm'
  },
  noSuccessScreen: {
    type: [Boolean, Number, String],
    default: null
  },
  autoLogin: {
    type: [Boolean, Number, String],
    default: true
  },

  buttonClass: {
    type: [String, Array, Object],
    default: null
  },
  linkClass: {
    type: [String, Array, Object],
    default: null
  },
  hideLinkWhenFormShown: {
    type: [Boolean, Number, String],
    default: true
  },
  hideActions: {
    type: [Boolean, Number, String],
    default: false
  },
  disableActions: {},
  triggerSubmit: {},
  submitOnEnter: {},

}

export default {
  name,
  mixins: [ComplexComponentMixin, FormMixin],
  props,
  events: 'plug.user.registration',
  data() {
    return {
      demandShown: false,
    }
  },
  computed: {
    canShowForm() {
      return this.demandShown || this.formShown
    },
    formShown() {
      const placer = {
        'form': '[[FORM]]'
      }
      return /\[\[FORM\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    isNonDemandPopup() {
      const placer = {
        'popup': '[[POPUP]]',
      }
      return /\[\[POPUP\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    formShownInPopup() {
      const placer = {
        'popup': '[[POPUP]]',
        'popup-demand-link': '[[POPUP]]',
        'popup-demand-button': '[[POPUP]]',
      }
      return /\[\[POPUP\]\]/.test(this.$t(this.messageI18nPath, placer))
    },
    noCloseButtonForPopup() {
      return this.$$fv(this.$attrs['noCloseButton'], this.$attrs['no-close-button'], this.isNonDemandPopup)
    },
    registerBoxTranslations() {
      return this.registerTranslations || this.$$t('registerTranslations', registerSpace)
    },
    registerBoxSettings() {
      return this.registerSettings ||
        this.$$s('registerSettings', this.$$rootSettings('page.register', registerSpace))
    },
    messageI18nPath() {
      return this.messagePath || 'this.register.label'
    },
    loginLinkLabelPath() {
      return this.loginLabelPath || 'login.link.label'
    },
    otherLinkUrl() {
      return this.otherLink
    },
    generalLinkLabelPath() {
      return this.otherLinklabelPath || 'other.link.label'
    },
    linkLabelPath() {
      return this.labelPath || 'register.link.label'
    },
    buttonTitlePath() {
      return this.labelPath || 'register.button.title'
    },
    linkClasses() {
      return this.linkClass || this.$$s('register.link.class', this.$$s('link.class'))
    },
    buttonClasses() {
      return this.buttonClass || this.$$s('register.button.class', this.$$s('button.class'))
    },

    hideLink() {
      return this.hideLinkWhenFormShown && this.demandShown
    },
  },
  created() {
    if (this.isNonDemandPopup) {
      this.demandShown = true
    }
  },
  methods: {
    showDemand() {
      this.$emit('demand.init')
      this.emit('demand.init')
      this.demandShown = true
    },
    hideDemand() {
      this.$emit('demand.end')
      this.emit('demand.end')
      this.demandShown = false
    },
    onSuccess(...args) {
      this.hideDemand()
      // this.$$debugLog({'re-register-plug-success': args})
      this.$emit('success', ...args)
      this.emit('success', ...args)
    },
    onError(...args) {
      // this.$$debugLog({'re-register-plug-error': args})
      this.$emit('error', ...args)
      this.emit('error', ...args)
    }
  }

}
