/*global _ */
export default {
  methods: {
    parsePlaycardsBonusPromotionData(promotion) {
      const actionOptions = promotion.action_options
      const {playcards, promoted_playcards} = actionOptions
      if (!_.isEmpty(playcards) && !_.isEmpty(promoted_playcards)) {
        const playcardsStatus = _.keyBy(promoted_playcards, 'playcard.id')
        const appliedPlaycards = actionOptions.playcards = _.filter(playcards, card => {
          const status = playcardsStatus[card.id]
          return !status || status.is_applied
        })

        if (_.isEmpty(appliedPlaycards)) {
          const error = this.getFailedPlaycardsBonusPromotionError(promotion)
          throw new Error(error)
        } else {
          const notApplied = _.omitBy(playcardsStatus, item => item.is_applied)
          if (!_.isEmpty(notApplied)) {
            if (this.hasFailingPlaycardsBonusLocation(promotion)) {
              this.partialError = this.getFailingLocationPlaycardsBonusError(promotion)
            }
          }
        }
      }
    },
    hasFailingPlaycardsBonusLocation(promotion) {
      promotion = promotion || this.promotion
      const playcardsStatus = _.get(promotion.action_options, 'promoted_playcards')
      if (playcardsStatus) {
        const notApplied = _.omitBy(playcardsStatus, item => item.is_applied)
        if (!_.isEmpty(notApplied)) {
          const locationServices = this.$$s('locations.services', [])
          const locations = _.filter(_.uniq(_.map(notApplied, item => {
            const location = _.get(item, `playcard.location.data`)
            const failing = location.is_failing ||
              _.some(locationServices, service => location[service] && location[service].is_failing)
            if (failing) {
              return location.display_name
            }
            return null
          })))

          if (!_.isEmpty(locations)) {
            promotion.failingLocations = locations
            return locations
          }
        }
      }
      return false
    },
    getFailingLocationPlaycardsBonusError(promotion) {
      promotion = promotion || this.promotion
      const template = this.errorMessageMap['failingLocation'] || this.errorMessageMap['default'] || ''
      return this.buildMessage(template, {promotion})
    },
    getFailedPlaycardsBonusPromotionError(promotion) {
      promotion = promotion || this.promotion
      const failingLocations = this.hasFailingPlaycardsBonusLocation(promotion)
      if (failingLocations) {
        return this.getFailingLocationPlaycardsBonusError(promotion)
      }
      return this.buildMessage(this.errorMessageMap['default'] || '', {promotion})
    }
  }
}
