
// import laserTagSvg from '../../../public/images/client/saas-onboarding/onboarding/blocks/laser-tag.svg'
// import waterParkSvg from '../../../public/images/client/saas-onboarding/onboarding/blocks/water-park.svg'

export default {

  attractionBlocks: [
    {
      label: 'laser tag',
      icon: 'attractions/laser tag (Union selection-working).svg',
    },
    {
      label: 'water park',
      icon: 'attractions/water park.svg',
    },
    {
      label: '3d movies',
      icon: 'attractions/3d movies.svg',
    },
    {
      label: 'Animal Adventure',
      icon: 'attractions/Animal  Adventure.svg',
    },
    {
      label: 'Arcade',
      icon: 'attractions/Arcade.svg',
    },
    {
      label: 'Balck light Golf',
      icon: 'attractions/Balck light  Golf.svg',
    },
    {
      label: 'Ball pits',
      icon: 'attractions/Ball pits.svg',
    },
    {
      label: 'Batting Cages',
      icon: 'attractions/Batting Cages.svg',
    },
    {
      label: 'Bouncy House',
      icon: 'attractions/Bouncy House.svg',
    },
    {
      label: 'Bowling',
      icon: 'attractions/Bowling.svg',
    },
    {
      label: 'Bumper boat',
      icon: 'attractions/Bumper boat.svg',
    },
    {
      label: 'Climbing Gyms',
      icon: 'attractions/Climbing Gyms.svg',
    },
    {
      label: 'Cosmic Golf',
      icon: 'attractions/Cosmic Golf.svg',
    },
    {
      label: 'Entertaiment Center',
      icon: 'attractions/Entertaiment Center.svg',
    },
    {
      label: 'Family Entertainment Center',
      icon: 'attractions/Family  Entertainment  Center.svg',
    },
    {
      label: 'Free Fall',
      icon: 'attractions/Free Fall.svg',
    },
    {
      label: 'Fun Center',
      icon: 'attractions/Fun Center.svg',
    },
    {
      label: 'Go Karts',
      icon: 'attractions/Go-Karts.svg',
    },
    {
      label: 'Ice skating',
      icon: 'attractions/Ice skating.svg',
    },
    {
      label: 'movie',
      icon: 'attractions/cinema.svg',
    },
    {
      label: 'Kid rides',
      icon: 'attractions/Kid rides.svg',
    },
    {
      label: 'Miniature golf',
      icon: 'attractions/Miniature golf.svg',
    },
    {
      label: 'Obstacle Course',
      icon: 'attractions/Obstacle Course.svg',
    },
    {
      label: 'PaintBall',
      icon: 'attractions/PaintBall.svg',
    },
    {
      label: 'Quest Room',
      icon: 'attractions/Quest Room.svg',
    },
    {
      label: 'Rock Climbing',
      icon: 'attractions/Rock Climbing.svg',
    },
    {
      label: 'Roller skating',
      icon: 'attractions/Roller skating.svg',
    },
    {
      label: 'Ropes Course',
      icon: 'attractions/Ropes Course.svg',
    },
    {
      label: 'Theme Park',
      icon: 'attractions/Theme Park.svg',
    },
    {
      label: 'Trampoline Park',
      icon: 'attractions/Trampoline Park.svg',
    },
    {
      label: 'Virtual reality',
      icon: 'attractions/Virtual reality.svg',
    },
    {
      label: 'cinema',
      icon: 'attractions/cinema.svg',
    },
    {
      label: 'escape room',
      icon: 'attractions/escape room.svg',
    },
    {
      label: 'zoo',
      icon: 'attractions/zoo.svg',
    },
  ],

  softwareSystemBlocks: [
    {
      label: 'intercard',
      icon: 'integrations/intercard.svg',
      hoverIcon: 'integrations/intercard.white.svg',
    },
    {
      label: 'clubspeed',
      icon: 'integrations/clubspeed.svg',
    },
    {
      label: 'embed',
      icon: 'integrations/embed.svg',
    },
    {
      label: 'Rts',
      icon: 'integrations/rts.svg',
      hoverIcon: 'integrations/rts.white.svg',
    },
    {
      label: 'Sacoa',
      icon: 'integrations/sacoa.svg',
    },
    {
      label: 'Constant Contact',
      icon: 'integrations/constant contact.svg',
    },
    {
      label: 'Mailchimp',
      icon: 'integrations/Mailchimp.svg',
    },
    {
      label: 'Salesforce',
      icon: 'integrations/Salesforce.svg',
    },
    {
      label: 'Party Center software',
      icon: 'integrations/party software center.svg',
    },
  ],

  goalBlocks: [
    {
      label: 'Collect customer data',
      icon: 'goals/Collect customer data.svg',
    },
    {
      label: 'Acquire new customers',
      icon: 'goals/Acquire new customers.svg',
    },
    {
      label: 'Get more foot traffic',
      icon: 'goals/Get more foot traffic.svg',
    },
    {
      label: 'Brand my company better',
      icon: 'goals/Brand my company better.svg',
    },
    {
      label: 'Collect Customer information',
      icon: 'goals/Collect Custumer information2 1.svg',
    },
    {
      label: 'Display Popup Offers & Alerts',
      icon: 'goals/Icon goals-Display Popup Offers & Alerts_1 1.svg',
    },
    {
      label: 'Make marketing easier and faster',
      icon: 'goals/Make marketing easier and faster.svg',
    },
    {
      label: 'Product and Service Sales Funnels',
      icon: 'goals/Product and Service Sales Funnels 1.svg',
    },
    {
      label: 'Run Better Loyalty and Vip Programs',
      icon: 'goals/Run Better Loyalty and Vip Programs.svg',
    },
    {
      label: 'Run better promotions',
      icon: 'goals/Run better promotions-2 2.svg',
    },
    {
      label: 'Sell Party and group sales',
      icon: 'goals/Sell Party and group sales.svg',
    },
    {
      label: 'Sell products online',
      icon: 'goals/Sell products online.svg',
    },
  ],

  useBlocks: [
    {
      label: 'Collect Customer Information',
      icon: 'collect-customer-information.svg',
    },
    {
      label: 'Product & Service Sales Funnels',
      icon: 'product-service-sales-funnels.svg',
    },
    {
      label: 'Birthday and Group Sales',
      icon: 'sell-party-and-group-sales.svg',
    },
    {
      label: 'Display Popup Offers & Alerts',
      icon: 'display-popup.svg',
    },
    {
      label: 'Managing Promotional Marketing',
      icon: 'run-better-promotions.svg',
    },
  ],

  useTags: [
    'Ecommerce',
    'Online marketing',
    'Social media marketing',
    'Group & party sales',
    'Collecting customer info',
    'Email marketing',
    'Finding time',
    'Knowing what to do',
    'Customer Retention',
    'Cost of advertising',
    'Marketing analytics',
    'Technical knowledge required',
  ],

  startBlocks: [
    {
      key: 'collectCustomerData',
      label: 'Collect Customer Data',
      text: 'Rocket Effect makes it easy to grow and stay strong. Great for single location!',
      icon: 'start-collect-customer-data.svg',
    },
    {
      key: 'startPromotionalOffer',
      label: 'Start a Promotional Offer',
      text: 'Manage multiple locations from one single interface. Including multiple websites, and merchant accounts.',
      icon: 'start-a-promotional-offer.svg',
    },
    /*
    // NOTE: Planning to return this later
    {
      key: 'sellOnline',
      label: 'Sell Online',
      text: 'Manage multiple locations from one single interface. Including multiple websites, and merchant accounts.',
      icon: 'start-sell-online.svg',
    },
    */
  ],

  guides: {
    collectCustomerData: {
      path: 'customer',
      title: 'Start Collect Customer Data',
      subtitle: 'Learn what a buyer persona is and how to conduct research, surveys, and interviews to build your own.',
      topics: [
        {
          label: 'Introduction',
          title: '5 minute Quickstart Guide to Collecting Customer Data',
          subtitle: 'This is just to get you started off,  if you have multiple locations but want to start with a single location you can add more locations at any time.',
        },
        {
          label: 'What Is a Buyer Persona?',
        },
        {
          label: 'How to Research Buyer Personas',
        },
        {
          label: 'How to Create Buyer Personas',
        },
        {
          label: 'How to Use Buyer Personas',
        },
      ],
    },
    startPromotionalOffer: {
      path: 'funnel',
      title: 'Setup a Promotional Offer',
      subtitle: 'Learn what a buyer persona is and how to conduct research, surveys, and interviews to build your own.',
      topics: [
        {
          label: 'Introduction',
          title: 'Start Drive Foot Traffic With Powerful Promotions in 10 Minutes or Less',
          subtitle: 'This is just to get you started off,  if you have multiple locations but want to start with a single location you can add more locations at any time.',
        },
        {
          label: 'What Is a Buyer Persona?',
        },
        {
          label: 'How to Research Buyer Personas',
        },
        {
          label: 'How to Create Buyer Personas',
        },
        {
          label: 'How to Use Buyer Personas',
        },
      ],
    },
    sellOnline: {
      path: 'order',
      title: 'Start Selling Online',
      subtitle: 'Learn what a buyer persona is and how to conduct research, surveys, and interviews to build your own.',
      topics: [
        {
          label: 'Introduction',
          title: 'Start Selling Online In 15 minute With OurQuickstart Guide to Selling Online',
          subtitle: 'This is just to get you started off,  if you have multiple locations but want to start with a single location you can add more locations at any time.',
        },
        {
          label: 'What Is a Buyer Persona?',
        },
        {
          label: 'How to Research Buyer Personas',
        },
        {
          label: 'How to Create Buyer Personas',
        },
        {
          label: 'How to Use Buyer Personas',
        },
      ],
    },
  },

}
