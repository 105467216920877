const services = {
  Places: 'addiesaas.classes.Utils.AutoCompletePlaces'
}
const getService = (name) => {
  return _.get(window, services[name])
}
export const autocomplete = (options, context) => {
  options = options || {}
  context = context || {}
  const {service: name, params = {}} = options
  if (name) {
    const Service = getService(name)
    if (Service) {
      const service = new Service(params, context)
    }
  }
}

export default autocomplete
