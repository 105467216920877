/*global _ */

export default {
  data() {
    return {
      editMode: false,
      editItem: null,
      galleryImages: []
    }
  },

  computed: {

    filteredProductImages() {
      const all = this.getProductImages(this.productItem, 'all')
      const limit = this.imageGalleryLimit
      if (limit) {
        return _.slice(all, 0, limit)
      }
      return all
    },
    showImageSlider() {
      if (this.useImageSlider) {
        const width = this.appWidth
        return width >= this.imageSliderMinWidth && width <= this.imageSliderMaxWidth
      }
      return false
    },
    gallerySlidesSettings() {
      return this.imageSliderOptions || {}
    },
    gallerySlides() {
      return _.map(this.galleryImages, image => ({image}))
    }
  },

  created() {
    if (this.token) {
      this.editMode = true
      this.editItem = _.cloneDeep(this.currentCartItems[this.token])
    }

    this.productQuantity = this.getProductCartedQuantity()
    this.galleryImages = this.filteredProductImages
  },

  mounted() {

  },
  methods: {
    highlightGalleryImage(index) {
      if (index) {
        this.galleryImages.unshift(...this.galleryImages.splice(index, 1))
      }
    },
    getComponentExtraClasses() {
      return [

      ]
    }
  },
}
