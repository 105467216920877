/*global _ */
import {EventBus, DataFilter} from '../../lib/utils'

import LocationMixin from '../location'
import ProductMixin from './product'
import CartMixins from '../../mixins/cart'

const mixins = [LocationMixin, ProductMixin, CartMixins]

export default {
  mixins,
  data() {
    return {
      planQuantity: 1,
      planItem: {}
    }
  },
  computed: {
    canReduceQuantity() {
      return false
    },
    canIncreaseQuantity() {
      return false
    },
  },
  async created() {

  },

  methods: {
    getPlans() {
      return _.get(this.productItem, 'plans.data', [])
    },
    getPlanItemProperty(key, defaultValue, item){
      //
      //       amount: 30
      // code: "pinball-membership"
      // description: null
      // details: {unit: "Quanity", features: "[{"name":"Unlimited play for the holder of membership"}]"}
      // id: 1
      // initial_amount: 0
      // interval: "month"
      // length: null
      // name: "“Pinball Wizard”  local membership"
      // period: 1
      // trial_amount: 0
      item = item || this.planItem || {}
      return _.get(item, key, defaultValue)
    },
    getPlanTitle(item) {
      return this.$$fs(
        this.getPlanItemProperty('title', null, item),
        this.getPlanItemProperty('display_name', null, item),
        this.getPlanItemProperty('name', null, item)
      )
    },
    getPlanCode(item) {
      return this.$$fs(
        this.getPlanItemProperty('code', null, item),
        this.getPlanItemProperty('display_name', null, item)
      )
    },
    getPlanPrice(item) {
      return this.$$fs(
        this.getPlanItemProperty('price', null, item),
        this.getPlanItemProperty('amount', null, item)
      )
    },
    getPlanInterval(item) {
      const interval = this.getPlanItemProperty('interval', null, item)
      const period =  1 * this.getPlanItemProperty('period', 1, item)
      return this.$$tc(interval, '/' + interval, period)
    },
    getPlanDetailsProps(key, defaultValue, item) {
      item = item || this.planItem || {}
      let path = 'details'
      if (key) {
       path += `.${key}`
      }
      return this.getPlanItemProperty(path, defaultValue, item)
    },
    getPlanFeatures(item) {
      item = item || this.planItem || {}
      let features = this.getPlanDetailsProps('features', [], item)
      if (_.isString(features)) {
        features = JSON.parse(features) || []
      }
      features = _.map(features, 'name')
      return features
    },
    isHtmlFeature(feature) {
      return _.startsWith(feature,'html::')
    },
    getHtmlFeature(feature) {
      return feature.replace(/^html::/, '')
    },
    isImageFeature(feature) {
      return _.startsWith(feature,'image::')
    },
    getImageFeature(feature) {
      return feature.replace(/^image::/, '')
    },
    isPlanItemCarted() {
      return _.get(this.getPlanCartItem() || {}, 'payload.options.plan') === this.getPlanCode()
    },
    getPlanCartItem(item, getDefault = false) {
      item = _.cloneDeep(item || this.productItem || {})
      const id = this.getProductSlug(item, item.id)
      let cartItem = this.currentCartItems[id]
      if (getDefault) {
        item.price = this.getPlanPrice()
        cartItem = _.merge({}, this.cartItemStub, {
          id,
          title: this.getPlanTitle(),
          type: item.type,
          total: 1 * this.getPlanPrice(),
          attributes: {
            product: item
          },
          payload: {
            product_id: item.id,
            options: {
              plan: this.getPlanCode()
            }
          },
          editRoute: this.getPlanRoute(),
          deletable: true,
          editable: true,
          cartCanChangeQuantity: false,
        })
      }
      return _.cloneDeep(cartItem)
    },
    getPlanCartedQuantity(item) {
      item = item || this.productItem || {}
      const cartItem = this.getPlanCartItem(item)
      return _.get(cartItem, 'payload.count', 1)
    },

    async addPlanItemToCart(item, moveToCart = true) {
      item = item || this.productItem || {}
      const cartItem = this.getPlanCartItem(item)
      const newCartItem = this.getPlanCartItem(item, true)

      let success
      if (!cartItem) {
        success = await this.addCartItem(newCartItem)
      } else {
        success = await this.updateCartItem(newCartItem)
      }
      if (success && moveToCart) {
        this.goto('cart')
      }
    },

    getPlanSlug(item, prefix = '', suffix = '') {
      item = item || this.productItem || {}
      if (prefix) {
        prefix += ' '
      }
      if (suffix) {
        suffix = ' ' + suffix
      }
      let name = _.kebabCase(prefix + item.name + suffix)
      return name
    },

    getPlanRoute(item) {
      return {name: 'shop.subscription'}
    },
    getPlanUrl(item) {
      this.getRouteUrl('shop.subscription')
    },
    getPlanBackgroundImageStyle(item) {
      item = item || this.productItem || {}
      const backgroundImage = this.showBackgroundImage ? this.getPlanBackgroundImage(item) : null
      const styles = {}
      if (backgroundImage) {
        styles.background = `transparent url(${backgroundImage}) no-repeat center center`
        styles.backgroundSize = 'cover'
      }
      return styles
    },

    getPlanImage(item) {
      item = item || this.productItem || {}
      return this.$$fs(
        _.get(item, 'image'),
        _.get(item, 'media.image'),
        this.$$s(`product.${item.type}.image.src`),
        this.$$s(`product.stub.image.src`),
        this.getPlanItemProperty('media.image_route', item)
      )
    },
    getPlanBackgroundImage(item) {
      item = item || this.productItem || {}
      return this.$$fv(
        _.get(item, 'media.background_image'),
        this.$$s(`media.background_image`),
        this.$$s(`product.${item.type}.background_image.src`),
        this.$$s(`product.stub.background_image.src`)
      )
    },
    getPlanImages(item, index) {
      item = item || this.productItem || {}
      const images = _.get(item, 'media.images', [])
      const image = this.getPlanImage(item)
      if (_.isNil(index)) {
        return images
      }
      if (index === 'all') {
        return [image, ...images || []]
      }
      if (index === 'featured') {
        return image
      }
      if (index === 'background') {
        return this.getPlanBackgroundImage(item)
      }
      return images[index] || image
    },
    getPlanItemFromId(id) {
      id = parseInt(id)
      const products = _.keyBy(this.getAllProducts(true), 'id')
      return products[id] || null
    }
  },
}

