import {name, mixins, props, data, defaultFormFields, validationsRules, EventBus} from './config'

import MainService from './../../../../services/main'
import {
  Validators
} from '../../../../lib/validations'

const {required, email} = Validators

export default {
  name,
  mixins,
  props,

  data() {
    return {
      resetRequestSent: false,
      resetRequestSentOnce: false,
      errorMessage: null,
      resendCount: 0,
      sendingResetRequest: false,
      fields: {
        email: this.email
      },
      validations: {
        email: {
          name: 'fields.email',
          validator: this.submittingInvalidRule,
          items: {
            required: {error: 'Email is required.'},
            email: {error: 'Email is not valid.'},
          },
        },
      },
    }
  },
  validations() {
    return {
      fields: {
        email: {
          required,
          email
        }
      }
    }
  },

  watch: {
    email(newVal) {
      this.fields.email = newVal
    },
  },

  computed: {
    showForm() {
      return !this.resetRequestSentOnce
    },
    isRequestError() {
      return !_.isEmpty(this.errorMessage)
    },
    requestError() {
      this.isLoggingIn = false
      return this.errorMessage
    },
    isResendEmail() {
      return this.resendCount > 0
    }
  },

  created() {
    this.resetForm()
  },

  methods: {
    async requestResetLink() {
      EventBus.$emit('user.before.password.reset')
      this.$v.$touch()
      this.formSubmitting = true
      this.errorMessage = null
      if (!this.isAnyInvalid) {
        const email = this.fields.email
        const reseturl = window.location.origin + this.$router.match({name: 'user.password.reset'}).path
        this.resetRequestSent = false
        this.sendingResetRequest = true
        try {
          const data = await MainService.forgotPassword({email, reseturl})
          this.resetRequestSent = true
          this.resetRequestSentOnce = true
          EventBus.$emit('user.password.reset')
          this.disableResend()
        } catch (e) {
          this.errorMessage = MainService.getFirstErrorMessage(e)

          e.all = MainService.getErrorMessagesWithKeys(e)
          this.showFormError(e, {'scrollTo': '.re-forgot-password-box', flattenTopMessages: true})
          this.emit('error', e, this.fields)
          this.$emit('error', e, this.fields)

          EventBus.$emit('user.password.reset.error', e)
        } finally {
          this.sendingResetRequest = false
        }
      }
    },
    async resend() {
      try {
        const data = await this.requestResetLink()
        this.resendCount++
      } catch (e) {
        this.resetRequestSent = false
        this.requestSentError = MainService.getFirstErrorMessage(e)
      }
    },
    resetForm() {
      this.fields.email = this.email || ''
      this.$v.$reset()
      this.resetRequestSent = false
      this.errorMessage = null
      this.resendCount = 0
      this.sendingResetRequest = false
      this.formSubmitting = true
      this.resetRequestSentOnce = false
    },
    backToLogin() {
      this.resetForm()
      this.$emit('backToLogin')
      EventBus.$emit('user.password.forgot.back-to-login')
    }
  }
}
