/*global _ */
import {Vue} from '~/addiesaas'
import createPersistedState from 'vuex-persistedstate'
import * as types from '../mutation-types/pages'

// set this true to namespace all getters, mutations, actions etc.
const namespaced =  true

export const namespace = 'Pages'
const rootNamespace = 'REUtils'
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

const location = window.addiesaas.location || {}
const locationId = location.id || ''
const locationIdKey = locationId

// state
const state = {
  postVerificationRoute: null,
  postVerificationRouteData: null,
  postLoginRoute: null,
  postLoginRouteData: null,
  savedLastRoute: null,
  savedLastRouteData: {},
  showGlobalTimer: true,
  pageTransitionData: {},
  promises: {}
}

// mutations
const mutations = {
  [types.SET_POST_VERIFICATION_ROUTE](state, page) {
    state.postVerificationRoute = page
  },
  [types.SET_POST_VERIFICATION_ROUTE_DATA](state, data) {
    state.postVerificationRouteData = data
  },
  [types.SET_POST_LOGIN_ROUTE](state, page) {
    state.postLoginRoute = page
  },
  [types.SET_POST_LOGIN_ROUTE_DATA](state, data) {
    state.postLoginRouteData = data
  },
  [types.SET_LAST_ROUTE](state, page) {
    state.postLoginPage = page
  },
  [types.SET_LAST_ROUTE_DATA](state, data) {
    state.postLoginSection = data
  },
  [types.HIDE_GLOBAL_TIMER](state) {
    state.showGlobalTimer = false
  },
  [types.SHOW_GLOBAL_TIMER](state, hide) {
    state.showGlobalTimer = true
  },
  [types.SET_PROMISE](state, promise) {
    const promises = state.promises
    promises[promise] = true
    state.promises = promises
  },
  [types.SET_PAGE_TRANSITION_DATA](state, data) {
    state.pageTransitionData = _.merge(_.cloneDeep(state.pageTransitionData), data || {})
  },
  [types.SET_PAGE_TRANSITION_DATA_ITEM](state, data) {
    _.forOwn(data, (value, key) => {
      Vue.set(state.pageTransitionData, key, value)
    })
  },
  [types.UNSET_PAGE_TRANSITION_DATA_ITEM](state, key) {
    _.forOwn(_.castArray(key), key => {
      Vue.delete(state.pageTransitionData, key)
    })
  },
  [types.UNSET_PAGE_TRANSITION_DATA](state) {
    state.pageTransitionData = {}
  }
}

const actions = {
  savePostVerificationPage({commit}, {page, options}) {
    commit(types.SET_POST_VERIFICATION_ROUTE, _.isString(page) ? page : page.name)
    commit(types.SET_POST_VERIFICATION_ROUTE_DATA, options)
  },
  savePostLoginPage({commit}, {page, options}) {
    commit(types.SET_POST_LOGIN_ROUTE, _.isString(page) ? page : page.name)
    commit(types.SET_POST_LOGIN_ROUTE_DATA, options)
  },
  savePageBeforeLeave({commit}, {page, options}) {
    commit(types.SET_LAST_ROUTE, _.isString(page) ? page : page.name)
    commit(types.SET_LAST_ROUTE_DATA, options)
  },
  allowShowingGlobalTimer({commit}) {
    commit(types.SHOW_GLOBAL_TIMER)
  },
  hideGlobalTimer({commit}) {
    commit(types.HIDE_GLOBAL_TIMER)
  },
  setServicePromise({commit}, promise) {
    commit(types.SET_PROMISE, promise)
  },
  setPageTransitionData({commit}, data) {
    commit(types.SET_PAGE_TRANSITION_DATA, data)
  },
  unsetPageTransitionData({commit}) {
    commit(types.UNSET_PAGE_TRANSITION_DATA)
  },
  setPageTransitionDataItem({commit}, data) {
    commit(types.SET_PAGE_TRANSITION_DATA_ITEM, data)
  },
  unsetPageTransitionDataItem({commit}, key) {
    commit(types.UNSET_PAGE_TRANSITION_DATA_ITEM, key)
  }
}

// getters
const getters = {
  servicePromises: state => state.promises,
  savedLastRoute: state => ({name: state.savedLastRoute, options: state.savedLastRouteData}),
  showingGlobalTimer: state => state.showGlobalTimer,
  savedPostVerificationRoute: state => (state.postVerificationRoute && {name: state.postVerificationRoute, options: state.postVerificationRouteData}),
  savedPostLoginRoute: state => (state.postLoginRoute && {name: state.postLoginRoute, options: state.postLoginRouteData}),
  pageTransitionData: state => state.pageTransitionData
}

const plugins = [
  createPersistedState({
    paths: [
      'showGlobalTimer',
      'savedLastRoute',
      'savedLastRouteData',
      'postVerificationRoute',
      'postVerificationRouteData',
      'pageTransitionData',
    ].map(i => `${rootNamespace}.${namespace}.${i}`),
    key: absoluteNamespace + locationIdKey,
  })
]

const pages = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}
export default pages
