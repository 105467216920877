/**
 * VNode is a functional component to render vuejs VNodes in a template
 * e.g., Vnodes(:vnodes="vnodes")
 *
 * Implemented in `re-profile` to render selected slots (vnodes) designed for child components
 * from parent profile component
 *
 * `re-profile` has child tab components like `re-profile-orders`, `re-profile-cards` etc.
 * where `orders` or `cards` are the `type`s of child components.
 *
 * Each tab component has it's own slots which are named prefixed with the type,
 * e.g., `orders-content` or `orders-not-found`
 *
 * Slots with names `orders-contents` or `orders-not-found` can be defined for `re-profile`.
 * However, these will be picked up selectively and passed to the child tab components.
 *
 * The template of re-profile renders the tab components using `component(:is="selectedComponent")
 * and injects the slots into it by using: `Vnodes(:vnodes="vnodes")` like this:
 *
 *    component(:is="selectedComponent")
 *        template(v-for="(vnodes, slotName) in childSlots" v-slot:[slotName]="slotProps")
 *            Vnodes(:vnodes="vnodes"  :props="slotProps")
 *
 */
export default {
  functional: true,
  render: (h, ctx) => {
    const {vnodes, props} = ctx.props
    if (_.isFunction(vnodes)) {
      return vnodes(props)
    }
    return vnodes
  }
}
