/*global _ */
import {setTimeoutAsync} from '../../../lib/utils'
export default {
  methods: {

    async showClaimConfirmation(rewards, options) {
      const type = _.first(rewards).type
      const settings = this.$$s(`rewards.confirmation.${type}`, this.$$$s)
      const translations = this.$$t(`rewards.confirmation.${type}`, this.$$$t)

      const componentPrefix = 're-loyalty-rewards-confirmation'
      const componentName = `${componentPrefix}-` + (type || 'achievement')
      const component = this.$$s(`rewards.components.confirmation.${type}.name`, componentName)
      const attrs = {
        componentAttrs: {
          value: rewards,
          options,
          settings,
          translations,
          showTitle: true,
          context: this
        },
        props: {
          containerClass: ['re-modal-md', componentPrefix, component],
          eventspace: 'user.profile.loyalty.reward.confirm'
        }
      }
      return await this.showModalAsync(component, attrs)
    },
    async initStandaloneClaim(reward, $event, vm) {
      await setTimeoutAsync(250)
      await this.initClaim(_.castArray(reward), {standalone: true})
    },
    async initClaim(rewards, options) {
      options = options || {}
      const claimables = options.skipIgnored || options.standalone ?
        rewards :
        rewards.filter(reward => !reward.ignored)

      if (claimables.length) {
        const confirmed = await this.showClaimConfirmation(rewards, options)
        if (!confirmed) {
          this.ignoreRewards(claimables)
        }
      }
    },
  }
}
