const EventBus = window.VueEventBus
import {currency, map} from '../lib/formatters'
// shop/orders is an container path alias set using /resources/js/lib/aliaser
import CartMixin from './external/cart'
//GETTERS: cartItemStub, cart:state, cartItems, cartDiscounts, cartItemCount, cartIsEmpty, cartItem(id), billingDetails(id)
//ACTIONS: addCartItem,addCartItems,addDiscount,removeCartDiscount,clearCart,removeCartItem,setBillingDetails

import Config from '../lib/config'
import MainService from '../services/main'
import ScrollMixins from './scroller'

import { mapGetters as rootMapGetters, mapActions as rootMapActions, createNamespacedHelpers } from 'vuex'
import { namespace } from '../vuexstore'
import { default as store, namespace as cartNamespace } from '../vuexstore/modules/cart'

const { mapGetters, mapActions } = createNamespacedHelpers([namespace, cartNamespace].join('/'))

export default {
  mixins: [CartMixin, ScrollMixins],
  data() {
    return {
      isSubmitting: false,
      timerWarningType: 'warning',

      validateRecursionThreshold: 2,
      validateRepeatCount: 0,
      isValidating: false,
      errors: {},
      itemErrors: {},
    }
  },

  watch: {
    cartStep(value, oldvalue) {
      if (value === this.currentStep) {
        this.scrollToTop(500, {offset: Config.SCROLL_OFFSET || -150})
        const methodName = _.camelCase('mounted ' + value)
        if (this[methodName] && _.isFunction(this[methodName])) {
          this[methodName]()
        }
      }
    }
  },
  computed: {
    ...mapGetters(Object.keys(store.getters)),
    ...rootMapGetters({
      authenticated: 'authCheck',
      authUser: 'authUser',
    }),
    billingEnabled() {
      const summary = this.costDetails || {}
      return summary.total > 0
    },
    summaryTotal() {
      const summary = this.costDetails || {}
      const total = (summary.total || 0) - (summary.deposit > 0 ? (summary.remaining || 0) : 0)
      return currency(total)
    },
    submitButtonText() {
      return this.submitButtonTitle +
        (this.submitButtonTitleIncludeAmount ? (' ' + this.summaryTotal) : '')
    },
    hasOnlyGiftcards() {
      return Object.values(this.currentCartItems).filter(x => x.type !== 'giftcard').length === 0
    },
    lastGiftcard() {
      return _.last(Object.values(this.currentCartItems).filter(x => x.type === 'giftcard'))
    },
    hasShippableProduct() {
      return _.some(this.currentCartItems, i => {
        const product = _.get(i, 'attributes.product')
        const needsShipping = this.$$fs(
          _.get(product, 'meta.needs_shipping'),
          _.get(product, 'custom.needs_shipping'),
          _.get(product, 'needs_shipping'),
        )
        return !!(1 * needsShipping)
      })
    },
    showErrorMessage() {
      return !this.valid && this.validationError
    },
    currentCartIsEmpty() {
      return _.isEmpty(this.currentCartItems)
    },
    currentCartItems() {
      const location = this.useLocationCheckout && this.currentLocationId
      const all = !location ? {...this.cartItems} : _.pickBy(this.cartItems, item => {
        const itemLocation = _.get(item, 'attributes.product.location_id')
        return !itemLocation || itemLocation === location
      })
      return all
    }
  },
  created() {

  },
  methods: {
    ...mapActions(Object.keys(store.actions)),
    hasAllGuestCheckoutProducts(types) {
      if (!types|| _.isEmpty(types)) {
        return true
      }
      if (_.isString(types)) {
        types = types.replace(' ', '').split(',')
      }
      const productTypes = _.map(this.currentCartItems, 'type')
      const diff = _.difference(productTypes, types)
      if (_.isEmpty(diff)) {
        return true
      }
      const hasInverse = _.some(types, t => _.startsWith(t, '-'))
      if (hasInverse) {
        const productTypesKeyed = _.keyBy(productTypes)
        for(let type of types) {
          if (_.startsWith(type, '-') && !!productTypesKeyed[type.replace(/^-/, '')]) {
            return false
          }
        }
        return true
      }
      return false
    },
    async submitCart(payload) {
      if (this.isDesignerMode) {
        this.setCartStep('final')
      }
      payload = payload || {}
      this.setValidationError('')
      this.isSubmitting = true
      payload.items = Object.keys(this.currentCartItems).map(x => this.currentCartItems[x].payload)
      payload.discounts =  Object.keys(this.cartDiscounts).map(x => this.cartDiscounts[x].payload)
      try {
        this.$emit('paying', payload)
        this.emit('cart.paying', payload)
        const cartPayload = this.sanitizeCartPayload(payload)
        const cartPayloadConfig = {config: {}}
        let [data] = await this.promise('shop.order', cartPayload, cartPayloadConfig)
        //let data = await MainService.order(cartPayload, cartPayloadConfig)
        this.setCartValid()
        this.$emit('paid', data.data)
        this.emit('cart.paid', data.data)
        this.setCartStep('final')
      } catch (e) {
        this.$emit('error', e, payload)
        this.emit('cart.processing.error', e, payload)
        const message = MainService.getFirstErrorMessage(e) || e.message || 'Unknown error!'
        this.$v.$reset()

        this.setCartInvalid()
        this.setValidationError(message)
        this.isSubmitting = false
      }
    },
    sanitizeCartPayload(payload) {
      return _.omitBy(map(payload, v => {
        return _.isObject(v) && !_.isArray(v) ? this.sanitizeCartPayload(v) : v
      }), v => _.isNil(v) || v === '')
    },

    async validateCart(resetCount = false, config = {}) {
      if (this.isDesignerMode) {
        return
      }
      if (this.currentCartIsEmpty) {
        return
      }
      // this.setCostDetails({})
      if (resetCount) {
        this.validateRepeatCount = 0
      }

      const cartItemKeys = Object.keys(this.currentCartItems)
      const cartDiscountKeys = Object.keys(this.cartDiscounts)

      const payload = {
        items: cartItemKeys.map(x => this.currentCartItems[x].payload),
        discounts: Object.keys(this.cartDiscounts).map(x => this.cartDiscounts[x].payload),
      }

      EventBus.$emit('cart.init-validation', payload)
      this.setValidationError('')
      this.setValidationErrors(null)
      this.setCartInvalid()
      this.setCartValidating(true)
      let askToSignIn = false
      let valid = false
      let validationError = null
      const itemErrors = {}
      const discountErrors = {}
      let errors = null

      try {
        config = config || {}
        const options = {config}
        EventBus.$emit('cart.validate.before', payload, options)
        let data = await MainService.validateOrder(payload, options)
        this.setCostDetails(data.data)

        EventBus.$emit('cart.validated', this.costDetails)
        valid = true

        if (!_.isNil(this.noAuthCheck) && !this.noAuthCheck) {
          await this.pingUser()
          valid = this.authenticated
          if (!valid) {
            validationError = 'Please sign in or register to proceed with checkout.'
            EventBus.$emit('cart.unauthenticated')
            askToSignIn = true
          }
        }

      } catch (e) {
        const responseData =  e.response && e.response.data || {}
        const responseStatus =  e.response && (1 * e.response.status) || 0
        EventBus.$emit('cart.error', responseData, e)
        this.setValidationErrors({...responseData, status: responseStatus})

        if (e.response && responseStatus === 422) {
          errors = responseData.errors

          EventBus.$emit('cart.error.validation', errors, this)

          Object.keys(errors).forEach(itemErrorKey => {

            if (itemErrorKey.startsWith('items.')) {

              const [keyword = 'item', index = null, errorName = 'unknown'] = itemErrorKey.split('.')

              const cartItemKey = cartItemKeys[index]
              const cartItem = this.currentCartItems[cartItemKey]
              const itemErrorDetails = errors[itemErrorKey]
              const itemError = {type: errorName, error: itemErrorDetails}
              itemErrors[cartItemKey] = itemErrorDetails[0]

              EventBus.$emit('cart.error.item.validation', itemError, cartItem, this)
              EventBus.$emit(`cart.error.item.validation.${errorName}`, itemError, cartItem, this)
              EventBus.$emit(`cart.error.item.validation.${cartItemKey}`, itemError, cartItem, this)
              EventBus.$emit(`cart.error.item.validation.${errorName}.${cartItemKey}`, itemErrorDetails, cartItem, this)
            }

            if (itemErrorKey.startsWith('discounts.')) {

              const [keyword = 'discounts', index = null, errorName = 'unknown'] = itemErrorKey.split('.')

              const cartDiscountKey = cartDiscountKeys[index]
              const cartDiscount = this.cartDiscounts[cartDiscountKey]
              const cartDiscountErrorDetails = errors[itemErrorKey]
              const cartDiscountError = {type: keyword, subType: errorName, error: cartDiscountErrorDetails}
              discountErrors[cartDiscountKey] = cartDiscountErrorDetails[0]

              EventBus.$emit('cart.error.discounts', cartDiscountError, cartDiscount, this)
              EventBus.$emit(`cart.error.discounts.${errorName}`, cartDiscountError, cartDiscount, this)
              EventBus.$emit(`cart.error.discounts.${errorName}.${cartDiscountKey}`, cartDiscountError, cartDiscount, this)
            }

          })

          const errorKey = Object.keys(errors)[0]
          validationError = errors[errorKey][0]
          EventBus.$emit('cart.error.validation.' + errorKey, errors[errorKey], errors, this)

        } else {
          validationError = 'Unknown error'
        }
        if (_.isEmpty(itemErrors) && _.isEmpty(discountErrors) ) {
          if (this.validateRepeatCount >= this.validateRecursionThreshold) {
            valid = false
            validationError = 'Ops! We have encountered an issue while checking your cart items. Please refresh the page.'
          } else {
            valid = true
            validationError = null
            this.setValidationErrors(null)
            this.validateRepeatCount++
            await this.validateCart()
            return
          }
        }
      }

      this.setCartValid(valid)
      this.setValidationError(validationError)
      this.setAskToSignIn(askToSignIn)
      this.itemErrors = itemErrors
      this.errors = errors

      this.setCartValidating(false)
    },

    cartItemButtonClicked(args) {
      const {actionName, item, button} = args
      const onActionName = _.camelCase(`on-${actionName}`)
      const methodName = _.camelCase(`cart-item-${onActionName}`)
      const eventName = _.kebabCase(`cart-item-before-${actionName}`)
      const itemEventName = `${eventName}-${item.type}`

      let nativeMethodCall = true

      if (item[onActionName]) {
        nativeMethodCall = item[onActionName](args) !== false
      }

      if (button[onActionName]) {
        nativeMethodCall = button[onActionName](args) !== false
      }

      this.$emit(itemEventName, args)
      EventBus.$emit(itemEventName, args)

      nativeMethodCall = this.$emit(eventName, args) !== false
      nativeMethodCall = EventBus.$emit(eventName, args) !== false

      if (nativeMethodCall) {
        if (this[methodName]) {
          this[methodName](args)
        }
      }
    },

    cartItemOnEdit(args) {
      const {item} = args

      const eventName = 'cart-item-edited'
      this.$emit(eventName, args)
      EventBus.$emit(eventName, args, this)

      if (item.editRoute) {
        const route = Object.assign({}, item.editRoute, {query: {token: item.id}})
        this.$router.push(route)
      }
      else {
        if (item.editUrl) {
          this.$router.push(item.editUrl)
        }
      }
    },

    /**
     *
     * @param item Object must have `id` property which refers to the item.id
     */
    async cartItemOnDelete(args) {
      const {item} = args

      this.removeCartItem(item)

      const eventName = 'cart-item-deleted'
      this.$emit(eventName, args)
      EventBus.$emit(eventName, args)
      this.validateRepeatCount = 0
      await this.validateCart()
    },

    createDiscountCoupon(code) {
      return {
        title: code,
        type: 'coupon',
        payload: {
          type: 'coupon',
          options: {
            code,
          },
        },
      }
    },

    clearCartItems() {
      const location = this.useLocationCheckout && this.currentLocationId
      this.clearCart({location})
    }

  }

}

