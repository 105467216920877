import pageProps from '../../../../props/profile/page-props'
import tableProps from '../../../../props/profile/table-props'
import editProps from '../../../../props/profile/edit-props'

export const props = {
  ...pageProps,
  ...tableProps,
  ...editProps,
  autoLoad: {
    type: [String, Array, Boolean],
    default() {
      return this.$options.$$s('autoLoad', false) // autoLoad data for other related pages. pass string or array of the profile page names
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading family members...')
    }
  },
  showLocationFilter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLocationFilter', false)
    }
  },
  locationFilterCriteria: {
    type: [String, Object, Array, Function],
    default() {
      return this.$options.$$s('locationFilterCriteria', '{[cardObject]}')
    }
  },
  emptyFilteredMemberMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyFilteredMemberMessage', 'No family has been associated to this location.')
    }
  },
  skipFieldsOnUpdate: {
    type: [String, Array],
    default() {
      return this.$options.$$s('skipFieldsOnUpdate', [])
    }
  }
}

export default props
