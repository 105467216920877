export const name = 're-shop-products'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import BaseMixin from './base/mixin'
import ProductsMixin from '../../../../mixins/shop/products'
export const mixins = [ComponentMixin, BaseMixin, ProductsMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus
}
