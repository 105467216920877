import {Vue} from '~/addiesaas'
//old
import FormSuccessModal from './FormSuccessModal'
Vue.component(FormSuccessModal.name, FormSuccessModal)

import Modal from './Modal'
Vue.component(Modal.name, Modal)

import ReModal from './re-modal'
Vue.component(ReModal.name, ReModal)

import ReAlert from './re-alert'
Vue.component(ReAlert.name, ReAlert)

import ReSuccess from './re-success'
Vue.component(ReSuccess.name, ReSuccess)

import REConfirm from './re-confirm'
Vue.component(REConfirm.name, REConfirm)

import ReInfo from './re-info'
Vue.component(ReInfo.name, ReInfo)

export default {
  FormSuccessModal,
  Modal,
  //new components
  ReModal,
  ReAlert,
  ReSuccess,
  REConfirm,
  ReInfo,
}
