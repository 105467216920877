/* global _ */
import {fv} from '../../../lib/utils'
import {nil} from '../../../lib/formatters'

export default {
  data() {
    return {
      selectedRow: this.activeRow,
    }
  },
  computed: {
    tableRows() {
      return this.proxyValues
    },
    tableRowOptions() {
      return _.get(this.options, 'row', {})
    },
    rowAttrs() {
      return this.tableRowOptions.attrs || {}
    },
    tableCellOptions() {
      return _.get(this.options, 'cell', {})
    },
    cellAttrs() {
      return this.tableCellOptions.attrs || {}
    },
    cellComponentName() {
      return this.tableCellOptions.component
    },
    cellComponentAttrs() {
      return {
        ...this.tableCellOptions.componentAttrs || {}
      }
    },
    cellComponentListeners() {
      return this.tableCellOptions.componentListeners || {}
    },
  },
  watch: {
    activeRow(value) {
      this.selectedRow = value
    }
  },
  methods: {
    getOriginalRow(index) {
      return this.innerValue[index]
    },
    getRow(index) {
      return this.tableRows[index]
    },
    getRowAttrs(data, index) {
      const attrs = this.rowAttrs
      const active = !_.isNil(this.selectedRow) && this.selectedRow === index
      const rowClasses = {active}
      const classes = this.parseAttrClasses(attrs, rowClasses)
      return {
        ...attrs,
        'data-row-index': index,
        class: classes
      }
    },
    getCellAttrs(data, index, rowIndex) {
      const column = this.getColumn(index)
      const row = this.getOriginalRow(rowIndex)
      const mergeData = {
        $row: row,
        $column: column,
        $index: index,
        $rowIndex: rowIndex
      }
      let parseAttrs = this.parseDynamicAttrs(column.cellParseAttrs, data, mergeData)

      const finalAttrs = {
        ...this.cellAttrs,
        ...parseAttrs,
      }

      const params = {
        value: finalAttrs,
        data,
        row,
        column,
        index,
        rowIndex,
        vm: this
      }
      this.applyDataFilter('cell-attrs', params)

      const name = fv(column.name, column.key, index)
      let label = this.getTitleValue(null, index)
      if (!_.trim(this.$$trim(label))) {
        label = ''
      }
      return {
        ...finalAttrs,
        'data-cell-name': name,
        'data-cell-index': index,
        ...(nil(label) ? {} : {'data-label': label}),
      }
    },
    cellComponent(data, index, rowIndex) {
      const column = this.getColumn(index)
      const row = this.getOriginalRow(rowIndex)
      const value = column.component || this.cellComponentName
      const params = {
        value,
        data,
        row,
        column,
        index,
        rowIndex,
        vm: this
      }
      this.applyDataFilter('cell-component', params)
      return params.value
    },
    getCellComponentAttrs(value, index, rowIndex) {
      const column = this.getColumn(index)
      const attrs = column.componentAttrs || {}
      let componentParseAttrs = column.componentParseAttrs
      if (!componentParseAttrs) {
        componentParseAttrs =  {value}
      } else {
        const row = this.getOriginalRow(rowIndex)
        componentParseAttrs = _.mapValues(componentParseAttrs, item => {
          if (_.isString(item)) {
            item = {key: item}
          }
          return this.getTableCell(item, row, index, rowIndex)
        })
      }
      const finalAttrs = {
        ...this.cellComponentAttrs,
        ...attrs,
        ...componentParseAttrs,
      }

      const row = this.getOriginalRow(rowIndex)
      const data = this.getRow(rowIndex)
      const component = this.cellComponent(value, index, rowIndex)
      const params = {
        key: `cell-component-row-${rowIndex}-cell-${index}`,
        value: finalAttrs,
        component,
        data,
        row,
        column,
        index,
        rowIndex,
        vm: this
      }
      this.applyDataFilter('cell-component-attrs', params)

      return finalAttrs
    },
    getCellComponentListeners(data, index, rowIndex) {
      const column = this.getColumn(index)
      return this.cellComponentListeners
    },
    getValueAttrs(data, index, rowIndex) {
      data = fv(data, this.getRow(index))
      const row = this.getOriginalRow(rowIndex)
      const column = this.getColumn(index)
      const attrs = {
        ...column.valueAttrs || {}
      }

      const mergeData = {
        $row: row,
        $column: column,
        $index: index,
        $rowIndex: rowIndex
      }
      let parseAttrs =this.parseDynamicAttrs(column.valueParseAttrs, data, mergeData)
      const finalAttrs = {
        ...attrs,
        ...parseAttrs,
      }
      const params = {
        value: finalAttrs,
        data,
        row,
        column,
        index,
        rowIndex,
        vm: this
      }
      this.applyDataFilter('value-attrs', params)
      return finalAttrs
    }
  }
}
