/*global _ */
import createPersistedState from 'vuex-persistedstate'

import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from './mutation-types'
const EventBus = window.VueEventBus

// state
export const state = {
  user: null,
  token: Cookies.get('token') || null,
  shadow_token: Cookies.get('shadow_token') || null,
  after_login_route: JSON.parse(Cookies.get('after_login_route') || null),
  user_fetched: false,
}

// mutations
export const mutations = {
  [types.CLEAN_TOKEN] (state) {
    state.token = null
    state.user = null
    Cookies.remove('token')
  },
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
    EventBus.$emit('user.token', token)
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
    EventBus.$emit('user.data.loaded', user)
    EventBus.$emit('user.auth', user)
    state.user_fetched = true
  },

  [types.FETCH_USER_FAILURE] (state, e) {
    EventBus.$emit('user.data.failed')
    EventBus.$emit('user.auth.failed')
    const error = new Error('Failed to retrieve user details.')
    error.$e = _.get(e, 'response.data', {})
    error.$$e = e
    throw error
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token = null
    Cookies.remove('token')
    EventBus.$emit('user.logout')
  },

  [types.UPDATE_USER] (state, { user, ...properties }) {
    if (user) {
      state.user = user
    }

    if (!_.isEmpty(properties)) {
      state.user = _.merge({}, state.user, properties)
    }
  },

  [types.SAVE_SHADOW_TOKEN] (state, { shadow_token }) {
    if (shadow_token === 'undefined') {
      shadow_token = null
    }
    state.shadow_token = shadow_token
    Cookies.set('shadow_token', shadow_token)
    EventBus.$emit('user.token.shadow', shadow_token)
  },

  [types.REMEMBER_AFTER_LOGIN_ROUTE] (state, { route, expires }) {
    state.after_login_route = route
    Cookies.set('after_login_route', JSON.stringify(route), { expires })
  },
  [types.SYNC] (state, data) {
      data = data || {}
      data.user = data.user || null
      data.token = data.token || null
      data.shadow_token = data.shadow_token || null
      data.after_login_route = data.after_login_route || null

      if (state.token !== data.token) {
        state.token = data.token
      }
      if (state.shadow_token !== data.shadow_token) {
        state.shadow_token = data.shadow_token
      }

      if (JSON.stringify(state.user) !== JSON.stringify(data.user)) {
        state.user = data.user
      }
      if (JSON.stringify(state.after_login_route) !== JSON.stringify(data.after_login_route)) {
        state.after_login_route = data.after_login_route
      }
  },
}

// actions
export const actions = {
  saveToken ({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  async pingUser ({ commit, state }) {
    if (!state.token) {
      EventBus.$emit('user.ping.failed', new Error('Missing authentication token'))
      commit(types.CLEAN_TOKEN)
    } else {
      try {
        await axios.get(`${window.addiesaas.url}/api/user/ping`)
        EventBus.$emit('user.ping.success')
        return true
      } catch (e) {
        EventBus.$emit('user.ping.failed', e)
        commit(types.CLEAN_TOKEN)
      }
    }
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get(`${window.addiesaas.url}/api/user/profile`)
      commit(types.FETCH_USER_SUCCESS, { user: data.data })
      return data.data
    } catch (e) {
      commit(types.CLEAN_TOKEN)
      commit(types.FETCH_USER_FAILURE, e)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit }) {
    try {
      await axios.post(`${window.config.baseUri}/api/logout`)
      // eslint-disable-next-line no-empty
    } catch (e) { }
    commit(types.LOGOUT)
  },

  saveShadowToken ({ commit }, payload) {
    commit(types.SAVE_SHADOW_TOKEN, payload)
  },

  authGuard({ state }, payload) {
    if (!state.user) {
      payload.$router.push({name: 'sign-in'});
    }
  },

  rememberAfterLoginRoute ({ commit }, payload) {
    commit(types.REMEMBER_AFTER_LOGIN_ROUTE, payload)
  },

  syncAuthDetails({commit}, payload) {
    commit(types.SYNC, payload)
  }
}

// getters
export const getters = {
  authUser: state => state.user,
  authToken: state => state.token,
  authCheck: state => state.user !== null,
  authFetched: state => state.user_fetched,
  shadowToken: state => state.shadow_token,
  afterLoginRoute: state => state.after_login_route,
}

export const plugins = [
  createPersistedState({
    paths: [
      'system/authentication.user',
      'system/authentication.token',
      'system/authentication.shadow_token',
      'system/authentication.after_login_route'
    ],
    key: 'auth',
  }),
]
