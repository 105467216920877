<template lang="pug">
  .b-onboarding__right.b-onboarding__right--select-blocks
    .b-onboarding__title What software systems would you like us to integrate with?
    .b-onboarding__subtitle Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

    onboarding-select-blocks(:blocks="options.softwareSystemBlocks" :selected="onboarding.software_systems || []" @select="select")

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    select(selected) {
      this.update({ software_systems: selected })
    },
  }
}
</script>
