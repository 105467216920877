import pageProps from '../../../../../../props/profile/page-props'
import contextProps from '../../../../../../props/context'

export const props = {
  ...pageProps,
  ...contextProps,
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Points History')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  loadButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.load.title', 'Show more')
    }
  },
  loadButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.load.classes', '')
    }
  },
  showLoadButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.load.show', true)
    }
  },
  closeButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.close.title', '')
    }
  },
  closeButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.close.classes', '')
    }
  },
  showCloseButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.close.show', true)
    }
  },
  pageSize: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('paginate.size', 6)
    }
  },
  dateFormat: {
    type: [Number, String, Object],
    default() {
      return this.$options.$$s('date.format', null)
    }
  },
  noHistoryMessage: {
    type: String,
    default() {
      return this.$options.$$t('noHistory.message', 'You haven\'t earned any points yet.')
    }

  }
}

export default props
