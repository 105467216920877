/*global _ */

export default {
  computed: {
    profileLocationId: {
      get() {
        let id = this.storedProfileData.location_id
        if (!id) {
          id = _.get(this.storedProfileData, 'loyalty.location_id') ||
          _.get(this.user, 'meta.preferred_location_id') ||
          _.get(this.user, 'meta.register_location_id') ||
            this.currentLocationId
          if (id) {
            this.updateProfileDataItem({'location_id': id})
            this.updateProfileDataItem({'loyalty.location_id': id})
          }
        }
        return id
      },
      set(id) {
        this.updateProfileDataItem({'location_id': id})
        this.updateProfileDataItem({'loyalty.location_id': id})
      }
    }
  }
}
