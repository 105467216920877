import pageProps from '../../../../../../../props/profile/page-props'
import contextProps from '../../../../../../../props/context'

export const props = {
  ...pageProps,
  ...contextProps,
  value: {},
  options: {},
  rewardTitle: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('rewardTitle', 'Congratulations!')
    }
  },
  showRewardTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showRewardTitle', true)
    }
  },
  rewardMessageTemplate: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('rewardMessageTemplate', `You've earned {{latestReward.claimMessageTitle}}.
       {{br}}Claim your reward or keep on earning to unlock even greater rewards.
       {{br}}{{expirationMessage}}`)
    },
  },
  topRewardMessageTemplate: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('topRewardMessageTemplate', `You've earned {{latestReward.claimMessageTitle}}.
         {{br}}Claim your reward now!
         {{br}}{{expirationMessage}}`
      )
    }
  },
  expirationMessage: {
    type: String,
    default() {
      return this.$options.$$t('expirationMessage', 'Earned credits will expire in {{expiration}} days.')
    }
  },
  confirmTitle: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('confirmTitle', 'Claim My Reward Now')
    },
  },
  declineTitle: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('declineTitle', 'Keep earning {{br}}to get a {{this.loyaltyTierStatus.nextReward.claimMessageTitle}}')
    },
  },
  allowMuteAlert: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowMuteAlert', true)
    }
  },
  muteButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.mute.title', 'Do not ask again')
    }
  },
}

export default props
