import {Vue} from '~/addiesaas'
// Components to list multiple products of a type of category etc.
import ReShopProducts from './products'
Vue.component(ReShopProducts.name, ReShopProducts)

import ReShopProductsFilters from './products/addons/filters'
Vue.component(ReShopProductsFilters.name, ReShopProductsFilters)
import ReShopProductsSort from './products/addons/sort'
Vue.component(ReShopProductsSort.name, ReShopProductsSort)
import ReShopProductsPagination from './products/addons/pagination'
Vue.component(ReShopProductsPagination.name, ReShopProductsPagination)
import ReShopProductsPaginationStatus from './products/addons/pagination/status'
Vue.component(ReShopProductsPaginationStatus.name, ReShopProductsPaginationStatus)

import ReShopProductsItemGeneric from './products/items/generic'
Vue.component(ReShopProductsItemGeneric.name, ReShopProductsItemGeneric)

import ReShopProductsItemGenericCard from './products/items/generic-card'
Vue.component(ReShopProductsItemGenericCard.name, ReShopProductsItemGenericCard)

import ReShopProductsItemReservation from './products/items/reservation'
Vue.component(ReShopProductsItemReservation.name, ReShopProductsItemReservation)


// Components to show individual product details
import ReShopProductEditor from './product/editor'
Vue.component(ReShopProductEditor.name, ReShopProductEditor)
import ReShopProductEditorGeneric from './product/editor/items/generic'
Vue.component(ReShopProductEditorGeneric.name, ReShopProductEditorGeneric)


import ReShopProduct from './product'
Vue.component(ReShopProduct.name, ReShopProduct)

import ReShopProductItemCustom from './product/items/custom'
Vue.component(ReShopProductItemCustom.name, ReShopProductItemCustom)
import ReShopProductItemGeneric from './product/items/generic'
Vue.component(ReShopProductItemGeneric.name, ReShopProductItemGeneric)

import ReShopProductItemSubscription from './product/items/subscription'
Vue.component(ReShopProductItemSubscription.name, ReShopProductItemSubscription)

import ReShopProductItemSubscriptionPlan from './product/items/subscription/plan'
Vue.component(ReShopProductItemSubscriptionPlan.name, ReShopProductItemSubscriptionPlan)

import ReShopProductItemReservation from './product/items/reservation'
Vue.component(ReShopProductItemReservation.name, ReShopProductItemReservation)

import ReReservationTimeslotInput from './product/items/reservation/timeslots'
Vue.component(ReReservationTimeslotInput.name, ReReservationTimeslotInput)

// alias of ReShopProductItemSubscription (@todo redundant - can be deleted)
import ReShopSubscription from './subscription'
Vue.component(ReShopSubscription.name, ReShopSubscription)

export default {
  ReShopProducts,
  ReShopProductsFilters,
  ReShopProductsSort,
  ReShopProductsPagination,
  ReShopProductsPaginationStatus,
  ReShopProductsItemReservation,

  ReShopProductEditor,
  ReShopProductEditorGeneric,

  ReShopProduct,
  ReShopSubscription,
  ReShopProductsItemGeneric,
  ReShopProductItemGeneric,
  ReShopProductsItemGenericCard,
  ReShopProductItemSubscription,
  ReShopProductItemSubscriptionPlan,
  ReShopProductItemReservation,
  ReReservationTimeslotInput
}
