/*global _, addiesaas, $, debugLog */
export const name = 're-form'
// generic setup
import services from './services'
import {allValidationsRules as validationsRules, defaultFormFields, parseFieldsValidations} from './form'
import props, {OPTIONS_PROP} from './props'
export {props, OPTIONS_PROP, validationsRules, defaultFormFields, parseFieldsValidations, services}

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'

export const mixins = [ComponentMixin, FormMixin, FormForceUpdateMixin]

export const EventBus = window.VueEventBus

const propDefs = props
export const data = {
  propDefs,
  optionsPropName: OPTIONS_PROP,
  loadedFormOptions: {},
  fields: {},
  vRoot: {},

  isProcessing: null,
  isPreparing: null,
  isValid: null,

  canShowPopup: null,
  showingSingleErrorLayout: false,
  hasConfirmation: false,

  locals: _.mapValues(props, p => null),
}

export default {
  OPTIONS_PROP,
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  parseFieldsValidations,

  EventBus,
  services
}
