import userComponents from './user'
import profileComponents from './profile'
import socialComponents from './social'
import cardComponents from './card'
import shopComponents from './shop'
import cartComponents from './cart'

export default {
  ...userComponents,
  ...profileComponents,
  ...socialComponents,
  ...cardComponents,
  ...shopComponents,
  ...cartComponents,
}
