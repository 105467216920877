/*global _ */
import {EventBus, DataFilter} from '../lib/utils'
import {RE} from '../lib/config'

const RELocation = RE.location || {}

export default {
  data() {
    return {
    }
  },
  computed: {
    currentLocation() {
      return RELocation || {}
    },
    currentLocationId() {
      return this.currentLocation.id
    },
    currentLocationName() {
      return this.currentLocation.name
    },
    currentLocationTitle() {
      return this.currentLocation.display_name
    }
  },
  methods: {

  }
}

