<template lang="pug">
  .b-scanner
    camera-scanner(v-if="qrSource" @decode="decoded" ref="camera" :delay-between-scans="Number(settings.delayBetweenScans)" @flashlight="flashlight = $event")
    error-state(v-if="!manualSource && backendError" :error="backendError" :transparent="false" @close="scanMore")
    .b-scanner__mask(v-if="manualSource")
      .b-scanner__code-entry
        form-group(:validator="$v.manualNumber" label="Voucher Number:" attribute="number" v-slot="props" :messages="{ backendError }")
          input(
            v-model="$v.manualNumber.$model"
            :class="props.classes"
            type="text"
            placeholder="Enter voucher number"
            @input="manualInput"
          )
        v-button.btn.btn-primary.btn-block.btn-almost-sm(@click="manual" :loading="manualSubmitting") Confirm
    voucher-state(v-if="object && objectType == 'voucher'" :voucher="object" @mounted="objectMounted" ref="voucher" :transparent="!manualSource")
    subscription-state(v-if="object && objectType == 'subscription'" :subscription="object" @mounted="objectMounted" ref="subscription" :transparent="!manualSource")
    coupon-state(v-if="object && objectType == 'coupon'" :coupon="object" @mounted="objectMounted" ref="coupon" :transparent="!manualSource")
    .b-scanner__header
      .b-scanner__header-left
        img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/camera-rotate-icon.svg`" @click="rotate")
        img.m-l-10(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/camera-flashlight-${flashlightText}-icon.svg`" @click="switchFlashlight")
      .b-scanner__header-right
        router-link(:to="{ name: 'vouchermobilescanner-settings' }")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/settings-icon.svg`")
      .b-scanner__header-center
        .b-scanner__switcher
          .b-scanner__switcher-switch(:class="{ active: redeemMode }" @click="switchMode") Redeem
          .b-scanner__switcher-switch(:class="{ active: validateMode }" @click="switchMode") Validate
    .b-scanner__footer
      .btn.btn-primary.btn-almost-sm.btn-block.m-t-10(v-if="scanNewCodeButton" @click="scanMore") Scan New Code
      .text-center(v-else)
        .b-scanner__source(:class="{ active: qrSource }" @click="switchSource")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/scan-qr-code-button-active.svg`" v-if="qrSource")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/scan-qr-code-button.svg`" v-else)
        .b-scanner__source(:class="{ active: manualSource }" @click="switchSource")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/scan-manual-button-active.svg`" v-if="manualSource")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/scan-manual-button.svg`" v-else)


</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import CameraScanner from '../components/CameraScanner'
import ErrorState from '../components/ErrorState'
import VoucherState from '../components/VoucherState'
import SubscriptionState from '../components/SubscriptionState'
import CouponState from '../components/CouponState'
import { required } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'
import baseUrl from '../mixins/base-url'

export default {
  components: {
    CameraScanner,
    ErrorState,
    VoucherState,
    SubscriptionState,
    CouponState,
  },

  mixins: [
    baseUrl,
  ],

  data: () => ({
    config: window.addiesaas.config,
    object: null,
    objectType: null,
    redeemMode: true,
    qrSource: true,
    manualNumber: null,
    manualSubmitting: false,
    backendError: null,
    flashlight: false,
  }),

  validations: {
    manualNumber: {
      required,
      backendError() {
        return !this.backendError
      },
    },
  },

  computed: {
    ...mapGetters({
      settings: 'VoucherMobileScanner/settings',
    }),
    validateMode() {
      return !this.redeemMode
    },
    manualSource() {
      return !this.qrSource
    },
    scanNewCodeButton() {
      return this.object && this.redeemMode && !this.settings.autoRedeem
    },
    flashlightText() {
      return this.flashlight ? 'on' : 'off'
    }
  },

  beforeRouteEnter (to, from, next) {
    next(window.addiesaas.store.getters.authCheck ? true : { name: 'vouchermobilescanner-sign-in' })
  },

  methods: {
    async decoded(text) {
      const qrCode = JSON.parse(text)

      if (Array.isArray(qrCode) && qrCode.length == 2) {
        const [[type, identifier], hash] = qrCode

        if (!this.object || this.objectType != type || this.object.id != identifier) {
          this.$refs.camera.pause()

          try {
            const { data: { data } } = await axios.post(`${this.baseUrl}/api/admin/qr-code/check`, {
              type,
              identifier,
              hash,
            })

            this.object = data
            this.objectType = type
          } catch (e) {
            await this.handleBackendException(e)
          }
        }
      }
    },
    async manual() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.manualSubmitting = true

        const { data: { data } } = await axios.post(`${this.baseUrl}/api/admin/qr-code/manual-check`, {
          identifier: this.manualNumber,
        })

        this.object = data.object.data
        this.objectType = data.type
      } catch (e) {
        await this.handleBackendException(e)
      } finally {
        this.manualSubmitting = false
      }
    },
    rotate() {
      this.$refs.camera.rotate()
    },
    switchMode() {
      this.redeemMode = !this.redeemMode
      this.scanMore()
    },
    switchSource() {
      this.qrSource = !this.qrSource
      if (this.qrSource) {
        this.scanMore()
      }
    },
    objectMounted() {
      if (this.redeemMode && this.settings.autoRedeem) {
        this.$refs.camera.resume()
      }
    },
    scanMore() {
      this.backendError = null
      this.object = this.objectType = null
      this.$refs.camera.resume()
    },
    manualInput() {
      this.backendError = null
    },
    switchFlashlight() {
      this.$refs.camera.switchFlashlight()
    },
    async handleBackendException(e) {
      if (e.response && e.response.status == 401) {
        await window.addiesaas.store.dispatch('logout')
        this.$router.push({ name: 'vouchermobilescanner-sign-in' })
      } else {
        this.backendError = getFirstErrorMessage(e)
      }
    },
  },
}
</script>

<style lang="scss">
.b-scanner {
  height: 100%;
  background-color: black;
  color: white;
  padding-top: 20px;
  &--dark {
    background-color: #373451;
  }
  a {
    color: #878DA9;
  }
  &__header {
    z-index: 1;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    &-left,
    &-right {
      position: relative;
      top: 6px;
    }
    &-left {
      float: left;
    }
    &-right {
      float: right;
    }
    &-center {
      text-align: center;
      margin-left: 50px;
      margin-right: 50px;
      min-height: 36px;
    }
  }
  &__switcher {
    display: inline-block;
    background-color: #E7E9F2;
    border-radius: 18px;
    line-height: 18px;
    &-switch {
      display: inline-block;
      padding: 9px;
      color: #373451;
      &.active {
        background-color: #8F8CF1;
        color: white;
        border-radius: 18px;
      }
    }
  }
  &__camera-window {
    position: absolute;
    top: 0;
    height: 100%;
    background: rgba(55, 52, 81, 0.7);
  }
  &__mask {
    position: absolute;
    top: 0;
    height: 100%;
    background: rgba(55, 52, 81, 0.7);
    width: 100%;
  }
  &__code-entry {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    .form-group {
      margin-bottom: 20px;
      label {
        margin-bottom: 4px;
        font-size: 13px;
      }
      .form-control {
        background-color: transparent;
        border-color: white;
        color: white;
        &::placeholder {
          color: #878DA9;
        }
      }
    }
  }
  &__text {
    &--center {
      text-align: center;
    }
    &--sm-title {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
    }
  }
  &__settings {
    padding: 20px 30px;
  }
  &__source {
    display: inline-block;
    padding: 3px;
    margin: 3px;
    border: 1px solid transparent;
    border-radius: 26px;
    &.active {
      border-color: white;
    }
  }
  &__footer {
    position: absolute;
    bottom: 8%;
    width: 100%;
    padding: 0 30px;
  }
}
</style>
