/*global addiesaas */
import {Vue} from '~/addiesaas'

export const create = (componentName, options = {}) => {
  const vueInit = addiesaas.initSync || (addiesaas.spa && addiesaas.spa.initSync) || initSync
  const el = options.el

  const initOptions = {
    render(h) {
      return h(componentName, options)
    }
  }
  if (el) {
    initOptions.el = el
  }

  const vueApp = vueInit(initOptions)

  if (!el) {
    vueApp.$mount()
  }

  const instance = vueApp.$children[0] || vueApp
  return instance
}

export const getParentSlots = function (vm) {
  vm = vm || this
  if (!vm) {
    return []
  }
  return [
    vm.$parent && vm.$parent.$slots,
    vm.$slots,
    vm.$parent && vm.$parent.$scopedSlots,
    vm.$scopedSlots,
  ]
}
const initSync = (options) => {
  const customVueStrap = {...options || {}}
  const VueApp = new Vue(customVueStrap)
  return VueApp
}
