/*global _ */
import loadJS from 'load-js'
const positions = {}
const head = positions.head = document.getElementsByTagName('head')[0]
positions.body = document.getElementsByTagName('body')[0]

export const addExternalCSS = function (paths, position) {
  if (!paths) {
    return
  }
  _.forOwn(_.filter(_.castArray(paths)), path => {
    const link = document.createElement("link")
    link.href = path
    link.type = "text/css"
    link.rel = "stylesheet"

    const positionElm = positions[position] || head
    positionElm.appendChild(link)
  })
}

export const addInlineCSS = function (css, position) {
  if (!css) {
    return
  }
  const style = document.createElement("style");

  style.type = 'text/css'
  // This is required for IE8 and below.
  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
  const positionElm = positions[position] || head
  positionElm.appendChild(style)
}

export const addExternalJS = function (paths, callback) {
  if (!paths) {
    return
  }
  paths = _.filter(_.map(_.castArray(paths), path =>  {
    if (_.isString(path)) {
      return {url: path}
    }
    return path
  }))
  loadJS(paths).then(callback || (() => {}))
}

export default {addExternalCSS, addInlineCSS, addExternalJS}
