import sharedProps from '../../../../props/profile/cards'
import pageProps from '../../../../props/profile/page-props'

const addCardProps = {
  autoLoad: {
    type: [String, Array, Boolean],
    default() {
      return this.$options.$$s('autoLoad', false) // autoLoad data for other related pages. pass string or array of the profile page names
    }
  },
  initAddCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.init.title', 'Add one more Play Cards') //@change: initAddCardButtonTitle / addedCardButtonTitle
    }
  },
  purchaseCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('purchaseCard.button.title', 'Purchase a new play card') //@change: purchaseCardButtonTitle
    }
  },
  archivePurchaseCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('archive.purchaseCard.button.title', 'Purchase a play card') //@added:
    }
  },
  purchaseCardButtonLink: {
    type: [String, Object],
    default() {
      return this.$options.$$s('purchaseCard.button.link', {name: 'playcards.buy'}) //@change: purchaseCardButtonLink
    }
  },

  addCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.button.title', 'Add play card') //@change: addCardButtonTitle
    }
  },
  addCardModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.modal.title', 'Add Play Card') //@change: addCardModalTitle
    }
  },
  addCardSuccessModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.modal.successTitle', 'Your Play Card has been added.') //@change: addCardSuccessModalTitle
    }
  },
}

const reloadCardProps = {
  reloadCardRoute: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('reload.route', {name: 'playcard.reload'})
    }
  },
  reloadCardType: {
    type: String,
    default() {
      return this.$options.$$s('reload.type', 'playcard_reload')
    }
  },
}

const cardFeatureProps = {
  showStatus: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showStatus', true) //@added:
    }
  },
  showBalance: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showBalance', true) //@added:
    }
  },
  forceShowBalance: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.forceShowBalance', false) //@added:
    }
  },
  showNumber: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showNumber', true)  //@added:
    }
  },
  showNumberMasked: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showNumberMasked', true)  //@added:
    }
  },
  maskedNumber: {
    type: String,
    default() {
      return this.$options.$$t('card.mask', this.$options.$$s('card.mask', null))  //@added:
    }
  },
  showTicket: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showTicket', true)  //@added:
    }
  },
  showBonus: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showBonus', true)  //@added:
    }
  },
  showPoints: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.showPoints', true)  //@added:
    }
  },

  allowReload: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowReload', true) //@added:
    }
  },
  allowSetPrimary: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowSetPrimary', true) //@added:
    }
  },
  allowForgotCode: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowForgotCode', true) //@added:
    }
  },
  allowResend: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowResend', true) //@added:
    }
  },
  allowArchive: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowArchive', true) //@added:
    }
  },
  allowTransferBalance: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('card.allowTransferBalance', false) //@added:
    }
  },

}

const modalProps = {
  numberInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalFooter', 'The Play Card number can be found in the email that was sent after the purchase was done or under the \'My Cards\' section of your Account.')
    }
  },
  codeInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalFooter', 'The CVV or Card Code can be found in the email that was sent after the the play card was claimed by you.')
    }
  },
}

const genericProps = {
  emptyCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyCardMessage', 'No Play Card has been associated to this account. To add a Play Card click the button above.')
    }
  },
  showLocationFilter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLocationFilter', false)
    }
  },
  emptyFilteredCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyFilteredCardMessage', 'No card has been associated to this location. To add a card click the button above.')
    }
  },
}

export const props = {
  ...pageProps,
  ...sharedProps,

  ...addCardProps,
  ...reloadCardProps,
  ...modalProps,
  ...cardFeatureProps,
  ...genericProps
}

export default props
