const defaultTable = {
  "columns": [
    {
      "name": "name",
      "key": "fullName",
      "title": "Name",
    },
    {
      "name": "relation",
      "title": "Relation",
    },
    {
      "name": "email",
      "title": "Email",
    },
    {
      "name": "birthdate",
      "title": "Birthday",
      "format": "formatDate",
      "valuePath": "birthdate",
    },
    {
      "name": "actions",
      "showTitle": false,
      "rowActions": {
        "edit": {
          "component": "re-button",
          "componentAttrs": {
            "title": "Edit",
            "showTitle": true,
          },
          "event": "edit"
        },
        "delete": {
          "component": "re-button",
          "componentAttrs": {
            "title": "Delete",
            "showTitle": true,
          },
          "event": "delete"
        },
      }
    },
  ],
  "view": {
    "type": "auto"
  },
  "eventSpace": "profile.family.table",
  "dataFilterSpace": "profile.family.table"
}

export default {
  data() {
    return {
      defaultTable
    }
  },
  computed: {
    tableComponentAttrs() {
      return {
        ...this.tableComponentMainAttrs,
        disabled: !!this.editingMember,
        activeRow: this.editingRow
      }
    },
  },
}
