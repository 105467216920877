export default {
  options: {
    type: [Array, Object, Function],
    default: () => ({})
  },
  // Set it to true when options is similar to : [{value, label}, {}, {}]
  useArrayOptions: {
    type: [Boolean, Number, String],
    default: false
  },
  blankOption: {
    type: [String, Number],
    default: null
  },
  blankOptionDisabled: {
    type: [String, Number, Boolean],
    default: true
  },
  endBlankOption: {
    type: [String, Number],
    default: null
  },
  endBlankOptionDisabled: {
    type: [String, Number, Boolean],
    default: true
  },
}
