import {context} from '../../../../props/context'

const addCardProps = {
  autoLoad: {},
  initAddCardButtonAttributes: {},
  initAddCardButtonTitle: {
    type: String,
    default: 'Add one more Card'
  },

  purchaseCardButtonTitle: {
    type: String,
    default: 'Purchase a new card'
  },
  purchaseCardButtonAttributes: {},

  allowTransferBalance: {},
  initTransferBalanceButtonTitle: {
    type: String,
    default: 'Transfer Balance'
  },
  initTransferBalanceButtonAttributes: {},

  archivePurchaseCardButtonTitle: {
    type: String,
    default: 'Purchase a new card'
  },
  purchaseCardButtonLink: {
    type: [String, Object],
    default: null
  },
  reloadCardRoute: {
    type: [String, Object],
    default: null
  },
  reloadCardType: {
    type: [String, Object],
    default: null
  },

  addCardClass: {
    type: [String, Array, Object],
    default: ''
  },
  addCardTitle: {
    type: String,
    default: null
  },
  addCardShowTitle: {
    type: [Boolean, Number, String],
    default: true
  },

  addCardNumberLabel: {
    type: String,
    default: null
  },
  addCardNumberPlaceholder: {
    type: String,
    default: null
  },
  addCardNumberClass: {
    type: String,
    default: null
  },
  addCardCodeLabel: {
    type: String,
    default: null
  },
  addCardCodePlaceholder: {
    type: String,
    default: null
  },
  addCardCodeClass: {
    type: String,
    default: null
  },

  addCardButtonTitle: {
    type: String,
    default: 'Add card'
  },
  addCardButtonClass: {
    type: [String, Array, Object],
    default: ''
  },
  addCardShowCancelButton: {
    type: [Boolean, Number, String],
    default: true
  },
  addCardCancelButtonTitle: {
    type: String,
    default: 'Cancel'
  },
  addCardCancelButtonClass: {
    type: [String, Array, Object],
    default: ''
  },

  isProcessingAddCard: {
    type: Boolean,
    default: false
  },
  addingMessage: {
    type: String,
    default: null
  },

  addCardHasResendCode: {},
  addCardResendCodeMessage: {},
  addCardResendCodeLinkLabel: {},
  addCardHasHelpNumberLink: {},
  addCardHelpNumberLinkLabel: {},
  addCardHasHelpCodeLink: {},
  addCardHelpCodeLinkLabel: {},

  showAddCardInModal: {
    type: [Boolean, Number, String],
    default: false
  },
  addCardModalTitle: {
    type: String,
    default: 'Add Card'
  },
  addCardSuccessModalTitle: {
    type: String,
    default: 'Your Card has been added.'
  },
}
const actionProps = {

}
const modalProps = {
  modalClasses: {
    type: [String, Array, Object],
    default: null
  },
  infoButtonTitle: {
    type: String,
    default: 'Ok'
  },
  infoButtonClass: {
    type: [String, Array, Object],
    default: null
  },
  numberInfoModalTitle: {
    type: String,
    default: 'Card Number'
  },
  numberInfoModalHeader: {
    type: String,
    default: null
  },
  numberInfoModalFooter: {
    type: String,
    default: 'The Card number can be found in the email that was sent after the purchase was done or under the \'My Cards\' section of your Account.'
  },
  numberInfoModalImgSrc: {
    type: String,
    default: null
  },

  codeInfoModalTitle: {
    type: String,
    default: 'CVV or Card Code'
  },
  codeInfoModalHeader: {
    type: String,
    default: null
  },
  codeInfoModalFooter: {
    type: String,
    default: 'The CVV or Card Code can be found in the email that was sent after the the card was claimed by you.'
  },
  codeInfoModalImgSrc: {
    type: String,
    default: null
  },

  resendModalTitle: {
    type: String,
    default: null
  },
  resendModalClasses: {
    type: [String, Array, Object],
    default: null
  },
  resendModalHeader: {
    type: String,
    default: 'Resend your code using the email address associated with your Card <b>OR</b> your Card Number.'
  },
  resendModalButtonTitle: {
    type: String,
    default: 'Recover'
  },
  resendModalButtonClasses: {
    type: String,
    default: null
  },
  resendModalErrorMessage: {
    type: String,
    default: null
  },
  resendModalRecoveredEmail: {
    type: String,
    default: null
  },
  resendModalSuccessMessage: {
    type: String,
    default: 'The Card code has been sent to the following email address'
  },
  resendModalSuccess: {
    type: Boolean,
    default: false,
  },
  resendModalResendMessage: {
    type: String,
    default: 'The Card code has been resent to the following email address'
  },
}

export const props = {
  translations: {},
  settings: {},
  cardType: {
    type: String,
    default: 'giftcard' // playcard
  },
  classes: {
    type: [String, Array, Object],
    default: ''
  },

  emptyCardMessage: {
    type: String,
    default: 'No card has been associated to this account. To add a card click the button above.'
  },

  tabs: {
    type: [Array, Object],
    default: null
  },
  cardsList: {
    type: [Array, Object],
    default: null
  },

  showProductImage: {
    type: [Boolean, Number, String],
    default: true
  },
  productImageClasses: {
    type: [String, Array, Object],
    default: null
  },

  errorMessage: {
    type: String,
    default: null
  },

  isLoading: {
    type: [Boolean, Number, String],
    default: true
  },
  loadingMessage: {
    type: String,
    default: 'Loading cards'
  },

  paginate: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('table.paginate', false)
    }
  },
  pageSize: {
    type: [Number, String],
    default() {
      return this.$options.$$s('table.page.size', 3)
    }
  },
  pageStart: {
    type: [Number, String],
    default() {
      return this.$options.$$s('table.page.start', 0)
    }
  },
  scrollToTabMaxWidth: {
    type: [String, Number, Boolean],
    default() {
      return this.$options.$$s('scrollToTop.maxWidth', false)
    }
  },
  showLocationFilter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLocationFilter', false)
    }
  },
  locationFilterPosition: {
    type: [Boolean, String],
    default() {
      // top => at the top when buttons are at the bottom using showButtonsAtBottom
      // buttons => along with buttons
      return this.$options.$$s('locationFilterPosition', 'top')
    }
  },
  emptyFilteredCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyFilteredCardMessage', 'No card has been associated to this location. To add a card click the button above.')
    }
  },
  showButtonsAtBottom: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showButtonsAtBottom', false)
    }
  },

  context,
  ...addCardProps,
  ...actionProps,
  ...modalProps
}
export default props
