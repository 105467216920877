import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'
import {props as customAmountProps} from '../../../../mixins/shop/card/custom-amount'

const localProps = {
  token: {},
  item: {},
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Buy play card')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  cartItemEditable: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('cartItemEditable', true)
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Add to cart')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },
  updateButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('updateButtonTitle', 'Update Cart')
    }
  },
  updateButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('updateButtonClasses', null)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Your order is being processed.')
    }
  },
  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },

  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },

  products: {
    type: Object,
    default: null
  },
  productsFilters: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$s('products.filters', null)
    }
  },
  noAgreeToTerms: {
    type: [Boolean, Number, String],
    default() {
      return !this.$options.$$s('showTerms', true)
    }
  },
  showBonusBox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showBonusBox', true)
    }
  },
  showGroupBoxTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showGroupBoxTitle', false)
    }
  },
  groupBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('groupBoxTitle', 'Select your amount')
    }
  },
  showProductSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showProductSelector', true)
    }
  },
  productLabelFormat: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('productLabelFormat') || this.$options.$$t('productLabelFormat') || null
    }
  },

  amountClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('amountClass', null)
    }
  },
  showAmountTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showAmountTitle', true)
    }
  },
  amountTitle: {
    type: String,
    default() {
      return this.$options.$$t('amountTitle', 'Select your amount')
    }
  },
  showAmountSubtitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showAmountSubtitle', true)
    }
  },
  amountSubtitle: {
    type: String,
    default() {
      return this.$options.$$t('amountSubtitle', 'Please choose the play card amount')
    }
  },
  amountBoxLabel: {
    type: String,
    default() {
      return this.$options.$$t('amountBoxLabel', 'Amount')
    }
  },
  amountSelectDefault: {
    type: String,
    default() {
      return this.$options.$$t('amountSelectDefault', 'Select a Card')
    }
  },
  noQuantity: {
    type: [Boolean, Number, String],
    default() {
      return !this.$options.$$s('showQuantity', true)
    }
  },
  quantityClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('quantityClass', null)
    }
  },
  showQuantityTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showQuantityTitle', true)
    }
  },
  quantityTitle: {
    type: String,
    default() {
      return this.$options.$$t('quantityTitle', 'Select')
    }
  },
  showQuantitySubtitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showQuantitySubtitle', false)
    }
  },
  quantitySubtitle: {
    type: String,
    default() {
      return this.$options.$$t('quantitySubtitle', '')
    }
  },
  quantityLabel: {
    type: String,
    default() {
      return this.$options.$$t('quantityLabel', 'Quantity')
    }
  },
  productType: {
    type: String,
    default() {
      return this.$options.$$s('productType', 'playcard_sale')
    }
  },

  // insert to cart props
  showInsertToCartButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showInsertToCartButton', false)
    }
  },
  insertToCartButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('insertToCartButtonClass', null)
    }
  },
  insertToCartButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('insertToCartButtonTitle', 'Add to cart')
    }
  },
  showInsertSuccessMessageAs: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showInsertSuccessMessageAs', 'alert')
    }
  },
  insertSuccessMessage: {
    type: [String],
    default() {
      return this.$options.$$t('insertSuccessMessage', '[#quantity=>Card has|Cards have#] been successfully added to cart.')
    }
  },
  insertSuccessMessageOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('insertSuccessMessageOptions', {})
      const $s = this.$options.$$s('insertSuccessMessageOptions', {})
      return _.merge({}, $t, $s)
    }
  },

  // location
  useLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useLocation', false)
    }
  },
  filterProductByLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('filterProductByLocation', 'strict')
    }
  },
  hideOtherFieldsOnNoLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('hideOtherFieldsOnNoLocation', true)
    }
  },
  locationInputOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('form.fields.location_id', {})
      const $s = this.$options.$$s('form.fields.location_id', {})
      return _.merge({}, $t, $s)
    }
  },
  rememberLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('rememberLocation', true)
    }
  },
  ...customAmountProps,
  limitQuantity: {
    type: [Boolean, Object, String],
    default() {
      return this.$options.$$s('limitQuantity', false)
    }
  },
  limitQuantityByLocation: {
    type: [Boolean, Object, String],
    default() {
      return this.$options.$$s('limitQuantityByLocation', false)
    }
  }
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
