export const name = 're-profile'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import UserHistoryMixin from '../../../../mixins/external/user-history'
import ComponentMixin from '../../../../mixins/components/complex'
import ProfileMixin from '../../../../mixins/profile'
import ProfileLocationMixin from '../../../../mixins/profile/location'
import MenuMixin from '../../../../mixins/profile/menu'
import AutoLoadMixin from './autoload'
import DesignerMixin from './designer'

export const mixins = [
  ComponentMixin,
  ProfileMixin,
  ProfileLocationMixin,
  MenuMixin,
  AutoLoadMixin,
  UserHistoryMixin,
  DesignerMixin
]

import Vnodes from '../../../elements/vnodes'
export const components = {Vnodes}
export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  components,
  data,
  EventBus,
}
