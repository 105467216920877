import Base, {
  axios,
  setPromise,
  getEndpoint, omitId,
} from '../../base'

export {
  APIURI,
  client_secret,
  client_id,
  getFirstErrorMessage,
  getErrorMessage,
  getErrorMessages,
  getErrorMessagesWithKeys,
  endpoints,
  services,
  handlePromise,
  promise,
  setPromise,
  getEndpoint,
} from '../../base'

const KEY = 'user.social.storage'
const PATH = '/customer/user/data-storage'

const myServices = {}

export const all = myServices.all = async (payload, options = {}) => {
  const endPoint = getEndpoint(`${KEY}.all`, payload, `${PATH}/storage`, options)
  const {data = {}} = await axios.get(endPoint, {params: omitId(payload)})
  return data
}
setPromise(`${KEY}.all`, all)

export const get = myServices.get = async (payload, options = {}) => {
  const endPoint = getEndpoint(`${KEY}.get`, payload, `${PATH}/storage/{id}`, options)
  const {data = {}} = await axios.get(endPoint, {params: omitId(payload)})
  return data
}
setPromise(`${KEY}.get`, get)

export const authorize = myServices.authorize = async (payload, options = {}) => {
  const endPoint = getEndpoint(`${KEY}.authorize`, payload, `${PATH}/storage/{id}/authorize`, options)
  const {data = {}} = await axios.post(endPoint, omitId(payload))
  return data
}
setPromise(`${KEY}.authorize`, authorize)

export const disconnect = myServices.disconnect = async (payload, options = {}) => {
  const endPoint = getEndpoint(`${KEY}.disconnect`, payload, `${PATH}/storage/{id}/disconnect`, options)
  const {data = {}} = await axios.post(endPoint, omitId(payload))
  return data
}
setPromise(`${KEY}.disconnect`, disconnect)

export const getFolders = myServices.getFolders = async (payload, options = {}) => {
  payload = payload || {}
  const endPoint = getEndpoint(`${KEY}.folders.get`, payload, `${PATH}/folders/{id}`, options)
  const {data = {}} = await axios.get(endPoint, {params: omitId(payload)})
  return data
}
setPromise(`${KEY}.folders.get`, getFolders)

export const getFiles = myServices.getFiles = async (payload, options = {}) => {
  payload.count = payload.count || 10
  const endPoint = getEndpoint(`${KEY}.files.get`, payload, `${PATH}/files/{id}`, options)
  const {data = {}} = await axios.get(endPoint, {params: omitId(payload)})
  return data
}
setPromise(`${KEY}.files.get`, getFiles)

export default {
  ...Base,
  ...myServices
}
