/*global _ */
import {Vue} from '~/addiesaas'
import VueI18n from 'vue-i18n'
import {hasThisBracketsTemplate, hasTcBracketsTemplate, splitByTcBracketsTemplate, templify} from './utils'

const ad = window.addiesaas || (window.addiesaas = {})
const adConfig = ad.config || {}
export const {locale = 'en-US'} = adConfig
export const LTLD ='frontend' // Language TLD
export const rootTranslations = _.get(adConfig, 'translations', {})
export const translations = _.get(rootTranslations, LTLD, {})

export const sliceTranslations = (space, merge = 'shared', hasRoot = true, mergeData = {}) => {
  const root = _.omit(translations || {}, ['this'])
  let t = hasRoot ? root : {}
  merge = !space ? {} : _.merge({}, _.get(root, merge, {}))
  space = space || root
  if (_.isString(space)) {
    space =  _.get(root, space, {})
  }
  let dynamicMerge = _.get(space, '$merge')
  if (dynamicMerge) {
    if (_.isString(dynamicMerge)) {
      dynamicMerge = dynamicMerge.replace(' ', '').split(',')
    }
    if (!_.isEmpty(dynamicMerge)) {
      const items = _.clone(dynamicMerge)
      dynamicMerge = {}
      _.forOwn(items, (i) => {
        dynamicMerge = _.merge({}, dynamicMerge, _.get(root, i, {}))
      })
    }
  }
  if (_.isEmpty(dynamicMerge) || (!_.isObject(dynamicMerge) && !_.isArray(dynamicMerge))) {
    dynamicMerge = {}
  }
  t.this = _.merge({}, merge, dynamicMerge, space, mergeData || {})
  return _.cloneDeep(t)
}

const tcTempliParse = function (template, value, data) {
  if (/{[^}]+?}/.test(template)) {
    const templiData = {
      n: value,
      ...(_.isObject(data) ? data : {}),
    }
    return templify(template, templiData, ['{', '}'])
  }
  return template
}

const multiPluralizeTranslator = function (template, data, ...rest) {
  if (hasTcBracketsTemplate(template)) {
    const items = splitByTcBracketsTemplate(template)
    return items.map(item => {
      if (hasTcBracketsTemplate(item)) {
        item = _.trimEnd(_.trimStart(item, '[#'), '#]')
        let key = item
        const withKey = item.split('=>', 2)
        const isCustomKey = withKey.length > 1
        if (isCustomKey) {
          key = withKey[0]
          const prefix = _.uniqueId('_customPluTrans__' + this._uid + '__')
          item = prefix + key
          const message = {
            [item]: withKey[1]
          }
          this.$i18n.mergeLocaleMessage(this.$i18n.locale, message)
        }
        const value = _.isObject(data) ? _.get(data, key, 0) : data
        const parsedItem = tcTempliParse(this.$$tc(item, value, value), value, data)
        return parsedItem
      }
      return tcTempliParse(item, data, data)
    }).join('')
  }
  return template
}

export const factory = function (method, me) {
  if (!me) {
    return () => {}
  }
  const i18n = me && me.$i18n

  let translator = (path, defaultValue = '', ...rest) => {
    const hasTcPipe = method === 'tc' && /\|/.test(path)
    let altPath
    if (!hasTcPipe) {
      path = _.trimEnd(path, '.')
      if (_.startsWith(path, '.')) {
        path = _.trimStart(path, '.')
        altPath = path
      } else {
        altPath = path
        path = 'this.' + path
      }
    }

    let hasPath = hasTcPipe || i18n.te(path)
    if (!hasPath && altPath !== path) {
      hasPath = i18n.te(altPath)
      if (hasPath) {
        path = altPath
      }
    }

    if (hasPath && method === 'tc' && hasTcBracketsTemplate(path)) {
      return multiPluralizeTranslator.call(me, path, ...rest)
    }
    let value = hasPath ? i18n[method](path, ...rest) : defaultValue
    if (hasTcPipe) {
      return tcTempliParse(value, ...rest)
    }
    return value
  }

  if (method === 't') {
    const basicTranslator = translator
    translator = (path, defaultValue = '', ...rest) => {
      let value = basicTranslator(path, defaultValue, ...rest)
      if (value) {
        let data = rest && rest[0]
        if (data && !_.isObject(data)) {
          data = rest[1]
        }
        if (data && !_.isObject(data)) {
          data = {}
        }
        if (hasThisBracketsTemplate(value)) {
          value = me.$$templify(value, data, ['[[', ']]'])
        }
      }
      return value
    }
  }
  return translator
}

export const setTranslations = function (space, mergeStrategy = 'shared', hasRoot = true, mergeParent = false, mergeData = {}) {
  mergeStrategy = _.isNil(mergeStrategy) ? 'shared' : mergeStrategy
  hasRoot = _.isNil(mergeStrategy) ? true : hasRoot
  mergeParent = _.isNil(mergeParent) ? false : mergeParent

  const UNFN = (key, dValue) => dValue
  //this.$$debugLog('lang space', space, this)
  this.$options.$$t = UNFN
  this.$options.$$tc = UNFN
  this.$options.$$d = UNFN
  this.$options.$$n = UNFN

  if (!this.$i18n) {
    //this.$$debugLog('no .$i18n')
    return false
  }
  if (space instanceof VueI18n) {
    //this.$$debugLog('lang space is a Vue18n', space)
    space = space.messages
  } else {
    //this.$$debugLog('slice translations')
    space = sliceTranslations(space, mergeStrategy, hasRoot, mergeData)
  }
  if (mergeParent) {
    //this.$$debugLog({setSpace: space})
    this.$i18n.mergeLocaleMessage(this.$i18n.locale, space)
  } else {
    this.$i18n.setLocaleMessage(this.$i18n.locale, space)
  }

  this.$options.$$t = factory('t', this)
  this.$options.$$tc = factory('tc', this)
  this.$options.$$d = factory('d', this)
  this.$options.$$n = factory('n',this)
}

export const createCustomTranslations = function (translations, mergeStrategy = 'shared', hasRoot = true, mergeParent = false) {
  const {dateTimeFormats = {}, numberFormats = {}} = rootTranslations
  const i18n = new VueI18n({
    locale,
    dateTimeFormats: {
      [locale]: dateTimeFormats
    },
    numberFormats: {
      [locale]: numberFormats
    },
    messages: {
      [locale]: translations
    }
  })
  const that = new Vue({i18n})
  setTranslations.call(that, translations, mergeStrategy, hasRoot, mergeParent)
  return _.pick(that.$options, ['$$t', '$$tc', '$$d', '$$n'])
}
export const trans = function (space, merge = 'shared') {
  return sliceTranslations(space, merge, false).this
}

Vue.prototype.$$allTranslations = translations
Vue.prototype.$$sliceTranslations = sliceTranslations
Vue.prototype.$$setTranslations = setTranslations

export default  {
  LTLD,
  translations,
  trans,
  rootTranslations,
  sliceTranslations,
  setTranslations,
  createCustomTranslations,
  factory
}

