import {Vue} from '~/addiesaas'
import ReBlankComponent from './re-blank-component'
Vue.component(ReBlankComponent.name, ReBlankComponent)

import ReToggleComponent from './re-toggle-component'
Vue.component(ReToggleComponent.name, ReToggleComponent)

import ReContentSections from './re-content-sections'
Vue.component(ReContentSections.name, ReContentSections)

import ReTable from './re-table'
Vue.component(ReTable.name, ReTable)

import REToastAlert from './re-toast-alert'
Vue.component(REToastAlert.name, REToastAlert)

import RESimpleDropdown from './re-simple-dropdown'
Vue.component(RESimpleDropdown.name, RESimpleDropdown)

import ReGroupBox from './re-group-box'
Vue.component(ReGroupBox.name, ReGroupBox)

import ReTabContainer from './re-tab-container'
Vue.component(ReTabContainer.name, ReTabContainer)

import ReTabItem from './re-tab-item'
Vue.component(ReTabItem.name, ReTabItem)

import ReLoading from './re-loading'
Vue.component(ReLoading.name, ReLoading)

import ReBonusBox from './re-bonus-box'
Vue.component(ReBonusBox.name, ReBonusBox)

import ReSlides from './re-slides'
Vue.component(ReSlides.name, ReSlides)

import ReImage from './re-image'
Vue.component(ReImage.name, ReImage)

import ReCreditCardForm from './re-credit-card-form'
Vue.component(ReCreditCardForm.name, ReCreditCardForm)

import ReCountdown from './re-countdown'
Vue.component(ReCountdown.name, ReCountdown)

import RePaginate from './re-pagniate'
Vue.component(RePaginate.name, RePaginate)

import ReContentPlaceholder from './re-content-placeholder'
Vue.component(ReContentPlaceholder.name, ReContentPlaceholder)

import ReEmbed from './re-embed'
Vue.component(ReEmbed.name, ReEmbed)

import mashups from './mashups'

export default {
  //new components
  ReBlankComponent,
  ReToggleComponent,
  ReContentSections,
  REToastAlert,
  RESimpleDropdown,
  ReGroupBox,
  ReTabContainer,
  ReTabItem,
  ReLoading,
  ReBonusBox,
  ReSlides,
  ReImage,
  ReCreditCardForm,
  ReCountdown,
  RePaginate,
  ReContentPlaceholder,
  ReEmbed,
  ...mashups
}
