export const name = 're-login'

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
import SocialMixin from '../../../../mixins/profile/social/auth'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'
export const mixins = [ComponentMixin, FormMixin, SocialMixin, FormForceUpdateMixin]

export const EventBus = window.VueEventBus

const propDefs = props
export const data = {
  propDefs,
  isLoggingIn: false,
  isLoggedIn: false,
  showingVerificationError: false,
  userVerificationPromptEmail: null,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
