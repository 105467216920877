
import CartMixin from '../../../../../../mixins/cart'
import TimerMixin from '../../../../../../mixins/timer'
import {formatDate, DateTime, Settings} from '../../../../../../lib/dates'
Settings.defaultLocale = 'en-us'

const EventBus = window.VueEventBus

export default {
  name: 're-cart-timer',
  mixins: [
    CartMixin,
    TimerMixin
  ],
  props: {
    reservationType: {
      type: [String, Array],
      default: () => ['race-reservations', 'reservation']
    }
  },

  data() {
    return {
      timerWarningType: 'warning'
    }
  },

  created() {
    EventBus.$on('timer.timeout', (timer) => {
      Vue.set(this, 'timerWarningType', 'danger')
    })
    EventBus.$on('timer.tick', (remaining, elapsed, timer) => {
      if (remaining <= 60) {
        Vue.set(this, 'timerWarningType', 'danger')
      } else {
        Vue.set(this, 'timerWarningType', 'warning')
      }
    })
  },

  computed: {
    timerStatus() {
      return this.needsToShowTimer && this.timerIsRunning ?
        `Time to complete your purchase: ${this.timerRemainingFormatted}` : ''
    },
    showGlobalTimerMessage() {
      const showGlobalTimer = this.$store.getters['REUtils/Pages/showingGlobalTimer'] || false
      return showGlobalTimer && this.needsToShowTimer && this.timerIsRunning
    },
    needsToShowTimer() {
      const cartItems = this.$store.getters['Shop/Orders/Cart/cartItems'] || {}
      return _.some(cartItems, ({type, useTimer}) => {
        return useTimer || _.castArray(this.reservationType).includes(type)
      })
    },
    timerRemainingFormatted() {
      if (this.timerRemaining){
        return formatDate(DateTime.fromSeconds(this.timerRemaining, {zone: 'UTC'}), 'HH:mm:ss')
      }
      return ''
    },
  },

  methods: {

  },
}
