export default {
  data() {
    return {
      inputType: 'options-table',
      inputTypeDef: {}
    }
  },
  methods: {

  }
}
