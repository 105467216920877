<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Please enter your new password

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        form-group(
          :validator="$v.password"
          label="Password:"
          attribute="password"
          v-slot="props"
          :messages="{ backendError }"
        )
          input(
            v-model="$v.password.$model"
            :class="props.classes"
            type="password"
            placeholder="Your new password"
            @input="input"
          )

        form-group(
          :validator="$v.passwordConfirmation"
          label="Confirm password:"
          attribute="password confirmation"
          v-slot="props"
          :messages="passwordConfirmationMessages"
        )
          input(
            v-model="$v.passwordConfirmation.$model"
            :class="props.classes"
            type="password"
            placeholder="Confirm your new password"
            @input="input"
          )

      .b-central__footer.b-central__footer--navigation-btn
        router-link.btn.btn-almost-sm.btn-secondary(:to="{ name: 'onboarding-sign-in' }") Back to Sign In
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Set new password

</template>

<script>
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: null,
    password: '',
    passwordConfirmation: '',
    backendError: null,
    passwordConfirmationMessages: { 'sameAsPassword': 'Password doesn\'t match.' },
    submitting: false,
  }),

  validations: {
    password: {
      required,
      backendError() {
        return !this.backendError
      },
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },

  created() {
    this.email = this.$route.query.email
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/auth/reset-password`, {
          email: this.email,
          code: this.$route.query.code,
          password: this.password,
        })
        this.$router.push({ name: 'onboarding-sign-in' })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
