<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Enter your email
      .b-central__subtitle Free forever. No credit card needed.

      .b-email-input-group
        form-group(:validator="$v.email" attribute="email" v-slot="props" :messages="{ backendError }")
          input.b-email-input-group__input(
            v-model="$v.email.$model"
            :class="props.classes"
            type="email"
            placeholder="Your work email address"
            @input="input"
          )
        v-button.btn.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$dirty && $v.$invalid") Try for FREE

      .b-central__message(:class="{ invisible: !waitingForVerification }") The verification link was sent to your email. Please click on it to verify your account. 
        a(href="#" @click.prevent="submit") Resend email.

</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    backendError: null,
    waitingForVerification: false,
    submitting: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/email`, {
          email: this.email
        })
        this.backendError = null
        this.waitingForVerification = true;
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
        this.waitingForVerification = false
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
      this.waitingForVerification = false
    }
  },
}
</script>
