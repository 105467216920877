import formatters from '../../lib/formatters'

export default {
  data() {
    return {
      builderStructures: {}
    }
  },
  mixins: [],

  computed: {

  },

  watch: {
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const structures = this.parseBuilderStructures(this.data)
      this.builderValues = _.cloneDeep(this.value || {})

      this.setBuilderConfigs(_.cloneDeep(structures.options || {}))
      this.setBuilderForm(_.cloneDeep(structures.form || {}))
      // this.builderValidations = this.data.form.validations || {}

      _.forOwn(_.get(structures, '$inject', {}), (item, key) => {
        this[key] = item
      })
    },
    parseBuilderStructures(data) {
      const clonedData = this.$$clone(_.omit(data, ['$inject']))
      const structures = this.builderStructures = this.parseBuilderStructureObject(clonedData, null, null, clonedData)
      this.parseBuilderStructureValidations(structures, null, null, structures)
      this.builderStructures.$inject = data.$inject
      return structures
    },
    parseBuilderStructureObject(obj, key, parent, origin) {
      if (_.startsWith(key, '@')) {
        return this.probeReactiveFunctions(obj, this.getBuilderData, true)
      }
      if (_.isObject(obj)) {
        obj = formatters.map(obj, (i, k) => this.parseBuilderStructureObject(i, k, obj, origin))

        if (parent &&  _.indexOf(['pages', 'sections', 'groups'], key) >= 0) {
          const items = _.values(obj)
          _.forOwn(items, (item, index) => {
            // if (item.namespace) {
            //   item.validations = item.validations || this.getBuilderItemValidations(item, item.key, parent, origin)
            // }
            // item.prev = items[(index * 1) - 1]
            // item.next = items[(index * 1) + 1]
          })
        }
      }
      return obj
    },
    parseBuilderStructureValidations(obj, key, parent, origin) {
      if (_.isObject(obj)) {
        formatters.map(obj, (i, k) => this.parseBuilderStructureValidations(i, k, obj, origin))

        if (['pages', 'sections', 'groups'].includes(key)) {
          const items = _.values(obj)
          _.forOwn(items, (item, index) => {
            if (_.isObject(item)) {
              if (item.namespace) {
                item.validations = item.validations || this.getBuilderItemValidations(item, item.key, parent, origin)
              }
              item.prev = items[(index * 1) - 1]
              item.next = items[(index * 1) + 1]

              item.$v = () => {
                return _.get(this.$v, item.validations, {})
              }
              item.isInvalid = () => {
                return _.get(item.$v(), '$invalid', false)
              }
              item.isValid = () => {
                return !item.isInvalid()
              }
              item.isValidationError = () => {
                return _.get(item.$v(), '$anyError', false)
              }
              item.isValidationValid = () => {
                return !item.isValidationError()
              }

              item.isPrevInvalid = () => {
                let invalid = false
                let prev = item.prev
                if (prev) {
                  invalid = prev.isInvalid()
                  if (!invalid) {
                    invalid = prev.isPrevInvalid()
                  }
                }
                return invalid
              }

              item.isPrevValidationError = () => {
                const prev = item.prev
                if (prev) {
                  return prev.isValidationError()
                }
                return false
              }
            }
          })
        }
      }
    },
    getBuilderItemValidations(obj, key, parent, origin) {
      let fields = []
      const form = obj.form
      const vGroupName = _.camelCase(obj.namespace)
      const vGroup = `form.validations.${vGroupName}`
      if (form) {
        const formFields = _.omitBy(form.fields || {}, f => f === false)
        fields = fields.concat(_.map(_.keys(formFields), (k) => `fields.${k}`))
      }
      const groups = obj.groups || {}
      _.forOwn(groups, (g, k) => {
        if (g.validations) {
          fields.push(g.validations)
        }
      })
      const sections = obj.sections || {}
      _.forOwn(sections, (s, k) => {
        if (s.validations) {
          fields.push(s.validations)
        }
      })

      _.set(origin, vGroup, fields)
      return vGroupName

    }
  }
}
