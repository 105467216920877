export default {
  computed: {
    quantityMessageDetails() {
      const message = this.$$t('messages.quantity')
      const config = this.$$s('messages.quantity', null)
      return _.merge({}, {
        component: 'div',
        message: this.parseReactiveValues(message, {$: this, config}),
        attrs: {}
      }, config)
    },
    showQuantityMessage() {
      if (!this.showQuantityInput || this.hideNonLocationFields) {
        return false
      }
      const config = this.$$s('messages.quantity', null)
      if (!config || _.isEmpty(config) || _.isNil(config.show)) {
        return false
      }
      if (config.show === true) {
        return true
      }
      return this.probeReactiveIf(config.show, {$: this})
    }
  },
  methods: {

  }
}
