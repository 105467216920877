import config from '~/vuestrap/config'
const { Vue, EventBus } = config

import Router from 'vue-router'
import scrollBehavior from '~/vuestrap/router/scroll'

export default function (routes) {
  Vue.use(Router)

  const router = new Router({
    routes,
    scrollBehavior,
    mode: 'history'
  })

  EventBus.$on('addiesaas.app.beforeInit', (el, addiesaas, vuestrap) => {
    vuestrap.router = router
  });
}
