import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const localProps = {
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showTitle', true)
    }
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title','Your shopping cart is empty')
    }
  },
  showGoToHomeButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showGoToHomeButton', true)
    }
  },
  buttonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttonTitle', 'GO TO MAIN PAGE')
    }
  },
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
