/*global _, addiesaas, $, debugLog */
import Validations, {
  ValidationMixins
} from '../../lib/validations'
import formatters, {templify} from '../../lib/formatters'

export default {
  props: {
    value: {}
  },
  validations() {
    return this.setValidationRules()
  },
  data() {
    return {
      Validations,
      setValidationRules() {
        return this.builderValidations.validations
      }
    }
  },
  mixins: [ValidationMixins],

  computed: {
    validations() {
      return this.builderValidations.messages
    }
  },

  watch: {

  },
  created() {

  },
  methods: {
    parseValidationSettings(settings) {
      const data = {this: this, ...Validations}
      let validations = {}
      let messages = {}
      // {field_name: {$items: {}}
      _.forOwn(settings, (i, k) => {
        const items = _.get(i, '$items')
        let rules = {}
        let message = {}
        if (items && _.isObject(items)) {
          // item: {ruleName: {rule: {name: }}}
          // item: {ruleName: errorMessage}
          _.forOwn(items, (item, key) => {
            let ruleObj = _.get(item, 'rule', {})
            if (_.isString(item) || item === true) {
              ruleObj.rule = {name: key}
            }
            const ruleName = ruleObj.name || (ruleObj.name = key)
            let params = ruleObj.params
            if (!_.isEmpty(params)) {
              params = _.map(_.values(params), p => templify(p, data))
            }
            let ruleFn = _.get(Validations, ruleName,
                _.get(formatters, ruleName,
                  _.get(_, ruleName,
                    _.get(this, ruleName,
                      _.get(window, ruleName)
                    )
                  )
                )
              )
            if (ruleFn && !_.isEmpty(params)) {
              ruleFn = ruleFn(...params)
            }
            rules[key] = ruleFn
            const defaultError = 'Invalid ' + _.startCase(key) + '.'
            let error = (_.isString(item) ? item : item.error) || defaultError
            message[key] = {error: templify(error, data)}
          })
        }
        validations[k] = rules
        messages[k] = {items: message, name: k, validator: null} || {}
      })

      return {validations, messages}
    }
  }
}
