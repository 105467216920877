<template lang="pug">
  div
    img.b-logo-bg(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.lg.svg`")
    .b-central.b-central--mobile
      .b-central__content.b-central__content--center
        img.m-t-40.m-b-15(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.sm.svg`")
        .b-central__panel.b-central__panel--w260.b-central__panel--center.m-b-40
          .b-central__title.b-central__title--sm.text-center Forgot your password?
          .b-central__subtitle.text-center We just sent you a 6-digit code to {{ email }}. Enter the code below to reset your password.

          onboarding-verificode(@change="input")

          v-button.btn.btn-lg.btn-block.btn-primary.m-b-20(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Reset password
          a.btn.btn-lg.btn-block.btn-secondary(@click.prevent="$router.go(-1)") Back

          .b-central__message.text-danger(v-if="backendError") {{ backendError }}

</template>

<script>
import axios from 'axios'
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: null,
    code: '',
    backendError: null,
    submitting: false,
  }),

  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
      integer,
    },
  },

  created() {
    this.email = this.$route.query.email
  },

  methods: {
    input(code) {
      this.code = code
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        const { data } = await axios.post(`${this.config.baseUri}/api/onboarding/auth/reset-password/check`, {
          email: this.email,
          code: this.code,
        })
        this.$router.push({ name: 'vouchermobilescanner-password-reset', query: { email: this.email, code: this.code } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>
