export default {
  showFormLabels: {
    type: [Boolean, Number, String],
    default: null,
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default: null,
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default: null
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default: null,
  },
  formTitle: {
    type: String,
  },
  showFormTitle: {
    type: [Boolean, Number, String],
    default: null,
  },
  formSubTitle: {
    type: String,
  },
  showFormSubTitle: {
    type: [Boolean, Number, String],
    default: null,
  },
  formNotice: {
    type: String,
  },
  showFormNotice: {
    type: [Boolean, Number, String],
    default: null,
  },
  formClasses: {
    type: [String, Array, Object],
  },
  submitButtonTitle: {
    type: String,
    default: 'Submit',
  },
  submitButtonClasses: {
    type: [String, Array, Object],
  },
  resetButtonTitle: {
    type: String,
    default: 'Reset',
  },
  resetButtonClasses: {
    type: [String, Array, Object],
  },
}
