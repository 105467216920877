/*global _ */
import {filterByLocation} from '../../lib/store/product'
import {fv} from '../../lib/utils'

const filterMap = {
  type: 'type',
  category: (value, i) =>  value === (i[i.type + '_type'] || i.category_slug),
  customType: 'custom.type',
}

export default {
  methods: {
    autoApplyFilterOnProducts(products, appendFilters = {}) {
      const filters = this.productsFilters || {publish_zones: [''], ...appendFilters}
      return this.applyFilterOnProducts(filters, products)
    },
    applyFilterOnProducts(filters, products, strict = false) {
      products = products || this.getAllProducts(true) || []
      let filtered = [...products || []];
      _.each(filters, (value, key) => {
        const mapKey = filterMap[key] || null;
        if (strict || (value !== '' && !_.isNil(value))) {
          if (mapKey) {
            filtered = _.filter(filtered, i => _.isFunction(mapKey) ? mapKey(value, i) : _.get(i, mapKey) === value)
          } else {
            const methodName = _.camelCase(`apply_${key}_filter`)
            const mapMethod = this[methodName];
            if (mapMethod) {
              filtered = mapMethod(filtered, value);
            }
          }
        }
      })
      return filtered
    },
    applyPublishZonesFilter(products, value) {
      return _.filter(products, product => {
        const productZones = _.castArray(product.publish_zones || [''])
        value = _.castArray(value || [''])
        return !_.isEmpty(_.intersection(productZones, value))
      })
    },
    applyLocationFilter(products, location, strict) {
      if (_.isObject(location)) {
        strict = fv(strict, location.strict)
        location = location.location
      }
      return filterByLocation(products, location, strict)
    },
    applyComboFilter(products) {
      // combo_id, combo_master, combo_switch_field, combo_switch_operator
      const comboFinder = ({custom: {combo_master: m = null} = {}}) => m && !_.isNil(m) && (!!JSON.parse(m)) === true
      const processed = _.groupBy(products, 'custom.combo_id') || {}
      const ungrouped = _.values(processed.undefined) || []
      const allGrouped = _.omit(processed, undefined) || {}
      const grouped = _.map(allGrouped, i => {
        const master = _.first(_.values(_.pickBy(i, comboFinder)))
        if (master) {
          master.combos = _.keyBy(_.omitBy(i, comboFinder), 'id')
        }
        return master
      })

      const filtered = [..._.values(ungrouped), ..._.values(grouped)]
      return filtered
    }
  }
}
