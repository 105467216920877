/*global _ */

import {currencyRounded} from '../../../lib/formatters'

// Can define in FrontendSettings Translation in
// page.profile.tab.loyalty =>
// status.shortMessageTemplate
// status.longMessageTemplate
// status.longMessagePrefix
// status.longMessageSuffix
// status.fulfilledMessageTemplate
const defaults = {
  shortMessage: `Spend <span class="amount">{{$remaining}}</span> more {{remainingSpentCurrency}} to unlock your next reward level for {{locationName}}.`,
  longMessage: [
    `{{prefix}}`,
    `Spend <span class="amount">{{$remaining}}</span> more {{remainingSpentCurrency}} and earn a {{nextRewardTitle}}`,
    '{{suffix}}'
  ],
  longMessagePrefix: '',
  longMessageSuffix: ' <span class="suffix">or select a reward from our rewards list.</span>',
  fulfilledMessage: 'Select a reward from our rewards list at {{locationName}}.'
}

export default {
  computed: {
    loyaltyTierRewards() {
      return this.loyaltyRewardsGrouped['tier']
    },
    topTierReward() {
      return _.find(this.loyaltyTierRewards || [], i => i.isTopReward)
    },
    lockedTierRewards() {
      return _.filter(this.loyaltyTierRewards || [], r => !r.unlocked)
    },
    unlockedTierRewards() {
      return _.filter(this.loyaltyTierRewards || [], r => r.unlocked)
    },
    lastUnlockedTierRewards() {
      return _.last(this.unlockedTierRewards)
    },
    hasUnlockedTierRewards() {
      return !!this.unlockedTierRewards.length
    },
    hasFulfilledTierRewards() {
      return this.hasUnlockedTierRewards && !this.lockedTierRewards.length
    },
    nextTierTargetReward () {
      const {points} = this.loyaltyBaseStatus
      return _.find(this.loyaltyTierRewards || [], p => p.points > points) || this.topTierReward
    },
    loyaltyTierStatus() {
      // value: points earned
      // spent: amount/money spent
      // $spent: amount/money spent in currency ($)
      const {points: value, spent, $spent, location_id, locationName} = this.loyaltyBaseStatus
      const topReward = this.topTierReward || {}
      const nextReward = this.nextTierTargetReward || {}
      const nextRewardTitle = nextReward.messageInlineTitle

      const target = nextReward.points // target points for next reward
      const targetPrice = nextReward.price || 0 // product price of the next reward
      const $target = currencyRounded.call(this, targetPrice, 'currencyRounded') // next reward product price in currency ($)
      const valuePercent = target ? value / target * 100 : 0 // percentage of points earned vs remaining points
      const remaining = _.toFinite(Math.max(0, target - value)) // remaining points until next reward
      const remainingSpent = this.getRewardPriceFromPoint(remaining) || 0 // amount/money to spend until next reward
      const $remaining = currencyRounded.call(this, remainingSpent, 'currencyRounded') //amount/money in currency ($) to spend until next reward
      const remainingPercent = target ? remaining / target * 100 : 0 // remaining points to earn vs remaining points
      const remainingSpentCurrency = this.$tc('currency.usd', remainingSpent) // currency name of remaining money/amount(pluralize)
      const remainingSpentCurrencyPrefixed = this.$tc('currency.usdWithValue', remainingSpent) // remaining money/amount + currency name of remaining money/amount (pluralize)
      return {
        defaults,
        location_id,
        locationName,
        br: '<br/>',
        nextReward,
        currentReward: this.lastUnlockedTierRewards,
        nextRewardTitle,
        topReward,
        value,
        valuePercent,
        spent,
        $spent,
        target,
        targetPrice,
        $target,
        remaining,
        remainingSpent,
        remainingPercent,
        $remaining,
        remainingSpentCurrency,
        remainingSpentCurrencyPrefixed,

        locked: this.lockedTierRewards,
        unlocked: this.unlockedTierRewards,
      }
    }
  }
}
