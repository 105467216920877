<template lang="pug">
  .b-subscription(v-if="subscription" :class="{ 'b-subscription--intransparent': !transparent }")
    .text-center.m-b-20
      .b-subscription__state.b-subscription__state--success(v-if="subscription.status == 'live'") {{ subscription.status }}
        img.b-subscription__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-ok-icon.svg`")
      .b-subscription__state.b-subscription__state--danger(v-else) {{ subscription.status }}
        img.b-subscription__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-danger-icon.svg`")
    .b-subscription__title.m-b-10 Subscription \#{{ subscription.id }}
    .b-subscription__item.m-b-10
      span Plan
      span {{ subscription.name }}
    .b-subscription__item.m-b-10
      span Start
      span {{ moment(subscription.starts_at).format('YYYY-MM-DD') }}
    .b-subscription__item.m-b-10(v-if="subscription.billing.expires_at")
      span Expire
      span {{ moment(subscription.billing.expires_at).format('YYYY-MM-DD') }}
    .b-subscription__divider.m-b-10
    .b-subscription__item
      span Total
      span {{ subscription.total | currency }}
</template>

<script>
import moment from 'moment'

export default {
  name: 'subscription-state',

  props: {
    subscription: {
      type: Object,
    },
    transparent: {
      type: Boolean,
      default: true,
    }
  },

  data: () => ({
    config: window.addiesaas.config,
  }),

  watch: {
    async subscription() {
      if (this.subscription) {
        this.$emit('mounted')
      }
    },
  },

  methods: {
    moment(x) {
      return moment(x)
    },
  }
}
</script>

<style lang="scss">
.b-subscription {
  position: absolute;
  z-index: 1;
  top: 108px;
  bottom: 108px;
  left: 30px;
  right: 30px;
  background-color: rgba(55,52,81,0.7);
  border-radius: 3px;
  padding: 15px 23px;
  &--intransparent {
    background-color: rgb(55, 52, 81);
  }
  &__state {
    display: inline-block;
    border-radius: 15px;
    line-height: 20px;
    padding: 5px 10px;
    &--success {
      color: #25C980;
      background-color: rgba(81,208,151,0.14);
    }
    &--danger {
      color: #EF3278;
      background-color: rgba(239,50,120,0.1);
    }
    &--success,
    &--danger {
      text-transform: capitalize;
    }
    &-icon {
      float: left;
    }
  }
  &__title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__divider {
    border-top: 1px solid white;
  }
}
</style>
