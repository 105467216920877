export const name = 're-shop-product-item-generic'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../../../mixins/components/complex-reactive'
import ResponsiveMixin from '../../../../../../mixins/responsive'
import ProductMixin from '../../../../../../mixins/shop/product'
import BaseItemMixin from './mixin'
export const mixins = [ComponentMixin, ResponsiveMixin, ProductMixin, BaseItemMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
