import Base from '../Base'
import {create, getNow} from '../../lib/dates'

const EXPIRATION_THRESHOLD_DAYS = 62

export class Card extends Base {
  constructor(data, vm) {
    super(data, vm)
  }

  get provider() {
    return this.data.cc_type || ''
  }

  get number() {
    return this.data.cc_number || ''
  }

  get masked() {
    return this.provider + '*' + this.number.slice(-4)
  }

  get expiration() {
    return this.getExpiration('yy/MM')
  }
  get expired() {
    return this.getExpiration() <= getNow().endOf('day')
  }

  get expiring() {
    return this.getExpiration().diff(getNow().endOf('day'), 'days').toObject().days <= EXPIRATION_THRESHOLD_DAYS
  }

  get expiringMonth() {
    return this.data.cc_expiration_month
  }

  get expiringYear() {
    return this.data.cc_expiration_year
  }

  getExpiration(format = null) {
    const month = this.expiringMonth
    const year = this.expiringYear
    const date = create(year, month).endOf('month')
    if (format) {
      return date.toFormat(format)
    }
    return date
  }
}

export default Card
