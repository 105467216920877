export const name = 're-shop-subscription'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'

export const mixins = [ComponentMixin]

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

}
