import {
  passwordRules,
  zipRules,
  setValidationRule,
  phoneRuleUSOrCanadaIfNotEmpty,
  validDate, validDateBeforeToday,
  Validators,
  zipRuleIfNotEmpty, truthyRule
} from '../../../../../lib/validations'

const CURRENT_YEAR = (new Date()).getFullYear()

export const defaultFormFields = {
  first_name: '',
  last_name: '',
  email: '',
  email_confirmation: '',
  phone: '',
  zip: '',
  billing_street: '',
  billing_city: '',
  billing_state: '',
  billing_country: 'US',
  card: '',
  card_brand: null,
  cvv: '',
  expMonth: '',
  expYear: '',
  expDate: '',
  gender: null,
  birth_date: null,
  password: null,
  password_confirmation: null,
  terms: null,
  billingEnabled: true,
  doRegister: false,
  subscribe: false,
  location_id: null,
  preferred_location_id: null,
  register_location_id: null,

  shipping_is_pickup: '',
  shipping_first_name: '',
  shipping_last_name: '',
  shipping_address: '',
  shipping_address2: '',
  shipping_city: '',
  shipping_state: '',
  shipping_zip: '',
  shipping_country: 'US',
}
export const allValidationsRules = {
  first_name: setValidationRule('fields.first_name', {
    required: {error: 'First Name is required.'}
  }),
  last_name: setValidationRule('fields.last_name', {
    required: {error: 'Last Name is required.'}
  }),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'}
  }),
  email_confirmation: {},
  phone: setValidationRule('fields.phone', {
    required: {error: 'Phone is required.'},
    phone: {error: 'Invalid phone number.', rule: phoneRuleUSOrCanadaIfNotEmpty}
  }),
  billing_street: {},
  zip: setValidationRule('fields.zip', zipRules),
  gender: {},
  location_id: {},
  register_location_id: {},
  preferred_location_id: {},
  birth_date: setValidationRule('fields.birth_date', {
    required: {error: 'Birth date is required.'},
    validDate: {error: 'Birth date is not valid.', rule: validDate},
    pastDate: {error: 'Birth date can not be a future date.', rule: validDateBeforeToday},
  }),
  password: setValidationRule('fields.password', passwordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', {
    confirmed: {error: 'Confirm Password needs to be same as Password.', rule: Validators.sameAs('password')},
  }),
  card: setValidationRule('fields.card', {
      required: {error: 'Credit Card Number is required.'},
      minLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.minLength(12)},
      maxLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.maxLength(20)},
    }
  ),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Security code is required.'},
    numeric: {error: 'Security code must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.minLength(3)},
    maxLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.maxLength(4)},
  }),
  expMonth: setValidationRule('fields.expMonth', {
    required: {error: 'Expiration Month is required.'},
    numeric: {error: 'Expiration Month must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.minLength(1)},
    maxLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.maxLength(12)},
  }),
  expYear: setValidationRule('fields.expYear', {
    required: {error: 'Expiration Year is required.'},
    numeric: {error: 'Expiration Year must be numeric.', rule: Validators.numeric},
    minValue: {error: `Expiration Year must be greater than or equal to ${CURRENT_YEAR}.`, rule: Validators.minValue(Number(String(CURRENT_YEAR).substring(2)))} ,
  }),
  expDate: {},
  terms: setValidationRule('fields.terms', {
    truthy: {error: 'Please agree to the Terms & Conditions', rule: truthyRule},
  }),
  subscribe: {},

  shipping_first_name: setValidationRule('fields.shipping_first_name'),
  shipping_last_name: setValidationRule('fields.shipping_last_name'),
  shipping_address: setValidationRule('fields.shipping_address', {
    required: {error: 'Address is required.', rule: Validators.requiredUnless('shipping_is_pickup')}
  }),
  shipping_address2: {},
  shipping_city: setValidationRule('fields.shipping_city', {
    required: {error: 'City is required.', rule: Validators.requiredUnless('shipping_is_pickup')}
  }),
  shipping_state: setValidationRule('fields.shipping_state', {
    required: {error: 'State is required.', rule: Validators.requiredUnless('shipping_is_pickup')}
  }),
  shipping_zip: setValidationRule('fields.shipping_zip', {
    required: {error: 'Zip is required.', rule: Validators.requiredUnless('shipping_is_pickup')},
    zip: {error: 'Invalid zip.', rule: zipRuleIfNotEmpty},
  }),
  shipping_country: {},

}

export default {defaultFormFields, allValidationsRules}
