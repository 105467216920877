import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'
import getMenuProps from '../../../../props/profile/menu'

const localProps = {
  ...getMenuProps(),

  autoLoad: {
    type: [Boolean, Number, String, Array, Object],
    default() {
      return this.$options.$$s('autoLoad', false)
    }
  },
  autoLoadFrequency: {
    type: [Boolean, Number, String, Array, Object],
    default() {
      return this.$options.$$s('autoLoadFrequency', false)
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading')
    }
  },
  tab: {}, // @deprecated: Check use and remove. Use defaultTab instead to set default selected menu name
  defaultTab: {
    type: String,
    default() {
      return this.$options.$$s('defaultTab', null)
    }
  },

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'My Account')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', '')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  showLoginBox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLoginBox', true)
    }
  },
  signInButtonMessage: {
    type: String,
    default() {
      return this.$options.$$t('login.label', 'You are not logged in. {br} {button}')
    }
  },
  signInButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('login.button.title', this.$options.$$t('login.link.label', 'Sign in'))
    }
  },
  signInButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('login.button.class', 'goto-login-button')
    }
  },
  scrollToTabMaxWidth: {
    type: [String, Number, Boolean],
    default() {
      return this.$options.$$s('nav.scrollToTab.maxWidth', false)
    }
  },
  showMenuAsDropDownMaxWidth: {
    type: [String, Number, Boolean],
    default() {
      return this.$options.$$s('nav.menuAsDropdown.maxWidth', false)
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
