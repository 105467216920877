/*global addiesaas */
import AsyncEventBus from '../lib/async-event-bus'
import {emit, registerPromise, EventBus} from '../lib/utils'

const hookFactory = function (lifecycle) {
  const eventSpace = _.get(this, lifecycle === 'beforeCreate' ? '$options.events' : 'eventSpace')
  if (eventSpace) {
    EventBus.$emit(`${eventSpace}.${lifecycle}`, this)
  }
  const tag = _.get(this, '$options._componentTag')
  if (tag) {
    EventBus.$emit(`${tag}.${lifecycle}`, this)
  }
  EventBus.$emit(`$${lifecycle}`, this)
  if (tag) {
    //this.$$debugLog({[lifecycle]: {tag, eventSpace, vm: this}})
  }
}
export default {
  data() {
    return {
      isPromising: false,
      promisingMessage: '',
      eventSpace: _.get(this.$attrs, 'eventSpace',
          _.get(this.$attrs, 'eventspace',
          _.get(this.$attrs, 'event-space',
          _.get(this.$props, 'eventspace',
          _.get(this.$options, 'events'
        )))))
    }
  },
  // beforeCreate() {
  //   hookFactory.call(this, 'beforeCreate')
  // },
  created() {
    hookFactory.call(this, 'created')
  },
  beforeUpdate() {
    hookFactory.call(this, 'beforeUpdate')
  },
  updated() {
    hookFactory.call(this, 'updated')
  },
  beforeMount() {
    hookFactory.call(this, 'beforeMount')
  },
  mounted() {
    hookFactory.call(this, 'mounted')
  },
  beforeDestroy() {
    hookFactory.call(this, 'beforeDestroy')
  },
  destroyed() {
    hookFactory.call(this, 'destroyed')
  },
  methods: {
    registerPromise,
    emit(name, ...args) {
      if (this.eventSpace) {
        emit.call(this, `${this.eventSpace}.${name}`, ...args)
      } else {
        return emit.call(this, name, ...args)
      }
    },
    emitBoth(name, ...args) {
      if (this.eventSpace) {
        emit.call(this, `${this.eventSpace}.${name}`, ...args)
      }
      return emit.call(this, name, ...args)
    },
    emitSlim(name, ...args) {
      if (this.eventSpace) {
        emit.call(this, `${this.eventSpace}.${name}`, ...args)
      }
      return this.$emit(name, ...args)
    },
    startPromising(message) {
      if (message) {
        this.promisingMessage = message
      }
      this.isPromising = true
    },
    stopPromising() {
      this.promisingMessage = ''
      this.isPromising = false
    },
    async emitAsync(...args) {
      if (this.eventSpace) {
        await AsyncEventBus.emit.call(this, `${this.eventSpace}.${name}`, ...args)
      }
      await AsyncEventBus.emit.call(this, name, ...args)
    },
    async promise(name, ...args) {
      let data = null
      this.isPromising = true
      try {
        data = await registerPromise(name, ...args)
      } finally {
        this.isPromising = false
      }
      return data
    }
  }
}
