import props from './../../../../props/inputs'
export const allProps = {
  ...props,
  containerClass: {
    default: ''
  },
  initMode: {
    type: String, // view, edit
    default: null
  },
  showView: {
    type: Boolean,
    default: false
  },
  viewComponent: {
    default: 're-text'
  },
  viewAttr: {
    type: Object,
    default: () => ({
    })
  },
  viewClass: {},
  viewValue: {},
  viewFormat: {},
  viewDefault: {},

  editButtonComponent: {
    default: 're-button'
  },
  editButtonAttr: {
    type: Object,
    default: () => ({
      title: 'edit'
    })
  },
  editButtonClass: {
    default: ''
  },

  inputComponent: {
    default: 're-input'
  },
  inputAttr: {
    type: Object,
    default: () => ({
    })
  },
  buttonComponent: {
    default: 're-button'
  },
  buttonAttr: {
    type: Object,
    default: () => ({
      title: 'Apply'
    })
  },

  disableInputOnApply: {
    default: true
  },
  buttonClass: {
    default: ''
  },
  isResetNeeded: {
    default: true
  },
  clearOnReset: {
    default: true
  },
  noApplyButton: {
    default: false
  },
  applyOnKeys: {
    default: true
  },
  applyOnReset: {
    default: true
  },
  resetButtonComponent: {
    default: 're-button'
  },
  resetButtonAttr: {
    type: Object,
    default: () => ({
      title: 'Reset'
    })
  },
  resetButtonClass: {
    default: ''
  },
  resetValue: {
    default: ''
  },
  showCancel: {
    default: false
  },
  cancelButtonComponent: {
    default: 're-button'
  },
  cancelButtonAttr: {
    type: Object,
    default: () => ({
      title: 'Reset'
    })
  },
  cancelButtonClass: {
    default: ''
  },
  disableButtonOnInvalid: {}
}
export default allProps
