/* global _ */
import {name, mixins, props, data, EventBus, DataFilter, applyStandardBonusOnProduct} from './config'
import {fv, fs} from '../../../../../lib/utils'

export default {
  name,
  mixins,
  props,
  data() {
    return {
      ...data,
      filteredItem: {
        attributes: {
          product: {
            title: null,
          },
          title: null,
          subTitle: null,
          notes: null,
        }
      }
    }
  },

  computed: {
    productImageSrc() {
      const item = this.item
      return fs(
        _.get(this.filteredItem, 'attributes.product.image'),
        _.get(item, 'attributes.product.image'),
        this.$$s(`product.${item.type}.image.src`),
        this.$$s(`product.stub.image.src`)
      )
    },
    cartItemSubTitle() {
      let subTitle = ''
      if (this.item.type === "giftcard") {
        const key = 'recipient_email'
        const defaultValue = 'for myself'
        subTitle = _.startCase(key) + ': ' + (_.get(this.item, `payload.options.${key}`, defaultValue) || defaultValue)
      } else {
        subTitle = fv(
          _.get(this.filteredItem, 'attributes.subTitle'),
          _.get(this.item, 'attributes.subTitle'),
        )
      }
      return subTitle
    },
    cartItemNotes() {
      return fs(
          _.get(this.filteredItem, 'attributes.notes'),
          _.get(this.item, 'attributes.notes'),
          '',
        )
    },
    productTitle() {
      return fs(
        _.get(this.filteredItem, 'attributes.product.title'),
        _.get(this.filteredItem, 'attributes.title'),
        _.get(this.item, 'attributes.title'),
        _.get(this.item, 'attributes.product.title'),
        '',
      )
    },
    showDeleteButton() {
      return this.item.deletable === true
    },
    showEditButton() {
      return this.item.editable === true
    },
    disabledDeleteButton() {
      return this.item.deletable === false
    },
    cartItemProduct() {
      return _.get(this, 'item.attributes.product')
    },
    cartItemLocation() {
      return _.get(this, 'item.payload.options.location_id')
    },
  },
  watch: {
    'item.payload.product_id'(value) {
      if (value) {
        this.calculateBonus()
        this.initCartItem()
      }
    }
  },
  created() {
    this.calculateBonus()
    this.initCartItem()
  },
  updated() {
    this.initCartItem()
  },
  methods: {
    calculateBonus() {
      const product = applyStandardBonusOnProduct(this.cartItemProduct, this.cartItemLocation)
      if (product) {
        const modifiedItem = _.merge(
          {},
          _.cloneDeep(this.item),
          {attributes: {product}}
        )
        this.updateCartItem(modifiedItem)
      }
    },
    initCartItem() {
      const item = this.item
      const type = item.type
      DataFilter.apply(`cart.item.${type}`, this.filteredItem, item, () => this)
      DataFilter.apply(`cart.item`, this.filteredItem, item, () => this)
    },
    childItemsDelete(item, $event) {
      item = item || this.item
      const childItems = _.filter(this.cartItems, i => i.parent_id === item.id)
      _.forOwn(childItems, i => {
        this.itemDelete($event, i)
      })
    },
    itemDelete($event, item) {
      item = item || this.item
      this.childItemsDelete(item, $event)
      this.cartItemButtonClicked({
        $event,
        actionName: 'delete',
        id: item.id,
        item,
        cartItems: this.currentCartItems,
        button: {}
      })
      EventBus.$emit('cart-item-deleted', item, this, this.$parent)
      EventBus.$emit('cart-item-deleted-' + item.type, item, this, this.$parent)
    },

    itemEdit($event) {
      const item = this.item
      this.cartItemButtonClicked({
        $event,
        item,
        actionName: 'edit',
        id: this.item.id,
        cartItems: this.currentCartItems,
        button: {}
      })
      EventBus.$emit('cart-item-edit', item, this, this.$parent)
      EventBus.$emit('cart-item-edit-' + item.type, item, this, this.$parent)
    },
  }
}
