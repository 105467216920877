import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const localProps = {
  containerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('cart-item-booking.class', null)
    }
  },
  defaultPackageTitle: {
    type: String,
    default() {
      return this.$options.$$t('cart-item-booking.default-package-title', 'Reservation')
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
