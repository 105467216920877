/*global _ */
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

const defaultOptions = {
  alwaysRefreshClones: false,
  arrows: true,
  arrowsOutside: false,
  autoplay: false,
  breakpoints: {},
  bullets: false,
  bulletsOutside: false,
  disable: false,
  disableArrowsOnEdges: false,
  draggingDistance: null,
  duration: 4000,
  fade: false,
  fixedHeight: false,
  fractions: false,
  gap: 0,
  infinite: true,
  initSlide: 1,
  lazy: false,
  lazyLoadOnDrag: false,
  pageScrollingElement: "",
  parallax: false,
  parallaxFixedContent: false,
  pauseOnHover: true,
  preventYScroll: false,
  progress: false,
  rtl: false,
  slideContentOutside: false,
  slideContentOutsideClass: "",
  slideImageInside: false,
  slideMultiple: false,
  slideRatio: 1,
  touchable: true,
  transitionSpeed: 600,
  visibleSlides: 1,
  '3d': false,
}
const defaultSlideOptions = {
  duration: 0
}

export default {
  name: 're-slides',
  components: {
    VueperSlides,
    VueperSlide
  },
  props: {
    options: {},
    slides: {},
    itemOptions:{},
  },
  data() {
    return {
    }
  },

  computed: {
    allSlides() {
      return this.slides || []
    },
    slidesOptions() {
      const moreOptions = {}
      if ((_.values(this.slides)).length === 1) {
        moreOptions.arrows = false
        moreOptions.bullets = false
      }
      const allOptions = _.merge({}, defaultOptions, this.options || {}, moreOptions)
      return allOptions
    }
  },

  created() {

  },
  methods: {
    getSlideOptions(slide, key) {
      return _.merge({}, defaultSlideOptions, this.itemOptions || {}, slide || {})
    }
  }
}
