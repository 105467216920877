/*global _ */
import {fv, templify} from '../../lib/utils'
import AutoLoadLocationsMixin from '../location-autoload'
import {req, withParams} from '../../lib/validations'

export default {
  mixins: [AutoLoadLocationsMixin],
  props: {
    validateFailingLocation: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('locations.validateFailing', true)
      }
    },
    failingLocationMessage: {
      type: [String],
      default() {
        return this.$options.$$t('locations.failing.message', '{location} is under maintenance')
      }
    },
    selectedLocationPropertyName: {
      type: [String],
      default() {
        return this.$options.$$s('selectedLocationPropertyName', 'fields.location_id')
      }
    },
    selectedLocationFieldName: {
      type: [String],
      default() {
        return this.$options.$$s('selectedLocationFieldName', 'location_id')
      }
    },
  },
  data() {
    return {
      selectedLocationProperty: null,
      selectedLocationField: null
    }
  },
  created() {
    this.selectedLocationProperty = this.selectedLocationProperty || this.selectedLocationPropertyName
    this.selectedLocationField = this.selectedLocationField || this.selectedLocationFieldName
  },
  computed: {
    selectedLocationName() {
      const locationId = fv(
        locationId,
        _.get(this, this.selectedLocationProperty),
        _.get(this, this.selectedLocationField),
        _.get(this.fields, this.selectedLocationField),
        this.shopLocationId
      )
      if (locationId) {
        const locations = this.allLocationDetails || {}
        const location = locations[locationId] || {}
        return location.display_name
      }
      return ''
    },
    failingLocationMessageFormatted() {
      return templify(
        this.failingLocationMessage,
        {$: this, location: this.selectedLocationName},
        ['{', '}']
      )
    },
    isSelectedLocationFailing() {
      return this.isLocationFailing()
    }
  },
  methods: {
    setupFailingLocationValidations(items) {
      const error = this.$$t(
        `form.fields.${this.selectedLocationField}.validationMessages.notFailing`,
        this.failingLocationMessageFormatted
      )

      const rule = withParams({type: 'notFailing', location: () => this.selectedLocationName}, (locationId) => {
        return !req(locationId) || !this.isLocationFailing(locationId)
      })

      items.notFailing = {error, rule}
    },
    isLocationFailing(locationId) {
      if (!this.validateFailingLocation) {
        return false
      }
      locationId = fv(
        locationId,
        _.get(this, this.selectedLocationProperty),
        _.get(this, this.selectedLocationField),
        _.get(this.fields, this.selectedLocationField),
        this.shopLocationId
      )
      if (!locationId) {
        return false
      }
      const locations = this.allLocationDetails || {}
      const location = locations[locationId]
      if (!location) {
        return false
      }
      const services = this.$$s('locations.services', [])
      return location.is_failing || _.some(services, service => location[service] && location[service].is_failing)
    },
    getFieldContainerExtraClasses(fieldName) {
      // disable - when location is_failing
      const classes = {}
      if (this.showLocationsInput && this.validateFailingLocation && fieldName !== this.selectedLocationField) {
        classes.disabled = this.isLocationFailing()
      }
      return [classes]
    }
  }
}
