import config from '~/vuestrap/config'
const { Vue } = config

import ValidableFormGroup from './ValidableFormGroup'
import Verificode from './Verificode'
import VButton from './VButton'

Vue.component(ValidableFormGroup.name, ValidableFormGroup)
Vue.component(Verificode.name, Verificode)
Vue.component(VButton.name, VButton)
