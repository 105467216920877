export default {
  name: 're-agree-to-terms',
  props: {
    message: {
      default: 'I HAVE READ & AGREE WITH ALL TERMS & CONDITIONS'
    },
    value: {
      default: 'no'
    },
    termsAgreedYesLabel: {
      type: String,
      default: 'I Agree',
    },
    termsAgreedNoLabel: {
      type: String,
      default: 'I Don\'t Agree',
    }
  },
  data() {
    return {
      termsAgreed: null
    }
  },
  created() {
    this.termsAgreed = this.value
  },

  watch: {
    termsAgreed(val) {
      this.$emit('input', val)
    }
  }
}
