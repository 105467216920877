export const SET_POST_VERIFICATION_ROUTE = 'SET_POST_VERIFICATION_ROUTE'
export const SET_POST_VERIFICATION_ROUTE_DATA = 'SET_POST_VERIFICATION_ROUTE_DATA'
export const SET_POST_LOGIN_ROUTE = 'SET_POST_LOGIN_ROUTE'
export const SET_POST_LOGIN_ROUTE_DATA = 'SET_POST_LOGIN_ROUTE_DATA'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
export const SET_LAST_ROUTE_DATA = 'SET_LAST_ROUTE_DATA'
export const HIDE_GLOBAL_TIMER = 'HIDE_GLOBAL_TIMER'
export const SHOW_GLOBAL_TIMER = 'SHOW_GLOBAL_TIMER'
export const SET_PROMISE = 'SET_PROMISE'
export const SET_PAGE_TRANSITION_DATA = 'SET_PAGE_TRANSITION_DATA'
export const UNSET_PAGE_TRANSITION_DATA = 'UNSET_PAGE_TRANSITION_DATA'
export const SET_PAGE_TRANSITION_DATA_ITEM = 'SET_PAGE_TRANSITION_DATA_ITEM'
export const UNSET_PAGE_TRANSITION_DATA_ITEM = 'UNSET_PAGE_TRANSITION_DATA_ITEM'
