/* global _ */
import {name, mixins, props, data, EventBus} from './config'

export default {
  name,
  mixins,
  props,
  data() {
    return {
      ...data
    }
  },
  computed: {
    disabledDeleteButton() {
      return this.item.deletable === false
    },
    packageTitle() {
      return this.item.title || this.item.groupTitle || this.defaultPackageTitle
    },
    packageOptions(){
      return _.get(this.item, 'payload.options', {})
    },
    packageQuantity(){
      return this.packageOptions.count || 0
    },
    packageItemsPrice(){
      return _.reduce(this.bookingPointProductItems, (total, item) => {
        return total + (1 * _.get(item, 'payload.options.total'))
      }, 0)
    },
    packageTaxTotal(){
      return _.reduce(this.bookingPointProductItems, (total, item) => {
        return total + (1 * _.get(item, 'payload.options.tax'))
      }, 0)
    },
    packageItemsTotal(){
      return this.packageItemsPrice + this.packageTaxTotal
    },
    slottedTimes() {
      return _.reduce(this.cartedSlots, (items, slot) => {
        const {time, racers, type, date} = slot
        items.push({time, date, racers, type : _.startCase(type)})
        return items
      }, [])
    }
  },
}
