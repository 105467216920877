<template lang="pug">
  div
    .b-onboarding.b-onboarding--steps(:class="{ '.b-onboarding--start': step == 7 }")
      .b-onboarding__left.b-onboarding__left--steps
        img.b-onboarding__left-logo(:src="`${config.baseUri}/images/client/saas-onboarding/logo.sm.white.svg`")
        .b-steps
          .b-steps__container(:class="`step-${step}`")
            .b-steps__step(v-if="step > 2")
              .b-steps__step-line
            .b-steps__step(v-for="(item, index) in steps" :key="item.route" :class="{ 'b-steps__step--active': isActive(index) }")
              .b-steps__step-line(v-if="index + 1 < steps.length")
              .b-steps__step-marker
              .b-steps__step-text
                .b-steps__step-text__full STEP 0{{ index + 1 }}
                  br
                  | {{ item.label }}
                .b-steps__step-text__short 0{{ index + 1 }}
          .b-steps__fade--top(v-if="step > 1")
          .b-steps__fade--bottom(v-if="step < steps.length")

      slot(@back="back" @next="next")

</template>

<script>
export default {
  data: () => ({
    steps: [
      {
        label: 'primary attraction sence',
        route: 'onboarding-step-one',
      },
      {
        label: 'secondary attraction sence',
        route: 'onboarding-step-two',
      },
      {
        label: 'systems to integrate with',
        route: 'onboarding-step-three',
      },
      {
        label: 'primary goals',
        route: 'onboarding-step-four',
      },
      {
        label: 'intended use cases',
        route: 'onboarding-step-five',
      },
      {
        label: 'greatest challenge',
        route: 'onboarding-step-six',
      },
      {
        label: 'where to start',
        route: 'onboarding-step-seven',
      },
    ],
  }),

  computed: {
    step() {
      return this.$route.meta.step
    },
  },

  methods: {
    isActive(index) {
      return this.offset(index) == 0
    },
    offset(index) {
      return index + 1 - this.step
    },
    back() {
      const name = this.step > (0 + 1) ? this.steps[this.step - 1 - 1].route : 'onboarding-welcome'
      this.$router.push({ name })
    },
    next() {
      console.log('next')
      const name = this.step < this.steps.length ? this.steps[this.step + 1 - 1].route : 'onboarding-guide'
      this.$router.push({ name })
    },
  }
}
</script>
