export const name = 're-shop-product-item-reservation'

import {
  mixins as baseMixins,
  props,
  validationsRules,
  defaultFormFields,
  data,
  EventBus
} from '../base/config'

// mixins
import ReservationMixin from '../../../../../../mixins/shop/reservation'
import MashupBuilderParserMixin from '../../../../../../mixins/mashup-builder/parser'
import EventsMixin from './events'
const mixins = [...baseMixins, ReservationMixin, MashupBuilderParserMixin, EventsMixin]

export {
  mixins,
  props,
  validationsRules,
  defaultFormFields,
  data,
  EventBus
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
