/*global _ */
export default {
  name: 're-toggle-component',
  props: {
    truthy: {
      type: [String],
      default: 're-modal'
    },
    eval: {
      type: [Boolean, Function, Number, String],
      default: false
    },
    falsy: {
      type: [String],
      default: 're-blank-component'
    }
  },
  computed: {
    isTruthy() {
      return _.isFunction(this.eval) ? this.eval() : !!this.eval
    },
    whichComponent() {
      return this.isTruthy ? this.truthy :  this.falsy
    },
    canShowDefaultSlot() {
      return (!this.$slots.truthy && !this.$slots.falsy) ||
        (this.isTruthy && !this.$slots.truthy) ||
        (!this.isTruthy && !this.$slots.falsy)
    }
  }
}
