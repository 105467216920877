/*global _ */
import {
  Validators,
  setValidationRule,
  zipRules,
  truthyRule,
  phoneRules,
  passwordRules,
  sometimesPasswordRules,
  validDate,
  validDateBeforeToday,
} from '../../../../lib/validations'

export const userFields = ['email', 'first_name', 'last_name']
export const metaFields = [
  'racername',
  'gender',
  'birth_date',
  'phone',
  'preferred_location_id',
  'address',
  'address2',
  'city',
  'country',
  'state',
  'zip',
  'subscribe',
  'sms_subscribe',
  'discovery',
]

export const defaultFormFields = {
  ..._.transform(userFields, (carry, key) => carry[key] = '', {}),
  ..._.transform(metaFields, (carry, key) => carry[key] = '', {}),
  password: '',
  password_confirmation: '',
}

export const allValidationsRules = {
  first_name: setValidationRule('fields.first_name'),
  last_name: setValidationRule('fields.last_name'),
  racername: {},
  phone: setValidationRule('fields.phone', phoneRules),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  gender:  setValidationRule('fields.gender'),
  birth_date: setValidationRule('fields.birth_date', {
    required: {error: 'Birth date is required.'},
    validDate: {error: 'Birth date is not valid.', rule: validDate},
    pastDate: {error: 'Birth date can not be a future date.', rule: validDateBeforeToday},
  }),
  address: setValidationRule('fields.address', 'Address'),
  address2: {},
  city: setValidationRule('fields.city'),
  state: setValidationRule('fields.state'),
  zip: setValidationRule('fields.zip', zipRules),
  country: setValidationRule('fields.country'),
  password: setValidationRule('fields.password', sometimesPasswordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', {
    confirmed: {error: 'Confirm Password needs to be same as Password.', rule: Validators.sameAs('password')},
  }),
  subscribe: {},
  sms_subscribe: {},

}

export default {defaultFormFields, allValidationsRules, userFields, metaFields}
