import pageProps from '../../../../props/profile/page-props'
import tableProps from '../../../../props/profile/table-props'

export const props = {
  ...pageProps,
  ...tableProps,

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading promotions...')
    }
  },
  scrollToTabMaxWidth: {
    type: [String, Number, Boolean],
    default() {
      return this.$options.$$s('scrollToTop.maxWidth', false)
    }
  }
}

export default props
