import Base from '../Base'
import Card from './Card'

export class Transaction extends Base {

  constructor(data, vm) {
    super(data, vm)
    this.card = new Card(data.card || data, vm)
  }
}

export default Transaction
