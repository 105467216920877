/*global _ */

export const settings = {
  readonly: {
    "noUI": true
  },
  scale: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "select-field",
    "attrs": {
      "label": "Rating scale",
      "options": [
        {"value": 5, "name": "1-5"},
        {"value": 10, "name": "1-10"}
      ],
    }
  },
  value: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "input-box-field",
    "attrs": {
      "label": "Value",
      "type": "number",
      "max": "{{scale}}",
    },
  },
  iconUnmarked: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "icon-select-upload-field",
    "bind": {
      "value": {
        changeCallback(component, value) {
          if (value && value.type && component.showTrait && component.hideTrait) {
            if (value.type === 'class') {
              component.showTrait('iconUnmarkedStyle')
            } else {
              component.hideTrait('iconUnmarkedStyle')
            }
          }
        }
      }
    },
    "attrs": {
      "label": "Select Unmarked Icon"
    }
  },
  iconUnmarkedStyle: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "radio-select-field",
    "attrs": {
      "label": "Indent",
      "options": [
        {"value": "fas", "label": "Solid"},
        {"value": "far", "label": "Regular"}
      ]
    }
  },
  iconMarked: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "icon-select-upload-field",
    "bind": {
      "value": {
        changeCallback(component, value) {
          if (value && value.type && component.showTrait && component.hideTrait) {
            if (value.type === 'class') {
              component.showTrait('iconMarkedColor')
            } else {
              component.hideTrait('iconMarkedColor')
            }
          }
        }
      }
    },
    "attrs": {
      "label": "Select Marked Icon"
    }
  },
  iconMarkedColor: {
    "changeProp": 1,
    "extendType": "vue",
    "component": "color-field",
    "attrs": {
      "label": "Icon Color"
    }
  }
}
export const settingsDefaults = {
  "scale": 10,
  "value": 5,
  "iconUnmarked": {
    "class": "fas fa-star",
    "type": "class",
    "url": "",
    "urlName": "",
    "color": "#E0DFFB",
    "style": "fas"
  },
  "iconUnmarkedStyle": "fas",
  "iconMarked": {
    "class": "fas fa-star",
    "type": "class",
    "url": "",
    "urlName": "",
    "style": ""
  },
  "iconMarkedColor": "#FFA500",
}

export default {
  data() {
    return {
      inputType: 'star-rating',
      inputTypeDef: {},
      optionsPropName: null,
    }
  },
  computed: {},
  methods: {
    prepareDesignerComponentAfter({data, component}) {
      Object.assign(data.props, settings)
      Object.assign(data.defaults, settingsDefaults)
      _.forOwn(data.defaults, (v, k) => {
        this.designerInputSettingUpdated({
          params: [k, v, null]
        })
      })
      this.starOptions = {
        ...this.starOptions,
        ...this.getStarOptions(data.defaults)
      }
    },
    designerSyncTrait(items) {
      const {component} = this.designerObject || {}
      if (!_.isEmpty(component)) {
        _.forOwn(items, (val, i) => {
          const trait = component.getTraitForVueProp(i)
          if (trait) {
            trait.setTargetValue(val)
            trait.setValueFromInput(val, 0)
            // trait.setValueFromInput(val)
            if (trait.view) {
              trait.view.rerender()
            }
          } else {
            component.set(i, val)
          }
        })
      }
    },
    designerInputSettingUpdated({component, editor, params}) {
      const nameMaps = {
        readonly: 'readonly',
        scale: 'max',
        value: 'value',
        iconUnmarked: 'unmarked',
        iconUnmarkedStyle: 'unmarked.style',
        iconMarked: 'marked',
        iconMarkedColor: 'marked.color'
      }
      let [name, value, oldValue] = params || []
      if (name && value !== oldValue && nameMaps[name]) {
        if (!_.isNaN(1 * value) && _.isFinite(1 * value)) {
          value = 1 * value
        }
        const options = _.cloneDeep(this.starOptions)
        const key = nameMaps[name] || name
        const val = _.get(options, key)
        if (_.isObject(value)) {
          value = _.merge({}, value, val || {})
        }
        _.set(options, key, value)
        this.$set(this, 'starOptions', options)
      }
    }
  }
}

