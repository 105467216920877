/*global _ */
import createPersistedState from 'vuex-persistedstate'
import {onLogout} from '../plugins/auth'
import * as types from '../mutation-types/profile'

// set this true to namespace all getters, mutations, actions etc.
const namespaced = true

export const namespace = 'Profile'
const rootNamespace = 'REUtils'
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

const location = window.addiesaas.location || {}
const locationId = location.id || ''
const locationIdKey = locationId

// state
const defaultStore = {
  autoload: null,
  initialAutoloaded: null,
  loading: {
    profile: false,
    loyalty: false
  },
  states: {
    loyalty: {
      showingHistory: false
    },
    members: [],
    cards: {
      playcard: [],
      giftcard: [],
    },
    promotions: [],
    rewards: [],
    paymentMethod: [],
  },
  orders: {
    filter: '',
    pageIndex: 0
  },
  loyalty: {
    points: 0,
    locationPoints: {},
    location_id: null,
    rewards: [],
  },
  location_id: null
}
const state = {
  profile: _.cloneDeep(defaultStore)
}

// mutations
const mutations = {
  [types.UPDATE_PROFILE_DATA](state, updates) {
    const profile = _.merge({}, state.profile, updates || {})
    state.profile = profile
  },
  [types.UPDATE_PROFILE_DATA_ITEM](state, updates) {
    const profile = state.profile
    _.forOwn(updates, (value, key) => {
      _.set(profile, key, value)
    })
    state.profile = profile
  },
  [types.SET_PROFILE_DATA](state, data) {
    state.profile = data
  },
  [types.UNSET_PROFILE_DATA](state) {
    state.profile = _.cloneDeep(defaultStore)
  },
}

const actions = {
  setProfileData({commit}, payload) {
    commit(types.SET_PROFILE_DATA, payload)
  },
  unsetProfileData({commit}) {
    commit(types.UNSET_PROFILE_DATA)
  },
  updateProfileData({commit}, payload) {
    commit(types.UPDATE_PROFILE_DATA, payload)
  },
  updateProfileDataItem({commit}, payload) {
    commit(types.UPDATE_PROFILE_DATA_ITEM, payload)
  },
}

// getters
const getters = {
  storedProfileData: state => state.profile
}

const plugins = [
  onLogout(absoluteNamespace + 'unsetProfileData'),
  createPersistedState({
    paths: [
      'profile.loyalty',
      'profile.orders',
    ].map(i => `${rootNamespace}.${namespace}.${i}`),
    key: absoluteNamespace + locationIdKey,
  })
]

const profile = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}
export default profile
