/*global _ */
import {getFirst} from '../../../../lib/formatters'

import contextProps from '../../../../props/context'
import ComponentMixin from '../../../../mixins/components/complex'
import ReactiveMixin from '../../../../mixins/mashup-builder/reactives'
export const mixins = [ComponentMixin, ReactiveMixin]

export default {
  name: 're-form-field',
  mixins,
  props: {
    ...contextProps,
    settings: {},
    translations: {},
    value: {},
    field: {
      type: [Object],
      default() {
        return {}
      }
    },
    form: {
      type: [Object],
      default() {
        return {}
      }
    },
    customErrors: {},
    validations: {
      type: [Object],
      default() {
        return {}
      }
    },
    v: {},
    vRoot: {},
    resetValidationOnInput: {
      default: false
    },
    $$v: {},
    classes: {}
  },
  model: {
      prop: 'value',
      event: 'update'
  },
  validations: null,
  data() {
    return {
      //fieldValue: this.$$fv(this.value, this.field.value, this.field.default)
      //fieldValue: this.value
    }
  },

  watch: {
    value(val) {
      this.fieldValue = val
    }
  },

  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update', val)
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    '$v': function() {
      return this.$$v
    },
    component() {
      const defaultComponent = 're-input'
      let type = this.field.type || 'input'
      if (type === 'text') {
        type = 'input'
      }
      let component = this.field.component || type
      const reComponent = _.startsWith(component, 're-') ? component : 're-' + component
      let probedComponent = this.probeComponent(reComponent)
      if (probedComponent) {
        return probedComponent
      }
      probedComponent = this.probeComponent(component)
      if (probedComponent) {
        return probedComponent
      }
      component = 're-' + type
      return this.probeComponent(component, defaultComponent)
    },
    isVisible() {
      return this.probeIf(this.field.show, this.field.hide, true)
    },
    isDisabled() {
      return this.probeIf(this.field.disabled, this.field.enabled)
    },
    isRequired() {
      return this.probeIf(this.field.required, this.field.notRequired)
    },
    fieldName() {
      return this.field.name
    },
    fieldType() {
      const field = this.field
      const type = this.attr.type || field.type || field.component || 'text'
      return type.replace(/^re-/, '')
    },
    attr() {
      const field = this.field
      const omitFromField = [
        'type', 'reactive', 'value', 'hidden', 'component', 'componentParseAttrs',
        'on', 'listeners',
        'show', 'hide', 'required', 'notRequired', 'disabled', 'enabled'
      ]
      const conditionals = _.pickBy({
        required: this.isRequired,
        disabled: this.isDisabled,
      }, (v) => _.isBoolean(v))

      let attr = {
        ..._.omit(field, omitFromField),
        ...(field.attr || {}),
        ...conditionals,
        ...this.parseDynamicAttrs(field.componentParseAttrs)
      }
      if (field.hidden) {
        attr.type = 'hidden'
      }

      if (!attr.context) {
        let context = field.context || this.context ||
          (this.$parent && this.$parent.context) || this.$parent
        attr.context = field.context = context
      }
      if (this.customErrors) {
        attr.customErrors = this.customErrors
      }
      return attr
    }
  },
  created() {

  },
  methods: {
    handleInput(...values) {
      this.fieldValue = values[0]
      this.resetFieldValidation()
    },
    resetFieldValidation(fieldsPath = 'fields') {
      if (this.resetValidationOnInput) {
        const fieldName = this.fieldName
        const pathPrefix = (fieldsPath ? `${fieldsPath}.` : '') + fieldName
        const reset  = _.get(this.$$v, pathPrefix + '.$reset')
        if (_.isFunction(reset)) {
          reset()
        }
      }
    },
    getFieldProps() {
      let props = {
        ...this.attr,
        validations: this.validations,
        v: this.v,
        vRoot: this.vRoot,
      }
      return props
    },
    getComponentExtraClasses() {
      const fieldType = this.fieldType
      const name = this.fieldName
      return [
        this.field.class || [],
        `field-type-${fieldType}`,
        `re-input-${name}`,
        {
          required: this.isRequired,
          disabled: this.isDisabled,
        }
      ]
    },
    probeIf(def, contraDef, defaultValue) {
      if (_.isBoolean(def)) {
        return def
      }
      if (_.isBoolean(contraDef)) {
        return def
      }

      const data = this.parseSourceData()

      if (_.isObject(def) && def['if']) {
        return this.probeReactiveIf(def['if'], data)
      }

      if (_.isObject(contraDef) && contraDef['if']) {
        return !this.probeReactiveIf(contraDef['if'], data)
      }

      return defaultValue
    },
    parseSourceData() {
      return {
        'this': this,
        '$': this.fieldValue,
        '$context': this.context || this.$parent,
      }
    },
    parseDynamicAttrs(attrs) {
      const values = _.mapValues(attrs || {}, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.parseSourceData()
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    getComponentListeners() {
      const item = this.field || {}
      let listeners = {
        ...(item.listeners || item.on || {}),
        ..._.pickBy(item, (i, k) => _.startsWith(k, '@'))
      }

      //const event = item.event || key
      listeners = _.mapValues(listeners, (listener) => {
        if (_.isObject(listener)) {
          const data = this.parseSourceData()
          const method = this.probeReactiveFunctions(listener, data, true)
          if (method) {
            listener = method
          }
        }
        if (_.isString(listener)) {
          listener = listener.replace(/^@/, '')
          listener = getFirst(listener, this, this.context)
        }
        return listener
      })

      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      return listeners
    },
    reset() {

    },
    show() {

    },
    hide() {

    }
  },
}
