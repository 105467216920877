export const name = 're-profile-playcards'

import { getFirstErrorMessage } from '~/lib/axios'
export {getFirstErrorMessage}

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import ProfileCardsMixin from '../../../../mixins/profile/cards'
import ProfileMixin from '../../../../mixins/profile'
import PlayCardListMixin from '../../../../mixins/external/playcard-list'
export const mixins = [ComponentMixin, ProfileMixin, ProfileCardsMixin, PlayCardListMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  cardType: 'playcard',
  reload_type: 'playcard_reload',
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  getFirstErrorMessage
}
