import {Vue, Vuex} from '../../addiesaas'
import _ from 'lodash'
import allModules from './resolve-all'
Vue.use(Vuex)

const createStore = () => {
  const plugins = [];
  allModules.values().forEach(m => {
    if ('plugins' in m) {
      plugins.push(...m.plugins)
    }
  })

  // option to custom set a state namespace in vuexstore files
  const modules = _.mapKeys(allModules, function(item, key) {
    return item.namespaced && item.namespace || key
  });

  const strict = process.env.NODE_ENV !== 'production'

  const newStore = new Vuex.Store({
    strict,
    modules,
    plugins
  })

  window.addiesaas.store = newStore

  return newStore
}
const store =  window.addiesaas.store || createStore()

export default store
