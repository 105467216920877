import component from '../../../../../props/component'
import form from '../../../../../props/form'
import cartItem from '../../../../../props/cart/item'
import processing from '../../../../../props/processing'

const localProps = {
  containerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s(
        'cart-item-card.class',
        this.$options.$$s('cart-item.class', null)
      )
    }
  },
  item: {
    type: Object,
    default: null
  },
  items: {
    type: [Array, Object],
    default: null
  },
  error: {
    type: [String, Object],
    default: null
  },
  showProductImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('cart-item-card.product.showImage', true)
    }
  },
  productImageClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('cart-item-card.product.imageClass', null)
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...cartItem,
  ...localProps,
}
export default props
