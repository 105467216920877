import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

import localProps from './props/local'
import formProps from './props/form'
import legacyProps from './props/legacy'

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps,
  ...formProps,
  ...legacyProps
}
export default props
