/* global _ */
import {mixins, props, data, defaultFormFields, validationsRules, EventBus} from '../config'
import {now} from '../../../../../../lib/dates'
import {toBoolean, fv, fn} from '../../../../../../lib/formatters'

import AsyncEventBus from '../../../../../../lib/async-event-bus'

import MainService from '../../../../../../services/main'

export default {
  mixins,
  props,

  data() {
    return {
      ...data,
      currentStep: 'billing',
      today: now,
      termsUrl: null,
      useUserData: false,
      triggerLoginSubmit: 0,
      loginEventSpace: 'cart.login',
      selectedAuthAction: this.authAction,
      isShowingForgotPassword: false,
      defaultLoginTranslations: {login: {label: '{form}'}}
    }
  },

  watch: {
    doLogin() {
      this.isShowingForgotPassword = false
    },
    selectedAuthAction(val) {
      this.setValidationError(null)
      this.$v.$reset()
      this.fields.doRegister = val === 'register'
    },
    authAction(val) {
      this.setValidationError(null)
      this.$v.$reset()
      this.fields.doRegister = val === 'register'
    },
    useUserData(newVal) {
      this.fields.first_name = newVal ? this.userDataMeta.first_name : ''
      this.fields.last_name = newVal ? this.userDataMeta.last_name : ''
      this.fields.email = newVal ? this.userDataMeta.email : ''
      this.fields.phone = newVal ? this.userDataMeta.phone : ''
    },
    async authenticated(newVal) {
      if (newVal) {
        this.validateRepeatCount = 0
        await this.validateCart()
        if (!this.valid || !this.billingEnabled) {
          this.goBack()
        }
      } else {
        await this.init()
      }
    }
  },

  async created() {
    this.setupForm(validationsRules, defaultFormFields, 'fields')
    this.setValidationError(null)

    await this.init()

    this.termsUrl = this.withTerms && (this.terms || '/terms-of-use')
    if(_.isEmpty(this.costDetails) && !_.isEmpty(this.totals)) {
      this.costDetails = this.totals
    }

    EventBus.$on('cart.processing.error', async (e, data) => {
      const all = e.all || {}
      if (!this.allowGuestCheckout && all.guest && all.signup) {
        await this.$nextTick(async () => {
          this.setValidationError('')
          this.setCartValid()
          this.setCartValidating(false)
          await this.pingUser()
          this.$forceUpdate()
          this.setCartValidating(false)
        })
      }
      if (this.doRegister && all.email) {
        this.showLoginProposal = true
        this.setValidationError('')
        this.setCartValid()
      }
    })
  },

  computed: {
    maxDateTime() {
      return this.getNow().toISODate()
    },
    isGuestCheckoutEnabled() {
      return this.allowGuestCheckout && this.hasAllGuestCheckoutProducts(this.guestCheckoutTypes)
    },
    authActionOptions() {
      const lang = this.authActionLang || {}
      let options = _.mapValues(this.authActionTypes, (item, key) => {
        item.label = _.get(lang, `${key}.label`, item.label || _.startCase(key))
        return item
      })
      if (!this.isGuestCheckoutEnabled) {
        options = _.omit(options, ['guest'])
      }
      return options
    },
    billingAuthMode() {
      return this.authenticated ? 'auth' : this.selectedAuthAction
    },
    doRegister() {
      return !this.authenticated && this.selectedAuthAction === 'register'
    },
    doLogin() {
      return !this.authenticated && this.selectedAuthAction === 'login'
    },
    doLoginForgotPassword() {
      return this.doLogin && this.isShowingForgotPassword
    },
    doGuest() {
      return !this.authenticated && this.selectedAuthAction === 'guest'
    },
    userNotice() {
      return this.$$templify(this.userNoticeTemplate, this.userDataMeta)
    },
    nextMonth() {
      return this.today.plus({months: 1})
    },
    expYears() {
      const years = []
      for (let i = this.nextMonth.year; i < this.nextMonth.year + 20; i++) {
        years.push(i)
      }
      return years
    },
    showSubmitButton() {
      return !this.currentCartIsEmpty && this.costDetails
    },
    showUserDataCheckbox() {
      return !_.isEmpty(this.userDataMeta) && this.showUseUserDataCheckbox
    },
    errorMessage() {
      return this.validationError
    },
    expirationDateGroupClasses() {
      const isField = this.isSectionField('billing', 'expDate', false)
      const classes = []
      if (isField) {
        const isMonthInvalid = this.dirtyInvalid('fields.expMonth')
        const isYearInvalid = this.dirtyInvalid('fields.expYear')
        if (isYearInvalid || isMonthInvalid) {
          classes.push('group-is-invalid')
        }
        const isMonthRequired = _.has(this.validations, `expMonth.items.required`)
        const isYearRequired = _.has(this.validations, `expYear.items.required`)
        if (isMonthRequired || isYearRequired) {
          classes.push('required')
        }
      }
      return classes
    }
  },

  methods: {
    async init() {
      if (!this.authenticated) {
        this.onAuthAction(this.selectedAuthAction)
        this.fields.doRegister = this.selectedAuthAction === 'register'
      }
      if (!_.isEmpty(this.cartPaymentDetails) && this.viewPaymentDetails) {
        this.fields = _.clone(this.cartPaymentDetails)
        this.setPaymentDetails({});
      }
    },
    switchToLogin() {
      this.showLoginProposal = false
      this.selectedAuthAction = 'login'
    },
    getComponentExtraClasses() {
      return [{
        'disabled': !!this.isFormDisabled,
        'review-payment-details': !!this.viewPaymentDetails,
        'register-flow': this.doRegister,
        'login-flow': this.doLogin,
        'login-flow-forgot-password': this.doLoginForgotPassword,
        'authenticated-flow': this.authenticated
      }]
    },
    triggerLogin() {
      this.triggerLoginSubmit++
    },
    onAuthAction(action) {

    },
    backToCart() {
      this.setCartInvalid()
      this.setValidationError(null)
      this.$v.$reset()
      this.setCartStep('cart')
      EventBus.$emit('billing.go-back', this)
    },

    setupValidationRules(validationRules, fieldKey, parseValidations) {
        this.setValidationRules = function () {

          let authSkip = fv(this.authSkipValidationFields, [])
          if (!_.isArray(authSkip)) {
            authSkip = []
          }

          let fieldsToValidate = this.getFieldsToValidate()
          if (this.authenticated) {
            fieldsToValidate = _.difference(fieldsToValidate, authSkip)
          }
          let rules = _.cloneDeep(validationRules)
          fieldsToValidate = _.keyBy(fieldsToValidate)
          rules = _.mapValues(rules, (rule, key) => fieldsToValidate[key] ? rule : {})
          return {
            [fieldKey]: parseValidations(rules)
          }
        }
    },

    getFieldsToValidate() {
      const  settings = _.keys(this.allSectionsSettings)
      let fields = []
      _.forOwn(settings, key => {
          if (key !== 'shipping' || this.hasShippableProduct) {
            const isShow = this.section(key, 'show', true, true)
            let sectionFields = this.getSectionFields(key)
            if (isShow === false) {
              sectionFields = []
            }
            if (_.isString(sectionFields)) {
              sectionFields = sectionFields
                .replace(/\s/g, '')
                .split(',')
            }
            if (!_.isArray(sectionFields) && _.isObject(sectionFields)) {
              sectionFields = _.values(sectionFields)
            }
            if (!_.isEmpty(sectionFields)) {
              fields = fields.concat(sectionFields)
            }
          }
      })
      return _.uniq(fields)
    },

    getCartShippingPayload() {
      return _.reduce(this.fields, (c, value, key) => {
        if (_.startsWith(key, 'shipping_')) {
          const shipKey = key.replace(/^shipping_/, '')
          c[shipKey] = value
        }
        return c
      }, {})
    },

    async submit() {
      this.fields.billingEnabled = this.billingEnabled

      this.formSubmitting = true
      this.setValidationError()

      if (!this.separateBillingForm) {
        this.$v.$touch()
        if (this.isAnyInvalid) {
          this.isSubmitting = false
          this.setCartInvalid()
          this.setValidationError('There was a problem with your submission. Errors have been highlighted above.')

          EventBus.$emit('billing.invalid', this)
          this.scrollToFirstErrorElement()

          return
        }
      }

      const payload = {}
      if (this.billingEnabled) {
        payload.payment = {
          card: {
            number: this.fields.card.replace(/ +/g, '').trim(),
            year: this.fields.expYear,
            month: this.fields.expMonth,
            code: this.fields.cvv,
            first_name: this.fields.first_name,
            last_name: this.fields.last_name,
            brand: this.fields.card_brand,
          },
        }
        payload.payment.billing = {
          address: this.fields.billing_street,
          state: this.fields.billing_state,
          zip: this.fields.zip,
          city: this.fields.billing_city,
          country: this.fields.billing_country,
        }

        if (this.paymentTransactionId) {
          payload.payment.transaction_id = this.paymentTransactionId
        }

        payload.payment.card.extra = this.paymentCardExtra
      }
      if (this.hasShippableProduct) {
        payload.shipping = this.getCartShippingPayload()
      }

      if (this.doRegister) {
        payload.signup = this.getSignupPayload()
      } else {
        if (!this.authenticated) {
          payload.guest = {
            first_name: this.fields.first_name,
            last_name: this.fields.last_name,
            email: this.fields.email,
            phone: this.fields.phone
          }
        }
      }
      EventBus.$emit('billing.before.submit', payload)
      await AsyncEventBus.emit('billing.before.submit.async', payload)
      this.$v.$reset()
      this.setPaymentDetails(this.fields)
      await this.submitCart(payload)
    },

    section(name, path, defaultValue, preferBillingAuthMode) {
      const allSections = _.merge({}, this.allSectionsLang, this.allSectionsSettings)
      if (!name && !path) {
        return allSections
      }
      let sections = allSections
      if (name) {
        sections = sections[name] || {}
      }
      if (path) {
        if (preferBillingAuthMode) {
          const mode = this.billingAuthMode
          const modePath = `modes.${mode}.${path}`
          let value = _.get(sections, modePath)
          if (!_.isNil(value)) {
            return value
          }
        }
        return _.get(sections, path, defaultValue)
      }
      return sections
    },

    getSignupPayload() {
      return {
        first_name: this.fields.first_name,
        last_name: this.fields.last_name,
        email: this.fields.email,
        phone: this.fields.phone,
        birth_date: this.fields.birth_date,
        password: this.fields.password,
        password_confirmation: this.fields.password_confirmation,
        subscribe: this.fields.subscribe,
        gender: this.fields.gender,
        preferred_location_id: this.fields.preferred_location_id,
        zip: this.fields.zip,
      }
    },

    /**
     *
     * @param section
     * @param field
     * @param defaultValue
     * @returns {boolean}
     */
    isSectionField(section, field, defaultValue) {
      const isField = this.isField(field, defaultValue)
      if (isField) {
        const fields = this.getSectionFields(section)
        return _.indexOf(fields, field) >= 0
      }
      return false
    },
    getSectionFields(section) {
      let fields = this.section(section, 'fields', [], true)
      if (_.isString(fields)) {
        fields = fields.replace(' ', '').split(',')
      }
      return fields
    },
    getSectionFieldTabIndex(section, field) {
      const tabIndexStarts =this.section(section, 'tabIndexStarts', null, true)
      if (_.isNil(tabIndexStarts)) {
        return null
      }
      const fields = this.getSectionFields(section)
      const index = fields.indexOf(field)
      return fn(tabIndexStarts, 1) + fn(index, 0)
    },
    isSectionVisible(section) {
      return toBoolean(this.section(section, 'show', true, true))
    },

    async validateContact() {
      this.setValidationError()
      this.$v.$touch()

      try {
        if (!this.isAnyInvalid) {

          if (this.doRegister && !this.billingEnabled && this.separateBillingForm) {
            await this.submit()
          } else {
            if (this.doRegister) {
              const payload = this.getSignupPayload()
              payload.email_confirmation = payload.email
              try {
                await axios.post(`${window.addiesaas.url}/api/user/register/validate`, payload)
              } catch (e) {
                throw MainService.getFirstErrorMessage(e)
              }
            }

            this.showContactStep = false
          }

          return
        } else {
          throw 'There was a problem with your submission. Errors have been highlighted above.'
        }
      } catch (e) {
        this.setCartInvalid()
        this.setValidationError(e)

        EventBus.$emit('billing.invalid', this)
        this.scrollToFirstErrorElement()
      }
    },

    goBack() {
      if (this.separateBillingForm && !this.showContactStep) {
        this.showContactStep = true
      } else {
        this.backToCart()
      }
    },

   },
}
