export const name = 're-shopping-cart'

// generic setup
import {allValidationsRules, defaultFormFields as formFields} from './form'
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
import CartMixin from '../../../../mixins/cart'
import CartLocationMixin from '../../../../mixins/cart/location'
import FailingLocationMixin from '../../../../mixins/shop/failing-location'
import DesignerMixin from './designer'
export const mixins = [ComponentMixin, FormMixin, CartMixin, CartLocationMixin, FailingLocationMixin, DesignerMixin]

import Vnodes from '../../../elements/vnodes'

export const EventBus = window.VueEventBus

export const components = {Vnodes}
// data
export const data = {
  propDefs,
  selectedLocationProperty: 'cartLocationId'
}

export default {
  name,
  components,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus,
}
