import ModalMixin from '../../../mixins/modal'

export default {
  name: 're-success',
  type: 'success',
  mixins: [ModalMixin],
  props: {
    title: {
      type: String,
      default: 'Success'
    },
    message: {
      type: String,
      default: 'The operation was successful'
    }
  },
  methods: {
    successClick() {
      this.close;
    }
  },
}
