export const DESIGNER_EVENTS = {
  PROCESSOR_INIT: 'designerVueProcessorInit',
  PROCESSOR_PROCESSED: 'designerVueProcessorProcessed',

  // called just after vuejs component is rendered
  // yet to be mounted or parsed (process HTML)
  // component is a JSON definition for vue-component
  // component is a grapesjs component for vue-instance
  // inject properties (props) and attributes to data
  // these will be merged to existing definition
  VUE_CREATE: 'designerVueCreate', // {componentName, componentOptions}
  VUE_CREATED: 'designerVueCreated', // {data, ...config}
  VUE_CREATED_BEFORE: 'designerVueCreated:before',
  VUE_CREATED_AFTER: 'designerVueCreated:after',

  // PROCESSOR_* are only for VUE-COMPONENT type
  PROCESSOR_PREPARE_HTML: 'designerVueProcessorPrepareHtml', // {component::GJSC, editor}
  // called after vuejs component HTML is parsed
  PROCESSOR_COMPONENT_CREATED: 'designerVueProcessorComponentCreated', // {component::gsjs, editor}

  PREPARE: 'prepareDesignerComponentGlobalDefinition', // {editor, data, component::JSON}
  PREPARE_PREFIX: 'prepareDesignerComponentGlobal', // {editor, data, component::JSON}
  PREPARE_PROPERTIES: 'prepareDesignerComponentGlobalProperties',
  PREPARE_ATTRIBUTES: 'prepareDesignerComponentGlobalAttributes',
  PREPARE_STYLES: 'prepareDesignerComponentGlobalStyles',
  PREPARE_TOOLBARS: 'prepareDesignerComponentGlobalToolbars',
  PREPARE_DEFAULTS: 'prepareDesignerComponentGlobalDefaults',
  PREPARE_PROPS: 'prepareDesignerComponentGlobalProps', // traits

  PREPARE_LOCAL: 'prepareDesignerComponentDefinition', // {editor, data, component::JSON}
  PREPARE_LOCAL_PREFIX: 'prepareDesignerComponent', // {editor, data, component::JSON}
  PREPARE_LOCAL_PROPERTIES: 'prepareDesignerComponentProperties',
  PREPARE_LOCAL_ATTRIBUTES: 'prepareDesignerComponentAttributes',
  PREPARE_LOCAL_STYLES: 'prepareDesignerComponentStyles',
  PREPARE_LOCAL_TOOLBARS: 'prepareDesignerComponentToolbars',
  PREPARE_LOCAL_DEFAULTS: 'prepareDesignerComponentDefaults',
  PREPARE_LOCAL_PROPS: 'prepareDesignerComponentProps', // traits

  VIEW_INIT: 'designerViewInitiated',

  INIT: 'designerInitiated', // {component, model}
  INIT_BEFORE: 'designerInitiated:before',
  INIT_AFTER: 'designerInitiated:after',

  VUE_OPTIONS_LOADING: 'designerLoadViewOptions',
  VUE_OPTIONS_LOADED: 'designerLoadedViewOptions',
  TRAITS_ADDING: 'designerAddTraits',
  TRAITS_ADDED: 'designerLoadedViewOptions',

  // BOTH for VUE-COMPONENT and VUE-INSTANCE
  //
  RENDERED: 'designerRendered', // {component, editor, el)
  RENDERED_AFTER: 'designerRendered:after', // {component, editor, el)

  COLLECT_WRAPPER: 'designerCollectComponentWrapOpts',

  MOUNTED: 'designerMounted',
  UPDATED: 'designerUpdated',
  ATTRIBUTE_UPDATED: 'designerAttributeUpdated',
  PROPERTY_UPDATED: 'designerPropertyUpdated',
  STATE_UPDATED: 'designerStateUpdated',
  COMPONENT_STATE_UPDATED: 'designerComponentStateUpdated',
  STATE_RESET: 'designerStateReset',
  SYNC_CONTENT: 'designerSyncContent',

  VIEW_REMOVED: 'designerViewRemoved', // {component}
  VIEW_DESTROY: 'designerViewDestroy', // {component}
  REMOVED: 'designerRemoved',
  DESTROY: 'designerDestroy',

  ACTIVATED: 'designerOnActive',
  DISABLED: 'designerOnDisable',
  STATUS_UPDATED: 'designerStatusUpdated',

  BUILD_HTML: 'buildDesignerComponentHTML',
  BUILD_SLOT_HTML: 'buildDesignerSlotHTML',
  BUILD_PROPS: 'buildDesignerProps',
  BUILD_SLOT_PROPS: 'buildDesignerSlotProps',

  // only for VUE-COMPONENT type
  // when component is loaded again from storage and vue instance will be missing
  // called after vuejs component is re-created
  RECREATED: 'designerComponentRecreated', // {el, component::GJSC, editor}

  // only for VUE-INSTANCE type
  // vue instances are created in init listener of grapesjs component
  // and props and attrs are set from data returned from creator
  VUE_RENDER_INIT: 'designerComponentRenderVueInit', // {el, component::GJSC, editor}
  VUE_RENDER_MOUNTED: 'designerComponentRenderVueMounted', // {el, component::GJSC, editor}

  ALL: 'designerCatchAllListener',
}

// Editor specific events
export const DESIGNER_PARENT_EVENTS = {
  CHILD_CREATED: 'children:component:create',
  CHILD_MOUNTED: 'children:component:mount',
  CHILD_ADDED: 'children:component:add',
  CHILD_REMOVED: 'children:component:remove',
  CHILD_CLONED: 'children:component:clone',
}

// VUE custom events
export const VUE_EVENTS = {
  UPDATED: 'updated'
}
export const VUE_METHODS = {
  VUE_CREATED: 'designerVueCreated',
  VUE_CREATED_BEFORE: '$_designerVueCreated',
  VUE_CREATED_AFTER: 'designerVueCreated$_',

  PROCESSOR_COMPONENT_CREATED: 'designerVueProcessorComponentCreated',
  PROCESSOR_COMPONENT_CREATED_BEFORE: '$_designerVueProcessorComponentCreated',
  PROCESSOR_COMPONENT_CREATED_AFTER: 'designerVueProcessorComponentCreated$_',

  INIT: 'designerInitiated',
  INIT_BEFORE: '$_designerInitiated',
  INIT_AFTER: 'designerInitiated$_',

  // only for vue instances
  VUE_RENDER_INIT: 'designerComponentRenderVueInit',
  VUE_RENDER_INIT_BEFORE: '$_designerComponentRenderVueInit',
  VUE_RENDER_INIT_AFTER: 'designerComponentRenderVueInit$_',

  VIEW_INIT: 'designerViewInitiated',
  VIEW_INIT_BEFORE: '$_designerViewInitiated',
  VIEW_INIT_AFTER: 'designerViewInitiated$_',

  RENDERED: 'designerRendered',
  RENDERED_BEFORE: '$_designerRendered',
  RENDERED_AFTER: 'designerRendered$_',

  UPDATED: 'designerUpdated',
  UPDATED_BEFORE: '$_designerUpdated',
  UPDATED_AFTER: 'designerUpdated$_',

  ATTRIBUTE_UPDATED: 'designerAttributeUpdated',
  ATTRIBUTE_UPDATED_BEFORE: '$_designerAttributeUpdated',
  ATTRIBUTE_UPDATED_AFTER: 'designerAttributeUpdated$_',

  MOUNTED: 'designerMounted',
  MOUNTED_BEFORE: '$_designerMounted',
  MOUNTED_AFTER: 'designerMounted$_',

  VIEW_REMOVED: 'designerViewRemoved',
  VIEW_REMOVED_BEFORE: '$_designerViewRemoved',
  VIEW_REMOVED_AFTER: 'designerViewRemoved$_',

  VIEW_DESTROY: 'designerViewDestroy',
  VIEW_DESTROY_BEFORE: '$_designerViewDestroy',
  VIEW_DESTROY_AFTER: 'designerViewDestroy$_',

  REMOVED: 'designerRemoved',
  REMOVED_BEFORE: '$_designerRemoved',
  REMOVED_AFTER: 'designerRemoved$_',

  DESTROY: 'designerDestroy',
  DESTROY_BEFORE: '$_designerDestroy',
  DESTROY_AFTER: 'designerDestroy$_',

  PROPERTY_UPDATED: 'designerPropertyUpdated',
  PROPERTY_UPDATED_BEFORE: '$_designerPropertyUpdated',
  PROPERTY_UPDATED_AFTER: 'designerPropertyUpdated$_',

  SYNC_CONTENT: 'designerSyncContent',
  SYNC_CONTENT_BEFORE: '$_designerSyncContent',
  SYNC_CONTENT_AFTER: 'designerSyncContent$_',
}

export const STATES = {
  CLONING: '__cloning',
  SAVING: '__saving',

  PAGE_DRAFT: 'draft',
  PAGE_PREVIEW: 'preview',
  PAGE_PUBLISHED: 'published',
  TRIGGER_ALL: 'TRIGGER_ALL',
  INIT: DESIGNER_EVENTS.INIT,
  VIEW_INIT: DESIGNER_EVENTS.VIEW_INIT,
  RENDERED: DESIGNER_EVENTS.RENDERED,
  ACTIVATED: DESIGNER_EVENTS.ACTIVATED,
  CANVAS_UNLOADING: 'canvasUnloading',

  CURRENT_CONTENT: 'currentContent',
  CURRENT_CONTENTSET: 'currentContentSet',
  CURRENT_TEMPLATE: 'currentTemplate',
  CONTENT_ID: 'contentId',
  CONTENTSET_ID: 'contentSetId',
  CURRENT_CONTENT_STATE: 'currentContent.state',
  PREVIOUS_CONTENT_STATE: 'previousContentState',
}

export const DESIGNER_CUSTOMIZE_EVENTS = {
  CUSTOMIZE_SETTING_BOX: 'designerComponentCustomizeSettingBox',
}


export const SILENT_STORE_OPTIONS = {silent: true, avoidStore: true}

export default {
  DESIGNER_EVENTS,
  DESIGNER_PARENT_EVENTS,
  VUE_EVENTS,
  VUE_METHODS,
  STATES,
  SILENT_STORE_OPTIONS,
  DESIGNER_CUSTOMIZE_EVENTS
}
