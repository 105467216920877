/*global _ */
// @requires: Utils/Vue/UI/WEB/Resources/Assets/js/mixins/profile/cards/locations.js
export default {
  methods: {
    async getUserLoyaltyCardsLocations(type = 'playcard') {
      const locations = await this.getUserCardsLocations(type)
      const loyaltyLocations = this.earnedLoyaltyLocationPoints || {}
      _.forOwn(loyaltyLocations, (value, id) => {
        if (value) {
          const location = _.get(window.addiesaas, `locations.${id}`)
          locations[id] = location.display_name || location.name
        }
      })
      return locations
    },
    async getUserLoyaltyPlayCardsLocations() {
      return await this.getUserLoyaltyCardsLocations('playcard')
    }
  }
}
