import {Vue} from '~/addiesaas'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const { locale = 'en-US', translations = {} } = window.addiesaas.config

const {
  dateTimeFormats = {},
  numberFormats = {},
} = translations

const i18n = window.addiesaas.i18n = new VueI18n({
  locale,
  dateTimeFormats: {
    [locale]: dateTimeFormats
  },
  numberFormats: {
    [locale]: numberFormats
  },
  messages: {
    [locale]: translations
  }
})

Vue.prototype.$$locale = locale

export default i18n
