/*global _ */
export const name = 're-shop-products-pagination'
import component from '../../../../../../props/component'
import form from '../../../../../../props/form'
import {queryVars} from '../../base/props'

export const props = {
  ...queryVars,
  ...component,
  ...form,

}
import BaseMixin from '../../base/mixin'
import ComponentMixin from '../../../../../../mixins/components/complex'
import ProductsMixin from '../../../../../../mixins/shop/products'

export const mixins = [ComponentMixin, BaseMixin, ProductsMixin]

export const data = {}

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },

  computed: {
    hasStats() {
      return !_.isEmpty(this.shopProductsPagination.result)
    },
    stats() {
      return this.shopProductsPagination.result || {}
    },
    totalPages() {
      return this.stats.last_page
    },
    currentPage() {
      return this.stats.current_page
    },
    canNext() {
      return this.currentPage < this.totalPages
    },
    canPrev() {
      return this.currentPage > 1
    },
    pageItems() {
      const first = 1
      const last = this.totalPages
      if (!last) {
        return []
      }
      const prev = Math.max(first, this.currentPage - 1)
      const current = this.currentPage
      const next = Math.min(last, current + 1)
      let prevPlus = Math.max(first, prev - 1)
      if (prevPlus - 1 > first) {
        prevPlus += '++'
      }
      let nextPlus = Math.min(last, next + 1)
      if (nextPlus + 1 < last) {
        nextPlus += '++'
      }

      const pages = [first, prevPlus, prev, current, next, nextPlus, last]
      const items = _.uniq(pages)
      return items
    }
  },

  async created() {

  },

  mounted() {

  },
  methods: {
    canNav(page) {
      return _.isFinite(page) &&
        page !== this.currentPage &&
        page >= 1 &&
        page <= this.totalPages
    },
    loadPage(page) {
      if (this.canNav(page)) {
        this.setShopPaginationProperty({page})
      }
    }
  }
}
