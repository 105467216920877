import {name, mixins, props, data, EventBus} from './config'
import {transferPlaycardBalance} from '../../../../services/card'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },
  watch: {
    'User.cards.playcard': {
      immediate: true,
      handler(val) {
        this.playcards = this.parseCards(val)
      }
    },
    playcards: {
      deep: true,
      handler(val) {
        this.cardsList = this.parseCards(val)
      }
    }
  },

  methods: {
    async load(...args) {
      await this.fetch(...args)
    },
    async fetch(autoloadAll) {
      this.playcards = this.User.cards.playcard
      this.emit('fetch.init')
      try {
        this.isLoading = !this.playcards.length
        const autoLoads = this.autoLoad
        if (autoLoads) {
          await this.initialAutoLoadCompleted()
          await this.autoLoadAll(autoLoads)
        } else {
          if (autoloadAll) {
            await this.autoLoadAll(autoLoads)
            await this.initialAutoLoadCompleted()
          }
        }
        const cards = await this.autoLoadPlaycards()
        this.playcards = cards
        this.arePlaycardsLoaded = true
        this.emit('playcards.loaded', cards)
        this.$emit('success', cards)
        this.emit('fetch.success', cards)
      } catch (e) {
        this.$emit('error', e)
        this.emit('fetch.error', e)
        this.emitError('playcards.load.error', e)
      } finally {
        this.isLoading = false
        this.emit('fetch.end')
      }
    },
    registerEventListeners() {
      EventBus.$on('playcards.load.error', (error, e, card) => {
        this.errorAlertAsync(error)
      })
      EventBus.$on('playcard.archive.error', (error, e, card) => {
        debugLog("playcard.archive.error", [error, e, card])
        this.errorAlertAsync(error)
      })
      EventBus.$on('playcard.primary.error', (error, e, card) => {
        this.errorAlertAsync(error)
      })
      EventBus.$on('playcard.primared', (card) => {
        if (this.$$s('card.setPrimary.hideConfirmation')) {
          this.successAlertAsync(this.$$t('card.setPrimary.success.message'))
        }
      })
      EventBus.$on('playcard.add.error', (error, e, card, options) => {
        options = options || {}
        if (!options.standalone) {
          this.errorAlertAsync(error)
        }
      })
      EventBus.$on('playcard.transfer-balance.error', (error, e, card) => {
        this.errorAlertAsync(error)
      })

      EventBus.$on('playcard.added', (card, options) => {
        options = options || {}
        if (options.standalone) {
          this.autoLoadAll(['playcards', ...this.autoLoad])
        }
      })
    },
    parseCardMoreAbilities(card, cards) {
      const mixin = this
      card.$can = {
        ...(card.$can || {}),
        ...{
          setPrimary: this.allowSetPrimary && !card.is_primary,
          transferBalance: this.allowTransferBalance,
        }
      }
      card.$actions = {
        ...(card.$actions || {}),
        ...{
          primary: async () => await mixin.primary(card),
          // transferBalance: async (...args) => await mixin.transferBalance(card, ...args),
          transferBalance: async (...args) => {
            await this.cardTransferBalance(card, ...args)
          },
        }
      }

      return card
    },

    async addPlayCard(fields, onSuccess, onError) {
      this.playcard.number = fields.number
      this.playcard.cvv = fields.cvv
      await this.add(onSuccess, onError)
    },
    recoverByEmail(payload) {
      this.$emit('recoverByEmail', payload)
      EventBus.$emit('playcard.recover-by-email', payload)
    },
    recoverByNumber(payload) {
      this.$emit('recoverByNumber', payload)
      EventBus.$emit('playcard.recover-by-number', payload)
    },
    async cardTransferBalance(card, to_playcard_id, options) {
      const payload = {
        ...options,
        id: card.id,
        to_playcard_id
      }
      const {data} = await transferPlaycardBalance(payload)
      await this.autoLoadAll(['playcards', ...this.autoLoad])
      return data
    }
  },
}
