import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  token: {
  },
  email: {
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Reset Password')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', 'Provide the following information to change your password.')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$s('submitButtonTitle', 'Submit')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Submitting your updates')
    }
  },

  showSuccessPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSuccessPopup', false)
    }
  },
  successTitle: {
    type: [String],
    default() {
      return this.$options.$$t('successTitle' , 'Success')
    }
  },
  showSuccessTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSuccessTitle', true)
    }
  },
  successMessage: {
    type: String,
    default() {
      return this.$options.$$t('successMessage', 'Your password has been changed.')
    }
  },
  showSuccessMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSuccessMessage', true)
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', true)
    }
  },
  showLoginBox: {
    type: [Boolean, Number, String],
    default() {
    return !!this.$options.$$s('showLoginBox', true)
    }
  },

  emailLabel: {
    type: [String],
    default() {
      return this.$options.$$t('form.fields.email.label', 'Email')
    }
  },

  passwordLabel: {
    type: [String],
    default() {
      return this.$options.$$t('form.fields.password.label', 'New Password')
    }
  },

  passwordConfirmationLabel: {
    type: [String],
    default() {
      return this.$options.$$t('form.fields.password_confirmation.label', 'Confirm Password')
    }
  },

  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },
  autoLoginRedirectBy: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('autoLoginRedirectBy', false)
    }
  },
  showAutoLoginRedirectCountdown: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showAutoLoginRedirectCountdown', true)
    }
  },

}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
