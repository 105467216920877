/*global _ */
import createPersistedState from 'vuex-persistedstate'
import * as types from '../mutation-types/super'

// set this true to namespace all getters, mutations, actions etc.
const namespaced = true
export const namespace = 'Super'
const rootNamespace = 'REUtils'
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

import {fv} from '../../lib/utils'
const location = window.addiesaas.location || {}
const locationId = location.id || ''
const locationIdKey = locationId

// state
const state = {
  store: {

  },
  storePersist: {

  }
}

// mutations
const mutations = {
  [types.INIT_SUPER_ITEM](state, items) {
    const store = state.store || {}
    _.forOwn(items, (value, path) =>  {
      if (!_.has(store, path)) {
        _.set(store, path, fv(value, {}))
      }
    })
    state.store = Object.assign({}, store)
  },
  [types.INIT_SUPER_PERSIST_ITEM](state, items) {
    const store = state.storePersist || {}
    _.forOwn(items, (value, path) =>  {
      if (!_.has(store, path)) {
        _.set(store, path, fv(value, {}))
      }
    })
    state.storePersist = Object.assign({}, store)
  },
  [types.SET_SUPER_ITEM](state, items) {
    state.store = _.merge({}, state.store, items)
  },
  [types.SET_SUPER_PERSIST_ITEM](state, items) {
    state.storePersist = _.merge({}, state.storePersist, items)
  },
  [types.UNSET_SUPER_ITEM](state, items) {
    const store = state.store
    _.forOwn(_.castArray(items), path =>  {
      _.unset(store, path)
    })
    state.store = Object.assign({}, store)
  }
}

const actions = {
  $$storeInit({commit}, payload) {
    commit(types.INIT_SUPER_ITEM, payload)
  },
  $$storePersistInit({commit}, payload) {
    commit(types.INIT_SUPER_PERSIST_ITEM, payload)
  },
  $$storeSet({commit}, payload) {
    commit(types.SET_SUPER_ITEM, payload)
  },
  $$storeSetPersist({commit}, payload) {
    commit(types.SET_SUPER_PERSIST_ITEM, payload)
  },
  $$storeUnset({commit}, payload) {
    commit(types.UNSET_SUPER_ITEM, payload)
  },
}

// getters
const getters = {
  $$storePersistGet: state => {
    return (path, defaultValue) => {
      if (_.isUndefined(path)) {
        return state.storePersist
      }
      return _.get(state.storePersist, path, defaultValue)
    }
  },
  $$storeGet: state => {
    return (path, defaultValue) => {
      if (_.isUndefined(path)) {
        return state.store
      }
      return _.get(state.store, path, defaultValue)
    }
  },
}

const plugins = [
  createPersistedState({
    paths: [
      'storePersist'
    ].map(i => `${rootNamespace}.${namespace}.${i}`),
    key: absoluteNamespace + locationIdKey,
  })
]

const superStore = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}
export default superStore
