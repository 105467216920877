export default {
  computed: {
    baseUrl() {
      const tenantGetter = 'MultiTenancy/tenant/tenant'
      if (tenantGetter in this.$store.getters) {
        return this.$store.getters[tenantGetter].url
      }
      return window.addiesaas.config.baseUri
    },
  },
}
