export default {
  computed: {
    pageIndex: {
      get() {
        return _.get(this.storedProfileData, 'orders.pageIndex', 0)
      },
      set(value) {
        this.updateProfileDataItem({'orders.pageIndex': value})
      }
    },
    paginationAttrs() {
      return {
        total: this.pageCount,
        settings: this.$$s('table.pagination', {}),
        translations: this.$$t('table.pagination', {}),
      }
    },
    pageNumber() {
      return this.pageIndex + 1
    },
    pageCount() {
      return this.paginate ? Math.ceil(this.ordersCount / this.pageSize) : 1
    },
    hasPagination() {
      return this.paginate && this.pageCount > 1
    },
  },
  methods: {
    goPage(pageNumber, isIndex = true) {
      const index = isIndex ? pageNumber : (pageNumber - 1)
      if (index >= 0 && index < this.pageCount) {
        this.pageIndex = index
      }
    },
    nextPage() {
      this.goPage(this.pageIndex + 1)
    },
    prevPage() {
      this.goPage(this.pageIndex - 1)
    },
  }
}
