/* global _ */
const defaultTable = {
  columns: [
    {
      name: 'title',
      key: 'title',
      title: 'Title'
    },
    {
      name: 'description',
      key: 'description',
      title: 'Description'
    },
    {
      name: 'code',
      key: 'coupon_code',
      title: 'Coupon Code'
    },
    {
      name: 'expires',
      valuePath: 'expire_at',
      title: 'Ends',
      format: 'formatDate'
    },
  ]
}

const filters = [
  {name: 'All', value: ''},
  {name: 'Active', value: 'isActive', noDataError : 'There are no active offers and rewards at the moment.'},
  {name: 'Expired', value: 'hasExpired', noDataError : 'There are no expired offers and rewards at the moment.'},
  {name: 'Claimed', value: 'hasClaimed', noDataError : 'There are no claimed offers and rewards at the moment.'},
]

export default {
  data() {
    return {
      defaultTable
    }
  },
  computed: {
    filterComponent() {
      return this.$$s('table.filter.component', 're-select')
    },
    filterComponentAttrs() {
      const $s = this.$$s('table.filter.componentAttrs', {})
      const $t = this.$$t('table.filter.componentAttrs', {})
      return _.merge({options: filters}, $t, $s)
    },

    tableComponentAttrs() {
      return {
        ...this.tableComponentMainAttrs,
        disabled: !!this.editingMember,
        activeRow: this.editingRow
      }
    }
  },
  methods: {
    applyFilter(items) {
      const filter = this.filter
      return _.filter(items, (item) => {
        if (_.has(item, filter)) {
          return _.get(item, filter)
        }
        if (this[filter]) {
          return this[filter](item)
        }
        return true
      })
    }
  }
}
