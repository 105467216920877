/*global _ */
import {fv, templify} from '../../../lib/utils'
import AutoLoadLocationsMixin from '../../location-autoload'
import {req, withParams} from '../../../lib/validations'

export default {
  mixins: [AutoLoadLocationsMixin],
  props: {
    validateFailingCardLocation: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('locations.cards.validateFailing', true)
      }
    },
    failingCardLocationMessage: {
      type: [String],
      default() {
        return this.$options.$$t('locations.cards.failing.message', 'Card location {location} is under maintenance.')
      }
    },
    selectedCardPropertyName: {
      type: [String],
      default() {
        return this.$options.$$s('selectedCardPropertyName', 'card')
      }
    },
    selectedCardFieldName: {
      type: [String],
      default() {
        return this.$options.$$s('selectedCardFieldName', 'card')
      }
    },
  },
  data() {
    return {
      selectedCardProperty: null,
      selectedCardField: null
    }
  },
  computed: {
    selectedCardLocationId() {
      return (_.get(this, this.selectedCardProperty) || {}).location_id
    },
    selectedCardLocationName() {
      const locationId = this.selectedCardLocationId
      if (locationId) {
        const locations = this.allLocationDetails || {}
        const location = locations[locationId] || {}
        return location.display_name
      }
      return ''
    },
    failingCardLocationMessageFormatted() {
      return templify(
        this.failingCardLocationMessage,
        {$: this, location: this.selectedCardLocationName},
        ['{', '}']
      )
    },
    isSelectedCardLocationFailing() {
      return this.isCardLocationFailing()
    }
  },
  created() {
    this.selectedCardProperty = this.selectedCardProperty || this.selectedCardPropertyName
    this.selectedCardField = this.selectedCardField || this.selectedCardFieldName
  },
  methods: {
    setupFailingCardLocationValidations(items) {
      const error = this.$$t(
        `form.fields.${this.selectedCardField}.validationMessages.locationNotFailing`,
        this.failingCardLocationMessage
      )

      const rule = withParams({type: 'locationNotFailing', location: () => this.selectedCardLocationName}, (card) => {
        return !req(card) || !this.isCardLocationFailing(card)
      })

      items.locationNotFailing = {error, rule}
    },
    isCardLocationFailing(cardNumber) {
      if (!this.validateFailingCardLocation) {
        return false
      }
      const card = _.isObject(cardNumber) ? cardNumber :
        (cardNumber && this.getLoadedCard ? this.getLoadedCard(cardNumber) :
          (_.get(this, this.selectedCardProperty,  this.card || this.selectedCard))
        )
      const locationId = card && card.location_id || this.selectedCardLocationId
      if (!locationId) {
        return false
      }
      const locations = this.allLocationDetails || {}
      const location = locations[locationId]
      if (!location) {
        return false
      }
      const services = this.$$s('locations.services', [])
      return location.is_failing || _.some(services, service => location[service] && location[service].is_failing)
    }
  }
}
