<template lang="pug">
  .b-onboarding__right.b-onboarding__right--select-blocks
    .b-onboarding__title What is your primary attraction?
    .b-onboarding__subtitle Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

    onboarding-select-blocks(:blocks="options.attractionBlocks" :selected="onboarding.primary_attraction || ''" @select="select" :single="true")

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    select(selected) {
      this.update({ primary_attraction: selected })
    },
  }
}
</script>
