/*global _ */
import {fv} from '../../../../lib/utils'

import {PARSE_MAP, TITLES, SUBTITLES, MESSAGES, ERROR_MESSAGES, ERROR_TITLES} from './constants'

export const props = {
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', true)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
}

export default {
  props,
  computed: {
    claimedTitle() {
      return this.buildMessage(this.$$t('claim.title', TITLES), {messageType: 'title'})
    },
    claimedSubTitle() {
      return this.buildMessage(this.$$t('claim.subTitle', SUBTITLES), {messageType: 'subTitle'})
    },
    claimedNotice() {
      return this.buildMessage(this.$$t('claim.notice', MESSAGES), {messageType: 'notice'})
    },
    parseMap() {
      const mapTranslations = this.$$t('parseMap', {})
      const mapSettings = this.$$s('parseMap', {})
      return _.merge({}, PARSE_MAP, mapTranslations, mapSettings)
    },
    errorMessageMap() {
      return _.merge({}, ERROR_MESSAGES, this.$$t('promotion.errors', {}))
    },
    errorTitlesMap() {
      return _.merge({}, ERROR_TITLES, this.$$t('promotion.errorTitles', {}))
    }
  },
  methods: {
    buildMessage(template, mergeData = {}) {
      const promotion = (mergeData || {}).promotion || this.promotion || {}
      const type = this.promotionType || _.get(promotion, 'promotion.data.action', 'default')
      if (_.isObject(template) && !_.isArray(template)) {
        template = template[type] || _.head(_.values(template))
      }
      const data = this.getBuilderData(promotion, {...mergeData || {}, type})

      const dataParams = {template, data, vm: () => this}
      this.$$applyDataFilters(`${this.eventSpace}.message.data`, dataParams)

      const messages = _.filter(_.values(
        this.parseReactiveValues(_.castArray(dataParams.template), dataParams.data)
      ))

      const params = {...dataParams, messages}
      this.$$applyDataFilters(`${this.eventSpace}.message`, params)
      const finalMessages = _.filter(_.castArray(params.messages))
      if (finalMessages.length) {
        return '<span>' + finalMessages.join('</span><span>') + '</span>'
      }
      return ''
    },
    getBuilderData(promotion, mergeData = {}) {
      promotion = promotion || this.promotion
      const data = {...(mergeData || {}), ...(promotion || {})}
      data.this = fv(data.this, this)
      data.br = fv(data.br, '</br>')

      const parsedData = _.mapValues(this.parseMap, def => this.parseReactiveValues(def, data))
      return {...data, ...parsedData}
    }
  }
}
