/*global _ */
import {getFirst, fv} from '../../../lib/formatters'
import ReactiveMixin from '../../mashup-builder/reactives'

export default {
  mixins: [ReactiveMixin],
  methods: {
    buildMessages(template, data) {
      data = data || {}
      data.this = fv(data.this, this)
      data.br = fv(data.br, '</br>')
      const messages = _.filter(_.values(this.parseReactiveValues(_.castArray(template), data)))
      return '<span>' + _.castArray(messages).join('</span><span>') + '</span>'
    },
    getComponentAttrs(component, defaults = {}, isElement = false, mergeData) {
      const attrs = {
        ...(defaults || {}),
        ..._.omit(component, ['name', 'attrs', 'parseAttrs', 'on', 'listeners']),
        ...(component.attrs || {})
      }
      let parseAttrs = this.parseDynamicAttrs(component.parseAttrs, mergeData)
      if (!isElement) {
        return {
          ...attrs,
          context: this.context || this.$parent,
          value: this.items,
          ...parseAttrs,
        }
      }
      return {
        ...attrs,
        ...parseAttrs,
      }
    },
    parseSourceData(mergeData) {
      return {
        'this': this,
        '$context': this.context,
        'status': this.loyaltyStatus,
        ...(mergeData || {})
      }
    },
    parseDynamicAttrs(attrs, mergeData) {
      const values = _.mapValues(attrs || {}, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def, mergeData)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.parseSourceData(mergeData)
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    getComponentListeners(component) {
      component = component || {}
      let listeners = component.on || component.listeners || {}
      listeners = _.mapValues(listeners, (listener) => {
        if (_.isObject(listener)) {
          const data = this.parseSourceData()
          const method = this.probeReactiveFunctions(listener, data, true)
          if (method) {
            listener = method
          }
        }
        if (_.isString(listener)) {
          listener = listener.replace(/^@/, '')
          listener = getFirst(listener, this, this.$parent)
        }
        return listener
      })

      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      return listeners
    },
  }
}
