import component from '../../../../../../props/component'
import form from '../../../../../../props/form'
import processing from '../../../../../../props/processing'

const localProps = {
  helpNumberLinkLabel: {
    type: String,
    default(){
      return this.$options.$$t('redemption.helpNumberLinkLabel','What is this?')
    }
  },
  helpCodeLinkLabel: {
    type: String,
    default(){
      return this.$options.$$t('redemption.helpCodeLinkLabel','What is this?')
    }
  },
  forgotCodeLinkLabel: {
    type: String,
    default(){
      return this.$options.$$t('redemption.forgotCodeLinkLabel', 'Forgot Code?')
    }
  },
  modalClasses: {
    type: [String, Array, Object],
    default(){
      return this.$options.$$s('redemption.modalClasses', null)
    }
  },

  showGiftcardRedmptions: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.showGiftcard', false)
    }
  },
  giftcardRedemptionTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.title',
        'Do you have Gift Card?')
    }
  },
  giftcardRedemptionNumberLabel:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.numberLabel',
        'Gift Card Number')
    }
  },
  giftcardRedemptionCodeLabel:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.codeLabel',
        'Code')
    }
  },
  giftcardRedemptionButtonTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.buttonTitle',
        'APPLY')
    }
  },
  giftcardNumberInfoTitle:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.numberInfo.title',
        'Gift Card Number')
    }
  },
  giftcardNumberInfoHeader:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.numberInfo.header', '')
    }
  },
  giftcardNumberInfoFooter: {
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.numberInfo.footer',
        'The Gift Card number can be found in the email that was sent after the purchase was done or under the \'My Gift Cards\' section of your Account.')
    }
  },
  giftcardNumberInfoImgSrc: {
    type: String,
    default(){
      return this.$options.$$s('redemption.giftcard.numberInfo.imgSrc', null)
    }
  },
  giftcardCodeInfoTitle:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.codeInfo.title',
        'CVV or Gift Card Code')
    }
  },
  giftcardCodeInfoHeader:{
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.codeInfo.header', '')
    }
  },
  giftcardCodeInfoFooter: {
    type: String,
    default(){
      return this.$options.$$t('redemption.giftcard.codeInfo.footer',
        'The CVV or Gift Card Code can be found in the email that was sent after the the gift card was claimed by you.')
    }
  },
  giftcardCodeInfoImgSrc: {
    type: String,
    default(){
      return this.$options.$$s('redemption.giftcard.codeInfo.imgSrc', null)
    }
  },
  hasHelpGiftcardNumberLink: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.giftcard.showHelpNumberLink', true)
    }
  },
  hasGiftcardForgotCodeLink: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.giftcard.showForgotCodeLink', true)
    }
  },

  showPlaycardRedmptions: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.showPlaycard', false)
    }
  },
  playcardRedemptionTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.title',
        'Do you have Play Card?')
    }
  },
  playcardRedemptionNumberLabel:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.numberLabel',
        'Play Card Number')
    }
  },
  playcardRedemptionCodeLabel:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.codeLabel',
        'Code')
    }
  },
  playcardRedemptionButtonTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.buttonTitle',
        'APPLY')
    }
  },
  playcardNumberInfoTitle:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.numberInfo.title',
        'Play Card Numbe')
    }
  },
  playcardNumberInfoHeader:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.numberInfo.header', '')
    }
  },
  playcardNumberInfoFooter: {
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.numberInfo.footer',
        'The Play Card number can be found in the email that was sent after the purchase was done or under the \'My Play Cards\' section of your Account.')
    }
  },
  playcardNumberInfoImgSrc: {
    type: String,
    default(){
      return this.$options.$$s('redemption.playcard.numberInfo.imgSrc', null)
    }
  },
  playcardCodeInfoTitle:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.codeInfo.title',
        'CVV or Play Card Code')
    }
  },
  playcardCodeInfoHeader:{
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.codeInfo.header', '')
    }
  },
  playcardCodeInfoFooter: {
    type: String,
    default(){
      return this.$options.$$t('redemption.playcard.codeInfo.footer',
        'The CVV or Play Card Code can be found in the email that was sent after the the play card was claimed by you.')
    }
  },
  playcardCodeInfoImgSrc: {
    type: String,
    default(){
      return this.$options.$$s('redemption.playcard.codeInfo.imgSrc', null)
    }
  },
  hasHelpPlaycardNumberLink: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.playcard.showHelpNumberLink', true)
    }
  },
  hasPlaycardForgotCodeLink: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.playcard.showForgotCodeLink', true)
    }
  },

  showCouponRedmptions: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.showCoupon', false)
    }
  },
  couponRedemptionTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.coupon.title',
        'Do you have Coupon?')
    }
  },
  couponRedemptionInputLabel:{
    type: String,
    default(){
      return this.$options.$$t('redemption.coupon.inputLabel',
        'Coupon')
    }
  },
  couponRedemptionButtonTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.coupon.buttonTitle',
        'Apply')
    }
  },

  resendModalTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.resendModal.title', null)
    }
  },
  resendModalClasses: {
    type: [String, Array, Object],
    default(){
      return this.$options.$$s('redemption.resendModal.class', null)
    }
  },
  resendModalHeader: {
    type: String,
    default(){
      return this.$options.$$t('redemption.resendModal.header',
        'Resend your code using the email address associated with your Card <b>OR</b> your Card Number.')
    }
  },
  resendModalButtonTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.resendModal.buttonTitle',
        'Recover')
    }
  },
  resendModalButtonClasses: {
    type: String,
    default(){
      return this.$options.$$s('redemption.resendModal.buttonClass',
        '')
    }
  },
  resendModalErrorMessage: {
    type: String,
    default: null
  },
  resendModalRecoveredEmail: {
    type: String,
    default: null
  },
  resendModalSuccessMessage: {
    type: String,
    default(){
      return this.$options.$$t('redemption.resendModal.successMessage',
        'The Card code has been sent to the following email address')
    }
  },
  resendModalSuccess: {
    type: Boolean,
    default: false,
  },
  resendModalResendMessage: {
    type: String,
    default(){
      return this.$options.$$t('redemption.resendModal.resendMessage',
        'The Card code has been resent to the following email address')
    }
  },
  hideForm: {
    type: [Boolean, Number, String],
    default(){
      return !!this.$options.$$s('redemption.hideForm', false)
    }
  },
  showFormButtonTitle: {
    type: [String],
    default(){
      return this.$options.$$t('redemption.showForm.button.title', 'Enter coupon code')
    }
  },
  showFormButtonTitleAfterApply: {
    type: [String],
    default(){
      return this.$options.$$t('redemption.showForm.appliedButton.title', 'Add')
    }
  },
  showFormButtonAttrs: {
    type: [Object],
    default(){
      return this.$options.$$s('redemption.showForm.button.title', {})
    }
  },
  showFormButtonClasses: {
    type: [String, Object, Boolean, Array],
    default(){
      return this.$options.$$s('redemption.showForm.button.classes', {})
    }
  },
  couponRedemptionAppliedTitle: {
    type: String,
    default(){
      return this.$options.$$t('redemption.appliedCoupon.title',
        'Your coupon code has been applied.')
    }
  },

}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
