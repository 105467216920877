/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'

const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

import VueTheMask from 'vue-the-mask'

export default {
  name: 're-mask-input',
  components: {VueTheMask},
  mixins,
  props: {
    ...props,
    mask: {
      type: [String, Array],
      required: true
    }
  },
  data() {
    return {
      optionsPropName: OPTIONS_PROP
    }
  },
  computed: {
    maskClass() {
      return this.inputClass + ' ' + (this.inputErrorClass || '')
    }
  },
  methods: {
    getLabelExtraClasses() {
      return ['re-input-label-mask']
    },
  },
}
