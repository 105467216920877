import addiesaas, {Vue} from '../../addiesaas'
const {$fn: {findOrCreate}} = addiesaas

export const EventBus = window.VueEventBus || new Vue()
findOrCreate(window, 'VueEventBus', EventBus, ({item, found}) => {
  if (!found) {
    Vue.prototype.$$emit = (...args) => window.VueEventBus.$emit(...args)
    Vue.prototype.$$on = (...args) => window.VueEventBus.$on(...args)
    Vue.prototype.$$once = (...args) => window.VueEventBus.$once(...args)
    Vue.prototype.$$off = (...args) => window.VueEventBus.$off(...args)
    Vue.prototype.$$gemitAsync = async (...args) => await window.VueEventBus.$$emitAsync(...args)
  }
})

findOrCreate('$core.EventBus', window.VueEventBus)
findOrCreate('$fn.createEventBus', (bus) => bus instanceof Vue ? bus: new Vue())

export default EventBus
