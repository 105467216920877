export const name = 're-profile-giftcards'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import ProfileMixin from '../../../../mixins/profile'
import ProfileCardsMixin from '../../../../mixins/profile/cards'
import GiftcardListMixin from '../../../../mixins/external/giftcard-list'

export const mixins = [ComponentMixin, ProfileMixin, ProfileCardsMixin, GiftcardListMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  cardType: 'giftcard',
  reloadType: 'giftcard_reload',
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
