/* global _ */
import {
  name,
  mixins,
  props,
  data,
} from './config'

import {getLocationNameSync, getLocationsSync} from '../../../../../../lib/formatters'
import {setTimeoutAsync} from '../../../../../../lib/utils'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      selectedLocationProperty: 'selectedLoyaltyLocationId'
    }
  },
  computed: {
    canShowHeaderButtons() {
      return !this.isPromising && (this.showLocationSelector || this.showHistoryButton)
    },
    leftStatusAttrs() {
      const component = _.merge(
        {},
        this.$options.$$t('status.left', {}),
        this.$options.$$s('status.left', {})
      )
      const defaults = {
        showProgressBar: false,
        showValues: false,
        showTitle: true,
        showShortMessage: false,
        showLongMessage: true,
        title: 'Unlock Your Next Reward',
        longMessageSuffix: '.',
      }

      const attrs = this.getComponentAttrs(component, defaults)
      const classes = this.parseAttrClasses(attrs)
      return {
        ...attrs,
        class: classes
      }
    },
    rightStatusAttrs() {
      const component = _.merge(
        {},
        this.$options.$$t('status.right', {}),
        this.$options.$$s('status.right', {})
      )
      const defaults = {
        title: 'Total Reward progress',
        showTitle: true,
        showMessages: false,
      }

      const attrs = this.getComponentAttrs(component, defaults)
      const classes = this.parseAttrClasses(attrs)
      return {
        ...attrs,
        class: classes
      }
    },
    rewardsTableTitle() {
      return this.parseReactiveValues(this.rewardsTitle, this.parseSourceData())
    },
    rewardsInfo() {
      const messages = []
      if (this.hasUnlockedTierRewards) {
        messages.push(this.unlockedRewardsMessage)
        const hasRemaining = this.lockedTierRewards.length > 0
        messages.push(
          hasRemaining ?
            this.unlockedRemainingRewardsMessageSuffix:
            this.unlockedNoRemainingRewardsMessageSuffix
        )
      }
      return messages.join('')
    },
    topReward() {
      if (this.useTierRanks) {
        return _.last(_.find(this.rewardRankedItems, (g, index) => index && !_.isEmpty(g)))
      }
      if (this.rewardOrder && this.rewardOrder === 'asc') {
        return _.tail(this.rewardItems)
      }
      return _.head(this.rewardItems)
    },
    rewardItems() {
      // rewards in ascending order
      let items = [...this.loyaltyTierRewards || []]

      if (this.rewardOrder) {
        if (this.rewardOrder === 'desc') {
          items = items.reverse()
        }
      } else {
        //  put mega/top reward at top
        if (items.length) {
          items.unshift(items.pop())
        }
      }
      return items
    },
    rewardRankedItems() {
      const groups = []
      _.forOwn(this.loyaltyTierRewards, item => {
        let items = groups[item.tierRank]
        if (!items) {
          items = groups[item.tierRank] = []
        }
        items.push(item)
      })
      return groups
    },
    showingHistory() {
      return _.get(this.storedProfileData, 'states.loyalty.showingHistory')
    },
    historyButtonAttrs() {
      return {
        title: this.historyButtonTitle,
        class: this.historyButtonClasses
      }
    },
    locationButtonAttrs() {
      const attrs = this.locationButtonAttr || {}
      if (_.isEmpty(attrs.hydrate) && _.isEmpty(attrs.options)) {
        //attrs.options = getLocationsSync()
        attrs.hydrate = {method: this.getUserLoyaltyCardsLocations}
      }
      return attrs

      //// default for re-input-button type
      // return {
      //   initMode: 'view',
      //   showView: true,
      //   viewComponent: 're-text',
      //   viewFormat: 'getLocationNameSync',
      //   noApplyButton: true,
      //   editButtonAttr: {
      //     class: this.locationButtonClasses,
      //     title: this.locationButtonTitle,
      //   },
      //   inputComponent: 're-select',
      //   inputAttr: {
      //     options: getLocationsSync()
      //   },
      //   ...this.locationButtonAttr
      // }
    },
    hasNoLockedReward() {
      return !this.lockedTierRewards.length
    },
    rewardsClasses() {
      return {
        [`rewards-order-${this.rewardOrder}`]: this.rewardOrder
      }
    }
  },
  methods: {
    async mayInitStandaloneClaim(reward, $event, vm) {
      if (!this.lockedTierRewards.length) {
        await this.initStandaloneClaim(reward, $event, vm)
      } else {
        await setTimeoutAsync(250)
        await this.initClaim(_.castArray(reward), {skipIgnored: true})
      }
    },
    showHistory() {
      this.emitSlim('show-history', this)
    },
    getComponentExtraClasses() {
      return {
        'use-tier-ranks': this.useTierRanks,
        'is-location-failing': this.isSelectedLocationFailing
      }
    }
  }
}
