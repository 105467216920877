/*global _ */
import component from '../../../../props/component'

import {props as messageProps} from './messages'
// take from ./messages.js mixin
const componentProps = _.omit(component, _.keys(messageProps))

const localProps = {
  promotedUserId: {},
  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  showSubmitButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.submit.title', false)
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.submit.title', 'Go to Account')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.submit.classes', 'btn re-submit-button my-account-button')
    }
  },
  submitButtonComponent: {
    default() {
      return this.$options.$$s('buttons.submit.component.name', 're-link')
    }
  },
  submitButtonAttrs: {
    default() {
      const $t = this.$options.$$t(`buttons.submit.component.attrs`, {
        to: {name: 'user.profile'},
        asHref: true,
        noDefaultClass: true,

      })
      const $s = this.$options.$$s(`buttons.submit.component.attrs`, {})
      return _.merge({}, $t, $s)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Processing your reward.')
    }
  },
  missingIdMessage: {
    type: String,
    default() {
      return this.$options.$$t('missingIdMessage', 'Reward details are missing.')
    }
  },
  showErrorTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showErrorTitle', true)
    }
  },
  // partial errors:
  //  Sometimes promotion can have multiple items in rewards
  //  one or more can fail. An error message is shown along with the success message
  //  this message can be shown at the top (above the success title or at the bottom of the success box)
  showPartialError: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPartialError', true)
    }
  },
  showPartialErrorOnTop: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPartialErrorOnTop', true)
    }
  }
}

export const props = {
  ...componentProps,
  ...localProps
}
export default props
