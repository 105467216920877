import MainService from '../../../../services/main'
const EventBus = window.VueEventBus

export default {
  name: 're-verify-account-error',
  props: {
    email: {
      type: String,
      required: true
    },
    showHomeLink: {
      type: Boolean,
      default: false
    },
    homeLinkLabel: {
      type: String,
      default: 'Go back to site'
    },
    resetTitle: {
      type: String,
      default: 'Registration not verified!'
    },
    resetButtonTitle: {
      type: String,
      default: 'Resend again'
    },
    resetButtonClass: {
      type: [String, Array, Object],
      default: 're-resend-button'
    },
    resetAgainButtonTitle: {
      type: String,
      default: 'Resend one more time'
    },
    resetAgainButtonClass: {
      type: [String, Array, Object],
      default: 're-resend-again-button'
    },
    successTitle: {
      type: String,
      default: 'SUCCESS'
    },
    repeatedSuccessMessage: {
      type: String,
      default: 'The registration confirmation has been re-sent.'
    },
    successMessage: {
      type: String,
      default: 'The registration confirmation has been re-sent to the following email address '
    }
  },
  data() {
    return {
      sendingRequest: false,
      requestSentError: null,
      resendCount: 0,
    }
  },
  mounted() {
    this.resendCount = 0
    this.requestSentError = null
  },
  computed: {
    showErrorMessage() {
      return !_.isEmpty(this.requestSentError)
    }
  },
  methods: {
    async resendConfirmation() {
      this.requestSentError = null
      const email = this.email
      this.sendingRequest = true
      EventBus.$emit('register-resend.before', email)
      try {
        const data = await MainService.sendConfirmation({email})
        this.resendCount++
        EventBus.$emit('register-resend.success', data)
      } catch (e) {
        this.requestSentError = MainService.getFirstErrorMessage(e)
        EventBus.$emit('register-resend.error', e)
      }
      this.sendingRequest = false
    },
  }
}
