/* global _ */
import {fv} from '../../../../lib/utils'
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  events: 'user.profile.orders',
  props,
  mixins,
  data() {
    return {
      ...data,
      sortState: 'desc',
      customView: null,
      lazyPaginationData: {
        total_pages: null,
        total: null
      }
    }
  },
  computed: {
    contentClasses() {
      const view = this.customView || this.tableView
      return [
        `view-${view}`
      ]
    },
    tableView() {
      return this.isListViewNotPossible ? 'grid' : this.view
    },
    isGrid() {
      return this.tableView == 'grid'
    },
    isListViewNotPossible() {
      return this.appWidth <= this.listViewMinWidth
    },
    sortedAsc() {
      return this.sortState === 'asc'
    },
    sortedDesc() {
      return this.sortState === 'desc'
    },
    ordersCount() {
      return fv(this.lazyPaginationData.total, _.size(this.allOrders || {}))
    },
    hasVisibleOrder() {
      return !!this.filteredOrders.length
    },
    filteredOrders() {
      if (this.paginateLazy) {
        return this.allOrders
      }
      const orderFactor = this.sortedAsc ? 1 : -1
      const sorted = this.allOrders.sort((a, b) =>
        orderFactor * (a.created_at.replace(/\D/g, '') - b.created_at.replace(/\D/g, ''))
      )
      const start = this.pageIndex * this.pageSize
      const end = start + this.pageSize
      const visible = _.slice(sorted, start, end)
      return visible
    },
    allOrders() {
      let orders = this.User.orders || []
      let skipStates = this.skipStates
      if (!this.paginateLazy && skipStates) {
        skipStates = _.castArray(skipStates)
        orders = orders.filter(order =>  {
          return !skipStates.includes(order.state)
        })
      }
      return orders
    },
    noOrderMessage() {
      let message = this.$$t('noOrder.message', 'You have not ordered anything yet.')
      const filter = this.filter
      if (this.showStatusFilters && filter) {
        const filterOptions = _.get(this.filterComponentAttrs, 'options')
        const filterItem = _.find(filterOptions, f => f.value === filter)
        if (filterItem) {
          message = filterItem.noDataError || message
        }
      }
      return message
    }
  },
  watch: {
    async pageIndex(value) {
      if (this.paginateLazy) {
        this.isBeingProcessed = true
        await this.fetchData((value || 0) + 1)
        this.isBeingProcessed = false
      }
    }
  },
  async created() {
    await this.fetch()
  },

  methods: {
    async fetch(autoloadAll, resetPage) {
      this.emit('fetch.init')
      try {
        let orders
        if (this.paginateLazy) {
          this.isBeingProcessed = true
          orders = this.orders = await this.fetchData(resetPage ? 1 : null) || []
        } else {
          this.isBeingProcessed = !this.hasVisibleOrder
          if (autoloadAll) {
            await this.autoLoadAll()
          }
          await this.initialAutoLoadCompleted()
          orders = this.orders = await this.autoLoadOrders() || []
        }
        this.$emit('success', orders)
        this.emit('fetch.success', orders)
      } catch (e) {
        this.$emit('error', e)
        this.emit('fetch.error', e)
      } finally {
        this.emit('fetch.end')
        this.isBeingProcessed = false
      }
    },
    async fetchData(page) {
      if (page) {
        this.pageIndex = page - 1
      }
      const page_size = this.pageSize
      page = page || (this.pageIndex + 1)
      const filter = this.filter
      const payload = {page_size, page}
      if (filter) {
        payload.where = {state: filter.split(',')}
      }
      const {data, meta} = await this.invokeService('user.orders.get', payload)
      const {pagination} = meta
      this.lazyPaginationData.total = pagination.total
      this.lazyPaginationData.total_pages = pagination.total_pages
      _.set(this.User, 'orders', data)
      this.updateProfileDataItem({'states.orders': data})
      return data
    },
    sortDesc() {
      this.sortState = 'desc'
    },
    sortAsc() {
      this.sortState = 'asc'
    },
    getCardDetails(orderItem) {
      const transaction = _.get(orderItem, 'transaction.data')
      if (transaction) {
        const ccType = transaction.cc_type || ''
        const ccNumber = transaction.cc_number || ''
        const ccLast4 = ccNumber.slice(-4)
        const data = {ccType, ccNumber, ccLast4}
        this.$$applyDataFilters(this.eventSpace + '.getCardDetails', data)
        return [ccType, ccLast4].join('*')
      }
      return ''
    },
    viewChanged(view) {
      this.customView = view
    }
  }
}
