
const EventBus = window.VueEventBus

export default {
    async emit(event, ...args) {
        const listeners = []
        EventBus.$emit(event, listeners)
        for (const listener of listeners) {
            const result = listener(...args)
            if (result instanceof Promise) {
                await result
            }
        }
    }
}
