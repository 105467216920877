export const name = 're-registration'

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
import props from './props'

export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'
import ResendDelayMixin from '../../../../mixins/features/resend-delay'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'
import SocialMixin from '../../../../mixins/profile/social/auth'
export const mixins = [ComponentMixin, FormMixin, FieldsMixin, SocialMixin, FormForceUpdateMixin, ResendDelayMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  isRegistering: false,
  isRegistered: false,
  termsUrl: null,
  racerNameTouched: false,
  isResendingConfirmation: false,
  resendingConfirmationCount: 0,
  registered: {
    email: null
  },
  pass: null,
  showLoginProposal: false,
  fieldCustomErrorsCache: {}
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
