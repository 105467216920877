/*global addiesaas, _ */
import User from '../classes/user/User'
import ProfileBaseMixin from './profile/base'
import ProfileAutoloadMixin from './profile/data/autoload'

const STORE = '$data.profile.user'

export default {
  mixins: [ProfileBaseMixin, ProfileAutoloadMixin],
  data() {
    return {
      // a REACTIVE object acting as proxy for User object stored
      // in `STORE` which is stored in the global
      // space `addiesaas.$data.profile.user`
      // Vuex limitation:
      //  1. endless recursion if a property is a vue instance
      //  2. mutation outside (inside User object) is not allowed
      proxyUser: {},

      superStoreName: 'user.profile',
      superStoreData: {
        autoLoad: {
          queue: {
            billing: false,
            cards: false,
            edit: false,
            family: false,
            giftcards: false,
            loyalty: false,
            orders: false,
            playcards: false,
            promotions: false,
            subscriptions: false
          }
        }
      },
      autoLoadQueue: {}
    }
  },
  computed: {
    isAutoLoadEnabled() {
      return _.get(this.storedProfileData, 'autoload')
    },
    isInitialAutoloaded() {
      return _.get(this.storedProfileData, 'initialAutoloaded', false)
    },
    isAutoLoadingProfile() {
      return _.get(this.storedProfileData, 'loading.profile')
    },
    User: {
      get() {
        const user = addiesaas.$fn.findOrCreate(STORE, {})
        this.proxyUser = user
        return this.proxyUser
      },
      set(value) {
        this.proxyUser = value
        _.set(addiesaas, STORE, value)
      }
    }
  },
  watch: {
    userDataMeta: {
      handler(value) {
        if (_.isEmpty(value)) {
          this.User = {}
        } else {
          const user = this.User
          if (user instanceof User) {
            user.setData(value)
          } else {
            this.User = new User(value, this)
          }
        }
      },
      immediate: true
    }
  },
  methods: {}
}

