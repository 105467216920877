export const name = 're-cart-final'

// generic setup
import {allValidationsRules, defaultFormFields as formFields} from './form'
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../mixins/components/complex'
import FormMixin from '../../../../../mixins/form'
import CartMixin from '../../../../../mixins/cart'
import DesignerMixin from './designer'
export const mixins = [ComponentMixin, FormMixin, CartMixin, DesignerMixin]

export const EventBus = window.VueEventBus

// data
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus,
}
