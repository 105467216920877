/*global _ */
import PageComponentMixin from '../mixins/components/page'
import {spaces} from './config'

import propDefs from '../props/page'

export const space = name => {
  let space = spaces[name]
  if (_.isString(space)) {
    space = {
      type: 'page',
      key: space
    }
  }
  return space
}

export const factory = (name, options = {}) => {
  const config = space(name) || {}
  const key = config.key
  const {
    translations = key,
    settings = key,
    events = key,
    components: defaultComponents = options.components || {},
    component: defaultComponent = options.component
  } = config

  const mixins = [PageComponentMixin, ...options.mixins || []]
  const props = {
    ...propDefs,
    translations: {default: translations},
    settings: {default: settings},
    ...options.props || {}
  }

  const data = () => ({
    propDefs,
    omitMergeAttrProps: ['id', 'class', 'style'],
    eventSpace: events,
    defaultComponent,
    defaultComponents,
    ...options.data || {}
  })

  return {
    name,
    mixins,
    props,
    data,
    translations,
    settings,
    events,
    ...options.def || {}
  }
}

export default {spaces, space, factory}

