import Validations from '../../lib/validations'

import formatters, {valueFormat, formatPath, get} from '../../lib/formatters'

export default {
  props: {
    context: {},
  },
  data() {
    return {
      formatters,
      formatPath,
      valueFormat,
      Validations
    }
  },
  mixins: [],

  computed: {
    builderData() {
      return {...this.fields, ..._.pick(this.builderStructures, ['$inject'])}
    },

    title() {
      const data = this.builderStructures
      return get(data, 'options.title', '')
    },
    showTitle() {
      const data = this.builderStructures
      return get(data, 'options.showTitle', false)
    },
    subTitle() {
      const data = this.builderStructures
      return get(data, 'options.subTitle', '')
    },
    showSubTitle() {
      const data = this.builderStructures
      return get(data, 'options.showSubTitle', false)
    },
    notice() {
      const data = this.builderStructures
      return get(data, 'options.notice', '')
    },
    showNotice() {
      const data = this.builderStructures
      return get(data, 'options.showNotice', false)
    },
    submitButtonTitle() {
      const data = this.builderStructures
      return get(data, 'form.buttons.submit.title',
          get(data, 'options.buttons.finalize.title',
          'Add to cart'
        ))
    },
    submitButtonClasses() {
      const data = this.builderStructures
      return get(
        data, 'form.buttons.submit.classes',
        get(data, 'form.buttons.submit.class',
          get(data, 'options.buttons.finalize.classes',
            get(data, 'options.buttons.finalize.class')
          )
        )
      )
    },
    prevPageButtonTitle() {
      const data = this.builderStructures
      return get(
        data, 'form.buttons.prev.title',
          get(data, 'options.buttons.prev.title', 'Back')
      )
    },
    prevPageButtonClasses() {
      const data = this.builderStructures
      return get(
        data, 'form.buttons.prev.classes',
        get(data, 'form.buttons.prev.class',
          get(data, 'options.buttons.prev.classes',
            get(data, 'options.buttons.prev.class')
          )
        )
      )
    },
    nextPageButtonTitle() {
      const data = this.builderStructures
      return get(
        data, 'form.buttons.prev.title',
          get(data, 'options.buttons.next.title', 'Next')
      )
    },
    nextPageButtonClasses() {
      const data = this.builderStructures
      return get(
        data, 'form.buttons.next.classes',
        get(data, 'form.buttons.next.class',
          get(data, 'options.buttons.next.classes',
            get(data, 'options.buttons.next.class')
          )
        )
      )
    },
    validateFormFieldOnDirty() {
      const data = this.builderStructures
      return get(
        data, 'form.options.validateFormFieldOnDirty',
          get(data, 'options.form.validateFormFieldOnDirty', false)
      )
    },

  },

  created() {

  },
  methods: {
    getBuilderData() {
      return {this: this, fields: this.fields, ...this.fields, ..._.pick(this.builderStructures, ['$inject'])}
    },
    getBuilderItemAttr(item, type) {
      const dataItems = {key: ['data-key'], namespace: ['data-namespace', 'ref']}
      const attr = {}
      _.forOwn(dataItems, (i, k) => {
        if (item[k]) {
          _.forOwn(i, t => {
            if (!/^[[$@]/.test(t)) {
              attr[t] = item[k]
            }
          })
        }
      })
      return attr
    },
    getBuilderValidationAttr(item, key) {
      if (_.isArray(item)) {
        key = item[0] || key
        item = item[1] || true
      }
      let source = _.get(this.builderStructures, `form.${key}`)
      if (source) {
        source = _.pick(source || {}, Validations.propKeys)
      }
      if (item === true) {
        return {[key]: source}
      }
      if (!_.isObject(item)) {
        return {[key]: {}}
      }
      return {[key]: item}
    },
    getBuilderItemClasses(item, type) {
      const classes = []
      const key = item.key || item.name
      if (type && key) {
        classes.push(_.kebabCase(`${type}_${key}`))
      }
      if (item.class) {
        classes.push(item.class)
      }
      if (item.required) {
        classes.push('required')
      }
      if (item.disabled || (item.disableOnPreviousInvalid && item.isPrevInvalid && item.isPrevInvalid())) {
        classes.push('disabled')
      }
      return classes
    },
    canShowBuilderItem(item, type) {
      if (!item) {
        return false
      }
      let show = (item || {}).show
      if (!_.isFunction(show) && _.isObject(show)) {
        show = this.probeReactiveFunctions(item, this.getBuilderData, true)
      }
      if (_.isFunction(show)) {
        show = show(item, this.getBuilderData)
      }
      if (show !== false) {
        if (type === 'page') {
          show = item.key === this.pageKey
        }
      }
      return show !== false
    }
  }
}
