import axios from 'axios'
import store from '~/vuestrap/vuexstore'

axios.interceptors.request.use(request => {
  if (store.getters && store.getters.authToken) {
    request.headers.common['X-Authorization'] = `Bearer ${store.getters.authToken}`
  }
  return request
})

// customAxios.interceptors.response.use(response => response, error => {
//   const {status} = error.response

//   if (status === 401 && store.getters && store.getters.authCheck) {
//     store.dispatch('logout').then(() => router.push({name: 'sign-in'}));
//   }

//   return Promise.reject(error)
// })
