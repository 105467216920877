/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-radio',
  mixins,
  props: {
    ...props,
    value: {
      type:[String, Boolean, Number, Array, Object],
      default: false
    },
    radioValue: {
      type: [String, Number, Boolean],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'form-control-radio'
    },
    multi: {
      type: [Boolean, Number, String],
      default: false
    },
  },
  data() {
    return {
      optionsPropName: OPTIONS_PROP,
    }
  },
  computed: {
    radioId() {
      return this.id || _.uniqueId('radio_' + _.snakeCase((this.name || '') + '_' + (this.radioValue || '')))
    },
    isChecked() {
      return this.value == this.radioValue
    }
  },
  methods: {
    labelClicked(e) {
      // fix for builder where builder prevents default event of the input and it's label
    },
    onInput(event) {
      if (this.isDesignerMode) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.isDirtyState = true
      const value = _.get(event, 'target.value', event)
      const checked = value == this.radioValue
      if (checked) {
        this.model =  _.get(event, 'target.value', event)
      } else {
        this.model = ""
      }
    },
    getLabelAttrs() {
      if (!this.designerMode) {
        return {}
      }
      return {
        ...(this.multi ? {
          'data-native-element': 'Label'
        } : {
          'data-prop-element': 'Label'
        })
      }
    },
    getLabelExtraClasses() {
      return [`re-input-label-radio`]
    },
  }
}
