/*global _ */
import {toBoolean, nil, fv} from '../lib/formatters'
import {getErrorMessage} from '../services/main'
import {parseValidationsFromString, parseFieldsValidationDef} from '../lib/validations/utils'

import allValidators, {
  parseValidations,
  parseValidationSetup,
  setValidationRule,
  ValidationMixins,
  Validators
} from '../lib/validations'

const mixins = [ValidationMixins]

export default {
  mixins,
  data() {
    return {
      formErrorMessage: null,
      fieldCustomErrors: {},
      formSetup: {},
      fields: {},
      validations: {},
      setValidationRules: () => ({})
    }
  },
  validations() {
    if (this.setValidationRules) {
      return this.setValidationRules()
    }
    return {}
  },
  created() {
    if (this.validateFormFieldOnDirty) {
      this.formSubmitting = true
    }
  },
  methods: {
    getFormAttrs() {
      const attrs = _.pick(this.$attrs || {}, ['action', 'enctype', 'method', 'novalidate', 'target', 'rel', 'name', 'accept-charset'])
      let autocomplete = toBoolean(this.autocomplete) === false ? 'off' : 'on'
      attrs.autocomplete = autocomplete
      return attrs
    },
    getFieldDefinition(fieldName, fieldsPath = 'fields') {
      if (_.isString(fieldName)) {
        const pathPrefix = ('form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName)
        return _.merge({}, this.$$t(pathPrefix), this.$$s(pathPrefix))
      }
      return fieldName
    },
    getFieldProps(name, fieldsPath = 'fields', options) {
      if (_.isObject(fieldsPath) && !options) {
        options = fieldsPath
        fieldsPath = 'fields'
      }
      options = options || {}
      const {
        fieldsPath: optionFieldsPath = null,
        defaults = {},
        force = {},
        skip = {},
        keep = {},
        value = undefined,
        type = undefined,
      } = options
      if (optionFieldsPath) {
        fieldsPath = optionFieldsPath
      }
      const fieldFullPath = (fieldsPath ? `${fieldsPath}.` : '') + name
      let props = {
        name,
        ...defaults,
        ...this.getFieldAttr(name, fieldsPath, options.attr || {}),
        validations: this.validations[name],
        label: this.getFieldLabel(name, defaults.label, fieldsPath),
        placeholder: this.getFieldPlaceholder(name, defaults.placeholder, fieldsPath),
        hint: this.getFieldHint(name, defaults.hint, fieldsPath),
        tabindex: this.getFieldOrder(name, fieldsPath, defaults.tabindex),
        customErrors: this.fieldCustomErrors[name],
        v: _.get(this, `$v.${fieldFullPath}`),
        getV: () => _.get(this, `$v.${fieldFullPath}`),
        vRoot: this.$v,
        getVRoot: () => this.$v
      }
      if (!props.type && !_.isUndefined(type)) {
        props.type = type
      }

      props = _.merge({}, props, force || {})

      if (!_.isEmpty(keep)) {
        props = _.pick(props, keep)
      } else if (!_.isEmpty(skip)) {
        props = _.omit(props, skip)
      }
      return props
    },
    getFieldContainerClasses(fieldName, validations, fieldsPath = 'fields') {
      const name = fieldName
      let field = this.getFieldDefinition(fieldName, fieldsPath)
      fieldName = fieldName.name || (_.isString(name) ? fieldName : null)

      const settingClass = field.class || field.classes || ''
      const generic = 're-input-' + _.kebabCase(fieldName)
      return _.filter([
        generic,
        settingClass,
        ...this.getFieldContainerRequiredClasses(fieldName, validations),
        ...this.getFieldContainerExtraClasses(fieldName, fieldsPath, field, validations)
      ])
    },
    getFieldContainerRequiredClasses(fieldName, validations) {
      validations = validations || this.validations
      return [{required: _.has(validations, `${fieldName}.items.required`)}]
    },
    getFieldContainerExtraClasses(fieldName, fieldsPath, field, validations) {
      return []
    },

    isField(fieldName, defaultValue = '', fieldsPath = 'fields') {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      return this.$$s(`${pathPrefix}.show`, this.$$s(pathPrefix, defaultValue))
    },
    getFieldValue(fieldName, fieldsPath = 'fields', defaultValue = null) {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      return this.$$s(`${pathPrefix}.value`, defaultValue)
    },
    getFieldModelValue(fieldName, fieldsPath = 'fields', defaultValue = null) {
      const pathPrefix = (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      return _.get(this.$v, `${pathPrefix}.$model`, _.get(this, pathPrefix, defaultValue))
    },

    getFieldLabel(fieldName, defaultValue = '', fieldsPath = 'fields', forceShow) {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const canShow = forceShow || fv(
        this.$$s(`${pathPrefix}.showLabel`, null),
        this.$$s(`${pathPrefix}.label`, null), // @deprecated: use showLabel instead
        this.showFormLabels !== false
      )
      if (canShow) {
        return this.$$t(`${pathPrefix}.label`, defaultValue)
      }
      return ''
    },
    getFieldPlaceholder(fieldName, defaultValue = '', fieldsPath = 'fields') {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const canShow = fv(
        this.$$s(`${pathPrefix}.showPlaceholder`, null),
        this.$$s(`${pathPrefix}.placeholder`, null), // @deprecated: use showPlaceholder instead
        this.showFormPlaceholders
      )
      if (canShow) {
        return this.$$t(`${pathPrefix}.placeholder`, defaultValue)
      }
      return ''
    },
    getFieldHint(fieldName, defaultValue = null, fieldsPath = 'fields') {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const canShow = fv(
        this.$$s(`${pathPrefix}.showHint`, null),
        this.$$s(`${pathPrefix}.hint`, null), // @deprecated: use showHint instead
        this.showFormFieldHint !== false
      )
      if (canShow) {
        return this.$$t(`${pathPrefix}.hint`, defaultValue)
      }
      return null
    },
    getFieldOrder(fieldName, fieldsPath = 'fields', defaultValue = null) {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      return this.$$s(`${pathPrefix}.order`, defaultValue)
    },
    getMaxFieldOrder(fieldsPath = 'fields', defaultValue = null) {
      const fields =  this.$$s('form' +  (fieldsPath ? `.${fieldsPath}` : ''), {})
      return _.max(_.map(fields, 'order'))
    },
    getFieldAttr(fieldName, fieldsPath = 'fields', defaultValue = {}) {
      const pathPrefix = 'form.' +  (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const settings = this.$$s(`${pathPrefix}.attr`) || {}
      const language = this.$$t(`${pathPrefix}.attr`) || {}
      const attrs = _.pick(this.$attrs || {}, ['name', 'id'])
      return _.merge({}, defaultValue, settings, language, attrs)
    },
    resetFieldsValidation(fieldsPath = 'fields') {
      const reset = _.get(this.$v, `${fieldsPath}.$reset`, _.get(this, `${fieldsPath}.$v.$reset`, (() => {})))
      reset()
      this.fieldCustomErrors = {}
      this.formErrorMessage = ''
    },
    resetFieldValidation(fieldName, fieldsPath = 'fields') {
      const pathPrefix = (fieldsPath ? `${fieldsPath}.` : '') + fieldName
      const reset  = _.get(this.$v, pathPrefix + '.$reset', (() => {}))
      this.setCustomError(fieldName, [])
      reset()
    },
    setCustomError(fieldName, error) {
      if (_.isString(error)) {
        error = {message: error}
      }
      this.$emit('field-custom-error', fieldName, error)
      this.$set(this.fieldCustomErrors, fieldName, error)
    },
    showFormError(e, options) {
      options = options || {}
      if (this.isSingleErrorLayout) {
        this.showingSingleErrorLayout = true
      }
      let {scrollTo = null, flattenTopMessages = true} = options
      const all = e.all
      if (_.isEmpty(all)) {
        const exception = e.$$e || e
        const errorMessage = getErrorMessage(exception)
        this.showingSingleErrorLayout = true
        this.formErrorMessage = [errorMessage]
      } else {
        if (this.showingSingleErrorLayout) {
          this.formErrorMessage = _.flatten(_.values(all))
        } else {
          _.forOwn(all, (message, key) => {
            this.setCustomError(key, {message})
          })
          const fields = {...this.fields, ...(options.fields || {})}
          const nonFields = _.pickBy(all, (error, key) => !_.has(fields, key))
          if (!_.isEmpty(nonFields)) {
            this.showingSingleErrorLayout = true
            this.formErrorMessage = _.flatten(_.values(nonFields))
          }
        }
      }
      if (this.formErrorMessage) {
        if (flattenTopMessages && _.isArray(this.formErrorMessage)) {
          this.formErrorMessage = this.formErrorMessage.join(' ')
        }
      }

      if (this.showingSingleErrorLayout) {
        this.scrollTo()
      } else {
        if (scrollTo) {
          this.scrollTo(scrollTo)
        } else {
          this.scrollToFirstErrorElement()
        }
      }
    },

    parseValidationSetup(validationRules) {
      return parseValidationSetup(validationRules, this)
    },
    getFormOptions() {
      return this.formOptions || {}
    },
    getFormClasses() {
      return [...this.getFormExtraClasses()]
    },
    getFormExtraClasses() {
      return []
    },
    resetForm(fieldsPath = 'fields') {
      if (!this.validateFormFieldOnDirty) {
        this.formSubmitting = false
      }
      const form = _.get(this, fieldsPath, this['form'])
      if (!_.isEmpty(form)) {
        _.forOwn(form, (value, key) => {
          form[key] = null
        })
      }
      const v = this.$v
      if (v) {
        const formV = _.get(v, fieldsPath)
        if (formV) {
          formV.$reset()
        } else {
          v.$reset()
        }
      }
    },
    setupAutoForm(form, fieldsPath = 'fields') {
      form = form || this.form || {}
      const defaultFormFields = _.transform(form.fields, (carry, f, key) => {
        return carry[key] = ''
      }, {})
      const allValidationsRules = _.mapValues(form.fields, (f, k) => {
        return f.validations || {}
      })
      return this.setupForm(allValidationsRules, defaultFormFields, fieldsPath)
    },

    setupForm(allValidationsRules, defaultFormFields, fieldsPath = 'fields') {

      const fieldKey = fieldsPath
      fieldsPath = fieldsPath ? (fieldsPath + '.') : ''
      defaultFormFields = defaultFormFields || {}

      // get form Fields from settings
      const formFields = _.omit(this.$$s(`form.${fieldKey}`) || {}, ['$pick'])

      // combine fields from default and frontend settings
      let defaultValues = _.merge(
        {},
        defaultFormFields,
        _.mapValues(formFields, (f, fk) => this.$$fv(f.default, f.value, defaultFormFields[fk], undefined)),
      )

      const fields = _.mapValues(defaultValues, v => v === undefined ? null : v)

      // set component option
      if (fieldKey) {
        const existingFields = _.cloneDeep(_.get(this, fieldKey, {}))
        const allFields = _.mergeWith({}, fields, existingFields, (ov, sv) => {
          return this.$$fs(sv, ov)
        })
        _.set(this, fieldKey, allFields)
      }

      this.hydrateFields(fields, fieldKey)
      // get field keys
      const formFieldsKeys = _.keys(fields)
      // initiate custom error object of fields
      this.fieldCustomErrors = _.mapValues({...defaultFormFields, ...formFields}, v => null)

      const validations = this.parseFormValidations(allValidationsRules, formFields, fieldKey)

      // filter out required validations - based on list of fields defined in $pick.validations
      // or defined field names
      let pickValidations = this.$$s(`form.${fieldKey}.$pick.validations`, null)
      pickValidations = pickValidations || formFieldsKeys
      if (_.isString(pickValidations)) {
        pickValidations = pickValidations.replace(/\s/g, '').split(',')
      }
      const validationRules = _.pick(validations, pickValidations)
      this.validations = this.parseValidationSetup(validationRules)

      if (_.has(this, 'setupValidationRules')) {
        this.setupValidationRules(validationRules, fieldKey, parseValidations)
      } else {
        this.setValidationRules = function () {
          return { [fieldKey]: parseValidations(validationRules) }
        }
      }
    },
    parseFormValidations(allRules, fields, formKey = 'fields') {
      allRules = allRules || {}
      if (_.isEmpty(fields)) {
        return allRules || {}
      }
      const rules = _.cloneDeep(allRules)
      // update validations from settings
      fields = _.omit(fields || {}, ['$pick'])
      const fieldRules =  _.mapValues(fields, (field, fieldName) => {
        let defaultRules = rules[fieldName]
        const label = this.$$t(
          `form.${formKey}.${fieldName}.validationLabel`,
          this.getFieldLabel(fieldName, _.startCase(fieldName), formKey, true)
        )
        const messagesPath = `form.${formKey}.${fieldName}`
        const messages = this.$$t(`${messagesPath}.validationMessages`,
          this.$$t(`${messagesPath}.validations`, null))
        const validations = _.isObject(field) ? field.validations : null
        const hasValidations = !_.isNil(validations) || !_.isNil(defaultRules)
        const isHidden = !nil(field.show) && toBoolean(field.show) === false
        const removeValidation = !hasValidations || isHidden || validations === false || validations === 0
        if (removeValidation) {
          return {}
        }
        if (_.isEmpty(defaultRules)) {
          const newRule = setValidationRule(`${formKey}.${fieldName}`)
          newRule.items = {}
          defaultRules = newRule
        }
        return this.parseFieldValidationRules(field, defaultRules, {fieldName, formKey, label, messages})
      })

      // update rule validation messages from translations (which are not defined in Settings -> form.fields)
      _.forOwn(rules, (rule, fieldName) => {
        const itemInFields = fields[fieldName]
        if (!itemInFields) {
          const messagesPath = `form.${formKey}.${fieldName}`
          const messages = this.$$t(`${messagesPath}.validationMessages`,
          this.$$t(`${messagesPath}.validations`, null))
          if (!_.isEmpty(messages)) {
            _.forOwn(rule.items, (item, ruleName) => {
              const message = messages[ruleName]
              if (message) {
                item.error = message
              }
            })
          }
        }
      })

      const finalItems = {...rules, ...fieldRules}
      let pickValidations = this.$$s(`form.${formKey}.$pick.validations`, null)
      if (pickValidations) {
        if (_.isString(pickValidations)) {
          pickValidations = pickValidations.replace(/\s/g, '').split(',')
          if (!_.isEmpty(pickValidations)) {
            return _.pick(finalItems, pickValidations)
          }
        }
      }

      return finalItems
    },
    parseFieldValidationRules(field, defaultRules, options) {
      defaultRules = defaultRules || {}
      const {label, messages, formKey, fieldName} = options
      let fieldRules = field.validations

      // backward compatability
      if (fieldRules === false) {
        fieldRules = {required: false}
      }
      // backward compatability
      if (fieldRules === true) {
        fieldRules = {required: true}
      }
      // backward compatability
      if (field.required === true) {
        fieldRules = {...(fieldRules || {}), required: true}
      }

      if (fieldRules && _.isString(fieldRules)) {
        fieldRules = parseValidationsFromString(fieldRules)
      }

      // backward compatability
      if (fieldRules) {
        const fieldRequiredRule = fieldRules.required
        if (fieldRequiredRule === false) {
          _.unset(fieldRules, 'required')
          _.unset(defaultRules, 'items.required')
        }
        if (fieldRequiredRule === true || fieldRequiredRule === 1) {
          fieldRules = {...fieldRules, required: {}}
        }
      }

      const defaultItems = defaultRules.items || {}
      const mergedItems = _.omitBy({...defaultItems, ...fieldRules || {}}, r => !r && !_.isEmpty(r))
      const finalItems = _.mapValues(mergedItems, (def, key) => {
        const message = _.get(messages, key)
        return parseFieldsValidationDef.call(this, def, key, {label}, {template: message})
      })
      const name = `${formKey}.${fieldName}`
      return {name, ...defaultRules, items: finalItems}
    },
    hydrateFields(fields, fieldsKey = 'fields') {
      // stub
    }
  }
}

