/*global _ */
import {Vue} from '~/addiesaas'
import {create} from './vue'

export const inject = (component, config = {}) => {

  const {useCreate = false, slots = {}, props = {}, target = 'body', options = {} }  = config

  let instance
  if (useCreate) {
    instance = create(component, {props})
  } else {
    const ComponentClass = Vue.extend(component)
    const componentProps = {
      propsData: props || {}
    }
    instance = new ComponentClass(componentProps)
  }
  if (slots) {
    if (_.isString(slots)) {
      instance.$slots.default = [slots]
    } else {
      _.forOwn(slots, (value, slotName) => {
        instance.$slots[slotName] = [value]
      })
    }
  }

  instance.$mount()
  if (!instance.hasMountPoint) {
    let targetElm = target
    if (_.isString(target)) {
      targetElm = document.querySelector(target)
    } else if (target instanceof Vue) {
      targetElm = target.$el
    }

    if (options.prepend) {
      targetElm.insertBefore(instance.$el, targetElm.firstChild)
    } else if (options.before) {
      if (targetElm.parentNode) {
        targetElm.parentNode.insertBefore(instance.$el, targetElm)
      }

    } else if (options.after) {
      if (targetElm.parentNode) {
        targetElm.parentNode.insertBefore(instance.$el, targetElm.nextSibling)
      }
    } else {
      targetElm.appendChild(instance.$el)
    }
  }

  return instance
}

export default inject
