import createPersistedState from 'vuex-persistedstate'
import * as types from '../mutation-types/cart'

const EventBus = window.VueEventBus
// set this true to namespace all getters, mutations, actions etc.
const namespaced = true
export const namespace = 'Cart'
const rootNamespace = 'REUtils'
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

const location = window.addiesaas.location || {}
const locationId = location.id || ''
const locationIdKey = locationId

// state
const state = {
  isLocationCheckout: false,
  location_id: null,
  locationValid: true,
  cartStep: 'cart',
  isValidating: false,
  validationError: null,
  validationErrors: null,
  valid: false,
  costDetails: null,
  askToSignIn: false,
  showCartInfoModal: false,
  cartInfoModalDetails: {},
  paymentDetails: {}
}

// mutations
const mutations = {
  [types.SET_CART_STEP](state, step) {
    state.cartStep = step
  },
  [types.SET_CART_LOCATION_VALID](state, valid) {
    state.locationValid = valid
  },
  [types.SET_CART_VALID](state, valid) {
    state.valid = valid
  },
  [types.SET_CART_VALIDATING](state, status) {
    state.isValidating = status
  },
  [types.SET_VALIDATION_ERROR](state, message) {
    state.validationError = message
  },
  [types.SET_VALIDATION_ERRORS](state, e) {
    state.validationErrors = e
  },
  [types.SET_COST_DETAILS](state, payload) {
    state.costDetails = payload
  },
  [types.SET_SIGN_IN_FLAG](state, payload) {
    state.askToSignIn = payload
  },
  [types.SET_PAYMENT_DETAILS](state, payload) {
    state.paymentDetails = payload
  },
  [types.SET_LOCATION_CHECKOUT](state, allow = true) {
    state.isLocationCheckout = allow || false
  },
  [types.SET_CART_LOCATION](state, location_id) {
    state.location_id = location_id
  },
  [types.SHOW_CART_INFO_POPUP](state, payload) {
    if (!payload) {
      state.cartInfoModalDetails = {}
      state.showCartInfoModal = false
    } else {
      state.cartInfoModalDetails = payload
      state.showCartInfoModal = true
    }
  },
}

const actions = {
  setCartStep ({ commit }, payload) {
    commit(types.SET_CART_STEP, payload)
  },
  setCartLocationValid ({ commit }, valid = true) {
    commit(types.SET_CART_LOCATION_VALID, valid)
  },
  setCartValid ({ commit }, valid = true) {
    commit(types.SET_CART_VALID, valid)
  },
  setCartInvalid ({ commit }) {
    commit(types.SET_CART_VALID, false)
  },
  setCartValidating({ commit }, status = true) {
    commit(types.SET_CART_VALIDATING, status)
  },
  setValidationError ({ commit }, payload = null) {
    commit(types.SET_VALIDATION_ERROR, payload)
  },
  setValidationErrors ({ commit }, payload = null) {
    commit(types.SET_VALIDATION_ERRORS, payload)
  },
  setCostDetails ({ commit }, payload) {
    commit(types.SET_COST_DETAILS, payload)
  },
  setAskToSignIn ({ commit }, payload) {
    commit(types.SET_SIGN_IN_FLAG, payload)
  },
  showCartInfo ({ commit }, payload) {
    commit(types.SHOW_CART_INFO_POPUP, payload)
  },
  hideCartInfo ({ commit }) {
    commit(types.SHOW_CART_INFO_POPUP, null)
  },
  setPaymentDetails({commit}, payload) {
    commit(types.SET_PAYMENT_DETAILS, payload)
  },
  allowLocationCheckout({commit}, allow = true) {
    commit(types.SET_LOCATION_CHECKOUT, allow)
  },
  setCartLocation({commit}, location_id) {
    commit(types.SET_CART_LOCATION, location_id)
  }
}

// getters
const getters = {
  cartStep: state => state.cartStep,
  isValidatingCart: state => state.isValidating,
  valid: state => state.valid,
  cartLocationValid: state => state.locationValid,
  validationError: state => state.validationError,
  validationErrors: state => state.validationErrors,
  costDetails: state => state.costDetails,
  askToSignIn: state => state.askToSignIn,
  cartInfoModalDetails: state => state.cartInfoModalDetails,
  showCartInfoModal: state => state.showCartInfoModal,
  cartPaymentDetails: state => state.paymentDetails,
  useLocationCheckout: state => state.isLocationCheckout,
  cartLocationId: (state, getters, rootState, rootGetters) => {
    const items = rootGetters['Shop/Orders/Cart/cartItems'] || []
    const location = _.filter(_.map(items, 'payload.options.location_id'))
    return _.head(location)
  }
}

const plugins = [
  createPersistedState({
    paths: [
      `${rootNamespace}.${namespace}`
    ],
    key: absoluteNamespace + locationIdKey,
  })
]

const cart = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}
export default cart
