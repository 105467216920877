import ModalMixin from '../../../mixins/modal-alerts'

export const props = {
  denyLabel: {
    type: [String, Function],
  },
  confirmLabel: {
    type: [String, Function],
  },
  confirmed: {
    type: [Function],
  },
  denied: {
    type: [Function],
    default: null,
  },
  containerClass: {
    type: [Array, String, Object],
    default: 're-modal-md'
  }
}
export default {
  name: 're-confirm',
  type: 'confirm',
  mixins: [ModalMixin],
  props,
  methods: {
    confirm() {
      this.respond(true)
    },
    deny() {
      this.respond(false)
    },
    respond(state) {
      const {then, confirmed} = this.options
      then && then(state)
      confirmed && confirmed(state)
      this.close(state)
    },
  }
}
