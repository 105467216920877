export const name = 're-shop-products-item-generic'

import {
  mixins,
  props,
  validationsRules,
  defaultFormFields,
  data,
  EventBus
} from '../base/config'

export {
  mixins,
  props,
  validationsRules,
  defaultFormFields,
  data,
  EventBus
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
