/*global _ */
import Base from '../Base'
import Family from './Family'
import Subscription from './Subscription'

export class User extends Base {
  constructor(data, vm) {
    super(data, vm)

    // initializing properties for use in reactive space
    this.members = []
    this.cards = {
      playcard: [],
      giftcard: [],
    }
    this.orders = []
    this.promotions = []
    this.rewards = []
    this.loyalty = {}
    this.paymentMethods = []
  }

  get id() {
    return this.data.id
  }

  get fullName() {
    return _.trim([this.firstName, this.lastName].join(' '))
  }

  get firstName() {
    return this.data.first_name
  }

  get lastName() {
    return this.data.last_name
  }

  get email() {
    return this.data.email
  }

  async family(fetch = false) {
    let members = this.members
    if (fetch || _.isEmpty(members)) {
      const items = await User.Family.all(this, this.$$vm())
      members.splice(0, members.length, ...items)
    }
    return members
  }

  addFamilyMember(data) {
    let members = this.members
    const member = new Family(data || {}, this, this.$$vm())
    members.splice(members.length, 0, member)
    return members
  }

  getFamilyMember(id, options = {}) {
    options = options || {}
    const findBy = options.findBy || 'id'
    const member = _.find(this.members, (member) => {
      if (_.isFunction(findBy)) {
        return findBy(member)
      }
      return _.get(member, findBy) === id
    })
    const getter = options.get
    if (getter) {
      if (member instanceof Family) {
        return _.get(member, getter)
      }
    }
    return member
  }

  async createFamilyMember(data) {
    let member
    let memberIndex
    if (_.isEmpty(data)) {
      memberIndex = _.findIndex(this.members, m => _.isNil(m.id))
      if (memberIndex >= 0) {
        member = this.members[memberIndex]
      }
    } else {
        member = new Family(data, this, this.$$vm())
    }
    if (member) {
      const createdMember = await member.create()
      const deleteCount = !_.isNil(memberIndex) && memberIndex >= 0 ? 1 : 0
      memberIndex =  deleteCount ? memberIndex : this.members.length
      this.members.splice(memberIndex, deleteCount, createdMember)
      return createdMember
    }
    return null
  }

  async deleteFamilyMember(id) {
    let member
    if (id instanceof Family) {
      member = id
      id = member.id
    } else {
      member = _.find(this.members, m => m.id === id)
    }
    const memberIndex = _.findIndex(this.members, m => m.id === id)
    if (memberIndex >= 0) {
      await member.delete()
      this.members.splice(memberIndex, 1)
      return id
    }
    return null
  }
}

User.Family = Family
User.Subscription = Subscription
export default User
