const EventBus = window.VueEventBus
import CartMixins from '../../cart'
import DatesMixin from '../../dates'

export default {
  mixins: [
    CartMixins,
    DatesMixin
  ],
  props: {
    item: {
      type: Object,
      default: null
    },
    items: {
      type: Object,
      default: null
    },
    error: {
      type: [String, Object],
      default: null
    },
    groupedItems: {
      type: Object,
      default: null
    },
    reservationType: {
      type: String,
      default: 'race-reservations'
    }
  },
  data() {
    return {}
  },
  created() {
    const item = this.item

    EventBus.$once('cart.error.item.expired.validation.' + this.item.id, (error, item, cart) => {
      EventBus.$emit('timer.timeout')
    })

    if (this.reloadedCartTimedout) {
      EventBus.$emit('timer.timeout')
    }

    EventBus.$on('cart-cleaned', cart => {
      this.unhold()
    })

    EventBus.$once('timer.timeout', (timer) => {
      EventBus.$off('timer.timeout')
      if (this.hasExpired(item)) {
        EventBus.$emit('timer.timeout.set-timeout-removed-types', [this.itemRaceTypeTitle])
        this.$nextTick(() => {
          EventBus.$emit('timer.timeout.show-timeout-alert')
          this.$nextTick(() => {
            this.removeThisCartItem()
            this.$nextTick(() => {
              this.tryTimerStart()
              EventBus.$emit('cart.validate')
            })
          })
        })
      }
    })

  },
  computed: {
    reloadedCartTimedout() {
      const holdEnds = 1 * _.get(this.item, 'payload.options.form.holdEndsAt', 0)
      const now = this.getNowSeconds()
      let expired = now > holdEnds
      if (!expired) {
        const earliestSlotTime = this.getEarliestSlotTime(this.item)
        if (earliestSlotTime) {
          expired = this.getNowSeconds()  >= earliestSlotTime
        }
      }
      return expired
    },
    itemOptions() {
      return _.get(this.item || {}, 'payload.options', {})
    },
    itemAttributes() {
      return _.get(this.item || {}, 'attributes', {})
    },
    itemSlot() {
      const options = this.itemOptions
      return options.slot || {}
    },
    itemForm() {
      const options = this.itemOptions
      return options.form || {}
    },
    usePackages() {
      return this.itemForm.usePackages
    },
    productRate() {
      return this.item.attributes.product.rate
    },
    productCount() {
      return this.item.payload.count
    },

    slottedDate() {
      const form = this.itemForm || {}
      return form.selectedDate
    },
    slottedDateFormatted() {
      const date = this.slottedDate
      if (date) {
        return this.formatDate(date, 'M/d/y')
      }
      return ''
    },
    cartedSlots() {
      const form = this.itemForm || {}
      return form.selectedSlots || {}
    },
    childIds() {
      return this.itemOptions.childItems || []
    },
    bookingPointProductItems() {
      const childIds = this.childIds
      const races = _.reduce(childIds, (items, id) => {
        const item = this.cartItem(id)
        if (item && item.type === 'races') {
          items[id] = item
        }
        return items
      }, {})

      return races
    },

  },
  methods: {
    tryTimerStart() {
      if (this.timerIsRunning) {
        return
      }
      const items = this.items
      const item = this.item
      const itemId = _.get(item, 'attributes.slot.id')
      if (itemId) {
        let endsAt = null
        _.forOwn(items, otherItem => {
          if (otherItem.type === this.reservationType) {
            const {attributes: {form = {}}} = otherItem
            const {holdEndsAt, holdStartsAt} = form
            endsAt = endsAt || this.getNowSeconds()
            if (holdEndsAt > endsAt) {
              endsAt = holdEndsAt
            }
          }
        })
        if (endsAt) {
          const timerTimeout = endsAt -  this.getNowSeconds()
          this.startTimer(timerTimeout)
        }
      }
    },
    hasExpired(item) {
      let expired = this.hasTimerExpired(item)
      if (!expired) {
        const earliestSlotTime = this.getEarliestSlotTime(item)
        if (earliestSlotTime) {
          expired = this.getNowSeconds() >= earliestSlotTime
        }
      }
      return expired
    },
    hasTimerExpired(item) {
      const ends = 1 * _.get(item, 'payload.options.form.holdEndsAt', 0)
      if (ends) {
        return this.getNowSeconds() >= ends
      }
      return false
    },
    deleteFromOtherItems() {
      const items = this.items
      const item = this.item
      const itemId = _.get(item, 'attributes.slot.id')
      if (itemId) {
        const holds = []
        _.forOwn(items, otherItem => {
          if (otherItem.type === this.reservationType) {
            const {attributes: {form: {selectedSlots = {}}}} = otherItem
            let timeToDelete = null
            if (!_.isEmpty(selectedSlots)) {
              _.forOwn(selectedSlots, (slot, time) => {
                if (slot.id === itemId) {
                  timeToDelete = time
                  return false
                }
              })
              if (timeToDelete) {
                let thisSlot = selectedSlots[timeToDelete]
                let thisSlotHold = thisSlot.hold || {}
                let holdId = thisSlotHold.onlineBookingReservationsId
                if (holdId) {
                  holds.push(holdId)
                }
                delete selectedSlots[timeToDelete]
              }
            }
          }
        })
        if (holds.length) {
          EventBus.$emit('cart.booking-item.unhold', holds)
        }
      }
    },
    removeThisCartItem(skipParent) {
      this.unhold()
      const item = this.item
      const items = _.cloneDeep(this.childIds)
      _.forOwn(items, id => {
        this.removeCartItem({id})
      })
      if (!skipParent) {
        this.removeCartItem({id: item.id})
      }
      EventBus.$emit('cart-item-deleted', item)
    },
    itemDelete($event) {

      const item = this.item
      this.removeThisCartItem(true)
      const slots = this.cartedSlots

      this.deleteFromOtherItems()
      this.cartItemButtonClicked({
        $event,
        actionName: 'delete',
        id: item.id,
        item,
        cartItems: this.currentCartItems,
        button: {}
      })

      EventBus.$emit('cart-item-deleted', item, this, this.$parent)
      EventBus.$emit('cart-item-deleted-' + item.type, item, this, this.$parent)
    },
    itemEdit($event) {
      const item = this.item
      this.setRaceEditItem(item)
      this.cartItemButtonClicked({
        $event,
        item,
        actionName: 'edit',
        id: this.item.id,
        cartItems: this.currentCartItems,
        button: {}
      })
      EventBus.$emit('cart-item-edit', item, this, this.$parent)
      EventBus.$emit('cart-item-edit-' + item.type, item, this, this.$parent)

      const page = 'page-race'
      this.goto(page)

    },
    unhold() {
      const slots = this.cartedSlots
      const holds = _.reduce(slots, (items, slot, key) => {
        const holdId = _.get(slot, 'hold.onlineBookingReservationsId')
        if (holdId) {
          items.push(holdId)
        }
        return items
      }, [])
      if (holds.length) {
        EventBus.$emit('cart.booking-item.unhold', holds)
      }
    },
    getEarliestSlotTime(item, slots) {
      slots = slots || _.values(_.get(item, 'payload.options.form.selectedSlots', {}))
      if (slots) {
        const slot = _.minBy(slots, o => {
          return this.parseDateTime(o.datetime).valueOf()
        })
        if (slot) {
          return this.parseDateTime(slot.datetime).toSeconds()
        }
      }
      return null
    },
  },
}
