/*global _, addiesaas, $, debugLog */
// @hydrate
// @define hydrator method for options
import {VUE_EVENTS as VE} from '../../designer/lib/constants'
import {callOnReady} from '../../designer/lib/vue'
import formatters from '../formatters'

const exportDefault = {}
export const optionsHydrator = exportDefault.optionsHydrator = function (hydrate) {
  const vm = this
  const context = this.context || {}
  const notifier = (options) => {
    const config = {reset: true}
    callOnReady(vm, VE.UPDATED + '::options', {options, config})
  }

  const refreshSelected = () => {
    const innerValueName = vm.innerValueName || 'innerValue'
    const innerValue = vm[innerValueName]
    if (!_.isNil(innerValue)) {
      vm[innerValueName] = ''
      vm.$nextTick(() => {
        vm[innerValueName] = innerValue
      })
    }
  }

  const hydrateValues = (values) => {
    const allOption = hydrateOptions.allOption
    if (_.isObject(allOption)) {
      if (_.isArray(values)) {
        values = [..._.values(allOption), ...values]
      } else if (_.isObject(values)) {
        values = {...allOption, ...values}
      }
    }
    vm.innerOptions = values
    vm.$nextTick(() => {
      refreshSelected()
      notifier(vm.innerOptions)
    })
  }

  const hydrateOptions = formatters.fv(hydrate || vm.hydrate || {})

  const getter = hydrateOptions.get
  if (getter) {
    const values = formatters.getFirst(getter, context, formatters, _, vm, window)
    hydrateValues(values)
    return
  }

  const method = hydrateOptions.method
  if (method) {
    const callable = _.isFunction(method) ? method :
      formatters.getFirst(method, context, formatters, _, vm, window)

    if (_.isFunction(callable)) {
      const callableResult = callable()
      if (callableResult instanceof Promise) {
        callableResult.then(hydrateValues)
      } else {
        hydrateValues(callableResult)
      }
      return
    }
  }

  const serviceName = hydrateOptions.service
  if (serviceName) {
    formatters.invokeService(serviceName)
      .then(hydrateValues)
  }
}

export default exportDefault
