export const name = 're-core-page-shop-products-pagination-status'
export const component = 're-shop-products-pagination-status'

import {factory} from '../../../../../../lib/page'
import {queryVars} from '../../../../../complex/shop/products/base/props'

const props = {
  ...queryVars
}

export default factory(name, {props, component})

