/*global _, addiesaas, $, debugLog */
import { createNamespacedHelpers } from 'vuex'
import { namespace } from '../vuexstore'
import { default as store, namespace as pageNamespace } from '../vuexstore/modules/pages'
const { mapGetters, mapActions } = createNamespacedHelpers([namespace, pageNamespace].join('/'))

export default {
  data() {
    return {
      pageErrorMessage: null,
    }
  },
  computed: {
    ...mapGetters(_.keys(_.omit(store.getters, ['servicePromises'])))
  },
  methods: {
    ...mapActions(_.keys(_.omit(store.actions, ['setServicePromise']))),
    goto(page, params = {}) {
      const url = this.getRouteUrl(page, params)
      if (url) {
        this.$router.push(url)
      }
    },
    gotoHome() {
      this.$router.push('/')
    },
    gotoLogin() {
      this.goto('sign-in')
    },
    gotoRegistration() {
      this.goto('user.register')
    },
    savePageAndGoto(toWhere, options) {
      this.savePageBeforeLeave({page: this.$route, options})
      this.goto(toWhere)
    },
    saveAndGotoLogin(options) {
      this.savePageAndGoto('sign-in', options)
    },
    saveAndGotoRegistration(options) {
      this.savePageAndGoto( 'user.register', options)
    },
    getRouteUrl(page, params = {}, hash = null) {
      let url = page
      const route = this.$router.match({name: page, params})
      const match = _.get(route, 'matched.0')
      if (match) {
        url = route.path
      }
      if (hash) {
        url += ('#' + hash)
      }
      return url
    },
    isRoute(routeName) {
      const name = _.trim(this.$route.name || '', '/')
      let isThisRoute = false
      if (!name) {
        const route = _.trim(this.getRouteUrl(routeName), '/')
        const path = _.trim(location.pathname, '/')
        isThisRoute = route === path
      } else {
        isThisRoute = name === routeName
      }
      return isThisRoute
    }
  }
}
