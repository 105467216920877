<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title.mb-4 What’s your role?

      .b-central__panel.b-central__panel--w600.m-b-20
        .b-role-boxes.row.justify-content-center
          .col-4.p-10(v-for="role in roles")
            a.b-role-box(href="#" @click.prevent="select(role)" :class="{ active: active(role) }")
              inline-svg(:src="require(`../../../public/images/client/saas-onboarding/role-${role.icon}-icon.svg`)")
              .b-role-box__text {{ role.text }}

      .b-central__footer.mb-4.b-central__footer--navigation-btn
        a.btn.btn-almost-sm.btn-secondary(@click.prevent="$router.go(-1)") Back
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$invalid") Continue

      .b-central__message.text-danger(v-if="backendError") {{ backendError }}

</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

const CODE_LENGTH = 6

const ROLES = [
  { text: 'Founder / CEO', icon: 'founder-ceo' },
  { text: 'Marketer', icon: 'marketer' },
  { text: 'Manager', icon: 'manager' },
  { text: 'Consultant / Agency', icon: 'consultant-agency' },
  { text: 'IT / Developer', icon: 'it-developer' },
  { text: 'Designer', icon: 'designer' },
  { text: 'Other', icon: 'other' },
]

export default {

  data: () => ({
    config: window.addiesaas.config,
    roles: ROLES,
    role: null,
    backendError: null,
    submitting: false,
  }),

  validations: {
    role: {
      required,
    },
  },

  methods: {
    select(role) {
      this.$v.role.$model = role.text
      this.backendError = null
    },

    active(role) {
      return role.text == this.role
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/role`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
          role: this.role
        })
        this.$router.push({ name: 'onboarding-get-started', query: this.$route.query })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>
