export default {
  name: 're-button',

  props: {
    nativeType: {
      type: String,
      default: 'button'
    },
    title: {},
    showTitle: {
      type: [Boolean, Number, String],
      default: true
    },
    label: {},
    disabled: {
      type: [Boolean, Number, String],
      default: false
    },

    loading: {
      type: [Boolean, Number, String],
      default: false
    },
    classes: {
      type: [String, Array, Object],
      default: null
    },

    noDefaultClass: {
      type: [Boolean, Number, String],
      default: false
    },
    value: {
    }
  },
  computed: {
    buttonTitle() {
      return this.$$fv(this.title, this.label, 'Submit')
    }
  },
  methods: {
    onClick() {
      this.$emit('input', this.value)
    }
  },
}
