/*global _ */
import {TIER_TYPE} from '../../../lib/profile/loyalty'

export default {
  computed: {
    loyaltyRewards() {
      return _.get(this.storedProfileData, 'loyalty.rewards', [])
    },
    loyaltyRewardsGrouped() {
      const baseGroup = _.groupBy(this.loyaltyRewards, 'type')
      const groups = _.mapValues(baseGroup, (items, type) => {
        items = items || []
        items.sort((a, b) => a.points - b.points)
        if (type === TIER_TYPE && items.length) {
          let topIndex = _.findLastIndex(items, i => {
            return i.tierRank === 1
          })
          if (topIndex < 0) {
             topIndex = items.length - 1
          }

          items[topIndex] = this.parseRewardItem(
            items[topIndex],
            'reward.tierTop',
            {
              isTopReward: true
            }
          )
        }
        return items
      })

      const params = {groups, vm: this}
      this.applyDataFilter('rewardGroups', params)
      return params.groups
    }
  },
  created() {
    const params = {vm: this}
    this.applyDataFilter('init', params)
  },
  methods: {
    getRewardPointFromPrice(price) {
      return price * this.pointPriceFactor
    },
    getRewardPriceFromPoint(point) {
      return point / this.pointPriceFactor
    },
    parseRewardItem(rewardItem, path, mergeData, mergeAttrs) {
      rewardItem = rewardItem || {}
      const {type} = rewardItem
      const settingsPath = path || `rewards.${type}`
      const settings = _.merge(
        {},
        this.$$t(settingsPath, path ? {} : this.$$t('reward.item', {})),
        this.$$s(settingsPath, path ? {} : this.$$s('reward.item', {})),
        mergeData || {}
      )
      const updates = this.getComponentAttrs(_.omit(settings, ['attrs']), {}, true, reward)
      const reward = {
        ...rewardItem,
        ...updates
      }
      // debugLog({settings, reward, rewardItem, mergeAttrs})
      reward.attrs = this.getRewardItemAttrs(settings.attrs, reward, mergeAttrs)

      const params = {reward, vm: this}
      this.applyDataFilter('reward', params)
      return params.reward
    },
    getRewardItemAttrs(settings, reward, mergeAttrs) {
      reward = reward || {}
      settings = settings || {}
      mergeAttrs = mergeAttrs || {}
      const {type, unlocked, isTopReward} = reward
      const defaults = {}
      const attrSettings = _.merge({}, settings, mergeAttrs)
      const attrs = this.getComponentAttrs(attrSettings, defaults, true, reward)

      const appendClasses = [
        [`reward-${type}`],
        {
          'is-unlocked': unlocked,
          'is-locked': !unlocked,
          [`reward-${type}-top`]: isTopReward,
        }
      ]
      const classes = this.parseAttrClasses(attrs, appendClasses)
      return {
        ...attrs,
        class: classes
      }
    }
  }
}
