/* global _ */
import {name, mixins, props, data, defaultFormFields, validationsRules, EventBus} from './config'
import {now} from '../../../../../lib/dates'

import AsyncEventBus from '../../../../../lib/async-event-bus'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      currentStep: 'billing',
      today: now,
    }
  },

  async created() {
    this.setupForm(validationsRules, defaultFormFields)
    this.setValidationError(null)
    if(_.isEmpty(this.costDetails) && !_.isEmpty(this.totals)) {
      this.costDetails = this.totals
    }
    if (!_.isEmpty(this.cartPaymentDetails) && this.viewPaymentDetails) {
      this.fields = _.clone(this.cartPaymentDetails)
      this.setPaymentDetails({});
    }
  },

  computed: {
    requiredBillingCity() {
      return this.hasBillingAddressBox && this.hasBillingCity
    },
    requiredBillingCountry() {
      return this.hasBillingAddressBox && this.hasBillingCountry
    },
    requiredBillingAddress() {
      return this.hasBillingAddressBox && this.hasBillingAddress
    },
    requiredBillingZip() {
      return this.hasBillingAddressBox && this.hasBillingZip
    },
    requiredBillingState() {
      return this.hasBillingAddressBox && this.hasBillingState
    },
    nextMonth() {
      return this.today.plus({months: 1})
    },
    expYears() {
      const years = []
      for (let i = this.nextMonth.year; i < this.nextMonth.year + 20; i++) {
        years.push(i)
      }
      return years
    },
    showSubmitButton() {
      return !this.currentCartIsEmpty && this.costDetails
    },
    errorMessage() {
      return this.validationError
    }
  },

  methods: {
    getComponentExtraClasses() {
      return [{
        'disabled': !!this.isFormDisabled,
        'review-payment-details': !!this.viewPaymentDetails,
      }]
    },
    backToCart() {
      this.setCartInvalid()
      this.setValidationError(null)
      this.$v.$reset()
      this.setCartStep('cart')
      EventBus.$emit('billing.go-back', this)
    },
    async submit()    {
      this.fields.billingEnabled = this.billingEnabled
      this.fields.isRequiredCity = this.requiredBillingCity
      this.fields.isRequiredCountry = this.requiredBillingCountry
      this.fields.isRequiredAddress = this.requiredBillingAddress
      this.fields.isRequiredZip = this.requiredBillingZip
      this.fields.isRequiredState = this.requiredBillingState

      this.formSubmitting = true
      this.setValidationError()
      this.$v.$reset()
      this.$v.$touch()

      if (!this.isAnyInvalid) {
        const payload = {}
        if (this.billingEnabled) {
          payload.payment = {
            card: {
              number: this.fields.card.replace(/ +/g, '').trim(),
              year: this.fields.expYear,
              month: this.fields.expMonth,
              code: this.fields.cvv,
              first_name: this.fields.first_name,
              last_name: this.fields.last_name,
            },
          }
          payload.payment.billing = {
            zip: this.fields.zip,
            city: this.fields.city,
            country: this.fields.country,
          }
        }

        if (!this.authUser) {
          payload.guest = {
            first_name: this.fields.first_name,
            last_name: this.fields.last_name,
            email: this.guestEmail,
            phone: '+1',
          }
        }
        EventBus.$emit('billing.before.submit', payload)
        await AsyncEventBus.emit('billing.before.submit.async', payload)
        this.setPaymentDetails(this.fields)
        await this.submitCart(payload)
      } else {
        this.isSubmitting = false
        this.setCartInvalid()
        this.setValidationError('There was a problem with your submission. Errors have been highlighted above.')
        EventBus.$emit('billing.invalid', this)
      }
    },
  }
}
