<template lang="pug">
  .b-onboarding__right.b-onboarding__right--select-blocks
    .b-onboarding__title What Attractions Does Your Entertaiment Center Have?
    .b-onboarding__subtitle Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

    onboarding-select-blocks(:blocks="options.attractionBlocks" :selected="onboarding.attractions || []" @select="select")

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    select(selected) {
      this.update({ attractions: selected })
    },
  }  
}
</script>
