/*global _ */
import {fv} from '../../../lib/utils'
import Paginator from '../../../classes/utils/paginator/Paginator'

/**
 *
 */

export default {
  data() {
    return {
      superStoreName: this.persistSpace,
      superStoreData: {},
      superStorePersistData: {
        page: null,
        index: null,
      },
      // pager: default - instance of Paginator
      // custom pager must extend Paginator or define all properties and methods of Paginator
      pager: {
        pages: null,
        count: null,
        index: null,
        currentPage: null,
        hasPages: null,
        canPrevious: null,
        canNext: null,
        navPages: null,
        navGapMark: null
      }
    }
  },
  computed: {
    persistedPageIndex: {
      get() {
        if (this.persistSpace) {
           return this.$$getStorePersist('index')
        }
      },
      set(value) {
        if (this.persistSpace) {
            this.$$setStorePersist('index', value)
        }
      },
    },
    pagesCount() {
      return this.pager.count
    },
    hasPages() {
      return this.pager.hasPages
    },
    canShow() {
      return this.hasPages
    },
    gapMark() {
      return this.pager.navGapMark
    },
    pageMessage() {
      return this.parseMessage(this.message)
    },
    canPrev() {
      return this.pager.canPrevious
    },
    showPrev() {
      const canPrev = this.canPrev
      const hide = this.hidePrevNext ||
        (!canPrev && fv(this.autoHidePrev, this.autoHidePrevNext))
      return !hide
    },
    canNext() {
      return this.pager.canNext
    },
    showNext() {
      const canNext = this.canNext
      const hide = this.hidePrevNext ||
        (!canNext && fv(this.autoHideNext, this.autoHidePrevNext))
      return !hide
    },
    allPages() {
      return this.pager.pages
    },
    pageItems() {
      return this.defaultReducer(this.allPages)
    },
  },

  methods: {
    defaultReducer(pages) {
      if (_.isFunction(this.reducer)) {
        return this.reducer(pages)
      }
      return this.pager.navPages
    },

    parseMessage(message) {
      return this.defaultMessageParser(message)
    },
    defaultMessageParser(message) {
      if (_.isFunction(this.messageParser)) {
        return this.messageParser(message)
      }
      return message
    },

    parsePage(index, page) {
      page = fv(page, this.getPage(index))
      return this.defaultPageParser(page, index)
    },
    defaultPageParser(page, index) {
      if (_.isFunction(this.pageParser)) {
        return this.pageParser(page, index)
      }
      return page
    },
    getPage(index) {
      return this.allPages[index]
    },
    isPage(index) {
      return _.isFinite(index)
    },
    isActivePage(index) {
      return this.pager.index === index
    },
    canNav(index) {
      return !this.isActivePage(index)
    },
    navigate(index, page) {
      if (_.isFunction(this.navigator)) {
        this.navigator(index, page, this)
      }
      if (_.isNil(index)) {
        return this.pager.navigate(page)
      }
      return this.pager.navigateIndex(index)
    },
    navPrev() {
      this.pager.previous()
      this.navigate(this.pager.index)
    },
    navNext() {
      this.pager.next()
      this.navigate(this.pager.index)
    },
    getPageClass(index) {
      return [
        this.pageNumberComponentAttrs.class,
        {
          active: this.isActivePage(index),
          'no-nav': !this.canNav(index)
        }
      ]
    },
    buildPager(skipNav) {
      let instance = this.paginator
      if (instance instanceof Paginator) {
        instance.$$vm = this
        return instance
      }

      const total = this.total
      const pages = this.pages
      const id = this.nameSpace

      const options = {total, pages, id}
      let pager = this.pager
      if (pager instanceof Paginator) {
        pager.init(options)
      } else {
        if (_.isFunction(instance)) {
          return instance(options, {}, this)
        }

        if (_.isObject(instance)) {
          instance = {}
        }

        pager = new Paginator(options, instance, this)
      }

      const value = this.value
      if (!skipNav && !_.isNil(value)) {
        pager.navigateIndex(value)
      }

      return pager
    }
  }
}
