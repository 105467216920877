export const name = 're-loyalty-tier-rewards'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../../../mixins/components/complex'
import ProfileMixin from '../../../../../../mixins/profile'
import LoyaltyMixin from '../../../../../../mixins/profile/loyalty'
import LoyaltyComponentBaseMixin from '../../../../../../mixins/profile/loyalty/types/base'
import LocationFailingMixin from '../../../../../../mixins/profile/failing-location'
import ResponsiveMixin from '../../../../../../mixins/responsive'
import CardLocationMixin from '../../../../../../mixins/profile/cards/locations'

export const mixins = [
  ComponentMixin,
  ProfileMixin,
  LoyaltyMixin,
  LoyaltyComponentBaseMixin,
  LocationFailingMixin,
  CardLocationMixin,
  ResponsiveMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  type: 'tier',
  errorMessage: '',
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
