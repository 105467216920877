export const name = 're-email-verification'

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
export const mixins = [ComponentMixin, FormMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  fields: {
    email: null,
    token: null,
  },
  success: false
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
