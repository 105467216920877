export const name = 're-shop-products-item-bases'

import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../../../mixins/components/complex'
import ProductMixin from '../../../../../../mixins/shop/product'
import BaseItemMixin from './mixin'
export const mixins = [ComponentMixin, ProductMixin, BaseItemMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  validationsRules,
  defaultFormFields,
  data,
  EventBus,
}
