/* global _ */
import {
  name,
  mixins,
  props,
  data,
  defaultFormFields,
  validationsRules,
  EventBus,
  userFields,
  metaFields,
  otherFields,
  groupedFields,
  defaultSkipValidations
} from './config'

import {
  parseValidations,
  parseValidationSetup,
  phoneRuleIfNotEmpty,
  setValidationRule,
  Validators,
  validDate,
  validDateBeforeToday,
  zipRuleIfNotEmpty
} from '../../../../lib/validations'

import MainService from '../../../../services/main'
import {fv} from '../../../../lib/utils'
import {TIMEZONE, parseDateTime, now, formatDate} from '../../../../lib/dates'
const {required, email, phone} = Validators

const validations = {
  first_name: setValidationRule('fields.first_name', {
    required: {error: 'First name is required.', rule: Validators.requiredIf('first_name_required')}
  }),
  last_name: setValidationRule('fields.last_name', {
    required: {error: 'Last name is required.', rule: Validators.requiredIf('last_name_required')}
  }),
  racer_name: setValidationRule('fields.racer_name', {
    required: {error: 'Racer name is required.', rule: Validators.requiredIf('racer_name_required')}
  }),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.', rule: Validators.requiredIf('email_required')},
    email: {error: 'Email is not valid.'},
  }),
  birth_date: setValidationRule('fields.birth_date', {
    required: {error: 'Valid Birth date is required', rule: Validators.requiredIf('birth_date_required')},
    validDate: {error: 'Birth date is not valid.', rule: validDate},
    pastDate: {error: 'Birth date can not be a future date.', rule: validDateBeforeToday},
  }),
  phone: setValidationRule('fields.phone', {
    required: {error: 'Phone is required.', rule: Validators.requiredIf('phone_required')},
    phone: {error: 'Invalid phone number.', rule: phoneRuleIfNotEmpty}
  }),

  address: setValidationRule('fields.address', {
    required: {error: 'Address is required.', rule: Validators.requiredIf('address_required')},
  }),
  address2: setValidationRule('fields.address2', {
    required: {error: 'Second Address is required.', rule: Validators.requiredIf('address2_required')},
  }),
  city: setValidationRule('fields.city', {
    required: {error: 'City is required.', rule: Validators.requiredIf('city_required')},
  }),
  state: setValidationRule('fields.state', {
    required: {error: 'State is required.', rule: Validators.requiredIf('state_required')},
  }),
  zip: setValidationRule('fields.zip', {
    required: {error: 'Zip is required.', rule: Validators.requiredIf('zip_required')},
    zip: {error: 'Invalid zip.', rule: zipRuleIfNotEmpty},
  }),
  country: setValidationRule('fields.country', {
    required: {error: 'Zip is required.', rule: Validators.requiredIf('country_required')},
  }),
}
export default {
  name,
  props,
  mixins,

  data() {
    return {
      ...data,
      fields: {
        first_name: '',
        last_name: '',
        racer_name: null,
        email: null,
        birth_date: null,
        phone: null,
        gender: null,
        address: null,
        address2: null,
        city: null,
        country: 'US',
        state: null,
        zip: null,
      },
      personalFormElements: ['fields.first_name', 'fields.last_name', 'fields.email', 'fields.birth_date', 'fields.phone', 'fields.gender'],
      addressFormElements: ['fields.address', 'fields.address2', 'fields.city', 'fields.country', 'fields.state', 'fields.zip'],
      validations: parseValidationSetup(validations, this),
      resettingPassword: false,
      isProcessingPassword: false,
      isSuccessPassword: false,
      errorMessagePersonal: null,
      isProcessingPersonal: false,
      isSuccessPersonal:false,
      errorMessageAddress: null,
      isProcessingAddress: false,
      isSuccessAddress: false,
      errorMessageProfile: null,
      isProcessingProfile: false,
      isSuccessProfile: false,
    }
  },

  validations() {
    const omit = (r, e) => {
      e = _.castArray(e)
      return _.mapValues(r, (i, k) => e.includes(k) ? {} : i)
    }
    let allValidationsRules = _.cloneDeep(validationsRules)

    const personalFields = groupedFields['personal'] || []
    const addressFields = groupedFields['address'] || []

    if (!this.showPersonalDetails) {
      allValidationsRules = omit(allValidationsRules, personalFields)
    }
    if (!this.showAddressDetails) {
      allValidationsRules = omit(allValidationsRules, addressFields)
    }

    const excludedFields = []

    //@todo: exculde iterating $$s(fields.item.show == false)
    if (!this.hasRacerNameInput) {
      excludedFields.push('racer_name')
    }
    if (!this.genderRequired) {
      excludedFields.push('gender')
    }
    const settings = this.$$s('form.fields', {})
    _.forOwn(settings, (item, key) => {
      if (item.show === false || item.validations === false) {
        excludedFields.push(key)
      }
    })
    allValidationsRules = omit(allValidationsRules, excludedFields)

    const allKeys = _.keys(allValidationsRules)

    const fields = parseValidations(allValidationsRules)

    const personalGroup = _.map(_.intersection(allKeys, personalFields), i => 'fields.' + i)
    const addressGroup = _.map(_.intersection(allKeys, addressFields), i => 'fields.' + i)

    return {fields, personalGroup, addressGroup}
  },

  computed: {
    maxDateTime() {
      return this.getNow().toISODate()
    },
    resetPasswordSettings() {
      let settings = this.$$clone(this.$$s(`forms.passwordDetails`))
      return settings
    },
    resetPasswordTranslations() {
      let translations = this.$$clone(this.$$t(`forms.passwordDetails`))
      return translations
    },
    resetPasswordAttr() {
      return {
        title: this.passwordBoxTitle,
        classes: this.passwordFormClass,
        buttonTitle: this.passwordFormButtonTitle,
        buttonClasses: this.passwordFormButtonClasses,
        translations: this.resetPasswordTranslations,
        settings: this.resetPasswordSettings,
      }
    },
    isPasswordBoxModal() {
      return !!_.get(this.passwordDetailsFormOptions, 'modal', false)
    },
    isShowPasswordModal() {
      return this.isPasswordBoxModal && this.resettingPassword
    },
    membershipInfo() {
      const membership = _.first(_.filter(this.loadedMemberships || [], item => /annual/i.test(_.get(item,'type.description', ''))))
      return fv(membership, null)
    },
    membershipName() {
      return _.get(this.membershipInfo || {}, 'type.description', '')
    },
    membershipExpiration() {
      const {expiration = null} = this.membershipInfo || {}
      return expiration ? formatDate(expiration, 'd MMMM yyyy') : ''
    }
  },

  async created() {
    this.isBeingProcessed = true
    if (!this.isDesignerMode) {
      await this.pingUser()
    }
    this.setupFormLocal()
    this.isBeingProcessed = false
  },

  methods: {
    setupFormLocal() {
      this.setupForm(validationsRules, defaultFormFields)
    },

    // ...mapActions(['updateUser']),
    hydrateFields(fields, fieldsKey = 'fields') {
      const user = this.user
      const meta = user.meta

      let birth_date = null
      const birthDate = this.parseDateTime(meta.birth_date)
      if (birthDate && birthDate.isValid) {
        birth_date = birthDate.setZone(this.TIMEZONE).toISO()
      }
      const values = {
        ..._.pick(user, userFields),
        ..._.pick(meta, metaFields),
        birth_date
      }

      const form = this[fieldsKey] || this
      _.forOwn(this.fields, (item, key) => {
        if (!_.isNil(values[key])) {
          form[key] = values[key]
        }
      })
    },
    openPasswordModal() {
      this.resettingPassword = true
    },
    closePasswordModal() {
      this.resettingPassword = false
    },
    successResetPassword() {
      this.resettingPassword = false
      this.isSuccessPassword = true
    },
    closeSuccessPasswordModal() {
      this.isSuccessPassword = false
    },

    async updatePersonal(form) {
      EventBus.$emit('profile.before.update-personal')
      let payload = _.get(_.pick(this, this.personalFormElements), 'fields', {})
      if (this.withRacerName) {
        payload = {...payload, clubspeed_racername: this.fields.racerName}
      }
      this.isSuccessPersonal = null
      const isSuccess = await this.changeProfile(payload, 'personalGroup', form)
      if (isSuccess) {
        this.isSuccessPersonal = true
        EventBus.$emit('profile.update-personal')
      }
    },
    async updateAddress(form) {
      EventBus.$emit('profile.before.update-address')
      const payload = _.get(_.pick(this, this.addressFormElements), 'fields', {})
      this.isSuccessAddress = null
      const isSuccess = await this.changeProfile(payload, 'addressGroup', form)
      if (isSuccess) {
        this.isSuccessAddress = true
        EventBus.$emit('profile.update-address')
      }
    },
    async updateProfile(form) {
      EventBus.$emit('profile.before.update-profile')
      const fields = [...this.personalFormElements, ...this.addressFormElements]
      let payload = _.get(_.pick(this, fields), 'fields', {})
      if (this.withRacerName) {
        payload = {...payload, clubspeed_racername: this.fields.racer_name}
      }
      this.isSuccessProfile = null
      const isSuccess = await this.changeProfile(payload, false, form)
      if (isSuccess) {
        this.isSuccessProfile = true
        EventBus.$emit('profile.update-profile')
        if (this.showSuccessPopup) {
          await this.successAlertAsync(this.profileSuccessMessage)
        }
      }
    },

    async changeProfile(payload, validationGroup = false, form = null) {
      if (validationGroup) {
        this.$v[validationGroup].$touch()
      } else {
        this.$v.$touch()
      }
      this.formSubmitting = true

      let error = null
      this.setErrorMessage(error, validationGroup)
      const anyError = validationGroup  ? this.$v[validationGroup].$anyError : this.isAnyInvalid
      let data = false
      if (!anyError) {
        EventBus.$emit('profile.before.update', payload)
        try {
          this.isProcessingProfile = true
          data = await MainService.updateUser(this.user.id, payload)
          this.$store.dispatch('updateUser', { user: data.data })
          this.$emit('success')
          EventBus.$emit('profile.update.success')
        } catch (e) {
          error = MainService.getFirstErrorMessage(e)
          this.setErrorMessage(error, validationGroup)
          this.$emit('error', e)
          EventBus.$emit('profile.update.error', e)
        } finally {
          this.isProcessingProfile = false
          this.formSubmitting = false
        }
        return data
      } else {
        this.formSubmitting = false
        if (form) {
          form.isProcessing = false
          form.isValid = false
        }
        error = 'Form data is invalid'
        this.setErrorMessage(error, validationGroup)
        this.scrollToFirstErrorElement()
      }
    },

    async deleteAccount() {
      const message = this.$$t('forms.delete.confirmation.message', 'Are you sure you want to delete your account?')
      const title = this.$$t('forms.delete.confirmation.title', null)
      const confirmLabel = this.$$t('forms.delete.confirmation.buttons.yes.title', 'Delete account')
      const denyLabel = this.$$t('forms.delete.confirmation.buttons.no.title', 'Cancel')
      const loaderMessage = this.$$t('forms.delete.processing.message', 'Deleting...')
      const successMessage = this.$$t('forms.delete.success.message', 'Your account is deleted successfully')
      const successMessageTitle = this.$$t('forms.delete.success.title', null)

      const options = {message, title, confirmLabel, denyLabel}
      const confirmed = await this.confirmAsync(options)
      if (confirmed) {
        try {
          this.formSubmitting = true
          this.setProcessing(null, true)
          await MainService.deleteUser()
          this.$store.dispatch('deleteUser', { user: this.user })
          EventBus.$emit('profile.delete.success')
          await this.successAlertAsync(successMessage, successMessageTitle)
          await this.logout()

        } catch (e) {
          const error = MainService.getFirstErrorMessage(e)
          this.setErrorMessage(error)
          this.$emit('error', e)
          EventBus.$emit('profile.delete.error', e)
        } finally {
          this.setProcessing(null, false)
          this.formSubmitting = false
        }
      }
    },

    setErrorMessage(error, validationGroup = false) {
      switch (validationGroup) {
        case 'personalGroup':
          this.errorMessagePersonal = error
          break
        case 'addressGroup':
          this.errorMessageAddress = error
          break
        default:
          this.errorMessageProfile = error
      }
    }
  }
}
