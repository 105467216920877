// import _ from 'lodash'
import {inject} from '../dynamic'
import ReToastAlert from '../../components/elements/re-toast-alert'

export default (slots, type = 'info', config = {}) => {

  const defaultProps = {
    id: '',
    type,
    toast: true,
    center: false,
    noIcon: false,
    clickToClose: true,
    clickOverlayToClose: true,
    dontDestroy: false,
    block: false,
    closer: true,
    size: 'full',
    classes: '',
    containerClass: ''
  };

  const props = {...defaultProps, ..._.pick(config || {}, Object.keys(defaultProps))}

  const {target = 'body', options = {}, prepend, before, after} = config
  Object.assign(options, {prepend, before, after})

  return inject(ReToastAlert, {props, slots, target, options})
}
