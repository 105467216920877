/*global _ */
import {hasSlot} from '../../lib/component'
import {attrs as validationAttrs} from '../../props/validations'
//@note: DesignerMixin is required for component like inputs which do not use Base mixin or Component mixin
import DesignerMixin from '../../designer/mixins'
import HydratorMixin from './hydrator'
import EventsMixin from '../events'
import ReactiveMixin from '../mashup-builder/reactives'
const mixins = [DesignerMixin, HydratorMixin, ReactiveMixin, EventsMixin]

import ReValidation from '../../components/basic/form/re-validation'
import {fv, setTimeoutAsync} from '../../lib/utils'
import F from '../../lib/formatters'
const components = {ReValidation}

export default {
  components,
  mixins,
  data() {
    return {
      innerValueName: 'innerValue',
      innerValue: this.value,
      isDirtyState: this.isDirty || false,
    }
  },

  computed: {
    isReadonly() {
      return !!this.allAttrs.readonly
    },
    isDisabled() {
      return !!this.allAttrs.disabled
    },
    inputAttrs() {
      const items = {
        name: this.name,
        tabindex: this.tabindex,
        disabled: this.isDisabled,
        readonly: this.isReadonly,

        ...this.extraInputAttrs,
        ...this.designerInputAttrs,
        ...this.customInputAttrs,
      }

      return _.omitBy(_.omit(items, this.omitInputAttrs), v => _.isNil(v))
    },
    customInputAttrs () {
      return {}
    },
    inputAttrsAll() {
      return {
        ...this.$$attrs(),
        ...this.inputAttrs,
      }
    },
    inputWithAutocompleteOffFixAttrs() {
      return {
        ...this.inputAttrsAll,
        ...this.autocompleteOffFixAttrs,
      }
    },
    designerInputAttrs() {
      if (!this.designerMode) {
        return {}
      }
      return {
        'data-native-element': this.inputType || 'input',
        'data-designer-protected': '1'
      }
    },
    autocompleteOffFixAttrs() {
      let autocomplete = this.autocomplete
      let name = this.name || ''
      // This fix is exclusive to Chrome based browsers (Google Chrome, Microsoft Edge).
      // NOTE: Historically Google Chrome and all Chrome based browsers
      // do not respect autocomplete='off' attribute in the input fields
      // This hack fix changes the name of the input field and
      // the value of the autocomplete attribute to values unknown Chrome to confuse it
      // as much as possible so that it is unable to detect what field type it is and
      // thus preventing it from suggesting autocomplete values, hence autocomplete is off.
      // MORE NOTE: Same fix is required for some fields in Firefox and Safari browsers too.
      // Hence, removing the browser detection conditions
      // However, this does not fix the popup that browsers can show for password type fields


      // const isChrome = /Chrome/.test((navigator.userAgent || ''))
      if (!(_.isFunction(autocomplete) || _.isObject(autocomplete))) {
        if (this.useAutocompleteOffFix) {
          if (!autocomplete) {
              autocomplete = 'off'
          }
          if (['off', 'none'].includes(autocomplete)) {
            autocomplete = _.uniqueId('no-autofill-')
          }
          name =  _.uniqueId(`field_${name}_no_autofill_`)
        }
      }

      return {
        name,
        autocomplete: fv(autocomplete, this.autocomplete, null)
      }
    },
    extraInputAttrs() {
      return {
        placeholder: fv(this.placeholder, null),
      }
    },
    omitInputAttrs() {
      return ['required']
    },

    allAttrs() {
      return {...this.$props, ...this.$attrs}
    },

    validationAttrs() {
      return {
        ..._.pick(this.allAttrs, validationAttrs),
        value: this[this.innerValueName || 'innerValue'],
        isDirty: this.isDirtyState,
        tabindex: null
      }
    },
    inputClasses() {
      return [this.inputClass]
    },

    model: {
      get() {
        return this[this.innerValueName || 'innerValue']
      },
      set(value) {
        this[this.innerValueName || 'innerValue'] = value
        this.$emit('input', value)
      }
    },

    parsedAttrs() {
      return this.parseDynamicAttrs(this.parseAttrs || {})
    },
    parsedHint() {
      return fv(this.parsedAttrs.hint, this.hint)
    },
    parsedShowHint() {
      return fv(this.parsedAttrs.showHint, this.showHint)
    }
  },

  watch: {
    value(newValue) {
      this[this.innerValueName || 'innerValue'] = newValue
    },
    isDirtyState(dirty) {
      if (dirty) {
        this.$emit('dirty', this[this.innerValueName || 'innerValue'])
      }
    }
  },

  created() {
    const autocomplete = this.autocomplete
    if (!_.isNil(autocomplete)) {
      if (_.isFunction(autocomplete)) {
        autocomplete.call(this)
      } else {
        if (_.isObject(autocomplete)) {
          F.autocomplete(autocomplete, this)
        }
      }
    }
  },

  methods: {
    hasSlot,
    onInput(event) {
      this.isDirtyState = true
      if (!this.lazy) {

        let isEvent = event instanceof Event
        if (this.isDesignerMode) {
          isEvent = true
          this.model = event?.target?.value ?? event
        } else {
          if (isEvent) {
            this.model = event.target.value
          } else {
            this.model = event
          }
        }
      }
    },
    onChange(event) {
      this.isDirtyState = true
      if (this.lazy) {
        if (event instanceof Event) {
          this.model = event.target.value
        } else {
          this.model = event
        }
      }
    },
    onEnter(event) {
      this.$emit('enter', event)
    },
    getLabelExtraClasses() {
      return []
    },
    getDescriptionExtraClasses() {
      return []
    },
    getDescriptionClasses() {
      return [
        're-input-description',
        ...this.getDescriptionExtraClasses(),
      ]
    },
    getLabelClasses() {
      const attr = this.$$attrs() || {}
      return [
        're-input-label',
        ...this.getLabelExtraClasses(),
        {
          required: attr.required
        }
      ]
    },
    parseDynamicAttrs(attrs) {
      const values = _.mapValues(attrs, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = {'this': this}
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
  }
}

