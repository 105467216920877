import editProps from '../../../../../props/profile/edit-props'
import pageProps from '../../../../../props/profile/page-props'

export default {
  ...pageProps,
  ...editProps,

  cards: {},

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Transfer Balance')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  errorMessage: {
    type: String,
    default: null,
  },
  buttonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.submit.title', 'Transfer')
    }
  },
  buttonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.submit.classes', null)
    }
  },
  showCancelButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s(
        'button.cancel.show',
        this.$options.$$s('button.cancel', true)
      )
    }
  },
  cancelButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.cancel.title', 'Cancel')
    }
  },
  cancelButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.cancel.classes', null)
    }
  },
  from: {},
  to: {},
  balance: {},
  inputs: {},
  filterCardByLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('filterCardByLocation', true)
    }
  },
  mapBalanceField: {
    type: [String],
    default() {
      return this.$options.$$s('mapBalanceField', 'cash')
    }
  },
  balanceConversionFactor: {
    type: [String],
    default() {
      return this.$options.$$s('balanceConversionFactor', 1)
    }
  },
  distributeBalanceFields: {
    type: [String, Array],
    default() {
      return this.$options.$$s('distributeBalanceFields', null)
    }
  },
  transferLimitDefs: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('transferLimits', {balance: 'cash'})
    }
  },
}
