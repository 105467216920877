<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center

      re-toast-alert.m-b-40(v-if="showLoggedOutMessage" type="warning" ) You have been logged out

      .b-central__title Sign in to Rocket Effect
      .b-central__subtitle Enter your details below.

      form(@submit.prevent="submit")
        .b-central__panel.b-central__panel--w400.b-central__panel--center
          form-group(:validator="$v.email" label="Email address:" attribute="email" v-slot="props" :messages="{ backendError }")
            input(
              v-model="$v.email.$model"
              :class="props.classes"
              type="email"
              placeholder="Your email address"
              @input="input"
            )
          form-group(:validator="$v.password" label="Password:" attribute="password")
            template(v-slot:default="props")
              input(
                v-model="$v.password.$model"
                :class="props.classes"
                type="password"
                placeholder="Your password"
                @input="input"
              )
            template(v-slot:after)
              .media-non-mobile
                .form-text.text-right
                  router-link.text-muted(:to="{ name: 'onboarding-password-forgot' }") Forgot your password?

        .b-central__footer.b-central__footer--wide-btn.b-central__footer--before-divider
          v-button.btn.btn-almost-sm.btn-primary(type="submit" :loading="submitting" :disabled="$v.$dirty && $v.$invalid") Sign in

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        .b-central__divider
          span or

      .b-central__footer.b-central__footer--wide-btn.m-b-15
        button.btn.btn-almost-sm.btn-primary.btn-facebook(@click.prevent="social('facebook')") Sign in with Facebook
          img.btn-icon-left(:src="`${config.baseUri}/images/client/saas-onboarding/facebook-icon.svg`")

      .b-central__footer.b-central__footer--wide-btn
        button.btn.btn-almost-sm.btn-primary.btn-google(@click.prevent="social('google')") Sign in with Google
          img.btn-icon-left(:src="`${config.baseUri}/images/client/saas-onboarding/google-icon.svg`")

      .b-central__message.text-danger.m-t-25(v-if="socialBackendError") {{ socialBackendError }}

      .media-mobile
        .form-text.b-central__forgot-password-btn
          router-link.text-muted(:to="{ name: 'onboarding-password-forgot' }") Forgot your password?


</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

import REToastAlert from 'utils/vue/components/elements/re-toast-alert'
Vue.component(REToastAlert.name, REToastAlert)

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    password: '',
    backendError: null,
    socialBackendError: null,
    submitting: false,
    showLoggedOutMessage: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
    password: {
      required,
    },
  },

  created() {
    if (this.showLoggedOutMessage = 'logged-out' in this.$route.query) {
      const query = Object.assign({}, this.$route.query)
      delete query['logged-out']
      this.$router.replace({ query })
    }
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true

        const { data: { access_token: token } } = await axios.post(`${this.config.baseUri}/api/clients/api/customer/login`, {
          username: this.email,
          password: this.password,
        })

        await this.redirect(token)
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    async redirect(token) {
      this.$store.dispatch('saveToken', { token })

      const { data: { data: { tenants: { data: tenants } } } } = await axios.get(`${this.config.baseUri}/api/onboarding/auth/user?include=tenants`)

      if (tenants.length) {
        this.$router.push({ name: 'onboarding-instance-select' })
      } else {
        this.backendError = 'These credentials do not match our records'
      }
    },

    async social(provider) {
      try {
        const response = await this.$auth.authenticate(provider)

        const { data: { data: { access_token: token } } } = await this.$http.post(`${this.config.baseUri}/api/onboarding/auth/social/${provider}`, response)

        await this.redirect(token)
      } catch (e) {
        this.socialBackendError = getFirstErrorMessage(e)
      }
    },

    input() {
      this.backendError = null
      this.socialBackendError = null
    }
  },
}
</script>
