let uid = 0
export const getQS = (keys) => {
  let qs = {}
  let search = (window.location.search || '')
    .replace(/^\?/, '') + '&'
  search
    .replace(/^\?/, '')
    .replace(/([^=]+?)=(.+?)&/g, (f, n, v) => qs[n] = decodeURIComponent(v))
  if (keys) {
    pick(qs, keys)
  }
  return qs
}
export const uniqueId = (prefix) => (prefix || '') + (++uid)
export const type = v => typeof v
export const isNil = v => v === undefined || v === null
export const fv = (...args) =>  args.find(v => !isNil(v))
export const isNumber = v => v * 1 === v
export const isString = v => type(v) === 'string'
export const isObject = v => !isNil(v) && type(v) === 'object'
export const isArray = v => (v) instanceof Array
export const isFunction = v => type(v) === 'function'

export const values = a => Object.values(a)
export const indexOf = (a, n) => a.indexOf(n)

export const cases = (v) => {
  v = isNil(v) || isFunction(v) || isObject(v) ? '' : (v+'')
  return v
    .split('')
    .map((letter, index) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
}
export const snakeCase = v => {
  return cases(v).replace(/-/g, '_').replace(/\s+/g, '_')
}
export const kebabCase = v => {
  return cases(v).replace(/_/g, '-').replace(/\s+/g, '-')
}

export const reduce = (o, c, a = {}) => {
  const entries = Object.entries(o || {})
  entries.forEach(([key, value]) => {
    a = c(a, value, key, o)
  })
  return a
}

export const mapValues = (o, c) => {
  const entries = Object.entries(o || {})
  let newO = isArray(o) ? [] : {}
  entries.forEach(([key, value]) => {
    newO[key] = isString(c) ? get(value, c) : c(value, key, o)
  })
  return newO
}

export const mapKeys = (o, c) => {
  const entries = Object.entries(o || {})
  let newO = {}
  entries.forEach(([key, value]) => {
    const newKey = isString(c) ? get(value, c) : c(value, key, o)
    newO[newKey] = value
  })
  return newO
}

export const forOwn = (o, c) => {
  const entries = Object.entries(o || {})
  entries.forEach(([key, value]) => {
    const ret = c(value, key, o)
    if (ret === false) {
      return o
    }
  })
}

export const get = (o, p, d) => {
  const paths = (p || '').split('.')
  let v = null
  let data = o
  let key
  while ((key = paths.shift()) !== undefined) {
    if (hasOwn(data, key)) {
      v = data[key]
    } else {
      return d
    }
    data = v
  }
  return v
}

export const set = (o, p, v) => {
  const paths = (p || '').split('.')
  let oo = o
  let data = o
  let key
  while ((key = paths.shift()) !== undefined) {
    if (hasOwn(oo, key)) {
        oo = data[key]
    } else {
      if (!paths.length) {
        data[key] = v
      } else {
        oo = data[key] = {}
      }
    }
    data = v
  }
  return v
}

export const hasOwn = (o, p) => {
  if (o.hasOwn) {
    return o.hasOwn(p)
  }
  if (o.hasOwnProperty) {
    return Object.prototype.hasOwnProperty.call(o, p);
  }
}

export const has = (o, p) => get(o, p) !== undefined

export const pick = (o, p) => {
  return p.reduce(o, (a, c) => {
    if (has(o, c)) {
      const v = get(o, c)
      set(a, c, v)
    }
    return a
  }, {})
}

const modules = {
  getQS,
  uniqueId,
  type,
  isNil,
  fv,
  isNumber,
  isString,
  isObject,
  isArray,
  isFunction,
  values,
  indexOf,
  cases,
  kebabCase,
  snakeCase,
  reduce,
  mapValues,
  mapKeys,
  forOwn,
  has,
  hasOwn,
  get,
  set,
  pick,
}
export default modules
window._lib = modules
