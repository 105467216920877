/*global _ */
import {parseDateTime} from '../../../lib/dates'
import {fv} from '../../../lib/utils'

export default {
  props: {
    min: {
      type: [String, Object, Number],
    },
    max: {
      type: [String, Object, Number],
    }
  },
  computed: {
    datepickerAttrs() {
      {
        const attrs = _.omit(this.$$attrs(), ['attr'])
        const max = attrs.maxDatetime = fv(this.max, attrs.maxDatetime)

        if (_.isString(max) || _.isNumber(max)) {
          const date = parseDateTime(max)
          if (date.isValid) {
            attrs.maxDatetime = date.toISODate()
          } else {
            _.unset(attrs, 'maxDatetime')
          }
        }

        const min = attrs.minDatetime = fv(this.min, attrs.minDatetime)
        if (_.isString(min) || _.isNumber(min)) {
          const date = parseDateTime(min)
          if (date.isValid) {
            attrs.minDatetime = date.toISODate()
          } else {
            _.unset(attrs, 'minDatetime')
          }
        }
        return attrs
      }
    }
  }
}
