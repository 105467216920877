export const SET_STORE_VALUE = 'SET_STORE_VALUE'
export const SET_BUILDER = 'SET_BUILDER'
export const SET_BUILDER_PROP = 'SET_BUILDER_PROP'

export const SET_VALUES = 'SET_VALUES'
export const SET_VALUE = 'SET_VALUE'

export const SET_VALIDATIONS = 'SET_VALIDATIONS'
export const SET_VALIDATION = 'SET_VALIDATION'

export const SET_CONFIGS = 'SET_CONFIGS'
export const SET_CONFIG = 'SET_CONFIG'

export const SET_FORM = 'SET_FORM'
export const SET_FORM_FIELDS = 'SET_FORM_FIELDS'
export const SET_FORM_FIELD = 'SET_FORM_FIELD'
export const SET_FORM_FIELD_PROP = 'SET_FORM_FIELD_PROP'
