/*global _ */
import {
  parseValidations,
  parseValidationSetup,
  setValidationRule,
  ValidationMixins,
  Validators
} from '../../../../../lib/validations'
const {helpers} = Validators

import {getFirstErrorMessage} from '../../../../../services/base'

import LanguageMixin from '../../../../../mixins/lang'
import SettingsMixin from '../../../../../mixins/settings'
import AlertMixin from '../../../../../mixins/alert'
import ServiceMixin from '../../../../../mixins/service'

const EventBus = window.VueEventBus

const validations = {
  number: setValidationRule('fields.number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Card code is required.'},
    digits: {error: 'Card code is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
}

export default {
  name: 're-add-card',
  props: {
    translations: {},
    settings: {},

    classes: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('classes', null)
      }
    },
    title: {
      type: String,
      default() {
        return this.$options.$$t('title', 'Add Card')
      }
    },
    showTitle: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('showTitle', true)
      }
    },
    subTitle: {
      type: String,
      default() {
        return this.$options.$$t('subTitle', null)
      }
    },
    showSubTitle: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('showSubTitle', false)
      }
    },


    errorMessage: {
      type: String,
      default() {
        return this.$options.$$t('errorMessage', null)
      }
    },
    addingMessage: { //@added:not-implemented
      type: String,
      default() {
        return this.$options.$$t('addingMessage', null)
      }
    },
    addedMessage: { //@added:not-implemented
      type: String,
      default() {
        return this.$options.$$t('addedMessage', 'Card has been added successfully')
      }
    },

    numberLabel: {
      type: String,
      default() {
        return this.$options.$$t('number.label', 'Card Number')
      }
    },
    numberPlaceholder: {
      type: String,
      // default: '',
      default() {
        return this.$options.$$t('number.placeholder', 'XXXX XXXX XXXX XXXX')
      }
    },
    numberClasses: {
      type: [String, Array, Object],
      // default: '',
      default() {
        return this.$options.$$s('number.classes', 'form-control')
      }
    },
    codeLabel: {
      type: String,
      default() {
        return this.$options.$$t('code.label', 'Code')
      }
    },
    codePlaceholder: {
      type: String,
      default() {
        return this.$options.$$t('code.placeholder', '****')
      }
    },
    codeClasses: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('code.classes', 'form-control')
      }
    },

    buttonTitle: {
      type: String,
      default() {
        return this.$options.$$t('button.title', 'Add Card')
      }
    },
    buttonClasses: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('button.classes', null)
      }
    },
    showCancelButton: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('cancelButton.show', true)
      }
    },
    cancelButtonTitle: {
      type: String,
      default() {
        return this.$options.$$t('cancelButton.title', 'Cancel')
      }
    },
    cancelButtonClasses: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('cancelButton.classes', null)
      }
    },

    isProcessing: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('isProcessing', null)
      }
    },
    hasResendCode: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('hasResendCode', null)
      }
    },
    resendCodeMessage: {
      type: String,
      default() {
        return this.$options.$$t('resendCodeMessage', 'Did you forget the Card code?')
      }
    },
    resendCodeLinkLabel: {
      type: String,
      default() {
        return this.$options.$$t('resendCodeLinkLabel', 'Resend code')
      }
    },
    hasHelpNumberLink: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('hasHelpNumberLink', true)
      }
    },
    helpNumberLinkLabel: {
      type: String,
      default() {
        return this.$options.$$t('helpNumberLinkLabel', 'What is this?')
      }
    },
    hasHelpCodeLink: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('hasHelpCodeLink', true)
      }
    },
    helpCodeLinkLabel: {
      type: String,
      default() {
        return this.$options.$$t('helpCodeLinkLabel', 'What is this?')
      }
    },

    standalone: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('standalone', false)
      }
    },
    cardType: {
      type: [String],
      default() {
        return this.$options.$$s('cardType', 'playcard')
      }
    }
  },

  mixins: [ValidationMixins, SettingsMixin, LanguageMixin, AlertMixin, ServiceMixin],

  data() {
    return {
      fields: {
        number: null,
        cvv: null,
      },
      processing: false,
      validations: parseValidationSetup(validations, this),
    }
  },
  validations:  { fields: parseValidations(validations) },

  watch: {
    isProcessing(val) {
      this.processing = val
    }
  },

  created() {
    this.$v.$reset()
    this.processing = this.isProcessing
    this.fields.number = null
    this.fields.cvv = null
    this.defineListeners()
  },

  computed: {
    inlineHelpImageSrc() {
      return this.$$s('attrs.inlineHelpImageSrc', this.$$s('inlineHelpImageSrc'))
    },
    isRequestError() {
      return !_.isEmpty(this.errorMessage)
    },
  },

  methods: {
    getFieldClass(field) {
      const def = this.$$s('form.fields.' + field, this.$$s('component.addCard.form.fields.' + field, {}))
      return [def.class || null, def.required !== false ? 'required' : null]
    },
    async addCard() {
      EventBus.$emit('card.before.add')
      this.formSubmitting = true
      this.$v.$touch()
      if (!this.isAnyInvalid) {
        this.processing = true
        const standalone = this.standalone
        if (standalone) {
          const payload = _.cloneDeep(this.fields)
          try {
            const service = `user.card.${this.cardType}.add`
            const card = await this.invokeService(service, payload)
            this.processing = false

            // this.$emit('card.added', card, {standalone})
            // EventBus.$emit('card.added', card, {standalone})
            this.$emit(`${this.cardType}.added`, card, {standalone})
            EventBus.$emit(`${this.cardType}.added`, card, {standalone})

            await this.alertAsync(this.addedMessage || 'Card has been added successfully')
          } catch (e) {
            const error = getFirstErrorMessage(
              e,
              this.$$t('error.message', 'Error adding card')
            )
            // this.$emit('card.add.error', e, payload, {standalone})
            // EventBus.$emit('card.add.error', e, payload, {standalone})
            this.$emit(`${this.cardType}.add.error`, error, e, payload, {standalone})
            EventBus.$emit(`${this.cardType}.add.error`, error, e, payload, {standalone})

            this.processing = false
            const title = this.$$t('error.title', null)
            await this.errorAlertAsync(error, title)
          }

        } else {
          const onSuccess = (...args) => {
            this.successCallback(...args)
          }
          const onError = (...args) => {
            this.errorCallback(...args)
          }
          this.$emit('card-add', this.fields, onSuccess, onError)
          EventBus.$emit('card.add', this.fields, onSuccess, onError)
        }
      }
    },
    successCallback(...args) {
      this.cancelThis(true, ...args)
    },
    errorCallback(...args) {

    },
    cancelThis(noCancelEvent, data = false, ...rest) {
      this.$v.$reset()
      this.formSubmitting = false
      this.$emit('close', data, ...rest)
      if (!noCancelEvent) {
        EventBus.$emit('card.add.cancel')
      }
    },
    resendCode() {
      this.$emit('card-open-resend-code')
      EventBus.$emit('card.open.resend.code')
    },
    whatIsCode() {
      this.$emit('card-open-info-code')
      EventBus.$emit('card.open.info.code')
    },
    whatIsNumber() {
      this.$emit('card-open-info-number')
      EventBus.$emit('card.open.info.number')
    },
    defineListeners() {
      EventBus.$on('card.added', (...args) => {
        this.successCallback(...args)
      })
      EventBus.$on('playcard.added', (...args) => {
        this.successCallback(...args)
      })
      EventBus.$on('playcard.add.error', (...args) => {
        this.errorCallback(...args)
      })
    }
  },
}
