/*global _ */
import {name, mixins, props, data, EventBus} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data
    }
  },

  computed: {

  },

  async created() {

  },

  mounted() {

  },
  methods: {

  },
}
