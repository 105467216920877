/*global _ */
const localProps = {
  translations: {
    type: [Object, Array, String],
    default: null
  },
  settings: {
    type: [Object, Array, String],
    default: null
  },
  value: {
    type: [Object, Array],
    default() {
      return _.merge(
        {},
        this.$options.$$t('value', {}),
        this.$options.$$s('value', {})
      )
    }
  },
  namespace: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('nameSpace', 're-embed-component')
    }
  },
  eventspace: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('eventspace', 're-embed-component')
    }
  },
  content: {
    type: [String, Object],
    default() {
      return this.$options.$$t('html', this.$options.$$s('html', null))
    }
  },
  js: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('js', null)
    }
  },
  jsUrls: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('jsUrls', null)
    }
  },
  css: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('css', null)
    }
  },
  cssUrls: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('cssUrls', null)
    }
  },
  // fontUrls: {
  //   type: [String, Array, Object],
  //   default() {
  //     return this.$options.$$s('fontUrls', null)
  //   }
  // },
  containerComponent: {
    type: [String],
    default() {
      return this.$options.$$s('components.container.name', 'div')
    }
  },
  containerComponentAttrs: {
    type: [Object],
    default() {
      return _.merge(
        {},
        this.$options.$$t('components.container.attrs', {}),
        this.$options.$$s('components.container.attrs', {})
      )
    }
  },
  containerComponentListeners: {
    type: [Object],
    default() {
      return this.$options.$$s('components.container.on', {})
    }
  },
  bodyComponent: {
    type: [String],
    default() {
      return this.$options.$$s('components.body.name', null)
    }
  },
  bodyComponentAttrs: {
    type: [Object],
    default() {
      return _.merge(
        {},
        this.$options.$$t('components.body.attrs', {}),
        this.$options.$$s('components.body.attrs', {})
      )
    }
  },
  bodyComponentListeners: {
    type: [Object],
    default() {
      return this.$options.$$s('components.body.on', {})
    }
  },
  headComponent: {
    type: [String],
    default() {
      return this.$options.$$s('components.head.name', null)
    }
  },
  headComponentAttrs: {
    type: [Object],
    default() {
      return _.merge(
        {},
        this.$options.$$t('components.head.attrs', {}),
        this.$options.$$s('components.head.attrs', {})
      )
    }
  },
  headComponentListeners: {
    type: [Object],
    default() {
      return this.$options.$$s('components.head.on', {})
    }
  },
  footComponent: {
    type: [String],
    default() {
      return this.$options.$$s('components.foot.name', null)
    }
  },
  footComponentAttrs: {
    type: [Object],
    default() {
      return _.merge(
        {},
        this.$options.$$t('components.foot.attrs', {}),
        this.$options.$$s('components.foot.attrs', {})
      )
    }
  },
  footComponentListeners: {
    type: [Object],
    default() {
      return this.$options.$$s('components.foot.on', {})
    }
  },

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', null)
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
}
export const props = {
  ...localProps
}
export default props
