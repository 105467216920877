import component from '../../props/component'
import form from '../../props/form'
import processing from '../../props/processing'

const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', '')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading')
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
