import {getProductById} from '../../lib/store/product'

export default {
  computed: {
    selectedUpsellProduct() {
      const product = this.selectedProduct && this.selectedProduct.upsellProduct
        && (getProductById(this.selectedProduct.upsellProduct))
      if (product && this.applyPointsOnProducts) {
        this.applyPointsOnProducts([product])
      }
      return product
    }
  },
  created() {
    this.$$on('cart-rule-action:replace_product', async (action, args) => {
      const {item: {attributes: {product} = {}} = {}} = args || {}
      if (product && this.applyPointsOnProducts) {
        this.applyPointsOnProducts([product])
      }
    })
  }
}
