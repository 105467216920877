/*global _, addiesaas, $, debugLog */
import {nil, empty} from '../../lib/formatters'

export const extendFn = (existingFunction, newFunction) => {
  if (_.isFunction(existingFunction)) {
    const addedFunction = newFunction
    newFunction = function (...args) {
      const val = existingFunction(...args)
      if (val) {
        return val
      }
      return addedFunction(...args)
    }
  }
  return newFunction
}

export const findKeyDeep = (data, needle, onlyIn = []) => {
  if (_.isMatch(data, needle)) {
    return ''
  }
  let path = _.findKey(data, needle)
  if (nil(path)) {
    let newData = _.pick(data, onlyIn)
    if (!empty(newData)) {
      _.forOwn(newData, (datum, i) => {
        const foundPath = findKeyDeep(datum, needle, onlyIn)
        if (!nil(foundPath)) {
          path = `${i}.${foundPath}`
          return false
        }
      })
    }
  }
  return path
}

export const builderEmit = function (eventName, ...params) {
  const editor = this || {}
  if (_.isFunction(editor.trigger)) {
    editor.trigger(eventName, ...params)
  }

  const getVM = _.get(editor, 'Config.getVM')
  if (getVM) {
    const vm = getVM()
    if (vm) {
      vm.emit(eventName, ...params)
    }
  }
}

export const builderComponentEmit = function (eventName, ...params) {
  const {component, editor, vm} = this || {}
  // editor and builderVue event for generic event name
  builderEmit.call(editor, eventName, ...params)
  if (_.isObject(component)) {
    const type = (component.get && component.get('type')) || component.type
    const vueName = (component.get && component.get('componentName')) || component.componentName
    const baseType = component.get && component.get('builder-type') || component['builder-type']
    const vueEventName = vueName && `vue::${vueName}::${eventName}`;

    // editor and builderVue event for generic event name with vue::<vue-componentName> prefix
    if (vueEventName) {
      builderEmit.call(editor, vueEventName, ...params)
    }
    if (type) {
      // editor and builderVue event for generic event name
      builderEmit.call(editor, eventName + '::' + type, ...params)
      // editor and builderVue event for generic event name with vue::<vue-componentName> prefix
      if (vueEventName) {
        builderEmit.call(editor, vueEventName + '::' + type, ...params)
      }
    }
    if (baseType) {
      // editor and builderVue event for generic event name
      builderEmit.call(editor, eventName + '::' + baseType, ...params)
      // editor and builderVue event for generic event name with vue::<vue-componentName> prefix
      if (vueEventName) {
        builderEmit.call(editor, vueEventName+ '::' + baseType, ...params)
      }
    }
    if (vueEventName && vm && vm.$emit) {
      // vue-component's own Vue event having name with vue::<vue-componentName> prefix
      vm.$emit(vueEventName, ...params)
    }
  }
}

export const getBuilderComponentEmitter = function (vm) {
  return (eventName, ...args) => {
    const arg = _.first(args)
    arg.vm = vm
    builderComponentEmit.call(arg, eventName, ...args)
  }
}

export default {extendFn, findKeyDeep, builderEmit, builderComponentEmit, getBuilderComponentEmitter}
