/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DatePickerMixin from '../../../../mixins/form/custom-field-properties/date-picker'
import DesignerMixin from './designer'
const mixins = [InputMixin, DatePickerMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

import {formatDate, Settings, TIMEZONE, locale, DateTime, parseDateTime} from '../../../../lib/dates'
import {fv, nil} from '../../../../lib/formatters'
Settings.defaultLocale = locale || 'en-us'

import DatetimePicker from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 're-date-inputs',
  components: {DatetimePicker},
  mixins,
  props:{
    ...props,
    value: {
      type: [String, Object, Boolean],
      default: null
    },
    label: {
      type: String,
      default: "Select Date"
    },

    showInputFields: {
      type: [Boolean, Number, String],
      default: false,
    },
    inputFieldsOrder: {
      type: [Array, String],
      default: 'month,day,year',
    },
    showPickerButton: {
      type: [Boolean, Number, String],
      default: true,
    },
    outputFormat: {
      type: [String, Boolean, Number],
      default: 'yyyy-MM-dd',
    },
    timeZone: {
      type: String,
      default: TIMEZONE
    },
    popupTarget: {
      type: [String, Object],
      default: null
    },
    allowInvalid: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data() {
    return {
      innerValueName: 'selectedDate',
      selectedDate: null,
      fields: {
        month: null,
        day: null,
        year: null,
        zone: TIMEZONE
      }
    }
  },

  created() {
    this.selectedDate = this.convertValueToSelectedDate()
  },

  watch: {
    value(newValue) {
      this.selectedDate = this.convertValueToSelectedDate(newValue)
    },
    selectedDate(newValue, oldValue) {
      newValue = this.convertValueToSelectedDate(newValue)
      if (newValue && (oldValue !== newValue)) {
        const date = parseDateTime(newValue)
        if (date && date.isValid) {
          this.setDateInputs(date)
        }
        this.emitValue(newValue)
      }
    },
    'fields.day'(val) {
      if (this.inputDateValid) {
        this.setSelectedDate()
      }
    },
    'fields.month'(val) {
      if (this.inputDateValid) {
        this.setSelectedDate()
      }
    },
    'fields.year'(val) {
      if (this.inputDateValid) {
        this.setSelectedDate()
      }
    },
    inputDateValid(isValid) {
      if (nil(this.inputDate)) {
        this.emitValue(null)
      }
      if (!isValid) {
        this.emitValue(this.allowInvalid ? this.inputDate : null)
      }
    }
  },

  computed: {
    inputFields() {
      let fields = {}
      if (this.showInputFields) {
        const placeholders = this.inputFieldsPlaceholders || []
        const masks = {
          month: '##',
          day: '##',
          year: '####',
        }
        const order = this.inputFieldsOrderArray
        fields = _.reduce(order, (result, key, index) => {
          const Key = _.startCase(key)
          result[key] = {
            name: `input${Key}`,
            mask: masks[key],
            placeholder: placeholders[index] || '',
            tabindex: this.tabindex || this.$attrs['tabindex'] || null,
            class: [
              `re-date-input-${key}`,
              this.inputClass,
              this.hasError && this.fieldHasError(key) ? this.inputErrorClass : ''
            ],
            readonly: this.isReadonly,
            disabled: this.isDisabled
          }
          return result
        }, {})
      }
      return fields

    },
    inputFieldsOrderArray() {
      let order = this.inputFieldsOrder
      if (!order) {
        order = ['month', 'day', 'year']
      }
      if (_.isString(order)) {
        order = order.toLowerCase().replace(/\s/g, '').split(',')
      }
      return order
    },
    inputFieldsPlaceholders() {
      let items = fv(this.placeholder, this.inputFieldsOrderArray)
      if (_.isNil(items)) {
        items = ['month', 'day', 'year']
      }
      if (_.isString(items)) {
        items = items.toLowerCase().replace(/\s/g, '').split(',')
      }
      return items
    },
    inputId() {
      return this.id || this.name
    },
    inputMonthError() {
      return _.isEmpty(this.fields.month) || this.fields.month > 12
    },
    inputDayError() {
      return _.isEmpty(this.fields.day) || this.fields.day > 31
    },
    inputYearError() {
      return _.isEmpty(this.fields.year) || this.fields.year.length < 4
    },
    inputDate() {
      let date = false
      const {day = null, month = null, year = null} = this.fields
      if (nil(_.trim(day)) && nil(_.trim(month)) && nil(_.trim(year))) {
        return null
      }
      if (day && month && year) {
        try {
          date = DateTime.fromObject(this.fields)
        } catch(e) {
          return false
        }
      }
      return date
    },
    inputDateValid() {
      return this.inputDate && this.inputDate.isValid
    }
  },
  async mounted() {
    if (this.popupTarget) {
      await this.$nextTick()
      const picker = this.$refs['popup-datepicker']
      const pickerEl = picker && picker.$el
      if (pickerEl) {
        const isParentBody = pickerEl.parentElement && pickerEl.parentElement.nodeName === 'BODY'
        if (!isParentBody) {
          let target = this.popupTarget
          if (!_.isObject(target)) {
            target = document.querySelector(target)
          }
          if (target) {
            target.appendChild(pickerEl)
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.popupTarget) {
      const picker = this.$refs['popup-datepicker']
      const pickerEl = picker && picker.$el
      if (pickerEl) {
        pickerEl.remove()
      }
    }
  },
  methods: {
    makeDirty() {
      this.isDirtyState = true
    },
    convertValueToSelectedDate(value) {
      value = _.isUndefined(value) ? this.value : value
      if (value && !_.isObject(value)) {
        if (value === 'false') {
          return false
        } else {
          return ('' + value).trim()
        }
    }
    },
    emitValue(value) {
      if (nil(_.trim(value))) {
        this.$emit('input', value)
        return
      }
      const date = parseDateTime(value)
      const isValid = date.isValid
      if (isValid) {
        if (this.outputFormat) {
          value = formatDate(value, this.outputFormat)
        }
      } else {
        if (this.allowInvalid) {
          value = false
        }
      }
      if (this.allowInvalid || isValid) {
        this.$emit('input', value)
      }
    },
    fieldHasError(key) {
      const Key = _.startCase(key)
      return this[`input${Key}Error`]
    },
    openDatePicker() {
      this.$refs['popup-datepicker'].$el.querySelector('.vdatetime-input').click()
    },
    setSelectedDate() {
      const date = this.inputDate
      if (this.inputDateValid) {
        this.selectedDate = date.toISO()
      }
    },
    setDateInputs(date) {
      this.fields.month = date && date.toFormat('M')
      this.fields.day = date && date.toFormat('d')
      this.fields.year =  date && date.toFormat('y')
    },
    getLabelExtraClasses() {
      return ['re-input-label-date-inputs']
    },
  }
}
