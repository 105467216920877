/* global _ */
export default {
  computed: {
    tableRepeat() {
      return _.get(this.options, 'repeater', _.get(this.options, 'useRepeater'))
    },
    tableRepeatOptions() {
      const repeat = this.tableRepeat
      return _.isObject(repeat) ? repeat : {}
    },
    isRepeater() {
      return !_.isEmpty(this.tableRepeatOptions)
    },
    repeatComponent() {
      return this.tableRepeatOptions.component || 're-content-sections'
    },
    useRepeaterRawData() {
      return this.tableRepeatOptions.useRawData
    },
    useColumnTransformer() {
      return this.tableRepeat === true || this.tableRepeatOptions.transformColumns
    },
    repeaterRows() {
      if (this.useRepeaterRawData) {
        return this.innerValue
      }
      return this.tableRows
    }
  },

  methods: {
    getRepeatComponentAttrs() {
      const options = this.tableRepeatOptions
      let attrs = options.attrs || {}
      if (this.useColumnTransformer) {
        attrs = _.merge({}, attrs, this.transformTableToRepeater())
      }

      return {
        context: this.context,
        ...attrs,
      }
    },
    getRepeatComponentListeners() {
      return {
        ...(this.tableRepeatOptions.listeners || {}),
      }
    },
    transformTableToRepeater() {
      let items = _.mapValues(_.cloneDeep(this.tableColumns), (item, key) => {
        if (!this.useRepeaterRawData) {
          item.valuePath = key
          item.format = null
        } else {
          if (item.key) {
            item.valuePath = item.key
          }
        }
        return item
      })
      if (this.useRepeaterRawData) {
        items = _.mapKeys(items, i => i.name)
      }
      const actions = _.reduce(this.tableColumns, (a, i, k) => {
        const actions = this.getRowActions(k)
        if (!_.isEmpty(actions)) {
          return _.mapValues(this.getRowActions(k), (action, key) => {
            action.component = this.getRowActionComponentAttrs(action, key)
            action.componentAttrs = this.getRowActionComponentListeners(action, key)
            action.on = this.getRowActionComponentListeners(action, key)
          })
        }
        return a
      }, {})
      return {
        parseData: true,
        sections: {
          row: {
            showItemTitle: true,
            items,
            actions
          }
        }

      }
    }
  }
}
