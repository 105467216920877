<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Forgot your password?
      .b-central__subtitle We’ll help you reset it and get back on track.

      .b-central__panel.b-central__panel--w400.b-central__panel--center.m-b-40
        form-group(
          :validator="$v.email"
          label="Email address:"
          attribute="email"
          v-slot="props"
          :messages="{ backendError }"
        )
          input(
            v-model="$v.email.$model"
            :class="props.classes"
            type="email"
            placeholder="Your email address"
            @input="input"
          )

      .b-central__footer.b-central__footer--navigation-btn
        a.btn.btn-almost-sm.btn-secondary(@click.prevent="$router.go(-1)") Back
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Continue

</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    backendError: null,
    submitting: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/auth/forgot-password`, {
          email: this.email,
        })
        this.$router.push({ name: 'onboarding-password-verify', query: { email: this.email } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
