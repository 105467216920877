export const props = {
  tableOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('table', {})
      const $t = this.$options.$$t('table', {})
      return _.merge({}, $t, $s)
    }
  },
  tableMergeOptions: {
    type: [Object],
    default() {
      const $s = this.$options.$$s('$table', {})
      const $t = this.$options.$$t('$table', {})
      return _.merge({}, $t, $s)
    }
  },
  paginate: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('table.paginate', true)
    }
  },
  pageSize: {
    type: [Number, String],
    default() {
      return this.$options.$$s('table.page.size', 10)
    }
  },
  pageStart: {
    type: [Number, String],
    default() {
      return this.$options.$$s('table.page.start', 0)
    }
  }
}
export default props
