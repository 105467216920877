/*global _ */
import {getLoyaltyRewards} from '../../../lib/profile/loyalty'
import {getLocationNameSync, currencyRounded, coerceBoolean, getLocationsFullSync, fv} from '../../../lib/formatters'

import CartMixin from '../../cart'
import ProfileBaseMixin from '../base'
import ParseMixin from './parse'
import RewardMixin from './reward'
import TierMixin from './tier'
import AchievementsMixin from './achievements'
import ClaimMixin from './claim'
import LocationMixin from './locations'

const POINT_FACTOR_PATH = 'loyalty_program.point_earning_ratio'
const LOCK_BELOW_PATH = 'loyalty_program.tier.lock_below_highest_unlocked'

const mixins = [
  ProfileBaseMixin,
  ParseMixin,
  RewardMixin,
  TierMixin,
  AchievementsMixin,
  ClaimMixin,
  CartMixin,
  LocationMixin
]

const USER_POINT_PATH = 'loyalty_program_points'

export default {
  mixins,
  data() {
    return {
      eventSpace: 'user.profile.loyalty',
      dataFilterSpace: 'user.profile.loyalty',
      pointPriceFactor: 1,
      lockBelowHighest: true,
      isMuting: false
    }
  },
  computed: {
    selectedLoyaltyLocationId: {
      set(val) {
        this.setLoyaltyLocationId(val)
      },
      get() {
        let id = _.get(this.storedProfileData, 'loyalty.location_id')
        if (!id) {
          id = this.currentLocationId
          this.setLoyaltyLocationId(id)
        }
        return id
      }
    },
    selectedLoyaltyLocationName() {
      return getLocationNameSync(this.selectedLoyaltyLocationId)
    },
    earnedLoyaltyLocationPoints() {
      return _.get(this.storedProfileData, 'loyalty.locationPoints') || []
    },
    earnedLoyaltyPoints() {
      if (this.selectedLoyaltyLocationId) {
        return this.earnedLoyaltyLocationPoints[this.selectedLoyaltyLocationId] || 0
      }
      return _.get(this.storedProfileData, 'loyalty.points') || 0
    },
    loyaltyBaseStatus() {
      const points = this.earnedLoyaltyPoints || 0
      const spent = this.getRewardPriceFromPoint(points) || 0
      const $spent = currencyRounded.call(this, spent, 'currencyRounded')
      const status = {
        points,
        spent,
        $spent,
        location_id: this.selectedLoyaltyLocationId,
        locationName: this.selectedLoyaltyLocationName,
      }
      const params = {status, vm: this}
      this.applyDataFilter('baseStatus', params)
      return params.status
    },
    loyaltyStatus() {
      const status = {
        ...this.loyaltyBaseStatus,
        tier: this.loyaltyTierStatus,
        achievements: this.loyaltyAchievementStatus,
      }
      const params = {status, vm: this}
      this.applyDataFilter('status', params)
      return params.status
    },
    hasUnlockedRewards() {
      return this.hasUnlockedAchievementRewards || this.hasUnlockedTierRewards
    },
  },
  watch: {
    [`user.${USER_POINT_PATH}`]: {
      handler(value, oldValue) {
        if (!_.isEqual(value,  oldValue)) {
          if (_.isObject(value)) {
            // value is having multiple locations
            this.updateProfileDataItem({'loyalty.locationPoints': value || []})
          } else {
            // value is single and not for multiple locations
            this.updateProfileDataItem({'loyalty.points': value || 0})
          }
        }
      },
      immediate: true
    },
    selectedLoyaltyLocationId(id) {
      this.initConfig()
    }
  },
  created() {
    this.initConfig()
    this.initLocation()
    this.watchCartItems()
  },
  methods: {
    initConfig() {
      const location = this.selectedLoyaltyLocationId
      const locations = getLocationsFullSync()
      this.pointPriceFactor = _.get(
        locations,
        `${location}.settings.${POINT_FACTOR_PATH}`,
        _.get(this.config, POINT_FACTOR_PATH, 1)
      )
      this.lockBelowHighest = coerceBoolean(
        _.get(
          locations,
        `${location}.settings.${LOCK_BELOW_PATH}`,
          _.get(this.config, LOCK_BELOW_PATH)
        )
      )
    },
    initLocation() {
      let id = this.selectedLoyaltyLocationId
      if (!id) {
        id = this.cartLocationId
        if (!id) {
          id = _.get(this.user, 'meta.preferred_location_id')
        }
        if (id) {
          this.setLoyaltyLocationId(id)
        }
      }
    },
    watchCartItems() {
      const setLocation = () => {
        const location = this.cartLocationId
        if (location && this.selectedLoyaltyLocationId !== location) {
          this.setLoyaltyLocationId(location)
        }
      }
      this.$$on('cart.item.added', setLocation)
      this.$$on('cart.item.updated', setLocation)
    },
    setLoyaltyLocationId(id) {
      this.updateProfileDataItem({'loyalty.location_id': id})
      this.updateProfileDataItem({'location_id': id})
    },
    async prepareRewards(force) {
      let rewards = this.loyaltyRewards
      const isLoading = _.get(this.storedProfileData, 'loading.loyalty')
      if (!isLoading && (force || _.isEmpty(rewards))) {
        await this.updateProfileDataItem({'loading.loyalty': true})
        rewards = getLoyaltyRewards.call(
          this,
          {
            status: this.loyaltyBaseStatus,
            parser: this.parseRewardItem,
            rewards: [...this.loyaltyRewards || []]
          }
        )
        await this.updateProfileDataItem({'loyalty.rewards': rewards})
        await this.$nextTick()
        this.updateProfileDataItem({'loading.loyalty': false})
      }
    },
    ignoreRewards(rewards) {
      _.forOwn(rewards, ({id}) => {
        _.forOwn(this.loyaltyRewards, (item, index) => {
          if (item.id === id) {
            const path = `loyalty.rewards.${index}.ignored`
            this.updateProfileDataItem({[path]: true})
          }
        })
      })
    },
    emitLoyaltyEvent(name, ...args) {
      this.emitSlim(name, ...args)
      if (this.type) {
        this.emitSlim(`type.${this.type}:${name}`, ...args)
      }
      if (this.mode) {
        this.emitSlim(`mode.${this.mode}:${name}`, ...args)
        if (this.type) {
          this.emitSlim(`type.${this.type}.mode.${this.mode}:${name}`, ...args)
        }
      }
      if (this.position) {
        this.emitSlim(`position.${this.position}:${name}`, ...args)
        if (this.type) {
          this.emitSlim(`type.${this.type}.position.${this.position}:${name}`, ...args)
        }
        if (this.mode) {
          this.emitSlim(`mode.${this.mode}.position.${this.position}:${name}`, ...args)
          if (this.type) {
            this.emitSlim(`type.${this.type}.mode.${this.mode}.position.${this.position}:${name}`, ...args)
          }
        }
      }
    },
    applyDataFilter(name, ...args) {
      const space = this.dataFilterSpace
      this.$$applyDataFilters(`${space}:${name}`, ...args)
      if (this.type) {
        this.$$applyDataFilters(`${space}.type.${this.type}:${name}`, ...args)
      }
      if (this.mode) {
        this.$$applyDataFilters(`${space}.mode.${this.mode}:${name}`, ...args)
        if (this.type) {
          this.$$applyDataFilters(`${space}.type.${this.type}.mode.${this.mode}:${name}`, ...args)
        }
      }
      if (this.position) {
        this.$$applyDataFilters(`${space}.position.${this.position}:${name}`, ...args)
        if (this.type) {
          this.$$applyDataFilters(`${space}.type.${this.type}.position.${this.position}:${name}`, ...args)
        }
        if (this.mode) {
          this.$$applyDataFilters(`${space}.mode.${this.mode}.position.${this.position}:${name}`, ...args)
          if (this.type) {
            this.$$applyDataFilters(`${space}.type.${this.type}.mode.${this.mode}.position.${this.position}:${name}`, ...args)
          }
        }
      }
    },
    toggleMute: _.debounce(async function (forceState) {
      if (this.isMuting) {
        return
      }
      try {
        this.isMuting = true
        const meta = 'mute_loyalty_reward_alerts'
        const newState = fv(forceState, !(_.get(this.user.meta, meta, false)))
        this.updateUser({meta: {[meta]: newState}})
        const id = this.user.id
        if (id) {
          await this.invokeService('user.update', id, {[meta]: newState})
        }
      } finally {
        this.isMuting = false
      }
    }, 1000, {leading: true})
  }
}
