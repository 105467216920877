export const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', '')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', '')
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },

  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },

  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.showLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.showPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.showFieldHint', true)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.options', {})
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.validateFieldOnDirty', false)
    }
  },
  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.submitError.showOnFields', true)
    }
  },
  showErrorMessageAboveForms: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.submitError.showAbove', false)
    }
  },
  showAllSubmitButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.buttons.submit.show', false)
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$s('form.buttons.submit.title', 'Submit')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.buttons.submit.classes', null)
    }
  },
  showAllResetButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.buttons.reset.show', false)
    }
  },
  resetButtonTitle: {
    type: String,
    default() {
      return this.$options.$$s('form.buttons.reset.title', 'Reset')
    }
  },
  resetButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.buttons.reset.classes', null)
    }
  },
  successTitle: {
    type: [String],
    default() {
      return this.$options.$$t('success.title', null)
    }
  },
  successSubTitle: {
    type: [String],
    default() {
      return this.$options.$$t('success.subTitle', null)
    }
  },
  successMessage: {
    type: [String],
    default() {
      return this.$options.$$t('success.message', null)
    }
  },
  showSuccessPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('success.showPopup', true)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Updating...')
    }
  },
}

export default localProps
