<template lang="pug">
  div
    img.b-logo-bg(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.lg.svg`")
    .b-central.b-central--mobile
      .b-central__content.b-central__content--center
        img.m-t-40.m-b-15(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.sm.svg`")
        .b-central__panel.b-central__panel--w260.b-central__panel--center
          .b-central__title.b-central__title--sm.text-center.m-b-30-f Please enter your new password

          form-group(
            :validator="$v.password"
            label="Password:"
            attribute="password"
            v-slot="props"
            :messages="{ backendError }"
          )
            input(
              v-model="$v.password.$model"
              :class="props.classes"
              type="password"
              placeholder="Your new password"
              @input="input"
            )

          form-group.m-b-30-f(
            :validator="$v.passwordConfirmation"
            label="Confirm password:"
            attribute="password confirmation"
            v-slot="props"
            :messages="passwordConfirmationMessages"
          )
            input(
              v-model="$v.passwordConfirmation.$model"
              :class="props.classes"
              type="password"
              placeholder="Confirm your new password"
              @input="input"
            )

          v-button.btn.btn-lg.btn-block.btn-primary.m-b-20(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Set new password
          router-link.btn.btn-lg.btn-block.btn-secondary(:to="{ name: 'vouchermobilescanner-sign-in' }") Back to Sign In

</template>

<script>
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: null,
    password: '',
    passwordConfirmation: '',
    backendError: null,
    passwordConfirmationMessages: { 'sameAsPassword': 'Password doesn\'t match.' },
    submitting: false,
  }),

  validations: {
    password: {
      required,
      backendError() {
        return !this.backendError
      },
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },

  created() {
    this.email = this.$route.query.email
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        await axios.post(`${this.config.baseUri}/api/onboarding/auth/reset-password`, {
          email: this.email,
          code: this.$route.query.code,
          password: this.password,
        })
        this.$router.push({ name: 'vouchermobilescanner-sign-in' })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
