export default {
  events: 'card.check-balance',
  watch: {
    card: {
      deep: true,
      handler(value) {
        this.emitSlim('card', value, this)
      }
    }
  },
}
