import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data
    }
  },
  computed: {
    reward() {
      return this.value
    }
  },
  methods: {
    async triggerClaim($event) {
      const reward = this.reward
      if (reward.unlocked) {
        this.emitLoyaltyEvent('claim-reward', reward, $event, this)
      }
    }
  }
}
