/* global _ */
export default {
  computed: {
    tableConfig() {
      const defaultOptions = this.defaultTable
      let options = this.tableOptions || {}
      const mergeOptions = this.tableMergeOptions || {}
      if (!options.columns) {
        return _.merge({}, defaultOptions, options, mergeOptions)
      }
      return _.merge({}, options, mergeOptions)
    },
    tableComponent() {
      return this.tableConfig.component || 're-table'
    },
    tableComponentListeners() {
      return {}
    },
    tableComponentMainAttrs() {
      return {
        options: this.tableConfig,
        context: this
      }
    },
  },
}
