import Base from '../../../Base'

export class Plan extends Base {

  constructor(data, vm) {
    super(data, vm)
  }

  get id() {
    return this.data.id
  }

  get title() {
    return this.data.title || this.data.name
  }
  get code() {
    return this.data.code || this.data.display_name
  }


}

export default Plan
