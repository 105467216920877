import {Vuex} from '~/addiesaas'
const {createNamespacedHelpers} = Vuex
import {namespace} from '../../vuexstore'
import store, {namespace as moduleNamespace} from '../store'
const {mapGetters, mapActions} = createNamespacedHelpers([namespace, moduleNamespace].join('/'))

export default {
  computed: {
    ...mapGetters(Object.keys(store.getters))
  },
  methods: {
    ...mapActions(Object.keys(store.actions))
  }
}
