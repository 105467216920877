/*global _ */
import AutoLoadLocationMixin from '../../../../mixins/location-autoload'

export default {
  mixins: [AutoLoadLocationMixin],
  data() {
    return {
      autoLoadConfig: this.autoLoad
    }
  },
  watch: {
    async authenticated(value) {
      if (value) {
        await this.initAutoLoad()
        await this.autoLoadData(false)
      }
    }
  },
  async created() {
    this.$$on('profile.autoload.init.all', async (config) => {
      await this.autoLoadData(true, config)
    })
    if (this.authenticated) {
      await this.initAutoLoad()
      await this.autoLoadData(false)
    }
  },
  methods: {
    async initAutoLoad() {
      const hasAutoLoad = !!this.autoLoadConfig
      await this.updateProfileDataItem({'autoload': hasAutoLoad})
      if (!hasAutoLoad) {
        await this.updateProfileDataItem({'initialAutoloaded': true})
        await this.updateProfileDataItem({'loading.profile': false})
      }
    },
    async autoLoadData(silent = true, autoLoadConfig) {
      this.validateAuthCookie()
      let config = autoLoadConfig || this.autoLoadConfig
      if (config) {
        if (!this.authenticated || this.isAutoLoadingProfile) {
          return
        }
        if (_.isString(config)) {
          config = config.replace(/\s/g, '').split(',')
        }
        config = _.filter(config.map(name => {
          if (_.isFunction(name)) {
            return name
          }
          const method = _.camelCase('autoLoad_' + name)
          return this[method]
        }))
        if (!silent) {
          this.startPromising(this.loadingMessage)
        }
        try {
          if (!autoLoadConfig) {
            this.autoLoadConfig = config
          }
          await this.updateProfileDataItem({'loading.profile': true})
          const promises = config.map(method => method())
          // promises.unshift(this.autoLoadProfile)
          await Promise.all(promises)
        } finally {
          this.stopPromising()
          await this.updateProfileDataItem({'loading.profile': false})
          if (!this.isInitialAutoloaded) {
            await this.updateProfileDataItem({'initialAutoloaded': true})
          }
        }

        if (!autoLoadConfig && this.autoLoadFrequency) {
          let duration = this.autoLoadFrequency
          if (duration === true) {
            duration = 300
          }
          setTimeout(async () => {
            await this.autoLoadData()
          }, duration * 1000)
        }
      }
    }
  }
}
