import {
  setValidationRule,
} from '../../../../lib/validations'

export const defaultFormFields = {username: '', password: ''}

export const allValidationsRules = {
  username: setValidationRule('fields.username', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  password: setValidationRule('fields.password'),
}

export default {defaultFormFields, allValidationsRules}

