/*global _ */
export default {
  data() {
    return {
      step: 0,
      selectedReward: null
    }
  },
  computed: {
    claimerComponent() {
      const reward = this.selectedReward
      const baseName = 're-loyalty-reward-claim-general'
      if (reward) {
        const productType = reward.product.type
        const defaultName = _.kebabCase(`re-loyalty-reward-claim-${productType}`)
        const componentName = _.get(
          reward,
          'claim.component.name',
          this.$$s(`rewards.components.claim.${productType}.name`, defaultName)
        )
        return this.probeComponent(componentName, baseName)
      }
      return null
    },
    claimerComponentAttrs() {
      const reward = this.selectedReward
      let attrs = {}
      if (reward) {
        const productType = reward.product.type
        const rewardAttrs = _.omit(_.get(reward, 'claim.component', {}), ['name'])
        const settings = _.omit(_.merge({},
          this.$$t(`rewards.components.claim.${productType}`, {}),
          this.$$s(`rewards.components.claim.${productType}`, {}),
        ), ['name'])
        return {
          ...attrs,
          ...rewardAttrs,
          ...settings,
          value: reward,
          settings: this.$$s(`rewards.componentSettings.claim.${productType}`, this.$$$s),
          translations: this.$$t(`rewards.componentTranslations.claim.${productType}`, this.$$$t),
        }
      }
      return attrs
    }
  },
  watch: {
    authenticated(isAuth) {
      if (!isAuth) {
        this.$emit('close', false)
      }
    }
  },
  async created() {
    const options = this.options || {}
    if (options.standalone) {
      await this.confirmReward(_.first(this.value))
    }
  },
  methods: {
    async confirmReward(reward, $event) {
      this.selectedReward = _.cloneDeep(reward)
      this.emitLoyaltyEvent('claim-init', reward, $event, this)
      this.step = 1
    },
    async onSuccess(order) {
      this.emitLoyaltyEvent('claim-success', order, {...this.selectedReward}, this)
      setTimeout(() => {
        this.fetchUser()
      }, 0)
    },
    async onError(e) {
      this.emitLoyaltyEvent('claim-error', e, {...this.selectedReward}, this)
      this.selectedReward = null
      this.$emit('error', e, this)
    },
    async onCancel() {
      this.emitLoyaltyEvent('claim-cancel', {...this.selectedReward}, this)
      this.selectedReward = null
      this.dismiss()
    },
    async confirm(reward, $event) {
      this.selectedReward = null
      this.$emit('close', reward)
    },
    dismiss() {
      this.selectedReward = null
      this.$emit('close', false)
    },
    getComponentExtraClasses() {
      return [
        `confirm-step-${this.step}`,
      ]
    },
    async mute() {
      this.toggleMute(true)
      this.dismiss()
    }
  }
}
