/*global _ */
import addiesaas from '~/addiesaas'
import {setTimeoutAsync} from '../lib/utils'
const storePath = '$data.custom.locations'
const fnPath = '$fn.custom.locations'

export default {
  props: {
    autoLocations: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('locations.autoload', false)
      }
    },
    autoLocationsInterval: {
      type: [Number, String], // seconds
      default() {
        return this.$options.$$s('locations.autoloadInterval', 120)
      }
    }
  },
  data() {
    return {
      allLocationDetails: addiesaas.locations,

       // set to false in component to prevent mixin to define auto loader
      // but still can access the autoloaded allLocationDetails if autoloaded by
      // parent or any other component in the page
      canAutoLoadLocations: null
    }
  },
  async created() {
    if (!this.canAutoLoadLocations) {
      await this.registerLocationsFetcher()
    }
  },
  methods: {
    async registerLocationsFetcher(isImmediate) {
      const fetcher = addiesaas.$fn.findOrCreate(`${fnPath}.fetchAll`, async (callback) => {
        try {
          _.set(addiesaas, `${storePath}.fetching`, true)
          const all = await this.invokeService('locations.read')
          if (all) {
            _.forOwn(all, location => {
              this.$set(this.allLocationDetails, location.id, location)
              window.addiesaas.locations[location.id] = location
            })
          }
        } catch (e) {
          //
        }
        _.set(addiesaas, `${storePath}.fetching`, false)
        if (callback) {
          await callback()
        }
      })

      const fetchFrequently = addiesaas.$fn.findOrCreate(`${fnPath}.fetchFrequently`, async () => {
        const interval = addiesaas.$fn.findOrCreate(`${storePath}.fetchFrequency`, this.autoLocationsInterval)
        await setTimeoutAsync(interval * 1000)
        const fetching = addiesaas.$fn.findOrCreate(`${storePath}.fetching`, false)
        if (!fetching) {
          await fetcher(fetchFrequently)
        } else {
          await fetchFrequently()
        }
      })

      const canAutoLoad = this.canAutoLoadLocations = this.$$fv(this.canAutoLoadLocations, this.autoLocations)
      const registered = _.get(addiesaas, `${storePath}.fetchRegistered`)
      if (canAutoLoad) {
        if (isImmediate) {
          await fetcher()
        }
        if (!registered) {
          _.set(addiesaas, `${storePath}.fetchRegistered`, true)
          await fetchFrequently()
        }
      }
    }
  }
}

