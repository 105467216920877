export const name = 're-cart-billing'

// custom setup
import {countries as utilCountries, states as utilStates} from '../../../../../lib/utils'
export const countries = utilCountries
export const states = utilStates

// generic setup
import {allValidationsRules, defaultFormFields as formFields} from './form'
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../mixins/components/complex'
import FormMixin from '../../../../../mixins/form'
import CartMixin from '../../../../../mixins/cart'
export const mixins = [ComponentMixin, FormMixin, CartMixin]

export const EventBus = window.VueEventBus

// data
export const data = {
  fields: defaultFormFields,
  propDefs,
  sources: {
    countries,
    states
  },
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus,
  countries,
  states,
}
