/*global _ */
import {parseJSONString} from '../../lib/formatters'
import {hasTemplate} from '../../lib/utils'
import {RE} from '../../lib/config'

// init types
const REProducts = (() => {
  const products = RE.products || {}
  if (!products.typed) {
    products.types = _.mapValues(products.types || {}, (i, type) => {
      i = _.map(i, p => {
        p.type = p.type || type
        return p
      })
      return i
    })
    products.typed = true
  }
  return products
})()

import LocationMixin from '../location'
import ProductsFilterMixin from './products-filter'

const mixins = [LocationMixin, ProductsFilterMixin]

export default {
  mixins,
  data() {
    return {
      productCategories: RE.products?.categories || {}
    }
  },
  computed: {
    allProducts() {
      return this.getAllProducts(true)
    }
  },
  async created() {
  },

  methods: {
    getAllProducts(flatten = false) {
      const typed = REProducts.types || {}
      return flatten ? _.flatMap(typed) : typed
    },
    getProductsByLocation(location, products) {
      location = location || this.currentLocationId
      products = products || this.getAllProducts(true) || []
      return _.filter(products, ({location_id = null}) =>  location_id === location)
    },
    getFilteredProducts(filters) {
      const products = this.applySortingOnProducts(this.applyFilterOnProducts(filters))
      return products
    },
    applySortingOnProducts(products, orderBy = 'asc') {
      const MAX = Number.MAX_SAFE_INTEGER
      const orderAttribute = 'custom.order'
      const orderFactor = orderBy && orderBy !== 'asc' ? -1 : 1
      const sorted = products.sort((a, b) =>
        orderFactor * (_.get(a, orderAttribute, MAX) - _.get(b, orderAttribute, MAX))
      )
      return sorted
    },

    getProductItemProperty(key, defaultValue, item) {
      item = item || this.productItem || {}
      return _.get(item, key, defaultValue)
    },
    getProductType(item) {
      item = item || this.productItem || {}
      return this.getProductItemProperty('type', null, item)
    },
    getProductSubType(item) {
      item = item || this.productItem || {}
      return this.getProductItemProperty('subType', null, item)
    },
    getProductProvider(item) {
      item = item || this.productItem || {}
      return this.getProductItemProperty('provider', null, item)
    },
    getProductProviderType(item) {
      item = item || this.productItem || {}
      return this.getProductItemProperty('providerType', null, item)
    },
    getProductCategory(item) {
      item = item || this.productItem || {}
      const type = item.type
      return this.getProductItemProperty('category_slug', item[type + '_type'], item)
    },
    getProductCustomType(item) {
      item = item || this.productItem || {}
      return this.getProductItemProperty('custom.type', null, item)
    },
    getProductCustomProp(key, defaultValue, item) {
      item = item || this.productItem || {}
      const custom = this.getProductCustomProps(item, true)
      if (_.isUndefined(key)) {
        return custom
      }
      let val = _.get(custom, key, defaultValue)
      if (hasTemplate(val)) {
        return this.$$templify(val, item, ['{', '}'])
      }
      return val
    },
    getProductCustomProps(item, parsed) {
      item = item || this.productItem || {}
      let data = this.getProductItemProperty('custom', {}, item)
      if (parsed) {
        data = _.reduce(data, (d, i, k) => {
          _.set(d, k, parseJSONString(i))
          return d
        }, {})
      }
      const settings = this.$$s()
      return _.merge({}, settings, data)
    },
    getProductSlug(item, prefix = '', suffix = '') {
      item = item || this.productItem || {}
      if (prefix) {
        prefix += ' '
      }
      if (suffix) {
        suffix = ' ' + suffix
      }
      let itemName = (_.isString(item) ? item: (item.name || item.title || item.display_name))
      let name = _.kebabCase(prefix + itemName + suffix)
      return name
    },
    getProductTitle(item) {

    },
    getProductPrice(item) {

    },
    getProductDescription(item) {

    },
    getProductTags(item) {
      return this.getProductCustomProp('tags', [], item)
    },
    getProductListingProperty(key, defaultValue, item) {
      let path = 'listing'
      if (key) {
        path = path + '.' + key
      } else {
        if (_.isUndefined(defaultValue)) {
          defaultValue = {}
        }
      }
      return this.getProductCustomProp(path, defaultValue, item)
    },

    getProductEditRoute(item) {
      return this.getProductRoute(item)
    },
    getProductRoute(item) {
      item = item || this.productItem || {}
      const product = this.getProductSlug(item, item.id)
      return {name: 'shop.product', params: {product}}
    },

    openProductDetailsPage(item) {
      this.goto(this.getProductUrl(item))
    },
    getProductUrl(item) {
      item = item || this.productItem || {}
      const product = this.getProductSlug(item, item.id)
      return this.getRouteUrl('shop.product', {product})
    },
    openProductEditPage(item) {
      this.goto(this.getProductEditUrl(item))
    },
    getProductEditUrl(item) {
      item = item || this.productItem || {}
      const product = this.getProductSlug(item, item.id)
      return this.getRouteUrl('shop.product.edit', {product})
    },

    getProductBackgroundImageStyle(item) {
      item = item || this.productItem || {}
      const backgroundImage = this.showBackgroundImage ? this.getProductBackgroundImage(item) : null
      const styles = {}
      if (backgroundImage) {
        styles.background = `transparent url(${backgroundImage}) no-repeat center center`
        styles.backgroundSize = 'cover'
      }
      return styles
    },

    getProductImage(item) {
      item = item || this.productItem || {}
      return this.$$fs(
        _.get(item, 'image'),
        _.get(item, 'custom.image'),
        _.get(item, 'media.image'),
        this.$$s(`product.${item.type}.image.src`),
        this.$$s(`product.stub.image.src`),
        this.getProductItemProperty('media.image_route', null, item)
      )
    },
    getProductBackgroundImage(item) {
      item = item || this.productItem || {}
      return this.$$fv(
        _.get(item, 'background_image'),
        _.get(item, 'custom.background_image'),
        _.get(item, 'media.background_image'),
        this.$$s(`media.background_image`),
        this.$$s(`product.${item.type}.background_image.src`),
        this.$$s(`product.stub.background_image.src`)
      )
    },
    getProductImages(item, index) {
      item = item || this.productItem || {}
      const images = _.get(item, 'media.images', [])
      const image = this.getProductImage(item)
      if (_.isNil(index)) {
        return images
      }
      if (index === 'all') {
        return [image, ...images || []]
      }
      if (index === 'featured') {
        return image
      }
      if (index === 'background') {
        return this.getProductBackgroundImage(item)
      }
      return images[index] || image
    },

    getProductClasses(item, addedClasses) {
      return [
        `product-id-${item.id}`,
        `product-name-${this.getProductSlug(item)}`,
        ...this.getProductExtraClasses(item) || [],
        ...addedClasses || []
      ]
    },
    getProductExtraClasses(item) {
      const tags = _.map(this.getProductTags(item) || [], t => 'tag-' + _.kebabCase(t))
      return [
        tags
      ]
    },

    probeProduct(product) {
      product = product || this.item || this.product
      if (!_.isEmpty(product) && _.isObject(product)) {
        return product
      }
      let productName = product
      if (productName) {
        const locationId = this.currentLocationId
        product = this.getProductItemFromId(productName) || this.getProductItemFromName(productName, locationId)
      }
      return product
    },
    getProductItemFromId(id) {
      id = parseInt(id)
      const staticProduct = RE.products.product
      if (staticProduct && id && id === (1 * staticProduct.id)) {
        return staticProduct
      }
      return _.find(this.getAllProducts(true), p => (p.id + '') === (id + ''))
    },
    getProductItemFromName(name, location) {
      const products = this.getAllProducts(true)
      const slug = this.getProductSlug(name)
      let foundProduct = null
      if (name && slug) {
        foundProduct = _.find(products, p => {
          const pSlug = this.getProductSlug(p.name)
          return  p.name && pSlug === slug && (!location || location === p.location_id)
        })
      }

      return foundProduct || null
    },

  }
}
