import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
    }
  },
  computed: {
    latestReward() {
      return _.last(this.value)
    },
    hasNoLockedReward() {
      return !this.lockedTierRewards.length
    },
    rewardMessage() {
      const expiration = this.latestReward.expiration

      const expirationMessage = expiration ? this.parseReactiveValues(this.expirationMessage, {...this.latestReward}): ''
      return this.buildMessages.call(
        this,
        this.hasNoLockedReward ? this.topRewardMessageTemplate : this.rewardMessageTemplate,
        {latestReward: this.latestReward, expirationMessage}
      )
    },
    rewardConfirmationButtonTitle() {
      return this.buildMessages.call(this, this.confirmTitle)
    },
    rewardDeclineButtonTitle() {
      return this.buildMessages.call(this, this.declineTitle)
    },
  },
  methods: {
  }
}
