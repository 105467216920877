import addiesaas, {Vue} from '../../addiesaas'

const {$fn: {findOrCreate}} = addiesaas

export const DataFilterBus = window.VueDataFilterBus = window.VueDataFilterBus || new Vue()

export const applyDataFilters = (filterName, ...args) => {
  DataFilterBus.$emit(filterName, ...args)
}

export const addDataFilter = (filterName, callback) => {
  DataFilterBus.$on(filterName, callback)
}

const DataFilter = {
  add: addDataFilter,
  apply: applyDataFilters
}

findOrCreate(window, 'VueDataFilter', DataFilter, ({item, found}) => {
  if (!found) {
    Vue.prototype.$$addDataFilter = addDataFilter
    Vue.prototype.$$applyDataFilters = applyDataFilters
  }
})

findOrCreate(addiesaas, '$core.VueDataFilterBus', window.VueDataFilterBus)
findOrCreate(addiesaas, '$core.DataFilter', DataFilter)

export default DataFilter
