export default {
  name: 'validation-input',
  props: {
    //value:[String, Number],
    name: {},
    id: {},
    label: {},
    value: {},
    type: {default: 'text'},
    placeholder: {},
    groupClass: {default: 'form-field'},
    groupErrorClass: {default: 'is-invalid form-group-error'},
    inputClass: {default: 'form-control'},
    inputErrorClass: {default: 'is-invalid form-control-error'},
    errorClass: {default: 'invalid-feedback'},
    validations: {type: Object},
    modelName: {},
    validator: {},
    options: {type: Object},
    noElement: {type: Boolean, default: false},
    inputAttributes: {type: Object},
    tabindex: {
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {

    onInput(event) {
      const value = _.get(event, 'target.value', event)
      this.$emit('input', value)
      if (this.hasError) {
        this.$emit('inputError', event, {name: this.vName, value})
      }
    },
    vCheck() {
      const validator = this.validator || (this.validations && this.validations.validator)
      if (_.isFunction(validator)) {
        return validator(...arguments)
      }
      return null
    }
  },
  computed: {
    attr() {
      return this.inputAttributes || {}
    },
    hasError() {
      return _.some(this.vItems, (i, rule) => this.vCheck(this.vName, rule))
    },
    vName() {
      return this.validations.name
    },
    vItems() {
      return this.validations.items || {}
    },
  }
}
