export const name = 're-social-media-storage'
export const settings = 'components.social.media.storage.selector'
export const translations = 'components.social.media.storage.selector'
export const events = 'components.social.media.storage.selector'

import ComponentMixin from '../../../../../mixins/components/complex-reactive'
import AuthMixin from '../../../../../mixins/profile/social/auth'
import ErrorsMixin from './errors'
import socialConfig from '../../../../../lib/social/media/storage/config'
export {socialConfig}

export const mixins = [ComponentMixin, AuthMixin, ErrorsMixin]

import {props} from './props'
export {props}

export default {
  name,
  mixins,
  props,
  settings,
  translations,
  events,
  socialConfig
}
