import CartMixin from '../../../../../../mixins/cart'
// noinspection NpmUsedModulesInstalled
import GiftcardApplyMixin from '../../../../../../mixins/external/giftcard-apply'
import LanguageMixin from '../../../../../../mixins/lang'
import SettingsMixin from '../../../../../../mixins/settings'
import {EventBus, DataFilter} from '../../../../../../lib/utils'

const defaultCostDetailsInfo = {
  price: {
    label: 'Subtotal',
  },
  tax: {
    label: 'Tax',
  },
  fees: {
    hideZero: true,
    label: 'Fees',
  },
  shipping_fees: {
    hideZero: true,
    label: 'Shipping',
  },
  price_discount: {
    hideZero: false,
    label: 'Discount',
    showNegative: true,
  },
  discounts: {
    hideZero: false,
    label: 'Gift Card',
    showNegative: true,
  },
  total: {
    label: 'Total',
  },
  remaining: {
    label: 'Remaining amount',
    hideZero: true
  },
}

export default {
  name: 're-cart-summary',
  mixins: [CartMixin, GiftcardApplyMixin, LanguageMixin, SettingsMixin],
  props: {
    translations: {},
    settings: {},
    costDetailsInfo: {
      type: Object,
      default: null
    },
    costDetailsOrder: {
      type: [Array, Object],
      default: () => (['price', 'tax', 'fees', 'shipping_fees', 'discounts', 'price_discount', 'total'])
    },
  },

  computed: {
    cartCostDetails() {
      const lang = this.$$t('summary.sections', this.$$t('sections', this.costDetailsInfo || defaultCostDetailsInfo))
      const settings = this.$$s('summary.sections', this.$$s('sections', {}))
      const info = _.merge({}, defaultCostDetailsInfo, lang, settings)
      const sortOrder = _.get(settings, '$order', this.costDetailsOrder || [])
      const raw = this.costDetails || {}
      const hasDeposit = raw['deposit'] > 0
      const details = _.reduce(sortOrder, (details, key, index) => {
        const item = info[key]
        let value
        if ('values' in info[key]) {
          value = _.sumBy(info[key].values, x => (x.startsWith('-') ? -raw[x.substr(1)] : raw[x]))
        } else {
          value = raw[key]
        }
        if (hasDeposit && key === 'total') {
          value = value - (raw['remaining'] || 0)
        }

        if (item) {
          const isObject = _.isObject(value)
          const isZero = !isObject && !(1 * value)
          let valid = !_.isNil(value)
          if (item.hideZero) {
            valid = !isZero
          }
          if (item.hide) {
            valid = false
          }
          if (!isObject) {
            if (item.showNegative) {
              value = -1 * value
            }
          }
          if (valid) {
            details.push({
              ...item,
              key,
              value
            })
          }
        }
        return details
        }
        , [])

      DataFilter.apply('cart.summary.details', details, this.costDetails, this)

      return details
    },
    giftCardDiscountValue() {
      return this.giftcardsDiscount ? this.giftcardsDiscount(this.costDetails) : 0
    },
    showGiftCardDiscountPoint() {
      return this.giftCardDiscountValue < 0
    },
    giftCardList() {
      return this.giftcards(this.costDetails)
    },
    remainingValue() {
      return this.costDetails.deposit > 0 ? this.costDetails.remaining : 0
    },
    allDiscounts() {
      return _.get(this.costDetails || {}, 'discounts.data', [])
    },
    couponDiscounts() {
      return this.getDiscountsByType('coupon')
    }
  },
  methods: {
    async deleteCouponDiscount(coupon) {
      this.removeCartDiscount(coupon)
      await this.validateCart(true)
    },
    getDiscountsByType(discountType) {
      const appliedDiscounts = _.cloneDeep(_.filter(this.allDiscounts, ({type = ''}) => type === discountType))
      const ids = {}
      _.forOwn(appliedDiscounts, (item, index) => {
        const code = item.options.code
        const cartDiscount = _.first(_.values(_.pickBy(this.cartDiscounts || {}, (d, id) => {
          return !ids[id] && d.type === discountType && _.get(d, 'payload.options.code') === code
        })))
        if (cartDiscount) {
          const id = cartDiscount.id
          item.title = cartDiscount.title
          item.id = id
          ids[id] = true
        }
      })
      return appliedDiscounts
    }
  }
}
