export const name = 're-core-page-shop-buy-play-card'
export const component = 're-buy-playcard'

import {factory} from '../../../../../lib/page'

const props = {
  terms: {},
  item: {},
  token: {}
}

const def = {}
export default factory(name, {props, component, def})
