/*global _ */
/**
 *  Global Events: (eventSpace: profile.promotion-status) - can be customized via prop
 *
 * INCOMING Events (external components can fire these events to ask this component to check)
 *
 *    1. `profile.promotion-status.check`(event: Object) =>
 *      Component initiates checking.
 *       The argument event must be an object.
 *        `event.name`:String - an unique identifier.
 *        `event.count`:Number (default: null) - Number of times to check, null for infinite
 *        `event.interval`:Number (default: inherit this.checkInterval) - Check interval in seconds.
 *        `event.immediate`:Boolean (default: true) - will start checking immediately or after completing one interval
 *        `event.enabled`:Boolean (default: true) - No check performed if `false`
 *        `event.filter`:Function(data) - Apply filter on fetched promotion data (component's default filter will not work).
 *            The component is passed to the function as context (this).
 *        `event.show`:Function(data) - Check if
 *           The component is passed to the function as context (this).
 *
 *        `event.promotion`:Function(data) (default: null): Function
 *           The component is passed to the function as context (this).
 * *
 *
 *    2. `profile.promotion-status.checkOn`(event: Object) =>
 *      Component registers a listener for a global event `<event.name>` and when
 *        that global event if emitted the component initiates checking
 *       The argument event must be an object and same as thta of `profile.promotion-status.check` event
 *
 *
 *  Fired Events:
 *    1. Global Async Event: `profile.promotion-status.beforeShow`(promotion: Object, getVM: Function) - fires before
 *        If the returned value of the event listener is `false` or an object with `preventDefault: true`, the popup is not shown
 *
 *    1. `profile.promotion-status.show`(promotion: Object, getVM: Function) - fires when popup is shown
 *    2. `profile.promotion-status.close`(promotion: Object, getVM: Function) - fires when popup is closed
 *
 *
 * Services Used:
 * 1. Fetch promotions: GET /api/customer/user/promotion (alias: `user.promotions.get`)
 * 2. Update user meta: PUT /api/user/{id} (alias: `user.update`)
 *    Updates meta - `seen_promotion_claimed_notifications` - array of promotion ids <markSeenAfter> seconds after
 *                      popup is shown or after closing the popup
 *
 */
import {Vue} from '~/addiesaas'
import ComponentMixin from '../../../../mixins/components/complex-reactive'
import PromotionMixin from './promotion'
import {setTimeoutAsync, fv} from '../../../../lib/utils'

export default {
  name: 're-promotion-status',
  mixins: [ComponentMixin, PromotionMixin],
  settings: 'page.profile.promotion-status',
  translations: 'page.profile.promotion-status',
  events: 'profile.promotion-status',
  props: {
    settings: {},
    translations: {},
    disabled: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('disabled', true) // disabled by default
      }
    },
    checkInterval: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('checkInterval', 600) // seconds
      }
    },
    checkEvents: {
      /*
        Object: {<eventName>: {interval: 10, count: 5, enabled: true, immediate: true}}
          Object Properties same as `event` object of `profile.promotion-status.check` event (check doc above)

        Object: {<eventName>: false/true} - enabled or disabled with default values

        Array: eventNames ['a', 'b']
            or array of Objects [{name: '<eventName>', interval, count, enabled, immediate}]

        String: comma-separated event names

        NOTE: Check starts after a tick
             Special eventName - `created` and `auth` are set as defaults events to start checking.
             These can be disabled from this prop by setting `false` or enabled: false
       */
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('checkEvents', [])
      }
    },
    skipPages: {
      default() {
        return this.$options.$$s('skipPages', [
          'sign-in',
          'rewards.claim',
          'user.register',
          'user.verification',
          'user.password.reset'
        ])
      }
    }
  },
  data() {
    return {
      isDisabled: this.disabled,
      timeoutIds: [],
      registeredEvents: {},
      checkingEvents: {}
    }
  },
  computed: {
    eventsToCheck() {
      const defaults = {created: {name: 'created'}, auth: {name: 'auth'}}
      const events = this.checkEvents || []
      return _.reduce(events, (items, event, key) => {
        if (_.isString(event)) {
          event = {name: event}
        }
        if (_.isBoolean(event)) {
          event = {name: key, enabled: event}
        }
        const eventName = event.name = event.name || key
        items[eventName] = event
        return items
      }, defaults)
    },
    isAuthenticated() {
      return this.authenticated && this.user && this.user.verified
    },
    canFetch() {
      // return this.isAuthenticated && !this.isDisabled && !this.isPromising && !this.showingNotification
      return this.isAuthenticated && !this.isDisabled && !this.showingNotification
    }
  },
  watch: {
    async showingNotification(value) {
      if (!value) {
        await this.fetchForEvent('created', false)
      }
    },
    async isAuthenticated(value) {
      if (value) {
        await this.fetchForEvent('auth')
      } else {
        this.clearTimeouts()
      }
    }
  },
  beforeDestroy() {
    this.clearFetchTimeout()
  },
  async created() {
    this.maySkipPage()
    await this.setupEventChecks()
  },
  methods: {
    canCheckEvent(event) {
      if (_.isString(event)) {
        event = this.eventsToCheck[event]
      }
      return !!_.get(event, 'enabled', event !== false)
    },
    maySkipPage() {
      const skip = _.some(this.skipPages, name => this.isRoute(name))
      if (skip) {
        this.isDisabled = true
      }
    },
    clearTimeouts() {
      _.forOwn(this.timeoutIds, id => {
        clearTimeout(id)
      })
    },
    clearTimeoutId(id) {
      this.timeoutIds = this.timeoutIds.filter(v => v !== id)
    },
    addTimeoutId(id) {
      this.timeoutIds = this.timeoutIds.filter(v => !v)
      this.timeoutIds.push(id)
    },
    registerListener(event) {
      const eventName = event.name
      if (this.canCheckEvent(eventName) && !this.registeredEvents[eventName]) {
        Vue.set(this.registeredEvents, eventName, true)
        this.$$on(eventName, async (...eventArgs) => {
          await this.$nextTick()
          await this.fetchForEvent({...event, eventArgs})
        })
      }
    },
    async setupEventChecks() {
      this.$$on(this.eventSpace + '.check', async (event) => {
        await this.fetchForEvent(event)
      })
      this.$$on(this.eventSpace + '.checkOn', async (event) => {
        await this.registerListener(event)
      })

      let events = _.omit(this.eventsToCheck || {}, ['created', 'auth'])
      _.forOwn(events, async (event) => {
        this.registerListener(event)
      })
      if (this.canCheckEvent('created')) {
        await this.fetchForEvent('created')
      } else {
        if(this.isAuthenticated) {
          await this.fetchForEvent('auth')
        }
      }
    },
    async fetchFrequently(immediate = true, event = {}) {
      if (this.isDisabled) {
        return
      }
      if (_.isString(event)) {
        event = this.checkingEvents[event] || this.eventsToCheck[event]
      }
      event = event || {}
      if (event.name && !this.checkingEvents[event.name]) {
        Vue.set(this.checkingEvents, event.name, event)
      }
      const eventName = event.name
      const count = fv(event.count, null)
      if (_.isNumber(count) && count <= 0) {
        Vue.delete(this.checkingEvents, eventName)
        return
      }

      event.interval = fv(event.interval, this.checkInterval)
      const interval = event.interval * 1000
      if (interval) {
        if (!immediate) {
          await setTimeoutAsync(interval)
        }
        if (this.canFetch) {
          this.isPromising = true
          await this.fetch(event)
          this.isPromising = false
        }

        const nextCount = _.isNumber(count) ? count - 1 : null
        const updatedEvent = {...event, count: nextCount}
        if (eventName) {
          Vue.set(this.checkingEvents, eventName, updatedEvent)
        }
        if (this.isAuthenticated && !this.showingNotification) {
          const timeoutId = setTimeout(async () => {
            this.clearTimeoutId(timeoutId)
            await this.fetchFrequently(true, updatedEvent)
          }, interval)
          this.addTimeoutId(timeoutId)
        }
      }
    },
    async fetchForEvent(event, immediate = true) {
      if (this.canCheckEvent(event)) {
         await this.fetchFrequently(fv(event.immediate, immediate), event)
      }
    }
  }
}
