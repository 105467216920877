<template lang="pug">

  a(:href="path")
    slot

</template>

<script>
export default {
  name: 're-router-link',

  props: ['to'],

  data: () => ({
    path: null
  }),

  created() {
    this.path = this?.$router?.match(this.to)?.fullPath;
  }
}
</script>
