export const SET_CART_STEP = 'SET_CART_STEP'
export const SET_CART_VALID = 'SET_CART_VALID'
export const SET_CART_LOCATION_VALID = 'SET_CART_LOCATION_VALID'
export const SET_CART_VALIDATING = 'SET_CART_VALIDATING'
export const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR'
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS'
export const SET_COST_DETAILS = 'SET_COST_DETAILS'
export const SET_SIGN_IN_FLAG = 'SET_SIGN_IN_FLAG'
export const SHOW_CART_INFO_POPUP = 'SHOW_CART_INFO_POPUP'
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS'
export const SET_LOCATION_CHECKOUT = 'SET_LOCATION_CHECKOUT'
export const SET_CART_LOCATION = 'SET_CART_LOCATION'
