/*global _ */
import Base from '../Base'
import {formatDate} from '../../lib/dates'
import {getFamilyRelations} from '../../lib/formatters'

export class Family extends Base {
  constructor(data, user, vm) {
    data.meta = data.meta || {}
    super(data, vm)
    this.init(data, user)
  }

  static async all(user, vm) {
    const {data} = await this.staticServiceCall('user.familyMember.all')
    return data.map(member => {
      return new Family(member, user, vm)
    })
  }

  static async create(payload, user, vm) {
    payload = _.omit(payload, ['id'])
    const {data} = await this.staticServiceCall('user.familyMember.create', payload)
    return new Family(data, user, vm)
  }

  static async get(id, user, vm) {
    const {data} = await this.staticServiceCall('user.familyMember.get', {id})
    return new Family(data, user, vm)
  }

  static async relations() {
    return getFamilyRelations()
  }

  get id() {
    return this.$$data.id
  }

  get meta() {
    return this.$$data.meta
  }

  get fullName() {
    return [this.firstName, this.lastName].join(' ').trim()
  }

  get firstName() {
    return this.$$data.first_name
  }

  get lastName() {
    return this.$$data.last_name
  }

  get email() {
    return this.$$data.email
  }

  get relation() {
    return this.$$data.relation
  }

  get birthdate() {
    return this.$$data.birthdate
  }

  set id(value) {
    this.$$data.id = value
  }

  set meta(value) {
    this.$$data.meta = value
  }

  set firstName(value) {
    this.$$data.first_name = value
  }

  set lastName(value) {
    this.$$data.last_name = value
  }

  set email(value) {
    this.$$data.email = value
  }

  set relation(value) {
    this.$$data.relation = value
  }

  set birthdate(value) {
    this.$$data.birthdate = formatDate(value, 'SQL')
  }

  init(data, user) {
    this.user = () => user
  }
  setData(value) {
    super.setData(value)
    this.$$data.fullName = this.fullName
    return this
  }

  async reload() {
    const id = this.id
    if (id) {
      const payload = {id}
      const {data} = await this.serviceCall('user.familyMember.get', payload)
      this.setData(data)
    }
    return this
  }

  async update(payload) {
    payload = _.omitBy({...this.$$data, ...payload || {}}, v => _.isNil(v))
    const id = this.id
    if (id) {
      payload.id = this.id
      const {data} = await this.serviceCall('user.familyMember.update', payload)
      this.setData(data)
    }
    return this
  }

  async create(payload) {
    payload = _.omit(payload || this.$$data, ['id'])
    const {data} = await this.serviceCall('user.familyMember.create', payload)
    this.setData(data)
    return this
  }

  async updateOrCreate(payload) {
    const id = this.id
    if (id) {
      return this.update(payload)
    } else {
      return this.create(payload)
    }
  }

  async delete() {
    const id = this.id
    if (id) {
      const payload = {id}
      await this.serviceCall('user.familyMember.delete', payload)
    }
    return id
  }
}

export default Family
