/*global _ */
import {EventBus, DataFilter} from '../../lib/utils'

import {Product} from '../../classes/store/Product'

import LocationMixin from '../location'
import ProductMixin from './product'
import CartMixins from '../../mixins/cart'

const mixins = [LocationMixin, ProductMixin, CartMixins]

export default {
  mixins,
  data() {
    return {

    }
  },
  computed: {

  },
  beforeCreate() {

  },

  created() {
  },
  mounted() {

  },
  methods: {
    async addProductItemToCart(item, moveToCart) {
      let cartItem = this.editItem

      let success
      if (!cartItem) {
        success = await this.addCartItem(item)
      } else {
        success = await this.updateCartItem(item)
      }
      if (success && moveToCart) {
        this.goto('cart')
      }
    },
  },
}

