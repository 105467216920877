<template lang="pug">
  span
    span {{ label }}: 
    span.b-welcome-contact-value(v-clipboard:copy="value")
      span.b-welcome-contact-value__text {{ value }}
      img.b-welcome-contact-value__copy(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/copy-icon.svg`" width="16" height="16")
</template>

<script>
export default {
  name: 'onboarding-contact-line',

  props: {
    line: String,
  },

  computed: {
    label() {
      return this.line.split(':')[0]
    },
    value() {
      return this.line.split(':')[1]
    },
  },

  data: () => ({
    config: window.addiesaas.config,
  }),
}
</script>
