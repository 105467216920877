import {Vue} from '~/addiesaas'
import Checkbox from './Checkbox'
Vue.component(Checkbox.name, Checkbox)

import FormGroupRow from './FormGroupRow'
Vue.component(FormGroupRow.name, FormGroupRow)

import FormInput from './FormInput'
Vue.component(FormInput.name, FormInput)

import ReValidation from './re-validation'
Vue.component(ReValidation.name, ReValidation)

//@todo: Deprecated use re-validation instead
import ValidationInput from './re-validation-input'
Vue.component(ValidationInput.name, ValidationInput)

import ReInput from './re-input'
Vue.component(ReInput.name, ReInput)

import ReMaskInput from './re-mask-input'
Vue.component(ReMaskInput.name, ReMaskInput)

import ReCheckbox from './re-checkbox'
Vue.component(ReCheckbox.name, ReCheckbox)

import ReCheckboxes from './re-checkboxes'
Vue.component(ReCheckboxes.name, ReCheckboxes)

import ReRadio from './re-radio'
Vue.component(ReRadio.name, ReRadio)

import ReRadios from './re-radios'
Vue.component(ReRadios.name, ReRadios)

import ReDatePicker from './re-date-picker'
Vue.component(ReDatePicker.name, ReDatePicker)

import ReDateInputs from './re-date-inputs'
Vue.component(ReDateInputs.name, ReDateInputs)

import ReSelect from './re-select'
Vue.component(ReSelect.name, ReSelect)

import ReSelect2 from './re-select2'
Vue.component(ReSelect2.name, ReSelect2)

import ReText from './re-text'
Vue.component(ReText.name, ReText)

import ReTextarea from './re-textarea'
Vue.component(ReTextarea.name, ReTextarea)

import ReAgreeToTerms from './re-agree-to-terms'
Vue.component(ReAgreeToTerms.name, ReAgreeToTerms)

import ReForm from './re-form'
Vue.component(ReForm.name, ReForm)

import ReFormField from './re-form-field'
Vue.component(ReFormField.name, ReFormField)

import ReOptionsTable from './re-options-table'
Vue.component(ReOptionsTable.name, ReOptionsTable)

import ReStarRating from './re-star-rating'
Vue.component(ReStarRating.name, ReStarRating)

import ReRecaptcha from './re-recaptcha'
Vue.component(ReRecaptcha.name, ReRecaptcha)

import ReInputButton from './re-input-button'
Vue.component(ReInputButton.name, ReInputButton)

import fields from './fields'

export default {
  Checkbox,
  FormGroupRow,
  FormInput,
  ReValidation,
  ValidationInput,

  ReInput,
  ReMaskInput,
  ReCheckbox,
  ReCheckboxes,
  ReRadio,
  ReRadios,
  ReDatePicker,
  ReDateInputs,
  ReSelect,
  ReText,
  ReTextarea,
  ReAgreeToTerms,
  ReForm,
  ReFormField,
  ReOptionsTable,
  ReStarRating,
  ReRecaptcha,
  ReInputButton,
  ...fields
}
