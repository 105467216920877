/*global _ */
import {getFirstCombination} from '../../lib/lodash-extras'
import {Product} from '../../classes/store/Product'
import ProductMixin from './products'
const mixins = [ProductMixin]

export default {
  mixins,
  data() {
    return {
      product: null,
      productItem: null,
      productType: null,
      productSubType: null,
      productProvider: null,
      productProviderType: null,
      productCategory: null,
      productCustomType: null,
    }
  },
  computed: {

  },
  async created() {
    const item = this.productItem = this.probeProduct(this.product || this.item)
    this.productType = this.getProductType(item)
    this.productSubType = this.getProductSubType(item)
    this.productProvider = this.getProductProvider(item)
    this.productProviderType = this.getProductProviderType(item)
    this.productCategory = this.getProductCategory(item)
    this.productCustomType = this.getProductCustomType(item)
    this.product = this.getProductFromService(item)
  },

  methods: {
    getProductFromService(productData) {
      let product = productData || {}

      const type = _.get(productData, 'type', this.productType || '')
      const subType = _.get(productData, 'subType', this.productSubType || '')
      const provider = _.get(productData, 'provider', this.productProvider || '')
      const providerType = _.get(productData, 'providerType', this.productProviderType || '')
      const customType = _.get(productData, 'customType', this.productCustomType || '')
      const combinationSource = [type, subType, customType, provider, providerType].map(_.upperCase)

      const service = this.productService ||
        (productData && getFirstCombination(combinationSource, _.assign({}, Product), true)) || null

      if (_.isFunction(service)) {
        if (!service.constructor) {
          product = service.call(this, productData)
        } else {
          product = new service(productData, this)
        }
      }
      return product
    }
  }
}
