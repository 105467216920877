/*global _ */
import {name, mixins, props, data} from './config'
import {addExternalCSS, addInlineCSS, addExternalJS} from '../../../lib/dom'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      data: {},
    }
  },
  computed: {
    hasContent() {
      return !!this.content
    },
    containerComponentName() {
      return this.containerComponent || 'div'
    },
    containerComponentAttr() {
      const attrs = {
        ...(this.$$attrs() || {}),
        ...(this.containerComponentAttrs || {})
      }
      attrs.class = _.castArray(attrs.class || [])
      attrs.class.push(this.classes)
      return attrs
    },
    containerComponentOn() {
      return {
        ...(this.$listeners || {}),
        ...(this.containerComponentListeners || {})
      }
    },
    hasTitle() {
      return this.$$trim(this.title) && this.showTitle
    },
    hasSubTitle() {
      return this.$$trim(this.subTitle) && this.showSubTitle
    },
    hasNotice() {
      return this.$$trim(this.notice) && this.showNotice
    },
    hasHead() {
      return this.hasTitle || this.hasSubTitle || this.hasNotice
    },
    headComponentAttr() {
      return this.headComponentAttrs || {}
    },
    headComponentOn() {
      return this.headComponentListeners || {}
    },
    bodyComponentAttr() {
      return this.bodyComponentAttrs || {}
    },
    bodyComponentOn() {
      return this.bodyComponentListeners || {}
    },
    footComponentAttr() {
      return this.footComponentAttrs || {}
    },
    footComponentOn() {
      return this.footComponentListeners || {}
    },
  },
  created() {
    const jsUrls = this.jsUrls
    const js = this.js
    const cssUrls = this.cssUrls
    const css = this.css
    if (jsUrls) {
      addExternalJS(jsUrls)
    }
    if (js) {
      addExternalJS({text: js})
    }
    if (cssUrls) {
      addExternalCSS(cssUrls)
    }
    if (css) {
      addInlineCSS(css)
    }
  },
}
