/*global _ */
import {name, mixins, props, data, EventBus} from './config'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,

      showInfoModal: false,
      isNumberInfo: false,
      showResendModal: false,
    }
  },

  watch: {
    giftcards(val) {
      this.cardsList = this.parseCards(val)
      _.set(this.User, 'cards.giftcard', this.cardsList)
    }
  },

  computed: {
    ownGiftcardsList() {
      return _.filter(this.cardsList || [], x => x.role === 'recipient')
    },

    sentGiftcardsList() {
      return _.filter(this.cardsList || [], x => x.role === 'giver')
    },
  },

  methods: {
    registerEventListeners() {

    },
    parseCardMoreAbilities(card, cards) {
      const mixin = this
      card.$can = {
        ...(card.$can || {}),
        ...{
          showBalance: this.forceShowBalance || (this.showBalance && this.canShowBalance(card)),
          showNumberMasked: this.showNumberMasked && (card.is_virtual || !this.canShowNumber(card)),
          showRecipient: this.showRecipient && this.canShowRecipient(card),
          showGiver: this.showGiver && this.canShowGiver(card),
          isForMyself: this.isForMyself(card),
          claim: this.allowClaim && this.isClaimable(card),
          claimForMyself: this.allowClaim && this.isClaimableForMyself(card),
          recoverCode: this.allowForgotCode && this.isCodeRecoverable(card),
          resend: this.allowResend && this.isResendable(card),
          archive: this.allowArchive && this.isArchivable(card),
        }
      }
      card.$actions = {
        ...(card.$actions || {}),
        ...{
          claim: async () => await mixin.claim(card),
          resend: async () => await mixin.resend(card),
          check: async () => await mixin.check(card),
        }
      }

      return card
    },
    hasGiftCards() {
      return this.areGiftcardsLoaded &&
        (!_.isEmpty(this.sentGiftcardsList) || !_.isEmpty(this.ownGiftcardsList))
    },
    giftCardsTabs() {
      return {
        'own': {
          'active': true,
          'title': 'My Gift Cards',
          'cards': this.hasGiftCards() && this.ownGiftcardsList || []
        },
        'sent': {
          'title': 'Sent gift cards',
          'cards': this.hasGiftCards() && this.sentGiftcardsList || []
        },
      }
    },

    async addGiftCard(fields) {
      this.giftcard.number = fields.number
      this.giftcard.cvv = fields.cvv
      await this.add()
    },

    recoverByEmail(payload) {
      this.$emit('recoverByEmail', payload)
    },
    recoverByNumber(payload) {
      this.$emit('recoverByNumber', payload)
    },
  },
}
