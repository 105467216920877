import AddToCartActionMixin from '../../../../designer/mixins/components/shop/buy/add-to-cart-action'
import SelectProductMixin from '../../../../designer/mixins/components/shop/buy/select-product'

export default {
  mixins: [AddToCartActionMixin, SelectProductMixin],
  data() {
    return {
      filterByProductType: 'giftcard',
    }
  }
}

