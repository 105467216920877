/***
 *  Use this plugin to dispatch store actions when user is logged out.
 *  Example usage: A store may have more profile related details or other tokens
 *  which need to be clean on logout. The relevant module can use this plugin and
 *  pass the action name(s).
 *
 * @param actions string|array  Store action name as string or names as array. Each name must be with absolute namespace
 * @returns {(function(*): void)|*}
 */
export const onLogout = (actions) => {
  return (store) => {
    store.watch((store, getters) => {
      return getters.authCheck
    }, isAuth => {
      if (!isAuth) {
        actions = Array.isArray(actions) ? actions : [actions]
        actions.forEach(action => {
          store.dispatch(action)
        })
      }
    }, {
      immediate: true
    })
  }
}

/***
 *  Use this plugin to dispatch store actions when user is log in.
 *  Example usage: A store may have more profile related details
 *  which need to be set on log in. The relevant module can use this plugin and
 *  pass the action name(s).
 *
 * @param actions string|array  Store action name as string or names as array. Each name must be with absolute namespace
 * @returns {(function(*): void)|*}
 */
export const onLogin = (actions) => {
  return (store) => {
    store.watch((store, getters) => {
      return getters.authCheck
    }, isAuth => {
      if (isAuth) {
        actions = Array.isArray(actions) ? actions : [actions]
        actions.forEach(action => {
          store.dispatch(action)
        })
      }
    }, {
      immediate: true
    })
  }
}
export default {onLogin, onLogout}
