/* global _ */
import ReactiveMixin from '../../../mixins/mashup-builder/reactives'
import {fv, map} from '../../../lib/formatters'

export default {
  mixins: [ReactiveMixin],
  data() {
    return {
      proxyValues: null
    }
  },
  watch: {
    innerValue: {
      deep: true,
      immediate: true,
      handler(value) {
        this.setProxyValues(value)
      }
    }
  },
  methods: {
    setProxyValues(value) {
      const rows = _.cloneDeep(value) || []
      const columns = this.tableColumns
      this.proxyValues = map(rows, (row, rowIndex) => {
        return map(columns, (column, columnIndex) => {
          if (this.columnHasRowActions(column, columnIndex)) {
            return null
          }
          return this.getTableCell(column, row, columnIndex, rowIndex)
        })
      })
    },
    getTableCell(column, row, columnIndex, rowIndex) {
      const key = fv(column.key, column.name)
      let value = fv(_.get(row, key), row[columnIndex], column.defaultValue)
      return this.parseValue(value, column, columnIndex, rowIndex, row)
    },
    parseValue(value, column, columnIndex, rowIndex, row) {
      const needsParsing = column.valuePath || column.format || column.parse
      if (needsParsing) {
        const data = {
          '$': value,
          '$table': this,
          '$columnIndex': columnIndex,
          '$rowIndex': rowIndex,
          '$context': this.context || this.$parent,
          '$column': column,
          '$row': row,
          ...row
        }
        value = this.parseReactiveValues(column, data)
      }
      if (value instanceof Promise) {
        value.then((resolvedValue) => {
          row[columnIndex] = resolvedValue
          // this.parseValue(resolvedValue, columnIndex, rowIndex, row, rows)
        })
        return ''
      }
      return value
    },
    getValue(index, rowIndex, value) {
      if (_.isUndefined(value)) {
        value = this.proxyValues[rowIndex][index]
      }
      const column = this.getColumn(index)
      const row = this.getOriginalRow(rowIndex)
      const data = this.getRow(rowIndex)
      const params = {value, data, row, column, rowIndex, index, vm: this}
      this.applyDataFilter('cell-value', params)
      return params.value
    }
  }
}
