/*global _ */
import pageProps from '../../../../../../props/profile/page-props'
import contextProps from '../../../../../../props/context'

export const props = {
  ...pageProps,
  ...contextProps,
  value: {},
  rewardsTitle: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('rewardsTitle', 'Get {{this.topReward.title}} FREE!')
    }
  },
  showRewardsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showRewardsTitle', true)
    }
  },
  unlockedRewardsMessage: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedRewardsMessage', 'You\'ve unlocked rewards. Claim your rewards')
    }
  },
  unlockedRemainingRewardsMessageSuffix: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedRemainingRewardsMessageSuffix', ' or continue playing to unlock bigger reward.')
    }
  },
  unlockedNoRemainingRewardsMessageSuffix: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedNoRemainingRewardsMessageSuffix', '.')
    }
  },
  historyButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.history.title', 'Points History')
    }
  },
  historyButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.history.classes', '')
    }
  },
  showHistoryButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.history.show', true)
    }
  },
  locationButtonComponent: {
    type: [String],
    default() {
      return this.$options.$$s('buttons.location.component', 're-select')
    }
  },
  locationButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.location.title', 'Select Location')
    }
  },
  locationButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.location.classes', '')
    }
  },
  locationButtonAttr: {
    type: [String, Array, Object],
    default() {
      const $t = this.$options.$$t('buttons.location.attrs', {})
      const $s = this.$options.$$s('buttons.location.attrs', {})
      return _.merge({}, $t, $s)
    }
  },
  showLocationSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.location.show', true)
    }
  },
  reloadButtonTitle: {
    type: [String],
    default() {
      return this.$options.$$t('buttons.reload.title', 'Reload Your FunZone Card')
    }
  },
  useTierRanks: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useTierRanks', true)
    }
  },
  rewardOrder: {
    type: [Boolean, String],
    default() {
      return this.$options.$$s('rewardOrder', false) // false, asc, desc
    }
  }
}

export default props
