import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
    }
  },
  computed: {
    hasNoLockedReward() {
      return !this.lockedAchievementRewards.length
    },
    rewardTitles() {
      let items = _.values(_.map(this.value, 'claimMessageTitle'))
      if (items.length === 1) {
        items.unshift('a')
      } else {
        const lastItem = items.pop()
        items = [items.join(', '), 'and', lastItem]
      }
      return items.join(' ')
    },
    rewardMessage() {
      return this.buildMessages.call(this, this.rewardMessageTemplate)
    }
  },
  methods: {

  }
}
