/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E, STATES as S} from '../../../lib/constants'
import {getBuilderComponentEmitter} from '../../../lib/utils'

const CLEARING = 'clearing'

export default function (component, editor, bus) {

  const globalEmit = getBuilderComponentEmitter(bus)
  const emit = (name, params) => {
    const emitParams = {component, editor, ...params}
    bus.$emit(name, emitParams)
    globalEmit(name, emitParams)
  }

  component.on(E.STATE_RESET, (...args) => {
    emit(E.STATE_RESET, ...args)
  })
  component.on(E.STATE_UPDATED, (...args) => {
    const [{name}] = args
    emit(E.STATE_UPDATED, ...args)
    emit(E.STATE_UPDATED + ':' + name, ...args)
  })

  bus.$on(E.RENDERED, () => {
    // component.setState('rendered', true)
  })

  editor.on(`run:${CLEARING}:before`, (v) => {
    component.setState(S.CANVAS_UNLOADING, v[S.CANVAS_UNLOADING])
  })

}

