/*global _ */
import {
  Validators,
  setValidationRule,
  zipRules,
  truthyRule,
  phoneRules,
  passwordRules,
  sometimesPasswordRules,
  validDate,
  validDateBeforeToday,
  emailConfirmationRules, phoneRuleIfNotEmpty,
} from '../../../../lib/validations'

export const userFields = ['email', 'first_name', 'last_name']
export const metaFields = [
  'racer_name',
  'gender',
  'birth_date',
  'phone',
  'address',
  'address2',
  'city',
  'country',
  'state',
  'zip',
  'subscribe',
  'sms_subscribe',
  'discovery',
]
export const otherFields = [
  'phone_confirmation',
  'email_confirmation',
  'old_password',
  'password',
  'password_confirmation'
];

export const groupedFields = {
  personal: ['email', 'first_name', 'last_name', 'racer_name', 'gender', 'birth_date', 'phone'],
  address: [  'address', 'address2', 'city', 'country', 'state', 'zip'],
}

export const defaultFormFields = {
  ..._.transform(userFields, (carry, key) => carry[key] = '', {}),
  ..._.transform(metaFields, (carry, key) => carry[key] = '', {}),
  old_password: '',
  password: '',
  password_confirmation: '',
}

export const defaultSkipValidations = [
  'racer_name', 'gender',
  'phone_confirmation', 'email_confirmation', 'password', 'password_confirmation',
  'subscribe', 'sms_subscribe', 'discovery',
]

export const allValidationsRules = {
  first_name: setValidationRule('fields.first_name'),
  last_name: setValidationRule('fields.last_name'),
  racer_name: setValidationRule('fields.racer_name'),

  phone: setValidationRule('fields.phone', {
    required: {error: 'Phone is required.'},
    phone: {error: 'Invalid phone number.', rule: phoneRuleIfNotEmpty}
  }),
  phone_confirmation: setValidationRule('fields.phone_confirmation', {
    confirmed: {error: 'Phone is not confirmed.', rule: Validators.sameAs('phone')}
  }),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  email_confirmation: setValidationRule('fields.email_confirmation', emailConfirmationRules),
  gender:  setValidationRule('fields.gender'),
  birth_date: setValidationRule('fields.birth_date', {
    required: {error: 'Birth date is required.'},
    validDate: {error: 'Birth date is not valid.', rule: validDate},
    pastDate: {error: 'Birth date can not be a future date.', rule: validDateBeforeToday},
  }),
  address: setValidationRule('fields.address', 'Address'),
  address2: setValidationRule('fields.address2', {
    required: {error: 'Address second line is required.'},
  }),
  city: setValidationRule('fields.city'),
  state: setValidationRule('fields.state'),
  zip: setValidationRule('fields.zip', zipRules),
  country: setValidationRule('fields.country'),

  old_password: {},
  password: setValidationRule('fields.password', sometimesPasswordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', {
    confirmed: {error: 'Confirm Password needs to be same as Password.', rule: Validators.sameAs('password')},
  }),
  subscribe: {},
  sms_subscribe: {},

}

export default {defaultFormFields, allValidationsRules, userFields, metaFields, otherFields, groupedFields, defaultSkipValidations}
