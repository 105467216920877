/*global _ */
import VueTheMask from 'vue-the-mask'
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-input',
  mixins,
  components: {VueTheMask},
  props: {
    ...props,
    readonly: {
      type: [Boolean, String, Array, Object],
      default: null
    },
    disabled: {
      type: [Boolean, String, Array, Object],
      default: null
    },
    canRevealPassword: {
      type: [Boolean, String, Array, Object],
      default: false
    },
    passwordRevealerClass: {
      type: [String, Array, Object],
      default: null
    },
    passwordConclealerClass: {
      type: [String, Array, Object],
      default: null
    },
    mask: {}
  },

  data() {
    return {
      optionsPropName: OPTIONS_PROP,
      toggledType: this.type,
    }
  },

  computed: {
    canRevealPasswordVisibility() {
      return this.canRevealPassword && this.type === 'password'
    },
    passwordRevealed() {
      return this.canRevealPasswordVisibility && this.toggledType === 'text'
    },
    inputComponent() {
      return !_.isEmpty(this.maskAttrs) ? 'the-mask' : 'input'
    },
    maskAttrs() {
      let attrs = {}
      let mask = this.mask
      if (mask) {
        if (_.isString(mask) || _.isArray(mask)) {
          attrs.mask = mask
        } else {
          attrs = mask
        }
      }
      return attrs
    },
    customInputAttrs() {
      return {
        ...this.maskAttrs
      }
    }
  },

  watch: {
    value(value) {
      const inputElement = this.$refs.input
      if (inputElement) {
        if (this.inputComponent === 'input') {
          inputElement.value = value
        }
      }
    },
    type() {
      this.toggledType = this.type
    },
  },

  methods: {
    togglePasswordVisibility() {
      if (this.canRevealPasswordVisibility) {
        this.toggledType = this.passwordRevealed ? 'password' : 'text'
      }
    },
    getLabelExtraClasses() {
      return [`re-input-label-${this.type || 'text'}`]
    }
  }
}
