/* global _ */
import {getFirstErrorMessage} from '../../../../services/base'

const cardStubData = {
  id: null,
  name: '',
  first_name: '',
  last_name: '',
  card: '',
  cvv: '',
  expMonth: '',
  expYear: '',
  expDate: '',
}

export default {
  data() {
    return {
      cardUpdateData: _.clone(cardStubData),
      editingCard: false,
      editingRow: null,
    }
  },
  computed: {
    cardFormComponent() {
      return this.$$s('components.edit.name') || 're-credit-card-form'
    },
    cardFormAttrs() {
      const $s = this.$$s('components.edit', {})
      const $t = this.$$t('components.edit', {})

      const attrs = _.merge(
        {},
        $t,
        _.omit($s, ['name', 'attrs']),
        $s.attrs || {}
      )
      return {
        settings: this.$$s('edit', {}),
        translations: this.$$t('edit', {}),
        ...attrs,
        location_id: this.filterLocationId,
      }
    },
    useSplitName() {
      return _.get(this.editOptions, 'useSplitName')
    },
    canShowHeaderButtons() {
      return !this.editingCard && !this.isPromising
    }
  },
  methods: {
    async initDelete({rowIndex}) {
      const confirm = await this.confirmAsync(
        this.$$t('delete.confirmation.message', 'Do you want to delete the card?'),
        this.$$t('delete.confirmation.title', 'Confirm Delete')
      )
      const item = this.tableData[rowIndex]
      if (confirm) {
        this.startPromising(this.$$t('delete.processing.message', 'Deleting card'))
        try {
          await this.pingUser()
          await this.invokeService('user.billing.paymentMethods.delete', item)
          await this.fetch()
          // this.User.paymentMethods.splice(rowIndex, 1)
        } catch (e) {
          const errorMessage = getFirstErrorMessage(e, this.$$t('delete.error.message', 'Unable to delete card!'))
          this.errorAlertAsync(errorMessage)
        }
        this.stopPromising()
      }
    },
    cancelEdit() {
      this.editingCard = false
      this.editingRow = null
      this.cardUpdateData = _.clone(cardStubData)
    },
    async initAdd() {
      this.showEditForm()
    },
    async initEdit({rowIndex}) {
      const item = this.tableData[rowIndex]
      this.showEditForm(item)
      this.editingRow = rowIndex
    },
    showEditForm(item) {
      const data = this.transformCardToFields(item)
      this.cardUpdateData = data
      this.editingCard = item || true
    },
    transformFieldsToCard(fields) {
      const data = {...fields}
      if (this.useSplitName) {
        data.name = [data.first_name, data.last_name].join(' ')
      }
      return data
    },
    transformCardToFields(card) {
      const data = (_.cloneDeep(card || {...cardStubData}))
      data.card = '' //F.maskLeft(data.card, _.get(this.editOptions, 'cardMask', 'XXXX XXXX XXXX 0000'))
      data.cvv = '' //F.maskLeft(data.card, _.get(this.editOptions, 'cvvMask', 'XXXX'))
      if (this.useSplitName) {
        const name = data.name || data.holder || ''
        const names = (name + '').split(' ', 2)
        data.first_name = names[0] || ''
        data.last_name = names[1] || ''
      }
      return data
    },
    async updateOrCreate(data, onSuccess, onError) {
      this.$v.$touch()
      if (this.isAnyInvalid) {
        this.scrollToFirstErrorElement(true)
        return
      }
      const fields = this.transformFieldsToCard(data)
      const payload = _.cloneDeep(fields)
      const rowIndex = this.editingRow
      const isCreate = _.isNil(rowIndex)

      try {
        this.startPromising(isCreate ? 'Updating card' : 'Adding card')
        await this.pingUser()
        const card = await this.invokeService('user.billing.paymentMethods.updateOrCreate', payload)
        await this.addOrUpdateCard(card)
        this.cancelEdit()
        if (_.isFunction(onSuccess)) {
          onSuccess()
        }
      } catch (e) {
        this.showFormError(e)
        if (_.isFunction(onError)) {
          onError()
        }
      } finally {
        this.stopPromising()
      }
    },
    async addOrUpdateCard(card) {
      const rowIndex = this.editingRow
      if (_.isNil(rowIndex)) {
        this.User.paymentMethods.push(card)
      } else {
        this.User.paymentMethods.splice(rowIndex, 1, card)
      }
    },
    async setAsDefault({rowIndex}) {
      try {
        const item = this.tableData[rowIndex]
        this.startPromising(this.$$t('seDefault.processing.message'))
        const {data} = await this.invokeService('user.billing.paymentMethods.default', item)
        await this.fetch()
        this.stopPromising()
      } catch (e) {
        this.stopPromising()
        const errorMessage = getFirstErrorMessage(
          e,
          this.$$t('seDefault.error.message', 'Unable to set card as default!')
        )
        this.errorAlertAsync(errorMessage)
      }
    }
  }
}
