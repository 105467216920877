/*global _, addiesaas */
import {fs, coerceBoolean as toBoolean} from '../../utils'
import {filterByLocation} from '../../store/product'

const DataFilters = window.VueDataFilter

export const TYPE_PATH = 'loyalty_program_reward'
export const TIER_TYPE = 'tier'
export const ACHIEVEMENTS_TYPE = 'achievements'
export const TIER_PATH = 'loyalty_program_reward_tiered'
export const TIER_RANK_PATH = 'loyalty_program_reward_tier_rank'
export const POINTS_PATH = 'loyalty_program_reward_points'

export const IMAGE_PATH = 'loyalty_program_image'
export const TAG_PATH = 'custom.loyalty_tag'
export const INLINE_TITLE_PATH = 'custom.loyalty_title'
export const CLAIM_MESSAGE_TITLE_PATH = 'custom.loyalty_claim_title'
export const EXPIRATION_PATH = 'loyalty_program_expire_days'
export const SUBTITLE_PATH = 'custom.loyalty_subtitle'
export const DESCRIPTION_PATH = 'custom.loyalty_description'
export const FOOTER_TAG_PATH = 'custom.loyalty_footer_tag'
export const FOOTER_MESSAGE_PATH = 'custom.loyalty_footer_message'

export const getLoyaltyProducts = function () {
  const location = this.selectedLoyaltyLocationId
  const allProducts = _.filter(
    _.flatMap(addiesaas.products.types),
      product => !!toBoolean(_.get(product, TYPE_PATH))
  )
  let products = _.cloneDeep(filterByLocation(allProducts, location))

  const params = {products, vm: this}
  if (this && this.applyDataFilter) {
    this.applyDataFilter('products', params)
  }
  DataFilters.apply('loyalty.products.prepare', params)
  return params.products
}

export const getLoyaltyRewards = function ({status, parser, rewards: mergeRewards}) {
  let highestUnlockedTierReward = null
  const lockBelowHighest = this.lockBelowHighest

  const mergeProperties = ['ignored']
  const {points} = status
  const products = _.cloneDeep(getLoyaltyProducts.call(this) || [])
  const rewards = _.map(products, (product) => {
    const mergeReward = _.find(mergeRewards, i => i.id === product.id)
    const reward = {
      ..._.cloneDeep(_.pick(mergeReward || {}, mergeProperties)),
      product,
      id: product.id,
      productType: product.type
    }
    const isTiered = !!_.get(product, TIER_PATH)
    reward.type = isTiered ? TIER_TYPE : ACHIEVEMENTS_TYPE
    reward.tierRank = isTiered ? _.get(product, TIER_RANK_PATH) : null
    reward.price = product.price
    const rewardPoints = reward.points = _.get(product, POINTS_PATH)
    const unlocked = reward.unlocked = rewardPoints <= points

    // store the highest reward
    if (lockBelowHighest && isTiered && unlocked) {
      if (!highestUnlockedTierReward || rewardPoints >= highestUnlockedTierReward.points) {
        highestUnlockedTierReward = reward
      }
    }

    reward.image = fs(
      _.get(product, IMAGE_PATH),
      _.get(product, 'image'),
      _.get(product, 'custom.image'),
      _.get(product, 'media.image'),
      this.$$s(`product.${product.type}.image.src`),
      this.$$s(`product.stub.image.src`)
    )

    reward.tag = _.get(product, TAG_PATH)
    const title = reward.title = product.title
    reward.messageInlineTitle = _.get(product, INLINE_TITLE_PATH, title)

    reward.subTitle = _.get(product, SUBTITLE_PATH)
    reward.description = _.get(product, DESCRIPTION_PATH, product.description)

    reward.footerTag = _.get(product, FOOTER_TAG_PATH)
    reward.footerMessage = _.get(product, FOOTER_MESSAGE_PATH)
    reward.claimMessageTitle = _.get(product, CLAIM_MESSAGE_TITLE_PATH, reward.messageInlineTitle)

    reward.expiration = _.toFinite(_.get(product, EXPIRATION_PATH))

    return reward
  })

  // lock the unlocked tier rewards below the highest unlocked reward
  if (lockBelowHighest && highestUnlockedTierReward) {
    _.forOwn(rewards, reward => {
      const isTiered = reward.type === TIER_TYPE
      if (isTiered && reward.unlocked && reward.id !== highestUnlockedTierReward.id) {
        reward.unlocked = false
      }
    })
  }

  const params = {rewards, vm: this}
  if (this && this.applyDataFilter) {
    this.applyDataFilter('rewards', params)
  }
  DataFilters.apply('loyalty.rewards.prepare', params)

  if (_.isFunction(parser)) {
    params.rewards = _.values(params.rewards).map(reward => {
      return parser.call(this, reward)
    })
  }

  return params.rewards
}

export default {
  getLoyaltyProducts,
  getLoyaltyRewards
}
