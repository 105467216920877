export default {
  translations: {
    type: [Object, Array, String],
    default: null
  },
  settings: {
    type: [Object, Array, String],
    default: null
  },
  title: {
    type: String,
  },
  showTitle: {
    type: [Boolean, Number, String],
    default: null
  },
  subTitle: {
    type: String,
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default: null,
  },
  notice: {
    type: String,
  },
  showNotice: {
    type: [Boolean, Number, String],
    default: null,
  },
  classes: {
    type: [String, Array, Object],
  },
  error: {
    type: String,
  },
  modalClasses: {
    type: [String, Array, Object],
  },
}

