/*global _ */
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
    }
  },
  computed: {},
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.state = 'init'
      await this.submitReward()
    },
  }
}
