export const name = 're-shop-product-item-custom'

import props from './props'
export {props}

import config from '../base/config'
import FormMixin from './form'
export const mixins = [...config.mixins, FormMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
