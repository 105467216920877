const setUser = async function () {
  const user = {
    id: 'qovgxe3xm4ladbpm',
    email: 'greg@rocketeffect.com',
    first_name: 'Greg',
    last_name: 'Moreno',
    verified: true,
    meta: {
      "gender": "na",
      "birth_date": "1999-11-26",
      "phone": "(234) 263-5122",
      "zip": "48084",
      "address": "Wilshire Drive Troy 22",
      "city": "Oakland County",
      "country": "US",
      "state": "MI",
    }
  }
  await this.$store.dispatch('updateUser', {user})
}
export default {
  async created() {
    if ((this.isDesignerMode || this.designerMode) && !this.user) {
      await setUser.call(this)
    }
  }
}
