import {
  passwordConfirmationRules,
  passwordRules,
  setValidationRule,
} from '../../../../lib/validations'

export const defaultFormFields = {token: '', email: '', password: '', password_confirmation: ''}

export const allValidationsRules = {
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  password: setValidationRule('fields.password', passwordRules),
  password_confirmation: setValidationRule('fields.password_confirmation', passwordConfirmationRules),
}

export default {defaultFormFields, allValidationsRules}
