<template lang="pug">
  .b-verificode.m-b-35
    .b-verificode__group
      input.b-verificode__input(
        v-for="i in 3"
        v-model="code[i - 1]"
        :ref="`code`"
        @keyup="keyup(i - 1, $event)"
        @paste="paste(i - 1, $event)"
        placeholder="0"
      )
    .b-verificode__divider -
    .b-verificode__group
      input.b-verificode__input(
        v-for="i in 3"
        v-model="code[i + 2]"
        :ref="`code`"
        @keyup="keyup(i + 2, $event)"
        @paste="paste(i + 2, $event)"
        placeholder="0"
      )

</template>

<script>
import { required, integer, between } from 'vuelidate/lib/validators'

const CODE_LENGTH = 6

export default {
  name: 'onboarding-verificode',

  data: () => ({
    code: ['', '', '', '', '', ''],
  }),

  validations: {
    code: {
      $each: {
        required,
        integer,
        between: between(0, 9),
      }
    },
  },

  watch: {
    code: {
      deep: true,
      handler() {
        this.$emit('change', this.code.join(''))
      },
    },
  },

  methods: {
    paste(index, event) {
      event.preventDefault()
      const paste = (event.clipboardData || window.clipboardData).getData('text')
      for (let j = 0, i = index; j < paste.length && i < CODE_LENGTH; j++) {
        if (Number.isInteger(parseInt(paste.charAt(j)))) {
          this.$set(this.code, i, paste.charAt(j))
          this.next(i)
          i++
        }
      }
    },

    keyup(index, event) {
      const key = event.key
      switch (key) {
        case 'Tab':
          break;
        case 'ArrowLeft':
          this.prev(index)
          break;
        case 'ArrowRight':
          this.next(index)
          break;
        default:
          if (Number.isInteger(parseInt(key))) {
            this.$set(this.code, index, key)
            this.next(index)
            this.$v.code.$touch()
            this.backendError = null
          }
      }
    },

    prev(index) {
      if (index > 0) {
        this.$refs.code[index - 1].focus()
        this.$refs.code[index - 1].select()
      } else {
        this.$refs.code[index].blur()
      }
    },

    next(index) {
      if (index + 1 < CODE_LENGTH) {
        this.$refs.code[index + 1].focus()
        this.$refs.code[index + 1].select()
      } else {
        this.$refs.code[index].blur()
      }
    },
  }
}
</script>
