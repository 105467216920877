<template lang="pug">
  div
    img.b-logo-bg(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.lg.svg`")
    .b-central.b-central--mobile
      .b-central__content.b-central__content--center
        img.m-t-40.m-b-15(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/logo.sm.svg`")
        .b-central__title.b-central__title--sm Rocket Effect Merchant
        .b-central__subtitle.m-b-30-f Voucher Scanner Web App
        .b-central__title.b-central__title--sm Sign In Now

        .b-central__panel.b-central__panel--w260.b-central__panel--center.m-b-20
          form-group.m-b-15-f(:validator="$v.email" label="Email:" attribute="email" v-slot="props" :messages="{ backendError }")
            input(
              v-model="$v.email.$model"
              :class="props.classes"
              type="email"
              placeholder="Enter your email"
              @input="input"
            )
          form-group.m-b-15-f(:validator="$v.password" label="Password:" attribute="password")
            template(v-slot:default="props")
              input(
                v-model="$v.password.$model"
                :class="props.classes"
                type="password"
                placeholder="Enter password"
                @input="input"
              )
          .form-group.form-check
            input#staySignedCheckbox.form-check-input(v-model="staySigned" type="checkbox")
            label.form-check-label(for="staySignedCheckbox") Stay signed in

        v-button.btn.btn-lg.btn-block.btn-primary.m-b-20(@click="submit" :loading="submitting" :disabled="$v.$dirty && $v.$invalid") Sign in

        router-link.text-muted(:to="{ name: 'vouchermobilescanner-password-forgot' }") Forgot your password?

</template>

<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: '',
    password: '',
    backendError: null,
    submitting: false,
    staySigned: false,
  }),

  validations: {
    email: {
      required,
      email,
      backendError() {
        return !this.backendError
      },
    },
    password: {
      required,
    },
  },

  beforeRouteEnter (to, from, next) {
    const authenticated = window.addiesaas.store.getters.authCheck
    const staySigned = window.addiesaas.store.getters['VoucherMobileScanner/staySigned']

    if (authenticated && staySigned) {
      try {
        // await axios.get(`${this.tenant.url}/api/tenant/profile`)
        next({ name: 'vouchermobilescanner-scanner' })
        return
      } catch {}
    }

    next()
  },

  async created() {
    this.staySigned = this.$store.getters['VoucherMobileScanner/staySigned']
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true

        const guard = 'admin'

        const { data: { access_token: token } } = await axios.post(`${this.config.baseUri}/api/clients/api/${guard}/login`, {
          username: this.email,
          password: this.password,
        })

        await this.$store.dispatch('saveToken', { token })
        await this.$store.dispatch('updateUser', { user: { email: this.email }})
        await this.$store.dispatch('VoucherMobileScanner/staySigned', { staySigned: this.staySigned })

        this.$router.push({ name: 'vouchermobilescanner-scanner' })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    }
  },
}
</script>
