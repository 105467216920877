/* global _ */
export default {
  data() {
    return {
      pageIndex: this.pageStart || 0,
    }
  },
  computed: {
    paginationAttrs() {
      return {
        total: this.pageCount,
        settings: this.$$s('table.pagination', {}),
        translations: this.$$t('table.pagination', {}),
      }
    },
    pageNumber() {
      return this.pageIndex + 1
    },
    pageCount() {
      return this.paginate ? Math.ceil(this.filteredItemsCount / this.pageSize) : 1
    },
    hasPagination() {
      return this.paginate && this.pageCount > 1
    },
  },
  watch: {
    async pageIndex(value) {
      await this.$nextTick()
      if (this.scrollToTabMaxWidth) {
        const max = this.scrollToTabMaxWidth * 1
        if (max && this.appWidth <= max) {
          this.scrollTo('.re-profile-content > .re-profile-tab-content')
        }
      }
    }
  },
  methods: {
    goPage(pageNumber, isIndex = true) {
      const index = isIndex ? pageNumber : (pageNumber - 1)
      if (index >= 0 && index < this.pageCount) {
        this.pageIndex = index
      }
    },
    nextPage() {
      this.goPage(this.pageIndex + 1)
    },
    prevPage() {
      this.goPage(this.pageIndex - 1)
    },
  }
}
