import {
  setValidationRule,
  emailConfirmationRules,
  parseValidations,
  parseValidationSetup
} from '../../../../../lib/validations'

export {parseValidations, parseValidationSetup}

export const defaultFormFields = {email: '', email_confirmation: ''}
export const allValidationsRules = {
  email: setValidationRule('guestEmailFields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
  email_confirmation: setValidationRule('guestEmailFields.email_confirmation', emailConfirmationRules),
}

export default {defaultFormFields, allValidationsRules, parseValidations, parseValidationSetup}
