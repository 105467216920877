<template lang="pug">
  .b-onboarding__right.b-onboarding__right--select-blocks
    .b-onboarding__title what is The primary goal you would like to achieve with rocket effect?
    .b-onboarding__subtitle Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

    onboarding-select-blocks(:blocks="options.goalBlocks" :selected="onboarding.primary_goal || ''" @select="select" :single="true")

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    select(selected) {
      this.update({ primary_goal: selected })
    },
  }
}
</script>
