/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]
import VueRecaptcha from 'vue-recaptcha'
const components = {VueRecaptcha}

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-recaptcha',
  components,
  mixins,
  props: {
    ...props,
    value: {
      type:[String, Boolean, Number, Array, Object],
      default: false
    },
    siteKey: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light'
    },
    size: {
      type: String,
      default: 'normal'
    },
    type: {
      type: String,
      default: null
    },
    settings: {
      type: [Object],
      default: () => ({
        loadRecaptchaScript: true
      })
    },
    inputClass: {
      type: String,
      default: 'form-control-recaptcha'
    },
  },
  data() {
    return {
      gCaptchaRendered: false,
      state: '',
      optionsPropName: OPTIONS_PROP,
      innerValue: null,
      internals: {
        siteKey: this.siteKey,
        size: this.size || 'normal',
        theme: this.theme || 'light',
        tabindex: this.tabindex || null,
        type: this.type || null,
        settings: this.settings || {},
      }
    }
  },
  computed: {
    gKey() {
      return this.internals.siteKey
    },
    recaptchaAttrs() {
      return _.omitBy({
        ..._.pick(this.internals, ['tabindex', 'type', 'size', 'theme']),
        ...this.internals.settings || {}, //theme, type , size , tabindex , badge, loadRecaptchaScript
      }, _.isNil)
    },
    missingSiteKey() {
      return !this.gKey
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    resetReCaptcha() {
      const gcaptcha = this.$refs.gcaptcha
      if (gcaptcha && !this.missingSiteKey) {
        debugLog({reset_recaptcha: gcaptcha})
        this.model = null
        this.state = 'reset'
        gcaptcha.reset()
      }
    },
    onInput(event) {
      this.isDirtyState = true
      //const value = event.target.checked || false
      debugLog({recaptcha_verify: event})
      this.state = 'verified'
      this.model = event
    },
    onError(event) {
      debugLog({recaptcha_error: event})
      this.state = 'error'
      this.model = null
    },
    onRender(event) {
      debugLog({recaptcha_render: event})
      this.gCaptchaRendered = event
      this.state = 'render'
      this.model = null
    },
    onExpiration(event) {
      debugLog({recaptcha_expiration: event})
      this.state = 'expired'
      this.model = null
    }
  }
}
