import {
  setValidationRule,
  Validators,
  parseValidations,
  parseValidationSetup
} from '../../../../../../lib/validations'
const {required, helpers} = Validators

export const couponNotApplied = function (value, fields) {
  if (value) {
    return !_.some(this.cartDiscounts || {}, d => d.type === 'coupon' && _.get(d, 'payload.options.code') === value)
  }
  return true
}

export {parseValidations, parseValidationSetup}

export const defaultFormFields = {
  giftcard_number: null,
  giftcard_cvv: null,
  playcard_number: null,
  playcard_cvv: null,
  coupon: null
}
export const allValidationsRules = {
  giftcard_number: setValidationRule('fields.giftcard_number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  giftcard_cvv: setValidationRule('fields.giftcard_cvv', {
    required: {error: 'Card code is required.'},
    digits: {error: 'Card code is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  playcard_number: setValidationRule('fields.playcard_number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  playcard_cvv: setValidationRule('fields.playcard_cvv', {
    required: {error: 'Card code is required.'},
    digits: {error: 'Card code is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  coupon: setValidationRule('fields.coupon', {
    required: {error: 'Coupon is required.'},
    applied: { error: 'Coupon is already applied.', rule: couponNotApplied}
  }),
}

export default {defaultFormFields, allValidationsRules, parseValidations, parseValidationSetup}
