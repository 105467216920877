import validations from './validations'
import {context} from './context'

export default {
  ...validations,
  context,
  advancedSettings: {},
  name: {
    type: [String, Number],
    required: false
  },
  id: {
    type: String,
  },
  label: {
    type: [String, Boolean, Number, Object, Array],
    default: null
  },
  description: {
    type: [String, Boolean, Number, Object, Array],
    default: null
  },
  value: {
    type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: [String, Array],
    default: null
  },
  // readonly: {
  //   type: [Boolean, String, Array, Object],
  //   default: null
  // },
  // disabled: {
  //   type: [Boolean, String, Array, Object],
  //   default: null
  // },
  tabindex: {
    default: null
  },
  autocomplete: {
    type: [String, Number, Boolean, Object, Function],
    default: null
  },
  useAutocompleteOffFix: {
    type: [String, Number, Boolean],
    default: false
  },
  hint: {
    type: String,
    default: null
  },

  groupClass: {
    type: [String, Array, Object],
    default: 'form-group form-field'
  },
  groupErrorClass: {
    type: [String, Array, Object],
    default: 'is-invalid form-group-error'
  },
  inputClass: {
    type: [String, Array, Object],
    default: 'form-control'
  },
  inputErrorClass: {
    type: [String, Array, Object],
    default: 'is-invalid form-control-error'
  },
  errorClass: {
    type: [String, Array, Object],
    default: 'invalid-feedback'
  },
  hydrate: {
    type: [String, Array, Object, Function],
    default: null
  },
  lazy: {
    type: [Boolean, String, Number],
    default: false
  },
  hintOnValue: {
    type: [Boolean, String, Number],
    default: null
  },
  showHint: {
    type: [Boolean, String, Number],
    default: true
  },
  parseAttrs: {
    type: [Array, Object],
    default() {
      return {}
    }
  }
}
