import Base from '../Base'
import User from './User'
import Order from '../shop/Order'
import Plan from '../store/products/subscription/Plan'
import {parseDateTime} from '../../lib/dates'

export class Subscription extends Base {

  constructor(data, vm) {
    super(data, vm)
    this.plan = new Plan(this.data.plan.data, vm)
    this.order = new Order(this.data.order.data, vm)
    this.billing = this.data.billing
    this.subscriber = new User(this.data.user, vm)
  }

  get id() {
    return this.data.id
  }

  get title() {
    return this.data.title || this.data.name
  }

  get subscriberName () {
    return this.subscriber.fullName
  }

  get isCardExpiring() {
    return this.hasCardExpired ? null : this.order.transaction.card.expiring
  }

  get hasCardExpired() {
    return this.order.transaction.card.expired
  }

  get cardExpiration() {
    return this.order.transaction.card.getExpiration()
  }

  get cardMaskedNumber() {
    return this.order.transaction.card.masked
  }

  get nextBill() {
    if (this.billing.next_at) {
      return parseDateTime(this.billing.next_at)
    }
    return null
  }

  async initCancel() {
    return await this.serviceCall('user.subscription.cancel', this.id)
  }

  async updatePaymentMethod(data) {
    return await this.serviceCall('user.subscription.paymentMethod.update', this.id, data)
  }
}

export default Subscription
