export const name = 're-loyalty-status'
// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../../mixins/components/complex'
import ResponsiveMixin from '../../../../../mixins/responsive'
import LoyaltyMixin from '../../../../../mixins/profile/loyalty'
import ShopLocationMixin from '../../../../../mixins/shop/location'
export const mixins = [ComponentMixin, ResponsiveMixin, LoyaltyMixin, ShopLocationMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  autoChecking: false,
  mode: 'status',
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus
}
