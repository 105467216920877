/*  global _ */
import {Vue} from '~/addiesaas'
const EventBus = window.VueEventBus
import ComponentMixin from '../../../mixins/components/complex'
import FormMixin from '../../../mixins/form'
import {parseValidations, required, setValidationRule, Validators} from '../../../lib/validations'

const CURRENT_YEAR = (new Date()).getFullYear()

const validationRules = {
  name: setValidationRule('fields.name'),
  first_name: setValidationRule('fields.first_name'),
  last_name: setValidationRule('fields.last_name'),
  card: setValidationRule('fields.card', {
      required: {error: 'Credit Card Number is required.'},
      minLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.minLength(12)},
      maxLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.maxLength(20)},
    }
  ),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Security code is required.'},
    numeric: {error: 'Security code must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.minLength(3)},
    maxLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.maxLength(4)},
  }),
  expMonth: setValidationRule('fields.expMonth', {
    required: {error: 'Expiration Month is required.'},
    numeric: {error: 'Expiration Month must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.minLength(1)},
    maxLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.maxLength(12)},
  }),
  expYear: setValidationRule('fields.expYear', {
    required: {error: 'Expiration Year is required.'},
    numeric: {error: 'Expiration Year must be numeric.', rule: Validators.numeric},
    minValue: {error: `Expiration Year must be greater than or equal to ${CURRENT_YEAR}.`, rule: Validators.minValue(Number(String(CURRENT_YEAR).substring(2)))} ,
  }),
  expDate: {},
  is_primary: {}
}
export const mixins = [ComponentMixin, FormMixin]

export default {
  name: 're-credit-card-form',
  mixins,
  props: {
    settings: {},
    translations: {},
    value: {},
    options: {},
    showFormLabels: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('form.showLabels', true)
      }
    },
    showFormPlaceholders: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('form.showPlaceholders', true)
      }
    },
    showFormFieldHint: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('form.showFieldHint', true)
      }
    },
    formOptions: {
      type: [Array, String, Object],
      default() {
        return this.$options.$$s('form.options', {})
      }
    },
    validateFormFieldOnDirty: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('form.validateFieldOnDirty', false)
      }
    },
    showSubmitErrorOnFields: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('form.submitError.showOnFields', true)
      }
    },
    useSplitName: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('useSplitName', false)
      }
    }
  },
  data() {
    return {
      fields: {
        card: null,
        name: null,
        first_name: null,
        last_name: null,
        expDate: null,
        expYear: null,
        expMonth: null,
        cvv: null,
        is_primary: false,
      },
      isProcessing: null,
      processingMessage: '',
    }
  },
  validations() {
    let fields = parseValidations(validationRules)
    fields = _.omitBy(fields, (f, k) => {

      return this.$$s(`form.fields.${k}.validations`, true) === false
    })
    if (this.useSplitName) {
      fields.name = {}
    } else {
      fields.first_name = {}
      fields.last_name = {}
    }
    return {fields}
  },
  computed: {
    fieldsOrdered() {
      let fields = this.$$s('form.$fieldsOrder', this.$$s('form.fieldsOrder', _.keys(this.fields)))
      fields = _.uniq(_.map(fields, v => v === 'expYear' || v === 'expMonth' ? 'expDate' : v))
      if (!this.useSplitName) {
        fields = _.omit(fields, ['first_name', 'last_name'])
      } else {
        fields = _.omit(fields, ['name'])
      }
      return fields
    },
    nextMonth() {
      return this.now.plus({months: 1})
    },
    nextMonthYear() {
      return this.nextMonth.year
    },
    expYears() {
      return _.range(this.nextMonthYear, this.nextMonthYear + 20)
    },
    expirationDateGroupClasses() {
      const isField = this.isField('expDate', true)
      const classes = []
      if (isField) {
        const isMonthInvalid = this.dirtyInvalid('fields.expMonth')
        const isYearInvalid = this.dirtyInvalid('fields.expYear')
        if (isYearInvalid || isMonthInvalid) {
          classes.push('group-is-invalid')
        }
        const isMonthRequired = _.has(this.validations, `expMonth.items.required`)
        const isYearRequired = _.has(this.validations, `expYear.items.required`)
        if (isMonthRequired || isYearRequired) {
          classes.push('required')
        }
      }
      return classes
    }
  },
  watch: {
    fields: {
      handler(fields) {
        this.$emit('input', fields)
      },
      deep: true
    },
    value: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  created() {
    this.init()
    this.validations = this.parseValidationSetup(validationRules)
  },
  beforeMount() {

  },
  mounted() {
  },
  beforeUpdate() {

  },
  updated() {

  },
  beforeDestroy() {

  },
  destroyed() {

  },
  errorCaptured(error, component, info) {

  },
  methods: {
    init() {
      let value = this.value || {}
      _.forOwn(value, (v, k) => {
        Vue.set(this.fields, k, v)
      })
    },
    successCallback() {
      this.emitBoth('close')
    },
    errorCallback(e) {
      this.showFormError(e)
    },
    cancel() {
      this.emitBoth('close')
    },
    submit() {
      this.formErrorMessage = null
      this.formSubmitting = true
      this.$v.$touch()
      if (!this.isAnyInvalid) {
        this.$v.$reset()
        this.emitBoth(
          'submit',
          this.fields,
          (...args) => {
            this.successCallback(...args)
          },
          (...args) => {
            this.errorCallback(...args)
          },
        )
      }
    }
  }
}
