/*global _ */
export const EventBus = window.VueEventBus
const defaultMenu = {
  edit: {
    title:'My Profile',
    component:'re-profile-edit',
    order: 0
  },
  settings: {
    title:  'Settings',
    component:  're-profile-settings',
    order: Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1
  }
}

const getFirst = function (items, path, defaultValue, type) {
  for (let item of items) {
    if (!_.isNil(item)) {
      const paths = []
      if (item) {
        paths.push(item)
      }
      if (path) {
        paths.push(path)
      }
      const fullPath = paths.join('.')
      if (fullPath) {
        const value = this.$options[type](fullPath, null)
        if (!_.isNil(value)) {
          return value
        }
      }
    }
  }
  return defaultValue
}

const getSettings = function (spaces, path, defaultValue) {
  return getFirst.call(this, spaces, path, defaultValue, '$$s')
}

const getTranslations = function (spaces, path, defaultValue) {
  return getFirst.call(this, spaces, path, defaultValue, '$$t')
}

export const getProps = (preferredPath) => {
  const props = {
    settings: {},
    translations: {},
    menu: {
      type: Array,
      default: function () {
        const preferredItemsPath = preferredPath && `${preferredPath}.items`
        const $t = getTranslations.call(this, [preferredItemsPath, 'menu'], '', {})
        const $s = getSettings.call(this, [preferredItemsPath, 'menu'], '', {})
        const mergedItems = _.merge({}, defaultMenu, $t, $s)

        const filteredItems = _.omitBy(mergedItems, i => !i || i.enabled === false || i.disabled)
        const count = _.size(filteredItems)
        const unsortedItems = _.values(_.mapValues(filteredItems, (item, key) => {
            if (!_.isObject(item)) {
              item = {}
            }
            item['key'] = item.key || key
            item['title'] = item.title || _.startCase(key)
            item['order'] = _.isNil(item.order) ? count : item.order
            return item
          }
        ))
        return unsortedItems.sort((a, b) => a.order - b.order)
      }
    },
    showLogout: {
      type: [Boolean, Number, String],
      default() {
        return !!getSettings.call(this, [preferredPath, ''], 'showLogout', true)
      }
    },
    logoutLabel: {
      type: String,
      default() {
        return getTranslations.call(this, [preferredPath, ''], 'logout.label', 'Log out')
      }
    },
    modalClass: {
      type: [String, Array, Object],
      default() {
        return getSettings.call(this, [preferredPath, ''], 'popupClass', 're-modal-md')
      }
    },
    menuContainerClasses: {
      type: [String, Array, Object],
      default() {
        return getSettings.call(this, [preferredPath, 'menuOptions'], 'container.classes', '')
      }
    },
    menuItemClasses: {
      type: [String, Array, Object],
      default() {
        return getSettings.call(this, [preferredPath, 'menuOptions'], 'item.classes', '')
      }
    },
    logoutModalTitle: {
      type: String,
      default() {
        return getTranslations.call(
          this,
          [preferredPath, ''],
          'logout.confirmation.title',
          'Do you want to sign out of your profile?'
        )
      }
    },
    logoutModalConfirmButtonTitle: {
      type: String,
      default() {
        return getTranslations.call(
          this,
          [preferredPath, ''],
          'logout.confirmation.yesButton.title',
          'Yes'
        )
      }
    },
    logoutModalCancelButtonTitle: {
      type: String,
      default() {
        return getTranslations.call(
          this,
          [preferredPath, ''],
          'logout.confirmation.noButton.title',
          'No'
        )
      }
    },
    menuHeadComponent: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.head.name',
          null
        )
      }
    },
    menuHeadComponentAttrs: {
      default() {
        const $t = getTranslations.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.head.attrs',
          {}
        )
        const $s = getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.head.attrs',
          {}
        )
        return _.merge({}, $t, $s)
      }
    },
    menuHeadComponentParseAttrs: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.head.parseAttrs',
          {}
        )
      }
    },
    menuHeadComponentListeners: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.head.on',
          {}
        )
      }
    },
    menuFootComponent: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.foot.name',
          null
        )
      }
    },
    menuFootComponentAttrs: {
      default() {
        const $t = getTranslations.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.foot.attrs',
          {}
        )
        const $s = getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.foot.attrs',
          {}
        )
        return _.merge({}, $t, $s)
      }
    },
    menuFootComponentParseAttrs: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.foot.parseAttrs',
          {}
        )
      }
    },
    menuFootComponentListeners: {
      type: [String, Number, Boolean],
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'components.foot.on',
          false
        )
      }
    },
    toggleTouchMaxWidth: {
      default() {
        return getSettings.call(
          this,
          [preferredPath, 'menuOptions'],
          'toggle.touch.maxWidth',
          1023
        )
      }
    }
  }
  return props
}

export default getProps
