import _ from 'lodash'

export function getErrorMessage(e, defaultMessage = 'Unknown error') {
  e = e || {}
  const response = e.response || null
  const data = response ? (response.data || {}) : e
  let message = data.message || data.error

  if (!message && (data.errors || (!message && response && response.status === 422))) {
    let {errors = {}} = data
    if (_.isString(errors)) {
      errors = {error: errors}
    }

    const firstError = errors[Object.keys(errors)[0]]
    message = _.isString(firstError) ? firstError : firstError.detail || firstError.title
  }

  return message || defaultMessage
}

export function getErrorMessages(e) {
  return Object.values(getErrorMessagesWithKeys(e))
}

export function getErrorMessagesWithKeys(e) {
  const response = e.response || {}
  const {data = {}} = response || {}
  let {errors = {}} = data || {}
  if (_.isString(errors)) {
    errors = {error: errors}
  }
  if (Array.isArray(errors)) {
    errors = {}
  } else {
    errors = _.mapValues(errors, item => _.isString(item) ? item : item[0])
  }
  return errors
}

export function getFirstErrorMessage(e, defaultMessage = 'Unknown error') {
  const errors = getErrorMessages(e)
  const errorKeys = Object.keys(errors)
  return errorKeys.length ? _.head(_.castArray(errors[errorKeys[0]])) :
    getErrorMessage(e, defaultMessage)
}
