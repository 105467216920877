/*global _ */
import {name, mixins, props, data} from './config'
import {fv, setTimeoutAsync} from '../../../../../lib/utils'
const USER_POINT_PATH = 'loyalty_program_points'

export default {
  name,
  mixins,
  props,
  settings: 'page.profile.tab.loyalty',
  translations: 'page.profile.tab.loyalty',
  data() {
    return {
      ...data,
    }
  },

  computed: {
    canShowStatus() {
      return this.authenticated
    },
    statusValue() {
      return _.toFinite(this.loyaltyTierStatus.value)
    },
    targetValue() {
      return _.toFinite(this.loyaltyTierStatus.target)
    },
    remainingValue() {
      return this.loyaltyTierStatus.$remaining
    },
    spentValue() {
      return this.loyaltyTierStatus.$spent
    },
    statusValuePercentage() {
      return _.toFinite(this.loyaltyTierStatus.valuePercent)
    },

    valuesAttrs() {
      return {
        'data-spent': this.spentValue,
        'data-remaining': this.remainingValue,
        'data-target': this.targetValue,
        'data-value': this.statusValue,
      }
    },
    statusValueAttrs() {
      return {
        title: this.statusValue,
        ...this.valuesAttrs
      }
    },
    targetValueAttrs() {
      return {
        title: this.targetValue,
        ...this.valuesAttrs
      }
    },
    statusMessageAttrs() {
      return {
        ...this.valuesAttrs,
        class: [
          {
            'loyalty-status-message-short': this.showShortMessage,
            'loyalty-status-message-long': !this.showShortMessage && this.showLongMessage
          }
        ]
      }
    },
    statusValueStyles() {
      return {
        width: this.statusValuePercentage + '%'
      }
    },
    targetValueStyles() {
      return {
        width: '100%'
      }
    },
    canShowMessage() {
      return fv(this.showMessages, this.showShortMessage || this.showLongMessage || this.fulfilledMessage)
    },
    statusMessage() {
      return fv(this.fulfilledMessage, this.statusShortMessage, this.statusLongMessages, '')
    },
    fulfilledMessage() {
      if (!this.hasFulfilledTierRewards || this.showMessages === false || !this.showFulfilledMessage) {
        return null
      }
      const data = this.loyaltyTierStatus
      const template = fv(this.fulfilledMessageTemplate, data.defaults.fulfilledMessage)
      const message = this.buildMessages(template, data)
      const params = {message, data, template, vm:() => this}
      this.applyDataFilter(`fulfilledMessage`, params)
      return params.message
    },
    statusShortMessage() {
      if (this.showMessages === false || !this.showShortMessage) {
        return null
      }
      const data = this.loyaltyTierStatus
      const template = fv(this.shortMessageTemplate, data.defaults.shortMessage)
      const message = this.buildMessages(template, data)
      const params = {message, data, template, vm:() => this}
      this.applyDataFilter(`shortMessage`, params)
      return params.message
    },
    statusLongMessages() {
      if (this.showMessages === false || !this.showLongMessage) {
        return null
      }
      const status = this.loyaltyTierStatus
      const data = {
        ...status,
        prefix: fv(this.longMessagePrefix, status.defaults.longMessagePrefix),
        suffix: fv(this.longMessageSuffix, status.defaults.longMessageSuffix),
      }
      const template = fv(this.longMessageTemplate, status.defaults.longMessage)
      const message = this.buildMessages(template, data)
      const params = {message, data, template, vm:() => this}
      this.applyDataFilter(`longMessage`, params)
      return params.message
    },
    canCheckRewards() {
      const skip = _.some(this.skipAnnounceOnPages, name => this.isRoute(name))
      const mute = _.get(this.user, `meta.mute_loyalty_reward_alerts`)
      return !mute && !skip &&
        this.announceRewards && this.canShowStatus && !this.autoChecking
    }
  },
  watch: {
    async earnedLoyaltyPoints(value, oldValue) {
      value = value || 0
      if (value !== oldValue) {
        await this.prepareRewards(true)
      }
    },
    async authenticated() {
      if (this.canCheckRewards) {
        await this.prepareRewards(true)
      }
    },
    async unlockedAchievementRewards() {
      await this.$nextTick()
      await this.autoCheckPossibleClaims()
    },
    async unlockedTierRewards() {
      await this.$nextTick()
      await this.autoCheckPossibleClaims()
    },
    canShowStatus: {
      immediate: true,
      async handler(canShow) {
        if (canShow) {
          await this.$nextTick()
          await this.autoCheckPossibleClaims()
        }
      }
    },
    shopLocationId: {
      handler(val) {
        if (val) {
          this.setLoyaltyLocationId(val)
        }
      },
      immediate: true
    }
  },
  async created() {
    await this.prepareRewards(true)
  },
  methods: {
    async autoCheckPossibleClaims() {
      if (!this.canCheckRewards) {
        return
      }
      await this.autoCheckTierPossibleClaims()
      await this.autoCheckAchievementsPossibleClaims()
    },
    async autoCheckTierPossibleClaims() {
      if (!this.canCheckRewards) {
        return
      }
      this.autoChecking = true
      this.$nextTick()
      await setTimeoutAsync(1000)
      const tierRewards = this.unlockedTierRewards || []
      await this.initClaim(tierRewards)
      this.autoChecking = false
    },
    async autoCheckAchievementsPossibleClaims() {
      if (!this.canCheckRewards) {
        return
      }
      this.autoChecking = true
      this.$nextTick()
      await setTimeoutAsync(1000)
      const achievementRewards = this.unlockedAchievementRewards || []
      await this.initClaim(achievementRewards)
      this.autoChecking = false
    },
  }
}
