import {
  setValidationRule,
} from '../../../../lib/validations'

export const defaultFormFields = {email: ''}

export const allValidationsRules = {
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
}

export default {defaultFormFields, allValidationsRules}

