export default (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ selector: to.hash });
      }, 500);
    });
  } else {
    return { x: 0, y: 0 };
  }
}
