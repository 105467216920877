<template lang="pug">
  .b-scanner.b-scanner--dark
    .b-scanner__header
      .b-scanner__header-left
        router-link(:to="{ name: 'vouchermobilescanner-scanner' }")
          img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/back-icon.svg`")
          span Back
      .b-scanner__header-right
        img(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/settings-icon.svg`")
      .b-scanner__header-center
    .b-scanner__text.b-scanner__text--center.b-scanner__text--sm-title.m-t-20 Settings
    .b-scanner__settings
      .form-group
        .custom-control.custom-switch
          input#customSwitch1.custom-control-input(v-model="autoRedeem" type="checkbox" @change="change")
          label.custom-control-label(for="customSwitch1") Redeem automatically
      .form-group
        label Delay between scans, ms
        input.form-control(v-model="delayBetweenScans" type="number" min="500" step="1" @input="change")
      .btn.btn-primary.btn-block(@click="signout") Sign out

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    config: window.addiesaas.config,
    autoRedeem: null,
    delayBetweenScans: null,
  }),

  computed: {
    ...mapGetters({
      settings: 'VoucherMobileScanner/settings',
    }),
  },

  beforeRouteEnter (to, from, next) {
    next(window.addiesaas.store.getters.authCheck ? true : { name: 'vouchermobilescanner-sign-in' })
  },

  mounted() {
    this.autoRedeem = this.settings.autoRedeem
    this.delayBetweenScans = this.settings.delayBetweenScans
  },

  methods: {
    change() {
      this.$store.dispatch('VoucherMobileScanner/saveSettings', { settings: {
        autoRedeem: this.autoRedeem,
        delayBetweenScans: this.delayBetweenScans,
      }})
    },
    async signout() {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'vouchermobilescanner-sign-in' })
    },
  },
}
</script>

<style lang="scss">
.b-scanner__settings {
  .form-group {
    .form-control {
      background-color: transparent;
      border-color: white;
      color: white;
      &::placeholder {
        color: #878DA9;
      }
    }

    .custom-control.custom-switch {
      .custom-control-label {
        margin-left: 15px;

        &::before {
          height: 22px;
          border-radius: 11px;
          top: -1.5px;
          width: 38.5px;
          left: -46.5px;
        }
        &::after {
          top: 1.5px;
          width: 16px;
          height: 16px;
          left: -42.5px;
          border-radius: 8px;

        }
      }
      .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(15.5px);
      }
    }
  }
}
</style>