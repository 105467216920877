/* global _ */
import User from '../../../../classes/user/User'
import {
  name,
  mixins,
  props,
  data,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      processingMessage: null,
      relations: {},
      eventSpace: 'user.profile.family',
      filterLocationId: null,
    }
  },
  computed: {
    members() {
      return _.get(this.storedProfileData, 'states.members', [])
    },
    hasVisibleData() {
      return !!this.members.length
    },
    tableData() {
      if (this.showLocationFilter && this.filterLocationId) {
        return _.filter(this.members, member => {
          let filter = this.locationFilterCriteria || {}
          if (_.isString(filter)) {
            const property = 'location_id'
            filter = [['some', [filter, [property, this.filterLocationId]]]]
          }
          return this.probeReactiveIf(filter, {...member, $: member})
        })
      }
      return this.members
    },
    showNoFamily() {
      return !this.hasVisibleData && !this.editingMember && !this.isPromising
    },
    locationInputAttrs() {
      const allOptionLabel = this.$$t('form.filters.location_id.allOptionLabel')
      const defaults = this.locationInputOptions || {
        'label': 'Select location',
      }
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      defaults.context = this
      return this.getLocationFieldProperties('location_id', {defaults, allOptionLabel}, 'filters')
    },
    hasFilteredMembers() {
      return !!this.tableData.length
    },
  },
  watch: {
    filterLocationId(id) {
      if (id) {
        this.profileLocationId = id
      }
    },
    profileLocationId(id) {
      if (id && this.filterLocationId && id !== this.filterLocationId) {
        this.filterLocationId = id
      }
    }
  },

  async activated() {
    await this.fetch()
  },

  methods: {
    getComponentExtraClasses() {
      return [{
        'editing-member': !!this.editingMember
      }]
    },
    async fetch(autoloadAll) {
      this.emit('fetch.init')
      try {
        if (!this.hasVisibleData) {
          this.startPromising(this.loadingMessage)
        }
        const autoLoads = this.autoLoad
        if (autoLoads) {
          await this.initialAutoLoadCompleted()
          await this.autoLoadAll(autoLoads)
        } else {
          if (autoloadAll) {
            await this.autoLoadAll(autoLoads)
            await this.initialAutoLoadCompleted()
          }
        }
        await this.initialAutoLoadCompleted()
        const members = await this.autoLoadFamily()
        this.$emit('success', members)
        this.emit('fetch.success', members)
      } catch (e) {
        this.$emit('error', e)
        this.emit('fetch.error', e)
      } finally {
        this.emit('fetch.end')
        this.stopPromising()
        const relations = await User.Family.relations()
        this.relations = _.mapKeys(relations)
      }
    },
  }
}
