/*global _ */
import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export default {
  name: 're-text',
  mixins,
  props: {
    value: {
      default: null
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {

  }
}
