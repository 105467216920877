import {
  parseValidations,
  setValidationRule,
  ValidationMixins,
  Validators
} from '../../../../../lib/validations'
const {required, email, helpers} = Validators

const EventBus = window.VueEventBus

const validations = {
  number: setValidationRule('fields.number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number is required.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  email: setValidationRule('fields.email', {
    required: {error: 'Email is required.'},
    email: {error: 'Email is not valid.'},
  }),
}

export default {
  name: 're-forgot-code-card',
  props: {
    classes: {
      type: [String, Array, Object],
      default: ''
    },
    title: {
      type: String,
      default: null
    },
    headerMessage: {
      type: String,
      default: 'Resend your code using the email address associated with your Card <b>OR</b> your Card Number.'
    },
    inputClasses: {
      type: [String, Array, Object],
      default: 'form-control'
    },
    buttonTitle: {
      type: String,
      default: 'Recover'
    },
    buttonClasses: {
      type: [String, Array, Object],
      default: null
    },
    isSuccess: {
      type: Boolean,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    recoveredEmail: {
      type: String,
      default: null,
    },
    successTitle: {
      type: String,
      default: 'Success'
    },
    successMessage: {
      type: String,
      default: 'The Card code has been sent to the following email address'
    },
    successLinkPrefix: {
      type: String,
      default: 'If you have not received an email please click on the '
    },
    successLinkLabel: {
      type: String,
      default: 'resend link.'
    },
    resendMessage: {
      type: String,
      default: 'The Card code has been resent to the following email address'
    },
    emailInputLabel: {
      type: String,
      default: 'Email Address'
    },
    emailInputPlaceholder: {
      type: String,
      default: 'Email Address'
    },
    numberInputLabel: {
      type: String,
      default: 'Card Number'
    },
    numberInputPlaceholder: {
      type: String,
      default: 'Card Number'
    },
  },

  mixins: [ValidationMixins],

  data() {
    return {
      fields: {
        number: null,
        email: null,
      },
      isProcessing: false,
      isResendEmail: false,

      validations,
    }
  },
  validations: {fields: parseValidations(validations)},

  watch: {
    isSuccess(newVal) {
      if (!newVal) {
        this.isResendEmail = false
      } else {
        this.fields = {
          email: null,
          number: null
        }
      }
      this.isProcessing = false
    },
    recoveredEmail(newVal) {
      if (newVal) {
        this.isProcessing = false
      }
    }
  },

  computed: {
    isRequestError() {
      return !_.isEmpty(this.errorMessage)
    },
    disabledSubmit() {
      return this.$v.fields.email.$invalid && this.$v.fields.number.$invalid
    },

    showSuccessResentBox() {
      return !_.isEmpty(this.recoveredEmail) && !this.isRequestError && this.isSuccess
    }
  },

  methods: {
    recoverCard() {
      EventBus.$emit('card.before.resend-code')
      this.$v.$touch()
      this.formSubmitting = true
      let payload = {}
      if (!this.$v.fields.email.$invalid) {
        this.isProcessing = true
        this.recoveredEmail = this.fields.email
        payload = {email: this.fields.email}
        this.$emit('recoverByEmail', payload)
        EventBus.$emit('card.resend-code.by-email', payload)
      }
      if (!this.$v.fields.number.$invalid) {
        this.isProcessing = true
        payload = {number: this.fields.number}
        this.$emit('recoverByNumber', payload)
        EventBus.$emit('card.resend-code.by-number', payload)
      }
    },
    resend() {
      EventBus.$emit('card.resend-code.before.resend-email')
      this.isResendEmail = true
      const payload = {email: this.recoveredEmail}
      this.$emit('recoverByEmail', payload)
      EventBus.$emit('card.resend-code.by-email', payload)
    }
  },
}
