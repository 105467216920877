/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

import {nil, toBoolean} from '../../../../lib/formatters'

export default {
  name: 're-star-rating',
  mixins,
  props: {
    ...props,
    iconMarked: {},
    iconMarkedColor: {},
    iconUnmarked: {},
    iconUnmarkedStyle: {},
    scale: {},
    readonly: {},
    disabled: {},
    inputClass: {
      default: 'form-control-star-rating'
    }
  },
  data() {
    return {
      starOptions: {}
    }
  },
  computed: {
    defaultOptions() {
      return {
        name: this.name,
        id: this.id,
        tabindex: this.tabindex,
        disabled: toBoolean(this.disabled),
        readonly: toBoolean(this.readonly),
        ...this.getStarOptions()
      }
    },
    icons() {
      const val = this.starOptions.value || 0
      const v = parseInt(val, 10)
      const partialParent = Math.ceil(val)
      const partial = val - v
      const markedDef = this.starOptions.marked
      const icons = _.reduce(_.range(1, this.starOptions.max + 1), (icons, i) => {
        const icon = {}
        const type = i <= v ? 'marked' : 'unmarked'
        const def = icon.def = this.starOptions[type]
        icon.value = i
        icon.component = def.url ? 'img': 'span'
        const isPartial = partialParent === i && partial > 0
        icon.attrs = this.getIconAttr(def, isPartial)
        if (isPartial) {
          icon.parentClass = 'partial-parent'
          icon.partial = partial
          icon.def = markedDef
          icon.partialComponent = markedDef.url ? 'img' : 'span'
          icon.partialAttrs = this.getIconAttr(markedDef, partial, type)
        }

        icons.push(icon)
        return icons
      }, [])

      return icons
    },
  },
  created() {
    this.starOptions = _.cloneDeep(this.defaultOptions)
  },
  updated() {
    //
  },
  methods: {
    getStarOptions(data) {
      const {
        iconMarked = this.iconMarked,
        iconMarkedColor = this.iconMarkedColor,
        iconUnmarked = this.iconUnmarked,
        iconUnmarkedStyle = this.iconUnmarkedStyle,
        scale = this.scale,
        value = this.value
      } = data || {}

      const marked = {
        ...iconMarked,
        color: iconMarkedColor
      }

      const unmarked = {
        ...iconUnmarked,
        style: iconUnmarkedStyle
      }

      return {
        value: 1 * value || 0,
        max: 1 * scale,
        marked: marked,
        markedColor: iconMarkedColor,
        unmarked: unmarked,
        unmarkedStyle: iconUnmarkedStyle,
      }
    },
    getIconAttr(def, partial, type) {
      const opt = this.starOptions
      const noActivity = opt.readonly || opt.disabled
      const isImg = def.url
      const isPartialParent = partial === true
      const isPartialValue = !isPartialParent && partial > 0
      const attr = {
        src: isImg ? def.url : null,
        class: [
          type,
          (def.class || '').split(' '),
          (def.style || '').split(' '),
          {
            'inactive': noActivity,
            'partial-unmarked': isPartialParent,
            'partial-marked': isPartialValue,
          }
        ],
        style: [
          _.omitBy({
          color: def.color,
          width: isPartialValue ? (partial*100) + '%': null
        }, nil)],
      }
      return _.omitBy(attr, nil)
    },
    onInput(value) {
      this.isDirtyState = true
      if (this.isDesignerMode) {
        this.designerSyncTrait({value})
      } else {
        if (!this.starOptions.readonly) {
          this.$set(this.starOptions, 'value', value)
          this.model = value
        }
      }
    }
  }
}
