import baseProps from '../base/props'

const localProps = {
  itemComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.plan', 're-shop-product-item-subscription-plan')
    }
  },
}
export const props = {
  ...baseProps,
  ...localProps
}
export default props
