export const name = 're-table'

import props from './props'

export {props}

import ComponentMixin from '../../../mixins/components/complex'
import BaseInputMixin from '../../../mixins/form/base-input'
import ResponsiveMixin from '../../../mixins/responsive'
import RepeaterMixin from './repeater'
import ColumnMixin from './columns'
import RowMixin from './rows'
import DataMixin from './data'
import ActionMixin from './actions'

export const mixins = [
  ComponentMixin,
  BaseInputMixin,
  ResponsiveMixin,
  RepeaterMixin,
  ColumnMixin,
  RowMixin,
  DataMixin,
  ActionMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
