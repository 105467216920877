import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'
import formGroup from '../components/ValidableFormGroup'
import config from '~/vuestrap/config'
const { Vue } = config

Vue.use(Vuelidate)
Vue.use(vuelidateErrorExtractor, {
  template: formGroup,
  messages: {
    required: 'The {attribute} field is required.',
    email: 'The {attribute} must be a valid email address.',
  },
});
