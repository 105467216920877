import SignIn from '../views/SignIn'
import InstanceSelect from '../views/InstanceSelect'
import Scanner from '../views/Scanner'
import Settings from '../views/Settings'
import PasswordForgot from '../views/PasswordForgot'
import PasswordVerify from '../views/PasswordVerify'
import PasswordReset from '../views/PasswordReset'

const PREFIX = '/scanner'

export default [
  { path: `${PREFIX}`, name: 'vouchermobilescanner-sign-in', component: SignIn },
  { path: `${PREFIX}/instance`, name: 'vouchermobilescanner-instance-select', component: InstanceSelect },
  { path: `${PREFIX}/scanner`, name: 'vouchermobilescanner-scanner', component: Scanner },
  { path: `${PREFIX}/settings`, name: 'vouchermobilescanner-settings', component: Settings },
  { path: `${PREFIX}/forgot-password`, name: 'vouchermobilescanner-password-forgot', component: PasswordForgot },
  { path: `${PREFIX}/password-verification`, name: 'vouchermobilescanner-password-verify', component: PasswordVerify },
  { path: `${PREFIX}/reset-password`, name: 'vouchermobilescanner-password-reset', component: PasswordReset },
]
