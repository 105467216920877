/*global _ */
import axios from 'axios'
import UniversalSocialauth, {Providers} from 'universal-social-auth'

import ComponentMixin from '../../../../mixins/component-base'
import EventsMixin from '../../../../mixins/events'
import providersConfig from '../../../../lib/social/auth/config'
import {setTimeoutAsync} from '../../../../lib/utils'

export default {
  name: 're-social-auth-button',
  mixins: [ComponentMixin, EventsMixin],
  props: {
    provider: {
      type: String
    },
    providerAttrs: {
    },
    buttonComponent: {
      default: 're-button'
    },
    buttonAttrs: {},
    value: {}
  },
  computed: {
    providerOptions() {
      const keyMaps = {
        client_id: 'clientId',
        redirect: 'redirectUri',
      }

      const providerAttrs = this.providerAttrs || {}
      const extend = providerAttrs.extend
      const defaults = (extend ? Providers[extend] : Providers[this.provider]) || {}
      const attrs = {
        ...defaults,
        ..._.get(providersConfig, this.provider, {}),
        ...providerAttrs,
      }
      return {
        providers: {
          [this.provider]: _.mapKeys(attrs, (value, key) => keyMaps[key] || key)
        }
      }
    },
    componentAttrs() {
      const attrs = this.buttonAttrs || {}
      const append = `btn-social-auth-${this.provider}`
      const classes = this.parseAttrClasses(attrs, append)
      return {
        ...(this.$attrs || {}),
        ...attrs,
        class: classes
      }
    }
  },
  methods: {
    async onClick(provider) {
      this.emit('init', provider, this)
      try {
        provider = provider || this.provider
        const providers = this.providerOptions
        const oAuth = new UniversalSocialauth(axios, providers)
        const options = _.get(providers.providers, provider)
        const isCustom = options.custom
        const response = await oAuth.authenticate(provider, isCustom ? options : {})
        if (response instanceof Error) {
          throw response
        }
        await setTimeoutAsync(0)
        await this.$nextTick()
        this.emit('success', response, provider, this)
      } catch (e) {
        debugLog({SOCIAL_AUTH_ERROR: e})
        await setTimeoutAsync(0)
        await this.$nextTick()
        this.emit('error', e, provider, this)
      }
    }
  },
}
