export const name = 're-shop-product'
import {getFirstCombination} from '../../../../lib/lodash-extras'
export {getFirstCombination}
import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import ProductMixin from '../../../../mixins/shop/product'
export const mixins = [ComponentMixin, ProductMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  getFirstCombination
}
