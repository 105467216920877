<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Get started absolutely free
      .b-central__subtitle Free forever. No credit card needed.

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        .row
          .col-sm-6
            form-group(
              :validator="$v.firstName"
              label="First name:"
              attribute="first name"
              v-slot="props"
            )
              input(
                v-model="$v.firstName.$model"
                :class="props.classes"
                placeholder="Your first name"
                @input="input"
              )
          .col-sm-6
            form-group(
              :validator="$v.lastName"
              label="Last name:"
              attribute="last name"
              v-slot="props"
            )
              input(
                v-model="$v.lastName.$model"
                :class="props.classes"
                placeholder="Your last name"
                @input="input"
              )

        form-group(
          :validator="$v.phoneNumber"
          label="Your phone:"
          attribute="phone number"
          v-slot="props"
          :messages="{ phoneNumber: 'Invalid phone number format.' }"
        )
          input(
            v-model="$v.phoneNumber.$model"
            :class="props.classes"
            placeholder="Type your phone"
            @input="input"
          )

        p Notification updates

        .form-check.mb-3
          input#unsubscribeCheckbox.form-check-input(v-model="unsubscribe" type="checkbox")
          label.form-check-label.text-muted(for="unsubscribeCheckbox") I don't want to receive updates from Rocket Effect related to marketing best practices, product and feature updates, and promotions.

        form-group.form-check(
          :validator="$v.agreement"
          attribute="terms and conditions"
          v-slot="props"
          :messages="{ checked: 'You must agree to Rocket Effect\\\'s Terms of Service and Privacy Policy in order to continue.' }"
          disable-form-control
        )
          input#tcsCheckbox.form-check-input(
            v-model="$v.agreement.$model"
            :class="props.classes"
            type="checkbox"
          )
          label.form-check-label.text-muted(for="tcsCheckbox") By clicking the "Sign Up" button, you are creating a Rocket Effect account, and you agree to Rocket Effect's 
            a(href="#") Terms of Use 
            |  and 
            a(href="#") Privacy Policy
            | .

      .b-central__footer.mb-4.b-central__footer--navigation-btn
        a.btn.btn-almost-sm.btn-secondary(@click.prevent="$router.go(-1)") Back
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$anyDirty && $v.$anyError") Sign Up

      .b-central__message.text-danger(v-if="backendError") {{ backendError }}

</template>

<script>
import axios from 'axios'
import { required, sameAs } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'
import PhoneNumber from 'awesome-phonenumber'

export default {

  data: () => ({
    config: window.addiesaas.config,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    unsubscribe: false,
    agreement: false,
    backendError: null,
    submitting: false,
  }),

  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    phoneNumber: {
      required,
      phoneNumber(value) {
        return (new PhoneNumber(value)).isValid()
      },
    },
    agreement: {
      checked: sameAs(() => true),
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true

        await axios.post(`${this.config.baseUri}/api/onboarding/register`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.phoneNumber,
          subscribe: !this.unsubscribe,
        })

        await axios.post(`${this.config.baseUri}/api/onboarding/phone-verification/send`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
        })

        this.$router.push({ name: 'onboarding-phone-verification', query: { ...this.$route.query, phone: this.phoneNumber } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    input() {
      this.backendError = null
    },
  },
}
</script>
