export default {
  processing: {
    type: [Boolean, Number, String],
    default: null,
  },
  processed: {
    type: [Boolean, Number, String],
    default: null,
  },
  processingError: {
    type: [String, Object],
  },
  processingSuccess: {
    type: [String, Object],
  },
}
