/* global _ */
import {name, mixins, props, data, EventBus} from './config'

export default {
  name,
  props,
  mixins,

  data() {
    return {
      ...data
    }
  },

  methods: {
    goToHome() {
      EventBus.$emit('cart.cart-empty.go-to-home')
      this.$router.push('/')
    }
  },
}
