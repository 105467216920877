import BaseError from './BaseError'
export const CODE = 'ARGUMENTS_MISSING'
export const MESSAGE = 'One or more arguments missing.'

export class MissingArgumentsError extends BaseError {
  constructor(message, code, params, previous) {
    super(message || MESSAGE, code || CODE, params, previous)
  }
}

export default MissingArgumentsError
