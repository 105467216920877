import { mapGetters } from 'vuex'
import MainService from '../../../../services/main'

const EventBus = window.VueEventBus

export default {
  name: 're-profile-settings',
  props: {
    formTitle: {
      type: String,
      default: 'Subscribe'
    },
    formSubtitle: {
      type: String,
      default: 'Would you like to receive promotional offers?'
    },
    formClass: {
      type: [String, Array, Object],
      default: ''
    },
    formSubmitButtonTitle: {
      type: String,
      default: 'Save'
    },
    successMessage: {
      type: String,
      default: 'Your changes have been saved.'
    },
    noEmailSubscribe: {
      type: Boolean,
      default: false
    },
    emailSubscribeLabel: {
      type: String,
      default: 'Yes, send me promotional offer emails'
    },
    noSmsSubscribe: {
      type: Boolean,
      default: false
    },
    smsSubscribeLabel: {
      type: String,
      default: 'Yes, send me promotional offer text messages / SMS (Mobile Phone).'
    }
  },

  data() {
    return {
      fields: {
        subscribe: null,
        sms_subscribe: null,
      },
      isSuccess: false,
      errorMessage: null,
    }
  },

  computed: {
    ...mapGetters({
      user: 'authUser'
    }),
  },

  created() {
    const user = this.user
    if (!user) {
      this.$router.push({ name: 'sign-in' })
      return false
    }
    const meta = user.meta

    this.fields = {
      subscribe: meta.subscribe,
      sms_subscribe: meta.sms_subscribe
    }
  },

  methods: {
    async save() {
      this.errorMessage = null
      this.isSuccess = false
      EventBus.$emit('profile.before.settings-update', this.fields)
      try {
        const data = await MainService.updateUser(this.user.id, this.fields)
        this.$store.dispatch('updateUser', { user: data.data })
        this.isSuccess = true
        EventBus.$emit('profile.settings-update.success', this.fields)
      } catch (e) {
        this.errorMessage = MainService.getFirstErrorMessage(e)
        EventBus.$emit('profile.settings-update.error', this.fields)
      }
    },
  },
}
