/*global _ */
import settingsTranslations from '../../../../../props/settings-translations'
import context from '../../../../../props/context'
export const props = {
  ...settingsTranslations,
  ...context,
  provider: {
    type: String,
    required: true
  },
  providerOptions: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.options`, {})
    }
  },
  useNavigation: {
    type: [Boolean, String, Number],
    default() {
      const provider = this.$options.propsData.provider
      return !!this.$options.$$s(`providers.${provider}.useNavigation`, false)
    }
  },
  title: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.title`, '')
    }
  },
  showTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showTitle`, null)
    }
  },
  subTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.subTitle`, '')
    }
  },
  showSubTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showSubTitle`, null)
    }
  },
  notice: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.notice`, '')
    }
  },
  showNotice: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showNotice`, null)
    }
  },
  selectorTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.selectorTitle`, '')
    }
  },
  showSelectorTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showSelectorTitle`, null)
    }
  },
  selectorSubTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.selectorSubTitle`, '')
    }
  },
  showSelectorSubTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showSelectorSubTitle`, null)
    }
  },
  selectorNotice: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.selectorNotice`, '')
    }
  },
  showSelectorNotice: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.showSelectorNotice`, null)
    }
  },
  connectButtonComponent: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.buttons.connect.component`, 're-social-auth-button')
    }
  },
  connectButtonAttrs: {
    default() {
      const provider = this.$options.propsData.provider
      const $t = this.$options.$$t(`providers.${provider}.buttons.connect.attrs`, {title: 'Connect'})
      const $s = this.$options.$$s(`providers.${provider}.buttons.connect.attrs`, {})
      return _.merge({}, $t, $s)
    }
  },
  confirmButtonComponent: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.buttons.confirm.component`, 're-button')
    }
  },
  confirmButtonAttrs: {
    default() {
      const provider = this.$options.propsData.provider
      const $t = this.$options.$$t(`providers.${provider}.buttons.confirm.attrs`, {title: 'Add photo'})
      const $s = this.$options.$$s(`providers.${provider}.buttons.confirm.attrs`, {})
      return _.merge({}, $t, $s)
    }
  },
  showDisconnectButton: {
    default() {
      const provider = this.$options.propsData.provider
      return !!this.$options.$$s(
        `providers.${provider}.buttons.disconnect.show`,
        this.$options.$$s(`providers.${provider}.buttons.disconnect`, true)
      )
    }
  },
  disconnectButtonComponent: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.buttons.disconnect.component`, 're-button')
    }
  },
  disconnectButtonAttrs: {
    default() {
      const provider = this.$options.propsData.provider
      const $t = this.$options.$$t(`providers.${provider}.buttons.disconnect.attrs`, {title: 'Disconnect'})
      const $s = this.$options.$$s(`providers.${provider}.buttons.disconnect.attrs`, {})
      return _.merge({}, $t, $s)
    }
  },
  showNavigateBackButton: {
    default() {
      const provider = this.$options.propsData.provider
      return !!this.$options.$$s(
        `providers.${provider}.navigation.buttons.back.show`,
        this.$options.$$s(`providers.${provider}.navigation.buttons.back`, true)
      )
    }
  },
  navigateBackButtonComponent: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.navigation.buttons.back.component`, 're-button')
    }
  },
  navigateBackButtonAttrs: {
    default() {
      const provider = this.$options.propsData.provider
      const $t = this.$options.$$t(`providers.${provider}.navigation.buttons.back.attrs`, {})
      const $s = this.$options.$$s(`providers.${provider}.navigation.buttons.back.attrs`, {title: 'Go back to previous folder'})
      return _.merge({}, $t, $s)
    }
  },
  navigateShowBreadcrumbs: {
    default() {
      const provider = this.$options.propsData.provider
      return !!this.$options.$$s(`providers.${provider}.navigation.showBreadcrumbs`, true)
    }
  },
  navigateRootFolderTitle: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$t(`providers.${provider}.navigation.rootFolder.title`, 'Root')
    }
  },
  limit: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.limit`, null)
    }
  },
  pageSize: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.pageSize`, 10)
    }
  },
  scrollBottomOffset: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.scrollBottomOffset`, 100)
    }
  },
  preAuthDelay: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.preAuthDelay`, 0) // ms
    }
  },
  postAuthDelay: {
    default() {
      const provider = this.$options.propsData.provider
      return this.$options.$$s(`providers.${provider}.postAuthDelay`, 0) // ms
    }
  },
}

export default props
