/*global _ */
import MessagesMixin from '../re-claim-promotion-reward/messages'
import {setTimeoutAsync} from '../../../../lib/utils'
const meta = 'seen_promotion_claimed_notifications'

export default {
  mixins: [MessagesMixin],
  props: {
    notificationEventSpace: {
      type: [String],
      default() {
        return this.$options.$$s('notificationEventSpace', 'profile.promotion-status.notification')
      }
    },
    notificationClass: {
      type: [String],
      default() {
        return this.$options.$$s('notificationClass', '')
      }
    },
    notificationAttrs: {
      type: [Object],
      default() {
        return this.$options.$$s('notificationAttrs', {})
      }
    },
    showHeading: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('showHeading', true)
      }
    },
    heading: {
      type: [String],
      default() {
        return this.$options.$$t('heading', 'Congratulations!')
      }
    },
    filterById: {
      type: [Array, String],
      default() {
        return this.$options.$$s('filters.id', '')
      }
    },
    filters: {
      type: [Boolean, Array, String, Object, Function],
      default() {
        return this.$options.$$s('filters.properties', null)
      },
    },
    // automatically mark promotion as seen after <n> seconds
    markSeenAfter: {
      type: [Number],
      default() {
        return this.$options.$$s('markSeenAfter', 10) // seconds
      },
    }
  },
  data() {
    return {
      promotion: {},
      promotions: {},
      showingNotification: false,
    }
  },
  computed: {
    notificationAttributes() {
      return {
        ...(this.notificationAttrs || {}),
        eventspace: this.notificationEventSpace,
        containerClass: this.notificationClass,
      }
    },
    promotionId() {
      return _.get(this.promotion, 'promotion.data.id')
    },
    appliedAt() {
      return _.get(this.promotion, 'applied_at')
    },
    promotedAt() {
      return _.get(this.promotion, 'promoted_at')
    },
    seenNotifications: {
      get() {
        return _.get(this.user, `meta.${meta}`, [])
      },
      set(value) {
        let ids = _.cloneDeep(_.get(this.user, `meta.${meta}`, []))
        ids.push(value)
        ids = _.uniq(ids)
        this.updateUser({meta: {[meta]: ids}})
        const userId = this.user.id
        if (userId) {
          this.invokeService('user.update', userId, {[meta]: ids})
        }
      }
    }
  },
  methods: {
    async fetch(event) {
      let data
      try {
        data = await this.invokeService('user.promotions.get')
        this.promotions = await this.applyFilters(data, event)
        if (this.promotions) {
          await this.mayShowNotification(this.promotions, event)
        }
      } catch (e) {
        const status =_.toFinite( _.get(e, 'response.status', e.code))
        if ([401, 403].includes(status)) {
          this.logout()
        }
      }
      return data
    },
    async applyFilters(data, event) {
      // custom filter applied by external events
      if (_.isFunction(event.filter)) {
        return await event.filter.call(this, data)
      }

      const seenIds = this.seenNotifications || []
      if (seenIds.length) {
        data = _.filter(data, d => {
          const date = d.applied_at || ''
          const id = _.get(d, 'promotion.data.id') || ''

          //!seenIds.includes(id) for backward compatibility
          if (seenIds.includes(id)) {
            return false
          }
          return !seenIds.includes(`${id}_${date}`)
        })
      }

      let ids = this.filterById || []
      if (!_.isArray(ids)) {
        ids = ids.replace(/[\s\t\r\n]/g, '').split(',')
      }

      if (ids.length) {
        data = _.filter(data, d => ids.includes(_.get(d, 'promotion.data.id')))
      }

      const filters = this.filters || null
      if (_.isNil(filters)) {
        data = _.filter(data, item => {
          return item.is_used ||
            (item.is_applied && !_.get(item, 'promotion.data.is_claimable'))
        })
      } else {
        if (!_.isEmpty(filters)) {
          data = _.filter(data, item => this.probeReactiveIf(filters, {...item}))
        }
      }
      return data
    },
    async mayShowNotification(data, event) {
      // custom check to show applied by external events
      if (_.isFunction(event.show)) {
        const canShow = await event.show.call(this, data)
        if (!canShow) {
          return
        }
      }

      if (!_.isEmpty(data)) {
        // custom function to choose promotion by external events
        if (_.isFunction(event.promotion)) {
          this.promotion = await event.promotion.call(this, data)
        } else {
          this.promotion = _.head(data)
        }
        if (!_.isEmpty(this.promotion)) {
          this.showingNotification = true
          const result = this.$$gemitAsync('beforeShow', this.promotion, () => this)
          const canShow = !_.some(result, r => r === false || !!_.get(r, 'preventDefault'))
          if (canShow) {
            this.emitSlim('show', this.promotion, () => this)
            setTimeout(() => {
              if (this.showingNotification) {
                this.markAsSeen()
              }
            }, this.markSeenAfter * 1000)
          }
        }
      }
    },
    markAsSeen() {
      const promotionId = this.promotionId
      if (promotionId) {
        const appliedAt = this.appliedAt
        this.seenNotifications = `${promotionId}_${appliedAt || ''}`
      }
    },
    async closeNotification() {
      this.isPromising = false
      this.markAsSeen()
      this.showingNotification = false
      this.emitSlim('close', this.promotion, () => this)
      this.promotion = null
      this.promotions = []
    }
  }
}
