export const name = 're-core-page-user-registration'
export const component = 're-registration'

import {factory} from '../../../../lib/page'

const props = {
  email: {},
  firstName: {},
  lastName: {},
  socialProvider: {}, // social provider
}

export default factory(name, {props, component})
