export default {
  data() {
    return {
      inputType: 'date-picker',
      inputTypeDef: {}
    }
  },
  methods: {

  }
}
