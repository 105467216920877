/*global _ */
export const name = 're-shop-products-pagination-status'
import component from '../../../../../../../props/component'
import form from '../../../../../../../props/form'
import {queryVars} from '../../../base/props'
export const props = {
  ...queryVars,
  ...component,
  ...form,

}
import BaseMixin from '../../../base/mixin'
import ComponentMixin from '../../../../../../../mixins/components/complex'
import ProductsMixin from '../../../../../../../mixins/shop/products'
export const mixins = [ComponentMixin, BaseMixin, ProductsMixin]

export const data = {}

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
    }
  },

  computed: {
    hasStats() {
      return !_.isEmpty(this.shopProductsPagination.result)
    },
    stats() {
      return this.shopProductsPagination.result || {}
    }

  },

  async created() {

  },

  mounted() {

  },
  methods: {

  }
}
