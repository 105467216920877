/*global _ */
import SettingsMixin from './settings'
import LangMixin from './lang'

const mixins = [
  SettingsMixin,
  LangMixin,
]

export const props = {
  settings: {},
  translations: {},
  skipCustomMount: {}
}

export default {
  props,
  mixins,
  data() {
    return {
      MOUNT_TARGET: null,
      MOUNT_CONTAINER: null,
      MOUNT_CONTAINER_CLASSES: null,
      MOUNT_CONTAINER_CLEAR: false,
      hasMountPoint: false
    }
  },
  destroyed() {
    if (this && this.hasMountPoint) {
      if (this && this.$el) {
        this.$el.remove()
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    if (this.MOUNT_TARGET && !this.skipCustomMount) {
      const target = document.querySelector(this.MOUNT_TARGET)
      const appendToContainerSelector = this.MOUNT_CONTAINER
      const appendToContainerClasses = this.MOUNT_CONTAINER_CLASSES
      let parent = _.last(target.querySelectorAll(appendToContainerSelector))
      if (!parent) {
        parent = document.createElement('div')
        parent.setAttribute('class', appendToContainerClasses)
        target.appendChild(parent)
      }
      if (this.MOUNT_CONTAINER_CLEAR) {
        while (parent.firstChild) {
          parent.removeChild(parent.firstChild);
        }
      }
      parent.append(this.$el)
      this.hasMountPoint = true
    }
  }
}
