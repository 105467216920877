/*global _ */
export const Validators = require('vuelidate/lib/validators')
import F from '../formatters'
import V, {standaloneRules, setValidationRule, parseValidations, parseValidationSetup} from './index'
export {setValidationRule, parseValidations, parseValidationSetup}
import {getRule as getBackendRule, isStandaloneRule as isStandaloneBackendRule} from './laravel/rules'
import {getMessage, parseMessage, messages} from './laravel/messages'

const formatValue = function (format, value) {
  if (format) {
    const formatter = F.getFirst(format, F, _, this, window)
    if (!_.isNil(formatter) && !_.isFunction(formatter)) {
      return formatter
    }
    if (formatter) {
      if (_.isArray(value)) {
        return value.map(v => formatter.call(this, ..._.castArray(v)))
      }
      return formatter.call(this, ..._.castArray(value))
    }
  }
  return value
}

const getRuleParams = function (def) {
  let {value, format} = def || {}
  if (!F.nil(value)) {
    return _.castArray(formatValue.call(this, format, value))
  }
  return null
}

const needsParams = function (name, def, key) {
  let {deserialized} = def || {}
  if (deserialized) {
    key = _.isFunction(name) ? key : name
    return !isStandaloneBackendRule(key)
  }
  return true
}

const getRuleFn = function (name, def) {
  def = def || {}
  const isFunction = _.isFunction(name)
  const rule = isFunction ? name : F.getFirst(name, V, F, _, this, window)

  if (_.isFunction(rule)) {
    return rule
  }
  return null
}

/**
 * Creates validation definition {rule, error}
 *
 * @param def Definition of rule
 *          ruleFn: {
 *            backendRule: 'min::{{value}}',
 *            backendRuleName: 'min',
 *            value: null,
 *            ruleFn: true,
 *            format: 'toNumber',
 *          }
 *        }
 *
 * @param key   Rule name (string)
 * @param field Field details object - {label, fieldName, name}
 * @param messageOptions Options (object) for message for parsing {template, parse}
 * @returns {Pick<*, keyof *>}
 */
export const parseFieldsValidationDef = function (def, key, field, messageOptions) {
  field = field || {}
  const skip = ['null', 'nullable']
  if(skip.includes(key)) {
    return {}
  }

  const label = field.label || _.startCase(field.fieldName || field.name)
  // debugLog({parseFieldsValidationDef: field, label, def, key})

  if (!_.isObject(def) || _.isArray(def)) {
     def = {
       value: def
    }
  }

  if (def.deserialized) {
    def = {
      ...def,
      ruleFn: getBackendRule(key),
    }
  }

  let validation = _.pick(def, ['error'])
  let {rule, value, ruleFn = key, deserialized, parameterized} = def || {}
  if (rule) {
    ruleFn = rule
  }
  // debugLog({parseFieldsValidationDef_RULE: rule, ruleFn})
  if (ruleFn) {
    //  if ruleFn is true - set key as ruleFn
    if (ruleFn === true) {
      ruleFn = key
    }

    let ruleFunction = getRuleFn.call(this, ruleFn, def)
    const hasParams = deserialized || parameterized ||
        (_.isString(ruleFn) && !standaloneRules.includes(ruleFn))
    let params = null
    if (hasParams) {
      const params = getRuleParams.call(this, def)
      // remove validation if param is nil
      const paramsRequired = needsParams.call(this, ruleFn, def, key)
      if (paramsRequired && F.nil(value)) {
        return {}
      }
      ruleFunction = ruleFunction(..._.castArray(params))
    }

    if (_.isFunction(ruleFunction)) {
      validation.rule = ruleFunction
    }
    if (_.get(messageOptions, 'template') || (!_.isEmpty(validation) && !validation.error)) {
      validation.error = getMessage(key, {params, label, field, ...messageOptions})
    }
    // debugLog({parseFieldsValidationDef_V: validation})
  }

  if (!validation || !validation.rule) {
    validation = {}
  }

  return validation
}

export const parseValidationsFromString = (rules) => {
  if (_.isString(rules)) {
    rules = rules.split('|')
  }
  const finalRules = {}
  while (rules.length) {
    let rule = rules.shift()
    const items = rule.split(':')
    const [ruleFn, params] = items
    finalRules[ruleFn] = {deserialized: true, ruleFn}
    if (params) {
      finalRules[ruleFn].parameterized = true
      finalRules[ruleFn].value = params.split(',')
    }
  }
  return finalRules
}

const ad = window.addiesaas = (window.addiesaas || {})
const adfn = ad.$fn || (ad.$fn = {})
const adValidators = adfn.validators || (adfn.validators = {})
adValidators.utils = {
  parseFieldsValidationDef,
  parseValidationsFromString,
  setValidationRule,
  parseValidations,
  parseValidationSetup,
  formatValue,
  getRuleFn,
  getMessage,
  parseMessage,
  getBackendRule,
  messages
}

export default {
  parseFieldsValidationDef,
  parseValidationsFromString,
  setValidationRule,
  parseValidations,
  parseValidationSetup
}
