export default {
  name: 're-bonus-box',
  props: {
    bonus: {
      required: true,
    },
    title: {
      type: String,
      default: 'Bonus buy'
    },
    subTitle: {
      type: String,
      default: null
    }
  },
}
