import {Vue} from '~/addiesaas'
import ReProfile from './re-profile'
Vue.component(ReProfile.name, ReProfile)

import ReProfileViewEdit from './re-profile-view-edit'
Vue.component(ReProfileViewEdit.name, ReProfileViewEdit)

import ReProfileEdit from './re-profile-edit'
Vue.component(ReProfileEdit.name, ReProfileEdit)

import ReProfileResetPassword from './re-profile-reset-password'
Vue.component(ReProfileResetPassword.name, ReProfileResetPassword)

import ReProfileGiftcards from './re-profile-giftcards'
Vue.component(ReProfileGiftcards.name, ReProfileGiftcards)

import ReProfilePlaycards from './re-profile-playcards'
Vue.component(ReProfilePlaycards.name, ReProfilePlaycards)

import ReProfileCards from './re-profile-cards'
Vue.component(ReProfileCards.name, ReProfileCards)

import ReProfileSettings from './re-profile-settings'
Vue.component(ReProfileSettings.name, ReProfileSettings)

import ReProfileOrders from './re-profile-orders'
Vue.component(ReProfileOrders.name, ReProfileOrders)

import ReProfileSubscriptions from './re-profile-subscriptions'
Vue.component(ReProfileSubscriptions.name, ReProfileSubscriptions)

import ReProfileFamily from './re-profile-family'
Vue.component(ReProfileFamily.name, ReProfileFamily)

import ReProfilePromotions from './re-profile-promotions'
Vue.component(ReProfilePromotions.name, ReProfilePromotions)

import ReProfileBilling from './re-profile-billing'
Vue.component(ReProfileBilling.name, ReProfileBilling)

import loyalties from './re-profile-loyalty'

export default {
  ReProfile,
  ReProfileEdit,
  ReProfileViewEdit,
  ReProfileResetPassword,
  ReProfileGiftcards,
  ReProfilePlaycards,
  ReProfileCards,
  ReProfileSettings,
  ReProfileOrders,
  ReProfileFamily,
  ReProfilePromotions,
  ReProfileBilling,
  ReProfileSubscriptions,
  ...loyalties
}
