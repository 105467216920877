import createPersistedState from 'vuex-persistedstate'
import * as types from './mutation-types'

const namespaced = true
const namespace = 'VoucherMobileScanner'

const state = {
  settings: {
    autoRedeem: false,
    delayBetweenScans: 500,
  },
  staySigned: false,
}

const mutations = {
  [types.SAVE_SETTINGS] (state, { settings }) {
    state.settings = Object.assign(state.settings, settings)
  },
  [types.SET_STAY_SIGNED] (state, { staySigned }) {
    state.staySigned = staySigned
  },
}

const actions = {
  saveSettings ({ commit }, payload) {
    commit(types.SAVE_SETTINGS, payload)
  },
  staySigned ({ commit }, payload) {
    commit(types.SET_STAY_SIGNED, payload)
  },
}

const getters = {
  settings: state => state.settings,
  staySigned: state => state.staySigned,
}

const modules = {
  [namespace]: {
    state,
    mutations,
    actions,
    getters,
  },
}

const plugins = [
  createPersistedState({
    paths: [namespace],
    key: namespace,
  }),
]

export { namespaced, namespace, plugins, modules }
