/* global _ */
import FormMixin from '../../../../mixins/form'
import {fv} from '../../../../lib/utils'

const formFields = {
  name: {
    label: 'name',
  },
  card: {
    component: 're-select',
    label: 'Relation',
    isWrapped: true,
    blankOption: 'Select relation',
    wrapperAttrs: {
      class: 'custom-select'
    },
    hydrate: {method: 'getFamilyRelations'},
  },
  email: {
    label: 'Email',
    validations: {
      email: {rule: 'email'}
    },
    validationMessages: {
      email: 'The :attribute must be a valid email address.'
    }
  },
  gender: {
    type: 'radios',
    label: 'Gender',
    hydrate: {method: 'getGenders'}
  },
  birthdate: {
    component: 're-date-inputs',
    label: 'Birthday',
    showInputFields: true,
  }
}

export default {
  mixins: [FormMixin],
  data() {
    return {
      fields: {
      },
    }
  },
  computed: {
    form() {
      return fv(this.formOptions, {fields: formFields})
    },
    formFields() {
      const fields = this.form.fields
      return _.mapValues(fields, (field, key) => {
        field.name = field.name || key
        return field
      })
    },
    formFieldOrder() {
      return this.form.$fieldsOrder || this.form.fieldsOrder || this.form.order ||
        _.reduce(this.formFields, (names, field, key) => {
          names.push(field.name || key)
          return names
        }, [])
    },
    formFieldOrdered() {
      return _.reduce(this.formFieldOrder, (fields, fieldName) => {
        const field = this.formFields[fieldName]
        if (field) {
          fields.push(field)
        }
        return fields
      }, [])

    },
  },
  created() {
    this.setupAutoForm()
  },
  methods: {
    hydrateForm(values) {
      values = values || {}
      _.forOwn(this.formFieldOrder, name => {
        this.fields[name] = values[name]
      })
    },
    getFormFieldAttrs(field) {
      const key = field.name
      const $$v = this.$v
      const validations = this.validations[key] || {}
      return {
        key,
        validations,
        $$v,
        field,
        vRoot: $$v.fields,
        v: $$v.fields[key],
        classes: this.getFieldContainerClasses(key),
        customErrors: this.fieldCustomErrors[key],
      }
    },
  }
}
