let mixins = {}
try {
  // noinspection NpmUsedModulesInstalled
  mixins = require('system/user/_mixins/user-history').default
} catch (e) {
  //
}

export default mixins

