/*global _ */

import ls from 'local-storage'
import {parseRelativeDateTime, getNow} from './dates'

/**
 *
 * @param name  string  Key of the cache. Key needs to be unique for each set of data
 * @param data  any     Data to store in cache using the name as key. If  data is undefined, cached data will be retrieved
 * @param options objet Configuration for cache {expires, expiresAt, forceExpire}
 *                        expires: can be a value supported by parseDateTime/parseRelativeDateTime.
 *                          default value is `never` which does not set any expiration
 *                        expiresAt: a number representing a Date's valueOf() at which the cache will be cleaned.
 *                            This needs to be set during storing the cache.
 *                            If skipped, it's value is calculated and stored parsing `expires`
 *                        forceExpire: Can be passed only during retrieving cache to actually clean the cache instead of retrieving
 * @returns {null|any}
 */
export const cache = (name, data, options) => {
  // {name, forceExpire[bool], expires[string: date|relative time], expiresAt[number: date in ms]}
  options = options || {}
  const configName = `cacheConfig.${name}`
  const cacheName = `cache.${name}`
  if (!_.isUndefined(data)) {
    const {expires, expiresAt} = options
    const config = _.omit(options, ['forceExpire'])
    if (_.isNil(expiresAt) && expires && expires !== 'never') {
      config.expiresAt = parseRelativeDateTime(expires).valueOf()
    }
    ls.set(configName, config)
    ls.set(cacheName, data)
  } else {
    const config = ls.get(configName) || {}
    const expired = options.forceExpire ||
      (config.expiresAt && config.expiresAt < getNow().valueOf())
    if (expired) {
      ls.remove(cacheName)
      ls.remove(configName)
      return null
    }
    data = ls.get(cacheName)
  }
  return data
}

export default {
  cache
}
