import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  email: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Forgot your password?')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showTitle', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', 'Enter your email address below and we\'ll get you back on track.')
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showSubTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', 'Please complete the form below')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showNotice', false)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Request reset link')
    }
  },

  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },

  showBackButton: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showBackButton', true)
    }
  },
  backButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('backButtonTitle', 'Back to login')
    }
  },

  showSuccessPopup: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showSuccessPopup', false)
    }
  },
  successTitle: {
    type: String,
    default() {
      return this.$options.$$t('successTitle', 'An email has been sent')
    }
  },

  successMessage: {
    type: String,
    default() {
      return this.$options.$$t('successMessage', 'An email has been sent to your with details on how to recover your password.')
    }
  },
  resendMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendMessage', 'The email has been resent.')
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Sending reset request')
    }
  },
  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
