/*global _ */
/**
 * @hydrate
 * hydrator
 * hydrate method
 * Can be different in different types of inputs
 * For radios, select and checkboxes optionsHydrator is used
 */
export default {
  created() {
    const hydrate = this.hydrate
    const canHydrate = hydrate && this.hydrator
    if (canHydrate) {
      this.hydrator()
      const events = _.filter(hydrate && _.castArray(hydrate.updateOn) || [])
      _.forOwn(events, event => {
        this.$$addDataFilter(event, () => {
          this.hydrator()
        })
        this.$$on(event, () => {
          this.hydrator()
        })
        if (this.context) {
          this.context.$on(event, () => {
            this.hydrator()
          })
        }
      })
    }
  }
}
