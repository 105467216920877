export const name = 're-loyalty-reward'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../../../mixins/components/complex'
import ProfileMixin from '../../../../../../mixins/profile'
import LoyaltyMixin from '../../../../../../mixins/profile/loyalty'
import ResponsiveMixin from '../../../../../../mixins/responsive'

export const mixins = [
  ComponentMixin,
  ProfileMixin,
  LoyaltyMixin,
  ResponsiveMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
  type: 'rewardItem',
  errorMessage: ''
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
