/* global _ */
import {getFirst, fv} from '../../../lib/formatters'

export default {
  computed: {

  },
  methods: {
    getRowActions(columnIndex, all) {
      const column = this.getColumn(columnIndex)
      const actions = column.rowActions || {}
      const params = {actions, vm:this, column, index: columnIndex}
      this.applyDataFilter('row-actions', params)

      return params.actions
    },
    getRowActionContainerAttrs(action, actionName) {
      const attrs = action.attrs || {}
      const classes = this.parseAttrClasses(attrs, `action-${actionName}`)
      return {
        ...attrs,
        class: classes
      }
    },
    rowActionComponent(action) {
      return action.component || 're-button'
    },
    getRowActionComponentAttrs(action, actionName) {
      const attrs = action.componentAttrs || {}
      const actionClasses = [
        `btn-action-${actionName}`
      ]
      const classes = this.parseAttrClasses(attrs, actionClasses)
      return {
        ...attrs,
        class: classes
      }
    },
    getRowActionComponentListeners(action, actionName, rowIndex) {
      let listeners = action.on || {}
      const event = action.event || actionName
      listeners = _.mapValues(listeners, async (listener) => {
        if (_.isString(listener)) {
          return getFirst(listener, this, this.context)
        }
        return listener
      })
      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      const click = listeners.click

      const row = this.getOriginalRow(rowIndex)
      const rowValues = this.getRow(rowIndex)
      const columns = this.tableColumns

      const onClick = async (e) => {
        if (this.isReadonly || this.isDisabled) {
          return
        }
        if (_.isFunction(click)) {
          click(e, rowIndex)
        }
        const params = {columns, row, rowIndex, rowValues}
        if (event) {
          this.emitTableEvent(event, {...params, action})
        }
        this.emitTableEvent('action', actionName, {...params, action})
      }
      listeners.click = onClick
      return listeners
    },
    showAction(action, actionName, $rowIndex) {
      const showIf = action.showIf
      if (showIf) {
        const $row = this.getOriginalRow($rowIndex)
        const $rowValues = this.getRow($rowIndex)
        const $columns = this.tableColumns
        const data = {$columns, $row, $rowIndex, $rowValues, ...$row}
        return this.probeReactiveIf(showIf, data)
      }
      return fv(action.show, true)
    }
  }
}
