import axios from 'axios'
export default {
  data() {
    return {
      userHistoryActions: null,
      userHistoryActionEndpoint: null,
    }
  },
  methods: {
    async addUserHistoryRecord(userEmail, actionName, payload) {
      const data = {
        type: actionName,
        user_email: userEmail,
        payload: payload || {}
      };
      await axios.post(this.userHistoryActionEndpoint, data);
    }
  },
  created() {
    const ad = window.addiesaas || {}
    const adConfig = ad.config || {}
    const adUserData = ad.user_data || {}
    this.userHistoryActions = adUserData.history_actions || {}
    this.userHistoryActionEndpoint = `${adConfig.apiUri}/user/history`
  }
}
