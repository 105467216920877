export default {
  props: {
    resendDelay: {
      type: [Object, Boolean, Number],
      default() {
        const $s = this.$options.$$s && this.$options.$$s('resendDelay', {}) || {}
        const $t = this.$options.$$t && this.$options.$$t('resendDelay', {}) || {}
        return _.merge({
          message: "Resend available in: {time}",
          timeFormat: "mm:ss 'minutes'",
        }, $s, $t)
      }
    },
  },
  data() {
    return {
      isShowingResendDelayMessage: false,
      isResendDisabled: false,
    }
  },
  computed: {
    useResendDelay() {
      return this.resendDelay && !!_.get(this.resendDelay, 'duration')
    },
    isShowingResendDelay() {
      return this.useResendDelay && this.isResendDisabled
    },
    resendCountdownAttrs() {
      return this.resendDelay
    }
  },
  methods: {
    disableResend() {
      if (this.useResendDelay) {
        this.isResendDisabled = true
      }
    },
    enableResend() {
      this.isResendDisabled = false
    },
  }
}
