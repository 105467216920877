/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E} from '../../../lib/constants'

export default function () {
  const bus = this

  bus.$on(E.INIT + ':after', ({component}) => {
    component.runEventQueue(E.INIT)
  })

  bus.$on(E.RENDERED + ':after', ({component}) => {
    component.runEventQueue(E.RENDERED)
  })
}
