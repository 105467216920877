import config from '../../auth/config'
const DRIVE_READONLY = "https://www.googleapis.com/auth/drive.readonly"
export const google = {
  ...(config['google'] || {}),
  scope: [DRIVE_READONLY]
}

export const facebook = {
  ...(config['facebook'] || {}),
  scope: ['user_photos', 'user_posts', 'email']
}

export const googleDrive = {
  ...google,
  accessType: 'offline',
  prompt: 'consent',
  requiredUrlParams: ['scope', 'access_type', 'prompt'],
  optionalUrlParams: [],
  extend: 'google',
  custom: true,
}

export default {
  google,
  facebook,
  'google-drive': googleDrive
}
