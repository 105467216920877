import component from '../component'
import form from '../form'
import processing from '../processing'

const localProps = {
  cards: {
    type: Array,
    default: null
  }
}

const addCardProps = {
  initAddCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.init.title', 'Add one more cards') //@change: initAddCardButtonTitle / addedCardButtonTitle
    }
  },
  purchaseCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('purchaseCard.button.title', 'Purchase a new card') //@change: purchaseCardButtonTitle
    }
  },
  archivePurchaseCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('archive.purchaseCard.button.title', 'Purchase a new card') //@added
    }
  },
  purchaseCardButtonLink: {
    type: [String, Object],
    default() {
      return this.$options.$$s('purchaseCard.button.link', {name: 'cards.buy'}) //@change: purchaseCardButtonLink
    }
  },

  addCardClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('addCard.classes', null) //@added:
    }
  },
  addCardTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.title', null) //@added:
    }
  },
  addCardShowTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.showTitle', true) //@added:
    }
  },

  addCardNumberLabel: {
    type: String,
    default() {
      return this.$options.$$t('addCard.number.label', null) //@added:
    }
  },
  addCardNumberPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('addCard.number.placeholder', null) //@added:
    }
  },
  addCardNumberClass: {
    type: String,
    default() {
      return this.$options.$$s('addCard.number.class', null) //@added:
    }
  },
  addCardCodeLabel: {
    type: String,
    default() {
      return this.$options.$$t('addCard.code.label', null) //@added:
    }
  },
  addCardCodePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('addCard.code.placeholder', null) //@added:
    }
  },
  addCardCodeClass: {
    type: String,
    default() {
      return this.$options.$$s('addCard.code.class', null) //@added:
    }
  },

  addCardButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.button.title', 'Add card') //@change: addCardButtonTitle
    }
  },
  addCardButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('addCard.button.class', null) //@added:
    }
  },
  addCardShowCancelButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.cancelButton.show', this.$options.$$s('addCard.cancelButton', true))//@added:
    }
  },
  addCardCancelButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.cancelButton.title', 'Cancel') //@added:
    }
  },
  addCardCancelButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('addCard.cancelButton.class', null) //@added:
    }
  },
  addingMessage: {
    type: String,
    default() {
      return this.$options.$$t('addCard.loadingMessage', 'Your card is being added...') //@added:
    }
  },

  addCardHasResendCode: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.codeResent', null) //@added:
    }
  },
  addCardResendCodeMessage: {
    type: String,
    default() {
      return this.$options.$$t('addCard.codeResent.message', null) //@added:
    }
  },
  addCardResendCodeLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('addCard.codeResent.label', null) //@added:
    }
  },
  addCardHasHelpNumberLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.help.number', this.$options.$$t('addCard.help.number', null)) //@added:
    }
  },
  addCardHelpNumberLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('addCard.help.number.label', null) //@added:
    }
  },
  addCardHasHelpCodeLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.help.code', this.$options.$$t('addCard.help.code', null)) //@added:
    }
  },
  addCardHelpCodeLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('addCard.help.code.label', null) //@added:
    }
  },

  showAddCardInModal: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('addCard.showInModal', false) //@change: showAddCardInModal
    }
  },
  addCardModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.modal.title', 'Add Card') //@change: addCardModalTitle
    }
  },
  addCardSuccessModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('addCard.modal.successTitle', 'Your card has been added.') //@change: addCardSuccessModalTitle
    }
  },


}

const queryProps = {}

// @deprecated
const communicationProps = {
  errorMessage: {
    type: String,
    default: null
  },
}
const featureProps = {
  reloadCardRoute: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('reload.route', {name: 'playcard.reload'})
    }
  },
  reloadCardType: {
    type: String,
    default() {
      return this.$options.$$s('reload.type', 'card.reload')
    }
  },
  showProductImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('product.showImage', true)
    }
  },
  productImageClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('product.imageClass', null)
    }
  },
  emptyCardMessage: {
    type: String,
    default() {
      return this.$options.$$t('emptyCardMessage', 'No card has been associated to this account. To add a card, click the button above.')
    }
  },
}

const componentProps = {
  component: {
    type: String,
    default() {
      return this.$options.$$s('components.root')
    }
  },
  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
  componentAttributes: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('componentAttrs', {})
      const $s = this.$options.$$s('componentAttrs', {})
      return _.merge({}, $t, $s)
    }
  },
}

const modalProps = {
  modalClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('modalClasses', null)
    }
  },
  infoButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('infoButtonTitle', 'Ok')
    }
  },
  infoButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('infoButtonClass', null)
    }
  },
  numberInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalTitle', 'Card Number')
    }
  },
  numberInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalHeader', null)
    }
  },
  numberInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalFooter', 'The card number can be found in the email that was sent after the purchase was done or under the \'My Cards\' section of your Account.')
    }
  },
  numberInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('numberInfoModalImgSrc', null)
    }
  },
  codeInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalTitle', 'CVV or Card Code')
    }
  },
  codeInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalHeader', null)
    }
  },
  codeInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalFooter', 'The CVV or Card Code can be found in the email that was sent after the the card was claimed by you.')
    }
  },
  codeInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('codeInfoModalImgSrc', null)
    }
  },
  resendModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('resendModalTitle', null)
    }
  },
  resendModalClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('resendModalClasses', null)
    }
  },
  resendModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('resendModalHeader', 'Resend your code using the email address associated with your Card <b>OR</b> your Card Number.')
    }
  },
  resendModalButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('resendModalButtonTitle', 'Recover')
    }
  },
  resendModalButtonClasses: {
    type: String,
    default() {
      return this.$options.$$s('resendModalButtonClasses', null)
    }
  },
  resendModalErrorMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalErrorMessage', null)
    }
  },
  resendModalRecoveredEmail: {
    type: String,
    default() {
      return this.$options.$$s('resendModalRecoveredEmail', null)
    }
  },
  resendModalSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalSuccessMessage', 'The Card code has been sent to the following email address')
    }
  },
  resendModalSuccess: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('resendModalSuccess', false)
    }
  },
  resendModalResendMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalResendMessage', 'The Card code has been resent to the following email address')
    }
  },
}

export const props = {
  ...component,
  ...form,
  ...processing,

  ...queryProps,
  ...communicationProps,
  ...componentProps,

  ...addCardProps,
  ...modalProps,
  ...featureProps,
  ...localProps
}
export default props
