export const START_TIMER = 'SET_TIMER'
export const STOP_TIMER = 'STOP_TIMER'
export const PAUSE_TIMER = 'PAUSE_TIMER'
export const UNPAUSE_TIMER = 'UNPAUSE_TIMER'
export const RESUME_TIMER = 'RESUME_TIMER'
export const RESET_TIMER = 'RESET_TIMER'
export const RESET_TIMEOUT = 'RESET_TIMEOUT'
export const RESTART_TIMER = 'RESTART_TIMER'
export const SET_TIMEOUT = 'SET_TIMEOUT'

export const SET_ELAPSED = 'SET_ELAPSED'
export const SET_REMAINING = 'SET_REMAINING'
