/*global _ */
import {fv} from '../../utils'
import {get as getConfig} from '../../config'

export const getCardCreditCashRatio = (location) => {
  const PATH = 'embed.credit_cash_ratio'
  return getConfig(PATH, 4, location)
}
export const getCardBonusTable = (location) => {
  const PATH = 'embed.bonus_table'
  return getConfig(PATH, [], location)
}
export const applyStandardBonusOnProduct = (sourceProduct, location, options = {}) => {
  if (!sourceProduct || !sourceProduct.use_standard_bonus) {
    return null
  }
  options = options || {}
  const {calculatePoints} = options
  let {amount} = options

  const product = _.cloneDeep(sourceProduct)

  const balance = product.balance || {}
  const added_bonus_points =
    balance.added_bonus_points =
      _.toFinite(fv(balance.added_bonus_points, balance.bonus_points))

  if (_.isNil(amount)) {
    amount = product.price
  } else {
    product.price = _.toFinite(amount).toFixed(2)
  }
  amount = _.toFinite(amount)

  let bonus_points = 0
  let bonus_cash = 0
  const tierItems = getCardBonusTable(location)
  const ratio = getCardCreditCashRatio(location) || 1
  const tier = _.find(tierItems, i => {
    return amount > 0 && amount >= _.toFinite(i.amount_from) && amount <= _.toFinite(i.amount_to)
  }) || {}

  if (calculatePoints) {
    balance.points = amount * ratio
  }

  bonus_points = _.toFinite(tier.bonus_amount) + _.toFinite(added_bonus_points)
  bonus_cash = bonus_points / ratio

  product.balance = _.merge({}, balance, {bonus_points, bonus_cash})

  return product
}

export default {
  getCardBonusTable, getCardCreditCashRatio, applyStandardBonusOnProduct
}
