/*global _ */
export const name = 're-reload-card'

export const defaultSections = {
  'summary': {
    showTitle: true,
    title: 'Summary',
    items: {
      balance: {
        title: 'Order Total',
        valuePath: 'localCart.subTotal',
        format: 'currency',
        class: 'order-sub-total'
      },
      bonus: {
        title: 'Tax',
        valuePath: 'localCart.tax',
        format: 'currency',
        class: 'order-tax'
      },
      points: {
        title: 'Total',
        valuePath: 'localCart.total',
        format: 'currency',
        class: 'order-total'
      }
    }
  }
}

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields, parseValidations, parseValidationSetup} from './form'

import props from './props'

export {props, validationsRules, defaultFormFields, parseValidations, parseValidationSetup}

import ComponentMixin from '../../../../mixins/components/complex-reactive'
import CardLoadMixins from '../../../../mixins/card/load'
import CartMixins from '../../../../mixins/cart'
import FormMixin from '../../../../mixins/form'
import UpsellProductMixin from '../../../../mixins/shop/upsell-product'
import ShopLocationMixin from '../../../../mixins/shop/location'
import ShopFailingLocationMixin from '../../../../mixins/shop/failing-location'
import ShopCardMixin from '../../../../mixins/shop/card/custom-amount'
import CustomFieldsMixin from '../../../../mixins/form/custom-field-properties'
import DynamicSectionsMixin from '../../../../mixins/dynamic/sections'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'
import ProductsFilterMixin from '../../../../mixins/store/products-filter'
import DesignerMixin from './designer'

export const mixins = [
  ComponentMixin,
  CardLoadMixins,
  CartMixins,
  FormMixin,
  ShopCardMixin,
  UpsellProductMixin,
  CustomFieldsMixin,
  DynamicSectionsMixin,
  FormForceUpdateMixin,
  ProductsFilterMixin,
  ShopLocationMixin,
  ShopFailingLocationMixin,
  DesignerMixin
]

export const EventBus = window.VueEventBus

const productsTyped = _.cloneDeep(
  _.pickBy(
    _.get(window.addiesaas, 'products.types', {}),
    (ps, t) => /_reload$/.test(t))
)
export const getAllProducts = () => _.flatten(_.values(productsTyped))

const propDefs = props
export const data = {
  propDefs,
  productsTyped: {},
  products: [],
  selectedProduct: null,
  isAddingToCart: false,
  showInfoModal: false,
  isNumberInfo: false,

  allCards: [],
  allCardsTyped: {},
  isLoadingCards: false,
  isSubmitting: false
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  parseValidations,
  parseValidationSetup,
  defaultSections,
  getAllProducts,
  EventBus
}
