import {name, mixins, props, data, defaultFormFields, validationsRules, EventBus} from './config'
import {setTimeoutAsync, fv} from '../../../../lib/utils'
import Countdown from '../../../../classes/utils/timer/Countdown'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      success: false,
      fields: {
        token: '',
        email: this.email || '',
        password: '',
        password_confirmation: '',
      },
      countdownRemaining: null,
    }
  },
  computed: {
    messageTemplateData() {
      const delaySeconds = fv(this.countdownRemaining, this.autoLoginRedirectBy * 1)
      const delay = delaySeconds ? (delaySeconds + ' ' + this.$tc('second|seconds', delaySeconds)) : ''
      return {
        delaySeconds,
        delay,
        email: this.fields.email,
        br: '<br/>'
      }
    }
  },
  created() {
    this.setupForm(validationsRules, defaultFormFields)
    if (!this.token) {
      this.formErrorMessage = this.$$t('form.fields.token.validations.required.message', 'Unable to find reset token')
    } else {
      this.fields.token = this.token
    }
  },
  methods: {
    getComponentExtraClasses() {
      return [{
        processed: this.success,
        disabled: !this.token
      }]
    },
    async autoRedirect() {
      let delaySeconds = this.autoLoginRedirectBy
      if (delaySeconds !== false) {
        delaySeconds = (delaySeconds * 1) || 0
        if (this.showAutoLoginRedirectCountdown) {
          this.countdownRemaining = delaySeconds
          const countdown = new Countdown(delaySeconds)
          countdown.onTick((remaining) => {
            this.countdownRemaining = remaining
          })
        }
        const delayMS = delaySeconds * 1000
        await setTimeoutAsync(delayMS)
        this.gotoLogin()
      }
    },
    async initReset() {
      this.resetFieldsValidation()
      this.$v.$touch()
      this.formSubmitting = true
      if (!this.isAnyInvalid) {
        const fields = _.clone(this.fields)
        if (!fields.token) {
          fields.token = this.token
        }
        EventBus.$emit('password-reset.init', fields)
        try {
          const [data] = await this.promise('user.password.reset', fields)
          this.emit('password-reset.success', data)
          this.success = true
          await this.autoRedirect()
        } catch (e) {
          const fields = {token: true}
          this.showFormError(e, {'scrollTo': '.password-reset-inputs-box', flattenTopMessages: true, fields})
          this.emit('password-reset.error', e)
        } finally {
          this.emit('password-reset.end', fields)
        }
      } else {
        this.scrollToFirstErrorElement()
      }
    },
  }
}
