export const name = 're-claim-promotion-reward'

import props from './props'

export {props}

import ComponentMixin from '../../../../mixins/components/complex-reactive'

import DefaultPromotionMixin from './types/default'
import AssignCouponMixin from './types/assign_coupon'
import CouponCodeMixin from './types/coupon_code'
import LoyaltyMixin from './types/loyalty_program_points_reward'
import PlaycardBonusMixin from './types/playcards_bonus'

import MessagesMixin from './messages'

export const mixins = [
  ComponentMixin,
  DefaultPromotionMixin,
  AssignCouponMixin,
  CouponCodeMixin,
  LoyaltyMixin,
  PlaycardBonusMixin,
  MessagesMixin,
]

export const EventBus = window.VueEventBus

const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
}
