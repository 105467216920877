export const name = 're-page-mashup'
import {getFirstCombination} from '../../../../lib/lodash-extras'
export {getFirstCombination}
import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'
export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
//import MashupBuilderMixin from '../../../../mixins/mashup-builder/indexx' // @todo using vuexstore remove if tests ok
import MashupBuilderMixin from '../../../../mixins/mashup-builder'
export const mixins = [ComponentMixin, MashupBuilderMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  getFirstCombination
}
