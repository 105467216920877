<template lang="pug">
  .b-onboarding__right
    .b-onboarding__title What is your greatest challenge?

    .b-onboarding__challenge
      .row
        .col-6.b-onboarding__challenge-video
          youtube.b-video-youtube(video-id="Y0Ve5Z0xIHQ")
          //- .b-video.b-video--sm
          //-   .b-video__play-container
          //-     img.b-video__play-button(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding/video-play-sm.svg`")
        .col-6.b-onboarding__challenge-text
          p Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet netus phasellus etiam quisque egestas porta lacus augue urna. Tortor nisi eleifend dictumst tempus ultricies at malesuada. Sagittis, scelerisque volutpat porttitor sit faucibus semper.

      .form-group
        label What is the greatest challenge you would like help with?
        textarea.form-control(v-model="onboarding.challenge" @blur="change")

    onboarding-back-and-forth

</template>

<script>
import OnboardingStep from '../mixins/OnboardingStep'

export default {
  mixins: [
    OnboardingStep
  ],

  methods: {
    change() {
      this.update({ challenge: this.onboarding.challenge })
    }
  }
}
</script>
