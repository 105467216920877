import {
  DEFAULT_PARSE_MAP,
  DEFAULT_TITLES,
  DEFAULT_SUBTITLES,
  DEFAULT_MESSAGES,
  DEFAULT_ERROR_TITLES,
  DEFAULT_ERROR_MESSAGES
} from './types/default'

import {
  ASSIGN_COUPON_PARSE_MAP,
  ASSIGN_COUPON_MESSAGES,
  ASSIGN_COUPON_ERROR_MESSAGES
} from './types/assign_coupon'

import {
  COUPON_CODE_PARSE_MAP,
  COUPON_CODE_MESSAGES,
  COUPON_CODE_ERROR_MESSAGES
} from './types/coupon_code'

import {
  LOYALTY_PARSE_MAP,
  LOYALTY_MESSAGES,
  LOYALTY_ERROR_MESSAGES
} from './types/loyalty_program_points_reward'

import {
  PLAYCARDS_BONUS_PARSE_MAP,
  PLAYCARDS_BONUS_MESSAGES,
  PLAYCARDS_BONUS_ERROR_MESSAGES
} from './types/playcards_bonus'

export const PARSE_MAP = {
  ...DEFAULT_PARSE_MAP,
  ...ASSIGN_COUPON_PARSE_MAP,
  ...COUPON_CODE_PARSE_MAP,
  ...LOYALTY_PARSE_MAP,
  ...PLAYCARDS_BONUS_PARSE_MAP,

}

export const TITLES = DEFAULT_TITLES

export const SUBTITLES = DEFAULT_SUBTITLES

export const ERROR_TITLES = DEFAULT_ERROR_TITLES

export const MESSAGES = {
  ...DEFAULT_MESSAGES,
  ...ASSIGN_COUPON_MESSAGES,
  ...COUPON_CODE_MESSAGES,
  ...LOYALTY_MESSAGES,
  ...PLAYCARDS_BONUS_MESSAGES,
}

export const ERROR_MESSAGES = {
  ...DEFAULT_ERROR_MESSAGES,
  ...ASSIGN_COUPON_ERROR_MESSAGES,
  ...COUPON_CODE_ERROR_MESSAGES,
  ...LOYALTY_ERROR_MESSAGES,
  ...PLAYCARDS_BONUS_ERROR_MESSAGES,
}

export default {PARSE_MAP, TITLES, SUBTITLES, MESSAGES, ERROR_TITLES, ERROR_MESSAGES}
