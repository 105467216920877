/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E} from '../../../lib/constants'
import {getBuilderComponentEmitter} from '../../../lib/utils'

const {
  UPDATED,
  ATTRIBUTE_UPDATED,
  PROPERTY_UPDATED,
  UPDATED_BEFORE,
  UPDATED_AFTER
} = E

export default function () {
  const bus = this
  const vm = this
  const targetVM = vm.pageWrapperComponent ? (vm.$children[0] || vm) : vm
  const globalEmit = getBuilderComponentEmitter(targetVM)
  const emit = (name, ...params) => {
    targetVM.$emit(name,...params)
    globalEmit(name,...params)
  }

  //{component, editor, params[name, value, oldValue]}
  targetVM.$on(UPDATED + ':after', (...args) => {

    const {component, editor, params} = args[0] || {}
    const skip = ['toolbar', 'open', 'status', 'close', 'attributes', 'traits']
    const [name, value, oldValue] = params

    const newParams = {name, value, oldValue, component, editor, params}
    if (name && !skip.includes(name) && !_.isEqual(value, oldValue)) {
      emit(PROPERTY_UPDATED + ':before', newParams)

      if (targetVM[PROPERTY_UPDATED]) {
        targetVM[PROPERTY_UPDATED](newParams)
      }
      emit(PROPERTY_UPDATED, newParams)

      emit(PROPERTY_UPDATED + `:before::${name}`, newParams)

      const methodName = _.camelCase(`designerState_${name}_Updated`)
      if (targetVM[methodName]) {
        //{component, editor, params, name, value, oldValue}
        targetVM[methodName](value, newParams)
      }

      emit(PROPERTY_UPDATED + `::${name}`, newParams)

      emit(PROPERTY_UPDATED + `:after::${name}`, newParams)

      emit(PROPERTY_UPDATED + ':after', newParams)
    }
  })

  targetVM.$on(ATTRIBUTE_UPDATED + ':before', (...args) => {

    if (targetVM[UPDATED_BEFORE]) {
      targetVM[UPDATED_BEFORE](...args)
    }

    // emulate callVue's :before
    emit(UPDATED + ':before', ...args)

    if (targetVM[UPDATED]) {
      targetVM[UPDATED](...args)
    }
    emit(UPDATED, ...args)

    if (targetVM[UPDATED_AFTER]) {
      targetVM[UPDATED_AFTER](...args)
    }
    // emulate callVue's :after
    emit(UPDATED + ':after', ...args)
  })
}
