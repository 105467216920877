export const invalid = 'The :attribute is not valid.'
export const messages = {
  "chars_only": "The :attribute may contain only these characters - :param.",
  "chars_except": "The :attribute must not contain these characters - :param.",
  "phone": invalid,
  "phone_rule": invalid,
  "phone_rule_if_not_empty": invalid,
  "phone_rule_us_or_canada": invalid,
  "phone_rule_us_or_canada_if_not_empty": invalid,
  "zip": invalid,
  "zip_rule": invalid,
  "zip_rule_if_not_empty": invalid,
  "zip_rule_us_or_canada": invalid,
  "zip_rule_us_or_canada_if_not_empty": invalid,
  "url_without_scheme_rule": invalid,
  "valid_date": invalid,
  "valid_date_before_today": invalid,
  "required_not_when": invalid,
  "required_when": invalid,
  "not_regexp": invalid,
  "regexp": invalid,
  "truthy_rule": invalid,
  "falsy_rule": invalid,
}

export default {
  messages
}
