<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Support Details
      .b-central__subtitle Please provide us with the details you would like to provide your customers when they ask for support.

      .b-central__panel.b-central__panel--w400.b-central__panel--center
        form-group(
          :validator="$v.companyName"
          label="Company name:"
          attribute="company name"
          v-slot="props"
          :messages="{ companyNameBackendError }"
        )
          input(
            v-model="$v.companyName.$model"
            :class="props.classes"
            placeholder="Your company name"
            @input="input"
            maxlength="100"
          )

        .form-group(v-if="companyNameAlternatives")
          span Available:
          a.ml-3(v-for="name in companyNameAlternatives" href="#" @click="setAlternativeCompanyName(name)") {{ name }}

        form-group(
          :validator="$v.supportEmail"
          label="Company Support Email:"
          attribute="company support email"
          v-slot="props"
        )
          input(
            v-model="$v.supportEmail.$model"
            :class="props.classes"
            type="email"
            placeholder="Your company support email"
            @input="input"
          )

        form-group(
          :validator="$v.websiteUrl"
          label="Website URL:"
          attribute="website url"
          v-slot="props"
          :messages="{ url: 'Please provide valid website address' }"
        )
          input(
            v-model="$v.websiteUrl.$model"
            :class="props.classes"
            placeholder="Type your website URL"
            @input="input"
          )

        form-group(
          :validator="$v.supportPhoneNumber"
          label="Company Support Phone Number:"
          attribute="company support phone number"
          v-slot="props"
          :messages="{ phoneNumber: 'Invalid phone number format' }"
        )
          input(
            v-model="$v.supportPhoneNumber.$model"
            :class="props.classes"
            placeholder="Your company support phone number"
            @input="input"
          )

      .b-central__footer.mb-4.b-central__footer--navigation-btn
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$invalid") Continue

      .b-central__message.text-danger(v-if="backendError") {{ backendError }}

</template>

<script>
import axios from 'axios'
import { required, email, url, maxLength } from 'vuelidate/lib/validators'
import { getFirstErrorMessage, getErrorMessagesWithKeys } from '~/lib/axios'
import PhoneNumber from 'awesome-phonenumber'

export default {

  data: () => ({
    config: window.addiesaas.config,
    companyName: '',
    supportEmail: '',
    websiteUrl: '',
    supportPhoneNumber: '',
    companyNameBackendError: null,
    backendError: null,
    submitting: false,
    tenantId: null,
    companyNameAlternatives: null,
  }),

  validations: {
    companyName: {
      required,
      maxLength: maxLength(100),
      companyNameBackendError() {
        return !this.companyNameBackendError
      },
    },
    supportEmail: {
      required,
      maxLength: maxLength(100),
      email,
    },
    websiteUrl: {
      required,
      maxLength: maxLength(250),
      urlWithoutSchema(value) {
        return /^(https?:\/\/)?(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}$/.test(value)
      },
    },
    supportPhoneNumber: {
      required,
      maxLength: maxLength(100),
      phoneNumber(value) {
        return (new PhoneNumber(value)).isValid()
      },
    },
  },

  watch: {
    companyName() {
      this.companyNameBackendError = null
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        this.companyNameAlternatives = null
        const { data } = await axios.post(`${this.config.baseUri}/api/onboarding/get-started`, {
          token: this.$route.query.token,
          email: this.$route.query.email,
          company_name: this.companyName,
          company_support_email: this.supportEmail,
          company_support_phone_number: this.supportPhoneNumber,
          website: this.websiteUrl,
        })

        this.tenantId = data.id

        this.check()
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
        const errorMessages = getErrorMessagesWithKeys(e)
        if ('company_name' in errorMessages) {
          this.companyNameBackendError = this.backendError
          this.backendError = null
          this.companyNameAlternatives = e.response.data.errors.company_name[1]
        }
        this.submitting = false
      }
    },

    async check() {
      const { data } = await axios.post(`${this.config.baseUri}/api/onboarding/get-started/check`, {
          id: this.tenantId,
      })

      if (data.ready) {
        this.$router.push({ name: 'onboarding-sign-in' })
      } else {
        setTimeout(() => {
          this.check()
        }, 3000)
      }
    },

    input() {
      this.backendError = null
    },

    setAlternativeCompanyName(name) {
      this.$v.companyName.$model = name
      this.companyNameAlternatives = null
    }
  },
}
</script>
