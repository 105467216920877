/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'
const mixins = [InputMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-checkbox',
  mixins,
  props: {
    ...props,
    value: {
      type:[String, Boolean, Number, Array, Object],
      default: false
    },
    multi: {
      type: [Boolean, Number, String],
      default: false
    },
    inputClass: {
      type: String,
      default: 'form-control-checkbox'
    },
    resetUncheckedValue: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data() {
    return {
      optionsPropName: OPTIONS_PROP,
    }
  },
  computed: {
    checkboxId() {
      return this.id || (_.uniqueId('checkbox_') + '_' + (this.name || ''))
    },
  },
  methods: {
    labelClicked(e) {
      // fix for builder where builder prevents default event of the input and it's label
    },
    onInput(event) {
      this.isDirtyState = true
      const value = _.get(event, 'target.checked', event) || (this.resetUncheckedValue ? null : false)
      this.model = value
    },
    getLabelAttrs() {
      if (!this.designerMode) {
        return {}
      }
      return {
        ...(this.multi ? {
          'data-native-element': 'Label'
        } : {
          'data-prop-element': 'Label'
        })
      }
    },
    getLabelExtraClasses() {
      return [`re-input-label-checkbox`]
    }
  }
}
