export default {
  computed: {
    rewardItemsCount() {
      return this.rewardItems.length
    }
  },
  watch: {
    rewardItemsCount: {
      immediate: true,
      handler(val) {
        this.canShowComponent = !!val
      },
    }
  }
}
