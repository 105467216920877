/*global _ */
import {templiParse, map, fv} from '../../../../../lib/formatters'
import {parser as conditionParser} from '../../../../../lib/conditionals'
import {optionsHydrator} from '../../../../../lib/form/input'

import inputProps from './../../../../../props/inputs'
import optionsProps from './../../../../../props/inputs-options'

import ReactiveMixin from '../../../../../mixins/mashup-builder/reactives'
import InputMixin from '../../../../../mixins/form/input'
import DesignerMixin from './../designer'
const mixins = [InputMixin, ReactiveMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

export default {
  mixins,
  props: {
    ...inputProps,
    ...optionsProps,
    label: {
      type: String,
      default: null
    },
    inputClass: {
      type: String,
      default: 'custom-select'
    },
    inputErrorClass: {
      type: String,
      default: 'is-invalid form-control-error'
    },
    component: {
      type: String
    },
    componentAttrs: {
    },
    isWrapped: {
      type: [String, Number, Boolean],
      default: false
    },
    wrapperAttrs: {
    },
    reduceOptions: {
    },
    reduceLabel: {
    },
    reduceValue: {
    },
    filter: {
    },
    sortLabels: {
      type: [String, Number, Boolean, Function],
      default: false // true/asc/desc/ascstring/descstring/ascnumber/descnumber
    },
    sortValues: {
      type: [String, Number, Boolean, Function],
      default: false // true/asc/desc/ascstring/descstring/ascnumber/descnumber
    },
    sortBy: {
    },
    cloneOptions: {
      default: true
    },
    fallbackValue: {}
  },
  data() {
    return {
      innerOptions: this.options,
      optionsPropName: OPTIONS_PROP,
      customConfig: {
        isWrapped: false,
        wrapperAttrs: {
          'class': null
        },
      }
    }
  },
  computed: {
    selectedOption() {
      return this.optionsItems[this.model]
    },
    optionsItems() {
      return _.mapKeys(this.optionsList, (option, index) => {
        return this.getOptionValue(option, index, this.optionsList)
      })
    },
    optionsList() {
      let source = this.innerOptions
      if (_.isFunction(source)) {
        source = source()
      }
      if (this.cloneOptions) {
        try {
          const cloned = _.cloneDeep(source)
          source = cloned
        } catch (e) {
          //
        }
      }

      const filter = this.filter
      if (filter) {
        if (_.isFunction(filter)) {
          source = filter.call(this, source)
        } else {
          // filtering using condition parser
          // `filter` must be an array
          //    - first element - is the value to check
          //    - second is the operator:
          //        - [alias] empty, notEmpty, equals, noEquals, contains, notContains, greater, less, greaterOrEquals, lessOrEquals, oneOf, nonOf
          //        - [formatter function name] formatter function name which can be extracted from formatters lib, lodash, vm, vm.context
          //        - [function] it can also be a function
          //    - third is the value to check against
          //        - it is blank for empty or notEmpty (or operator whose noValue is true)
          //        - it can be blank for non-alias operator
          //        - it can be an array of additional parameters for the non-alias operator
          //    - fourth is the default value
          const data = {this: this, context: this.context}
          if (_.isArray(source)) {
            source = source.filter((item, index) => {
              const conditions = map(filter, f => templiParse(f, {...data, item, index}))
              return conditionParser.apply(this, _.values(conditions))
            })
          } else {
            source = _.pickBy(source, (item, index) => {
              const conditions = map(filter, f => templiParse(f, {...data, item, index}))
              return conditionParser.apply(this, _.values(conditions))
            })
          }
        }
      }

      const sortBy = this.sortBy
      if (sortBy) {
        if (_.isFunction(sortBy)) {
          source = sortBy.call(this, source)
        } else {
          source = _.sortBy(source, sortBy)
        }
      }

      const reducer = this.reduceOptions
      if (reducer) {
        if (_.isFunction(reducer)) {
          source = reducer.call(this, source)
        } else {
          source = this.parseReactiveValues(reducer, source)
        }
      }

      const sourceIsArray = _.isArray(source)
      const useArrayOptions = this.useArrayOptions

      let options = []
      _.forOwn(source, (name, value) => {
        if (_.isObject(name)) {
          options.push(name)
        } else {
          if (sourceIsArray && !useArrayOptions) {
            options.push({value: name, name})
          } else {
            options.push({value, name})
          }
        }
      })

      const sortLabels = this.sortLabels
      if (sortLabels) {
        if (_.isFunction(sortLabels)) {
          options = sortLabels.call(this, options)
        } else {
          this.sortOptions(
            options,
            item => {
              return this.getOptionLabel(item, null, options)
            },
            sortLabels
          )
        }
      } else {
        const sortValues = this.sortValues
        if (sortValues) {
          if (_.isFunction(sortValues)) {
            options = sortValues.call(this, options)
          } else {
            this.sortOptions(
              options,
              item => {
                return this.getOptionValue(item, null, options)
              },
              sortLabels
            )
          }
        }
      }

      return options
    },
    hasBlankOption() {
      return !_.isNil(this.blankOption)
    },
    hasEndBlankOption() {
      return !_.isNil(this.endBlankOption)
    },
    inputComponent() {
      return this.component
    },
    hasInputComponent() {
      return this.component && !this.hasInputPlugin
    },
    hasInputPlugin() {
      return this.component && _.startsWith(this.component, 'plugin-')
    },
    inputComponentClasses() {
      const classNames = _.get(this.wrapperAttrs, 'class', _.get(this.customConfig.wrapperAttrs, 'class'))
      return [classNames]
    },
    hasInputWrapper() {
      return this.isWrapped || this.customConfig.isWrapped
    },
    inputWrapperClasses() {
      const classNames = _.get(this.wrapperAttrs, 'class', _.get(this.customConfig.wrapperAttrs, 'class'))
      return [classNames]
    },
    propsAttrs() {
      return {
        ...this.$$attrs(),
        ...this.$options.propsData,
      }
    },
    componentProps() {
      return _.omit({
        ...this.propsAttrs,
        ...this.componentAttrs || {},
        context: this.context
      }, ['component', 'componentAttrs'])
    },
    slotAttrs() {
      return {
        model: this.model,
        options: this.optionsList,
        blankOption: this.blankOption,
        onInput: this.onInput,
        inputAttrs: this.inputAttrs,
        allAttrs: this.allAttrs,
        validationAttrs: this.validationAttrs,
        isDirty: this.isDirtyState,
        ...(this.hasInputWrapper ? (this.wrapperAttrs || {}): {}),
        ...(this.hasInputComponent ? (this.componentAttrs || {}): {})
      }
    }
  },
  watch: {
    innerOptions: {
      handler(values) {
        this.checkFallbackValue()
      },
      deep: true
    },
    innerValue: {
      handler(value) {
        this.checkFallbackValue(value)
      }
    },
    options: {
      handler(newValue) {
        this.innerOptions = newValue
      },
      deep: true
    }
  },
  methods: {
    sortOptions(options, valueFinder, order) {
      options.sort((a, b) => {
        let valueA = valueFinder(a)
        let valueB = valueFinder(b)
        if (valueA === valueB) {
          return 0
        }
        const isDesc = _.startsWith(order, 'desc')
        const isNumber = _.endsWith(order, 'number')
        const isString = _.endsWith(order, 'string')
        if (isNumber) {
          valueA = parseFloat(valueA)
          valueA = parseFloat(valueB)
        } else if (isString) {
          valueA = (valueA + '').replace(/\s/g, '').toLowerCase()
          valueB = (valueB + '').replace(/\s/g, '').toLowerCase()
        }
        const descFactor = isDesc ? -1 : 1
        const isALesser = valueA < valueB
        return descFactor * (isALesser? -1 : 1)
      })
    },
    getOptionValue(option, index, options) {
      option = fv(option, options[index])
      const reducer = this.reduceValue
      if (_.isFunction(reducer)) {
        return reducer.call(this, option, index, options)
      }
      if (reducer) {
        return this.parseReactiveValues(reducer, option)
      }
      const {value, id, name, label, title, text} = option || {}
      return fv(value, id, name, label, title, text)
    },
    getOptionAttrs(option, index, options) {
      option = fv(option, options[index])
      return option.attrs || {}
    },
    getOptionLabel(option, index, options) {
      option = fv(option, options[index])
      const reducer = this.reduceLabel
      if (_.isFunction(reducer)) {
        return reducer.call(this, option, index, options)
      }
      if (reducer) {
        return this.parseReactiveValues(reducer, option)
      }
      const {value, id, name, label, title, text} = option || {}
      return fv(name, label, title, text, value, id)
    },
    getLabelExtraClasses() {
      return [`re-input-label-select`]
    },
    onInput(event) {
      const value = _.get(event, 'target.value', event)
      this.isDirtyState = true
      this.model = value
    },
    checkFallbackValue() {
      const fallback = this.fallbackValue
      if (!_.isNil(fallback)) {
        const value = this.innerValue
        const values = this.innerOptions
        if (!_.has(values, value)) {
          this.model = fallback
        }
      }
    },
    hydrator: optionsHydrator,
  }
}
