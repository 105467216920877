/*global _ */
import draggable from 'vuedraggable'
import props from './../../../../props/inputs'
import InputMixin from '../../../../mixins/form/input'
import DesignerMixin from './designer'

const components = {draggable}
const mixins = [InputMixin, DesignerMixin]

export const dragDefaults = {
  tag: 'ul',
  handle: '.handle',
  draggable: '.table-row',
  group: {name: 'table', put: false, pull: false},
  direction: 'vertical',
  forceFallback: true,
  animation: 200,
  easing: 'cubic-bezier(0.32, 0, 0.67, 0)',
  ghostClass: 'drag-ghost',
  chosenClass: 'drag-chosen',
  dragClass: 'dragged-item',
}

export const OPTIONS_PROP = 'advancedSettings'

export default {
  name: 're-options-table',
  components,
  mixins,
  props: {
    ...props,
    label: {
      type: String,
      default: "Options"
    },
    options: {
      type: [Array, Object],
      default() {
        return {
          columns: [
            'label', 'value'
          ],
          returnObject: true,
          draggable: true,
          deletable: true,
          addButtonOnTop: true,
        }
      }
    },
    dragOptions: {
      type: [Object],
      default() {
        return {}
      }
    },
    inputClass: {
      type: String,
      default: 'custom-options-table'
    },
    inputErrorClass: {
      type: String,
      default: 'is-invalid form-control-error'
    }
  },
  data() {
    return {
      optionsPropName: OPTIONS_PROP,
      table: []
    }
  },
  computed: {
    columns() {
      return this.options.columns
    },
    columnLength() {
      return _.values(this.columns).length
    },
    tableObject() {
      return _.map(this.table, (item) => _.zipObject(this.columns, item))
    },
    draggableOptions() {
      return {
        ...dragDefaults,
        ...this.dragOptions
      }
    }
  },
  watch: {
    table(v, o) {
      this.model = _.cloneDeep(this.options.returnObject ? this.tableObject : v)
    }
  },
  created() {
    let val = this.value || []
    if (!_.isEmpty(val)) {
      if (_.isObject(val) && !_.isArray(val)) {
        val = _.reduce(val, (a, label, value) => {
          a.push({value, label})
          return a
        }, [])
      }
      const first = _.first(val)
      if (!_.isEmpty(first) && !_.isArray(first) && _.isObject(first)) {
        const cols = this.columns
        val = _.reduce(val, (o, i) => {
          if (!_.isEmpty(cols)) {
            o.push(_.map(cols, c => i[c] || ''))
          } else {
            o.push(_.values(i))
          }
          return o
        }, [])
      }
    }
    this.table = val
  },
  methods: {
    getClasses(path) {
      return [_.get(this.options, path + '.classes')]
    },
    getLabelExtraClasses() {
      return this.getClasses('label.classes')
    },
    getNewRow() {
      return new Array(this.columnLength)
    },
    addRow() {
      if (_.isNil(this.table)) {
        this.table = []
      }
      return this.table.push(this.getNewRow())
    },
    deleteRow(rowIndex) {
      this.table.splice(rowIndex, 1)
    }
  }
}
