export const name = 're-buy-giftcard-extended'

// generic setup
import {
  allValidationsRules as validationsRules,
  defaultFormFields,
} from './form'

import props from './props'

export {props, validationsRules, defaultFormFields}
// noinspection NpmUsedModulesInstalled
import GiftcardFormMixin from '../../../../mixins/external/giftcard-form'
import CartMixins from '../../../../mixins/cart'
import ComponentMixin from '../../../../mixins/components/complex-reactive'
import FormMixin from '../../../../mixins/form'
import FormForceUpdateMixin from '../../../../mixins/form/force-update-onload'
import ProductsFilterMixin from '../../../../mixins/store/products-filter'
import ShopFailingLocationMixin from '../../../../mixins/shop/failing-location'
export const mixins = [
  ComponentMixin,
  FormMixin,
  GiftcardFormMixin,
  CartMixins,
  FormForceUpdateMixin,
  ProductsFilterMixin,
  ShopFailingLocationMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
