export const name = 're-card-balance'

export const defaultSections = {
  'playcard': {
    showTitle: false,
    items: {
      balance: {
        title: 'Balance',
        valuePath: 'card.balance',
        group: 'sum',
        format: 'currency',
        class: 'card-balance'
      },
      bonus: {
        title: 'Bouns',
        valuePath: 'card.bonus',
        group: 'sum',
        format: 'currency',
        class: 'card-bonus'
      },
      points: {
        title: 'Points',
        valuePath: 'card.points',
        group: 'sum',
        format: 'toNumber',
        class: 'card-points'
      }
    }
  },
  'giftcard': {
    showTitle: false,
    items: {
      balance: {
        title: 'Balance',
        valuePath: ['card.cash', 'card.bonus_cash'],
        group: 'sum',
        format: 'currency',
        class: 'card-balance'
      },
      bonus: {
        title: 'Bouns',
        valuePath: 'card.bonus',
        group: 'sum',
        format: 'currency',
        class: 'card-bonus'
      },
      points: {
        title: 'Points',
        valuePath: 'card.points',
        group: 'sum',
        format: 'toNumber',
        class: 'card-points'
      }
    }
  }
}

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'

export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../../mixins/components/complex-reactive'
import CardLoadMixins from '../../../../../mixins/card/load'
import CartMixins from '../../../../../mixins/cart'
import FormMixin from '../../../../../mixins/form'
import FormForceUpdateMixin from '../../../../../mixins/form/force-update-onload'
import DynamicSectionsMixin from '../../../../../mixins/dynamic/sections'
import FailingCardLocationMixin from '../../../../../mixins/shop/card/failing-location'
import EventsMixin from './events'

export const mixins = [
  ComponentMixin,
  CardLoadMixins,
  CartMixins,
  FormMixin,
  FailingCardLocationMixin,
  DynamicSectionsMixin,
  FormForceUpdateMixin,
  EventsMixin
]

export const EventBus = window.VueEventBus

const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  defaultSections,

  EventBus
}
