// this is a helper library to render components using addiesaas inside a target html element
import _ from '../../lib/lodashe/slim'

import jquery from 'jquery/dist/jquery.slim.min'

const ME = 'renderer'
const AD = 'addiesaas'
const RE = 'RocketEffect'
const RE_COMPONENT = RE + ' component'
const COMPONENT_CLASS = 'addiesaas-component'
const WITH_PRELOADER_CLASS = 'with-preloader-message'
const PRELOADER_CLASS = 'addiesaas-preloader-message'

const getComponent = (component, options) => {
  options = options || {}
  let name = component
  if (!name) {
    throw `No ${RE_COMPONENT} provided`
  }
  const isNotStrict = options.strict === false

  const defs = options.components ||
    _.get(window[AD], 'frontend.components', {})
  let componentDef = defs[name]
  if (!componentDef) {
    const fullName = 're-core-page-' + name
    componentDef = defs[fullName]
    if (componentDef) {
      name = fullName
    }
  }

  const componentOptions = options.component || {}
  const def = {...componentDef || {}, ...componentOptions}
  if (componentDef) {
    return {name, ...def}
  }
  if (isNotStrict) {
    return {name, ...def}
  }
  emit(options, 'probe.component:failed', {component})
  throw `${RE_COMPONENT} "${component}" not defined`;
}

const getTarget = (target, options) => {
  options = options || {}
  const appendToBody = options.appendToBody
  const $ = jquery
  let $target = $(target)
  const isTargetBody = _.indexOf(['body', 'html', 'document'], target) >= 0
  if (!isTargetBody && $target.length) {
    return $target
  }
  if (isTargetBody || appendToBody) {
    $target = $('<div></div>')
    $target.appendTo('body')
    emit(options, 'probe.target.body', {target: $target})
    return $target
  }

  if (!target) {
    emit(options, 'probe.target:undefined', {target})
    throw `No ${RE_COMPONENT} target defined`
  }
  if ($target.length) {
    emit(options, 'probe.target:failed', {target})
    throw `${RE_COMPONENT} target ${target} not found`
  }
}

const prepareTarget = (config) => {
  const {target, component, options = {}} = config
  target.attr('v-cloak', true)
  const classNames = _.fv(options.classes, [])
  if (classNames !== false) {
    const container = _.kebabCase(`${AD}-${component.name}-container`)
    target.addClass([
      COMPONENT_CLASS,
      container,
      _.uniqueId(container + '-')
    ])
    if (classNames) {
      target.addClass(classNames)
    }
  }
  emit(options, 'prepare.target', {target})
  setPreloader(_.fv(options.preloader, {}), target, options)
}

const setPreloader = (config, target, options) => {
  if (config === false) {
    emit(options, 'prepare.preloader:disabled', {options})
    return
  }
  if (config === true) {
    config = {}
  }
  config = config || {}

  target.addClass(WITH_PRELOADER_CLASS)

  const selector = `.${PRELOADER_CLASS}`
  const $ = jquery

  let preloader = target.children(selector).first()
  if (!preloader.length) {
    preloader = $(`<div class="${PRELOADER_CLASS}"></div>`)
      .prependTo(target)
    const classes = config.classes
    if (classes) {
      preloader.addClass(classes)
    }
    const message = _.fv(config.message, 'Loading')
    if (message) {
      preloader.html(message)
    }
  }
  emit(options, 'prepare.preloader', {preloader})
}

const prepareComponent = (config) => {
  const {target, component, options} = config || {}

  const slot = getSlot(target)
  const $ = jquery

  const tag = component.name
  const props = getProps(config)
  emit(options, 'component.attributes', {attributes: props})

  let domAttributes = getDomAttributes(_.get(options, 'component.domAttributes'))
  if (domAttributes) {
    emit(options, 'component.domAttributes', {attributes: domAttributes})
  }
  const componentHTML = $(`<${tag} ${domAttributes}></${tag}>`)
  emit(options, 'component.html', {component: componentHTML, slot})

  _.forOwn(props, (value, key) => {
    componentHTML.attr(key, value)
  })

  const componentElement = $(componentHTML).append(slot)
  emit(options, 'component.element', {element: componentElement})

  componentElement.appendTo(target)
}

const prepareRenderOptions = (config) => {
  const {options = {}, target} = config || {}
  const renderOptions = {
    el: target[0],
    ...options.renderOptions || {},
  }
  return renderOptions
}

const getSlot = (target) => {
  const slot = target.children(`:not(.${PRELOADER_CLASS})`)
  return slot.detach()
}

const getProps = (config, serialized = false) => {
  const {component = {}, options = {}} = config || {}
  const qs = _.getQS() || {}
  const qSvars = component.vars || []

  const props = _.get(options, 'component.attributes') || {}

  const items = _.reduce(qSvars, (varItems, varName) => {
    const attrName = _.kebabCase(varName)
    if (!_.isNil(qs[varName])) {
      const value = _.get(qs, varName, _.get(qs, attrName))
      if (!_.isNil(value)) {
        varItems[attrName] = value
      }
    }
    return varItems
  }, props)

  let attributes = _.mapKeys(items, (v, k) => {
    k = _.kebabCase(k)
    if (!_.isString(v)) {
      k = `:${k}`
    }
    return k
  })

  attributes = _.mapValues(attributes, (v, k) => {
    if (_.isObject(v)) {
      v = JSON.stringify(v)
    }
    return v
  })

  if (serialized) {
    return getDomAttributes(attributes)
  }

  return attributes
}

const getDomAttributes = (attrs) => {
  if(attrs) {
    if (_.isArray(attrs)) {
      return attrs.join(' ')
    }
    if (_.isObject(attrs)) {
      return _.values(_.mapValues(attrs, (v, k) => {
        return `${k}="${v}"`
      })).join(' ')
    }
    if (_.isString(attrs)) {
      return attrs
    }
  }
  return ''
}

const emit = (options, event, ...args) => {
  let emitter = options.emit
  if (!emitter || !_.isFunction(emitter)) {
    emitter = (() => {})
  }
  emitter(`${ME}.${event}`, ...args)
}

export const render = (component, target, options) => {
  options = options || {}
  let config = _.isObject(component) ? component : {component, target, options}
  if (_.isObject(component)) {
    ({component, target, options = {}} = config)
  }

  config.component = getComponent(component, options)
  emit(options, `probe.component`, {component, config})
  const $target = config.target = getTarget(target, options)
  emit(options, `probe.target`, {target: $target})

  prepareTarget(config)
  prepareComponent(config)
  const renderOptions = prepareRenderOptions(config)

  const callback = function () {
    setTimeout(() => {
      const componentName = _.get(config, 'component.name')
      const eventParams = {config, component, target, options}
      emit(options, `render:before`, {...eventParams, renderOptions})
      const renderer = window[AD].initSync || (window[AD].spa && window[AD].spa.initSync)
      const app = renderer(renderOptions, {componentName, target})
      const instance = app.$children[0]
      emit(options, `rendered.${componentName}`, {...eventParams, instance})
      emit(options, `rendered`, {...eventParams, instance})
      if (_.isFunction(options.rendered)) {
        options.rendered.call(app, instance, eventParams)
      }
    }, 0)
  }

  if (options.getCallback) {
    return callback
  }

  setTimeout(() => {
    callback()
  }, 0)
}

export default render
