/*global _, addiesaas */
export const filterByLocation = (products, location, isStrict) => {
  return _.filter(products, product => {
    const productLocation = product.location_id
    const supportedLocation = product.supported_locations || []
    const noLocation = !productLocation && _.isEmpty(supportedLocation)
    return (!isStrict && noLocation) ||
      supportedLocation.includes(location) ||
      productLocation === location
  })
}

export const getProducts = (flatten = true) => {
  const products = _.get(addiesaas, 'products.types')
  if (flatten) {
    return _.flatMap(products)
  }
  return products
}
export const getProductsByType = (type) => {
  if (_.isEmpty(type)) {
    return getProducts(true)
  }
  return _.flatMap(_.pick(_.get(addiesaas, 'products.types'), _.castArray(type)))
}

export const getProductById = (id, getCopy = true) => {
  const products = getProducts()
  const product =  _.find(products, p => p.id == id)
  if (getCopy) {
    return _.cloneDeep(product)
  }
  return product
}

export default {filterByLocation, getProducts, getProductsByType, getProductById}
