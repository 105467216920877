export const name = 're-core-page-reload-a-card'
export const component = 're-reload-card'

import {factory} from '../../../../../lib/page'

const props = {
  number: {},
  card: {},
  product: {},
  terms: {},
  owner: {},
  token: {},
  item: {}
}

const def = {
  created() {
    window.VueEventBus.$on('buy.card-reload.add-to-cart', () => {
      this.$router && this.$router.push({ name: 'cart' })
    })
  }
}
export default factory(name, {props, component, def})

