export default {
  methods: {
    async initialAutoLoadCompleted() {
      return new Promise((resolve) => {
          if (this.isInitialAutoloaded) {
            resolve(true)
          } else {
            this.$watch('isInitialAutoloaded', autoloaded => {
              if (autoloaded) {
                resolve(true)
              }
            })
          }
        })
    },
    async autoLoadAll(parts) {
      if (parts && _.isString(parts)) {
        parts = parts.split(',')
      }
      if (_.isArray(parts)) {
        parts = _.uniq(parts)
      }
      await this.$$gemitAsync('profile.autoload.init.all', parts)
    },
    autoLoadProfile: _.debounce(async function () {
      if (this.validateAuthCookie()) {
        const user = await this.fetchUserSilent()
        if (user) {
          this.$$applyDataFilters('profile.autoload.data.user', user)
        }
        return user
      }
      return null
    }, 500,{leading: true, trailing: false}),

    autoLoadOrders: _.debounce(async function (payload) {
      if (!this.validateAuthCookie()) {
        return null
      }
      const {data: orders} = await this.invokeService('user.orders.get', payload)
      this.$$applyDataFilters('profile.autoload.data.orders', orders)
      _.set(this.User, 'orders', orders)
      this.updateProfileDataItem({'states.orders': orders})
      return orders
    }, 500,{leading: true, trailing: false}),

    autoLoadFamily: _.debounce(async function () {
      if (!this.validateAuthCookie()) {
        return null
      }
      const family = await this.User.family(true)
      this.$$applyDataFilters('profile.autoload.data.family', family)
      const members = _.map(family || [], f => _.cloneDeep(f.$$data))
      this.updateProfileDataItem({'states.members': members})
      return family
    }, 500, {leading: true, trailing: false}),

    autoLoadPlaycards: _.debounce(async function () {
      if (!this.validateAuthCookie()) {
        return null
      }
      const cards = await this.invokeService('user.card.playcards.get')
      const params = {cards, vm: () => this}
      this.$$applyDataFilters('profile.autoload.data.cards', cards)
      this.$$applyDataFilters('playcard.cards', params)
      _.set(this.User, 'cards.playcard', params.cards)
      this.updateProfileDataItem({'states.cards.playcard': params.cards})
      return cards
    }, 500, {leading: true, trailing: false}),
    async autoLoadPlaycard(...args) {
      return await this.autoLoadPlaycards(...args)
    },

    autoLoadPromotions: _.debounce(async function () {
      if (!this.validateAuthCookie()) {
        return null
      }
      const promotions = await this.invokeService('user.promotions.get')
      const params = {promotions, vm: () => this}
      this.$$applyDataFilters('profile.autoload.data.promotions', params)
      _.set(this.User, 'promotions', params.promotions)
      this.updateProfileDataItem({'states.promotions': params.promotions})
      return params.promotions
    }, 500, {leading: true, trailing: false}),

    autoLoadPaymentMethods: _.debounce(async function () {
      if (!this.validateAuthCookie()) {
        return null
      }
      const cards = await this.invokeService('user.billing.paymentMethods.get')
      const params = {cards, vm: () => this}
      this.$$applyDataFilters('profile.autoload.data.paymentMethods', params)
      _.set(this.User, 'paymentMethods', params.cards)
      this.updateProfileDataItem({'states.paymentMethods': params.cards})
      return params.cards
    }, 500, {leading: true, trailing: false}),
  }
}
