/* global _ */
import {nil} from '../../../../lib/formatters'

export default {
  data() {
    return {
      editingMember: false,
      editingRow: null,
    }
  },
  computed: {
    canShowHeaderButtons() {
      return !this.editingMember && !this.isPromising
    },
    isAddingMember() {
      return this.editingMember === true
    },
    isEditingMember() {
      return _.isObject(this.editingMember)
    }
  },
  methods: {
    async initDelete({rowIndex}) {
      // await this.pingUser()
      const confirm = await this.confirmAsync(
        "Do you want to delete the family member?",
        "Confirm Delete",
      )
      const member = this.members[rowIndex]
      if (confirm) {
        this.startPromising('Deleting member')
        try {
          await this.User.deleteFamilyMember(member.id)
          await this.fetch(true)
          this.stopPromising()
        } catch (e) {
          this.stopPromising()
          await this.alertAsync(e.message)
        }
      }
    },
    cancelEdit() {
      this.editingMember = false
      this.editingRow = null
      this.hydrateForm()
    },
    async initAdd() {
      this.showEditForm()
    },
    async initEdit({rowIndex}) {
      const member = this.members[rowIndex]
      this.showEditForm(member)
      this.editingRow = rowIndex
    },
    showEditForm(member) {
      const data = member// && member.$$data
      this.hydrateForm(_.cloneDeep(data || {}))
      this.editingMember = member || true
      this.resetFieldsValidation()
      if (this.validateFormFieldOnDirty) {
        this.formSubmitting = true
      }
    },
    async updateOrCreate() {
      this.$v.$touch()
      if (this.isAnyInvalid) {
        this.scrollToFirstErrorElement(true)
        return
      }
      let skipFields = (this.isEditingMember ? this.skipFieldsOnUpdate : []) || []
      if (_.isString(skipFields)) {
        skipFields = skipFields.split(',')
      }

      const fields = this.fields
      const payload = _.omitBy(fields, (value, key) => {
        return nil(value) || skipFields.includes(key)
      })
      const member = this.editingMember

      try {
        if (member === true) { // create
          this.startPromising('Adding member')
          // await this.pingUser()
          await this.User.createFamilyMember(payload)
        } else {
          this.startPromising('Updating member')
          // await this.pingUser()
          const originalMember = this.User.getFamilyMember(member.id)
          await originalMember.update(payload)
        }
        this.cancelEdit()
        await this.fetch(true)
      } catch (e) {
        this.showFormError(e)
      } finally {
        this.stopPromising()
      }
    }
  }
}
