/*global _ */
import {getFirstErrorMessage} from '~/lib/axios'
import props from './props'
import ComponentMixin from '../../../../../mixins/components/complex-reactive'
import F, {parseNumber} from '../../../../../lib/formatters'
import FormMixin from './form'

const EventBus = window.VueEventBus

export default {
  name: 're-transfer-card-balance',
  events: 'card.transferBalance',
  props,
  mixins: [
    ComponentMixin,
    FormMixin
  ],
  data() {
    return {
    }
  },
  computed: {
    isRequestError() {
      return !_.isEmpty(this.errorMessage)
    },
    submitButtonAttrs() {
      return {
        disabled: this.isAnyInvalid
      }
    },
    fromCard() {
      return this.findCard(this.fields.from)
    },
    toCard() {
      return this.findCard(this.fields.to)
    },
    formatterDataSource() {
      const fieldFormatsSettings = {
        to: {
          format: [
            ["findCard", [{"get": "number"}]]
          ]
        },
        ...this.$$s('fieldFormats', {})
      }
      const fieldFormats = _.omit(this.$$t('fieldFormats', {}), ['$tc'])
      const fieldFormatsTc = _.pick(this.$$t('fieldFormats', {}), ['$tc'])
      const tcFormats = {
        balance: {
          omitBlank: true,
          omitZero: true,
          joinBy: [', ', ' and '],
          template: [
            '[#balance=>0|{n} credit|{n} credits#]',
            '[#tickets=>0|{n} ticket|{n} tickets#]',
          ],
          field: true
        },
        were: {
          field: ['balance', 'tickets'],
          groupBy: 'nsum',
          template: 'was|were'
        },
        ...(fieldFormatsTc || {})
      }

      const allFormats = _.merge(
        {},
        fieldFormats,
        fieldFormatsSettings
      )
      const $fields = _.mapValues(allFormats, (def, key) => {
        const val = this.fields[key]
        return this.formatReactiveValues(val, def, this.fields)
      })

      _.forOwn(tcFormats, (def, key) => {
        if (_.isString(def)) {
          def = {template: def, field: key}
        }
        const {template, field: defField} = def
        if (_.isArray(template)) {
          let values = template.map(t => this.$$tc(t, t, this.fields))
          if (def.omitZero) {
            values = values.filter(v => 0 !== (1 * v))
          }
          if (def.omitBlank) {
            values = values.filter(v => !!_.trim(v))
          }

          if (def.joinBy) {
            def.joinBy = _.castArray(def.joinBy)
            let delimiter = def.joinBy.pop()
            let newValues = values.splice(-2)
            newValues = [
              newValues.join(delimiter)
            ]
            values.push(newValues)
            delimiter = def.joinBy.pop() || delimiter
            values = values.join(delimiter)
          }
          $fields[key] = values

        } else {
          let val = this.fields[defField] || 0
          if (_.isArray(defField)) {
            val = _.values(_.pick(this.fields, defField))
            if (def.groupBy) {
              const method = F.getFormatter(def.groupBy)
              if (method) {
                val = parseNumber(method.call(this, val))
              }
            }
          }
          $fields[key] = this.$$tc(template, val + ' ' + key, val, this.fields)
        }
      })
      return {
        ...(this.fields || {}),
        $fields,
        '$context': this.context,
      }
    },
    confirmationMessage() {
      const defaultMessage = 'Do you confirm {{$fields.balance}} transfer to {{$fields.to}}?'
      const message = this.$$t('confirmation.message', defaultMessage)
      return this.parseReactiveValues(message, this.formatterDataSource)
    },
    confirmationTitle() {
      return this.$$t('confirmation.title', this.genericTitle)
    },
    confirmationOptions() {
      const defaultOptions = {
        denyLabel: 'No',
      }
      const $$s = this.$$s('confirmation.options', defaultOptions)
      const $$t = this.$$t('confirmation.options', defaultOptions)
      return _.merge({}, $$s, $$t)
    },
    genericTitle() {
      return this.$$t('modal.title', 'Transfer Balance')
    },
    successMessage() {
      const defaultMessage = 'Balance transferred successfully.'
      const message = this.$$t('success.message', defaultMessage)
      return this.parseReactiveValues(message, this.formatterDataSource)
    },
  },
  created() {
    this.init()
  },
  methods: {
    findCard(id, options = {}) {
      options = options || {}
      const findBy = options.findBy || 'id'
      const item = _.find(this.cards, (card) => {
        if (_.isFunction(findBy)) {
          return findBy(card)
        }
        return _.get(card, findBy) === id
      })
      const getter = options.get
      if (getter) {
        return _.get(item, getter)
      }
      return item
    },
    async initTransferBalance(confirmation) {
      if (!confirmation) {
        this.$emit('hide')
        confirmation = await this.confirmAsync(
          this.confirmationMessage,
          this.confirmationTitle,
          {target: 'body', ...this.confirmationOptions}
        )
        this.$emit('show')
      }
      if (confirmation) {
        await this.transferBalance()
      }
    },
    async transferBalance() {
      EventBus.$emit('card.before.transferBalance')
      this.formSubmitting = true
      this.$v.$touch()
      if (!this.isAnyInvalid) {
        const to = this.fields.to
        const balance = (1 * this.fields.balance) || 0
        const balanceConversionFactor =( this.balanceConversionFactor * 1) || 1
        let transferableBalance = (balanceConversionFactor * balance)

        const remainingFields = _.omit(this.fields, ['from', 'to', 'balance'])
        let options = _.mapValues(remainingFields, field => (1 * field) || 0)
        const mapBalanceField = this.mapBalanceField || 'cash'
        let distributeFields = this.distributeBalanceFields || null
        if (distributeFields) {
          if (_.isString(distributeFields)) {
            distributeFields = _.map(distributeFields.split(','), f => _.trim(f))
          }
          _.forOwn(distributeFields , field => {
            const value = (1 * this.fromCard[field]) || 0
            if (transferableBalance > 0) {
              let fieldValue = Math.min(value, transferableBalance)
              if (fieldValue >= 0) {
                options[field] = fieldValue
              }
              transferableBalance -= fieldValue
            }
          })
        } else {
          options[mapBalanceField] = transferableBalance
        }

        const processing = this.$$t('processing.message', 'Transferring')

        this.startProcessing(processing)
        try {
          await this.fromCard.$actions.transferBalance(to, options)
          this.stopProcessing()
          this.emit('transferred', this.fields)
          await this.showSuccessMessage()
          this.discard(true)
        } catch (e) {
          this.stopProcessing()
          const title = this.$$t('error.title', this.genericTitle)
          const error = getFirstErrorMessage(
            e,
            this.$$t('error.message', 'Error transferring balance')
          )
          await this.errorAlertAsync(error, title)
        }
      }
    },
    async showSuccessMessage() {
      const title = this.$$t('success.title', this.genericTitle)
      this.$emit('hide')
      await this.alertAsync(this.successMessage, title, 'success', {target: 'body'})
      this.$emit('show')
    },
    discard(val) {
      this.$emit('close', val)
    },
    cancelThis() {
      this.$v.$reset()
      this.formSubmitting = false
      this.$emit('close')
      EventBus.$emit('card.transferBalance.cancel')
    }
  },
}
