/*global _, addiesaas, $, debugLog */
import {factory as settingFactory} from '../../../../../lib/settings'
import {createCustomTranslations} from '../../../../../lib/lang'
import {getFirstCombination} from '../../../../../lib/lodash-extras'
import {fv} from '../../../../../lib/utils'

export default {
  data() {
    return {
      productItemView: this.view === 'grid' ? 'grid' : 'list',
      productType: this.type,
      productSubType: this.subtype,
      productProvider: this.provider,
      productProviderType: this.providertype,
      productCategory: _.kebabCase((this.category || '').toLowerCase()),
      productCustomType: this.customtype,
      productFilters: this.filters || {},
      useProductCombo: !!(1 * this.combo),
      $$ps: null,
      $$pt: null,
      $$ptc: null,
      $$pd: null,
      $$pn: null,
      lazyLoad: this.lazy,
    }
  },

  computed: {
  },

  async created() {
    this.init()
  },

  mounted() {

  },
  methods: {
    init() {
      const settings = this.getProductSpecificSettings(null);
      this.$$ps = settingFactory(settings)
      const messages = this.getProductSpecificSettings(null, this.$$t, this.$$$t)
      const i18n = createCustomTranslations(messages)
      this.$$pt = i18n.$$t
      this.$$ptc = i18n.$$tc
      this.$$pd = i18n.$$d
      this.$$pn = i18n.$$n

      this.lazyLoad = fv(this.lazyLoad, this.$$ps('load.lazy', false))
      this.productItemView = this.view || this.$$ps('view') || this.productItemView
    },
    getProductVariationDetails(item) {
      const type = item ? this.getProductType(item) : this.productType
      const category = item ? this.getProductCategory(item) : this.productCategory
      const view = this.productItemView
      const subType = item ? this.getProductSubType(item) : this.productSubType
      const provider = item ? this.getProductProvider(item) : this.productProvider
      const providerType = item ? this.getProductProviderType(item) : this.productProviderType
      return {type, category, view, subType, provider, providerType}
    },

    getProductsSettingsPaths(item) {
      const options = this.getProductVariationDetails(item)
      const type =  `types.${options.type}`
      const category = `categories.${options.category}`
      const view = `views.${options.view}`
      const subType = `subTypes.${options.subType}`
      const provider =  `providers.${options.provider}`
      const providerType = `types.${options.providerType}`
      const init = item ? ['item'] : []
      const suffix = item ? '.item' : ''

      const items = [
          ...init || [],
        `${type}${suffix}`,
        `${category}${suffix}`,
        `${view}${suffix}`,

        `${type}.${view}${suffix}`,
        `${category}.${view}${suffix}`,

        `${type}.${category}${suffix}`,
        `${type}.${category}.${view}${suffix}`,

        `${type}.${subType}${suffix}`,
        `${type}.${subType}.${view}${suffix}`,

        `${type}.${category}.${subType}${suffix}`,
        `${type}.${category}.${subType}.${view}${suffix}`,

        `${type}.${provider}${suffix}`,
        `${type}.${provider}.${view}${suffix}`,
        `${type}.${provider}.${subType}${suffix}`,
        `${type}.${provider}.${subType}.${view}${suffix}`,

        `${type}.${provider}.${providerType}${suffix}`,
        `${type}.${provider}.${providerType}.${view}${suffix}`

      ]
      return items
    },
    getProductItemComponent(item) {
      item = item || {}
      const type = item.type || this.productType
      const subType = item.subType || this.productSubType
      const category = item.category || this.productCategory
      const view = this.productItemView

      const items = [
        `types.${type}`,
        `types.${type}.${subType}`,
        `categories.${category}`,
        `views.${view}`,
      ];

      let component = this.$$fv(
        this.probeComponent(`re-shop-products-item-${type}-${category}-view-${view}`),
        this.probeComponent(`re-shop-products-item-${type}-${category}`),
        this.probeComponent(`re-shop-products-item-${type}-subtype-${subType}-view-${view}`),
        this.probeComponent(`re-shop-products-item-${type}-subtype-${subType}`),
        this.probeComponent(`re-shop-products-item-${type}-${subType}-view-${view}`),
        this.probeComponent(`re-shop-products-item-${type}-${subType}`),
        this.probeComponent(`re-shop-products-item-${type}-view-${view}`),
        this.probeComponent(`re-shop-products-item-${type}`),
        this.itemComponent
      )
      return getFirstCombination(items, this.$$s, true, 'components', null, component)
    },
    getProductSpecificSettings(item, source = this.$$s, init = this.$$$s) {
      const paths = this.getProductsSettingsPaths(item)
      const items = paths.map(path => this.$$clone(source(path, {})))
      return _.merge({}, init || {}, ...items)
    },
  },
}
