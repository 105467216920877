import {default as importModule, namespace as importNamespace} from './modules/import'
import createPersistedState from 'vuex-persistedstate'

const namespaced = true
const namespace = 'Utils/ImportExport'

const modules = {
  [importNamespace]: importModule
}

// plugins
const plugins = [
  // createPersistedState({
  //   paths: [
  //     `${namespace}.${importNamespace}`,
  //   ],
  //   key: namespace,
  // }),
]

export {namespaced, namespace, plugins, modules}
