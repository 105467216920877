<template lang="pug">
  .b-onboarding__footer
    .b-onboarding__footer-left
      span.btn.btn-back(@click="$parent.$parent.back")
        span.btn-back__text Back
        i.btn-back__icon
          img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-btn-back.svg`")
    .b-onboarding__footer-right
      span.btn.btn-primary.btn-lg(@click="next" :disabled="disabled" :class="{ disabled }") Next
        i
          img(:src="`${config.baseUri}/images/client/saas-onboarding/onboarding-btn-next.svg`")
</template>

<script>
export default {
  name: 'onboarding-back-and-forth',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    config: window.addiesaas.config,
  }),

  methods: {
    next() {
      console.log(this.disabled)
      if (!this.disabled) {
        this.$parent.$parent.next()
      }
    },
  }
}
</script>
