const props = {
  editButtonTitle: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('item.buttons.edit.title', 'Edit')
    }
  },
  deleteButtonTitle: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('item.buttons.delete.title', 'Delete')
    }
  },
  topButtonsClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('item.product.imageClass', 'mobile')
    }
  },
  bottomButtonsClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('item.product.imageClass', 'non-mobile')
    }
  },
  showTopButtonTitles: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.buttons.top.showTitle', false)
    }
  },
  showBottomButtonTitles: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.buttons.bottom.showTitle', false)
    }
  },
  showTopButtonIcons: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.buttons.top.showIcon', true)
    }
  },
  showBottomButtonIcons: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.buttons.bottom.showIcon', true)
    }
  },

}


export default props
