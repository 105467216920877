export const name = 're-cart-billing-extended'

// custom setup
import {countries as utilCountries, states as utilStates} from '../../../../../lib/utils'
export const countries = utilCountries
export const states = utilStates

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'
export {validationsRules, defaultFormFields}
import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../mixins/components/complex'
import FormMixin from '../../../../../mixins/form'
import CustomFieldsMixin from '../../../../../mixins/form/custom-field-properties'
import CartMixin from '../../../../../mixins/cart'
export const mixins = [
  ComponentMixin,
  FormMixin,
  CustomFieldsMixin,
  CartMixin
]

export const EventBus = window.VueEventBus

// data
export const data = {
  propDefs,
  fields: defaultFormFields,
  showLoginProposal: false,
  sources: {
    countries,
    states
  },
  paymentTransactionId: null,
  paymentCardExtra: {},
  showContactStep: true,

  disableBackButton: false,
  disableSubmitButton: false,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus,
  countries,
  states,
}
