import ShopCardMixin from '../../../../mixins/shop/card/custom-amount'

export default {
  mixins: [
    ShopCardMixin
  ],
  data() {
    return {
      count: 1
    }
  },
  computed: {
    quantityInputLabel() {
      return this.showFormLabels ? this.quantityLabel : null
    },
  }
}
