export const name = 're-shop-product-item-subscription'

import {getFirstCombination} from '../../../../../../lib/lodash-extras'
export {getFirstCombination}

import {
  mixins as baseMixins,
  validationsRules,
  defaultFormFields,
  data,
  EventBus
} from '../base/config'

// mixins
import SubscriptionMixin from '../../../../../../mixins/shop/subscription'
const mixins = [...baseMixins, SubscriptionMixin]

// props
import props from './props'

// data
data.propDefs = props

export {
  mixins,
  props,
  data,
  validationsRules,
  defaultFormFields,
  EventBus
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  getFirstCombination
}
