/*global _ */
import props from './../../../../props/inputs'

import InputMixin from '../../../../mixins/form/input'
import DatePickerMixin from '../../../../mixins/form/custom-field-properties/date-picker'
import DesignerMixin from './designer'
const mixins = [InputMixin, DatePickerMixin, DesignerMixin]

export const OPTIONS_PROP = 'advancedSettings'

import {fv} from '../../../../lib/utils'
import {Settings, TIMEZONE, locale} from '../../../../lib/dates'
Settings.defaultLocale = locale || 'en-us'

import DatetimePicker from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 're-date-picker',
  components: {DatetimePicker},
  mixins,
  props: {
    ...props,
    label: {
      type: String,
      default: "Select Date"
    },
    timeZone: {
      type: String,
      default: TIMEZONE
    }
  },
  data() {
    return {
      innerValueName: 'selectedDate',
      selectedDate: null
    }
  },

  created() {
    if (this.value && !_.isObject(this.value)) {
      this.selectedDate = ('' + this.value).trim()
    }
  },

  watch: {
    value(newValue) {
      if (_.isObject(newValue) || _.isNil(newValue)) {
        this.selectedDate = null
      } else {
        this.selectedDate = ('' + newValue).trim()
      }
    },
    selectedDate(newValue) {
      if (_.isObject(newValue)) {
        newValue = null
      } else {
        newValue = ('' + newValue).trim()
      }
      this.$emit('input', newValue)
    }
  },

  computed: {
    inputId() {
      return fv(this.id, _.uniqueId('date_picker_'))
    }
  },
  methods: {
    getLabelExtraClasses() {
      return ['re-input-label-date-picker']
    },
  }
}
