/*global _ */
import LocationFailingMixin from '../shop/failing-location'

export default {
  mixins: [LocationFailingMixin],
  props: {
    showLocationFailingErrorMessage: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('showLocationFailingErrorMessage', true)
      }
    },
  },
  data() {
    return {
      cardItemsErrorMessage: '',
      canAutoLoadLocations: false,
      selectedLocationProperty: 'filterLocationId',
    }
  },
  computed: {
    selectedLocationName() {
      const locationId = _.get(this, this.selectedLocationProperty)
      return (this.allLocationDetails[locationId] || {}).display_name
    },
    locationFailingErrorMessage() {
      return this.isSelectedLocationFailing ? this.failingLocationMessageFormatted : null
    }
  }
}
