import pageProps from '../../../../../../props/profile/page-props'
import contextProps from '../../../../../../props/context'

export const props = {
  ...pageProps,
  ...contextProps,
  value: {},
}

export default props
