/*global _ */
import { createNamespacedHelpers } from 'vuex'
import { namespace } from '../../vuexstore'
import { default as shopStore, namespace as shopStoreNamespace } from '../../vuexstore/modules/shop'
const { mapGetters, mapActions } = createNamespacedHelpers([namespace, shopStoreNamespace].join('/'))

import ProductsStoreMixin from '../store/products'
const mixins = [ProductsStoreMixin]

export default {
  mixins,
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(Object.keys(shopStore.getters)),
  },

  async created() {

  },

  methods: {
    ...mapActions(Object.keys(shopStore.actions)),
  },
}

