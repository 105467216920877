/*global _ */
import {Vue} from '~/addiesaas'
import config from './config'

export const {CONFIG: {TIMEZONE = 'UTC', locale = 'en-us'}} = config
// https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
export const {DateTime, Settings, Duration, Interval} = require('luxon')
Settings.defaultLocale = locale
Settings.defaultZoneName = TIMEZONE
export const now = DateTime.local().setZone(TIMEZONE)
export const dateReset = {hour: 0, minute: 0, second: 0}
export const dateEndReset = {hour: 23, minute: 59, second: 59}

export const todayStart = now.set(dateReset)
export const todayEnd = now.set(dateEndReset)
export const tomorrowStart = todayStart.plus({days: 1})
export const tomorrowEnd = todayEnd.plus({days: 1})

export const formats = {
  'YEAR': 'yyyy',
  'YEAR2': 'yy',
  'MONTH': 'MM',
  'MONTH_UNPADDED': 'M',
  'MONTH_NAME_SHORT': 'MMM',
  'MONTH_NAME': 'MMMM',
  'DAY': 'dd',
  'DAY_UNPADDED': 'd',
  'HOUR': 'HH',
  'HOUR12': 'hh',
  'HOUR_UNPADDED': 'H',
  'HOUR12_UNPADDED': 'h',
  'MINUTE': 'mm',
  'MINUTE_UNPADDED': 'm',
  'SECOND': 'ss',
  'SECOND_UNPADDED': 's',
  'SQL': 'yyyy-MM-dd HH:mm:ss',
  'USDATE': 'DDD',
  'USDATE_WITH_DAY': 'DDDD',
  'USDATE_WITH_TIME': 'ff',
  'DATETIME': [
    'yyyy-MM-dd h:mm:ss a',
    'yyyy-MM-dd hh:mm:ss a',
    'yyyy-MM-dd H:mm:ss',
    'yyyy-MM-dd h:mm a',
    'yyyy-MM-dd hh:mm a',
    'yyyy-MM-dd H:mm',
    'yyyy-MM-dd',
  ],
  'TIME': [
    'HH:mm:ss',
    'HH:mm',
    'H:mm:ss',
    'H:mm',

    'hh:mm:ss a',
    'hh:mm a',
    'h:mm:ss a',
    'h:mm a',

    'hh:mm:ss',
    'hh:mm',
    'h:mm:ss',
    'h:mm'
  ],
}

export const create = (y, M = 1, d = 1, h = 0, m = 0, s = 0) => {
  return new DateTime.local(y, M, d, h, m, s)
}

export const parseDateTime = (date, formats, options) => {
  if (_.isArray(date)) {
    date = date[0] || ''
  }
  date = _.trim(date)
  if (!date) {
    return date
  }

  if (formats) {
    return parseDateTimeFromFormats(date, formats, options)
  }

  if (date === 'today' || date === 'today-start') {
    return getNow().startOf('day')
  }
  if (date === 'today-end') {
    return getNow().endOf('day')
  }
  if (date === 'now') {
    return getNow()
  }
  if (date === 'tomorrow' || date === 'tomorrow-start') {
    return getNow().plus({days: 1}).startOf('day')
  }
  if (date === 'tomorrow-end') {
    return getNow().plus({days: 1}).endOf('day')
  }

  if (!(date instanceof DateTime)) {
    if (/\s/.test(date)) {
      return DateTime.fromSQL(date)
    } else if (/-/.test(date)) {
      return DateTime.fromISO(date)
    } else if (!/\D/.test(date)) {
      return DateTime.fromMillis(1 * date)
    } else if (/\//.test(date)) {
      return DateTime.fromJSDate(new Date(date))
    } else if (_.isString(date)) {
      return DateTime.fromJSDate(new Date(date))
    } else if (date instanceof Date) {
      return DateTime.fromJSDate(date)
    } else if (_.isObject(date)) {
      return DateTime.fromObject(date)
    }
  }
  return date
}

//date +xx days or +xx days
//date -yy years or -yy years
//date plus 10 days or plus 10 days
//{from: date, interval: [['plus', {years: 1}], ['minus', {days: 1}])
export const parseRelativeDateTime = (value, formats, options) => {
  if (!value) {
    return parseDateTime('now')
  }

  if (_.isString(value)) {
    // add defaults (plus and date)
    if (/^[\d]+?\s/.test(value)) {
      value = 'now plus ' + value
    }

    // add default date
    if (_.startsWith(value, 'plus') || _.startsWith(value, '+') ||
      _.startsWith(value, 'minus') || _.startsWith(value, '-')) {
      value = 'now ' + value
    }

    const initialParts = value.trim().split(' ')
    const partsLength = initialParts.length
    // return date parsed if only one part in string param
    if (partsLength == 1) {
      return parseDateTime(initialParts.shift())
    }

    // extract date
    let possibleDatePart = initialParts.shift()
    const possibleDate = parseDateTime(possibleDatePart)

    // return if invalid date
    if (!possibleDate || !possibleDate.isValid) {
      return possibleDate
    }

    const from = possibleDate

    value = initialParts.join(' ')
      .replace('+', 'plus ')
      .replace('-', 'minus ')

    const values = value.replace(/\s{2,}/g, '').split(' ')
    const firstValue = values[0]
    // add default plus
    if (!['plus','minus'].includes(firstValue)) {
      values.unshift('plus')
    }

    let [relative, count, unit] = values || []
    count = (1 * (count + '').trim()) || 0
    unit = unit || 'milliseconds'
    const interval = [[relative || 'plus', {[unit]: count}]]
    value = {from, interval}
  }

  if (_.isObject(value) || !_.isArray(value)) {
    let baseDate = parseDateTime(value.from || 'now', formats, options)
    if (baseDate.isValid) {
      const interval = value.interval
      _.forOwn(interval, item => {
        const [method, param] = item
        if (_.isFunction(baseDate[method])) {
          baseDate = baseDate[method](param)
        }
      })
      return baseDate
    }
  }
  return null
}

export const parseDateTimeFromFormats = (source, formats, options = {}) => {
  let date = null
  _.forOwn(_.castArray(formats), format => {
    date = DateTime.fromFormat(source, format, options)
    if (date.isValid) {
      return false
    }
  })
  return date.isValid ? date : null
}
export const formatDate = (date, format, prefix = '', suffix = '') => {
  let datetime = parseDateTime(date)
  if (datetime) {
    if (datetime.setLocale) {
      datetime = datetime.setLocale(locale)
    }
    if (datetime.isValid) {
      let formatted
      if (_.isObject(format)) {
        formatted = formatDateByMethod(datetime, format)
      } else {
        format = format || 'DDD'
        if (formats[format]) {
          format = formats[format]
        }
        formatted = datetime.toFormat(format)
      }
      return prefix + formatted + suffix
    }
  }
  return ''
}
export const formatDateByMethod = (date, format) => {
  const {method, params} = format || {}
  return date[method](..._.castArray(params))
}
/**
 *
 * @param date
 * @param template
 * @returns luxon.DateTimeInterval
 */
export const parseDateInterval = (date, template) => {

  if (!template) {
    return null
  }

  let startDate = now
  let dates = startDate.until(todayEnd)

  if (date instanceof DateTime && template instanceof DateTime) {
    return date.until(template)
  }

  if (template instanceof DateTime) {
    date = parseDateTime(date)
    return date.until(template)
  }

  if (template === 'custom') {
    startDate = parseDateTime(date)// @todo: change to fromFormat
    return startDate.until(startDate.set(dateEndReset))
  }
  if (template === 'tomorrow') {
    startDate = tomorrowStart
    return startDate.until(tomorrowEnd)
  }
  if (template === 'this-week') {
    return now.until(now.endOf('week').set(dateEndReset))
  }
  if (template === 'this-month') {
    return now.until(now.endOf('month').set(dateEndReset))
  }

  return dates
}

export const getDate = (date, prefix = '', suffix = '') => {
  return formatDate(date, 'DDD', prefix, suffix)
}

export const getTime = (value, format = 't', prefix = '', suffix = '') => {
  let newValue = value = _.trim(value)
  if (newValue) {
    newValue = parseDateTime(newValue)
    if (!newValue.isValid) {
      newValue = parseDateTimeFromFormats(value, formats.TIME)
    }
    if (newValue && newValue.isValid) {
      return formatDate(newValue, format, prefix, suffix)
    }
  }
  return value
}

export const getDayDate = (date, prefix = '', suffix = '') => {
  return formatDate(date, 'd MMMM, EEEE', prefix, suffix)
}
export const getDayDateYear = (date, prefix = '', suffix = '') => {
  return formatDate(date, 'DDDD', prefix, suffix)
}
export const getDateTime = (date, prefix = '', suffix = '') => {
  return formatDate(date, 'ff', prefix, suffix)
}
export const getDateTimeYear = (date, prefix = '', suffix = '') => {
  return formatDate(date, 'DDD', prefix, suffix)
}

export const getNow = (zone = TIMEZONE, seconds = false) => {
  const nowDate = DateTime.local().setZone(zone)
  if (seconds) {
    return nowDate.toSeconds()
  }
  return nowDate
}

export const getNowSeconds = (zone = TIMEZONE) => getNow(zone, true)

export const time2Min = (time) => {
  return DateTime.fromFormat(time, 'h:mm a').valueOf() / 60000
}

Vue.prototype.$$now = now
Vue.prototype.$$getNow = getNow
Vue.prototype.$$parseDateTime = parseDateTime
Vue.prototype.$$TIMEZONE = TIMEZONE
Vue.prototype.$$formatDate = formatDate

export default {
  TIMEZONE,
  DateTime,
  Duration,
  Interval,
  Settings,
  now,
  dateReset,
  dateEndReset,
  todayStart,
  todayEnd,
  tomorrowStart,
  tomorrowEnd,
  create,
  parseDateTime,
  parseRelativeDateTime,
  parseDateTimeFromFormats,
  formatDate,
  formatDateByMethod,
  parseDateInterval,
  getDate,
  getTime,
  getDayDate,
  getDayDateYear,
  getDateTime,
  getDateTimeYear,
  getNow,
  getNowSeconds,
  time2Min,
  formats,
}
