import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  token: {
  },
  email: {
  },
  successMessage: {
    type: String,
    default() {
      return this.$options.$$t('successMessage', 'Your account has been confirmed')
    }
  },
  failureMessage: {
    type: String,
    default() {
      return this.$options.$$t('failureMessage', 'Failed to verify your account')
    }
  },
  verifyingMessage: {
    type: String,
    default() {
      return this.$options.$$t('verifyingMessage', 'Verifying your email')
    }
  },
  showLoginBox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showLoginBox', true)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },
  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
