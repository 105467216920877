/*global _ */
import formatters from '../../lib/formatters'

const {
  getFirst, templify, templiParse, formatPath, valueFormat, map, notEmpty,
  every, some, fv
} = formatters

export const reactiveTypes = {
  '$change': {
    update: 'updateReactiveItems',
    fire: 'fireReactiveEvents',
    call: 'callReactiveActions',
  }
}
export default {
  methods: {
    setupReactiveItems(items, helpers) {
      this.watchReactiveItems(items, helpers)
    },
    watchReactiveItems(items, helpers) {
      const types = reactiveTypes
      const namespace = helpers.namespace

      // items can be this.fields
      _.forOwn(items, (item, key) => {
        const reactives = _.get(item, 'reactive', {})
        if (reactives !== false && reactives.unwatch !== false) {
          const watchspace = `${namespace}.${key}`
          this.$watch(
            watchspace,
            (value, oldValue) => {
              const event = {source: {key, item, items, namespace, watchspace, data: {value, oldValue}}, helpers}
              _.forOwn(types, (typeItems, type) => {
                //type = $change
                const reactiveType = reactives[type] // $change:Object
                if (reactiveType) {
                  // changeType = update/fire/call
                  _.forOwn(typeItems, (callbackName, changeType) => {
                    //'update': Update fields (this.fields) having $compute, listed in changeTypeParams
                    //'fire': fire events listed in changeTypeParams
                    //'call': call methods listed in changeTypeParams
                    const changeTypeParams = reactiveType[changeType]
                    if (notEmpty(changeTypeParams)) {
                      const callback = this[callbackName]
                      if (_.isFunction(callback)) {
                        callback(changeTypeParams, event)
                      }
                    }
                  })
                }
              })

              this.$nextTick(() => {
                this.fireReactiveAutoEvents(namespace + ':changed', key, value, event)
                this.fireReactiveAutoEvents(watchspace + ':changed', value, event)
                this.fireReactiveAutoEvents(key + ':changed', value, event)
              })
            },
            {immediate: true}
          )
        }
      })
    },

    updateReactiveItems(items, event) {
      const {helpers = {}, source = {}} = event
      if (_.isString(items)) {
        items = [items]
      }
      if (formatters.isArrayJSONObject(items)) {
        items = _.values(items)
      }
      const sourceItems = source.items
      _.forOwn(items, (key) => {
        const isSourceItem = _.startsWith(key, 'this.')
        const item = isSourceItem ?
          _.get(this, key.replace(/^this\./, '')) :
          _.get(sourceItems, key)

        const reactives = _.get(item, 'reactive')
        if (reactives) {
          let computes = _.get(reactives, '$compute')
          if (computes) {
            const newEvent = {
              source,
              helpers,
              target: {
                key,
                item: helpers.propGetter(key),
                data: {
                  value: helpers.valueGetter(key)
                }
              }
            }
            this.computeReactiveItem(computes, newEvent)
          }
        }
      })
    },

    fireReactiveAutoEvents(eventName, ...args) {
      this.emitBoth(eventName, ...args)
    },
    fireReactiveEvents(items, event) {
      const {helpers = {}, source = {}, target = source} = event
      const {data: allData = {}} = helpers
      const data = _.isFunction(allData) ? allData() : allData
      data['$'] = _.get(target, 'data.value')

      if (_.isString(items)) {
        items = [items]
      }
      this.$nextTick(() => {
        _.forOwn(items, (props, eName) => {
          let params = _.get(props, 'params', [])
          if (notEmpty(params)) {
            params = this.parseReactiveValuesByTemplate(_.values(params), data)
          }
          this.emitBoth(eName, ...params)
        })
      })
    },

    callReactiveActions(items, event) {

    },

    computeReactiveItem(computes, event) {
      const {helpers = {}, source = {}, target = {}} = event
      const {data: allData = {}} = helpers

      const data = _.isFunction(allData) ? allData() : allData
      data['$'] = _.get(target, 'data.value')

      computes = _.cloneDeep(computes)
      if (formatters.isArrayJSONObject(computes)) {
        computes = _.values(computes)
      }
      _.forOwn(computes, (compute) => {
        const canSet = this.probeReactiveIf(compute['if'], data, event)
        if (canSet) {
          this.setReactiveItemValues(compute.value, data, event)
          this.setReactiveItemProps(compute.prop, data, event)
        }
      })

    },

    /**
     * Parses an expression definition to get a truthy or falsy result
     * `expression` can be
     *  1. array [[],[]]- each item is an array whose first value is a method/or method name and
     *      second is an array to for the params of the method
     *  2. object with one of these - $and, $or, $every, $some, $notEvery, $notSome
     *      the value needs to be an array based on #1
     *
     *    The method, if string, is extracted from the first of these: this.context, this, formatters, _, window
     *      using _.get/formatters.getFirst
     *
     *  Examples:
     *
     *  probeReactiveIf([['truthy', '{[x]}']], {x: true}
     *  probeReactiveIf({$or: [['truthy', '{[x]}']]}, {x: true}
     *
     * @param expression
     * @param data
     * @param event
     * @returns boolean
     */
    probeReactiveIf(expression, data, event = {}) {
      const {helpers = {}, source = {}, target = {}} = event
      if (_.isBoolean(expression)) {
        return expression
      }

      let expressed = true
      if (_.isObject(expression)) {
        if (_.isArray(expression)) {
          const methodDefs = this.probeReactiveFunctions(expression, data)
          for (const method of methodDefs) {
            expressed = method()
          }
        } else {
          const items = expression.$notEvery || expression.$notSome ||
            expression.$every || expression.$and ||
            expression.$some || expression.$or
          const methodDefs = this.probeReactiveFunctions(items, data)
          if (expression.$notEvery) {
            return !every(methodDefs, (fn, index) => methodDefs[index]())
          }
          if (expression.$notSome) {
            return !some(methodDefs, (fn, index) => methodDefs[index]())
          }
          if (expression.$every || expression.$and) {
            return every(methodDefs, (fn, index) => methodDefs[index]())
          }
          if (expression.$some || expression.$or) {
            return some(methodDefs, (fn, index) => methodDefs[index]())
          }
        }
      }
      return expressed
    },
    setReactiveItemProps(def, data, event) {
      if (def) {
        const setter = _.get(event, 'helpers.propSetter')
        const key = _.get(event, 'target.key')
        if (setter && key) {
          setter(key, def)
        }
      }
    },
    setReactiveItemValues(def, data, event) {
      if (!_.isUndefined(def)) {
        const setter = _.get(event, 'helpers.valueSetter')
        const key = _.get(event, 'target.key')
        let value = this.parseReactiveValues(def, data, event)
        data['$'] = value
        setter(key, value)
      }
    },
    parseReactiveValues(def, data, event) {
      if (_.isString(def)) {
        return this.parseReactiveValuesByTemplate(def, data, event)
      }
      if (_.isArray(def)) {
        return _.map(def, i => this.parseReactiveValues(i, data, event))
      }
      if (_.isObject(def)) {
        if (def.method) {
          return this.parseReactiveValuesByMethod(def, data, event)
        }
        if (def.promise) {
          return this.parseReactiveValuesByMethod(def, data, event)
        }
        return this.parseReactiveValuesByValuePath(def, data, event)
      }
      return def
    },
    parseReactiveValuesByTemplate(def, data, event) {
      return templiParse.call(this, def, data || {})
    },
    parseReactiveValuesByValuePath(def, data, event) {
      let values = null
      const hasValuePaths = _.has(def, 'valuePath')
      let value = hasValuePaths ? def.valuePath : def.value
      if (!_.isString(value)) {
        value = _.values(value)
      }

      let mergedData = data
      if (def.mergeData) {
        const mergableData = _.mapValues(
          _.cloneDeep(def.mergeData),
          item => this.parseReactiveValues(item, data, event)
        )
        mergedData = _.merge({}, mergedData, mergableData)
      }
      values = hasValuePaths ? formatPath(value, mergedData) : templiParse(value, mergedData)
      if (def.format || def.group || def.formatEach) {
        if (def.skipNil) {
          if (formatters.nil(values)) {
            return fv(def.defaultValue, null)
          }
          if (_.isObject(values)) {
            values = formatters.removeBy(values, v => formatters.nil(v))
          }
          if (_.isEmpty(values)) {
            return fv(def.defaultValue, null)
          }
        }
        values = this.formatReactiveValues(values, def, mergedData, event)
      }

      return values
    },
    parseReactiveValuesByMethod(def, data, event) {
      let values = null
      const hasPromise = def.promise
      let setMethod = hasPromise ? def.promise : def.method

      const methodDefs = this.probeReactiveFunctions(setMethod, data)
      for (const method of methodDefs) {
        if (hasPromise) {
          values = method()
        } else {
          values = method()
        }
      }
      return values
    },
    probeReactiveFunctions(obj, data, options) {
      let dataDef = data
      options = this.$$fv(options, {})
      if (_.isBoolean(options)) {
        options = {getSingle: options}
      }
      const {getSingle = false, async = null} = options
      const getContext = (item) => {
        const def = item && item[2]
        let context = def
        if (def) {
          if (_.isString(def)) {
            context =_.get(this, def)
            if (!context) {
              if (_.isFunction(dataDef)) {
                 dataDef = dataDef()
              }
              context = getFirst(
                def,
                dataDef,
                _.get(dataDef, 'this'),
                _.get(dataDef, '$inject'),
                this && this.context,
                this,
              )
            }
          }
        }
        return context || (this && this.context || this)
      }
      const getMethod = (fn) => {
        let method = fn && (_.isFunction(fn) ? fn :
          getFirst(fn, this && this.context, this, formatters, _, window)
        )
        if (!method) {
          if (_.isFunction(dataDef)) {
             dataDef = dataDef()
          }
          method = getFirst(
            fn,
            dataDef,
            _.get(dataDef, 'this'),
            _.get(dataDef, '$inject'),
            this && this.context,
            this
          )
        }
        return method
      }

      if (!_.isObject(obj) && !_.isArray(obj)) {
        obj = [obj]
      }
      if (formatters.isArrayJSONObject(obj)) {
        obj = _.values(obj)
      }
      let functions = _.reduce(obj, (f, params, fn) => {
        let context = this && this.context || this
        if (_.isFinite(fn)) {
          const item = _.isString(params) ? [params] : _.values(params)
          fn = item[0] || null
          params = item[1] || []
          context = getContext(item)
        }

        let method = getMethod(fn)
        if (method && _.isFunction(method)) {
          const isAsync = !!async
          let callback = null
          if (isAsync) {
            callback = async () => {
              let dataSource = data
              if (_.isFunction(data)) {
                dataSource = data()
              }
              const args = this.parseReactiveValuesByTemplate(_.values(params), dataSource)
              return await method.apply(context, args)
            }
          } else {
            callback = () => {
              let dataSource = data
              if (_.isFunction(data)) {
                dataSource = data()
              }
              const args = this.parseReactiveValuesByTemplate(_.values(params), dataSource)
              return method.apply(context, args)
            }
          }
          f.push(callback)
        }
        return f
      }, [])

      if (getSingle) {
        if (functions.length === 1) {
          return functions[0]
        }
        return function (...args) {
          for (const method of functions) {
            method(...args)
          }
        }
      }

      return functions
    },
    evaluateReactiveSwitchedState(items, data, event) {
      let value = null
      let fulfilledCase = false
      const defaultItem = items[0]
      _.forEach(_.values(items), (item, index) => {
        if (index) {
          const truthy = this.probeReactiveIf(item['[case]'], data, event)
          if (truthy) {
            fulfilledCase = true
            value = item.value
            if (_.isObject(item)) {
              const parse = item.parse
              if (parse) {
                value = this.parseReactiveValues(value, data, event)
              }
            }
            return false
          }
        }
      })
      if (!fulfilledCase) {
        value = defaultItem
        if (_.isObject(defaultItem)) {
          value = _.isArray(value) ? value : (defaultItem.value || defaultItem)
          const parse = _.isArray(value) || defaultItem.parse
          if (parse) {
            value = this.parseReactiveValues(value, data, event)
          }
        }
      }
      return value
    },
    formatReactiveValues(values, def, data, event) {
      return valueFormat.call(this, values, fv(def.defaultValue, null), def, data)
    },
    parseReactiveAttrs(attrs, data = {}) {
      if (!_.isObject(attrs) || _.isArray(attrs)) {
        return attrs
      }
      data.this = fv(data.this, this)
      const values = _.mapValues(attrs, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseReactiveAttrs(def, data)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    // template: Text containing a part with [# .. #] wrapper:
    //    The i18n translation must have a defined pluralization
    //    e.g., apple => 'no apple|apple|apples' , banana => 'no banana|banana|bananas'
    //      ("You have [#apple#].", 0) => You have no apple.
    //      ("You have [#apple#].", 1) => You have apple.
    //      ("You have [#apple#].", 10) => You have apples.

    //      ("You have [#apple#] and [#banana#].", {apple: 10, banana: 0}) => You have apples and no banana.

    //       With apple => '{n} apple|{n} apple|{n} apples', banana => 'no banana|{n} banana|{n} bananas'
    //   ("You have [#apple#] and [#banana#].", {apple: 0, banana: 10}) => You have 0 apple and 10o bananas.
    //
    //  You can also define custom pluralization without predefined translation:
    //    ("You have [#apple=>no apple|an apple|{n} apples#] and [banana=>#0 banana|one banana|so many bananas#].",
    //    {apple: 1, banana: 10})
    //      => You have an apple and so many bananas.
    //
    parsePluralize(template, data) {
      if (_.isObject(template)) {
        return map(template, item => this.$$tc(item, item, data))
      }
      return this.$$tc(template, template, data)
    }
  }
}
