import Base from '../Base'
import Transaction from './Transaction'

export class Order extends Base {

  constructor(data, vm) {
    super(data, vm)
    this.transaction = new Transaction(this.data.transaction.data, vm)
  }

}

export default Order
