import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  token: {},
  item: {},
  showFormLabels: {
    label: 'Show Form labels',
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },
  isCompactMode: {
    type: [Boolean, Number, String],
    default() {
      return !this.$options.$$s('isCompactMode', false)
    }
  },
  noAgreeToTerms: {
    type: [Boolean, Number, String],
    default() {
      return !this.$options.$$s('showTerms', true)
    }
  },
  amountBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.product.title', 'Select your amount')
    }
  },
  amountBoxSubtitle: {
    type: String,
    default() {
      return this.$options.$$t(
        'form.product.subTitle',
        this.$options.$$t('form.product.subtitle', 'Please choose the gift card amount.')
      )
    }
  },
  amountBoxLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.product.label', 'Amount')
    }
  },
  personalDetailsTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.customer.title', 'Personal information')
    }
  },
  personalDetailsSubtitle: {
    type: String,
    default() {
      return this.$options.$$t('form.customer.subTitle', 'Please enter your contact details.')
    }
  },
  noPhoneInput: {
    type: [Boolean, Number, String],
    default() {
       return !!this.$options.$$s('noPhoneInput', true)
    }
  },
  noGiftee: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noGiftee', false)
    }
  },
  recipientBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.recipient.title', 'This Gift Card is for (recipient)')
    }
  },
  recipientBoxSubtitle: {
    type: String,
    default() {
      return this.$options.$$t('form.recipient.subTitle', 'Please enter contact details.')
    }
  },
  previewCardBoxTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.preview.title', 'Preview: The gift card email will look like this')
    }
  },
  previewCardBoxHtml: {
    default() {
      return this.$options.$$t('form.preview.template', null)
    }
  },
  isGiftNoteRequired: {
    type: [Boolean, Number, String],
    default() {
       return !!this.$options.$$s('form.fields.giftNote.validations', false)
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Your order is being processed...')
    }
  },
  cartItemEditable: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('cartItemEditable', true)
    }
  },
  productsFilters: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$s('products.filters', null)
    }
  },

  // quantity
  noQuantity: {
    type: [Boolean, Number, String],
    default() {
      return !this.$options.$$s('showQuantity', true)
    }
  },
  quantityClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('quantityClass', null)
    }
  },
  quantityTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.quantity.title', 'Select')
    }
  },
  quantitySubtitle: {
    type: String,
    default() {
      return this.$options.$$t('form.quantity.subTitle', '')
    }
  },
  quantityLabel: {
    type: String,
    default() {
      return this.$options.$$t('quantityLabel', 'Quantity')
    }
  },
  // insert to cart props
  showInsertToCartButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showInsertToCartButton', false)
    }
  },
  insertToCartButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('insertToCartButtonClass', null)
    }
  },
  insertToCartButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('insertToCartButtonTitle', 'Add to cart')
    }
  },
  showInsertSuccessMessageAs: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('showInsertSuccessMessageAs', 'alert')
    }
  },
  insertSuccessMessage: {
    type: [String],
    default() {
      return this.$options.$$t('insertSuccessMessage', '[#quantity=>Card has|Cards have#] been successfully added to cart.')
    }
  },
  insertSuccessMessageOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('insertSuccessMessageOptions', {})
      const $s = this.$options.$$s('insertSuccessMessageOptions', {})
      return _.merge({}, $t, $s)
    }
  },
  // Use location
  useLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('useLocation', false)
    }
  },
  filterProductByLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('filterProductByLocation', true) // true/false/'strict'
    }
  },
  hideOtherFieldsOnNoLocation: {
    type: [Boolean, Number, String],
    default() {
      return this.$options.$$s('hideOtherFieldsOnNoLocation', false)
    }
  },
  locationInputOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('form.fields.location_id', {})
      const $s = this.$options.$$s('form.fields.location_id', {})
      return _.merge({}, $t, $s)
    }
  },
  cardLocationField: {
    type: [Number, String],
    default() {
      return this.$options.$$s('cartLocationField', 'location_id')
    }
  },
  filterCardByLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('filterCardByLocation', true)
    }
  },
  rememberLocation: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('rememberLocation', true)
    }
  },
  locationTitle: {
    type: String,
    default() {
      return this.$options.$$t('form.location.title', null)
    }
  },
  locationSubtitle: {
    type: String,
    default() {
      return this.$options.$$t('form.location.subTitle', null)
    }
  },

  showProductSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showProductSelector', true)
    }
  },
  showProductAtTop: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showProductAtTop', true)
    }
  },
  customSteps: {
    default() {
      return this.$options.$$s('customSteps', null) // [['personal', 'location', 'quantity', 'product'], ['recipient'], ['preview']]
    }
  }
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
