/*global _ */
import pageProps from '../../../../../../props/profile/page-props'
import contextProps from '../../../../../../props/context'

const defaultComponents = {
  tier: {
    name: 're-loyalty-tier-rewards',
    attrs: {},
    parseAttrs: {},
    on: {},
  },
  achievements: {
    name: 're-loyalty-achievement-rewards',
    attrs: {},
    parseAttrs: {},
    on: {},
  },
}

const defaultHistoryComponent = {
    name: 're-loyalty-points-history',
    attrs: {},
    parseAttrs: {},
    on: {},
}
export const props = {
  ...pageProps,
  ...contextProps,
  autoLoad: {
    type: [String, Array, Boolean],
    default() {
      return this.$options.$$s('autoLoad', false) // autoLoad data for other related pages. pass string or array of the profile page names
    }
  },
  loyaltyComponents: {
    default() {
      const $t = this.$options.$$t('components', {})
      const $s = this.$options.$$s('components', {})
      const components = _.merge({}, $t, $s)
      if (_.isEmpty(components)) {
        return defaultComponents
      }
      return components
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading loyalty rewards...')
    }
  },
  historyComponent: {
    default() {
      const $t = this.$options.$$t('history.component', {})
      const $s = this.$options.$$s('history.component', {})
      const components = _.merge({}, $t, $s)
      if (_.isEmpty(components)) {
        return defaultHistoryComponent
      }
      return components
    }
  }
}

export default props
