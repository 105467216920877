import {forceUpdateOnResize, observeResize} from '../lib/utils'

export default {
  data() {
    return {
      appWidth: window.innerWidth,
    }
  },
  watch: {
    appWidth: {
      handler(width, previousWidth) {
        this.$emit('window.resized', width, previousWidth, this, window)
        this.$$emit('window.resized', width, previousWidth, this, window)
      },
      immediate: true
    }
  },
  async mounted() {
    this.$nextTick()
    forceUpdateOnResize(this)
    componentResized.call(this, this.$el)
    observeResize(this.$el, (...args) => {
      componentResized.call(this, ...args)
    })
  },
  methods: {
    setAppWidth(width) {
      this.appWidth = width
    }
  }
}

const componentResized = function (e, ...args) {
  if (e.getBoundingClientRect) {
    const rect = e.getBoundingClientRect()
    this.$emit('component.resized', this, rect, e, ...args)
    this.$$emit('component.resized', this, rect, e, ...args)
  }
}

