import {name, mixins, props, data} from './config'

export default {
  name,
  mixins,
  props,
  data() {
    return {
      ...data,
    }
  },

  computed: {},
  created() {

  },
  methods: {


  }
}
