import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const componentProps = {
  number: {

  },
  cvv: {

  },
  numberLabel: {
    type: String,
    default: 'Card Number'
  },
  numberPlaceholder: {
    type: String,
    default: 'XXXX XXXX XXXX XXXX'
  },

  codeLabel: {
    type: String,
    default: 'Code'
  },
  codePlaceholder: {
    type: String,
    default: '****'
  },

  onlyAuth: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('onlyUserCanCheck', false)
    }
  },
  canLoadUserCards: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('canLoadUserCards', false)
    }
  },
  loadingCardsMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingCardsMessage', 'Loading cards...')
    }
  },
  userCardSelectorAttrs: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('userCardSelector', {})
      const $s = this.$options.$$s('userCardSelector', {})
      return _.merge({
        blankOption: 'Select a card',
        reduceValue: '{{number}}',
        reduceLabel: '{{number}}',
      }, $t, $s)
    }
  },

  productTypes: {
    type: [Array, Object],
    default() {
      return this.$options.$$s('productTypes', null)
    }
  },
  productTypeLang: {
    type: [Array, Object],
    default() {
      return this.$options.$$t('productTypes', {})
    }
  },
  defaultProductType: {
    type: String,
    default() {
      return this.$options.$$s('defaultProductType', 'playcard_sale')
    }
  },
  cardType: {
    type: [String],
    default() {
      return this.$options.$$s('cardType', null)
    }
  },
  balanceComponent: {
    type: [String],
    default() {
      return this.$options.$$s('components.balance', null)
    }
  },
  showBalanceInModal: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showBalanceInModal', false)
    }
  },
  balanceTitle: {
    type: String,
    default() {
      return this.$options.$$t('balanceTitle', 'Card Balance')
    }
  },

  showResendLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showResendLink', false)
    }
  },
  resendLinkPrefix: {
    type: String,
    default() {
      return this.$options.$$t('resendLinkPrefix', 'Did you forget the Gift Card code? ')
    }
  },
  resendLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('resendLinkLabel', 'Resend CVV code')
    }
  },

  showPurchaseLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPurchaseLink', false)
    }
  },
  purchaseLinkPrefix: {
    type: String,
    default() {
      return this.$options.$$t('purchaseLinkPrefix', 'Would you like to ')
    }
  },
  purchaseLink: {
    type: [String, Object],
    default: function () {
     return this.$options.$$s('purchaseLink', { name: 'giftcards.buy' })
    }
  },
  purchaseLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('purchaseLinkLabel', 'Purchase Gift Card')
    }
  },
  purchaseLinkAttrs: {
    type: [Object],
    default() {
      return this.$options.$$s('purchaseLinkAttrs', {})
    }
  },

  modalClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('modalClasses', null)
    }
  },
  numberInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalTitle', 'Card Number')
    }
  },
  numberInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalHeader')
    }

  },
  numberInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('numberInfoModalFooter', 'The Card number can be found in the email that was sent after the purchase was done or under the \'My Cards\' section of your Account.')
    }
  },
  numberInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('numberInfoModalImgSrc', null)
    }
  },

  codeInfoModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalTitle', 'CVV or Card Code')
    }
  },
  codeInfoModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalHeader', null)
    }
  },
  codeInfoModalFooter: {
    type: String,
    default() {
      return this.$options.$$t('codeInfoModalFooter', 'The CVV or Card Code can be found in the email that was sent after the the card was claimed by you.')
    }
  },
  codeInfoModalImgSrc: {
    type: String,
    default() {
      return this.$options.$$s('codeInfoModalImgSrc', null)
    }
  },

  resendModalTitle: {
    type: String,
    default() {
      return this.$options.$$t('resendModalTitle')
    }
  },
  resendModalClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('resendModalClasses')
    }
  },
  resendModalHeader: {
    type: String,
    default() {
      return this.$options.$$t('resendModalHeader', 'Resend your code using the email address associated with your Card <b>OR</b> your Card Number.')
    }
  },
  resendModalButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('resendModalButtonTitle', 'Recover')
    }
  },
  resendModalButtonClasses: {
    type: String,
    default() {
      return this.$options.$$s('resendModalButtonClasses')
    }
  },
  resendModalErrorMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalErrorMessage')
    }
  },
  resendModalRecoveredEmail: {
    type: String,
    default() {
      return this.$options.$$t('resendModalRecoveredEmail')
    }
  },
  resendModalSuccessMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalSuccessMessage', 'The Card code has been sent to the following email address')
    }
  },
  resendModalSuccess: {
    type: Boolean,
    default: false,
  },
  resendModalResendMessage: {
    type: String,
    default() {
      return this.$options.$$t('resendModalResendMessage', 'The Card code has been resent to the following email address')
    }
  },

  checkAnotherButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('checkAnotherButtonTitle', 'Check balance on another card')
    }
  },

  hasHelpNumberLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('hasHelpNumberLink', true)
    }
  },
  helpNumberLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('helpNumberLinkLabel', 'What is this?')
    }
  },
  hasHelpCodeLink: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('hasHelpCodeLink', true)
    }
  },
  helpCodeLinkLabel: {
    type: String,
    default() {
      return this.$options.$$t('helpCodeLinkLabel', 'What is this?')
    }
  },
}

const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Check card balance')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Check card balance')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },

  resetButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('resetButtonTitle', 'Check new card')
    }
  },
  resetButtonAttrs: {
    type: [Object],
    default() {
      return this.$options.$$s('resetButtonAttrs', {})
    }
  },
  resetButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('resetButtonClasses', null)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Retrieving your card balance.')
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },

  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },

  noCvvForAuth: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noCvvForAuth', false)
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...componentProps,
  ...localProps
}
export default props
