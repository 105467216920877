<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Verify your email address
      .b-central__subtitle We just sent you a 6-digit code to {{ email }}.
        br
        | Enter the code below to confirm your email address.

      onboarding-verificode(@change="input")

      .b-central__message.text-danger.m-b-20(v-if="backendError") {{ backendError }}

      .b-central__message.text-success.m-b-20(v-if="resent") We have resent a code to your email.

      .b-central__message.text-muted.m-b-35 Make sure to keep this window open while you check your inbox. 
        a(href="#" @click.prevent="resend") Resend code.

      .b-central__footer.b-central__footer--navigation-btn
        a.btn.btn-almost-sm.btn-secondary(@click.prevent="$router.go(-1)") Back
        v-button.btn.btn-almost-sm.btn-primary(@click="submit" :loading="submitting" :disabled="$v.$invalid") Verify

</template>

<script>
import axios from 'axios'
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators'
import { getFirstErrorMessage } from '~/lib/axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    email: null,
    code: '',
    backendError: null,
    submitting: false,
    resent: false,
  }),

  validations: {
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
      integer,
    },
  },

  created() {
    this.email = this.$route.query.email
  },

  methods: {
    input(code) {
      this.code = code
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.submitting = true
        const { data } = await axios.post(`${this.config.baseUri}/api/onboarding/email-verification/check`, {
          email: this.email,
          code: this.code,
        })
        this.$router.push({ name: 'onboarding-password', query: { token: data.token, email: this.email } })
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      } finally {
        this.submitting = false
      }
    },

    async resend() {
      try {
        this.resent = false
        await axios.post(`${this.config.baseUri}/api/onboarding/email-verification/send`, {
          email: this.email,
        })
        this.resent = true
        this.backendError = null
      } catch (e) {
        this.backendError = getFirstErrorMessage(e)
      }
    },
  },
}
</script>
