export const SYNC = 'SYNC'
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const CLEAN_TOKEN = 'CLEAN_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const SAVE_SHADOW_TOKEN = 'SAVE_SHADOW_TOKEN'
export const REMEMBER_AFTER_LOGIN_ROUTE = 'REMEMBER_AFTER_LOGIN_ROUTE'
