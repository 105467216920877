export default {
  name: 're-group-box',
  props: {
    titleContainerClasses: {},
    contentContainerClasses: {},
    actionsContainerClasses: {},
  },
  data() {
    return {}
  }
}
