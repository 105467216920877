/*global _ */
import props from './props'
const EventBus = window.VueEventBus

import ConfigMixin from '../../../../mixins/config'
import ComponentMixin from '../../../../mixins/components/complex-reactive'
import ResponsiveMixin from '../../../../mixins/responsive'
import ProfileMixin from '../../../../mixins/profile'
import PaginateMixin from './paginate'
import ProfileLocationMixin from '../../../../mixins/profile/location'
import CardLocationMixin from '../../../../mixins/profile/cards/locations'
import LocationFailingMixin from '../../../../mixins/profile/failing-location'
import FormMixin from '../../../../mixins/form'
import FieldsMixin from '../../../../mixins/form/custom-field-properties'

const mixins = [
  ConfigMixin,
  ComponentMixin,
  ResponsiveMixin,
  ProfileMixin,
  ProfileLocationMixin,
  CardLocationMixin,
  FormMixin,
  FieldsMixin,
  PaginateMixin,
  LocationFailingMixin
]

export default {
  name: 're-profile-cards',
  mixins,
  props,

  data() {
    return {
      showAddModal: false,
      showAddedSuccessModal: false,
      showArchiveSuccessModal: false,
      showTransferBalanceModal: false,
      isTransferBalanceModalInvisible: false,
      showTransferBalanceSuccessModal: false,
      showInfoModal: false,
      isNumberInfo: false,
      showResendModal: false,
      transferBalanceAttrs: {},
      filterLocationId: null,
    }
  },

  computed: {
    purchaseCardButtonAttrs() {
      const attrs = this.purchaseCardButtonAttributes || {}
      const type = this.cardType
      const append = `btn-new-card btn-new-${type}`
      const classes = this.parseAttrClasses(attrs, append)
      return {
        ...attrs,
        class: classes
      }
    },
    initAddCardButtonAttrs() {
      const attrs = this.initAddCardButtonAttributes || {}
      const type = this.cardType
      const append = `show-add-${type}-modal show-add-card-modal`
      const classes = this.parseAttrClasses(attrs, append)
      return {
        ...attrs,
        class: classes
      }
    },
    canTransferBalance() {
      const transferrableCards = _.filter(this.cards, card => !card.is_locked)
      return transferrableCards.length && this.allowTransferBalance
    },
    initTransferBalanceButtonAttrs() {
      const attrs = this.initTransferBalanceButtonAttributes || {}
      const type = this.cardType
      const append = `show-transfer-balance-modal`
      const classes = this.parseAttrClasses(attrs, append)
      return {
        ...attrs,
        class: classes
      }
    },
    infoTitle() {
      return this.isNumberInfo ?  this.numberInfoModalTitle : this.codeInfoModalTitle
    },
    infoHeaderText() {
      return this.isNumberInfo ? this.numberInfoModalHeader : this.codeInfoModalHeader
    },
    infoFooterText() {
      return this.isNumberInfo ? this.numberInfoModalFooter : this.codeInfoModalFooter
    },
    infoImgSrc() {
      return this.isNumberInfo ? this.numberInfoModalImgSrc : this.codeInfoModalImgSrc
    },
    hasCards() {
      return !_.isEmpty(this.cardsList)
    },
    cardTabs() {
      const defaultTab = {
        hideTitle: true,
        title: 'Cards',
        active: true,
        cards: this.cards
      }
      if (!_.isEmpty(this.tabs)) {
        const tabs = this.tabs
        if (_.size(tabs) === 1) {
          const tabKey = _.head(_.keys(tabs))
          const tab = {...defaultTab, ...(_.head(_.values(tabs)))}
          return {
            [tabKey]: tab
          }
        }
        return tabs
      }
      return {
        allCards: defaultTab
      }
    },
    hasTabs() {
      return !_.isEmpty(this.cardTabs)
    },
    cards() {
      if (this.showLocationFilter && this.filterLocationId) {
        const cards = _.filter(this.cardsList, card => card.location_id === this.filterLocationId)
        this.$emit('update:cards', cards)
        return cards
      }
      this.$emit('update:cards', this.cardsList)
      return this.cardsList
    },
    addCardAttr() {
      return _.pickBy({
        classes: this.addCardClass,//@fix
        title: this.addCardTitle, //@fix
        showTitle: this.addCardShowTitle, //@added
        isProcessing: this.isProcessingAddCard,
        addingMessage: this.addingMessage, //@fix
        errorMessage: this.addingErrorMessage,

        numberLabel: this.addCardNumberLabel, //@fix
        numberPlaceholder: this.addCardNumberPlaceholder, //@fix
        numberClasses: this.addCardNumberClass, //@fix
        codeLabel: this.addCardCodeLabel, //@fix
        codePlaceholder: this.addCardCodePlaceholder, //@fix
        codeClasses: this.addCardCodeClass, //@fix

        buttonTitle: this.addCardButtonTitle,
        buttonClasses: this.addCardButtonClass,//@fix
        showCancelButton: this.addCardShowCancelButton,//@fix
        cancelButtonTitle: this.addCardCancelButtonTitle, //@fix
        cancelButtonClasses: this.addCardCancelButtonClass, //@fix

        hasResendCode: this.addCardHasResendCode, //@fix
        resendCodeMessage: this.addCardResendCodeMessage, //@fix
        resendCodeLinkLabel: this.addCardResendCodeLinkLabel, //@fix

        hasHelpNumberLink: this.addCardHasHelpNumberLink, //@fix
        helpNumberLinkLabel: this.addCardHelpNumberLinkLabel, //@fix

        hasHelpCodeLink: this.addCardHasHelpCodeLink, //@fix
        helpCodeLinkLabel: this.addCardHelpCodeLinkLabel, //@fix
        ...this.$$t('addCard.attrs', {}),
        ...this.$$s('addCard.attrs', {}),
      }, i => !_.isNil(i))
    },
    locationInputAttrs() {
      const allOptionLabel = this.$$t('form.filters.location_id.allOptionLabel')
      const defaults = this.locationInputOptions || {
        label: 'Select location'
      }
      if (!_.has(defaults, 'blankOption')) {
        defaults.blankOption = 'Select location'
      }
      defaults.context = this
      return this.getLocationFieldProperties('location_id', {defaults, allOptionLabel}, 'filters')
    },
  },
  watch: {
    filterLocationId(id) {
      if (id) {
        this.profileLocationId = id
      }
    },
    profileLocationId(id) {
      if (id && this.filterLocationId && id !== this.filterLocationId) {
        this.filterLocationId = id
      }
    }
  },
  async created() {
    this.registerEventListeners()
    if (this.profileLocationId) {
      this.filterLocationId = this.profileLocationId
    }
  },

  methods: {
    getComponentExtraClasses() {
      return [{
        'can-transfer-balance': !this.isSelectedLocationFailing && this.canTransferBalance,
        'is-location-failing': this.isSelectedLocationFailing,
        'using-location-filter': this.filterLocationId && this.hasCards && this.showLocationFilter,
        'not-using-location-filter': !this.filterLocationId || !this.hasCards || !this.showLocationFilter
      }]
    },
    getCardAttr(card) {
      return {
        card: card,
        context: this.context || this.$parent,
        cardType: card.type || this.cardType,
        showImage: this.showProductImage,
        imageClass: this.productImageClasses,
        modalClasses: this.modalClasses,

        purchaseLink: this.purchaseCardButtonLink,
        reloadRoute: this.reloadCardRoute,
        reloadType: this.reloadCardType,
      }
    },
    getCardTranslations() {
      return this.$$t('card', this.$$t('cardItem', this.$$t('component.card')))
    },
    getCardSettings() {
      return this.$$s('card', this.$$s('cardItem', this.$$s('component.card')))
    },
    registerEventListeners() {
      EventBus.$on('giftcard.archived', card => {
        this.showArchiveSuccessModal = true
        this.autoLoadData()
      })
      EventBus.$on('playcard.archived', card => {
        this.showArchiveSuccessModal = true
        this.autoLoadData()
      })

      EventBus.$on('card.open.resend.code', () => {
        this.showResendModal = true
      })
      EventBus.$on('card.open.info.number', () => {
        this.showInfoModal = true
        this.isNumberInfo = true
      })
      EventBus.$on('card.open.info.code', () => {
        this.showInfoModal = true
        this.isNumberInfo = false
      })
      EventBus.$on('card.added', () => {
        this.showAddModal = false
        this.showAddedSuccessModal = true
        this.autoLoadData()
      })
      EventBus.$on('transfer-balance-from-card', (card) => {
        this.transferBalanceAttrs = {from: card.id}
        this.openTransferBalanceModal()
      })
    },

    addCard(fields, onSuccess, onError) {
      this.$emit('addCard', fields, onSuccess, onError)
    },

    openAddCardModal() {
      this.showAddedSuccessModal = false
      this.showAddModal = true
    },
    closeAddCardModal() {
      this.showAddModal = false
    },

    balanceTransferred() {
      setTimeout(() => {
        this.autoLoadData()
      }, 0)
    },
    openTransferBalanceModal() {
      this.showTransferBalanceSuccessModal = false
      this.showTransferBalanceModal = true
    },
    closeTransferBalanceModal() {
      this.transferBalanceAttrs = {}
      this.showTransferBalanceModal = false
      this.showTransferBalanceSuccessModal = false
    },

    closeInfoModal() {
      this.showInfoModal = false
      this.isNumberInfo = false
    },
    closeResendModal() {
      this.showResendModal = false
      this.recoverSuccess = false
    },
    recoverByEmail(payload) {
      this.$emit('recoverByEmail', payload)
    },
    recoverByNumber(payload) {
      this.$emit('recoverByNumber', payload)
    },
    async autoLoadData() {
      const autoLoad = _.uniq(this.autoLoad || [])
      await this.autoLoadAll(autoLoad)
    }
  },
}
