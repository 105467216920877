import {name, mixins, props, data, EventBus} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return data
  },
  async created() {
    this.fields.email = decodeURIComponent(this.email)
    this.fields.token = this.token
    EventBus.$emit('email-verification.init', this.email, this.verifyingMessage)
      this.isBeingProcessed = true
      try {
        const [data] = await this.promise('user.email.verify', this.fields)
        this.success = true
        EventBus.$emit('email-verification.success', this.email, this.successMessage, data)
      } catch (e) {
        this.success = false
        EventBus.$emit('email-verification.failed', e, this.email, this.failureMessage)
      } finally {
        this.isBeingProcessed = false
        EventBus.$emit('email-verification.end', this.email)
      }
  }
}
