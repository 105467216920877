import ModalMixin from '../../../mixins/modal'
import MountMixin from '../../../mixins/custom-mount'

export const mixins = [ModalMixin, MountMixin]

export default {
  name: 're-modal',
  mixins,
  type: 'generic',
  events: 'modal',
  data() {
    return {
      MOUNT_TARGET: this.$$s('globalModal.appendTo.target'),
      MOUNT_CONTAINER: this.$$s(
        'globalModal.appendTo.container',
        '.rocket-effect-container.rocketeffect-global-modal-container'
      ),
      MOUNT_CONTAINER_CLASSES: this.$$s(
        'globalModal.appendTo.classes',
        'rocket-effect-container rocketeffect-global-modal-container'
      ),
    }
  },
  created() {
    document.addEventListener('keyup', this.keyUp)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyUp)
  }
}
