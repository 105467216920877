import {Vuex} from '~/addiesaas'
const {createNamespacedHelpers} = Vuex
import { namespace } from '../vuexstore'
import store, {namespace as pageNamespace } from '../vuexstore/modules/super'
const { mapGetters, mapActions } = createNamespacedHelpers([namespace, pageNamespace].join('/'))

/*** SUPERSTORE
 *    Mixin using vuex to facilitate dynamic vuex storage (optionally with persistence)
 *    without the need to define a vuex module and related mixin.
 *
 * HOW TO USE SUPERSTORE
 *  1. Set superStoreName to a identifiable unique key which will act as the namespace
 *  2. Define all properties (even with null values) to be stored under the superStoreName in vuex in superStoreData
 *  3. Define all properties (even with null values) to be persistently stored in superStorePersistData
 *  4. Use this.$$store computed property to get/set the full non-persist store under the superStoreName
 *  5. Use this.$$storePersist computed property to get/set the full persist store under superStoreName
 *  6. Use this.$$getStore(key, defaultValue) or $$getStorePersist(key, defaultValue)
 *  7. Use this.$$setStore(key, value) or $$setStorePersist(key, value)
 *  8. NOTE: this.$$initStore is called automatically on created event which instantiate the store under the condition:
 *    a) superStoreName is truthy, i.e. defined
 *    b) superStoreData is not null/undefined
 *  9. Can be enhanced further to define computed properties of vm from the store (both persist and non-persist)
 *
 */
export default {
  data() {
    return {
      superStoreName: null,
      superStoreData: {},
      superStorePersistData: {},
    }
  },
  async created() {
    this.$nextTick()
    this.$$initStore()
  },
  computed: {
    $$store: {
      get() {
        return this.$$getStore()
      },
      set(value) {
        this.$$setStore(value)
      }
    },
    $$storePersist: {
      get() {
        return this.$$getStorePersist()
      },
      set(value) {
        this.$$setStorePersist(value)
      }
    },
    ...mapGetters(Object.keys(store.getters))
  },
  methods: {
    $$initStore() {
      if (this.superStoreName && !_.isNil(this.superStoreData)) {
        this.$$storeInit({[this.superStoreName]: this.superStoreData})
        this.$$storePersistInit({[this.superStoreName]: this.superStorePersistData || {}})
      }
    },
    $$getStore(key, defaultValue) {
      const path = this.superStoreName + (key ? ('.' + key) : '')
      return this.$$storeGet(path, defaultValue)
    },
    $$getStorePersist(key, defaultValue) {
      const path = this.superStoreName + (key ? ('.' + key) : '')
      return this.$$storePersistGet(path, defaultValue)
    },
    $$setStore(key, value) {
      if (this.superStoreName) {
        if (_.isObject(key)) {
          this.$$storeSet({[this.superStoreName]: key})
        } else {
          const path = this.superStoreName + (key ? ('.' + key) : '')
          this.$$storeSet(_.set({}, path, value))
        }
      }
    },
    $$setStorePersist(key, value) {
      if (this.superStoreName) {
        if (_.isObject(key)) {
          this.$$storeSetPersist({[this.superStoreName]: key})
        } else {
          const path = this.superStoreName + (key ? ('.' + key) : '')
          this.$$storeSetPersist(_.set({}, path, value))
        }
      }
    },
    ...mapActions(Object.keys(store.actions))
  }
}
