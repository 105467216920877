/*global _ */
import props from './props'
import FormMixin from '../../../../../mixins/form'
import {required, email} from '../../../../../lib/validations'

const mixins = [FormMixin]
const componentDefAttrs = [
  'settings', 'translations', 'form', 'customError', 'classes',
  'validations', 'v', 'vRoot', '$$v'
]

export default {
  name: 're-email-input',
  mixins,
  props,
  data() {
    return {
      fields: {
        value: this.value
      },
      validations: {
        value: {
          name: 'fields.value',
          items: {
            required: {error: 'Email is required.'},
            email: {error: 'Email is not valid.'},
          },
        },
      },
    }
  },
  validations() {
    return {
      fields: {
        value: {
          required,
          email
        }
      }
    }
  },
  computed: {
    componentAttrs() {
      const attrs = this.$$attrs()
      return _.pick(attrs, componentDefAttrs)
    },
    fieldAttrs() {
      const $$v = this.$v
      return {
        validations: this.validations.value,
        context: this.context || this.$parent,
        field: this.getField(),
        $$v,
        vRoot: $$v.fields,
        v: $$v.fields.value,
        ...this.componentAttrs
      }
    },
    selectedListeners() {
      return _.omit(this.$listeners, ['input'])
    },
    model: {
      get() {
        return this.fields.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  watch: {
    'fields.value'(value) {
      const valid = !this.isAnyInvalid
      const needsValid = this.emitOnValid
      if (needsValid && !valid) {
        this.model = ''
      } else {
        this.model = value
      }
    },
    value(newValue) {
      this.fields.value = newValue
    }
  },
  methods: {
    getField() {
      const attrs = this.$$attrs()
      return {
        name: 'email',
        label: 'Email address',
        required: true,
        attr: {
          type: 'email'
        },
        ..._.omit(attrs, componentDefAttrs),
      }
    },
    onDirty(value) {
      this.isDirtyState = true
    }
  }
}
