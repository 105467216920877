export default {
  props: {
    filterByProducts: {
      default() {
        const $$s = this.$options.$$s
        if ($$s) {
          return $$s('filterByProducts', null)
        }
        return null
      }
    }
  },
  data() {
    return {
      filterByProductType: null
    }
  }
}
