/*global _ */
import {
  name,
  mixins,
  props,
  data,
  defaultFormFields,
  validationsRules,
} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      fields: {
        card: ''
      },
      cards: [],
    }
  },
  computed: {
    cardSelectionOptions() {
      const cards = _.cloneDeep(_.values(this.cards || []))
      const params = {cards, data: this.cards, vm: this}
      this.$$applyDataFilters(`${this.eventSpace}.playcards`, params)
      this.$$applyDataFilters('playcards.selectOptions', params)
      return params.cards
    },
    cardSelectionAttrs() {
      const attrs = this.cardSelectAttrs || {}
      const defaults = this.getFieldProps('card', {
        defaults: {
          blankOption: 'Select a Card',
          label: 'Select Card',
          options: this.cardSelectionOptions,
          reduceValue: '{{number}}',
          reduceLabel: '{{number}}'
        }
      })
      return _.merge({}, defaults, attrs)
    }
  },
  async created() {
    this.setupForm(validationsRules, defaultFormFields)
    await this.loadCards()
    await this.init()
  },
  methods: {
    async loadCards() {
      let cards = _.get(this.storedProfileData, 'states.cards.playcard')
      if (_.isEmpty(cards)) {
        this.startPromising()
        try {
          cards = await this.invokeService('user.card.playcards.get')
          const params = {cards, vm: () => this}
          this.$$applyDataFilters(`playcard.cards`, params)
          this.updateProfileDataItem({'states.cards.playcard': params.cards})
        } catch (e) {
          cards = []
        }
        this.stopPromising()
      }
      this.cards = this.applyFilter(cards)
      return cards
    },
    applyFilter(cards) {
      cards = _.cloneDeep(_.filter(cards || [], card => !card.is_locked))
      const location = this.selectedLoyaltyLocationId
      if (location) {
        return _.filter(cards, card => card.location_id === location)
      }
      return cards
    },
    getOrderPayload() {
      const card = this.fields.card
      const reward = this.reward
      const items = [{
        product_id: reward.product.id,
        options: {
          number: card,
          location_id: this.selectedLoyaltyLocationId
        },
        count: 1,
      }]
      return {items}
    }
  }
}
