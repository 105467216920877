/*global _ Vue */
import {inject} from '../dynamic'
import {promiseFactory} from '../utils'

const useCreate = {
  _$useCreate: true
}

const optionFactory = (message, title, options = {}) => {
  options = options || {}
  if (_.isObject(message)) {
    options = _.merge({}, message, options || {})
  } else {
    options.message = message || ' '
  }
  if (!_.isUndefined(title)) {
    if (_.isObject(title)) {
      options = _.merge({}, title, options || {})
    } else {
      options.title = title || options.title || ' '
    }
  }

  return options
}

const componentFactory = function (module, properties, defaultProps, skipProps) {
  properties = properties || {}
  const defaultSkip = ['props', 'slots', 'options', 'target', 'prepend', 'append', 'after']
  skipProps = skipProps || []
  defaultProps = _.defaultsDeep(defaultProps, {
    title: null,
    message: null,
    noClose: false,
  })
  defaultProps = _.omit(_.omit(defaultProps, skipProps), defaultSkip)

  let targetElement = 'body'
  if (this instanceof Vue) {
    targetElement = this
  }

  const allProperties = {...defaultProps, ...(properties || {})}
  const props = {
    config: {
      ...defaultProps,
      ..._.omit(_.omit(properties || {}, skipProps), defaultSkip),
    },
    ...(properties.props || {})
  }
  const {
    _$useCreate = false,
    slots,
    target = targetElement,
    options = {},
    prepend,
    before,
    after
  } = allProperties
  Object.assign(options, {prepend, before, after})

  const component = inject(module, {useCreate: _$useCreate, slots, props, target, options})

  const dismiss = () => {
    if (component && component.$el) {
      component.$el.remove()
    }
    if (component && component.$destroy) {
      component.$destroy()
    }
  }
  component.$on('dismiss', dismiss)
  component.$on('close', dismiss)
  return component
}

export const alert = function (properties = {}) {
  const defaultProps = {
    title: null,
    message: '',
    noClose: false,
    _$useCreate: true,
  }
  return componentFactory.call(this, 're-alert', properties, defaultProps)
}

export const alertAsync = function (message, title, type, options = {}) {
  options.type = type
  return promiseFactory.call(this, alert, optionFactory(message, title, options))
}

export const infoAlert = function (message, title, options = {}) {
  options.type = 'info'
  return alert.call(this, optionFactory(message, title, options))
}

export const infoAlertAsync = function (message, title, options = {}) {
  return alertAsync.call(this, message, title, 'info', options)
}

export const warningAlert = function (message, title, options = {}) {
  options.type = 'warning'
  return alert.call(this, optionFactory(message, title, options))
}

export const warningAlertAsync = function (message, title, options = {}) {
  return alertAsync.call(this, message, title, 'warning', options)
}

export const errorAlert = function (message, title, options = {}) {
  options.type = 'error'
  return alert.call(this, optionFactory(message, title, options))
}

export const errorAlertAsync = function (message, title, options = {}) {
  return alertAsync.call(this, message, title, 'danger', options)
}

export const successAlert = function (message, title, options = {}) {
  options.type = 'success'
  return alert.call(this, optionFactory(message, title, options))
}

export const successAlertAsync = function (message, title, options = {}) {
  return alertAsync.call(this, message, title, 'success', options)
}

export const confirm = function (properties = {}) {
  const defaultProps = {
    title: null,
    message: 'Do you want to continue',
    denyLabel: 'No',
    confirmLabel: 'Yes',
    noClose: false,
    ...useCreate,
  }
  return componentFactory.call(this, 're-confirm', properties, defaultProps)
}

export const confirmAsync = async function (message, title, options = {}) {
  return promiseFactory.call(this, confirm, optionFactory(message, title, options))
}

const getModalProperties = function (component, options) {
  options = options || {}
  return {
    ...useCreate,
    ..._.isObject(component) ? component: {component, ...options}
  }
}

export const showModal = function (...args) {
  return componentFactory.call(this, 're-modal', getModalProperties(...args), {}, ['message', 'title'])
}

export const showModalAsync = async function (...args) {
  return promiseFactory.call(this, showModal, getModalProperties(...args))
}

// Almost similar to showModal
// Except it can accept any vuejs component to be rendered and can have modal-like close/cancel feature
// The vuejs component needs to emit cancel event to support this
export const render = function (component, options) {
  if (_.isObject(component)) {
    const args = component
    component = args.component
    options = args.options
  }
  component = component || 're-modal'
  options = _.merge({_$useCreate:true, target: 'body'}, options || {})
  return componentFactory.call(this, component, options, {}, ['message', 'title', 'noClose'])
}

export const renderAsync = async function (component, options) {
  return promiseFactory.call(this, render, {component, options})
}

export const modals = {
  alert,
  alertAsync,
  confirm,
  confirmAsync,

  infoAlert,
  infoAlertAsync,
  successAlert,
  successAlertAsync,
  warningAlert,
  warningAlertAsync,
  errorAlert,
  errorAlertAsync,

  showModal,
  showModalAsync,
  render,
  renderAsync
}

const ad = window.addiesaas = (window.addiesaas || {})
const adfn = ad.$fn || (ad.$fn = {})
adfn.modals = modals

export default modals
