const defaultTable = {
  "columns": [
    {
      "name": "name",
      "valuePath": "holder",
      "title": "Name",
    },
    {
      "name": "type",
      "valuePath": "brand",
      "title": "Type",
    },
    {
      "name": "card",
      "title": "Card Number",
      "valuePath": "card",
      "format": "maskLeft",
      "formatParams": ["XXXX XXXX XXXX 0000"],
    },
    {
      "name": "is_primary",
      "title": "Primary",
      "valuePath": "is_primary",
      "format": "yesno",
      "cellParseAttrs": {
        "data-primary-card": {
          "value": "{[$]}"
        }
      }
    },
    {
      "name": "actions",
      "showTitle": false,
      "rowActions": {
        "edit": {
          "component": "re-button",
          "componentAttrs": {
            "title": "Edit",
            "showTitle": true,
          },
          "event": "edit"
        },
        "delete": {
          "component": "re-button",
          "componentAttrs": {
            "title": "Delete",
            "showTitle": true,
          },
          "event": "delete"
        },
      }
    },
  ],
  "view": {
    "type": "auto"
  },
  "eventSpace": "profile.billing.table",
  "dataFilterSpace": "profile.billing.table"
}

export default {
  data() {
    return {
      defaultTable
    }
  },
  computed: {
    tableComponentAttrs() {
      return {
        ...this.tableComponentMainAttrs,
        disabled: !!this.editingCard,
        activeRow: this.editingRow
      }
    },
  },
}
