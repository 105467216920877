import config from '../../../../../../lib/config'
const {BASE_IMAGES: reImagePath = ''} = config

const defaultPaymentMethods = {
  message: null,
  items: {
    'visa': {
      'image': `${reImagePath}payment-card-visa.png`,
      'title': 'Visa',
      'url': '//usa.visa.com/',
      'cssClass': [],
    },
    'mastercard': {
      'image': `${reImagePath}payment-card-master.png`,
      'title': 'Mastercard',
      'url': '//www.mastercard.us/',
      'cssClass': [],
    },
    'americanexpress': {
      'image': `${reImagePath}payment-card-ae.png`,
      'title': 'American Express',
      'url': '//www.americanexpress.com/',
      'cssClass': [],
    },
    'discover': {
      'image': `${reImagePath}payment-card-discover.png`,
      'title': 'Discover',
      'url': '//www.discover.com/',
      'cssClass': [],
    },
  }
}

export default {
  name: 're-cart-payment-info',
  props: {
    classes:{
      type: [String, Array, Object],
      default: ''
    },
    paymentMethods: {
      type: Object,
      default: () => defaultPaymentMethods
    },
  },

  computed: {
    cartPaymentMethods() {
      return this.paymentMethods || {message: '', items: {}}
    },
  },
}
