import component from '../../../../../../props/component'
import form from '../../../../../../props/form'
import processing from '../../../../../../props/processing'

const localProps = {
  item: {},
  view: {
    type: [String],
    default() {
      return this.$options.$$s('view.type', 'list')
    }
  },
  showFeaturedImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.featured.show', true)
    }
  },
  showBackgroundImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('images.background.show', true)
    }
  },
}
export const props = {
  ...component,
  // ...form,
  ...processing,
  ...localProps
}
export default props
