export const name = 're-forgot-password'

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields} from './form'

import props from './props'

export {props, validationsRules, defaultFormFields}

import ComponentMixin from '../../../../mixins/components/complex'
import FormMixin from '../../../../mixins/form'
import ResendDelayMixin from '../../../../mixins/features/resend-delay'
export const mixins = [ComponentMixin, FormMixin, ResendDelayMixin]

export const EventBus = window.VueEventBus

const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
