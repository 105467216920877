/*global _ */
import {EventBus, DataFilter} from '../../lib/utils'

import LocationMixin from '../location'
import CartMixins from '../cart'
import ProductStoreMixin from '../store/product'

const mixins = [ProductStoreMixin, LocationMixin, CartMixins]

export default {
  mixins,
  data() {
    return {
      productQuantity: 1,
    }
  },
  computed: {
    canReduceQuantity() {
      return this.productQuantity > 1
    },
    canIncreaseQuantity() {
      return true
    },
  },
  async created() {

  },

  methods: {
    getProductCartItem(item, getDefault = false) {
      item = item || this.productItem || {}
      const id = this.getProductSlug(item, item.id)
      let cartItem = this.currentCartItems[id]
      if (!cartItem && getDefault) {
        cartItem = _.merge({}, this.cartItemStub, {
          id,
          title: item.title,
          type: item.type,
          total: 1 * item.price,
          attributes: {
            product: item
          },
          payload: {
            product_id: item.id,
          },
          editRoute: this.getProductEditRoute(item),
          deletable: true,
          editable: false,
        })
      }
      return _.cloneDeep(cartItem)
    },
    getProductCartedQuantity(item) {
      item = item || this.productItem || {}
      const cartItem = this.getProductCartItem(item)
      return _.get(cartItem, 'payload.count', 1)
    },
    subProductQuantity() {
      const newQuantity = --this.productQuantity
      if (newQuantity >= 1) {
        this.productQuantity = newQuantity
      }
    },
    addProductQuantity() {
      const newQuantity = ++this.productQuantity
      if (newQuantity >= 1) {
        this.productQuantity = newQuantity
      }
    },
    async addProductItemToCart(item, quantity, moveToCart) {
      item = item || this.productItem || {}
      let cartItem = this.getProductCartItem(item)

      let success
      if (!cartItem) {
        cartItem = this.getProductCartItem(item, true)
        if (quantity) {
          _.set(cartItem, 'payload.count', quantity)
          cartItem.total = quantity * item.price
        }
        success = await this.addCartItem(cartItem)
      } else {
        if (!quantity) {
          quantity = (cartItem.payload.count || 0) + 1
        }
        _.set(cartItem, 'payload.count', quantity)
        cartItem.total = quantity * item.price
        success = await this.updateCartItem(cartItem)
      }

      if (success && moveToCart) {
        if (moveToCart === true) {
          this.goto('cart')
        } else {
          this.goto(moveToCart)
        }
      }
    },

  }
}
