import {setTimeoutAsync, setTimeoutPromise} from '../lib/utils'

export default {
  data: () => ({
  }),
  methods: {
    setTimeoutPromise, // @deprecated: use setTimeoutAsync instead
    setTimeoutAsync
  }
}
