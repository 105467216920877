/*global _ */
import {Vue} from '~/addiesaas'

import {
  name,
  mixins,
  props,
  data,
  defaultFormFields,
  validationsRules,
  defaultSections,
  EventBus
} from './config'

export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      selectedCardProperty: 'selectedCard',
      selectedCardField: 'fields.number',
      fields: {
        number: this.number || null,
        cvv: this.cvv || null,
      },
      showInfoModal: false,
      isNumberInfo: false,
      showResendModal: false,
      showBalanceModal: false,
      recoverSuccess: false,
      showingBalance: false,
      card: null,
      loginEventSpace: 'check-balance.login',
      isLoadingCards: false,
      allCards: [],
      allCardsTyped: {},
      forceHidePurchaseLink: false
    }
  },

  computed: {
    showAuth() {
      return this.onlyAuth && !this.authenticated
    },
    balanceProductTypes() {
      return this.productTypes || {}
    },
    hasMultiTypes() {
      return _.size(this.balanceProductTypes || {}) > 1
    },
    balanceCardTypes() {
      return _.filter(_.map(this.balanceProductTypes, 'type'))
    },
    infoTitle() {
      return this.isNumberInfo ?  this.numberInfoModalTitle : this.codeInfoModalTitle
    },
    infoHeaderText() {
      return this.isNumberInfo ? this.numberInfoModalHeader : this.codeInfoModalHeader
    },
    infoFooterText() {
      return this.isNumberInfo ? this.numberInfoModalFooter : this.codeInfoModalFooter
    },
    infoImgSrc() {
      return this.isNumberInfo ? this.numberInfoModalImgSrc : this.codeInfoModalImgSrc
    },
    displayResendModal() {
      return this.showResendLink && this.showResendModal
    },
    hasCard() {
      return  !_.isEmpty(this.card)
    },
    displayBalanceModal() {
      return false;//this.showBalanceModal && this.hasCard
    },
    balance() {
      if (!this.hasCard) {
        return 0
      }
      const card = this.card
      return card.balance ? card.balance : (((1 * card.cash) || 0) + ((1 * card.bonus_cash) || 0))
    },

    balanceComponentName() {
      return this.balanceComponent || 're-blank-component'
    },
    hasUserCards() {
      return this.canLoadUserCards && !_.isEmpty(this.allCards)
    },
    cardSelectorComponentAttrs() {
      return this.hasUserCards && this.userCardSelectorAttrs || {}
    },
    canShowPurchaseLink() {
      return this.showPurchaseLink && !this.forceHidePurchaseLink
    },
    purchaseLinkAttributes() {
      const attrs = _.merge(
        {to: this.purchaseLink},
        this.purchaseLinkAttrs || {},
        this.parseReactiveAttrs(this.$$s('purchaseLinkParseAttrs', {}), {...this.fields})
      )
      return attrs
    },
    cvvRequired() {
      return !this.authenticated || !this.canLoadUserCards || !this.noCvvForAuth
    },
    selectedCard() {
      return this.getLoadedCard(this.fields.number)
    }
  },

  watch: {
    resendModalSuccess(newVal) {
      this.recoverSuccess = newVal
    },
    async authenticated(val) {
      if (val) {
        await this.init()
      } else {
        this.allCards = []
        this.allCardsTyped = {}
        this.initFields(true)
      }
    },
  },

  async created() {
    if (this.onlyAuth || this.canLoadUserCards) {
      await this.pingUser()
    }
    this.setupForm(validationsRules, defaultFormFields)

    if (this.user) {
      await this.init()
    }
  },
  methods: {
    async init() {
      if (!this.canLoadUserCards) {
        return
      }
      const options = {type: this.balanceCardTypes}
      this.isLoadingCards = true

      const {allCards, allCardsTyped} = await this.fetchCards(options)
      this.allCards = allCards
      this.allCardsTyped = allCardsTyped

      this.isLoadingCards = false
      await this.$nextTick()
      this.initFields()
    },
    initFields(isReset) {
      this.fields.number = isReset ? null : this.number
    },
    getNumberFieldComponent() {
      const defaults = {component: this.hasUserCards ? 're-select': 're-input'}
      const options = {...defaults, ...this.cardSelectorComponentAttrs}
      return options.component || 're-input'
    },
    getNumberFieldContainerAttrs() {
      const options = {...this.cardSelectorComponentAttrs, name: 'number'}
      return {
        class: this.getFieldContainerClasses(options)
      }
    },
    getNumberFieldAttrs() {
      const options = this.cardSelectorComponentAttrs
      return this.getFieldProps('number', {
        defaults: {
          ...options,
          checkInvalid: _.get(this.$v, 'fields.number.$dirty'),
          label: this.numberLabel,
          placeholder: this.numberPlaceholder,
          options: this.allCards
        }
      })
    },
    getLoadedCard(number) {
      return _.find(this.allCards, card => card.number === number)
    },
    setCard(card, fields) {
      Vue.set(this, 'card', card)
      if (!_.isEmpty(data)) {
        this.showBalance()
        this.emitSlim('success', card, fields, this)
      } else {
        const e = this.$$t('error.general', "Error while retrieving balance. Please try again.")
        throw new Error(e)
      }
    },
    async getBalance() {
      this.resetFieldsValidation()
      this.$v.$touch()
      this.formSubmitting = true
      if (!this.isAnyInvalid) {
        const fields = _.clone(this.fields)
        this.emitSlim('valid', fields, this)
        EventBus.$emit('card.check-balance', fields, this) // @deprecated: Use card.check-balance.before
        this.emitSlim('before', fields, this)

        if (!this.cvvRequired) {
          return this.setCard(this.selectedCard, fields)
        }
        const options = {type: this.cardType}
        try {
          const [data] = await this.promise('user.card.get', fields, options)
          const card = _.get(data, 'data', data)
          this.setCard(card, fields)
        } catch (e) {
          this.showFormError(e, {'scrollTo': '.re-card-balance-box', flattenTopMessages: true})
          this.emitSlim('error', e, this)
        } finally {
          this.emitSlim('end', fields, this)
        }

      } else {
        this.emitSlim('invalid', this)
        this.scrollToFirstErrorElement()
      }
    },
    getBalanceSections(card) {
      // debugLog({card})
      const types = this.balanceCardTypes
      const sections = this.parseDynamicSections(
        this.$$s('balance.sections', defaultSections),
        {card},
        'balance.sections'
      )
      const type = _.get(
        card,
        'data.type',
        _.get(card, 'type', _.first(types) || 'giftcard')
      )
      return _.pick(sections, [type])
    },
    showForm() {

    },
    hideForm() {

    },
    resetAll() {
      this.card = null
      this.resetForm()
      this.hideBalance()
    },
    showBalance() {
      this.showingBalance = true
      EventBus.$emit('card.check-balance.showing')
    },
    hideBalance() {
      this.showingBalance = false
      this.showBalanceModal = false
      EventBus.$emit('card.check-balance.another-card')
    },
    whatIsNumber() {
      this.showInfoModal = true
      this.isNumberInfo = true
      EventBus.$emit('card.open.info.number')
    },
    whatIsCode() {
      this.showInfoModal = true
      this.isNumberInfo = false
      EventBus.$emit('card.open.info.code')
    },
    closeInfoModal () {
      this.showInfoModal = false
      this.isNumberInfo = false
    },
    openResendModal() {
      this.showResendModal = true
      EventBus.$emit('card.open.resend-code')
    },
    closeResendModal() {
      this.showResendModal = false
      this.recoverSuccess = false
    },
    recoverByEmail(data) {
      this.$emit('recoverByEmail', data)
      EventBus.$emit('card.check-balance.resend-code.by-email', data)
    },
    recoverByNumber(data) {
      this.$emit('recoverByNumber', data)
      EventBus.$emit('card.check-balance.resend-code.by-number', data)
    },
    setupValidationRules(validationRules, fieldKey, parseValidations) {
      if (this.validateFailingCardLocation) {
        this.setupFailingCardLocationValidations(validationRules.number.items)
      }
      this.setValidationRules = function () {
        let rules = parseValidations(validationRules)
        if (!this.cvvRequired) {
          rules = _.cloneDeep(rules)
          rules['cvv'] = {}
        }
        const validations = {[fieldKey]: rules}
        return validations
      }
    }
  },
}
