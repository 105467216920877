/*global _ */
import {name, mixins, props, components, data, EventBus} from './config'
import {getParentSlots} from '../../../../lib/vue'

export default {
  name,
  mixins,
  props,
  components,

  data() {
    return {
      ...data,
      showingVerificationError: false,
      userVerificationPromptEmail: null,
    }
  },

  computed: {
    routeIsProfile() {
      return true
    },
    childSlots() {
      const allSlots = getParentSlots(this)
      const type = this.selected
      const slots = _.reduce(allSlots, (slots, items) => {
        return {
          ...slots,
          ..._.pickBy(items, (i, k) => _.startsWith(k, `${type}-`)),
        }
      }, {})
      return slots
    },
    selectedComponent() {
      const menu = this.menu.find(x => x.key === this.selected) || this.menu[0]
      return menu.component
    },
    userLandedAfterSignIn() {
      const parts = document.referrer.split('/');
      return parts[parts.length-1] === 'sign-in';
    },
    showMenuAsDropDown() {
      return this.showMenuAsDropDownMaxWidth && this.appWidth <= this.showMenuAsDropDownMaxWidth
    }
  },
  watch: {
    async selected(value) {
      await this.$nextTick()
      if (value) {
        if (this.scrollToTabMaxWidth) {
          const max = this.scrollToTabMaxWidth * 1
          if (max && this.appWidth <= max) {
            this.scrollTo('.re-profile-content > .re-profile-tab-content')
          }
        }
      }
    },
    user: {
      immediate: true,
      handler(user) {
        if (user && !this.user.verified && !this.isDesignerMode) {
          this.showVerificationError(this.user.email)
          this.logout()
        }
      }
    }
  },
  async created() {
    const isDesignerMode = this.isDesignerMode ||  this.designerMode
    if (!this.showLoginBox && !isDesignerMode) {
      this.authGuard(this)
    }

    EventBus.$on('user.logout', () => {
      if (!this.showLoginBox && !isDesignerMode) {
        this.authGuard(this)
      }
    })
    EventBus.$on('profile.change-tab', (key) => {
      this.select(key)
    })
    // detect hash change and change tab
    window.addEventListener('hashchange', async () => {
      const key = (location.hash || '').replace('#', '')
      await this.$nextTick()
      if (this.selected !== key) {
        this.select(key)
      }
    })

    EventBus.$emit('profile.created', this)

    if (!isDesignerMode){
      this.startPromising()
      await this.pingUser()
      if (this.authenticated) {
        if (this.user) {
          this.fetchUserSilent()
        } else {
          await this.fetchUserSilent()
        }
      }
    }

    if (this.user) {
      this.selectDefault()
    }

    this.stopPromising()
  },

  async updated() {
    if (!this.selected) {
      this.selectDefault()
    }
  },

  mounted() {
    if (this.userLandedAfterSignIn && this.user) {
      try {
        this.addUserHistoryRecord(
          this.user.email,
          _.get(
            this.userHistoryActions,
            'user_landed_to_profile_after_sign_in.name'
          )
        )
      } finally {
        //
      }

    }
  },
  methods: {
    showVerificationError(email) {
      this.userVerificationPromptEmail = email
      this.showingVerificationError = true
    },
    getProfileTabAttrs(name) {
      const otherAttrs = this.mergedAttr
      const translations = this.$$clone(this.$$t(`tab.${name}`, this.$t('this')))
      const settings = this.$$clone(this.$$s(`tab.${name}`, this.$$s()))
      const attr = {
        translations,
        settings,
      }
      return attr
    },
    getComponentExtraClasses() {
      return [`selected-tab-${this.selected}`]
    }
  },
}
