export const props = {
  formNotice: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('formNotice', 'You have earned {{rewardClaimTitle}}. {{br}}What Card would you like to add it to? {{br}}{{br}}{{expirationMessage}}')
    }
  },
  printButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('printButtonTitle', 'Print Voucher')
    }
  },
  cardSelectAttrs: {
    type: [String, Array, Object],
    default() {
      return _.merge(
        {},
        this.$options.$$t('form.fields.card', {}),
        this.$options.$$s('form.fields.card', {})
      )
    }
  }
}

export default props
