/*global _ */
import formatters from '../../../../../../lib/formatters'
import {name, mixins, props, data, EventBus} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data,
      eventSpace: 'shop.product.reservation',
      fields: {},
      galleryImages: []
    }
  },

  computed: {

  },

  async created() {
    if (this.editItem) {
      this.fields = _.get(this.editItem, 'attributes.fields', {})
    }
  },

  mounted() {

  },
  methods: {

    getComponentExtraClasses() {
      return []
    }
  },
}
