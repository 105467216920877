import addiesaas, {Vue} from '../../addiesaas'

import plugins from '../plugins'
import mixins from '../mixins'

import EventBus from '../eventBus'
import DataFilter from '../DataFilter'

Vue.config.productionTip = false;

export default {
  Vue, EventBus, DataFilter
}
