/*global _ */
export default {
  computed: {
    loyaltyAchievementRewards() {
      return this.loyaltyRewards.filter(reward => reward.type !== 'tier')
    },
    lockedAchievementRewards() {
      return _.filter(this.loyaltyAchievementRewards || [], r => !r.unlocked)
    },
    unlockedAchievementRewards() {
      return _.filter(this.loyaltyAchievementRewards || [], r => r.unlocked)
    },
    hasUnlockedAchievementRewards() {
      return !!this.unlockedAchievementRewards.length
    },
    loyaltyAchievementStatus() {
      return {
        ...this.loyaltyBaseStatus,
        locked: this.lockedAchievementRewards,
        unlocked: this.unlockedAchievementRewards,
      }
    },
  }
}
