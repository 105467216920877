/*global _ */
import { createNamespacedHelpers } from 'vuex'
import {getters, actions, absoluteNamespace} from '../vuexstore/modules/modals'

const { mapGetters, mapActions } = createNamespacedHelpers(absoluteNamespace)

import contextProp from '../props/context'

import ComponentMixin from './component-base'
import ReactiveMixin from './mashup-builder/reactives'
import EventsMixin from './events'
import ResponsiveMixin from './responsive'

import {getFirst} from '../lib/formatters'

const mixins = [
  ComponentMixin,
  ReactiveMixin,
  EventsMixin,
  ResponsiveMixin,
]

export const props = {
  ...contextProp,
  id: {
    type: [String, Function],
  },
  uniqueId: {
    type: [String, Number],
  },
  value: {},
  eventspace: {},
  config: {
    type: [Boolean, Function, Object],
    default: () => ({})
  },
  maskClass: {
    type: [String, Array, Object]
  },
  wrapperClass: {
    type: [String, Array, Object]
  },
  containerClass: {
    type: [String, Array, Object]
  },
  maskStyles: {
    type: [String, Array, Object]
  },
  wrapperStyles: {
    type: [String, Array, Object]
  },
  containerStyles: {
    type: [String, Array, Object]
  },
  component: {},
  componentAttrs: {},
  componentParseAttrs: {},
  componentListeners: {},
  componentCloseEvents: {},
  closeOnEscape: {
    type: [Boolean, Function]
  },
  noClose: {
    type: [Boolean, Function]
  },
  // @deprecated. Use noClose
  noCloseButton: {
    type: [Boolean, Function]
  },
}

export default {
  props,
  mixins,
  events: 'modal',
  computed: {
    options() {
      const propsToPick = _.omit(this.$props, ['config', 'context'])
      return {
        context: this.context,
        ..._.defaultsDeep({}, this.config || {}, propsToPick)
      }
    },
    ...mapGetters(Object.keys(getters))
  },
  async mounted() {
    const id = this.options.uniqueId
    if (id) {
      const existing = _.get(this.storedModalUniqueIds, id)
      if (existing) {
        await this.$nextTick()
        this.dismiss()
      } else {
        this.holdModalUniqueId({id, value: this._uid})
      }
    }
  },
  destroyed() {
    const id = this.options.uniqueId
    if (id) {
      const uid = _.get(this.storedModalUniqueIds, id)
      if (uid === this._uid) {
        this.releaseModalUniqueId(id)
      }
    }
  },
  methods: {
    ...mapActions(Object.keys(actions)),
    parseSourceData() {
      return {
        'this': this,
        '$context': this.options.context || this.$parent,
      }
    },
    parseDynamicAttrs(attrs) {
      const values = _.mapValues(attrs || {}, (def, key) => {
        if (_.startsWith(key, '_$')) {
          return this.parseDynamicAttrs(def)
        }
        if (_.isString(def)) {
          def = {valuePath: def}
        }
        const data = this.parseSourceData()
        return this.parseReactiveValues(def, data)
      })
      return _.mapKeys(values, (value, key) => {
        if (_.startsWith(key, '_$')) {
          return key.replace(/^_\$/, '')
        }
        return key
      })
    },
    getComponentAttrs() {
      let componentAttrs = this.options.componentAttrs
      let componentParseAttrs = this.options.componentParseAttrs
      if (componentParseAttrs) {
        componentParseAttrs = this.parseDynamicAttrs(componentParseAttrs)
        componentAttrs = _.merge(
          {},
          componentAttrs || {},
          componentParseAttrs || {}
        )
      }
      return componentAttrs
    },
    getComponentListeners() {
      let listeners = this.options.componentListeners || {}
      listeners = _.mapValues(listeners, (listener) => {
        if (_.isObject(listener)) {
          listener = listener.handler
        }
        if (_.isString(listener)) {
          listener = listener.replace(/^@/, '')
          listener = getFirst(listener, this, this.options.context)
        }
        return listener
      })

      listeners = _.omitBy(listeners, (listener) => !_.isFunction(listener))

      // emit close event from component based on custom events of the component
      const componentCloseEvents = this.componentCloseEvents || []
      _.forOwn(componentCloseEvents, eventName => {
        if (eventName) {
          const closeListener = listeners[eventName]
          listeners[eventName] = function (...args) {
            if (_.isFunction(closeListener)) {
              closeListener.call(this, ...args)
            }
            this.close(...args)
          }
        }
      })

      return listeners
    },

    getModalClasses() {
      const type = this.options.type || this.$options.type || 'generic'
      return [
        `re-modal-${type}`,
        ...this.getModalExtraClasses()
      ]
    },
    getModalExtraClasses() {
      return []
    },
    dismiss() {
      const {then} = this.options
      then && then()
      this.$emit('dismiss')

      // backward compatibility
      this.$emit('close')
    },
    close(state = false, ...args) {
      const {then} = this.options
      then && then(state)
      this.$emit('close', state, ...args)
      this.dismiss()
    },
    keyUp(event) {
      if (this.options.closeOnEscape !== false && event.keyCode === 27) {
        this.close()
      }
    }
  }
}
