import {createNamespacedHelpers } from 'vuex'
import {fullNamespace} from '../../vuexstore/modules/shop'
const {mapGetters, mapActions} = createNamespacedHelpers(fullNamespace)
export default {
  computed: {
    ...mapGetters(['shopLocationId'])
  },
  methods: {
    ...mapActions(['setShopLocationId'])
  }
}
