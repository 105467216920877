const defaultTable = {
  "columns": [
    {
      "name": "date",
      "title": "Date",
      "valuePath": "created_at",
      "format": "formatDate",
    },
    {
      "name": "number",
      "key": "invoice_number",
      "title": "Number"
    },
    {
      "name": "amount",
      "title": "Amount",
      "valuePath": "total",
      "format": "currency"
    },
    {
      "name": 'card',
      "title": "Paid with",
      "value": "{[$row]}",
      "format": "context.getCardDetails",
    },
    {
      "name": 'details',
      "valuePath": 'invoice_url',
      "title": "",
      "component": "re-link",
      "componentParseAttrs": {
        "url": "invoice_url"
      },
      "componentAttrs": {
        "title": "Details"
      }
    }
  ],
  "view": {
    "type": "auto"
  }
}

const filters = [
  {name: 'All', value: ''},
  {name: 'Finished', value: 'finished', noDataError : 'There are no finished orders at the moment.'},
  {name: 'Failed', value: 'failed', noDataError : 'There are no failed orders at the moment.'},
  {name: 'Refunded', value: 'refunded,admin-refunded,admin-partially-refunded', noDataError : 'There are no refunded orders at the moment.'},
]

export default {
  data() {
    return {
      defaultTable
    }
  },
  computed: {
    filter: {
      get() {
        return _.get(this.storedProfileData, 'orders.filter', '')
      },
      set(value) {
        this.updateProfileDataItem({'orders.filter': value})
      }
    },
    filterComponent() {
      return this.$$s('table.filter.component', 're-select')
    },
    filterComponentAttrs() {
      const $s = this.$$s('table.filter.componentAttrs', {})
      const $t = this.$$t('table.filter.componentAttrs', {})
      return _.merge({options: filters}, $t, $s)
    },
    tableData() {
      return this.filteredOrders
    },
    tableComponentAttrs() {
      return {
        ...this.tableComponentMainAttrs,
        disabled: !!this.editingMember,
        activeRow: this.editingRow
      }
    },
    hasItems() {
      return !!this.ordersCount
    },
    canShowHeaderButtons() {
      return this.showStatusFilters &&
        (this.filter !== '' || this.hasItems) &&
        !this.isBeingProcessed
    }
  },
  watch: {
    async filter(value, oldValue) {
      if (!_.isNil(oldValue)) {
        await this.applyFilter()
      }
    }
  },
  methods: {
    async applyFilter() {
      await this.fetch(false, true)
    }
  }
}
