import {Vue} from '~/addiesaas'
import ReAddCard from './general/re-add-card'
Vue.component(ReAddCard.name, ReAddCard)
import ReTransferCardBalance from './general/re-transfer-card-balance'
Vue.component(ReTransferCardBalance.name, ReTransferCardBalance)

import ReForgotCodeCard from './general/re-forgot-code-card'
Vue.component(ReForgotCodeCard.name, ReForgotCodeCard)

import ReCardBalance from './general/re-card-balance'
Vue.component(ReCardBalance.name, ReCardBalance)

import ReReloadCard from './re-reload-card'
Vue.component(ReReloadCard.name, ReReloadCard)

import ReBuyGiftcard from './re-buy-giftcard'
Vue.component(ReBuyGiftcard.name, ReBuyGiftcard)
import ReBuyGiftcardExtended from './re-buy-giftcard-extended'
Vue.component(ReBuyGiftcardExtended.name, ReBuyGiftcardExtended)

import ReCardItem from './general/re-card-item'
Vue.component(ReCardItem.name, ReCardItem)

import ReBuyCard from './re-buy-card'
Vue.component(ReBuyCard.name, ReBuyCard)

import ReBuyPlaycard from './re-buy-playcard'
Vue.component(ReBuyPlaycard.name, ReBuyPlaycard)

export default {
  ReAddCard,
  ReTransferCardBalance,
  ReForgotCodeCard,
  ReCardBalance,
  ReReloadCard,
  ReBuyGiftcard,
  ReCardItem,
  ReBuyCard,
  ReBuyPlaycard
}
