import {name, mixins, props, data, defaultFormFields, validationsRules, EventBus} from './config'

export default {
  name,
  props,
  mixins,

  events: 'user.login',
  data() {
    return {
      ...data,
      errorMessage: this.error,
      fields: {
        username: this.email
      },
      forgotPasswordShown: this.forgotPassword,
      forgotPasswordEmail: this.email,
    }
  },

  computed: {
    showForm() {
      return !this.forgotPasswordShown || this.showForgotPasswordPopup
    },
    loginBannerStyle() {
      return this.bannerImage ? { backgroundImage: `url('${this.bannerImage}')`} : ''
    },
    forgotPasswordAttr() {
      const attr = this.mergedAttr
      return _.pickBy(attr, (a, k) => /forgotpassword/i.test(k))
    },
    registrationLinkPosition() {
      if (this.hideRegistration) {
        return false
      }
      return this.registrationLinkAfterSubmit ? 'after-submit' : (
        this.registrationLinkAtTop ? 'top' : 'bottom'
      )
    }
  },

  async created() {
    if (this.redirectOnPreAuth && this.authenticated) {
      await this.redirectOnAuth(true)
    }
    this.setupForm(validationsRules, defaultFormFields)
  },

  watch: {
    async triggerSubmit(event) {
      if (event) {
        await this.loginNow(event)
      }
    }
  },
  methods: {
    async trySubmitOnEnter() {
      if(this.submitOnEnter) {
        await this.loginNow()
      }
    },
    async loginNow(event) {
      this.isLoggingIn = false
      this.resetFieldsValidation()
      this.showingVerificationError = false
      const fields = _.cloneDeep(this.fields)
      const publicFields = _.omit(fields, ['password'])
      this.emit('before', publicFields)
      this.$emit('before', publicFields)
      this.$v.$touch()
      this.formSubmitting = true
      if (!this.isAnyInvalid) {
        this.$emit('valid', fields)
        this.emit('valid', fields)
        if (this.disableActions) {
          return
        }
        this.isLoggingIn = true
        try {
          this.$emit('init', publicFields)
          this.emit('init', publicFields)
          const [data] = await this.promise('user.login', fields)
          await this.finalizeLogin(data, publicFields)
          this.emit('success', data, fields)
          this.$emit('success', data, fields)
          this.resetForm()
        } catch (e) {
          this.isLoggingIn = false
          this.emit('error', e, publicFields)
          this.$emit('error', e, publicFields)
          this.showLoginFormError(e, {form: publicFields, 'scrollTo': '.re-login-box'})
        } finally {
          this.emit('end', publicFields)
          this.$emit('end', publicFields)
      }
      } else {
        this.emit('invalid', publicFields)
        this.$emit('invalid', publicFields)
        this.scrollToFirstErrorElement()
      }
    },
    showLoginFormError(e, options) {
      const all = e.all || {}
      if (all.username) {
        const label = this.$$t('form.fields.username.label', 'email')
        all.username = all.username.replace(/username/i, label)
        all.email = all.username
      }
      const original = e.$e || {}
      if (original.error === 'invalid_credentials') {
        all.password = e.message
      }
      if (original.error === 'user_not_confirmed' || /UserNotConfirmedException/.test(original.exception)) {
        all.username = e.message
        all.email = e.message
        if (this.showVerificationErrorMessage) {
          const {form = {}} = options
          this.showVerificationError(form.username || form.email)
          return null
        }
      }
      const fields = {username: true, email: true, ...options.form}
      return this.showFormError(e, {...options, flattenTopMessages: true, fields})
    },
    showVerificationError(email) {
      this.userVerificationPromptEmail = email
      this.showingVerificationError = true
    },
    showForgotPassword(email) {
      if (email === false) {
        this.forgotPasswordShown = false
        this.emit('close.forgot-password')
        this.$emit('closeForgotPassword')
      } else {
        email = email || this.fields.username || ''
        this.forgotPasswordEmail = email
        this.forgotPasswordShown = true
        this.emit('open.forgot-password', email)
        this.$emit('forgotPassword', email)
      }
    }
  }
}
