import * as types from '../mutation-types/modals'

// set this true to namespace all getters, mutations, actions etc.
export const namespaced =  true

export const namespace = 'Modals'
export const rootNamespace = 'REUtils'
export const absoluteNamespace = [rootNamespace, namespace, ''].join('/')

// state
const state = {
  uniqueIds: {}
}

// mutations
const mutations = {
  [types.SET_UNIQUE_ID](state, {id, value}) {
    state.uniqueIds[id] = value
  },
  [types.UNSET_UNIQUE_ID](state, id) {
    delete state.uniqueIds[id]
  },
  [types.UNSET_UNIQUE_IDS](state) {
    state.uniqueIds = []
  }
}

export const actions = {
  holdModalUniqueId({commit}, payload) {
    commit(types.SET_UNIQUE_ID, payload)
  },
  releaseModalUniqueId({commit}, id) {
    commit(types.UNSET_UNIQUE_ID, id)
  },
  clearModalUniqueIds({commit}) {
    commit(types.UNSET_UNIQUE_IDS)
  }
}

// getters
export const getters = {
  storedModalUniqueIds: state => state.uniqueIds,
}

export const plugins = []

const module = {namespaced, namespace, state, mutations, actions, getters, absoluteNamespace, plugins}

export default module
