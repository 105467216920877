/*global _ */
import props from './props'
import InputMixin from '../../../../mixins/form/input'
import F from '../../../../lib/formatters'
import {fs} from '../../../../lib/utils'

const mixins = [InputMixin]

export default {
  name: 're-input-button',
  mixins,
  props,
  data() {
    return {
      valueEmitted: false,
      previousValue: this.value,
      mode: this.showView && _.isNil(this.initMode) ? 'view': this.initMode
    }
  },
  computed: {
    injectedContext() {
      return this.context || this.$parent
    },
    isViewMode() {
      return this.mode == 'view'
    },
    viewAttrs() {
      return {
        ...this.viewAttr || {},
      }
    },
    parsedViewValue() {
      const value = fs(this.innerValue, this.viewValue, this.viewDefault)
      let formatDef = this.viewFormat
      if (formatDef) {
        if (_.isString(formatDef) || _.isArray(formatDef)) {
          formatDef = {format: formatDef}
        }
        try {
          return F.valueFormat.call(this, value, this.viewDefault, formatDef)
        } catch (e) {
          //
        }
      }
      return value
    },
    editButtonAttrs() {
      return {
        context: this.injectedContext,
        ...this.editButtonAttr || {},
      }
    },
    inputAttrs() {
      return {
        context: this.injectedContext,
        ...this.inputAttr || {},
        disabled: this.isButtonDisabled,
        readonly: this.isButtonDisabled,
      }
    },
    buttonAttrs() {
      return {
        context: this.injectedContext,
        ...this.buttonAttr || {},
        disabled: this.isButtonDisabled,
      }
    },
    cancelButtonAttrs() {
      return {
        context: this.injectedContext,
        ...this.cancelButtonAttr || {}
      }
    },
    resetButtonAttrs() {
      return {
        context: this.injectedContext,
        ...this.resetButtonAttr || {}
      }
    },
    isButtonDisabled() {
      return this.isResetNeeded && this.valueEmitted && this.disableInputOnApply
    },
    isButtonVisible() {
      return !this.noApplyButton && !this.isResetButtonVisible
    },
    isResetButtonVisible() {
      return this.isResetNeeded && !this.showView && this.valueEmitted
    },
    isCancelButtonVisible() {
      return this.showCancel && this.showView && !this.isViewMode
    },

    containerClasses() {
      return [
        this.containerClass,
        `re-input-button-${this.mode}-mode`
      ]
    },
    inputClasses() {
      return [
        {
          disabled: this.isButtonDisabled
        }
      ]
    },
    viewClasses() {
      return [
        this.viewClass
      ]
    },
    editButtonClasses() {
      return [
        this.editButtonClass
      ]
    },
    buttonClasses() {
      return [
        this.buttonClass,
        {
          disabled: this.isInputInvalid
        }
      ]
    },
    cancelButtonClasses() {
      return [
        this.cancelButtonClass
      ]
    },
    resetButtonClasses() {
      return [
        this.resetButtonClass
      ]
    },
    inputOptions() {
      return _.get(this.inputAttrs, 'options')
    },
    isInputInvalid() {
      return this.inputAttrs.required && F.nil(this.innerValue)
    }
  },
  watch: {
    innerValue(val) {
      if (this.noApplyButton) {
        this.update()
      }
    }
  },
  created() {
    // if (this.model) {
    //   this.valueEmitted = true
    // }
  },
  methods: {
    view() {
      this.mode = 'view'
      this.valueEmitted = false
    },
    edit() {
      this.mode = 'edit'
      this.valueEmitted = false
    },
    update() {
      this.emitValue()
    },
    onDirty(value) {
      this.isDirtyState = true
    },
    getLabelExtraClasses() {
      return ['re-input-button-label']
    },
    async emitValueOnKey(e) {
      if (this.applyOnKeys) {
        await this.$nextTick()
        this.emitValue()
      }
    },
    emitValue() {
      this.model = this.innerValue
      // if (!nil(this.innerValue)) {
      //   this.valueEmitted = true
      // }
      this.previousValue = this.model
      if (this.showView) {
        this.view()
      }
    },
    resetInput() {
      this.valueEmitted = false
      if (this.clearOnReset) {
        this.innerValue = this.resetValue
        if (this.applyOnReset) {
          this.model = this.innerValue
        }
      }
    },
    cancelEdit() {
      if (!this.valueEmitted) {
        this.innerValue = this.previousValue
      }
      this.view()
    },
    getInputOption(value, options = {}) {
      options = options || {}
      const findBy = options.findBy || 'id'
      const item = _.find(this.inputOptions || [], (option) => {
        if (_.isFunction(findBy)) {
          return findBy(option)
        }
        return _.get(option, findBy) === value
      })
      const getter = options.get
      if (item && getter) {
        return _.get(item, getter)
      }
      return item
    }
  }
}
