/*global _ */
import {name, mixins, props, data, EventBus, services} from './config'
import {parseValidations} from '../../../../lib/validations'

export default {
  name,
  mixins,
  props,
  data() {
    return {
      ...data,
      setValidationRules() {
        const items = this.validations || {}
        const parsedItems = parseValidations(items)
        const fields = this.parseValidationsOnIfConditions(parsedItems)
        return {
          fields
        }
      },
    }
  },

  watch: {
    isSuccess(val) {
      this.$nextTick(() => {
        if (!_.isNil(val)) {
          this.canShowPopup = !!this.getOption('showSuccessPopup', null, true)
          this.isProcessing = false
          this.isValid = val
        }
      })
    },
    errorMessage(val) {
      this.$nextTick(() => {
        if (!_.isNil(val)) {
          this.isProcessing = false
          this.isValid = false
        }
      })
    }
  },

  computed: {
    isValidateImmediately() {
      return this.getOption('validateFormFieldOnDirty', 'field') !== false
    },
    isSingleErrorLayout() {
      return this.getOption('errorLayout', 'field') === 'all'
    },
    hasErrorMessage() {
      return !_.isEmpty(this.locals.errorMessage || this.errorMessage)
    },
    hasSuccessMessage() {
      return !_.isEmpty(this.locals.successMessage || this.successMessage)
    },
    showMessage() {
      return (this.hasErrorMessage && this.isValid === false) || (this.hasSuccessMessage && this.isValid)
    },
    messageType() {
      return (this.hasErrorMessage && this.isValid === false) ? 'danger' : 'success'
    },
    alertMessage() {
      let message = ''
      if (this.hasErrorMessage && this.isValid === false) {
        message = this.locals.errorMessage || this.errorMessage
      }
      if (this.hasSuccessMessage && this.isValid) {
        message = this.locals.successMessage || this.successMessage
      }
      return message
    },
    slotAttrs() {
      return {
        fields: this.fields,
        vRoot: this.$v,
        validations: this.validations,
        fieldCustomErrors: this.fieldCustomErrors,
        submit: this.clickSubmit,
        reset: this.clickReset,
        isDisabled: this.checkIfFieldDisabled,
        isVisible: this.checkIfFieldVisible,
      }
    },
    slotAttrsMayWithoutV() {
      const noValidation = this.isSingleErrorLayout
      return {
        ...this.slotAttrs,
        vRoot: noValidation ? {fields: {}} : this.$v,
        validations: noValidation ? {} : this.validations,
        fieldCustomErrors: noValidation ? {} : this.fieldCustomErrors,
      }
    },
  },
  async created() {
    if (this.prepareForm) {
      this.isPreparing = true
      await this.prepareForm()
      await this.$nextTick()
      this.isPreparing = false
    }
  },
  methods: {
    ...services,
    getSlotAttrs(noValidation) {
      return {
        fields: this.fields,
        vRoot: noValidation ? {fields: {}} : this.$v,
        validations: noValidation ? {} : this.validations,
        fieldCustomErrors: noValidation ? {} : this.fieldCustomErrors,
        submit: this.clickSubmit,
        reset: this.clickReset,

      }
    },
    resetProps() {
      this.$v.$reset()
      this.showingSingleErrorLayout = false
      this.formErrorMessage = null
      this.isValid = null
      this.canShowPopup = null
      this.isProcessing = false
      this.locals.errorMessage = null
      this.locals.successMessage = null
      this.hasConfirmation = false
    },
    async clickReset() {
      this.resetProps()
      this.resetForm()
      await this.$nextTick()
      this.$emit('reset', this)
      EventBus.$emit('form-reset', this)
    },
    async clickSubmit() {
      this.resetProps()
      if (this.$v && this.$v.fields) {
        this.$v.$touch()
        if (this.isAnyInvalid) {
          if (this.isSingleErrorLayout) {
            this.showingSingleErrorLayout = true
            this.scrollTo('.re-form-message')
          } else {
            this.scrollToFirstErrorElement()
          }
          return
        }
      }

      this.isProcessing = true
      this.formSubmitting = true
      if (this.isNativeSubmit()) {
        await this.submitForm()
      } else {
        await this.$nextTick()
        this.$emit('submit', this)
        EventBus.$emit('form-submit', this)
      }
      this.isProcessing = false
    },
    getComponentExtraClasses() {
      return [
        this.formClass,
        this.locals.formClass,
        {
          'showing-confirmation': this.hasConfirmation
        }
      ]
    }
  },
}
