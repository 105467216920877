import {Vue} from '~/addiesaas'

import ReLoyaltyAchievementRewards from './profile-pages/achievement-rewards'
Vue.component(ReLoyaltyAchievementRewards.name, ReLoyaltyAchievementRewards)

import ReLoyaltyTierRewards from './profile-pages/tier-rewards'
Vue.component(ReLoyaltyTierRewards.name, ReLoyaltyTierRewards)

import ReLoyaltyPointsHistory from './profile-pages/history'
Vue.component(ReLoyaltyPointsHistory.name, ReLoyaltyPointsHistory)

import ReLoyaltyReward from './rewards/main'
Vue.component(ReLoyaltyReward.name, ReLoyaltyReward)

import ReLoyaltyStatus from './status'
Vue.component(ReLoyaltyStatus.name, ReLoyaltyStatus)

import ReProfileLoyalty from './profile-pages/main'
Vue.component(ReProfileLoyalty.name, ReProfileLoyalty)

import ReLoyaltyRewardsConfirmationTier from './claim/confirm/tier'
Vue.component(ReLoyaltyRewardsConfirmationTier.name, ReLoyaltyRewardsConfirmationTier)

import ReLoyaltyRewardsConfirmationAchievement from './claim/confirm/achievements'
Vue.component(ReLoyaltyRewardsConfirmationAchievement.name, ReLoyaltyRewardsConfirmationAchievement)

import ReLoyaltyRewardClaimGeneral from './claim/general'
Vue.component(ReLoyaltyRewardClaimGeneral.name, ReLoyaltyRewardClaimGeneral)

import ReLoyaltyRewardClaimPlaycardReload from './claim/playcard-reload'
Vue.component(ReLoyaltyRewardClaimPlaycardReload.name, ReLoyaltyRewardClaimPlaycardReload)

export default {
  ReLoyaltyAchievementRewards,
  ReLoyaltyTierRewards,
  ReLoyaltyPointsHistory,
  ReLoyaltyReward,
  ReLoyaltyStatus,
  ReProfileLoyalty,
}
