export const ASSIGN_COUPON_PARSE_MAP = {
  $discount: {
    value: '{[action_options.acquired_discount]}',
    format: 'currency'
  }
}

export const ASSIGN_COUPON_MESSAGES = {
  'assign_coupon': `You saved {{$discount}} by using this coupon.`,
}

export const ASSIGN_COUPON_ERROR_MESSAGES = {}
