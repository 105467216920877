<template lang="pug">
  .b-coupon(v-if="coupon" :class="{ 'b-coupon--intransparent': !transparent }")
    .text-center.m-b-20
      template(v-if="redeemed")
        .b-coupon__state.b-coupon__state--success(v-if="!alreadyUsed") Redeemed
          img.b-coupon__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-ok-icon.svg`")
        .b-coupon__state.b-coupon__state--danger(v-if="alreadyUsed") Cannot be Redeemed
          img.b-coupon__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-danger-icon.svg`")
        .b-coupon__state-subtitle(v-if="alreadyUsed") Coupon has already been used.
      template(v-else)
        .b-coupon__state.b-coupon__state--warning Not Claimed
          img.b-coupon__state-icon(:src="`${config.baseUri}/images/client/voucher-mobile-scanner/voucher-state-dollar-icon.svg`")
        .b-coupon__state-subtitle.b-coupon__state-subtitle--warning {{ coupon.name }}
      .b-coupon__title.m-t-10.m-b-10 Coupon {{ coupon.code }}
      .b-coupon__flex-row
        span Type
        span {{ coupon.type }}
      .b-coupon__flex-row
        span Discount
        span(v-if="coupon.type == 'fixed'") {{ coupon.discount | currency }}
        span(v-if="coupon.type == 'percentage'") {{ Number(coupon.discount) }}%
        span(v-if="coupon.type != 'fixed' && coupon.type != 'percentage'") {{ coupon.discount }}
    .b-coupon__divider.m-b-10
    v-button.btn.btn-info.btn-almost-sm.btn-block.m-t-20(v-if="!redeemed", @click="redeem", :loading="redeeming") Redeem

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import baseUrl from '../mixins/base-url'

export default {
  name: 'coupon-state',

  props: {
    coupon: {
      type: Object,
    },
    transparent: {
      type: Boolean,
      default: true,
    }
  },

  mixins: [
    baseUrl,
  ],

  data: () => ({
    config: window.addiesaas.config,
    alreadyUsed: false,
    redeemed: false,
    redeeming: false,
    items: {},
  }),

  computed: {
    ...mapGetters({
      settings: 'VoucherMobileScanner/settings',
    }),
  },

  watch: {
    async coupon() {
      await this.couponChanged()
    },
  },

  async created() {
    await this.couponChanged()
  },

  methods: {
    async couponChanged() {
      if (this.coupon) {
        this.alreadyUsed = this.coupon.is_redeemed
        this.redeemed = this.coupon.is_redeemed

        if (this.settings.autoRedeem && !this.alreadyUsed) {
          await this.redeem()
        }

        this.$emit('mounted')
      }
    },
  
    async redeem() {
      try {
        this.redeeming = true

        await axios.post(`${this.baseUrl}/api/admin/coupon/${this.coupon.id}/code/${this.coupon.coupon_code_id}/redeem`)

        this.redeemed = true
        this.redeeming = false
      } catch (e) {
        console.error(e)
        if (e.response && e.response.status == 401) {
          await window.addiesaas.store.dispatch('logout')
          this.$router.push({ name: 'vouchermobilescanner-sign-in' })
        }
      } finally {
        this.redeeming = false
      }
    },
  },
}
</script>

<style lang="scss">
.b-coupon {
  position: absolute;
  z-index: 1;
  top: 108px;
  bottom: 108px;
  left: 30px;
  right: 30px;
  background-color: rgba(55,52,81,0.7);
  border-radius: 3px;
  padding: 15px 23px;
  &--intransparent {
    background-color: rgb(55, 52, 81);
  }
  &__state {
    display: inline-block;
    border-radius: 15px;
    line-height: 20px;
    padding: 5px 10px;
    &--success {
      color: #25C980;
      background-color: rgba(81,208,151,0.14);
    }
    &--warning {
      color: #EDBB26;
      background-color: rgba(247,212,108,0.2);
    }
    &--danger {
      color: #EF3278;
      background-color: rgba(239,50,120,0.1);
    }
    &-icon {
      float: left;
    }
    &-subtitle {
      color: #EF3278;
      font-size: 11px;
      margin-top: 2px;
      &--warning {
        color: #EDBB26;
      }
    }
  }
  &__title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  &__items {
    max-height: calc(100vh - 387px);
    overflow-y: scroll;
    padding-right: 16px;
    margin-right: -16px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: #8E8EEE;
      border-radius: 2px;
    }
  }
  &__item {
    &-redeemed,
    &-redeeming {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    &-redeemed {
      color: #878DA9;
    }
    &-redeeming {
      font-weight: 600;
      font-size: 16px;
      &-minus,
      &-count,
      &-plus {
        display: inline-block;
      }
      &-minus,
      &-plus {
        font-size: 24px;
        font-weight: 700;
        &[disabled] {
          color: gray;
        }
      }
      &-count {
        width: 25px;
        border-radius: 2px;
        color: #75ACF1;
        background-color: rgba(135, 187, 253, 0.5);
        margin-left: 6px;
        margin-right: 6px;
        text-align: center;
      }
    }
  }
  &__divider {
    border-top: 1px solid white;
  }
  &__flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
