/*global _ */
import {toBoolean, fv} from '../../../../lib/formatters'
import {parseFieldsValidations, setImportValidations, required as requiredRuleFn} from './form'
import ifsParser from './ifs-parser'

export default {
  hasOption(name, isAdvancedOptions = false) {
    if (isAdvancedOptions) {
      const propName = this.optionsPropName
      name = name ? `${propName}.${name}` : propName
    }
    return _.has(this.loadedFormOptions, name) || _.has(this.locals, name) || _.has(this, name)
  },
  getOption(name, defaultValue, isAdvancedOptions) {
    if (isAdvancedOptions) {
      const propName = this.optionsPropName
      name = name ? `${propName}.${name}` : propName
    }
    return fv(
      _.get(this.loadedFormOptions, name),
      _.get(this.locals, name),
      _.get(this, name),
      defaultValue
    )
  },
  setOption(name, value, isAdvancedOptions) {
    if (isAdvancedOptions) {
      const propName = this.optionsPropName
      name = name ? `${propName}.${name}` : propName
    }
    _.set(this.loadedFormOptions, name, value)
    return value
  },

  isNativeSubmit() {
    return this.getOption('submitAction', null, true) === 'native'
  },

  async loadForm(id) {
    const propName = this.optionsPropName
    this.loadedFormOptions = {
      [propName]: this[propName] || {}
    }
    id = id || this.formId
    if (id) {
      try {
        const [{data} = {}] = await this.promise('forms.read', {id})
        this.loadedFormOptions = data
        return data
      } catch (e) {
        //
      }
    }
  },
  async submitForm() {
    try {
      const fields = _.cloneDeep(this.fields)
      fields.id = this.formId
      const [data] = await this.promise('forms.submit', fields)
      this.resetForm()
      this.handleSubmission(_.get(data, 'data'))
      return data
    } catch (e) {
      this.showFormError(e, {flattenTopMessages: false})
    }
  },

  getConfirmationMessage() {
    if (this.hasConfirmation) {
      return this.successMessage || this.locals.successMessage
    }
    return ''
  },

  successSendToPage(to, response) {
    const {confirmation} = response
    this.canShowPopup = !!this.getOption('showSuccessPopup', null)
    this.hasConfirmation = true
    this.$set(this.locals, 'successMessage', confirmation)
  },
  successSendToNextUrl(to, response) {
    const {data} = response
    const url = to + '?data=' + encodeURIComponent(JSON.stringify(data))
    window.location.assign(url)
  },
  successSendToNextView(to, response) {
    const switcher = _.get(addiesaas, 'pageService.actions')
    if (switcher && switcher.invokeAction) {
      const params = {
        action: 'switch-view',
        viewId: to,
        ...response
      }
      switcher.invokeAction(params)
    }
  },
  successSendToNextStep(to, response) {
    const switcher = _.get(addiesaas, 'pageService.actions')
    if (switcher && switcher.invokeAction) {
      const params = {
        action: 'render-content',
        contentId: to,
        ...response
      }
      switcher.invokeAction(params)
    }
  },

  handleSubmission(response) {
    const sendTo = this.getOption('sendTo', null, true)
    const sendToType = this.getOption('sendToType', 'page', true)
    const method = _.camelCase(`success-send-to-${sendToType}`)
    this.isValid = true
    if (this[method]) {
      this[method](sendTo, response)
    }
  },

  async prepareForm(customValues) {
    if (!customValues) {
      await this.loadForm()
    }
    const values = customValues || this.getOption(null, {}, true)
    if (_.isObject(values)) {
      const form = values.form || {}
      const fields = form.fields || {}
      this.fields = _.mapValues(fields, f => f.value || '')
    }
    const validations = this.prepareFormValidations(customValues)
    return validations
  },

  prepareFormValidations(customValues) {
    let vItems = {}
    const values = customValues || this.getOption(null, {}, true)
    if (_.isObject(values)) {
      const fields = _.get(values, 'form.fields', {})
      setImportValidations(values, fields)
      vItems = parseFieldsValidations(fields, false, values)
    }
    this.validations = vItems
    return vItems
  },

  parseValidationsOnIfConditions(fields) {
    return _.mapValues(fields, (rulesDef, field) => {
      const disabled = this.checkIfFieldDisabled(field)
      const notVisible = !this.checkIfFieldVisible(field)
      if (disabled || notVisible) {
        return {}
      }

      const notRequired = !this.checkIfFieldRequired(field, rulesDef)
      if (notRequired) {
        _.unset(rulesDef, 'required')
      } else {
        rulesDef.required = rulesDef.required || requiredRuleFn
        const vPath = `${field}.items.required`
        if (!_.get(this.validations, field)) {
          _.set(this.validations, field, {items: {}, name: `fields.${field}`})
        }
        const definedItem = _.get(this.validations, vPath)
        if (!definedItem) {
          const fieldDef = this.getFieldAdvancedSettings(field)
          const vDef = parseFieldsValidations({[field]: fieldDef}, true)
          const requiredDef = _.get(vDef, vPath)
          _.set(this.validations, vPath, requiredDef)
        }
      }
      return rulesDef
    })
  },
  getFieldAdvancedSettings(fieldName, path, defaultValue) {
    const fullPath = `form.fields.${fieldName}` + (path ? `.${path}` : '')
    return this.getOption(fullPath, defaultValue, true)
  },
  checkIfFieldRequired(fieldName, rulesDef) {
    const requiredRule = rulesDef.required
    const required = !!requiredRule ||
      toBoolean(this.getFieldAdvancedSettings(fieldName, 'required', false))
    const def = this.getFieldAdvancedSettings(fieldName, 'advancedSettings.requiredIf', [])

    const isRequired = ifsParser.call(this, def, this.fields || {}, required)
    
    return isRequired
  },
  checkIfFieldVisible(fieldName) {
    const def = this.getFieldAdvancedSettings(fieldName, 'advancedSettings.visibleIf', [])
    return ifsParser.call(this, def, this.fields || {}, true)
  },
  checkIfFieldDisabled(fieldName) {
    const def = this.getFieldAdvancedSettings(fieldName, 'advancedSettings.enableIf', [])
    return !ifsParser.call(this, def, this.fields || {}, true)
  },
}
