import {
  zipRules,
  setValidationRule,
  Validators,
} from '../../../../../lib/validations'

const CURRENT_YEAR = (new Date()).getFullYear()

export const defaultFormFields = {
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    zip: '',
    country: 'US',
    state: '',
    card: '',
    cvv: '',
    expMonth: '',
    expYear: '',
    isRequiredCity: false,
    isRequiredAddress: false,
    isRequiredZip: false,
    isRequiredCountry: false,
    isRequiredState: false,
    billingEnabled: true,
  }
export const allValidationsRules = {
  first_name: setValidationRule('fields.first_name', {
    required: {error: 'First Name is required.', rule: Validators.requiredIf('billingEnabled')}
  }),
  last_name: setValidationRule('fields.last_name', {
    required: {error: 'Last Name is required.', rule: Validators.requiredIf('billingEnabled')}
  }),

  city: setValidationRule('fields.city', {
    required: {error: 'City is required.', rule: Validators.requiredIf('isRequiredCity')}
  }),
  state: setValidationRule('fields.state', {
    required: {error: 'State is required.', rule: Validators.requiredIf('isRequiredState')}
  }),
  zip: setValidationRule('fields.zip', zipRules),
  country: setValidationRule('fields.country', {
    required: {error: 'County is required.', rule: Validators.requiredIf('isRequiredCountry')}
  }),
  address: setValidationRule('fields.address', {
    required: {error: 'Address is required.', rule: Validators.requiredIf('isRequiredAddress')}
  }),

  card: setValidationRule('fields.card', {
      required: {error: 'Credit Card Number is required.', rule: Validators.requiredIf('billingEnabled')},
      minLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.minLength(12)},
      maxLength: {error: 'Credit Card Number must be of 10-16 digits.', rule: Validators.maxLength(20)},
    }
  ),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Security code is required.', rule: Validators.requiredIf('billingEnabled')},
    numeric: {error: 'Security code must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.minLength(3)},
    maxLength: {error: 'Security code must be of 3-4 digits.', rule: Validators.maxLength(4)},
  }),
  expMonth: setValidationRule('fields.expMonth', {
    required: {error: 'Expiration Month is required.', rule: Validators.requiredIf('billingEnabled')},
    numeric: {error: 'Expiration Month must be numeric.', rule: Validators.numeric},
    minLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.minLength(1)},
    maxLength: {error: 'Expiration Month must be of 1-12.', rule: Validators.maxLength(12)},
  }),
  expYear: setValidationRule('fields.expYear', {
    required: {error: 'Expiration Year is required.', rule: Validators.requiredIf('billingEnabled')},
    numeric: {error: 'Expiration Year must be numeric.', rule: Validators.numeric},
    minValue: {error: `Expiration Year must be greater than or equal to ${CURRENT_YEAR}.`, rule: Validators.minValue(Number(String(CURRENT_YEAR).substring(2)))} ,
  }),
}

export default {defaultFormFields, allValidationsRules}
