<template lang="pug">
button.v-button(:type="nativeType", :disabled="disabled || loading", @click="click", :class="{ 'btn': !noDefaultClass, 'btn-block': block, 'btn-lg': large, 'btn-sm': small, 'btn-loading': loading }")
  .spinner-container
    transition(name="button-fade" mode="out-in")
      .spinner(v-show="loading")
    slot
</template>

<script>
export default {
  name: 'v-button',

  props: {
    nativeType: {
      type: String,
      default: 'submit'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    noDefaultClass: {
      type: Boolean,
      default: false
    },

    block: Boolean,
    large: Boolean,
    small: Boolean,
  },

  methods: {
    click (e) {
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="scss">
.v-button {
  .button-fade-enter-active,
  .button-fade-leave-active {
    transition: opacity 1s;
  }
  .button-fade-enter,
  .button-fade-leave-active {
    opacity: 0;
    will-change: opacity;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(359deg)
    }
  }
  .spinner-container {
    position: relative;
    .spinner {
      display: inline-block;
      position: absolute;
      height: 40px;
      width: 40px;
      top: 50%;
      right: 50%;
      margin-top: -20px;
      margin-right: -20px;
      opacity: 1;
      filter: alpha(opacity=100);
      animation: rotation .7s infinite linear;
      border: 8px solid rgba(0, 0, 0, 0);
      border-top-color: white;
      border-radius: 100%;
      transition: .3s all ease;
    }
  }
}
</style>
