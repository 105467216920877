/*global _ */
import {empty, notEmpty, map, templiParse, removeBy} from '../../lib/formatters'
import BuilderReactiveMixin, {reactiveTypes} from './reactives'

export default {
  mixins: [BuilderReactiveMixin],
  data() {
    return {
      builderValues: {},
      injectOptions: {},
      formatterDataSource: {}
    }
  },
  computed: {
    pageBuilderData() {
      return this.parseBuilderOptions()
    },
    pageBuilderAttr() {
      let data = this.pageBuilderData
      return {data}
    }
  },

  async created() {
    this.injectOptions = this.getInjectOptions()
    this.formatterDataSource = {this: this, ...this.injectOptions}
  },

  mounted() {

  },

  methods: {
    getParseBuilderOptions() {
      return _.merge(
        {},
        this.$$clone(this.$$t(`content`, {})),
        this.$$clone(this.$$s(`content`, {}))
      )
    },
    parseBuilderOptions() {
      const settings = this.getParseBuilderOptions()
      const injects = {"$inject": this.injectOptions}
      let data = this.parseBuilderObject(this.parseBuilderDataDefinitions(settings))
      data = this.cleanupBuilderOptions(data)
      data = _.merge({}, data, injects)

      const options = {data} //parent data
      this.parseBuilderConfig(data, options)
      this.parseBuilderForms(data, options)
      this.parseBuilderValidations(data, options)
      this.parseBuilderPages(data, options)

      this.parseBuilderGroups(data, options)
      this.parseBuilderSections(data, options)
      this.parseBuilderTabs(data, options)
      this.parseBuilderLists(data, options)

      this.parseBuilderExtraOptions(data, options)
      return data
    },

    parseBuilderDataDefinitions(obj, key, parent, origin) {
      if (_.isObject(obj) || _.isArray(obj)) {
        if (obj['[data]']) {
          obj = this.generateBuilderData(obj)
          return obj
        }
        obj = map(obj, (i, k) => this.parseBuilderDataDefinitions(i, k, obj, origin || obj))
      }
      return obj
    },

    parseBuilderObject(obj, key, parent, origin) {
      const parentNamespace = (parent || {}).namespace
      if (_.isObject(obj) || _.isArray(obj)) {
        if (key === '[removeif]' || key === '[keepif]') {
          obj = this.probeReactiveIf(obj, this.formatterDataSource)
          return obj
        }

        if (obj['[switch]']) {
          obj = this.evaluateReactiveSwitchedState(obj['[switch]'], this.formatterDataSource)
          return obj
        }

        if (key && parent) {
          if (_.indexOf(['pages', 'sections', 'tabs', 'groups'], key) >= 0) {
            obj = map(obj, (o, k) => {
              if (_.isObject(o)) {
                o.key = k
                o.namespace = _.trim((parentNamespace || '') + `.${key}.${k}`, '.')
              }
              this.mergeBuilderGlobalConfig(o, key, parent, origin)
              return o
            })
          }
          if (_.indexOf(['form'], key) >= 0) {
            obj.namespace = _.trim((parentNamespace || '') + `.${key}`, '.')
            obj.key = _.trim((parent.key || '') + `-${key}`, '-')
          }
        }
        obj = map(obj, (i, k) => this.parseBuilderObject(i, k, obj, origin || obj))
      }
      return obj
    },

    cleanupBuilderOptions(obj, key, parent, origin) {
      if (_.isObject(obj)) {
        obj = removeBy(obj, (item, key) => {
          const canRemove = _.isObject(item) && (item['[removeif]'] === true || item['[keepif]'] === false)
          return canRemove
        })
        obj = map(obj, o => this.cleanupBuilderOptions(o))
      }
      return obj
    },

    generateBuilderData(obj) {

      const data = obj['[data]']
      if (_.isString(data)) {
        if (/{{[^}]+?}}/.test(data) || /^{\[[^\]]+?\]}$/.test(data)) {
          return templiParse(data, this.formatterDataSource)
        } else {
          return _.get(this, data,  _.get(this.formatterDataSource, data, {}))
        }
      }
      let value = null
      if (_.isObject(data)) {
        const methodDefs = this.probeReactiveFunctions(data, this.formatterDataSource)
        for (const method of methodDefs) {
          value = method()
        }
      }
      return value
    },
    getInjectOptions(source) {
      const defs = _.get(source, '$inject', this.$$s('$inject',{}))
      const injects = _.mapValues(defs, path => {
        return _.get(this, path, null)
      })
      return injects
    },

    mergeBuilderGlobalConfig(item, key, parent, global) {
      const globalKeys = {
        pages: 'page',
        sections: 'section',
        groups: 'group',
        tabs: 'tab'
      }
      const globalKey = globalKeys[key]
      if (globalKey) {
        const globalSettings = global[globalKey] || {}
        if (notEmpty(globalSettings)) {
          _.defaultsDeep(item, globalSettings)
        }
      }
      return item
    },
    parseBuilderConfig(data, options) {

    },
    parseBuilderForms(data, options) {
      const form = data.form || (data.form = {})
      const fields = form.fields || (form.fields = {})
      _.forOwn(reactiveTypes, (reactiveType, reactiveTypeKey) => {
        _.forOwn(reactiveType, (type, typeKey) => {
          const search = `reactive.${typeKey}On${reactiveTypeKey}`
          _.forOwn(fields, (field, fieldKey) => {
            const item = _.get(field, search)
            if (notEmpty(item)) {
              _.forOwn(item, i => {
                const formSearch = `${i}.reactive.${reactiveTypeKey}.${typeKey}`
                const value = _.get(fields, formSearch, [])
                if (!_.includes(value, fieldKey)) {
                  value.push(fieldKey)
                  _.set(fields, formSearch, _.uniq(value))
                }
              })
            }
          })
        })
      })
    },
    parseBuilderValidations(data, options) {
      const form = data.form || (data.form = {})
      const validations = form.validations || (form.validations = {})
      const fieldsValidations = validations.fields || (validations.fields = {})

      const fields = _.get(form, 'fields', {})
      _.forOwn(fields, (f, k) => {
        if (f.required) {
          const path = `${k}.$items.required`
          const hasRequired = _.get(fieldsValidations, path)
          if (!hasRequired) {
            const fieldName = _.startCase((f.title || f.name || k).replace(/^[\s\S]*\./, ''))
            _.set(fieldsValidations, path, {"error": `${fieldName} is required.`})
          }
        }
      })
    },
    parseBuilderPages(data, options) {

    },
    parseBuilderGroups(data, options) {

    },
    parseBuilderSections(data, options) {

    },
    parseBuilderTabs(data, options) {

    },
    parseBuilderLists(data, options) {

    },

    parseBuilderExtraOptions(data) {
      return data
    },

  },
}
