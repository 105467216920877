/*global _ */
import {
  getCardCreditCashRatio,
  getCardBonusTable,
  applyStandardBonusOnProduct
} from '../../../lib/shop/cards/standard-bonus'

export default {
  computed: {
    cardCreditCashRatio() {
      const location = _.get(this, 'fields.location_id')
      return getCardCreditCashRatio(location)
    },
    cardBonusTable() {
      const location = _.get(this, 'fields.location_id')
      return getCardBonusTable(location)
    }
  },
  methods: {
    applyStandardBonusOnProduct(amount, product, calculatePoints) {
      const location = _.get(this, 'fields.location_id')
      const modifiedProduct = applyStandardBonusOnProduct(product, location, {amount, calculatePoints})
      if (modifiedProduct) {
        _.merge(product, modifiedProduct)
      }
    }
  }
}
