<template lang="pug">
  .b-central
    .b-central__content.b-central__content--center
      .b-central__title Select Account

      .b-central__panel.b-central__panel--w260.b-central__panel--center.m-t-50
        ul.b-btn-list(v-if="tenants")
          li.ul.b-btn-list__item(v-for="tenant in tenants" @click="select(tenant)") {{ tenant.name }}
            img.b-btn-list__arrow-right(:src="`${config.baseUri}/images/client/saas-onboarding/list-arrow-right.svg`" width="20" height="20" alt="")

</template>

<script>
import axios from 'axios'

export default {

  data: () => ({
    config: window.addiesaas.config,
    token: null,
    tenants: null,
  }),

  async created() {
    const { data: { data } } = await axios.get(`${this.config.baseUri}/api/onboarding/auth/user?include=tenants`)

    this.$store.dispatch('updateUser', { data })

    this.token = data.token
    this.tenants = data.tenants.data
  },

  methods: {
    async select(tenant) {
      const { data } = await axios.post(`${tenant.url}/api/tenant/sso`, {
        token: this.token,
      })

      this.$store.dispatch('MultiTenancy/saveTenant', { tenant })
      this.$store.dispatch('saveToken', { token: data.token })

      this.$router.push({ name: 'vouchermobilescanner-scanner' })
    },
  },
}
</script>
