/*global _ */
const EventBus = window.VueEventBus

import CartMixin from '../../../../mixins/cart'
import CartLocationMixin from '../../../../mixins/cart/location'
import SettingsMixin from '../../../../mixins/settings'
import TranslationsMixin from '../../../../mixins/lang'
import ResponsiveMixin from '../../../../mixins/responsive'
import AlertMixin from '../../../../mixins/alert'

export default {
  name: 're-mini-cart',
  settings: 'page.cart',
  translations: 'page.cart',
  mixins: [SettingsMixin, TranslationsMixin, CartMixin, CartLocationMixin, AlertMixin, ResponsiveMixin],
  props: {
    containerClass: {
      type: [String, Array, Object],
      default: ''
    },
    countClass: {
      type: [String, Array, Object],
      default: 're-cart-count'
    },
    iconClass: {
      type: [String, Array, Object],
      default: 're-cart-icon'
    },
    cartLink: {
      type: [String, Object],
      default: () => ({ name: 'cart' })
    },
    cartLinkLabel: {
      default: ''
    },
    multiLocationItems: {
      default() {
        /**
         * {
         *    disabled: false, // false: allow multi-location cart items
         *    location: {probe: []}}, // array of path e.g., location_id
         *            (to be searched in item.payload.options, item.payload, item.options and item)
         *    add: {error: {message, title}}
         *    update: {error: {message, title}}
         *  }
         *
         */
        const $t = this.$options.$$t('multiLocationItems', {})
        const $s = this.$options.$$s('multiLocationItems', {})
        return _.merge({}, $t, $s)
      }
    }
  },
  computed: {
    visibleCartItemsCount() {
      return (_.reject(this.currentCartItems || {}, (i) => i.attributes.skipCartCount)).length
    }
  },
  created() {
    EventBus.$on('cart.item.before.add', this.mayPreventAddToCart)
    EventBus.$on('cart.item.before.update', this.mayPreventAddToCart)
    EventBus.$on('promise.shop.order.init', this.mayAddLocationToOrder)
  }
}
