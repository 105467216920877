import config from '~/vuestrap/config'
const { Vue, EventBus } = config

import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'


const services = 'onboarding' in window.addiesaas ? window.addiesaas.onboarding.services : null
const redirectUri = `${window.addiesaas.config.baseUri}/onboarding`

Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {
  providers: {
    facebook: {
      clientId: services ? services.facebook.client_id : null,
      redirectUri,
    },
    google: {
      clientId: services ? services.google.client_id : null,
      redirectUri,
    },
  }
})
