/* global _ */
import {name, mixins, props, data, Subscription} from './config'

export default {
  name,
  props,
  mixins,
  data() {
    return {
      ...data,
      eventSpace: 'user.profile.subscriptions'
    }
  },
  computed: {
    maxDateTime() {
      return this.getNow().toISODate()
    },
    activeOrdersCount() {
      return _.size(this.activeOrders)
    },
    ordersCount() {
      return _.size(this.allOrders || {})
    },
    activeOrders() {
      return _.filter(this.allOrders, o => o.status === 'live')
    },
    allOrders() {
      return this.orders || []
    },
    subscriptionHandlers() {
      return {
        'subscription:cancelled': this.subscriptionCancelled,
        'subscription:card.updated': this.subscriptionPaymentMethodUpdated,
      }
    },
    buyComponentAttrs() {
      return {
        settings: this.$$clone(this.$$s(`buy`, {})),
        translations: this.$$clone(this.$$t(`buy`, {})),
      }
    }
  },

  async created() {
    await this.fetch()
  },

  methods: {
    async fetch(autoloadAll) {
      this.emit('fetch.init')
        try {
          this.isBeingProcessed = true
          if (autoloadAll) {
            await this.autoLoadAll()
          }
          await this.initialAutoLoadCompleted()
          const [data] = await this.promise('user.subscriptions.get')
          const orders = this.orders = data.data || {}
          this.$emit('success', orders)
          this.emit('fetch.success', orders)
        } catch (e) {
          this.$emit('error', e)
          this.emit('fetch.error', e)
        } finally {
          this.emit('fetch.end')
          this.isBeingProcessed = false
        }
    },
    async subscriptionCancelled() {
      await this.fetch()
    },
    async subscriptionPaymentMethodUpdated() {
      await this.fetch()
    },
    getPlanAttr(order) {
      const subscription = new Subscription({...order, user: this.user}, this)
      const plan = _.get(order, 'plan.data', {})
      const item = _.get(plan, 'product.data')

      return {
        plan,
        item,
        subscription,
        mode: 'profile'
      }
    }

  }
}
