/*global _, addiesaas, $, debugLog */
import {DESIGNER_EVENTS as E} from '../../../lib/constants'
import {getBuilderComponentEmitter} from '../../../lib/utils'

const {
  PREPARE,
  PREPARE_PREFIX,
  PREPARE_LOCAL,
  PREPARE_LOCAL_PREFIX
} = E

export default function (...args) {
  // just after vue component is rendered
  //{editor, component, data: {properties, props, defaults, attributes, styles, toolbars}}
  // Here `props` means settings/traits
  const bus = this
  const vm = this
  const targetVM = vm.pageWrapperComponent ? (vm.$children[0] || vm) : vm

  const arg = args[0] || (args[0] = {})
  arg.vm = vm
  const data = arg.data || (arg.data = {})
  const items = ['properties', 'attributes', 'styles', 'toolbars', 'defaults', 'props']

  //{editor, vm, component}   // At this stage component is a JSON
  const globalEmit = getBuilderComponentEmitter(targetVM)
  const emit = (name, ...params) => {
    targetVM.$emit(name, ...params)
    globalEmit(name, ...params)
  }

  // init data with items

  _.forOwn(items, item => {
    data[item] || (data[item] = {})
  })

  globalPrepare.call(vm, ...args)

  // before prepare
  emit(PREPARE + ':before', ...args)
  if (targetVM[PREPARE]) {
    targetVM[PREPARE](...args)
  }
  // prepare
  emit(PREPARE, ...args)

  if (targetVM[PREPARE_LOCAL]) {
    targetVM[PREPARE_LOCAL](...args)
  }
  emit(PREPARE_LOCAL + ':before', ...args)

  _.forOwn(items, item => {
    const def = data[item] || (data[item] = {})
    const globalMethod = _.camelCase(PREPARE_PREFIX + '_' + item)
    if (targetVM[globalMethod]) {
      targetVM[globalMethod]({...arg, [item]: def})
    }
    emit(globalMethod, {...arg, [item]: def})

    const localMethod = _.camelCase(PREPARE_LOCAL_PREFIX + '_' + item)
    if (targetVM[localMethod]) {
      targetVM[localMethod]({...arg, [item]: def})
    }
    emit(localMethod, {...arg, [item]: def})
  })

  emit(PREPARE_LOCAL, ...args)

  const localAfterMethod = _.camelCase(PREPARE_LOCAL_PREFIX + '_after')

  if (targetVM[localAfterMethod]) {
    targetVM[localAfterMethod](...args)
  }
  emit(PREPARE_LOCAL + ':after', ...args)

  // after prepare
  emit(PREPARE + ':after', ...args)
}

const globalPrepare = function ({data}) {
  const properties = data.properties || (data.properties = {})
  properties.vueOptionsName = this.optionsPropName || 'advancedSettings'
  const vueOptionsDefName = properties.vueOptionsDefName = this.optionsDefName || 'optionsDef'
  properties.vueOptionsDef = this[vueOptionsDefName] || {}
}
