export default {
  data() {
    return {

    }
  },
  mixins: [],

  computed: {

  },
  created() {
  },
  methods: {
    async gotoBuilderPage(page, noScroll) {
      const pageIndex = _.isFinite(page) ? page : _.findIndex(this.pages, p => p.key === page)
      if (pageIndex >= 0) {
        this.pageIndex = pageIndex
        if (!noScroll) {
          await this.$nextTick()
          this.lazyScrollTo('.re-page')
        }
      }
    },
    async gotoBuilderPreviousPage() {
      --this.pageIndex
      await this.$nextTick()
      this.lazyScrollTo('.re-page')
    },
    async gotoBuilderNextPage() {
      try {
        const pageEvents = _.get(this.page, 'reactive.events', {})
        const beforeEvent = _.get(pageEvents, 'beforeNextPage')
        if (beforeEvent) {
          const methods = this.probeReactiveFunctions(beforeEvent, this.getBuilderData, {async: true})
          for(const method of methods) {
            await method()
          }
        }
        this.pageIndex++
        await this.$nextTick()
        this.lazyScrollTo('.re-page')
      } catch (e) {
        await this.alert(e.message)
      }
    },
    async finalize() {
      try {
        const pageEvents = _.get(this.page, 'reactive.events', {})
        const beforeEvent = _.get(pageEvents, 'beforeFinalize')
        if (beforeEvent) {
          const methods = this.probeReactiveFunctions(beforeEvent, this.getBuilderData, {async: true})
          for(const method of methods) {
            await method()
          }
        }
      } catch (e) {
        await this.alert(e.message)
      }
    },
    builderNav(where, options = {}, type = 'page') {
      if (type === 'page') {
        this.gotoBuilderPage(where, true)
      }
      let scrollTo = options.scrollTo || '.re-page'
      if (options.scrollToNamespace) {
        scrollTo = `[data-namespace="${options.scrollToNamespace}"]`
      }
      if (scrollTo) {
        this.lazyScrollTo(scrollTo)
      }
    }

  }
}
