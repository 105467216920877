export const props = {
  formNotice: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('formNotice', 'You have earned {{rewardClaimTitle}}.')
    }
  },
  successMessage: {
    type: String,
    default() {
      return this.$options.$$t(
        'successMessage',
        `You have earned {{rewardClaimTitle}} {{br}}
          To claim your Gift, print Voucher and bring it to a location. Or use QR code.
           {{br}}{{expirationMessage}}`
      )
    }
  },
}

export default props
