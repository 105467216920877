/*global _ */
import {Vue} from '~/addiesaas'

export default {
  data() {
    return {
      canShowComponent: true,
    }
  },
  methods: {
    parseAttrClasses(attrs, append) {
      attrs = attrs || {}
      let classes = attrs.class || ''
      if (_.isString(classes)) {
        classes = classes.split(' ')
      }
      classes = _.castArray(classes)
      if (append) {
        classes.push(append)
      }
      return classes
    },
    probeComponent(componentName, defaultComponent) {
      if (componentName in Vue.options.components || componentName in this.$options.components) {
        return componentName
      }
      return defaultComponent
    },
    getComponentClasses() {
      return [this.classes, ..._.castArray(this.getComponentExtraClasses())]
    },
    getComponentExtraClasses() {
      return []
    }
  }
}

