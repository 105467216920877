export const initVM = function (vm) {
  vm = vm || {}
  // @deprecated use: $$vm instead
  this.vm = () => vm
  this.$$vm = () => vm
  this.$$t = vm.$$t
  this.$$s = vm.$$s
  this.$$d = vm.$$d
  this.$$n = vm.$$n
  this.$$tc = vm.$$tc

  this.$$fv = vm.$$fv
  this.$$fs = vm.$$fs
  this.$$fn = vm.$$fn
}
export default {
  initVM
}
