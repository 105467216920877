import listeners from './methods/listeners'
import StoreMixin from './store'
const mixins = [StoreMixin]

export default {
  mixins,
  props: {
    designerMode: {}
  },
  data() {
    return {
      designerObject: {},
    }
  },
  created() {
    if (this.designerMode) {
      this.enableDesignerMode()
      listeners.call(this)
    }
  }
}
