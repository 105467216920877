var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('re-validation',_vm._b({staticClass:"re-input",scopedSlots:_vm._u([{key:"input",fn:function(ref){
var hasError = ref.hasError;
return [_c('div',{staticClass:"label-container"},[(_vm.label)?_c('label',{class:_vm.getLabelClasses(),attrs:{"for":_vm.id || _vm.name}},[_vm._v(_vm._s(_vm.label))]):_vm._e()]),_c('div',{staticClass:"field-container"},[(_vm.hasTab)?[_c('re-tab-container',{staticClass:"timeslot-selector-groups",scopedSlots:_vm._u([{key:"after-titles",fn:function(){return undefined},proxy:true},{key:"content-after",fn:function(){return undefined},proxy:true}],null,true)},_vm._l((_vm.timeSlotTabs),function(ref,tabKey){
var slots = ref.slots;
var title = ref.title;
var hideTitle = ref.hideTitle; if ( hideTitle === void 0 ) hideTitle = false;
var active = ref.active; if ( active === void 0 ) active = false;
return _c('re-tab-item',{key:tabKey,attrs:{"title":title,"active":active}},[_c('div',{staticClass:"re-timeslots"},_vm._l((slots),function(timeslot){return _c('re-button',{key:("timeslot-" + (timeslot.value)),staticClass:"btn-timeslot",class:{active: timeslot.value === _vm.innerValue},on:{"click":function($event){$event.preventDefault();return _vm.selectTimeSlot(timeslot)}}},[_c('span',[_vm._v(_vm._s(timeslot.label))])])}),1)])}),1)]:[_c('div',{staticClass:"re-timeslots"},_vm._l((_vm.timeSlots),function(timeslot){return _c('re-button',{key:("timeslot-" + (timeslot.value)),staticClass:"btn-timeslot",class:{active: timeslot.value === _vm.innerValue},on:{"click":function($event){$event.preventDefault();return _vm.selectTimeSlot(timeslot)}}},[_c('span',[_vm._v(_vm._s(timeslot.label))])])}),1)]],2)]}},{key:"hint",fn:function(){return [(_vm.hint)?_c('small',{staticClass:"form-text help-block"},[_vm._v(_vm._s(_vm.hint))]):_vm._e()]},proxy:true}])},'re-validation',_vm.validationAttrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }