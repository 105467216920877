import pageProps from '../../../../../../props/profile/page-props'
import contextProps from '../../../../../../props/context'

export const props = {
  ...pageProps,
  ...contextProps,
  value: {},
  rewardsTitle: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('rewardsTitle', null)
    }
  },
  showRewardsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showRewardsTitle', false)
    }
  },
  unlockedRewardsMessage: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedRewardsMessage', 'You\'ve unlocked rewards. Claim your rewards')
    }
  },
  unlockedRemainingRewardsMessageSuffix: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedRemainingRewardsMessageSuffix', ' or continue playing to unlock more rewards.')
    }
  },
  unlockedNoRemainingRewardsMessageSuffix: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('unlockedNoRemainingRewardsMessageSuffix', '.')
    }
  },
  historyButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.history.title', 'Points History')
    }
  },
  historyButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.history.classes', '')
    }
  },
  showHistoryButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.history.show', false)
    }
  },
  locationButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('buttons.location.title', 'Select Location')
    }
  },
  locationButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.location.classes', '')
    }
  },
  locationButtonAttr: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('buttons.location.attrs', {})
    }
  },
  showLocationSelector: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('buttons.location.show', false)
    }
  },
}

export default props
