/*global _ */
import {name, mixins, props, data, EventBus, getFirstCombination} from './config'
export default {
  name,
  mixins,
  props,

  data() {
    return {
      ...data
    }
  },

  computed: {
  },

  async created() {
  },

  mounted() {

  },
  methods: {
    getProductItemComponent(item) {
      item = item || {}
      const type = item.type || this.productType
      const subType = item.subType || this.productSubType
      const category = item.category || this.productCategory
      const view = this.productItemView

      const items = [
        `types.${type}`,
        `types.${type}.${subType}`,
        `categories.${category}`,
        `views.${view}`,
      ];

      let component = this.$$fv(
        this.probeComponent(`re-shop-product-editor-${type}-${category}`),
        this.probeComponent(`re-shop-product-editor-${type}-subtype-${subType}`),
        this.probeComponent(`re-shop-product-editor-${type}-${subType}`),
        this.probeComponent(`re-shop-product-editor-${type}`),
        this.itemComponent
      )
      return getFirstCombination(items, this.$$s, true, 'components', null, component)
    },

    getProductItemAttrs(item) {
      item = item || this.productItem || {}
      const otherAttrs = this.mergedAttr

      const translations = this.getProductSpecificSettings(item, this.$$t, this.$$$t)
      const settings = this.getProductSpecificSettings(item)

      const parentAttr = _.pick(otherAttrs, ['token'])
      const attr = {
        ...parentAttr,
        item,
        translations,
        settings,
        class: this.getProductClasses(item, ['re-shop-product-editor'])
      }
      return attr
    },
    getProductVariationDetails(item) {
      const type = item ? this.getProductType(item) : this.productType
      const category = item ? this.getProductCategory(item) : this.productCategory
      const subType = item ? this.getProductSubType(item) : this.productSubType
      const provider = item ? this.getProductProvider(item) : this.productProvider
      const providerType = item ? this.getProductProviderType(item) : this.productProviderType
      return {type, category, subType, provider, providerType}
    },
    getProductsSettingsPaths(item) {
      const options = this.getProductVariationDetails(item)
      const type =  `types.${options.type}`
      const category = `categories.${options.category}`
      const subType = `subTypes.${options.subType}`
      const provider =  `providers.${options.provider}`
      const providerType = `types.${options.providerType}`
      const init = item ? ['item'] : []
      const suffix = item ? '.item' : ''

      const items = [
          ...init || [],
        `${type}${suffix}`,
        `${category}${suffix}`,
        `${type}.${category}${suffix}`,
        `${type}.${subType}${suffix}`,
        `${type}.${category}.${subType}${suffix}`,
        `${type}.${provider}${suffix}`,
        `${type}.${provider}.${subType}${suffix}`,
        `${type}.${provider}.${providerType}${suffix}`,
      ]
      return items
    },
    getProductSpecificSettings(item, source = this.$$s, init = this.$$$s) {
      const paths = this.getProductsSettingsPaths(item)
      const items = paths.map(path => this.$$clone(source(path, {})))
      return _.merge({}, init || {}, ...items)
    },
    getComponentExtraClasses() {
      return [
        _.kebabCase(`type-${this.productType}`),
        _.kebabCase(`subtype-${this.productSubType}`),
        _.kebabCase(`category-${this.productCategory}`),
        _.kebabCase(`custom-type-${this.productCustomType}`),
        _.kebabCase(`location-${this.currentLocationName}`),
      ]
    }
  },
}
