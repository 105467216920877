import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'
import cartItem from '../../../../../props/cart/item'

const localProps = {
  containerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('cart-item.class', null)
    }
  },
  item: {
    type: Object,
    default: null
  },
  headers: {},
  items: {
    type: [Array, Object],
    default: null
  },
  error: {
    type: [String, Object],
    default: null
  },
  groupItems: {},
  loginUri: {},
  registerUri: {},
  needsToSignIn: {},
  showProductImage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.product.showImage', true)
    }
  },
  productImageClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('item.product.imageClass', null)
    }
  },
  showUnitPrice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.showUnitPrice', false)
    }
  },
  hideQuantity: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('item.hideQuantity', false)
    }
  },

}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...cartItem,
  ...localProps
}
export default props
