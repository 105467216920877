/*global Vuex, _ */
import {fv} from '../lib/utils'

export const state = {
  loadingAll: false,
  records: {},
  config: {
    options: {},
    routes: {},
    service: null,
    services: {},
    timezone: null
  },
}

export const mutations = {
  setConfigurations(state, payload) {
    _.forOwn(payload.services, service => {
      if (service) {
        service.refreshing = fv(service.refreshing, false)
        service.running = fv(service.running, false)
        service.loading = fv(service.loading, false)
        _.forOwn(service.monitors || [], monitor => {
          if (monitor) {
            monitor.refreshing = fv(monitor.refreshing, false)
            monitor.running = fv(monitor.running, false)
            monitor.loading = fv(monitor.loading, false)
          }
        })
      }
    })
    state.config = payload
  },
  clearRecords(state) {
    state.records = {}
  },
  setRecords(state, records) {
    state.records = records
  },
  addRecord(state, {tag, key, ...rest}) {
    if (!state.records[tag]) {
      state.records[tag] = {}
    }
    if (!state.records[tag][key]) {
      state.records[tag][key] = []
    }
    state.records[tag][key].push({...rest, tag, key})
  },
  setLoadingAllData(state, payload) {
    state.loadingAll = payload
  },
  setServiceProperty(state, {tag, key, value, property}) {
    if (_.isNil(key)) {
      state.config.services[tag][property] = value
    } else {
     state.config.services[tag].monitors[key][property] = value
    }
  },
}

export const actions = {
  setConfigurations({commit}, payload) {
    commit('setConfigurations', payload)
  },
  clearRecords({commit}, payload) {
    commit('clearRecords', payload)
  },
  setRecords({commit}, payload) {
    commit('setRecords', payload)
  },
  addRecord({commit}, payload) {
    commit('addRecord', payload)
  },
  setLoadingAllData({commit}, payload) {
    commit('setLoadingAllData', payload)
  },
  setServiceProperty({commit}, payload) {
    commit('setServiceProperty', payload)
  }
}

export const getters = {
  records: state => state.records,
  config: state => state.config,
  isLoadingAllData: state => state.loadingAll,
}

export const createStore = () => {
  return new Vuex.Store({
    strict: true,
    state,
    mutations,
    actions,
    getters
  })
}

export default createStore
