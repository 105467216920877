/*global _ */
import ProfileMenuMixin from './profile-menu'

/**
 * By default, in guest mode, `Sign in` link is shown.
 * After authentication, all pages except the Profile page show `My Profile` link whereas Profile page shows `Sign Out` link
 *
 * To force both `Sign Out` and `My Profile` links to be shown:
 *   - Set `signOutOnlyInProfile` prop to false. This will show both links in all pages except the Profile page.
 *   - Set `showProfileInProfile` prop to true. This will show both links in the Profile page.
 *
 */
export default {
  name: 're-login-status',
  mixins: [ProfileMenuMixin],
  props: {
    containerClass: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('header.icon.container.class', '')
      }
    },
    noSignInLink: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.icon.signIn.disabled', false)
      }
    },
    signInLabel: {
      type: String,
      default() {
        return this.$options.$$t('header.icon.signIn.label', 'Sign in')
      }
    },
    signInClass: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('header.icon.signIn.class', 're-status-sign-in-link')
      }
    },
    signInLink: {
      type: [String, Object],
      default() {
        return this.$options.$$s('header.icon.signIn.link', {name: 'sign-in'})
      }
    },
    noSignOutLink: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.icon.signOut.disabled', false)
      }
    },
    signOutLabel: {
      type: String,
      default() {
        return this.$options.$$t('header.icon.signOut,label', 'Sign out')
      }
    },
    signOutClass: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('header.icon.signOut.class', 're-status-sign-out-link')
      }
    },
    signOutOnlyInProfile: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.icon.signOut.onlyInProfile', true)
      }
    },
    reloadAfterSignOut: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.menu.SignOut.reload', false)
      }
    },
    noProfileLink: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.icon.profile.disabled', false)
      }
    },
    profileLabel: {
      type: String,
      default() {
        return this.$options.$$t('header.icon.profile.label', 'My Account')
      }
    },
    profileClass: {
      type: [String, Array, Object],
      default() {
        return this.$options.$$s('header.icon.profile.class', 're-status-profile-link')
      }
    },
    profileLink: {
      type: [String, Object],
      default() {
        return this.$options.$$s('header.icon.profile.link', {name: 'user.profile'})
      }
    },
    showProfileInProfile: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.icon.profile.inProfile', false)
      }
    },
    validateAuthInterval: {
      type: [Boolean, Number, String],
      default() {
        return this.$options.$$s('validateAuthInterval', 60)
      }
    },
  },
  data() {
    return {
      validateAuthTimeout: null
    }
  },
  mounted() {
    this.validateAuthFrequently()
  },
  beforeDestroy() {
    if (this.validateAuthTimeout) {
      clearTimeout(this.validateAuthTimeout)
    }
  },
  computed: {
    routeIsProfile() {
      return this.isRoute('user.profile')
    },
    isAuthenticated() {
      return this.authenticated && this.user && this.user.verified
    },
    showSignInLink() {
      const canShow = !this.noSignInLink
      return !this.isAuthenticated && canShow
    },
    showProfileLink() {
      const canShow = !this.noProfileLink && (!this.routeIsProfile || this.showProfileInProfile)
      return this.isAuthenticated && canShow
    },
    showSignOutLink() {
      const canShow = !this.noSignOutLink && (this.routeIsProfile || !this.signOutOnlyInProfile)
      return this.isAuthenticated && canShow
    }
  },
  methods: {
    validateAuthFrequently() {
      const interval = this.validateAuthInterval * 1000
      if (interval) {
        this.validateAuthTimeout = setTimeout(() => {
          const valid = this.validateAuthCookie()
          if (valid) {
            this.validateAuthFrequently()
          }
        }, interval)
      }
    },
    async userSignOut() {
      await this.logout()
      if (this.reloadAfterSignOut) {
        window.location.reload()
      }
    }
  }
}
