<template lang="pug">
  .form-group
    label(v-if="label") {{ label }}
    slot(
      :classes="{ 'form-control': !disableFormControl, 'is-invalid': hasErrors, 'is-valid': isValid }"
      :errors="activeErrors"
      :events="events"
      :first-error-message="firstErrorMessage"
      :has-errors="hasErrors"
      :validator="preferredValidator"
    )
    slot(
      name="errors"
      :errors="activeErrors"
      :error-messages="activeErrorMessages"
      :has-errors="hasErrors"
      :first-error-message="firstErrorMessage"
    )
      .invalid-feedback(v-if="hasErrors") {{ firstErrorMessage }}
    slot(name="after")

</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'
export default {
  name: 'form-group',

  mixins: [singleErrorExtractorMixin],

  props: {
    disableFormControl: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
