export const name = 're-loyalty-reward-claim-playcard-reload'

import props from './props'

export {props}

import ClaimerMixin from '../base/claimer'

export const mixins = [
  ClaimerMixin
]
import {
  setValidationRule,
} from '../../../../../../lib/validations'

export const defaultFormFields = {card: ''}

export const validationsRules = {
  card: setValidationRule('fields.card', {
    required: {error: 'Card is required.'}
  }),
}
export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  defaultFormFields,
  validationsRules,
}
