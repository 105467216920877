import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  title: {
    type: [String, Array, Object],
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },
  subTitle: {
    type: String,
    default() {
     return this.$options.$$t('subTitle', null)
    }
  },
  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },
  notice: {
    type: String,
    default() {
     return this.$options.$$t('notice', null)
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading subscriptions...')
    }
  },
  buyComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.buy','re-shop-subscription')
    }
  },
  planComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.plan','re-shop-product-item-subscription-plan')
    }
  },


}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
