import createPersistedState from 'vuex-persistedstate'
import * as types from './mutation-types'

const namespaced = true
const namespace = 'Onboarding'

const state = {
  tenantId: null,
}

const mutations = {
  [types.SET_TENANT_ID] (state, { id }) {
    state.tenantId = id
  },
}

const actions = {
  setTenantId ({ commit }, payload) {
    commit(types.SET_TENANT_ID, payload)
  },
}

const getters = {
  tenantId: state => state.tenantId,
}

const modules = {
  [namespace]: {
    state,
    mutations,
    actions,
    getters,
  },
}

const plugins = [
  createPersistedState({
    paths: [namespace],
    key: namespace,
  }),
]

export { namespaced, namespace, plugins, modules }
