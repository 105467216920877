/*global _ */
import {toBoolean} from '../../../../lib/formatters'
import {parseFieldsValidationDef, parseValidationsFromString} from '../../../../lib/validations/utils'
export {parseFieldsValidationDef, parseValidationsFromString}

import {setValidationRule, required} from '../../../../lib/validations'
export {required}

export const setImportValidations = (settings, fields) => {
  const importConfig = _.get(settings, 'importConfig', {})
  const module = importConfig.importType
  const allRules = _.get(importConfig, 'validations.rules')
  if (module && !_.isEmpty(allRules)) {
    const config = importConfig.config
    _.forOwn(config, (item, key) => {
      const column = item.column
      const mapped = _.get(item, 'fields.0', key)
      let validations = allRules[mapped]
      if (validations) {
        const field = fields[column]
        const forceCustom = _.get(field, 'advancedSettings.forceCustomValidations')
        if (forceCustom) {
          const existingRules = _.get(field, 'advancedSettings.validations', {})
          const moduleRules = parseValidationsFromString(validations)
          validations = _.merge({}, moduleRules, existingRules)
        }
        _.set(field, 'advancedSettings.validations', validations)
      }
    })
  }
}

export const parseFieldsValidations = (fields, forceRequired, settings) => {
  return _.mapValues(fields, (field, fieldKey) => {
    let items = {}
    const {advancedSettings} = field || {}
    if (advancedSettings) {
      let vItems = advancedSettings.validations
      if (_.isEmpty(vItems)) {
        vItems = advancedSettings.defaultValidations
      }
      if (_.isString(vItems) || _.isArray(vItems)) {
        vItems = parseValidationsFromString(vItems)
      }
      if (vItems) {
        items = _.reduce(vItems, (finalItems, item, key) => {
          if (!key || key === 'null') {
            return finalItems
          }
          if (item.ruleFn !== false) {
            const rules = parseFieldsValidationDef(item, key, field)
            if (rules && !_.isEmpty(rules)) {
              finalItems[key] = rules
            }
          }
          if (item.children) {
            _.forOwn(item.children, (child, childKey) => {
              if (child.ruleFn !== false) {
                const rules = parseFieldsValidationDef(child, childKey, field)
                if (rules && !_.isEmpty(rules)) {
                  finalItems[childKey] = rules
                }
              }
            })
          }
          return finalItems
        }, {})
      }
    }
    if ((forceRequired || toBoolean(field.required)) && !items.required) {
      const fName = field.label || _.startCase(field.fieldName || field.name)
      const {items: {required: requiredItem = {}}} = setValidationRule(null, fName)
      items.required = parseFieldsValidationDef(requiredItem, 'required', field)
    }
    items = _.omitBy(items, (v) => !v || _.isEmpty(v))
    return {items, name: `fields.${fieldKey}`}
  })
}

export const defaultFormFields = {}
export const allValidationsRules = {}

export default {
  defaultFormFields,
  allValidationsRules,
  parseFieldsValidations,
  parseFieldsValidationDef,
  parseValidationsFromString,
  setImportValidations,
  required
}

