import component from '../../../../../props/component'
import form from '../../../../../props/form'
import processing from '../../../../../props/processing'

const localProps = {
  allowGuestCheckout: {},
  guestCheckoutTypes: {},
  skipBillingIfNoPayment: {},
  isFormDisabled: {},
  viewPaymentDetails: {},

  separateBillingForm: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('separateBillingForm', false)
    }
  },

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Billing Information')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  showNote: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNote', true)
    }
  },
  note: {
    type: String,
    default() {
      return this.$options.$$t('note',
          `Please enter your billing details that
          will be associated to the payment.`)
    }
  },
  totals: {
    type: Object,
    default: null,
  },
  guestEmail: {
    type: String,
    default: null,
  },
  billingLoadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Your order is being processed...')
    }
  },
  userNoticeTemplate: {
    type: String,
    default() {
      return this.$options.$$t('userNotice', 'Checkout as {{first_name}} {{last_name}} ') //@added:
    }
  },
  registerNotice: {
    type: String,
    default() {
      return this.$options.$$t('registerNotice', 'Please fill in your registration and billing details in the form below.') //@added:
    }
  },
  guestNotice: {
    type: String,
    default() {
      return this.$options.$$t('guestNotice', '')
    }
  },
  userLogoutLabel: {
    type: String,
    default() {
      return this.$options.$$t('logout.link.label', this.$options.$$t('logout.link.label', 'Sign out'))
    }
  },
  loginButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('loginButtonTitle', 'Sign in')
    }
  },
  loginButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('loginButtonClass', null)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t(
        'submit.buttonTitle',
        this.$options.$$t('submitButtonTitle', 'Submit payment')
      )
    }
  },
  submitButtonTitleIncludeAmount: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submit.buttonTitle.withAmount', true)
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s(
        'submit.buttonContainerClass',
        this.$options.$$s('submitButtonClasses', null)
      )
    }
  },
  submitButtonContainerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submit.buttonContainerClass', null)
    }
  },
  submitButtonImageSrc: {
    type: String,
    default() {
      return this.$options.$$s('submit.buttonImageSrc', null)
    }
  },

  nextButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t(
        'next.buttonTitle',
        this.$options.$$t('nextButtonTitle', 'Next')
      )
    }
  },
  nextButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s(
        'next.buttonContainerClass',
        this.$options.$$s('nextButtonClasses', null)
      )
    }
  },
  nextButtonContainerClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('next.buttonContainerClass', null)
    }
  },
  nextButtonImageSrc: {
    type: String,
    default() {
      return this.$options.$$s('next.buttonImageSrc', null)
    }
  },

  backButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('backButtonTitle', 'Go back')
    }
  },
  backButtonClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$t('backButtonClass', null)
    }
  },

  firstNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.firstName.label', 'First Name')
    }
  },
  firstNamePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.firstName.placeholder', 'First Name')
    }
  },
  firstNameClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.firstName.class', 'form-field')
    }
  },
  lastNameLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.lastName.label', 'Last Name')
    }
  },
  lastNamePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.lastName.placeholder', 'Last Name')
    }
  },
  lastNameClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.lastName.class', 'form-field')
    }
  },
  emailLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.email.label', 'Last Name')
    }
  },
  emailPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.email.placeholder', 'Last Name')
    }
  },
  emailClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.email.class', 'form-field')
    }
  },
  phoneLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.phone.label', 'Phone')
    }
  },
  phonePlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.phone.placeholder', 'Phone')
    }
  },
  phoneClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.phone.class', 'form-field')
    }
  },

  withBirthDayInputs: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputs', true)
    }
  },
  birthdayInputsOrder: {
    type: [Array, String],
    default() {
      return this.$options.$$s('birthdayInputsOrder', 'month,day,year')
    }
  },
  withBirthDayInputButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputButton', true)
    }
  },

  cardLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.card.label',
        'Credit Card Number')
    }
  },
  cardPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.card.placeholder',
        'XXXX XXXX XXXX XXXX')
    }
  },
  cardClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.card.class',
        'form-field card-number')
    }
  },
  cvvLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.cvv.label',
        'Security code')
    }
  },
  cvvPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.cvv.placeholder', '****')
    }
  },
  cvvClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.cvv.class', 'form-field card-code')
    }
  },
  expMonthLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expMonth.label',
        'Expiration Month')
    }
  },
  expMonthPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expMonth.placeholder',
        'Month')
    }
  },
  expMonthClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.expMonth.class',
        'form-field exp-month')
    }
  },
  expYearLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expYear.label', 'Expiration Year')
    }
  },
  expYearPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.expYear.placeholder', 'Year')
    }
  },
  expYearClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.expYear.class', 'form-field exp-year')
    }
  },
  zipLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.zip.label', 'Zip Code')
    }
  },
  zipPlaceholder: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.zip.placeholder', 'XXXXXXXX')
    }
  },
  zipClass: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('form.fields.zip.class',
        'form-field billing-zip')
    }
  },
  terms: {
    type: String,
    default() {
      return this.$options.$$s('termsUrl') || this.$options.$$s('terms.url', null)
    }
  },
  withTerms: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('form.fields.terms', this.$options.$$s('withTerms', false))
    }
  },
  termsLinkLabel: {
    type: [String, Object],
    default() {
      return this.$options.$$t('terms.link.label') || 'Terms and Conditions'
    }
  },

  noAuthCheck: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('noAuthCheck', true)
    }
  },

  authAction: {
    type: [String],
    default() {
      return this.$options.$$s('authAction', 'login')
    }
  },//@added:
  authActionTypes: {
    type: [Array, Object],
    default() {
      return this.$options.$$s('authActions', {
        register: {},
        login: {},
        guest: {},
      })
    }
  },//@added:
  authActionLang: {
    type: [Array, Object],
    default() {
      return this.$options.$$t('authActions', {
        register: {
          label: 'Register and checkout'
        },
        login: {
          label: 'Sign in and checkout'
        },
        guest: {
          label: 'Checkout as guest'
        },
      })
    }
  },
  showUseUserDataCheckbox: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showUseUserDataCheckbox', true)
    }
  },
  useUserDataCheckboxLabel: {
    type: String,
    default() {
      return this.$options.$$t('useUserDataCheckboxLabel', 'Use my data')
    }
  },
  userBoxClass: {
    type: String,
    default() {
      return this.$options.$$s('userBoxClass', null)
    }
  },
  cardBoxClass: {
    type: String,
    default() {
      return this.$options.$$s('cardBoxClass', null)
    }
  },
  subscribeLabel: {
    type: [String],
    default() {
      return this.$options.$$s('form.fields.subscribe.label',
        'I want to to receive promotions and special offers via email.')
    }
  }
}

const formProps = {
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', true)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },
  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },
  errorComponent: {
    type: String,
    default() {
      return this.$options.$$s('errorComponent', 're-cart-error')
    }
  },
  validationMessage: {
    type: String,
    default: 'Validating'
  },
  // to suppress backward feature to skipping field validation when authenticated
  authSkipValidationFields: {
    type: [Array, String, Boolean],
    default() {
      return this.$options.$$s('form.authSkipValidationFields', [
        'phone',
        'password',
        'password_confirmation',
        'birth_date',
        // 'terms',
        'subscribe'
      ])
    }
  },
}

export const defaultSectionsSettings = {
  contact: {
    show: true,
    showTitle: false,
    showSubTitle: false,
    showNotice: false,
    tabIndexStarts: 100,
    fields: [
      'first_name',
      'last_name',
      'email',
      'phone',
      // 'birth_date',
      // 'gender',
      // 'location_id',
      // 'preferred_location_id',
      // 'register_location_id',
      'password',
      'password_confirmation',
    ],
    modes: {
      auth: {
        show: true,
        fields: [
          'first_name',
          'last_name',
          'email',
          'phone',
          // 'birth_date',
          // 'gender',
          // 'location_id',
          // 'preferred_location_id',
          // 'register_location_id',
        ],
      },
      guest: {
        fields: [
          'email',
        ]
      }
    }
  },
  billing: {
    show: true,
    showTitle: false,
    showSubTitle: false,
    showNotice: false,
    tabIndexStarts: 200,
    fields: [
      'birth_date',
      'zip',
      'card',
      'cvv',
      // 'expDate',
      'expMonth',
      'expYear',
      'terms',
      'subscribe'
    ],
    modes: {
      auth: {
        fields: [
          'zip',
          'card',
          'cvv',
          // 'expDate',
          'expMonth',
          'expYear',
          'terms'
        ]
      },
      guest: {
        fields: [
          'first_name',
          'last_name',
          'card',
          // 'expDate',
          'expMonth',
          'expYear',
          'cvv',
          'zip'
        ]
      }
    }
  },
  shipping: {
    show: true,
    showTitle: false,
    showSubTitle: false,
    showNotice: false,
    tabIndexStarts: 300,
    fields: [
      'shipping_is_pickup',
      'shipping_first_name',
      'shipping_last_name',
      'shipping_address',
      'shipping_address2',
      'shipping_city',
      'shipping_state',
      'shipping_zip',
      // 'shipping_country',
    ],
    modes: {
      guest: {
      }
    }
  },
}
export const defaultSectionsLang = {
  contact: {
    title: 'Contact details ',
    subTitle: '',
    notice: '',
  },
  billing: {
    title: 'Billing details',
    subTitle: '',
    notice: '',
  },
  shipping: {
    title: 'Shipping details',
    subTitle: '',
    notice: '',
  }
}

const sectionProps = {
  showSectionTitles: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('section.showTitle', false)
    }
  },
  showSectionSubTitles: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('section.showSubTitle', false)
    }
  },
  showSectionNotices: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('section.showNotice', false)
    }
  },
  allSectionsSettings: {
     type: [Array, String, Object],
    default() {
      const sections = this.$options.$$s('$sections')
      return sections || _.merge({}, defaultSectionsSettings, this.$options.$$s('sections', {}))
    }
  },
  allSectionsLang: {
    type: [Array, String, Object],
    default() {
      const sections = this.$options.$$t('$sections')
      return sections || _.merge({}, defaultSectionsLang, this.$options.$$t('sections', {}))
    }
  },
}


export const props = {
  ...component,
  ...form,
  ...processing,
  ...formProps,
  ...sectionProps,
  ...localProps
}
export default props
