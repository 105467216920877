import { createNamespacedHelpers } from 'vuex'

const  EventBus = window.VueEventBus

import { namespace } from '../vuexstore'
import { default as timerStore, namespace as timerStoreNamespace } from '../vuexstore/modules/timer'
const { mapGetters, mapActions } = createNamespacedHelpers([namespace, timerStoreNamespace].join('/'))

export default {
  computed: {
    ...mapGetters(Object.keys(timerStore.getters)),
  },

  methods: {
    ...mapActions(Object.keys(timerStore.actions)),
  },

  created() {
    if (!this.timerIsRunning && this.timerTimedout) {
        this.resetTimeout()
    }
    if (this.timerIsRunning && this.needsToShowTimer && _.isEmpty(this.timerUpdater)) {
        this.resumeTimer()
    }
  },
  watch: {
    timerTimedout(newValue, oldValue) {
      if (newValue ===  true) {
        // EventBus.$emit('timer.timeout', this.timerObject)
      }
    },
    timerRemaining(newValue, oldValue) {
      if (!this.timerIsPaused) {
        // EventBus.$emit('timer.tick', newValue, this.timerElapsed, this.timerObject)
      }
    },
    timerIsRunning(newValue, oldValue) {
      if (newValue) {
        // EventBus.$emit('timer.started', this.timerObject)
      }
      else {
       // EventBus.$emit('timer.stopped', this.timerObject)
      }
    }
  }

}
