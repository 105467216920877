import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Hi,')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  showPersonalDetailsTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPersonalDetailsTitle', false)
    }
  },
  showPersonalDetailsSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showPersonalDetailsSubTitle', false)
    }
  },
  showEdit: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showEditOnLoad', false)
    }
  },
  editButtonTitle: {
    type: String,
    default() {
     return this.$options.$$t('edit.button.title', 'Edit')
    }
  },
  showEditButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.button.show', true)
    }
  },
  editTitle: {
    type: String,
    default() {
     return this.$options.$$t('edit.title', 'Update your profile')
    }
  },
  showEditTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showTitle', false)
    }
  },
  editSubTitle: {
    type: String,
    default() {
     return this.$options.$$t('edit.subTitle', null)
    }
  },
  showEditSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showSubTitle', false)
    }
  },
  editNotice: {
    type: String,
    default() {
     return this.$options.$$t('edit.notice', null)
    }
  },
  showEditNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showNotice', true)
    }
  },


  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
     default() {
      return this.$options.$$s('classes', null)
    }
  },

  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$s('submitButtonTitle', 'Submit')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', null)
    }
  },

  showCloseButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showCloseButton', false)
    }
  },
  showBackButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showBackButton', true)
    }
  },
  backButtonTitle: {
    type: [String],
    default() {
      return this.$options.$$t('backButtonTitle' , 'Back')
    }
  },
  backButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('backButtonClasses', null)
    }
  },
  showSuccessPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.showSuccessPopup', false)
    }
  },
  backToViewOnSave: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('edit.backOnSave', true)
    }
  },
  successTitle: {
    type: [String],
    default() {
      return this.$options.$$t('edit.successTitle' , null)
    }
  },
  successSubTitle: {
    type: [String],
    default() {
      return this.$options.$$t('edit.successSubTitle' , null)
    }
  },
  successMessage: {
    type: [String],
    default() {
      return this.$options.$$t('edit.successMessage', null)
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', true)
    }
  },
  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Loading...')
    }
  },
  formSubmitMessage: {
    type: String,
    default() {
      return this.$options.$$t('edit.submitMessage', 'Updating...')
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },

  withBirthDayInputs: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputs', false)
    }
  },
  birthdayInputsOrder: {
    type: [Array, String],
    default() {
      return this.$options.$$s('birthdayInputsOrder', 'month,day,year')
    }
  },
  withBirthDayInputButton: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('withBirthDayInputButton', true)
    }
  },
  disableSavedBirthDay: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('disableSavedBirthDay', false)
    }
  },

  subscribeLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.subscribe.label',
        this.$options.$$t('subscribeLabel',
            `By registering with us you are opting-in to
            receive news and special offers. To change your email preferences,
            visit account profile.`
        ))
    }
  },
  subscribeSmsLabel: {
    type: String,
    default() {
      return this.$options.$$t('form.fields.sms_subscribe.label',
       this.$options.$$t('form.fields.subscribe_sms.label',
       this.$options.$$t('subscribeSmsLabel',
          `By registering with us you are opting-in to
          receive news and special offers vis SMS. To change your SMS preferences,
          visit account profile.`
      )))
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
