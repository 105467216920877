export const name = 're-cart-item-booking'

// generic setup
import {allValidationsRules, defaultFormFields as formFields} from './form'
export const validationsRules = allValidationsRules
export const defaultFormFields = formFields

import propDefs from './props'
export const props = propDefs

import ComponentMixin from '../../../../../mixins/components/complex'
import FormMixin from '../../../../../mixins/form'
import CartBookingMixin from '../../../../../mixins/cart/items/booking'
export const mixins = [ComponentMixin, FormMixin, CartBookingMixin]

export const EventBus = window.VueEventBus

export const data = {
  propDefs,
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,

  EventBus
}
