/*global _ */
import {name, mixins, props, data, validationsRules, parseValidations, parseValidationSetup, EventBus} from './config'
import {setValidationRule} from '../../../../lib/validations'
import {setTimeoutAsync} from '../../../../lib/utils'

const validationGroupRules = {
  personalDetails: [
    'firstName', 'lastName', 'email', 'emailConfirmation'
  ],
  personalDetailsWithPhone: [
    'firstName', 'lastName', 'email', 'emailConfirmation', 'phone', 'phoneConfirmation'
  ],
  gifteeDetails: [
    'gifteeFirstName', 'gifteeLastName', 'gifteeEmail', 'gifteeEmailConfirmation'
  ],
  gifteeDetailsWithPhone: [
    'gifteeFirstName', 'gifteeLastName', 'gifteeEmail', 'gifteeEmailConfirmation','gifteePhone', 'gifteePhoneConfirmation'
  ]
}

export default {
  name,
  props,
  mixins,

  data() {
    return {
      ...data,
      termsAgreed: 'no',
      editMode: false,
      validations: {},
      isSubmitting: false,
    }
  },
  watch: {
    giftCardPreviewing() {
      this.lazyScrollTo('.re-page')
    },
  },

  validations() {
    return this.prepareValidationRules(_.cloneDeep(validationsRules), _.cloneDeep(validationGroupRules))
  },

  computed: {
    cards() {
      let allProducts = _.cloneDeep(_.values(this.availableGiftCards || []))
      const filterByIds = this.filterByProducts
      if (!_.isEmpty(filterByIds) && _.isArray(filterByIds)) {
        allProducts = _.filter(allProducts, p => filterByIds.includes(p.productId))
      }
      const location = this.location_id
      const filters = {}
      if (location && this.useLocationFilter) {
        if (this.useStrictLocationFilter) {
          filters.location = {location, strict: true}
        } else {
          filters.location = location
        }
      }
      const cards = this.applyPointsOnProducts(
        this.autoApplyFilterOnProducts(allProducts, filters)
      )

      return _.map(cards, item => {
        item.$numbers = {
          price: _.toNumber(item.price),
          priceRound: _.round(item.price),
        }
        return item
      })
    },
    cardPreviewHtml() {
      return this.giftCardPreviewHtml
      // return this.previewCardBoxHtml || this.giftCardPreviewHtml
    }
  },

  async created() {
    if (this.isGiftNoteRequired) {
      validationsRules.giftNote = setValidationRule('giftNote', {
        required: {error: 'Note is required.'}
      })
    }
    this.validations = parseValidationSetup(validationsRules, this)
    this.editMode = !!this.token || !!this.item || !!this.giftCardEditItem

    if (this.authenticated) {
      this.firstName = this.user.first_name
      this.lastName = this.user.last_name
      this.email = this.emailConfirmation = this.user.email
      this.phone = this.phoneConfirmation = this.user.meta.phone
    }

    if (!this.showAgreeToTermsBox) {
      this.termsAgreed = 'yes'
    }
    if (this.editMode) {
      EventBus.$emit('giftcard.before.edit')
      this.termsAgreed = 'yes'
      const editItem = this.giftCardEditItem || this.currentCartItems[this.token || this.item]
      this.count = editItem.payload.count
      this.hydrateCardFromCart(editItem)
    }
  },

  methods: {
    getFieldClasses(field, source = {}, mergeData = {}) {
      const data = {...source}
      const path = `form.fields.${field}`
      const def = this.$$s(`${path}.classes`, this.$$s(`${path}.attr.classes`))
      const classes = this.parseReactiveAttrs(def, data || {})
      return [
        classes,
        mergeData
      ]
    },
    getProductIdFieldAttrs(item) {
      return {
        'class': this.getFieldClasses(
          'productId',
          item, {
            active: this.productId == item.id
          }
        ),
        name: 'amount',
        key: item.id,
        id: `amount${item.id}`,
        radioValue: item.id,
        context: this,
        validations: this.validations.productId,
        label: this.productLabelFormat ?
          this.$$templify(this.productLabelFormat, item) : item.title,
      }
    },
    validateCardInputs() {
      this.$v.$touch()
      this.formSubmitting = true
      return !this.disabledCardProcessor
    },

    async processCard() {
      if (!this.validateCardInputs()) {
        this.scrollToFirstErrorElement()
      } else {
        if (this.giftee) {
          EventBus.$emit('giftcard.preview')
          this.showGiftCardPreview()
        } else {
          await this.addCardToCart()
        }
      }
    },

    hydrateCardFromCart(cartItem) {
      this.setGiftCardInEditMode()
      const items = this.localGiftCardCartItems
      const item = items[cartItem.id || cartItem.cartId]
      this.setGiftCardInput(item)
      this.phoneConfirmation = this.phone
      this.gifteeEmailConfirmation = this.gifteeEmail
      this.gifteePhoneConfirmation = this.gifteePhone
      this.setGiftCardEditItem(null)
      EventBus.$emit('giftcard.edit')
    },

    async addCardToCart() {
      this.formSubmitting = true
      EventBus.$emit('giftcard.before.add-to-cart')
      this.cardReadyForCart = true
      this.isSubmitting = true
      const item = this.proposedGiftCardCart
      item.editRoute = {name: 'giftcards.buy'}
      item.payload.count = this.count
      this.setGiftCardInput({cartId: item.id})
      this.setLocalGiftCardCartItem(_.clone(this.giftCardInput(), true))

      item.editable =  this.cartItemEditable
      if (this.editItem) {
        item.id = this.editItem.id
      }

      let success
      if (this.editMode) {
        success = await this.updateCartItem(item)
      } else {
        success = await this.addCartItem(item)
      }

      if (success) {
        this.setGiftCardEditItem(null)
        const actioned = this.isDesignerMode ? false : this.executeCustomAddToCartAction()
        if (!this.isDesignerMode && !actioned) {
          EventBus.$emit('buy.giftcard.add-to-cart')
        }
      }

      if (this.isDesignerMode) {
        await setTimeoutAsync(1000)
        this.isSubmitting = false
      }
    },

    prepareValidationRules(rules, groups) {
      const fields = _.uniq([..._.keys(rules), 'location_id', 'count', 'custom_amount'])
      const checker = {phone: 'showPhoneInput', phoneConfirmation: 'showPhoneInput'}

      const fieldRules = _.reduce(fields, (all, field) => {
        all[field] = this.isField(field, true) && (!checker[field] || this[checker[field]])
        return all
      }, {})

      rules = _.mapValues(rules, (item, field) => fieldRules[field] ? item : {})
      return {
        ...parseValidations(rules),
        ...groups
      }
    }
  },
}
