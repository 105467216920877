import ComponentMixin from '../../../../mixins/components/complex'
import MenuMixin from '../../../../mixins/profile/menu'
import ResponsiveMixin from '../../../../mixins/responsive'
import getMenuProps from '../../../../props/profile/menu'
export const EventBus = window.VueEventBus

export default {
  mixins: [ComponentMixin, ResponsiveMixin, MenuMixin],
  settings: 'page.profile',
  translations: 'page.profile',
  props: {
    showProfileMenu: {
      type: [Boolean, Number, String],
      default() {
        return !!this.$options.$$s('header.menu.show', false)
      }
    },
    ...getMenuProps('header.menu')
  },
  data() {
    return {
      canShowMenu: false,
      MENU_CLASS_PREFIX: 'header-menu-item',
      SELECT_EVENT: 'profile.change-tab'
    }
  },
  computed: {
    isTouchToggler() {
      const width = this.appWidth
      const maxWidth = _.toFinite(this.toggleTouchMaxWidth)
      return maxWidth && width <= maxWidth
    }
  },
  created() {
    EventBus.$on('profile.select-tab', (key) => {
      this.selected = key
    })
  },
  methods: {
    showMenu() {
      if (!this.isTouchToggler) {
        this.canShowMenu = true
      }
    },
    hideMenu(force) {
      if (force || !this.isTouchToggler) {
        this.canShowMenu = false
      }
    },
    nav(key) {
      if (this.routeIsProfile) {
        this.onNav(key)
      } else {
        location.href = this.getProfileLink(key)
      }
    },
    onNav(key) {
      this.hideMenu(true)
      this.select(key)
    },
    mayToggleMenu() {
      if (this.isTouchToggler) {
        this.canShowMenu = !this.canShowMenu
      }
    }
  }

}
