/*global _ */
import Base, {
  axios,
  setPromise,
  getEndpoint,
  omitId,
  client_secret,
  client_id,
} from './base'

export {
  axios,
  APIURI,
  client_secret,
  client_id,
  getFirstErrorMessage,
  getErrorMessage,
  getErrorMessages,
  getErrorMessagesWithKeys,
  endpoints,
  services,
  handlePromise,
  promise,
  setPromise,
  getEndpoint,
} from './base'

export const registerUser = async (payload, options = {}) => {
  const endPoint = getEndpoint('user.registration', {}, '/user/register', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.registration', registerUser)

export const sendConfirmation = async (payload, options = {}) => {
  // {email}
  const endPoint = getEndpoint('user.confirmRegistration', {}, '/user/register/resend', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.registration.resend-confirmation', sendConfirmation)

export const emailVerify = async (payload, options = {}) => {
  // {email, token}
  const endPoint = getEndpoint('user.email.verify', {}, '/user/email-verification/check', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.email.verify', emailVerify)

export const validateEmail = async (payload, options = {}) => {
  const endPoint = getEndpoint('user.email.validate', {}, '/user/register/validate-email', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.validate.email', validateEmail)

export const loginUser = async (request, options = {}) => {
  // {email, reseturl}
  const scope = ''
  const payload = {client_id, client_secret, scope, ...request || {}}
  const endPoint = getEndpoint('user.login', {}, '/clients/api/login', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.login', loginUser)

export const forgotPassword = async (payload, options = {}) => {
  // {email, reseturl}
  const endPoint = getEndpoint('user.password.forgot', {}, '/user/password/forgot', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.password.forgot', forgotPassword)

export const resetPassword = async (payload, options = {}) => {
  // {email, reseturl}
  const endPoint = getEndpoint('user.password.reset', {}, '/user/password/reset', options)
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.password.reset', resetPassword)

export const validateAuth = async (options = {}) => {
  const endPoint = getEndpoint('user.ping', {}, '/v1/user/ping', options)
  try {
    await axios.post(endPoint)
    return true
  } catch (e) {
    //
  }
  return false
}
setPromise('user.ping', validateAuth)

export const userProfile = async (options = {}) => {
  const endPoint = getEndpoint('user.profile', {}, '/user/profile', options)
  try {
    const {data} = await axios.get(endPoint)
    return data.data
  } catch (e) {
    //
  }
  return null
}
setPromise('user.profile', userProfile)

export const updateUser = async (id, payload, options = {}) => {
  const endPoint = getEndpoint('user.update', {id}, '/user/{id}', options)
  const {data} = await axios.put(endPoint, payload)
  return data
}
setPromise('user.update', updateUser)

export const deleteUser = async (options = {}) => {
  const endPoint = getEndpoint('user.delete', {}, '/user', options)
  const {data} = await axios.delete(endPoint)
  return data
}
setPromise('user.delete', deleteUser)

export const validateOrder = async (payload, options = {}) => {
  const endPoint = getEndpoint('user.order.validate', {}, '/customer/order/validate', options)
  const {data} = await axios.post(endPoint, payload, options.config || {})
  return data
}
setPromise('user.validate.order', validateOrder)

export const order = async (payload, options = {}) => {
  const endPoint = getEndpoint('user.order.purchase', {}, '/customer/order', options)
  const {data} = await axios.post(endPoint, payload, options.config || {})
  return data
}
setPromise('shop.order', order)

export const notifyOrder = async (payload, options = {}) => {
  if (!_.isObject(payload)) {
    payload = {id: payload}
  }
  const endPoint = getEndpoint('user.order.notify', payload, '/customer/order/{id}/notify', options)
  const {data = {}} = await axios.get(endPoint, {params: omitId(payload)})
  return data
}
setPromise('user.order.notify', notifyOrder)

export default {
  ...Base,
  registerUser,
  sendConfirmation,
  loginUser,
  forgotPassword,
  notifyOrder,
  updateUser,
  deleteUser,
  validateEmail,
  validateOrder,
  order,
  validateAuth
}
