/*global _ */
import F from '../../../lib/formatters'
export default function (namespace, events) {
  const mixin = {
    listeners: {},
    emit(name, ...args) {
      if (namespace) {
        const vmEventListener = _.camelCase(`on_${namespace}_event`)
        const vm = this.$$vm()
        if (_.isFunction(vm[vmEventListener])) {
          vm[vmEventListener](name, ...args)
        }

        let localListener = _.get(this.$$data, 'listener')
        if (_.isString(localListener)) {
          localListener = F.getFirst(localListener, F, vm)
        }
        if (_.isFunction(localListener)) {
          localListener(name, ...args)
        }

      }
      (this.listeners[name] || []).forEach((listener) => {
        if (_.isFunction(listener)) {
          listener.call(this, ...args)
        }
      })
      if (namespace) {
        const eventName = `${namespace}.${name}`
        const vm = this.$$vm()
        if (vm && vm.$emit) {
          vm.$emit(eventName, ...args)
          if (window.VueEventBus) {
            const id = this.$_id
            if (id) {
              window.VueEventBus.$emit(id + '.' + eventName, ...args)
            }
          }
        }
      }

      if (name.indexOf(':') > 0) {
        const [mainName, paramName] = name.split(':')
        this.emit(mainName, paramName, [paramName, ...args])
      }
      return this
    },

    on(name, callback) {
      const listeners = this.listeners[name] || (this.listeners[name] = [])
      listeners.push(callback)
      return this
    }
  }

  events = events || {}
  _.forOwn(events, name => {
    const fnName = _.camelCase('on_' + name.replace(':', '_'))
    mixin[fnName] = function (callback) {
      this.on(name, callback)
      return this
    }
  })
  return mixin
}
