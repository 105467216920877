/*global _ */

import ModalMixin from './modal'

export const props = {
  type: {
    type: String,
    default: null
  },
  message: {
    type: [String, Function],
  },
  title: {
    type: [String, Function],
  },
  buttonTitle: {
    type: String,
    default: 'Ok'
  },
  buttonClasses: {
    type: [String, Array, Object],
  }
}
export default {
  props,
  mixins: [ModalMixin],
  methods: {
    buttonClick() {
      this.respond(true);
    },
    respond(state) {
      this.close(state)
    }
  }
}
