export const name = 're-profile-edit'

// custom setup
import {countries, states} from '../../../../lib/utils'
export {countries, states}

// generic setup
import {allValidationsRules as validationsRules, defaultFormFields, userFields, metaFields, otherFields, groupedFields, defaultSkipValidations} from './form'
import props from './props'

export {props, validationsRules, defaultFormFields, userFields, metaFields, otherFields, groupedFields, defaultSkipValidations}

import ComponentMixin from '../../../../mixins/components/complex'
import ProfileMixin from '../../../../mixins/profile'
import FormMixin from '../../../../mixins/form'
import DesignerMixin from '../re-profile/designer'
export const mixins = [ComponentMixin, FormMixin, ProfileMixin, DesignerMixin]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,

  sources: {
    countries,
    states
  },
}

export default {
  name,
  mixins,
  props,
  data,

  validationsRules,
  defaultFormFields,
  userFields,
  metaFields,
  otherFields,
  groupedFields,
  defaultSkipValidations,

  EventBus,
  countries,
  states,
}
