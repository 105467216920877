/*global _ */
import StandardBonusMixin from './standard-bonus'
import {truthyRule, maxValue, minValue} from '../../../lib/validations'
import {templify, fv} from '../../../lib/utils'

export const props = {
  // amount
  allowCustomAmountProduct: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('allowCustomAmountProduct', false)
    }
  },
  customAmountProductProbe: {
    type: [Boolean, Number, String, Function],
    default() {
      return this.$options.$$s('customAmountProductProbe', 'extra_cash_enabled')
    }
  },
  customAmountRounded: {
    type: [Boolean, Number, String, Function],
    default() {
      return !!this.$options.$$s('customAmountRounded', true)
    }
  },
  customAmountMaxProperty: {
    type: [Boolean, Number, String, Function],
    default() {
      return this.$options.$$s('customAmountMaxProperty', 'max_extra_cash')
    }
  },
  customAmountMinProperty: {
    type: [Boolean, Number, String, Function],
    default() {
      return this.$options.$$s('customAmountMinProperty', 'min_extra_cash')
    }
  },
  customAmountCartItemProperty: {
    type: [String],
    default() {
      return this.$options.$$s('customAmountCartItemProperty', 'extra_cash')
    }
  },
  showQuantityForCustomAmountProduct: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showQuantityForCustomAmountProduct', true)
    }
  },
  customAmountInputOptions: {
    type: [Object],
    default() {
      const $t = this.$options.$$t('form.fields.custom_amount', {})
      const $s = this.$options.$$s('form.fields.custom_amount', {})
      return _.merge({}, $t, $s)
    }
  }
}

export default {
  props,
  mixins: [StandardBonusMixin],
  computed: {
    showCustomAmountInput() {
      return this.isCustomAmountProduct
    },
    showQuantityInput() {
      return !this.isCustomAmountProduct || this.showQuantityForCustomAmountProduct
    },
    isCustomAmountProduct() {
      return this.probeCustomAmountProduct(this.selectedProduct)
    },
    customAmountInputContainerAttrs() {
      return {
        class: this.getFieldContainerClasses('custom_amount')
      }
    },
    customAmountInputAttrs() {
      const defaults = this.customAmountInputOptions || {}
      return this.getFieldProps('custom_amount', {defaults})
    }
  },
  watch: {
    showCustomAmountInput(val) {
      if (!val) {
        this.fields.custom_amount = null
      }
    },
    'fields.custom_amount'(amount) {
      if (this.isCustomAmountProduct) {
        const product = this.selectedProduct
        amount = this.customAmountRounded ? _.round(amount) : amount
        this.calculatePointsForCustomAmount(amount, product)
        this.applyStandardBonusOnProduct(amount, product)
      }
    }
  },
  methods: {
    probeCustomAmountProduct(product) {
      if (!_.isEmpty(product)) {
        const prober = this.customAmountProductProbe || 'extra_cash_enabled'
        if (_.isFunction(prober)) {
          return prober(product)
        }
        return !!truthyRule(_.get(product, prober))
      }
      return false
    },
    resetCustomAmountProduct(products, skipId) {
      _.forOwn(products, product => {
        if (product.extra_cash_enabled && product.id !== skipId) {
          product.price = '0.00'
          product.numbers = {
            price: 0,
            priceRound: 0,
          }
          product.balance = {
            bonus_cash: '0.00',
            bonus_points: 0,
            points: 0
          }
        }
      })
    },
    setupCustomAmountValidations(items) {
      const max = _.toFinite(_.get(this.selectedProduct, this.customAmountMaxProperty)) || null
      const min = _.toFinite(fv(_.get(this.selectedProduct, this.customAmountMinProperty), 1))
      if (this.customAmountRounded) {
        const error = this.$$t(
          'form.fields.custom_amount.validationMessages.integer',
      'Amount must not have decimal.'
        )
        items.integer = {error}
      }

      const error = this.$$t(
        'form.fields.custom_amount.validationMessages.required',
        'Amount is required.'
      )
      items.required = {error}
      if (max) {
        const error = templify(
          this.$$t(
            'form.fields.custom_amount.validationMessages.max',
            'Amount can not be more than {max}.'
          ),
          {max, ...this.fields},
          ['{', '}']
        )
        items.max = {error, rule: maxValue(max)}
      }
      if (min) {
        const error = templify(
          this.$$t(
            'form.fields.custom_amount.validationMessages.min',
            'Amount can not be less than {min}.'
          ),
          {min, ...this.fields},
          ['{', '}']
        )
        items.min = {error, rule: minValue(min)}
      }
    },
    calculatePointsForCustomAmount(amount, product) {
      let points = 0
      const ratio = this.cardCreditCashRatio

      if (_.isNil(amount)) {
        amount = product.price
      } else {
        product.price = _.toFinite(amount).toFixed(2)
      }
      points = amount * ratio
      product.balance.points = points
    },
    applyPointsOnProducts(products) {
      return _.map(products, product => {
        const needsCalculation = product.standard_bonus_included === false
        if (needsCalculation) {
          this.applyStandardBonusOnProduct(null, product)
        }
        return product
      })
    }
  }
}
