import init from './init'
import queues from './queues'
import update from './update'
import destroy from './destroy'

export default function () {
  init.call(this)
  update.call(this)
  destroy.call(this)
  queues.call(this)
}

