import {context} from '../../../../../props/context'
export const allProps = {
  context,
  value: {},
  emitOnValid: {
    type: [Boolean, Number, String],
    default() {
      return true
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return true
    }
  },
}
export default allProps
