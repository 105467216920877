import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

// forgot password settings
const {key: forgotPasswordSpace = 'page.forgot-password'} = space('user-forgot-password') || {}
import {name as forgotPasswordComponentName} from '../re-forgot-password/config'
import {space} from '../../../../lib/page'

const localProps = {
  email: {},
  noRedirect: {
    type: [Boolean, Number, String],
    default: false
  },
  redirectTo: {
    type: [String, Object],
    default: null
  },
  redirectOnPreAuth: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('redirectOnPreAuth', true)
    }
  },

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Sign in')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', true)
    }
  },
  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', 'Please complete the form below to login')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },
  showFormLabels: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormLabels', true)
    }
  },
  showFormPlaceholders: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormPlaceholders', false)
    }
  },
  showFormFieldHint: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showFormFieldHint', true)
    }
  },

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', [])
    }
  },
  submitButtonTitle: {
    type: String,
    default() {
      return this.$options.$$t('submitButtonTitle', 'Sign in')
    }
  },
  submitButtonClasses: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('submitButtonClasses', [])
    }
  },

  inputClass: {
    type: String,
    default() {
      return this.$options.$$s('inputClass', 'form-control')
    }
  },
  hideForgotPassword: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('hideForgotPassword', false)
    }
  },
  hideRegistration: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('hideRegistration', true)
    }
  },
  registrationLinkAtTop: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('registrationLinkAtTop', false)
    }
  },
  registrationLinkAfterSubmit: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('registrationLinkAfterSubmit', false)
    }
  },

  hideActions: {
    type: Boolean,
    default() {
      return !!this.$options.$$s('hideActions', false)
    }
  },
  disableActions: {},
  triggerSubmit: {},

  loadingMessage: {
    type: String,
    default() {
      return this.$options.$$t('loadingMessage', 'Signing in...')
    }
  },

  showSubmitErrorOnFields: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubmitErrorOnFields', false)
    }
  },
  validateFormFieldOnDirty: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('validateFormFieldOnDirty', false)
    }
  },
  formOptions: {
    type: [Array, String, Object],
    default() {
      return this.$options.$$s('form.default.options', {})
    }
  },

  showVerificationErrorMessage: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('user.unverified.message.show', true)
    }
  },
  showVerificationErrorMessageAsPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('user.unverified.message.showPopup', true)
    }
  },
  showLoginBanner: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showBanner', false)
    }
  },
  bannerImage: {
    type: String,
    default() {
      return this.$options.$$s('bannerImage', null)
    }
  },

}

const forgotPasswordProps = {

  forgotPassword: {
    type: [Boolean, Number, String],
    default: null
  },

  showForgotPasswordPopup: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('forgotPassword.showPopup', false)
    }
  },
  forgotPasswordSettings: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$s('forgotPassword', forgotPasswordSpace)
    }
  },
  forgotPasswordTranslations: {
    type: [String, Object, Array],
    default() {
      return this.$options.$$t('forgotPassword', forgotPasswordSpace)
    }
  },
  forgotPasswordComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.forgotPassword', forgotPasswordComponentName)
    }
  },
  submitOnEnter: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('submitOnEnter', false)
    }
  }
}

export const props = {
  ...component,
  ...form,
  ...processing,
  ...forgotPasswordProps,
  ...localProps,
}

export default props
