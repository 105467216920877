export default {

  data() {
    return {
      productView: this.view === 'grid' ? 'grid' : 'list',
    }
  },

  computed: {
    isGridView() {
      return this.productView === 'grid'
    },
    showProductFeaturedImage() {
      return this.getProductImage() && this.showFeaturedImage &&
        this.$options.$$s(`view.types.${this.productView}.images.featured.show`, true)
    }
  },

  created() {

  },

  mounted() {

  },
  methods: {
    getProductItemStyle() {
      const styles = {...this.getProductBackgroundImageStyle()}
      return styles
    },
    getProductItemDetailsStyle() {
      const styles = {...this.getProductBackgroundImageStyle()}
      return styles
    },
    getComponentExtraClasses() {
      return [
        `${this.productView}-view-item`
      ]
    }
  },
}
