import {alert} from '../lib/utils'
import {
  confirmAsync,
  successAlertAsync,
  alertAsync,
  infoAlertAsync,
  warningAlertAsync,
  errorAlertAsync,
  showModal,
  showModalAsync,
} from '../lib/dynamic/modals'

export default {
  data() {
    return {
      confirmer: null
    }
  },
  methods: {
    alert,
    confirmAsync,
    alertAsync,
    infoAlertAsync,
    errorAlertAsync,
    successAlertAsync,
    warningAlertAsync,
    showModal,
    showModalAsync,
    confirm(message, callback, options) {
      const closeIt = () => this.confirmer = null
      const defaultOptions = {
        title: null,
        message: 'Do you want to continue?',
        confirmLabel: 'Yes',
        denyLabel: 'No'
      }
      options = options || {}
      if (message) {
        options = _.isObject(message) ? message : {message}
      }
      options = _.merge({}, defaultOptions, options)
      let {confirmed = null, denied = null} = options
      options.confirmed = () => {
        if (callback) {
          callback()
        }
        if (confirmed) {
          confirmed()
        }
        closeIt()
      }
      options.denied = () => {
        if (denied) {
          denied()
        }
        closeIt()
      }
      this.confirmer = _.cloneDeep(options)
      return this.confirmer
    }
  },

}

