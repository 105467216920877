import component from '../../../../props/component'
import form from '../../../../props/form'
import processing from '../../../../props/processing'

const localProps = {
  item: {},
  view: {},
  token: {},

  itemComponent: {
    type: String,
    default() {
      return this.$options.$$s('components.item', 're-shop-product-item-generic')
    }
  },

  title: {
    type: String,
    default() {
      return this.$options.$$t('title', 'Product')
    }
  },
  showTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showTitle', false)
    }
  },

  subTitle: {
    type: String,
    default() {
      return this.$options.$$t('subTitle', null)
    }
  },

  showSubTitle: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showSubTitle', false)
    }
  },

  notice: {
    type: String,
    default() {
      return this.$options.$$t('notice', '')
    }
  },
  showNotice: {
    type: [Boolean, Number, String],
    default() {
      return !!this.$options.$$s('showNotice', false)
    }
  },

  classes: {
    type: [String, Array, Object],
    default() {
      return this.$options.$$s('classes', null)
    }
  },
}
export const props = {
  ...component,
  ...form,
  ...processing,
  ...localProps
}
export default props
