import {
  setValidationRule,
  helpers
} from '../../../../../lib/validations'

export const defaultFormFields = {number: '', cvv: ''}

export const allValidationsRules = {
  number: setValidationRule('fields.number', {
    required: {error: 'Card number is required.'},
    digits: {error: 'Card number must be a number.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
  cvv: setValidationRule('fields.cvv', {
    required: {error: 'Card code is required.'},
    digits: {error: 'Card code must be a number.', rule: helpers.regex('digits', /^[0-9]*$/)}
  }),
}

export default {defaultFormFields, allValidationsRules}

