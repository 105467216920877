import ActionsMixin from './actions'
import EventsMixin from './events'
import FormMixin from './form'
import InitMixin from './init'
import PageMixin from './page'
import PropMixin from './props'
import ReactiveMixin from './reactives'
import StoreMixin from './store'
import ValidationMixin from './validation'

const mixins = [StoreMixin, InitMixin, PropMixin, EventsMixin, ActionsMixin, ValidationMixin, ReactiveMixin, PageMixin, FormMixin]

export default {
  mixins,
}
