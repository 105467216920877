export const name = 're-profile-promotions'

// custom setup

// generic setup
import props from './props'

export {props}

import ComponentMixin from '../../../../mixins/components/complex-reactive'
import ProfileMixin from '../../../../mixins/profile'
import TableMainMixin from '../../../../mixins/table'
import TableMixin from './table'
import PaginateMixin from './paginate'
import ResponsiveMixin from '../../../../mixins/responsive'
export const mixins = [
  ComponentMixin,
  ProfileMixin,
  TableMainMixin,
  TableMixin,
  PaginateMixin,
  ResponsiveMixin
]

export const EventBus = window.VueEventBus

// data
const propDefs = props
export const data = {
  propDefs,
}

export const PARSE_MAP = {
  "title": {
    "valuePath": "promotion.data.name"
  },
  "description": {
    "valuePath": "promotion.data.description"
  },
  "action": {
    "valuePath": "promotion.data.action"
  },
  "coupon_code": {
    "valuePath": "action_options.coupon_code"
  },
  "is_claimable": {
    "value": "{[promotion.data.is_claimable]}"
  },
  "is_usable": {
    "value": "{[promotion.data.is_usable]}"
  },
  "is_manually_action_required": {
    "value": "{[promotion.data.is_manually_applied]}"
  },
  "claim_url": {
    "valuePath": "action_options.coupon_claim_url"
  },
  "manual_apply_url": {
    "valuePath": "manual_apply_url"
  },
  "is_applied": {
    "value": "{[is_applied]}"
  },
  "is_stopped": {
    "value": "{[is_stopped]}"
  },
  "is_used": {
    "value": "{[is_used]}"
  },
  "is_expired": {
    "value": "{[is_expired]}"
  },
  "applied_at": {
    "valuePath": "applied_at"
  },
  "expire_at": {
    "valuePath": "expire_at"
  },
  "$discount": {
    "value": "{[action_options.acquired_discount]}",
    "format": "currency"
  },
  "discount": {
    "value": "{[action_options.acquired_discount]}"
  },
  "familyMember": {
    "valuePath": "{{action_options.family_member_first_name}} {{action_options.family_member_last_name}}",
    "format": "trim"
  }
}

export const ITEM_PARSE_MAP = {
  "is_active": {
    "value": "{[$]}",
    "format": "isActive"
  },
  "is_claimed": {
    "value": "{[$]}",
    "format": "hasClaimed"
  },
}

export default {
  name,
  mixins,
  props,
  data,
  EventBus,
  PARSE_MAP,
  ITEM_PARSE_MAP
}
