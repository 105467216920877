const setUser = async function () {
  const user = {
    email: 'greg@rocketeffect.com',
    first_name: 'Greg',
    last_name: 'Moreno'
  }
  await this.updateUser({user})
}
export default {
  async mounted() {
    if (this.isDesignerMode) {
      await this.$nextTick()
      await setUser.call(this)
    }
  }
}
