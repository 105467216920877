/*global _ */
import Base, {
  axios,
  payloads,
  setPromise,
  getEndpoint,
  omitId,
} from './base'

export {
  APIURI,
  client_secret,
  client_id,
  getFirstErrorMessage,
  getErrorMessage,
  getErrorMessages,
  getErrorMessagesWithKeys,
  endpoints,
  services,
  handlePromise,
  promise,
  setPromise,
  getEndpoint,
} from './base'

const myServices = {}
export const updatePlaycard = myServices.updatePlaycard = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    'user.card.playcard.update',
    payload,
    '/customer/playcard/{id}',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcard.update', {})}
  const {data} = await axios.put(endPoint, omitId(payload))
  return data
}
setPromise('user.card.playcard.update', updatePlaycard)

export const transferPlaycardBalance = myServices.transferPlaycardBalance = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    'user.card.playcard.transfer.balance',
    payload,
    '/customer/playcard/{id}/transfer',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcard.transfer.balance', {})}
  const {data} = await axios.post(endPoint, payload)
  return data
}
setPromise('user.card.playcard.transfer.balance', transferPlaycardBalance)

export const getPlaycardDetails = myServices.getPlaycardDetails = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    //@breaking: Earlier using `user.playcard.details` <= `playcard.details`
    'user.card.playcard.get',
    {},
    '/customer/playcard/details',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcard.get', {})}
  const {data} = await axios.post(endPoint, payload)
  data.type = 'playcard'
  return data
}
// @deprecated: Use `user.card.playcard.get` instead
setPromise('card.playcard.get', getPlaycardDetails)
setPromise('user.card.playcard.get', getPlaycardDetails)

export const checkGiftcard = myServices.checkGiftcard = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    //@breaking: Earlier using `user.giftcard.check` <= `giftcard.check`
    'user.card.giftcard.get',
    {},
    '/customer/giftcard/check',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.giftcard.get', {})}
  const {data} = await axios.post(endPoint, payload)
  data.type = 'giftcard'
  return data
}

// @deprecated: Use `user.card.giftcard.get` instead
setPromise('card.giftcard.check', checkGiftcard)
setPromise('user.card.giftcard.get', checkGiftcard)

export const getCard = myServices.getCard = async (payload, options = {}) => {
  options = options || {}
  const type = options.type || ''
  if (type.includes('giftcard')) {
    return await checkGiftcard(payload, options)
  }
  if (type.includes('playcard')) {
    return await getPlaycardDetails(payload, options)
  }
  let data = {}
  try {
    data = await getPlaycardDetails(payload, options)
    return data
  } catch (e) {
    //
  }
  try {
    data = await checkGiftcard(payload, options)
  } catch (e) {
    //
  }
  return data
}
// @deprecated: Use `user.card.get` instead
setPromise('card.get', getCard)
setPromise('user.card.get', getCard)

export const getPlaycards = myServices.getPlaycards = async (payload = {}, options = {}) => {
  const where = {is_archived: false}
  const order = 'is_primary:desc,user_order'
  const page_size = 999
  payload = {where, order, page_size, ...payload || {}}
  const endPoint = getEndpoint(
    'user.card.playcards.get',
    {},
    '/customer/playcard',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcards.get', {})}
  // debugLog({getPlaycards_PAYLOAD: payload})
  const {data: {data: cards}} = await axios.get(endPoint, {params: payload})
  return cards
}
setPromise('user.card.playcards.get', getPlaycards)

export const getGiftcards = myServices.getGiftcards = async (payload = {}, options = {}) => {
  payload = {page_size: 999, ...payload || {}}
  const endPoint = getEndpoint(
    'user.card.giftcards.get',
    {},
    '/customer/giftcard',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.giftcards.get', {})}
  const {data: {data: cards}} = await axios.get(endPoint, {params: payload})
  return cards
}
setPromise('user.card.giftcards.get', getGiftcards)

export const getCards = myServices.getCards = async (payload = {}, options = {}) => {
  const allCards = {'giftcard': getGiftcards, 'playcard': getPlaycards}
  const type = _.castArray(options.type || _.keys(allCards))
  if (_.isArray(type)) {
    const cards = await Promise.all(type.map(async (cardType) => {
      if (allCards[cardType]) {
        try {
          return await allCards[cardType](payload, options)
        } catch (e) {
          return []
        }
      }
    }))
    return _.zipObject(type, cards)
  }
}
setPromise('user.card.cards.get', getCards)

export const discardCard = myServices.discardCard = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    'user.card.playcard.discard',
    payload,
    '/customer/playcard/{id}/discard',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcard.discard', {})}
  const {data} = await axios.post(endPoint, omitId(payload))
  return data
}
setPromise('user.card.playcard.discard', discardCard)

export const addPlaycard = myServices.addPlaycard = async (payload, options = {}) => {
  const endPoint = getEndpoint(
    'user.card.playcard.add',
    payload,
    '/customer/playcard/add',
    options
  )
  payload = {...(payload || {}), ..._.get(payloads, 'user.card.playcard.add', {})}
  const {data: {data: playcard}} = await axios.post(endPoint, payload)
  return playcard
}
setPromise('user.card.playcard.add', addPlaycard)


export default {
  ...Base,
  ...myServices
}
